import dayjs, { DayjsFormats } from 'lib/dayjs'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ContactOptIn, CustomFieldType, Status } from 'src/generated/graphql'
import * as React from 'react'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { numberString } from 'src/util/functions'
import { OptInRender } from 'components/NewTable/cell/OptInRender/OptInRender'
import { IContact } from 'src/types/IContact'
import { ISegment } from 'src/types/ISegment'
import { ColumnType } from 'components/NewTable/types'
import { ItemsRender } from 'components/NewTable/cell/ItemsRender/ItemsRender'

export const getContactColumns = (): ColumnType<IContact>[] => [
  { id: 'name', label: 'Name' },
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'formattedPhoneNumber', label: 'Phone' },
  { id: 'email', label: 'Email' },
  {
    id: 'listContacts',
    label: 'Lists',
    format: (value, { items }) =>
      items ? <ItemsRender items={items} /> : <></>,
  },
  {
    id: 'optIn',
    label: 'Opt-in method',
    format: (value, { contactSource }) => (
      <OptInRender
        optIn={value as ContactOptIn}
        contactSource={contactSource}
      />
    ),
  },

  {
    id: 'status',
    label: 'Status',
    minWidth: 143,
    format: (value) => <StatusContent status={value as Status} />,
  },
  {
    id: 'createdAt',
    label: 'Added',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAt),
  },
  {
    id: 'carrierType',
    label: 'Phone Type',
  },
  ...customFieldsStore.customFieldsFiltered.map((field, index, array) => ({
    id: field.id,
    label: field.name,
    minWidth: index === array.length - 1 ? 143 : undefined,
    format: (value: any, contact: IContact) => {
      const customField = customFieldsStore.customFieldsMap.get(field.id)
      if (!customField) {
        return <></>
      }
      if (customField.type === CustomFieldType.DateTime) {
        const date = contact.customFieldsMap.get(customField.id)?.date
        if (!date) {
          return <></>
        }
        return <>{dayjs(date).format(DayjsFormats.fullWithAt)}</>
      }
      if (customField.type === CustomFieldType.Date) {
        const date = contact.customFieldsMap.get(customField.id)?.date
        if (!date) {
          return <></>
        }
        return <>{dayjs(date).format(DayjsFormats.date)}</>
      }
      if (
        customField.type === CustomFieldType.Text ||
        customField.type === CustomFieldType.Url
      ) {
        const value = contact.customFieldsMap.get(customField.id)?.value
        if (!value) {
          return <></>
        }
        return <>{value}</>
      }
      if (customField.type === CustomFieldType.Number) {
        const value = contact.customFieldsMap.get(customField.id)?.value
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!value && value !== 0) {
          return <></>
        }
        return <>{numberString({ val: +value })}</>
      }
      return <div>{customField.id}</div>
    },
  })),
]

export const segmentsColumns: ColumnType<ISegment>[] = [
  {
    id: 'name',
    label: 'Segment name',
    format: (value) => <div className={'edit'}>{value as string}</div>,
  },
  {
    id: 'createdAt',
    label: 'Created',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAt),
  },
  // {
  //   id: 'matchedContacts',
  //   label: 'Matched contacts',
  //   format: (value) => <>{Array.isArray(value) && value.length}</>,
  // },
]

export const noSortsColumns = ['listContacts']
