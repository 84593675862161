import { ReactComponent as HabspotLogoSmall } from 'icons/24px/Habspot.svg'
import { ReactComponent as KeapLogoSmall } from 'icons/24px/Keap.svg'
import { ReactComponent as ActiveCampaignLogoSmall } from 'icons/24px/ActiveCampaign.svg'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { ReactComponent as Zapier } from 'assets/icons/zapier-logo_black.svg'
import GoogleSheets from 'assets/images/GoogleSheets.png'

import { FC, ReactNode } from 'react'
import { AccountIntegrationType } from 'src/generated/graphql'
import { appLinks } from 'src/util/links'
import { IIcon } from 'components/Icon/Icon'

export interface IIntegration {
  title: string
  subTitle?: string
  docsLink?: string
  exploreLink?: string
  disabled?: boolean
  authUrl?: string
  automationsCount: number
  zapsCount: number
  icon?: IIcon
  logoContent?: ReactNode
  Logo?: FC
  type: AccountIntegrationType | 'Mock'
  w50?: boolean
}

export type IAccountIntegrationType = AccountIntegrationType | 'Mock'

export const integrationCards: IIntegration[] = [
  {
    type: AccountIntegrationType.ActiveCampaign,
    Logo: ActiveCampaignLogoSmall,
    automationsCount: 0,
    zapsCount: 0,
    title: 'ActiveCampaign',
    docsLink: appLinks.activeCampaignDocs,
  },
  {
    type: AccountIntegrationType.Keap,
    Logo: KeapLogoSmall,
    automationsCount: 0,
    zapsCount: 0,
    title: 'Keap',
    subTitle: 'Infusionsoft',
    docsLink: appLinks.keapDocs,
  },
  {
    type: AccountIntegrationType.HubSpot,
    Logo: HabspotLogoSmall,
    automationsCount: 0,
    zapsCount: 0,
    title: 'HubSpot',
    docsLink: appLinks.hubSpotDocs,
  },
  {
    type: AccountIntegrationType.Zapier,
    Logo: Zapier,
    automationsCount: 0,
    zapsCount: 0,
    title: 'Zapier',
    docsLink: appLinks.zapierDocs,
    authUrl: 'https://zapier.com/app/connections/call-loop',
  },
  {
    type: AccountIntegrationType.Custom,
    Logo: Logo,
    automationsCount: 0,
    zapsCount: 0,
    title: 'Webhook',
    docsLink: appLinks.webhookDocs,
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/aweber/integrations/call-loop',
    icon: 'aWeber',
    automationsCount: 0,
    zapsCount: 0,
    title: 'AWeber',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/drip/integrations/call-loop',
    icon: 'drip',
    automationsCount: 0,
    zapsCount: 0,
    title: 'Drip',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/mailchimp/integrations/call-loop',
    icon: 'mailchimp',
    automationsCount: 0,
    zapsCount: 0,
    title: 'Mailchimp',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/google-sheets/integrations/call-loop',
    logoContent: (
      <img src={GoogleSheets} alt={'GoogleSheets'} width={22.531} height={30} />
    ),
    automationsCount: 0,
    zapsCount: 0,
    title: 'Google Sheets',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/typeform/integrations/call-loop',
    icon: 'typeform',
    automationsCount: 0,
    zapsCount: 0,
    title: 'Typeform',
    w50: true,
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/unbounce/integrations/call-loop',
    icon: 'unbounce',
    automationsCount: 0,
    zapsCount: 0,
    title: 'Unbounce',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/gravity-forms/integrations/call-loop',
    icon: 'gravityForms',
    automationsCount: 0,
    zapsCount: 0,
    title: 'Gravity Forms',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/stripe/integrations/call-loop',
    icon: 'stripe',
    automationsCount: 0,
    zapsCount: 0,
    title: 'Stripe',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/leadpages/integrations/call-loop',
    icon: 'leadpages',
    automationsCount: 0,
    zapsCount: 0,
    title: 'Leadpages',
  },
]

export const integrationCardsMap: Map<IAccountIntegrationType, IIntegration> =
  new Map(
    integrationCards.map((integrationCard) => [
      integrationCard.type,
      integrationCard,
    ])
  )
