import styles from 'src/routes/settings/account/routes/MyProfile/VirtualContactCard/FullCardContent/styles.module.scss'
import { SocialMediaIcon } from 'components/AppIcon/SocialMediaIcon'
import { SocialMediaEnums } from 'src/enums/SocialMediaEnums'
import { getLastLinkPart } from 'components/VCard/MediaCardItem/MediaCardItem'

export const SocialMediaItem = ({
  url,
  type,
}: {
  url: string
  type: SocialMediaEnums
}) => {
  return (
    <div className={styles.infoItemFooter}>
      <a href={url} target={'_blank'} rel="noreferrer" className={'row8'}>
        <SocialMediaIcon icon={type} />
        <span className={'s2'}>{type}</span>
      </a>
      <span className={'s2 gray1 ellipsisNowrap'}>{getLastLinkPart(url)}</span>
    </div>
  )
}
