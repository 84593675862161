import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import createCampaignStore from 'store/campaigns/createCampaignStore'

export const AddMessage = () => {
  return (
    <button
      className={classNames(styles.btnWrap, 'row8')}
      onClick={() => createCampaignStore.addMessage()}
    >
      <div className={styles.wrapPlus}>
        <AppIcon color={ColorsNames.blue3}>
          <Plus />
        </AppIcon>
      </div>

      <span className={'bold blue3'}>Add message</span>
    </button>
  )
}
