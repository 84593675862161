import { SizedBox } from 'components/SizedBox'
import { observer } from 'mobx-react-lite'
import { NumbersTable } from 'src/routes/settings/numbers/NumbersTable'
import { CallForwardingModal } from 'src/routes/settings/numbers/modals/CallForwardingModal'
import { ReleaseNumbersModal } from 'src/routes/settings/numbers/modals/ReleaseNumbersModal'

export const Numbers = observer(() => {
  return (
    <div className={'small-container full-height'}>
      <CallForwardingModal />
      <ReleaseNumbersModal />
      <h1>Numbers</h1>
      <SizedBox height={28} />
      <NumbersTable />
    </div>
  )
})
