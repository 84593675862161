// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zkT77f1BxNGTdFmMAQaP{display:grid;grid-template-columns:1fr max-content 1fr;align-items:center}.zkT77f1BxNGTdFmMAQaP span{margin:0 16px}`, "",{"version":3,"sources":["webpack://./src/components/HorizontalDevider/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,yCAAA,CACA,kBAAA,CACA,2BACE,aAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: 1fr max-content 1fr;\n  align-items: center;\n  span{\n    margin: 0 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `zkT77f1BxNGTdFmMAQaP`
};
export default ___CSS_LOADER_EXPORT___;
