import { ActionsEnum } from 'components/Button/types'
import { SmallBtn } from 'components/Button/SmallBtn'
import * as React from 'react'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionBtnProps } from 'components/NewTable/types'
import { IPhoneNumber } from 'src/types/IPhoneNumber'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'

export const VerifyNumberAction = ({
  menu,
  medium,
}: ActionBtnProps<IPhoneNumber>) => {
  const navigate = useNavigate()
  const onVerify = () => {
    navigate(RoutesEnum.settingsCompliance)
  }
  if (menu || medium) {
    return (
      <MediumBtn
        type={ActionsEnum.sent}
        onClick={onVerify}
        text={'Verify'}
        menu={menu}
      />
    )
  }
  return (
    <SmallBtn
      type={ActionsEnum.sent}
      onClick={onVerify}
      // iconType={IIconType.stroke}
      tooltip={'Verify'}
      // iconColor={ColorsNames.success1}
    />
  )
}
