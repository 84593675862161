import { observer } from 'mobx-react-lite'
import teamStore from 'store/settings/teamStore'
import { Modal } from 'components/Modal/Modal'
import { InviteContent } from 'src/routes/settings/company/routes/CompanyTeam/modals/MemberModal/InviteContent/InviteContent'

export const MemberModal = observer(() => {
  const onClose = () => {
    teamStore.setOpenMemberModal(false)
  }
  return (
    <Modal
      open={teamStore.openMemberModal}
      onClose={onClose}
      title={teamStore.editMemberId ? 'Edit member' : 'Invite team member'}
    >
      <InviteContent />
    </Modal>
  )
})
