import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { ITrigger } from 'src/types/ITrigger'
import { IBroadcast } from 'src/types/IBroadcast'
import { DetailTriggerContent } from 'components/DetailsModalContent/DetailTriggerContent'
import { DetailBroadcastContent } from 'components/DetailsModalContent/DetailBroadcastContent'
import { DetailWebFormContent } from 'components/DetailsModalContent/DetailWebFormContent'
import { DetailKeywordContent } from 'components/DetailsModalContent/DetailKeywordContent'
import { IKeyword } from 'src/types/IKeyword'
import { IWebForm } from 'src/types/IWebForm'
import { ICampaign } from 'src/types/ICampaign'
import { DetailCampaignContent } from 'components/DetailsModalContent/DetailCampaignContent'
import classNames from 'classnames'

export const DetailsModalContent = observer(
  ({
    element,
  }: {
    element: ITrigger | IBroadcast | IWebForm | IKeyword | ICampaign
  }) => {
    const isTrigger = 'appOption' in element
    const isBroadcast = 'simplifiedStatus' in element
    const isWebFrom = 'fieldsToCollect' in element
    const isKeyword = 'keywordPhoneNumbers' in element
    const isCampaign = 'campaignStepResponses' in element
    return (
      <div
        className={classNames(styles.wrap, isCampaign && styles.fromCampaign)}
      >
        {isTrigger && <DetailTriggerContent trigger={element} />}
        {isBroadcast && <DetailBroadcastContent broadcast={element} />}
        {isWebFrom && <DetailWebFormContent webForm={element} />}
        {isKeyword && <DetailKeywordContent keyword={element} />}
        {isCampaign && <DetailCampaignContent campaign={element} />}
      </div>
    )
  }
)
