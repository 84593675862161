import superAdminStore from 'store/superAdmin/superAdminStore'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { SmallBtn } from 'components/Button/SmallBtn'
import * as React from 'react'
import { IOrganization } from 'src/types/IOrganization'
import { IOrganizationDetails } from 'src/types/IOrganizationDetails'
import { ActionBtnProps } from 'components/NewTable/types'
import { IIconType } from 'components/AppIcon/AppIcon'

export const AddCallerIdBtn = ({
  onCloseTT,
  row,
  medium,
  menu,
}: ActionBtnProps<IOrganization | IOrganizationDetails>) => {
  const onAddCallerId = () => {
    onCloseTT && onCloseTT()
    if (row) {
      superAdminStore.setOpenAddCallerIdModalOrganizationId(row.id)
    }
  }
  return medium || menu ? (
    <MediumBtn
      type={ActionsEnum.addCallerId}
      onClick={onAddCallerId}
      menu={menu}
      iconType={IIconType.circle}
    />
  ) : (
    <SmallBtn
      type={ActionsEnum.addCallerId}
      onClick={onAddCallerId}
      iconType={IIconType.circle}
    />
  )
}
