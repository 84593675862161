import { FC, useEffect, useRef, useState } from 'react'
import DatePickerLib, { ReactDatePicker } from 'react-datepicker'
import { nanoid } from 'nanoid'

type Props = {
  startTime: Date | null | undefined
  onChange: (val: Date | null) => void
  zoneAbbreviation?: string
}

export const TimePickerWithoutTZ: FC<Props> = ({
  startTime,
  onChange,
  zoneAbbreviation,
}) => {
  const [trigger, setTrigger] = useState('')
  const ref = useRef<ReactDatePicker | null>(null)
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const value = ref.current?.state?.inputValue
      const list = document.querySelector('.react-datepicker__time-list')
      if (list) {
        Array.from(list.children)
          .find((el) => el.innerHTML.includes(value))
          ?.scrollIntoView()
      }
    })
  }, [trigger])

  return (
    <div className={'timePicker'}>
      {zoneAbbreviation && (
        <div className={'zoneAbbreviationWrap'}>
          <div className={'zoneAbbreviation'}>{zoneAbbreviation}</div>
        </div>
      )}
      <div>
        <DatePickerLib
          placeholderText={'Select time'}
          selected={startTime}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          onKeyDown={() => {
            setTrigger(nanoid())
          }}
          ref={ref}
        />
      </div>
    </div>
  )
}
