import styles from 'components/Preview/styles.module.scss'
import { MessageCardContent } from 'components/Preview/MessageCardContent'
import { FC } from 'react'
import { IRinglessMessage } from 'components/Preview/types'

export const RinglessContent: FC<IRinglessMessage> = ({
  phone,
  ringlessVoicemailAudio,
}) => {
  return (
    <div className={styles.messageWrap}>
      <div className={styles.audioMessage}>
        <MessageCardContent
          title={'Ringless'}
          from={'ringlessPreview'}
          audio={ringlessVoicemailAudio}
          phone={phone}
        />
      </div>
    </div>
  )
}
