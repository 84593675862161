import styles from './styles.module.scss'
import { Button } from 'components/Button/Button'
import { DefaultSubscriptionCard } from 'src/Modals/UpgradePlanModal/Step1Upgrade/cards/DefaultSubscriptionCard'
import { CustomCard } from 'src/Modals/UpgradePlanModal/Step1Upgrade/cards/CustomCard'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { PayGoLimits } from 'src/Modals/UpgradePlanModal/Step1Upgrade/cards/PayGoLimits'
import { RangePlanCard } from 'src/Modals/UpgradePlanModal/Step1Upgrade/components/RangePlanCard/RangePlanCard'

export const Step1Upgrade = observer(() => {
  const rightCard = useMemo(() => {
    if (upgradePlanStore.isPayAsYouGo) {
      return <PayGoLimits />
    }
    return <CustomCard />
  }, [upgradePlanStore.isPayAsYouGo])

  return (
    <>
      <RangePlanCard
        period={upgradePlanStore.modalActivePeriod}
        rightBtn={
          !upgradePlanStore.isPayAsYouGo && (
            <Button
              onClick={() => {
                upgradePlanStore.setIsPayAsYouGo(true)
              }}
            >
              <span className={'s2 bold linkGray2'}>Pay As You Go</span>
            </Button>
          )
        }
        rangeInputProps={{
          rangeValues: upgradePlanStore.proPricesForPeriod.map(
            (price) => price.creditsIncluded || 0
          ),
          value: upgradePlanStore.modalSelectedRangeValue,
          max: upgradePlanStore.proPricesForPeriod.length - 1,
          onChangeValue: (val) => {
            if (upgradePlanStore.isCustomPlane) {
              upgradePlanStore.setIsCustomPlane(false)
            }
            if (upgradePlanStore.isPayAsYouGo) {
              upgradePlanStore.setIsPayAsYouGo(false)
            }
            upgradePlanStore.setSelectedModalRangeValue(val)
          },
          disabled:
            upgradePlanStore.isCustomPlane || upgradePlanStore.isPayAsYouGo,
        }}
      />
      <div className={styles.cards}>
        <DefaultSubscriptionCard />
        {rightCard}
      </div>
    </>
  )
})
