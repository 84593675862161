import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { ITrigger } from 'src/types/ITrigger'

export const TriggerCopyWebhookBtn = (props: ActionBtnProps<ITrigger>) => {
  const { row } = props
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.copy}
      textBtn={'Copy webhook'}
      copyContent={row?.link || ''}
    />
  )
}
