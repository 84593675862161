import { observer } from 'mobx-react-lite'
import { Row } from 'src/routes/settings/compliance/modals/components/Row/Row'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'

export const ContactInfo = observer(() => {
  const { contactInfo } = tollFreeVerificationStore
  return (
    <div className={'col16'}>
      <p className={'par2'}>
        <b>Contact info</b>
      </p>
      <div className={'col12'}>
        <Row
          title={'Name'}
          value={`${contactInfo?.firstName || ''} ${
            contactInfo?.lastName || ''
          }`.trim()}
        />
        <Row title={'Email'} value={contactInfo?.email} />
        <Row title={'Phone number'} value={contactInfo?.formattedPhoneNumber} />
      </div>
    </div>
  )
})
