import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import numbersStore from 'store/settings/numbers/numbersStore'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { SizedBox } from 'components/SizedBox'
import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { Ul } from 'components/Ul/Ul'
import { Li } from 'components/Ul/Li'
import {
  useReleasePhoneNumberMutation,
  VerificationStatus,
} from 'src/generated/graphql'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'

export const ReleaseNumbersModal = observer(() => {
  const [releasePhoneNumber, { loading }] = useReleasePhoneNumberMutation()
  const [understand, setUnderstand] = useState(false)

  useEffect(() => {
    setUnderstand(false)
  }, [numbersStore.openReleaseNumberModal])

  const onRelease = async () => {
    try {
      await releasePhoneNumber({
        variables: {
          id: numbersStore.openReleaseNumbers[0].id,
        },
      })
      numbersStore.onRelease()
    } catch (e) {
      console.error(e)
    }
  }
  const onClose = () => {
    numbersStore.setOpenReleaseNumberModal([])
  }

  const number = numbersStore.openReleaseNumbers[0]
  const inReview = number?.verificationStatus === VerificationStatus.InReview

  return (
    <Modal
      open={numbersStore.openReleaseNumberModal}
      onClose={onClose}
      title={'Release number'}
      withoutHeader={inReview}
    >
      {inReview ? (
        <div className={'pModalInfo'}>
          <ModalIcon type={'infoError1'} />
          <h3 className={'mb20'}>
            Toll-free numbers that are in review, can’t be released
          </h3>
          <SizedBox height={20} />
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            fullWidth
            onClick={onClose}
          >
            Done
          </Button>
        </div>
      ) : (
        <div className={'mediumModal'}>
          <Alert
            type={AlertTypeEnum.notify2}
            text={`Are you sure you want to release ${number?.friendlyName}? This action is destructive and cannot be undone.`}
          />
          <SizedBox height={32} />
          <div className={styles.wrapAffects}>
            <p className={'par2 bold mb16'}>
              Releasing the number will affect connected with the number
            </p>
            <Ul>
              <Li>Scheduled broadcasts</Li>
              <Li>Campaigns</Li>
              <Li>Triggers</Li>
              <Li>Keywords</Li>
              <Li>Web Forms</Li>
            </Ul>
          </div>
          <InputCheckbox
            label={
              'I understand the action is destructive and can not be undone'
            }
            checked={understand}
            onChecked={() => setUnderstand(!understand)}
            noBordered
            fitHeight
            alignCenter
          />
          <div className={'mt40 row12'}>
            <Button
              size={BtnSize.medium}
              typeBtn={BtnType.primary}
              onClick={onRelease}
              disabled={!understand}
              loading={loading}
            >
              Release number
            </Button>
            <Button
              size={BtnSize.medium}
              typeBtn={BtnType.secondaryGray}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
})
