// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yZ6bCJXkzRZARo8ctMCg{border:1px solid var(--primary-color-gray-3);border-radius:12px;padding:15px;width:536px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/components/PhoneCheckr/modal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,kBAAA,CACA,YAAA,CACA,WAAA","sourcesContent":[".wrap{\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 12px;\n  padding: 15px;\n  width: 536px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `yZ6bCJXkzRZARo8ctMCg`
};
export default ___CSS_LOADER_EXPORT___;
