import { BtnSize, Button } from 'components/Button/Button'
import { Icon } from 'components/Icon/Icon'
import { observer } from 'mobx-react-lite'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { Tabs } from 'components/Tabs/Tabs'
import React from 'react'
import { SizedBox } from 'components/SizedBox'
import { SearchInput } from 'components/Input/InputText/SearchInput'
import createTextToSpeechStore from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechStore'
import styles from './styles.module.scss'
import { OptionsRender } from 'components/Dropdown/components/OptionsRender'
import { VoicePreviewPlayer } from 'src/Modals/CreateTextToSpeechModal/components/ChooseVoice/VoicePreviewPlayer'

export const ChooseVoice = observer(() => {
  const {
    setActiveTabIndex,
    activeTabIndex,
    search,
    setSearch,
    voiceOptions,
    selectedOption,
    onSelect,
    standardLengthString,
    premiumLengthString,
  } = createTextToSpeechStore
  const { setTrigger, trigger, onTriggerClick, showTT, setShowTT } =
    useFixedTooltip()

  const renderOptions = voiceOptions.map((option) => ({
    ...option,
    leftTitleContent: <VoicePreviewPlayer voice={option.data} />,
  }))

  return (
    <>
      <FixedTooltip
        visible={showTT}
        trigger={trigger}
        white
        noArrow
        position={TTPositionEnum.bottomLeft}
      >
        <SizedBox height={16} />
        <Tabs
          tabs={[
            `Basic ${standardLengthString}`,
            `Premium ${premiumLengthString}`,
          ]}
          activeTabIndex={activeTabIndex}
          onTabClick={setActiveTabIndex}
          paddingInline={12}
        />
        <div className={'p8 col8'}>
          <SearchInput value={search} onSearch={setSearch} />
          <div className={styles.wrapItems}>
            <OptionsRender
              options={renderOptions}
              setShowTT={setShowTT}
              selectedOption={selectedOption}
              onSelect={onSelect}
            />
          </div>
        </div>
      </FixedTooltip>
      <Button
        size={BtnSize.small}
        hoverGray
        style={{ paddingLeft: 12 }}
        ref={setTrigger}
        onClick={onTriggerClick}
      >
        <Icon icon={'recording'} color={'PrimaryColorGray1'} />
        {selectedOption?.title}
        <Icon icon={'angleDown'} color={'PrimaryColorGray1'} />
      </Button>
    </>
  )
})
