// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kzlsGgAz7AlQWh6tW5la{min-width:640px;padding:64px;background-color:var(--primary-color-gray-4);min-height:var(--page-height)}.kzlsGgAz7AlQWh6tW5la.GxnZH2uuzKlTGqTlwSTt{text-align:center}.kzlsGgAz7AlQWh6tW5la h1{margin-bottom:12px}@media(max-width: 800px){.kzlsGgAz7AlQWh6tW5la{min-width:unset;padding:48px 24px}}@media(max-height: 600px){.kzlsGgAz7AlQWh6tW5la{min-width:unset;padding:48px 24px}}.G7lA1E2zwAzl2GvXCb8n{margin-top:64px;margin-bottom:32px;display:grid;row-gap:16px}`, "",{"version":3,"sources":["webpack://./src/routes/main/createMessages/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,4CAAA,CACA,6BAAA,CACA,2CACE,iBAAA,CAEF,yBACE,kBAAA,CAEF,yBAXF,sBAYI,eAAA,CACA,iBAAA,CAAA,CAEF,0BAfF,sBAgBI,eAAA,CACA,iBAAA,CAAA,CAGJ,sBACE,eAAA,CACA,kBAAA,CACA,YAAA,CACA,YAAA","sourcesContent":[".wrap{\n  min-width: 640px;\n  padding: 64px;\n  background-color: var(--primary-color-gray-4);\n  min-height: var(--page-height);\n  &.center{\n    text-align: center;\n  }\n  h1{\n    margin-bottom: 12px;\n  }\n  @media (max-width: 800px) {\n    min-width: unset;\n    padding: 48px 24px;\n  }\n  @media (max-height: 600px) {\n    min-width: unset;\n    padding: 48px 24px;\n  }\n}\n.massMessageItemsWrap{\n  margin-top: 64px;\n  margin-bottom: 32px;\n  display: grid;\n  row-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `kzlsGgAz7AlQWh6tW5la`,
	"center": `GxnZH2uuzKlTGqTlwSTt`,
	"massMessageItemsWrap": `G7lA1E2zwAzl2GvXCb8n`
};
export default ___CSS_LOADER_EXPORT___;
