import { action, computed, makeObservable, observable } from 'mobx'
import {
  FilterCondition,
  FilterDataType,
  FilterInput,
} from 'src/generated/graphql'
import {
  CommonFilter,
  CommonFilterProps,
} from 'store/contacts/segment/filters/CommonFilter'
import { RangeDatePickerStore } from 'store/rangeDatePickerStore/rangeDatePickerStore'
import dayjs from 'lib/dayjs'

type Props = CommonFilterProps

export class DateFilter extends CommonFilter {
  rangeDatePickerStore = new RangeDatePickerStore()
  constructor(props: Props) {
    super(props)
    makeObservable(this, {
      date: observable,
      rangeDatePickerStore: observable,
      value: observable,

      setValue: action.bound,

      filterInput: computed,
      inputValue: computed,
      inputValueExt: computed,
      isDate: computed,
      isNumber: computed,
      isRange: computed,
      isTime: computed,
      isUnknown: computed,
      isNotEmpty: computed,
    })

    if (this.isNumber) {
      if (props.value) {
        this.value = props.value
      }
    }
    if (this.isRange) {
      if (props.value) {
        this.rangeDatePickerStore.setStartDate(dayjs(props.value).toDate())
      }
      if (props.valueExt) {
        this.rangeDatePickerStore.setEndDate(dayjs(props.valueExt).toDate())
      }
    }
    if (this.isDate) {
      if (props.value) {
        this.date = dayjs(props.value).toDate()
      }
    }
  }

  date: Date | null = new Date()
  value = ''

  get isNotEmpty() {
    if (this.isDate) {
      return !!this.date
    }
    if (this.isRange) {
      return (
        !!this.rangeDatePickerStore.startDate &&
        !!this.rangeDatePickerStore.endDate
      )
    }
    if (this.isNumber) {
      return !!this.value
    }
  }

  get isTime() {
    return this.dataType === FilterDataType.DateTime
  }

  get isDate() {
    return (
      this.condition === FilterCondition.Before ||
      this.condition === FilterCondition.After ||
      this.condition === FilterCondition.On
    )
  }
  get isNumber() {
    return (
      this.condition === FilterCondition.Greater ||
      this.condition === FilterCondition.GreaterEq ||
      this.condition === FilterCondition.Less ||
      this.condition === FilterCondition.LessEq ||
      this.condition === FilterCondition.Eq
    )
  }
  get isRange() {
    return (
      this.condition === FilterCondition.Between ||
      this.condition === FilterCondition.NotBetween
    )
  }

  get isUnknown() {
    return !this.isDate && !this.isNumber && !this.isRange
  }

  get inputValue(): string | null {
    if (this.isDate && this.date) {
      return dayjs(this.date).utc().toISOString()
    }
    if (this.isNumber) {
      return this.value
    }
    if (this.isRange && this.rangeDatePickerStore.startDate) {
      return dayjs(this.rangeDatePickerStore.startDate).utc().toISOString()
    }
    return null
  }

  get inputValueExt(): string | null {
    if (this.isRange && this.rangeDatePickerStore.endDate) {
      return dayjs(this.rangeDatePickerStore.endDate).utc().toISOString()
    }
    return null
  }

  get filterInput(): FilterInput {
    return {
      orIndex: this.orIndex,
      andIndex: this.andIndex,
      dataType: this.dataType,
      condition: this.condition,
      field: this.field,
      value: this.inputValue,
      valueExt: this.inputValueExt,
    }
  }

  setValue = (value: typeof this.value) => {
    this.value = value
  }

  setDate = (value: typeof this.date) => {
    this.date = value
  }
}
