import billingStore from 'store/settings/billing/billingStore'
import triggersStore from 'store/triggers/triggersStore'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import keywordsStore from 'store/keywords/keywordsStore'
import campaignsStore from 'store/campaigns/campaignsStore'
import webFormStore from 'store/webForms/webFormStore'
import { Limits } from 'src/generated/graphql'
import teamStore from 'store/settings/teamStore'
import { AppElements } from 'src/enums/appElements'

export const AppLimits: { [key: string]: keyof Limits } = {
  [AppElements.Automation]: 'automations',
  [AppElements.Broadcast]: 'broadcasts',
  [AppElements.Campaign]: 'campaigns',
  [AppElements.Trigger]: 'triggers',
  [AppElements.WebForm]: 'webForms',
  [AppElements.Contact]: 'contacts',
  users: 'users',
}

export const acceptedCreate = ({
  acceptedCB,
  limitFor,
}: {
  acceptedCB: () => void
  limitFor: AppElements | keyof Limits
}) => {
  if (billingStore.clSubscription?.limits) {
    const limit = billingStore.clSubscription?.limits[AppLimits[limitFor]] || 0
    let accepted = false
    if (limitFor === AppElements.Broadcast) {
      accepted = broadcastsStore.broadcasts.length < limit
    }
    if (limitFor === AppElements.Campaign) {
      accepted = campaignsStore.campaigns.length < limit
    }
    if (limitFor === AppElements.Trigger) {
      accepted = triggersStore.triggers.length < limit
    }
    if (limitFor === AppElements.Keyword) {
      accepted = keywordsStore.keywords.length < limit
    }
    if (limitFor === AppElements.WebForm) {
      accepted = webFormStore.webForms.length < limit
    }
    if (limitFor === 'users') {
      accepted = teamStore.members.length < limit
    }

    if (accepted || !limit) {
      acceptedCB()
    } else {
      billingStore.setLimitModalFor(limitFor)
    }
  } else {
    acceptedCB()
  }
}
