import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'
import segmentStore from 'store/contacts/segment/segmentStore'
import { Filter } from 'store/contacts/segment/types'
import { useEffect } from 'react'

export const FilterConditionDropdown = observer(
  ({ filter }: { filter: Filter }) => {
    const optionsOperators: IOption[] = segmentStore.dataTypeConditions
      .get(filter.dataType)
      ?.map((item) => ({
        value: item.key,
        title: item.label,
      })) as IOption[]

    useEffect(() => {
      if (!filter.condition) {
        filter.setCondition(optionsOperators[0].value)
      }
    }, [filter.condition])
    return (
      <Dropdown
        options={optionsOperators}
        selectedValue={filter.condition}
        onSelectValue={filter?.setCondition}
      />
    )
  }
)
