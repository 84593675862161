import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import { useTriggersDetailContext } from 'src/routes/main/triggers/detail/triggerDetail'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'

export const LeftStickyContent = observer(() => {
  const triggersDetailStore = useTriggersDetailContext()
  return (
    <div className={styles.wrapRangePicker}>
      <RangeDatePicker
        rangeDatePickerStore={
          triggersDetailStore.tableStore.rangeDatePickerStore
        }
      />
    </div>
  )
})
