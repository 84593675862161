import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'
import { ReactComponent as StatusIcon } from 'icons/16px/Status/Status.svg'
import superAdminStore from 'store/superAdmin/superAdminStore'
import { BillingStatus, OrganizationStatus } from 'src/generated/graphql'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { capitalize } from 'lodash'

const getOptions = (
  statusEnum: typeof OrganizationStatus | typeof BillingStatus
): IOption[] =>
  Object.values(statusEnum).map((status) => ({
    title: (
      <StatusContent
        status={status}
        text={
          status === BillingStatus.PendingCancellation
            ? 'Pending Cancellation'
            : capitalize(status)
        }
        noIcon
      />
    ),
    value: status,
  }))

export const subscriptionStatusesOptions = [
  {
    title: (
      <StatusContent status={BillingStatus.Active} text={'Active'} noIcon />
    ),
    value: BillingStatus.Active,
  },
  {
    title: <StatusContent status={BillingStatus.Lead} text={'Lead'} noIcon />,
    value: BillingStatus.Lead,
  },
  {
    title: <StatusContent status={BillingStatus.Trial} text={'Trial'} noIcon />,
    value: BillingStatus.Trial,
  },
  {
    title: (
      <StatusContent status={BillingStatus.Canceled} text={'Canceled'} noIcon />
    ),
    value: BillingStatus.Canceled,
  },
  {
    title: (
      <StatusContent
        status={BillingStatus.PendingCancellation}
        text={'Pending Cancellation'}
        noIcon
      />
    ),
    value: BillingStatus.PendingCancellation,
  },
]

export const Filters = observer(() => {
  return (
    <div className={'row12'}>
      <Dropdown
        dropDownContentWidth={250}
        width={210}
        maxHeightDropdown={250}
        options={getOptions(OrganizationStatus)}
        selectedValues={superAdminStore.organizationStatuses}
        onMultiSelectValues={(values) =>
          superAdminStore.setOrganizationStatuses(values)
        }
        leftIcon={<StatusIcon />}
        multi
        unit={'status'}
        units={'statuses'}
        labelInput={'Organization status'}
      />
      <Dropdown
        dropDownContentWidth={250}
        width={210}
        maxHeightDropdown={250}
        options={subscriptionStatusesOptions}
        selectedValues={superAdminStore.billingStatuses}
        onMultiSelectValues={(values) =>
          superAdminStore.setSubscriptionStatuses(values)
        }
        leftIcon={<StatusIcon />}
        multi
        unit={'status'}
        units={'statuses'}
        labelInput={'Subscription status'}
      />
    </div>
  )
})
