import { useEffect } from 'react'
import user from 'src/store/user/user'
import companyStore from 'store/settings/company/companyStore'
import billingStore from 'store/settings/billing/billingStore'
import { ClStatus, Status } from 'src/generated/graphql'
import mainStore from 'store/mainStore'

export function useCanny() {
  useEffect(() => {
    if (companyStore.id) {
      const identifyWidget = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Canny('closeChangelog')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Canny('initChangelog', {
          appID: '65170ce5d7434e18223bc9bb',
          position: 'top',
          align: 'left',
          theme: 'light', // options: light [default], dark, auto
        })
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Canny('initChangelog', {
        appID: '65170ce5d7434e18223bc9bb',
        position: 'top',
        align: 'left',
        theme: 'light', // options: light [default], dark, auto
      })

      const userStatus = billingStore.clSubscription?.status || 'Lead'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Canny(
        'identify',
        {
          appID: '65170ce5d7434e18223bc9bb',
          user: {
            email: user.email,
            name: user.name,
            id: user.member?.uniqueId,
            created: user.member?.createdAt,
            companies: [
              {
                created: user.member?.organization?.createdAt,
                id: user.member?.organization?.id,
                monthlySpend: billingStore.clSubscription?.price?.priceValue,
                name: companyStore.companyName,
              },
            ],
            customFields: {
              plan: billingStore.clSubscription?.price?.planTitle,
              phone: user.member?.internationalPhone,
              completeRegister: !!billingStore.clSubscription,
              userStatus:
                userStatus === ClStatus.Trialing ? Status.Trial : userStatus,
              userRole: user.role,
            },
          },
        },
        identifyWidget
      )
    }
  }, [companyStore.id, mainStore.analyticTriggerUpdate])
}
