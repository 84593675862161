import styles from './styles.module.scss'
import React, { FC } from 'react'

type Props = {
  active: boolean
  onChange?: (value: boolean, e: React.ChangeEvent) => void
  tabIndex?: number
  disabled?: boolean
  marginInline?: number
}

export const Switch: FC<Props> = ({
  active,
  onChange,
  tabIndex,
  disabled,
  marginInline,
}) => {
  return (
    <input
      type="checkbox"
      className={styles.switch}
      checked={active}
      onChange={(e) => onChange && onChange(e.target.checked, e)}
      tabIndex={tabIndex}
      disabled={disabled}
      style={{ marginInline }}
    />
  )
}
