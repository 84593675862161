import { Dropdown } from 'components/Dropdown/Dropdown'
import React from 'react'
import { Maybe, useGetBusinessCountryStatesQuery } from 'src/generated/graphql'

export const StatesDropdown = ({
  disabled,
  state,
  setState,
  dropDownContentWidth,
  withRemove,
  countryCode,
  error,
}: {
  countryCode: string
  disabled?: boolean
  withRemove?: boolean
  state?: string
  setState: (state: string | null) => void
  dropDownContentWidth?: number
  error?: string
}) => {
  const { data } = useGetBusinessCountryStatesQuery({
    variables: {
      countryCode: countryCode,
    },
    skip: !countryCode,
  })
  const states = data?.getBusinessCountryStates || []

  const stateToOption = (state: Maybe<string>) => ({
    title: state || '',
    value: state || '',
    optionClass: 'p4_0',
  })
  return (
    <Dropdown
      disabled={disabled}
      fullWidth
      withSearch
      openOnFocus
      withNoResults
      dropDownContentWidth={dropDownContentWidth}
      labelInput={countryCode === 'CA' ? 'Province' : 'State'}
      options={states.map(stateToOption)}
      selectedValue={state}
      placeholder={
        countryCode === 'CA' ? 'e.g. Alberta' : 'e.g. California (CA)'
      }
      onSelectValue={(value) => setState(value)}
      onRemove={() => setState(null)}
      withRemove={withRemove}
      withNoData
      error={error}
    />
  )
}
