// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GYcTwVA12O0y49YSvoQL{display:flex;column-gap:12px;padding-top:40px}.YlNwUpq6vES4JQAcBqkA{border:none;outline:none;display:flex;align-items:center}.YlNwUpq6vES4JQAcBqkA:hover{background-color:var(--primary-color-gray-4);outline:none}.YlNwUpq6vES4JQAcBqkA .VSsWul1KbzySPFmebeYR{display:flex;align-items:center}.WV7Sb6yq4gCJeXUckICN{border:1px solid var(--primary-color-gray-3);border-radius:16px;padding:15px;margin-bottom:16px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/modals/ContinueCancelSubscriptionModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,gBAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,4BACE,4CAAA,CACA,YAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CAIJ,sBACE,4CAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".actions{\n  display: flex;\n  column-gap: 12px;\n  padding-top: 40px;\n}\n.checkbox{\n  border: none;\n  outline: none;\n  display: flex;\n  align-items: center;\n  &:hover{\n    background-color: var(--primary-color-gray-4);\n    outline: none;\n  }\n  .titleContent{\n    display: flex;\n    align-items: center;\n  }\n}\n\n.card{\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 16px;\n  padding: 15px;\n  margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `GYcTwVA12O0y49YSvoQL`,
	"checkbox": `YlNwUpq6vES4JQAcBqkA`,
	"titleContent": `VSsWul1KbzySPFmebeYR`,
	"card": `WV7Sb6yq4gCJeXUckICN`
};
export default ___CSS_LOADER_EXPORT___;
