export enum UnauthorizedRoutes {
  login = '/login',
  login2 = '/login2',
  invite = '/invite/:uid',
  forgot = '/forgot',
  signup = '/signup',
  ban = '/ban',
  migration = '/migration',
}

export enum NoCompleteRoutes {
  register1 = '/register1',
  register2 = '/register2',
}

export enum RoutesEnum {
  inviteExist = 'invite/existing/:id',
  newOrganization = '/new-organization',
  admin = '/admin',
  adminOrganizations = '/admin/organizations',
  adminImports = '/admin/imports',
  adminCompliance = '/admin/compliance',
  organizations = 'organizations',
  imports = 'imports',
  customPlans = 'customPlans',

  dashboard = '/dashboard',
  settings = '/settings',
  create = '/createMessage',

  broadcasts = '/broadcasts',
  broadcastDetail = '/broadcasts/:id',
  broadcastEdit = '/broadcasts/:id/edit',
  broadcastDuplicate = '/broadcasts/:id/duplicate',
  newBroadcast = '/broadcasts/new',

  campaigns = '/campaigns',
  campaignDetail = '/campaigns/:id',
  campaignEdit = '/campaigns/:id/edit',
  campaignDuplicate = '/campaigns/:id/duplicate',
  newCampaign = '/campaigns/new',

  triggers = '/triggers',
  triggerDetail = '/triggers/:id',
  triggerEdit = '/triggers/:id/edit',
  triggerDuplicate = '/triggers/:id/duplicate',
  newTrigger = '/triggers/new',

  keywords = '/keywords',
  keywordDetail = '/keywords/:id',
  keywordEdit = '/keywords/:id/edit',
  keywordDuplicate = '/keywords/:id/duplicate',
  newKeyword = '/keywords/new',

  webForms = '/webForms',
  webFormDetail = '/webForms/:id',
  webFormEdit = '/webForms/:id/edit',
  webFormDuplicate = '/webForms/:id/duplicate',
  newWebForm = '/webForms/new',

  //
  contacts = '/contacts',
  lists = 'lists',
  contactsLists = '/contacts/lists',
  listDetail = '/contacts/lists/:id',
  segments = 'segments',
  segmentsDetailRoutes = '/contacts/segments/*',
  segmentNew = '/contacts/segments/new',
  segmentDetail = '/contacts/segments/:id',
  contactsHistory = '/contacts/history',
  history = 'history',
  historyDetail = '/contacts/history/:id',

  automations = '/automations',
  account = 'account',
  profile = 'profile',
  privacy = 'privacy',
  company = 'company',
  compliance = 'compliance',
  settingsCompliance = '/settings/compliance',
  business = 'business',
  tollFreeVerification = 'toll-free-verification',
  A2P10DLCRegistration = 'A2P-10DLC-registration',
  team = 'team',
  numbers = 'numbers',
  billing = 'billing',
  integrations = 'integrations',
  settingsIntegrations = '/settings/integrations',
  audio = 'audio',
  help = 'help',
  plan = '/plan',
  reopen = 'reopen',
}

export const routeWithRedirect = (route: RoutesEnum) => '/' + route
