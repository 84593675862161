import { KeywordInput, Status } from 'src/generated/graphql'
import { smsMessageToSmsMessageInput } from 'store/textareaStore'
import { IKeyword } from 'src/types/IKeyword'

export function iKeywordToInput(keyword: IKeyword): KeywordInput {
  return {
    id: +keyword.id,
    name: keyword.name,
    listIds: keyword.contactsListsIds,
    smsMessage: keyword.smsMessage
      ? smsMessageToSmsMessageInput(keyword.smsMessage)
      : undefined,
    status: keyword.status || Status.Active,
    phoneNumberIds: keyword.keywordPhoneNumbers?.map((phone) => +phone?.id),
  }
}
