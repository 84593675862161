import { makeAutoObservable } from 'mobx'
import { ICustomField } from 'src/types/ICustomField'
import { generateField } from 'store/contacts/functions'
import { IOption } from 'src/types/IOption'
import { CustomFieldType } from 'src/generated/graphql'
import {
  iCustomFieldToOption,
  iCustomFieldToOptionWithIcon,
} from 'store/contacts/customFields/functions'

export const reservedNamesForCustomFields = [
  'First Name',
  'Last Name',
  'Email',
  'Phone',
]

export const reservedKeyForCustomFields = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
]

class CustomFieldsStore {
  constructor() {
    makeAutoObservable(this)
  }
  openCustomFieldsModal = false
  focusedCustomFieldId = ''
  isErrorAlreadyUsedName: Set<string> = new Set<string>()
  alreadyUsedNamesMap: Map<string, string> = new Map<string, string>()
  customFieldsMap: Map<string, ICustomField> = new Map<string, ICustomField>()
  customFieldsMapModal: Map<string, ICustomField> = new Map<
    string,
    ICustomField
  >()

  get alreadyUsedNames() {
    return Array.from(this.alreadyUsedNamesMap.entries()).map((entry) => ({
      id: entry[0],
      name: entry[1],
    }))
  }

  get disabledSave() {
    let disabled = false
    this.customFieldsMapModal.forEach((field) => {
      if (!field.name || !field.type) {
        disabled = true
      }
    })
    if (this.isErrorAlreadyUsedName.size) {
      disabled = true
    }
    return disabled
  }

  get orderCustomFields() {
    return this.customFieldsModal.map((filed) => filed.id)
  }

  get customFields() {
    return Array.from(this.customFieldsMap.values())
  }
  get customFieldsModal() {
    return Array.from(this.customFieldsMapModal.values())
  }
  get customFieldsFiltered() {
    return Array.from(this.customFieldsMap.values()).filter(
      (filed) => filed.name && filed.type
    )
  }

  get customFieldsOptions(): IOption<ICustomField>[] {
    const options: IOption[] = []
    this.customFields.forEach((field) => {
      if (field.name && field.type) {
        options.push(iCustomFieldToOption(field))
      }
    })
    return options
  }

  get customFieldsOptionsWithIcons(): IOption[] {
    const options: IOption[] = []
    this.customFields.forEach((field) => {
      if (field.name && field.type) {
        options.push(iCustomFieldToOptionWithIcon(field))
      }
    })
    return options
  }

  get customFieldsTimeOptions(): IOption[] {
    return this.customFieldsOptions.filter(
      (option) =>
        option.data?.type === CustomFieldType.Date ||
        option.data?.type === CustomFieldType.DateTime
    )
  }

  setOrderCustomFields(order: string[]) {
    const fields: ICustomField[] = []
    order.forEach((id) => {
      const field = this.customFieldsMapModal.get(id)
      if (field) {
        fields.push(field)
      }
    })
    this.customFieldsMapModal = new Map(
      fields.map((filed) => [filed.id, filed])
    )
  }

  setOpenCustomFieldsModalModal(state: boolean) {
    if (state) {
      this.customFieldsMapModal = new Map<string, ICustomField>(
        this.customFieldsMap
      )
      if (!this.customFieldsMapModal.size) {
        this.addCustomField()
      }
    }
    this.alreadyUsedNamesMap = new Map<string, string>(
      this.customFieldsModal.map((field) => [field.id, field.name])
    )
    this.openCustomFieldsModal = state
  }
  onSave() {
    this.customFieldsMapModal.forEach((field) => {
      if (!field.name || !field.type) {
        this.customFieldsMapModal.delete(field.id)
      }
    })

    this.customFieldsMap = new Map<string, ICustomField>(
      this.customFieldsMapModal
    )

    if (this.customFieldsMapModal.size === 0) {
      this.addCustomField()
    }
    this.openCustomFieldsModal = false
  }
  updateCustomField(filed: ICustomField) {
    const oldField = this.customFieldsMapModal.get(filed.id)
    if (oldField) {
      this.customFieldsMapModal.set(oldField.id, { ...oldField, ...filed })
    }
  }
  addCustomField() {
    const newField = generateField()
    this.focusedCustomFieldId = newField.id
    this.customFieldsMapModal.set(newField.id, newField)
  }
  deleteCustomFieldModal(id: string) {
    this.isErrorAlreadyUsedName.delete(id)
    this.customFieldsMapModal.delete(id)
    if (!this.customFieldsMapModal.size) {
      this.addCustomField()
    }
  }
  addAlreadyName(id: string, name: string) {
    this.alreadyUsedNamesMap.set(id, name)
  }
  addErrorAlreadyUsedName(id: string) {
    this.isErrorAlreadyUsedName.add(id)
  }
  removeErrorAlreadyUsedName(id: string) {
    this.isErrorAlreadyUsedName.delete(id)
  }
  getCustomNameByKey(key: string) {
    return this.customFieldsMap.get(key)?.name
  }
  setCustomFields(customFields: ICustomField[]) {
    this.customFieldsMap.clear()
    this.customFieldsMap = new Map(
      customFields.map((field) => [field.id, field])
    )
  }
}

export default new CustomFieldsStore()
