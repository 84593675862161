import { makeAutoObservable, reaction } from 'mobx'
import { ITrigger } from 'src/types/ITrigger'
import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import {
  ContentType,
  Direction,
  GetTriggerMessagesQuery,
  MessageStatisticsResponse,
} from 'src/generated/graphql'
import { IStatisticMessage } from 'src/types/IDetailMessage'
import { messageStatisticsColumns } from 'components/NewTable/columns/messageStatisticsColumns'

export class TriggersDetailStore {
  tableStore!: TableStore<IStatisticMessage>
  constructor() {
    makeAutoObservable(this)
  }

  triggerMessageStatistics: MessageStatisticsResponse | null = null
  statisticMessages: IStatisticMessage[] = []
  trigger: ITrigger | null = null

  get totalCharged() {
    return this.triggerMessageStatistics?.chargeStatistics?.totalCharged || 0
  }

  setTrigger = (trigger: ITrigger) => {
    this.trigger = trigger
    this.tableStore = new TableStore<IStatisticMessage>({
      columns: messageStatisticsColumns({
        type: trigger.type as ContentType,
        status: TableStatuses.totalCount,
        withShortStatistic:
          !!this.triggerMessageStatistics?.shortUrlClickStatistics
            ?.shortUrlVisits?.length,
      }),
      orderBy: 'state.timeToSend',
      order: Direction.Desc,
      tableName: 'detailTriggerTable',
      status: TableStatuses.totalCount,
      withDataRange: true,
    })
    reaction(
      () => this.tableStore.status,
      (status) => {
        this.tableStore.setColumns(
          messageStatisticsColumns({
            type: this.trigger?.type as ContentType,
            status,
            withShortStatistic:
              !!this.triggerMessageStatistics?.shortUrlClickStatistics
                ?.shortUrlVisits?.length,
          })
        )
      }
    )
  }

  setMessageStatistics = (
    messageStatistics: MessageStatisticsResponse | null
  ) => {
    this.triggerMessageStatistics = messageStatistics
    this.tableStore.setColumns(
      messageStatisticsColumns({
        type: this.trigger?.type as ContentType,
        status: this.tableStore.status,
        withShortStatistic:
          !!messageStatistics?.shortUrlClickStatistics?.shortUrlVisits?.length,
      })
    )
  }

  setMessages = (data: GetTriggerMessagesQuery) => {
    const iTriggerMessages: IStatisticMessage[] = []

    data.getTriggerMessages?.content?.forEach((message) => {
      if (message) {
        iTriggerMessages.push({
          id: message?.id,
          ...message,
        })
      }
    })
    this.statisticMessages = iTriggerMessages
  }
}
