import styles from './styles.module.scss'
import { ReactComponent as Google } from 'icons/Google.svg'
import user from 'store/user/user'
// import { ReactComponent as Delete } from 'icons/16px/Delete.svg'
// import { BtnSize, BtnType, Button } from 'components/Button/Button'
// import accountStore from 'src/routes/settings/account/utils/accountStore'
// import { googleLogout } from '@react-oauth/google'
import { Dispatch, SetStateAction } from 'react'
import { observer } from 'mobx-react-lite'

export const GoogleCard = observer(
  ({}: // setAutoLoad,
  {
    setAutoLoad: Dispatch<SetStateAction<boolean>>
  }) => {
    // const onLogoutSuccess = () => {
    //   user.deleteConnectingWithGoogle()
    // }
    // const onVerifyDelete = () => {
    //   googleLogout()
    //   onLogoutSuccess()
    // }
    // const onVerifyChange = () => {
    //   googleLogout()
    //   onLogoutSuccess()
    //   setAutoLoad(true)
    // }
    // const handleDelete = () => {
    //   accountStore.setOpenVerifyNumberModal(true, {
    //     title: 'Verify your identity',
    //     info1: `We’ve sent it to ${user.email}`,
    //     info2: 'Didn’t receive the code?',
    //     size: 6,
    //     onVerify: onVerifyDelete,
    //   })
    // }
    // const handleChange = () => {
    //   accountStore.setOpenVerifyNumberModal(true, {
    //     title: 'Verify your identity',
    //     info1: `We’ve sent it to ${user.email}`,
    //     info2: 'Didn’t receive the code?',
    //     size: 6,
    //     onVerify: onVerifyChange,
    //   })
    // }
    return (
      <div className={styles.wrap}>
        <div className={styles.icon}>
          <Google />
        </div>
        <div className={styles.info}>
          <span className={'medium'}>Connected with Google</span>
          <span className={'s2 blue3'}>{user.email}</span>
        </div>
        {/*{user.isEmailAuth ? (*/}
        {/*  <Button*/}
        {/*    size={BtnSize.small}*/}
        {/*    typeBtn={BtnType.secondaryGray}*/}
        {/*    onClick={handleDelete}*/}
        {/*  >*/}
        {/*    <Delete />*/}
        {/*  </Button>*/}
        {/*) : (*/}
        {/*  <Button*/}
        {/*    size={BtnSize.small}*/}
        {/*    typeBtn={BtnType.secondaryGray}*/}
        {/*    onClick={handleChange}*/}
        {/*  >*/}
        {/*    Change*/}
        {/*  </Button>*/}
        {/*)}*/}
      </div>
    )
  }
)
