import styles from './styles.module.scss'
import { SizedBox } from 'components/SizedBox'
import importContacts from 'assets/images/importContacts.png'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as ArrowRight } from 'icons/16px/Arrow-Right.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { IObjective } from 'store/dashboard/objectives/types'
import objectivesStore from 'store/dashboard/objectives/objectivesStore'
import { RoutesEnum } from 'src/routes/routes'
import { observer } from 'mobx-react-lite'
import { DashboardRoutesEnum } from 'src/routes/main/dashboard/dashboardRoutes'
import { useRef } from 'react'

export const ActiveObjectiveContent = observer(() => {
  const { step } = useParams()
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const activeObjective: IObjective | undefined =
    objectivesStore.objectivesMap.get(Number(step))
  if (!activeObjective) {
    navigate(RoutesEnum.dashboard)
    return <></>
  }
  const { lessons, currentLesson } = activeObjective
  const onScrollTop = () => {
    if (ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }
  const onNextLesson = () => {
    objectivesStore.changeObjective({
      ...activeObjective,
      currentLesson: Number(currentLesson + 1),
    })
    onScrollTop()
  }
  const onNextCourse = () => {
    step &&
      navigate(
        RoutesEnum.dashboard +
          '/' +
          DashboardRoutesEnum.objective +
          '/' +
          (Number(step) + 1)
      )
    onScrollTop()
  }
  const onComplete = () => {
    objectivesStore.changeObjective({
      ...activeObjective,
      lessons: lessons.map((lesson, index) =>
        index === currentLesson ? { ...lesson, complete: true } : lesson
      ),
    })
  }
  const lesson = lessons[currentLesson]
  return (
    <div className={styles.layout} ref={ref}>
      <div className={styles.wrap}>
        <iframe
          width="664"
          height="376"
          src="https://www.youtube.com/embed/1nYFPzh3yJM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <SizedBox height={48} />
        <div className={styles.content}>
          <h2>{lesson?.title}</h2>
          <SizedBox height={32} />
          <p className={'par0'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat
            convallis dui sed commodo. Proin molestie consectetur leo sed
            laoreet. Maecenas aliquet lectus eget tellus porta, nec scelerisque
            lorem tincidunt.
          </p>
          <SizedBox height={32} />
          <p className={'par0'}>
            Etiam iaculis, erat convallis dictum suscipit, purus dolor eleifend
            erat, non aliquet nulla eros at nisi. In auctor vitae metus a
            sagittis.
          </p>
          <SizedBox height={48} />
          <h3 className={'bold'}>Title lorem ipsum sit amet</h3>
          <SizedBox height={16} />
          <p className={'par0'}>
            Etiam at vestibulum dolor. Mauris fermentum massa sed dolor
            vestibulum, id rhoncus quam malesuada. In mollis congue metus sed
            congue. Nunc nec tortor lacus. Vestibulum eleifend metus at erat
            posuere, et efficitur massa molestie.
          </p>
          <SizedBox height={40} />
          <img src={importContacts} alt="" />
          <SizedBox height={40} />
          <p className={'par0'}>
            Cras a faucibus lectus. Pellentesque habitant morbi tristique
            senectus et netus et malesuada fames ac turpis egestas. Vestibulum
            quis neque erat. Integer rhoncus tempus nulla, vel congue libero
            facilisis id. Fusce sed ipsum vel sapien pellentesque lobortis. Ut
            purus nibh, ullamcorper sit amet posuere quis, pretium ut sapien:
          </p>
          <SizedBox height={48} />
          <div className={styles.wrapActions}>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={onComplete}
            >
              Mark as completed
            </Button>
            {currentLesson + 1 === lessons.length ? (
              <Button
                typeBtn={BtnType.secondaryGray}
                size={BtnSize.medium}
                onClick={onNextCourse}
              >
                Next course
                <ArrowRight />
              </Button>
            ) : (
              <Button
                typeBtn={BtnType.secondaryGray}
                size={BtnSize.medium}
                onClick={onNextLesson}
              >
                Next lesson
                <ArrowRight />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
