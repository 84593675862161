import { observer } from 'mobx-react-lite'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { Alert } from 'components/Alert/Alert'
import billingStore from 'store/settings/billing/billingStore'
import companyStore from 'store/settings/company/companyStore'

export const CarrierFeeFailureAlert = observer(() => {
  const onPayNow = () => {
    billingStore.setOpenUpdateCardModal(true)
  }
  if (companyStore.organization?.carrierFeePaymentFailed) {
    return (
      <Alert
        type={AlertTypeEnum.error}
        text={
          <>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <b>Houston we have a problem.</b> There's an issue with your
            payment. Please update your card on file and complete your payment.
          </>
        }
        global
        rightContent={
          <button className={'s1 bold white'} onClick={onPayNow}>
            Pay now
          </button>
        }
      />
    )
  }
  return null
})
