import { CustomField, GetCustomFieldsQuery } from 'src/generated/graphql'
import customFieldsStore, {
  reservedKeyForCustomFields,
} from 'store/contacts/customFields/customFieldsStore'
import { transformCustomFieldsFromBack } from 'store/contacts/customFields/functions'
import { useEffect } from 'react'
import { ICustomField } from 'src/types/ICustomField'

export function useSetCustomFields(data?: GetCustomFieldsQuery) {
  useEffect(() => {
    if (data?.getCustomFields) {
      const customFields: CustomField[] = []
      data?.getCustomFields?.forEach((field) => {
        if (
          field &&
          field.key &&
          !reservedKeyForCustomFields.includes(field.key)
        ) {
          customFields.push(field)
        }
      })
      const iCustomFields: ICustomField[] =
        transformCustomFieldsFromBack(customFields)
      customFieldsStore.setCustomFields(iCustomFields)
    }
  }, [data])
}
