import { makeAutoObservable } from 'mobx'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import { ContactBulkOperationRequestInput } from 'src/generated/graphql'

class EditContactListsStore {
  dropdownContactListsStore: DropdownContactListsStore
  constructor() {
    makeAutoObservable(this)
    this.dropdownContactListsStore = new DropdownContactListsStore()
  }
  bulk: ContactBulkOperationRequestInput | null = null

  get openEditListsModal() {
    return !!this.bulk
  }

  setEditListsModalContactIds = (
    bulk: ContactBulkOperationRequestInput | null
  ) => {
    this.bulk = bulk
  }
}

export default new EditContactListsStore()
