import { ICampaign } from 'src/types/ICampaign'
import { numberString } from 'src/util/functions'
import { ColumnType } from 'components/NewTable/types'

export const columnsDetailOverview: ColumnType<ICampaign>[] = [
  { id: 'name', label: 'Name' },
  {
    id: 'total',
    label: 'Total',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.totalCount }),
  },
  {
    id: 'success',
    label: 'Success',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.successCount }),
  },
  {
    id: 'shortUrlClickStatistics',
    label: 'Clicks',
    format: (value, { shortUrlClickStatistics }) =>
      numberString({ val: shortUrlClickStatistics?.totalVisits || 0 }),
  },
  {
    id: 'pending',
    label: 'Pending',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.pendingCount }),
  },

  // {
  //   id: 'clickedLink',
  //   label: 'Clicked link',
  //   format: (value) => numberString({ val: +value || 0 }),
  // },
  // {
  //   id: 'liveAnswer',
  //   label: 'Live answer',
  //   format: (value) => numberString({ val: +value || 0 }),
  // },
  // {
  //   id: 'voicemail',
  //   label: 'Voicemail',
  //   format: (value) => numberString({ val: +value || 0 }),
  // },
  {
    id: 'failed',
    label: 'Failed',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.failedCount }),
  },
  {
    id: 'unsubscribed',
    label: 'Unsubscribed',
    format: (value, { messagesStat }) =>
      numberString({ val: +messagesStat?.unsubscribedCount }),
  },
]
