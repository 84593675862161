// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.px6z864qEM_q3rbh_Yff{max-height:232px;overflow-y:scroll;margin-right:-6px}.px6z864qEM_q3rbh_Yff::-webkit-scrollbar,.px6z864qEM_q3rbh_Yff::-webkit-scrollbar-track{width:4px;height:4px;border-radius:3px}.c_2VTPO3jOd8Q6QbqV7B{height:100%;vertical-align:middle;margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/Modals/CreateTextToSpeechModal/components/ChooseVoice/styles.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AACA,sBACE,gBAAA,CACA,iBAAA,CAEA,iBAAA,CC8CA,wFACE,SAAA,CACA,UAAA,CACA,iBAAA,CD7CJ,sBACE,WAAA,CACA,qBAAA,CACA,gBAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrapItems{\n  max-height: 232px;\n  overflow-y: scroll;\n  @include scroll-4;\n  margin-right: -6px;\n\n}\n\n.wrapPlay{\n  height: 100%;\n  vertical-align: middle;\n  margin-right: 8px;\n}","@mixin svg-color($color){\n  svg {\n    g {\n      path {\n        fill: $color;\n      }\n    }\n\n  }\n}\n@mixin svg-color-rect($color){\n  svg {\n    g {\n      rect {\n        fill: $color;\n      }\n    }\n\n  }\n}\n\n@mixin svg-color-stroke($color){\n  svg{\n    path{\n      stroke: $color;\n    }\n  }\n}\n@mixin svg-color-circle($color){\n  svg{\n    circle{\n      fill: $color;\n    }\n  }\n}\n@mixin svg-color-fill($color){\n  svg{\n    path{\n      fill: $color;\n    }\n  }\n}\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapItems": `px6z864qEM_q3rbh_Yff`,
	"wrapPlay": `c_2VTPO3jOd8Q6QbqV7B`
};
export default ___CSS_LOADER_EXPORT___;
