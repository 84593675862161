import { EveryPeriodEnum } from 'components/Schedule/store/types'
import {
  CampaignDelayPeriod,
  CampaignScheduleType,
  CustomMonthRepeatType,
  DayOfWeek,
  OnMonthDayOrdinal,
  OnMonthDayType,
  RepeatType,
  SendLaterRepeatType,
} from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'

export enum ScheduleVariants {
  broadcast,
  campaign,
}

export const operatorsOptions: IOption<CampaignScheduleType>[] = [
  {
    title: 'Before',
    value: CampaignScheduleType.Before,
  },
  {
    title: 'On',
    value: CampaignScheduleType.On,
  },
  {
    title: 'After',
    value: CampaignScheduleType.After,
  },
]

export const periodsOptions: IOption<CampaignDelayPeriod>[] = [
  {
    title: 'Minute',
    value: CampaignDelayPeriod.Minute,
  },
  {
    title: 'Hour',
    value: CampaignDelayPeriod.Hour,
  },
  {
    title: 'Day',
    value: CampaignDelayPeriod.Day,
  },
  {
    title: 'Week',
    value: CampaignDelayPeriod.Week,
  },
  {
    title: 'Month',
    value: CampaignDelayPeriod.Month,
  },
  {
    title: 'Year',
    value: CampaignDelayPeriod.Year,
  },
]
export const repeatsOptions: IOption[] = [
  {
    title: 'Daily',
    value: 'daily',
  },
  {
    title: 'Weekly',
    value: 'weekly',
  },
  {
    title: 'Monthly',
    value: 'monthly',
  },
]
export const everyPeriodOptions: IOption<RepeatType>[] = [
  {
    title: EveryPeriodEnum.Day,
    value: RepeatType.Daily,
  },
  {
    title: EveryPeriodEnum.Week,
    value: RepeatType.Weekly,
  },
  {
    title: EveryPeriodEnum.Month,
    value: RepeatType.Monthly,
  },
]
export const everyPeriodOptionsMap = new Map(
  everyPeriodOptions.map((period) => [period.value, period])
)
export const repeatsOptions2: IOption<SendLaterRepeatType>[] = [
  {
    title: 'Never',
    value: SendLaterRepeatType.Never,
  },
  {
    title: 'Every day',
    value: SendLaterRepeatType.EveryDay,
  },
  {
    title: 'Every week',
    value: SendLaterRepeatType.EveryWeek,
  },
  {
    title: 'Every month',
    value: SendLaterRepeatType.EveryMonth,
  },
  {
    title: 'Custom',
    value: SendLaterRepeatType.Custom,
  },
]

export const repeatsOptions2map = new Map(
  repeatsOptions2.map((opt) => [opt.value, opt])
)
export const onTheOrderOptions: IOption[] = [
  {
    title: 'First',
    value: OnMonthDayOrdinal.First,
  },
  {
    title: 'Second',
    value: OnMonthDayOrdinal.Second,
  },
  {
    title: 'Third',
    value: OnMonthDayOrdinal.Third,
  },
  {
    title: 'Fourth',
    value: OnMonthDayOrdinal.Fourth,
  },
  {
    title: 'Last',
    value: OnMonthDayOrdinal.Last,
  },
]
export const monthlyToggleOptions: IOption[] = [
  {
    title: 'Each',
    value: CustomMonthRepeatType.Each,
  },
  {
    title: 'On the',
    value: CustomMonthRepeatType.OnThe,
  },
]
export const DaysWeeklyStringObj = [
  {
    key: DayOfWeek.Sunday,
    short: 'Su',
    full: 'Sunday',
  },
  {
    key: DayOfWeek.Monday,
    short: 'Mo',
    full: 'Monday',
  },
  {
    key: DayOfWeek.Tuesday,
    short: 'Tu',
    full: 'Tuesday',
  },
  {
    key: DayOfWeek.Wednesday,
    short: 'We',
    full: 'Wednesday',
  },
  {
    key: DayOfWeek.Thursday,
    short: 'Th',
    full: 'Thursday',
  },
  {
    key: DayOfWeek.Friday,
    short: 'Fr',
    full: 'Friday',
  },
  {
    key: DayOfWeek.Saturday,
    short: 'Sa',
    full: 'Saturday',
  },
]
export const DaysWeeklyStringObjMap = new Map(
  DaysWeeklyStringObj.map((day) => [day.key, day])
)
export const onTheDayOptions: IOption<OnMonthDayType>[] = [
  {
    title: 'Sunday',
    value: OnMonthDayType.Sunday,
  },
  {
    title: 'Monday',
    value: OnMonthDayType.Monday,
  },
  {
    title: 'Tuesday',
    value: OnMonthDayType.Tuesday,
  },
  {
    title: 'Wednesday',
    value: OnMonthDayType.Wednesday,
  },
  {
    title: 'Thursday',
    value: OnMonthDayType.Thursday,
  },
  {
    title: 'Friday',
    value: OnMonthDayType.Friday,
  },
  {
    title: 'Saturday',
    value: OnMonthDayType.Saturday,
  },
  {
    title: 'Day',
    value: OnMonthDayType.Day,
  },
]
export const DaysWeekly = Array(7)
  .fill(0)
  .map((val, index) => index)
export const DaysMonthly = Array(31)
  .fill(0)
  .map((val, index) => index + 1)

const dayOfWeekToIndex = (days: Array<DayOfWeek | null>) => {
  const indexes: number[] = []
  const obj = {
    [DayOfWeek.Sunday]: 0,
    [DayOfWeek.Monday]: 1,
    [DayOfWeek.Tuesday]: 2,
    [DayOfWeek.Wednesday]: 3,
    [DayOfWeek.Thursday]: 4,
    [DayOfWeek.Friday]: 5,
    [DayOfWeek.Saturday]: 6,
  }
  days.forEach((day) => {
    if (day !== null) {
      indexes.push(obj[day])
    }
  })
  return indexes
}

export const getChainDaysString = (days: Array<DayOfWeek | null>) => {
  const daysIndexes = dayOfWeekToIndex(days)
  const sortDays = [...daysIndexes].sort()
  const replacer = sortDays
    .map((day, index) => {
      if (sortDays[index + 1] - day === 1 && day - sortDays[index - 1] === 1) {
        return '-'
      }
      return day
    })
    .join('')
    .replace(/-----|----|---|--/g, '-')
    .split('')
  return replacer
    .map((day) => {
      if (day !== '-') {
        return DaysWeeklyStringObj[+day].short
      } else {
        return '-'
      }
    })
    .join(', ')
    .replace(/, -,/g, ' -')
}
