import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { DatePicker } from 'components/DatePicker/DatePicker'
import { TimePicker } from 'components/TimePicker/TimePicker'
import dayjs from 'lib/dayjs'
import { CampaignScheduleStore } from 'components/Schedule/store/campaignScheduleStore'
import { ScheduleContentWrapper } from 'components/Schedule/ScheduleContentWrapper/ScheduleContentWrapper'

type Props = {
  scheduleStore: CampaignScheduleStore
}

export const CalendarDateContent: FC<Props> = observer(({ scheduleStore }) => {
  useEffect(() => {
    if (!scheduleStore.calendarDate) {
      scheduleStore.setCalendarDate(
        dayjs().startOf('day').add(12, 'hours').toDate()
      )
    }
  }, [scheduleStore.activeCalendarDate])

  if (!scheduleStore.activeCalendarDate) {
    return <></>
  }
  return (
    <ScheduleContentWrapper>
      <DatePicker
        startDate={scheduleStore.calendarDate}
        onChange={(date) => scheduleStore.setCalendarDate(date)}
      />
      <TimePicker
        startTime={scheduleStore.calendarDate}
        onChange={(time) => scheduleStore.setCalendarDate(time)}
      />
    </ScheduleContentWrapper>
  )
})
