import { CampaignDelayPeriod, SendLaterRepeatType } from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'

export interface ISchedule {
  laterDate: Date | null
  laterCount: number
  laterPeriod: IOption<CampaignDelayPeriod>
  laterAdvanced: boolean
  laterAdvancedTime: Date | null
  everyCount: number
  sendLaterConfigRepeatType: SendLaterRepeatType
  repeatMonthlyToggle: IOption
  selectedOnTheOrderOptions: IOption
  selectedOnTheDayOptions: IOption
  endingDate: Date | null
  onlyImmediatelyBetween: boolean
  betweenStartTime: Date | null
  betweenEndTime: Date | null
  sendTimeString: string
}

export enum ActiveScheduleItemEnum {
  activeSendImmediately,
  activeSendLater,
}

export enum EndingEnum {
  never,
  endingDate,
}

export enum EveryPeriodEnum {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}
