import styles from './styles.module.scss'
import { FC } from 'react'
import parse from 'html-react-parser'
import { IPreviewMessage } from 'store/broadcasts/createBroadCastStore'
import { ImagesPreview } from 'components/Preview/ImagesPreview'
import { VCardGallery } from 'components/VCard/VCardGallery/VCardGallery'
import { textToHtml } from 'src/util/functions'

export interface IMessage {
  phone: string
  message?: IPreviewMessage
  text?: string
  company?: string
  placeholder?: string
}

export const MessagePreview: FC<IMessage> = ({
  phone,
  text,
  company,
  message,
  placeholder,
}) => {
  const isMessage =
    !message?.messageHtml &&
    !(message?.complianceText && message?.compliance) &&
    !message?.vCards?.length
  return (
    <>
      {message?.answer && (
        <div className={styles.answerWrap}>
          <div className={styles.answer}>
            <div className={styles.text}>{message.answer}</div>
          </div>
        </div>
      )}
      {(!isMessage || placeholder) && (
        <div className={styles.message}>
          <div className={styles.number}>{phone}</div>
          {!isMessage ? (
            <div className={styles.text}>
              {company && `${company}: `}
              {text && parse(text)}
              {message && parse(textToHtml(message.messageHtml, true, true))}
              {message?.compliance && ' ' + message.complianceText}
            </div>
          ) : (
            <div className={'s3 medium gray2'}>{placeholder}</div>
          )}
        </div>
      )}
      {!!message?.vCards?.length &&
        message.vCards.map((vCard) => (
          <VCardGallery key={vCard.id} width={155} vCard={vCard} />
        ))}
      <ImagesPreview images={message?.attachImages} />
    </>
  )
}
