import { ActionBtnProps } from 'components/NewTable/types'
import { IBroadcast } from 'src/types/IBroadcast'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { IIconType } from 'components/AppIcon/AppIcon'
import { useBroadcastFunctions } from 'src/routes/main/broadcasts/hooks/useBroadcastFunctions'

export const BroadcastPauseBtn = (props: ActionBtnProps<IBroadcast>) => {
  const { row, rows } = props
  const { onPause } = useBroadcastFunctions(row, rows)
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.pause}
      onAction={onPause}
      iconType={IIconType.circle}
    />
  )
}
