import { IntegrationsHeader } from 'src/routes/settings/integrations/header/IntegrationsHeader'
import { IntegrationsContent } from 'src/routes/settings/integrations/content/IntegrationsContent'
import { CallLoopApiKeyModal } from 'src/routes/settings/integrations/modal/CallLoopApiKeyModal/CallLoopApiKeyModal'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { useSetIntegrations } from 'src/routes/settings/integrations/hooks/useSetIntegrations'
import { ErrorIntegrationsModal } from 'src/routes/settings/integrations/modal/ErrorIntegrationsModal/ErrorIntegrationsModal'
import { CommonIntegrationsModal } from 'src/routes/settings/integrations/modal/connectIntegrationsModal/CommonIntegrationsModal'
import { CustomIntegrationModal } from 'src/routes/settings/integrations/modal/CustomIntegrationModal/CustomIntegrationModal'

export const Integrations = () => {
  const { loading } = useSetIntegrations()
  if (loading) {
    return <AbsoluteLoader size={40} />
  }
  return (
    <div className={'small-container2 full-height'}>
      <CommonIntegrationsModal />
      <CustomIntegrationModal />
      <ErrorIntegrationsModal />
      <CallLoopApiKeyModal />
      <IntegrationsHeader />
      <IntegrationsContent />
    </div>
  )
}
