import { Separator } from 'components/Separator/Separator'
import styles from './styles.module.scss'
import { IColor } from 'styles/colors'

export const HorizontalDivider = ({ color }: { color?: IColor }) => (
  <div className={styles.wrap}>
    <Separator color={color} />
    <span className={'gray1'}>or</span>
    <Separator color={color} />
  </div>
)
