import { useEffect } from 'react'
import { root } from 'src/util/globalVariables'

const modalRoot = document.getElementById('modal-root')

export function useOverlayClick(
  onOverlayClick: () => void,
  isOpen: boolean,
  parentElement?: HTMLDivElement
) {
  useEffect(() => {
    const childrenCount = modalRoot?.children.length
    if (isOpen) {
      if (!childrenCount) {
        return
      }
      if (childrenCount === 1 && parentElement) {
        parentElement.addEventListener('click', onOverlayClick)
      } else if (childrenCount === 1 && root) {
        root.addEventListener('click', onOverlayClick)
      } else {
        modalRoot.children[childrenCount - 2].addEventListener(
          'click',
          onOverlayClick
        )
      }
    } else {
      if (!childrenCount) {
        return
      }

      if (childrenCount === 1 && root) {
        root.removeEventListener('click', onOverlayClick)
      } else {
        modalRoot.children[childrenCount - 2] &&
          modalRoot.children[childrenCount - 2].removeEventListener(
            'click',
            onOverlayClick
          )
      }
    }
    return () => {
      if (!isOpen) {
        if (!childrenCount) {
          return
        }
        if (childrenCount === 1 && root) {
          root.removeEventListener('click', onOverlayClick)
        } else {
          modalRoot.children[childrenCount - 2] &&
            modalRoot.children[childrenCount - 2].removeEventListener(
              'click',
              onOverlayClick
            )
        }
      }
    }
  }, [isOpen])
}
