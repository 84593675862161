import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { ClassesEnum } from 'styles/classes'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import { ReactComponent as More } from 'icons/16px/More.svg'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { useWebFormFunctions } from 'src/routes/main/webForms/hooks/useWebFormFunctions'
import { useWebFormDetailContext } from 'src/routes/main/webForms/detail/webFormDetailPage'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'

export const MoreBtnWebForm = observer(() => {
  const { id } = useParams()
  const { webForm } = useWebFormDetailContext()
  const { showTT, setShowTT, trigger, setTrigger, onTriggerClick } =
    useFixedTooltip()
  const { onDuplicate, onEdit, onDelete } = useWebFormFunctions(webForm)
  const onBtnClick = () => {
    setShowTT(false)
  }

  const actions = useMemo(() => {
    if (!webForm) return <></>

    return (
      <>
        <MenuBtn
          type={MenuEnum.edit}
          onClick={() => {
            onBtnClick()
            onEdit()
          }}
        />
        <MenuBtn
          type={MenuEnum.duplicate}
          onClick={() => {
            onBtnClick()
            onDuplicate()
          }}
        />
        {/*<MenuBtn*/}
        {/*  type={MenuEnum.analytics}*/}
        {/*  onClick={() => {*/}
        {/*    onBtnClick()*/}
        {/*  }}*/}
        {/*/>*/}
        <MenuBtn
          type={MenuEnum.delete}
          onClick={() => {
            onBtnClick()
            id && onDelete([id])
          }}
        />
      </>
    )
  }, [webForm])
  return (
    <>
      <FixedTooltip
        visible={showTT}
        trigger={trigger}
        width={148}
        white
        noArrow
        position={TTPositionEnum.bottomRight}
        globalClasses={[ClassesEnum.p8]}
        addLeftPosition={8}
      >
        {actions}
      </FixedTooltip>
      <div ref={setTrigger}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          className={styles.btn}
          onClick={onTriggerClick}
          icon
        >
          <More />
        </Button>
      </div>
    </>
  )
})
