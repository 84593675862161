import { SizedBox } from 'components/SizedBox'
import { observer } from 'mobx-react-lite'

import { Step1 } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/Step1'
import { RangeBar } from 'components/RangeBar/RangeBar'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import { Step2 } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/Step2'
import { Step3 } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/Step3'
import { Step4 } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/Step4'
import { Step5 } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/Step5'
import { Step6 } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/Step6'
import { Step7 } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/Step7/Step7'

export const A2P10DLCRegistrationContent = observer(() => {
  return (
    <>
      <span className={'gray1 letter1'}>A2P 10DLC registration</span>
      <SizedBox height={24} />
      <div className={'row-between'}>
        <h3>
          <b>{a2p10DLCStore.stepTitle}</b>
        </h3>
        <span className={'s2 medium gray1'}>
          Step {a2p10DLCStore.step} / {a2p10DLCStore.stepCount}
        </span>
      </div>
      <SizedBox height={16} />
      <RangeBar progress={a2p10DLCStore.progressPercent} style2 />
      <SizedBox height={a2p10DLCStore.step > 2 ? 40 : 32} />
      {a2p10DLCStore.step === 1 && <Step1 />}
      {a2p10DLCStore.step === 2 && <Step2 />}
      {a2p10DLCStore.step === 3 && <Step3 />}
      {a2p10DLCStore.step === 4 && <Step4 />}
      {a2p10DLCStore.step === 5 && <Step5 />}
      {a2p10DLCStore.step === 6 && <Step6 />}
      {a2p10DLCStore.step === 7 && <Step7 />}
    </>
  )
})
