export enum OperatorsEnum {
  is = 'is',
  isAnyOf = 'is any of',
  isNoneOf = 'is none of',
  isEqualToAllOf = 'is equal to all of',
  isEqualToAnyOf = 'is equal to any of',
  isNotEqualToAllOf = 'is not equal to all of',
  isNotEqualToAnyOf = 'is not equal to any of',
  isNot = 'is not',
  contains = 'contains',
  containsAnyOf = 'contains any of',
  doesNotContainAnyOf = 'does not contain any of',
  startsWith = 'starts with',
  startsWithAnyOf = 'starts with any of',
  endsWith = 'ends with',
  endsWithAnyOf = 'ends with any of',
  doesNotContain = 'does not contain',
  isUnknown = 'is unknown',
  isKnown = 'is known',
  hasAnyValue = 'has any value',
  before = 'before',
  on = 'on',
  after = 'after',
  between = 'between',
  notBetween = 'not between',
  moreThan = 'more than',
  exactly = 'exactly',
  lessThan = 'less than',
}
