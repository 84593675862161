import { Route, Routes } from 'react-router-dom'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { NoCompleteRoutes } from 'src/routes/routes'
import { RegisterStep1 } from 'src/routes/auth/signUp/registerStep1'
import { RegisterStep2 } from 'src/routes/auth/signUp/registerStep2'
import { NoMatch } from 'src/routes/noMatch'
import { AppRoute, renderRoute } from 'src/util/route'

const routes: AppRoute[] = [
  {
    path: NoCompleteRoutes.register1,
    Element: RegisterStep1,
  },
  {
    path: NoCompleteRoutes.register2,
    Element: RegisterStep2,
  },
]

export const NoCompleteRegister = () => {
  return (
    <Routes>
      <Route element={<AuthPage />}>
        {routes.map(renderRoute)}
        <Route
          path="*"
          element={<NoMatch redirectRoute={NoCompleteRoutes.register1} />}
        />
      </Route>
    </Routes>
  )
}
