import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { MemberResponseFragment } from 'src/generated/graphql'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import contactStore from 'store/contacts/contactStore'
import { IContactList } from 'src/types/IContactList'
import { contactListToOption } from 'store/contacts/functions'

class TeamStore {
  dropdownContactListsStore: DropdownContactListsStore
  constructor() {
    makeAutoObservable(this)
    this.dropdownContactListsStore = new DropdownContactListsStore()
    reaction(
      () => contactStore.contactLists,
      () => {
        if (this.openMemberModal) {
          if (this.editMember) {
            const contactLists: IContactList[] = []
            this.editMember?.invitationBid?.listIds?.forEach((id) => {
              const list = contactStore.contactListsMap.get(String(id))
              if (list) {
                contactLists.push(list)
              }
            })
            runInAction(() => {
              this.dropdownContactListsStore.selectedOptions =
                contactLists.map(contactListToOption)
            })
          }
        }
      }
    )
  }
  refreshTrigger = ''
  sentInvite: {
    email: string
    id: number
    isResent?: boolean
  } | null = null
  membersMap: Map<number, MemberResponseFragment> = new Map()

  openMemberModal = false

  editMemberId: number | null = null

  get editMember() {
    if (this.editMemberId) {
      return this.membersMap.get(this.editMemberId)
    }
    return undefined
  }

  get members() {
    return Array.from(this.membersMap.values())
  }
  setOpenMemberModal(state: boolean, memberId: number | null = null) {
    this.openMemberModal = state
    this.editMemberId = memberId
    if (state) {
      if (this.editMember) {
        const contactLists: IContactList[] = []
        this.editMember.invitationBid?.listIds?.forEach((id) => {
          const list = contactStore.contactListsMap.get(String(id))
          if (list) {
            contactLists.push(list)
          }
        })
        this.dropdownContactListsStore.selectedOptions =
          contactLists.map(contactListToOption)
      }
    }
  }
  onRefresh() {
    this.refreshTrigger = nanoid()
  }
  setSentInvite(invite: typeof this.sentInvite) {
    this.sentInvite = invite
    this.onRefresh()
  }

  setMembers(members: MemberResponseFragment[]) {
    members.forEach((member) => {
      this.membersMap.set(member.id, member)
    })
  }
  removeMember(id: number) {
    this.membersMap.delete(id)
  }
}

export default new TeamStore()
