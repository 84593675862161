import React, { useEffect, useMemo, useState } from 'react'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ReactComponent as TestSMS } from 'icons/16pxNoMask/Test-it SMS.svg'
import { ReactComponent as TestItAudio } from 'icons/16pxNoMask/Test-it Audio.svg'
import { TestPhoneInputComponent } from 'components/TestItBtn/TestPhoneInputComponent'
import { SendTestTooltip } from 'components/TestItBtn/SendTestTooltip'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import {
  ContentType,
  TestCallMessageRequestInput,
  TestMessageRequestInput,
  TestRinglessMessageRequestInput,
  useSendTestCallMessageMutation,
  useSendTestMessageMutation,
  useSendTestRinglessMessageMutation,
} from 'src/generated/graphql'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import billingStore from 'store/settings/billing/billingStore'
import { observer } from 'mobx-react-lite'

export const TestItBtn = observer(
  ({
    textTooltip,
    fromTable,
    setHover,
    fromAudio,
    fromCreate,
    setOpenTest,
    openTest,
    tabIndex,
    withoutAction,
    widthInput,
    testTooltipPosition,
    testMessageInput,
    testCallMessageRequestInput,
    testRinglessMessageRequestInput,
    type,
    medium,
    staticContent,
    onSuccess,
  }: {
    textTooltip?: string | JSX.Element
    fromTable?: boolean
    fromAudio?: boolean
    fromCreate?: boolean
    medium?: boolean
    type?: ContentType
    openTest?: boolean
    tabIndex?: number
    setHover?: (val: boolean) => void
    setOpenTest?: (state: boolean) => void
    withoutAction?: boolean
    widthInput?: number
    testTooltipPosition?: TTPositionEnum
    testMessageInput?: TestMessageRequestInput
    testCallMessageRequestInput?: TestCallMessageRequestInput
    testRinglessMessageRequestInput?: TestRinglessMessageRequestInput
    staticContent?: boolean
    onSuccess?: () => void
  }) => {
    const { trigger, setTrigger, showTT, onTriggerClick, setShowTT } =
      useFixedTooltip()
    const [open, setOpen] = useState(openTest)
    const [phone, setPhone] = useState('')
    const [success, setSuccess] = useState(false)

    const [sendTestMessage, { loading: loadingTestSMS }] =
      useSendTestMessageMutation()
    const [sendTestCallMessage, { loading: loadingTestVoice }] =
      useSendTestCallMessageMutation()
    const [sendTestRinglessMessage, { loading: loadingTestRingless }] =
      useSendTestRinglessMessageMutation()

    useEffect(() => {
      if (success) {
        setTimeout(() => {
          setShowTT(false)
          setSuccess(false)
          onSuccess && onSuccess()
          onTest()
        }, 1000)
      }
    }, [success])

    const handleTest = async () => {
      if (billingStore.currentCredits <= 0) {
        billingStore.setDisabledHideNotEnoughCreditsTimestamp(0)
      } else {
        try {
          if (testMessageInput && (type === ContentType.Sms || !type)) {
            await sendTestMessage({
              variables: {
                testMessageInput: { ...testMessageInput, toNumber: phone },
              },
            })
          }
          if (
            testCallMessageRequestInput &&
            (type === ContentType.Voice || !type)
          ) {
            await sendTestCallMessage({
              variables: {
                testMessageInput: {
                  ...testCallMessageRequestInput,
                  toNumber: phone,
                },
              },
            })
          }
          if (
            testRinglessMessageRequestInput &&
            (type === ContentType.Ringless || !type)
          ) {
            await sendTestRinglessMessage({
              variables: {
                testMessageInput: {
                  ...testRinglessMessageRequestInput,
                  toNumber: phone,
                },
              },
            })
          }
          setSuccess(true)
        } catch (e) {
          console.error(e)
        }
      }
    }

    const disabled = useMemo(() => {
      if (!phone || phone.includes('_')) {
        return true
      }

      if (testMessageInput && type === ContentType.Sms) {
        if (!testMessageInput.fromNumberId) {
          return true
        }
        return !testMessageInput.smsMessage.text
      }
      if (testCallMessageRequestInput && type === ContentType.Voice) {
        if (!testCallMessageRequestInput.fromNumberId) {
          return true
        }
        return (
          !testCallMessageRequestInput.voiceMessage?.liveAnswer?.audioId &&
          !testCallMessageRequestInput.voiceMessage?.voiceMail?.audioId
        )
      }
      if (testRinglessMessageRequestInput && type === ContentType.Ringless) {
        if (!testRinglessMessageRequestInput.fromNumberId) {
          return true
        }
        return !testRinglessMessageRequestInput.audioId
      }
      return false
    }, [
      testMessageInput,
      testCallMessageRequestInput,
      testRinglessMessageRequestInput,
      phone,
    ])

    const handleTriggerClick = () => {
      if (withoutAction) {
        return
      }
      if (fromCreate) {
        setOpen(true)
        setOpenTest && setOpenTest(true)
      } else {
        onTriggerClick()
      }
    }

    const onClose = () => {
      setOpen(false)
      setOpenTest && setOpenTest(false)
    }

    const onTest = () => {
      onClose()
    }

    useEffect(() => {
      setHover && setHover(showTT)
    }, [showTT])

    return (
      <>
        {open ? (
          <TestPhoneInputComponent
            fromCreate={fromCreate}
            onClose={onClose}
            onTest={handleTest}
            width={widthInput}
            disabled={disabled}
            loading={loadingTestSMS || loadingTestVoice || loadingTestRingless}
            success={success}
            phone={phone}
            setPhone={setPhone}
          />
        ) : (
          <SendTestTooltip
            type={type}
            onTest={handleTest}
            trigger={trigger}
            showTT={showTT}
            fromAudio={fromAudio}
            position={testTooltipPosition}
            disabled={disabled}
            loading={loadingTestSMS || loadingTestVoice || loadingTestRingless}
            success={success}
            phone={phone}
            setPhone={setPhone}
            staticContent={staticContent}
          />
        )}

        {!open && !staticContent && (
          <MuiTooltip
            title={textTooltip || (fromAudio ? 'Test audio' : 'Test message')}
            placement={fromTable || fromCreate ? 'top' : 'top-end'}
            arrow
          >
            <div ref={setTrigger}>
              <Button
                tabIndex={tabIndex}
                typeBtn={fromTable ? undefined : BtnType.secondaryGray}
                size={fromCreate || medium ? BtnSize.medium : BtnSize.small}
                onClick={handleTriggerClick}
                hoverGray2={fromTable}
                active={showTT}
                icon={fromCreate}
              >
                {fromAudio ? <TestItAudio /> : <TestSMS />}
                {medium
                  ? textTooltip || (fromAudio ? 'Test audio' : 'Test message')
                  : ''}
              </Button>
            </div>
          </MuiTooltip>
        )}
      </>
    )
  }
)
