import { ApolloError } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import alertStore from 'store/alertStore'
import { IAlert } from 'src/types/IAlert'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import parse from 'html-react-parser'

export function useApolloError(apolloError?: ApolloError | null) {
  const [errorMessage, setErrorMessage] = useState('')
  useEffect(() => {
    const message = apolloError?.graphQLErrors[0].message
    if (message) {
      alertStore.disabledErrorAlert(message)
      const blacklistMatch = apolloError?.graphQLErrors[0].extensions
        ?.blacklistMatch as string
      setErrorMessage(
        message.replace(blacklistMatch, `<b>${blacklistMatch}</b>`)
      )
    }
  }, [apolloError])

  return {
    errorMessage,
    setErrorMessage,
    ErrorAlert: (props: IAlert) => (
      <>
        {errorMessage && (
          <Alert
            text={parse(errorMessage)}
            type={AlertTypeEnum.error}
            {...props}
          />
        )}
      </>
    ),
  }
}
