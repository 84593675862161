import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Icon } from 'components/Icon/Icon'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import styles from './styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { SizedBox } from 'components/SizedBox'
import React from 'react'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { CopyBtn } from 'components/Button/CopyBtn'

export const IdFieldButton = ({ id }: { id: string }) => {
  const { trigger, showTT, setTrigger, onTriggerClick, setShowTT } =
    useFixedTooltip()
  if (!id) {
    return null
  }
  return (
    <>
      <FixedTooltip
        trigger={trigger}
        visible={showTT}
        white
        noArrow
        width={252}
        position={TTPositionEnum.bottomLeft}
        noOpacity
        addLeftPosition={-32}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={'bold'}>Field ID</span>
            <MuiTooltip
              title={
                "If you are using this custom field for an integration, you can access it's internal name here"
              }
              arrow
              placement={'top'}
              width={222}
            >
              <span>
                <Icon icon={'question'} color={'PrimaryColorGray1'} />
              </span>
            </MuiTooltip>
          </div>
          <InputText value={id} disabled />
          <SizedBox height={16} />
          <CopyBtn content={id} onCopied={() => setShowTT(false)} />
        </div>
      </FixedTooltip>
      <MuiTooltip title={'Field id'} arrow placement={'top'}>
        <Button
          ref={setTrigger}
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.mini}
          active={showTT}
          onClick={onTriggerClick}
        >
          <Icon icon={'code'} fontSize={12} color={'PrimaryColorGray1'} />
        </Button>
      </MuiTooltip>
    </>
  )
}
