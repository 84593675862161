import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import {
  ChangeBroadcastStateAction,
  useChangeBroadcastsStateMutation,
  useDeleteBroadcastsMutation,
  useGetBroadcastByIdLazyQuery,
} from 'src/generated/graphql'
import { IBroadcast } from 'src/types/IBroadcast'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import billingStore from 'store/settings/billing/billingStore'
import companyStore from 'store/settings/company/companyStore'

export function useBroadcastFunctions(
  broadcast?: IBroadcast | null,
  broadcasts?: IBroadcast[]
) {
  const [getBroadcastById] = useGetBroadcastByIdLazyQuery()
  const navigate = useNavigate()
  const [changeBroadcastsState] = useChangeBroadcastsStateMutation()
  const [deleteBroadcast] = useDeleteBroadcastsMutation()

  const longIds = broadcasts && broadcasts.map((item) => +item.id)

  const onOpenDetail = () => {
    if (broadcast) {
      broadcastsStore.setBroadcastDetailModal(broadcast)
    }
    if (broadcast?.__typename === 'BroadcastResponse') {
      const count = broadcast.listCount + broadcast.contactCount - 1
      if (count >= 0) {
        getBroadcastById({
          variables: {
            broadcastId: broadcast.id,
          },
        })
          .then(({ data }) => {
            if (data?.getBroadcastById) {
              const broadcast = broadcastsStore.updateFullBroadcast(
                data.getBroadcastById
              )
              if (broadcastsStore.broadcastDetailModal) {
                broadcastsStore.setBroadcastDetailModal(broadcast)
              }
            }
          })
          .catch(console.error)
      }
    }
  }

  const onEdit = () => {
    if (broadcast) {
      navigate(`${RoutesEnum.broadcasts}/${broadcast.id}/edit`)
    }
  }

  const onDuplicate = () => {
    if (broadcast) {
      navigate(`${RoutesEnum.broadcasts}/${broadcast.id}/duplicate`)
    }
  }

  const onPause = async () => {
    if (broadcast || longIds) {
      try {
        const { data } = await changeBroadcastsState({
          variables: {
            action: ChangeBroadcastStateAction.Pause,
            broadcastIds: broadcast ? [+broadcast.id] : longIds,
          },
        })
        if (data?.changeBroadcastsState) {
          broadcastsStore.setBroadcasts(data.changeBroadcastsState)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onStop = async () => {
    if (broadcast || longIds) {
      try {
        const { data } = await changeBroadcastsState({
          variables: {
            action: ChangeBroadcastStateAction.Stop,
            broadcastIds: broadcast ? [+broadcast.id] : longIds,
          },
        })
        if (data?.changeBroadcastsState) {
          broadcastsStore.setBroadcasts(data.changeBroadcastsState)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onResume = async () => {
    const onTurnOn = async () => {
      if (broadcast || longIds) {
        try {
          const { data } = await changeBroadcastsState({
            variables: {
              action: ChangeBroadcastStateAction.Run,
              broadcastIds: broadcast ? [+broadcast.id] : longIds,
            },
          })
          if (data?.changeBroadcastsState) {
            broadcastsStore.setBroadcasts(data.changeBroadcastsState)
          }
        } catch (e) {
          console.error(e)
        }
      }
    }

    if (!billingStore.autoRecharge) {
      let findCallTransfer = false
      if (broadcast?.voiceMessage?.liveAnswer?.transferCallEnabled) {
        findCallTransfer = true
      }
      if (broadcasts) {
        broadcasts.forEach((item) => {
          if (item.voiceMessage?.liveAnswer?.transferCallEnabled) {
            findCallTransfer = true
          }
        })
      }
      if (findCallTransfer) {
        companyStore.setTurnOnAutoRechargeModal('broadcastResume', onTurnOn)
        return
      }
    }

    await onTurnOn()
  }
  const onStart = async () => {
    if (broadcast || longIds) {
      try {
        const { data } = await changeBroadcastsState({
          variables: {
            action: ChangeBroadcastStateAction.Run,
            broadcastIds: broadcast ? [+broadcast.id] : longIds,
          },
        })
        if (data?.changeBroadcastsState) {
          broadcastsStore.setBroadcasts(data.changeBroadcastsState)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onArchive = async (ids?: number[]) => {
    if (broadcast || ids || longIds) {
      try {
        const { data } = await changeBroadcastsState({
          variables: {
            action: ChangeBroadcastStateAction.Archive,
            broadcastIds: ids || (broadcast ? [+broadcast?.id] : longIds),
          },
        })
        if (data?.changeBroadcastsState) {
          broadcastsStore.setBroadcasts(data.changeBroadcastsState)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onRestore = async () => {
    if (broadcast || longIds) {
      try {
        const { data } = await changeBroadcastsState({
          variables: {
            action: ChangeBroadcastStateAction.Restore,
            broadcastIds: broadcast ? [+broadcast.id] : longIds,
          },
        })
        if (data?.changeBroadcastsState) {
          broadcastsStore.setBroadcasts(data.changeBroadcastsState)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onAnalytics = () => {
    console.log('Analytics')
  }
  const onDelete = async (propsIds?: number[]) => {
    if (broadcast || longIds || propsIds) {
      try {
        const ids: number[] =
          (broadcast ? [broadcast.id] : longIds) || propsIds || []
        await deleteBroadcast({ variables: { broadcastIds: ids } })
        navigate(RoutesEnum.broadcasts)
        broadcastsStore.deleteBroadcasts(ids.map((id) => String(id)))
      } catch (e) {
        console.error(e)
      }
    }
  }

  return {
    onPause,
    onResume,
    onOpenDetail,
    onEdit,
    onDuplicate,
    onDelete,
    onStop,
    onStart,
    onArchive,
    onRestore,
    onAnalytics,
  }
}
