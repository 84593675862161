import { observer } from 'mobx-react-lite'
import integrationsStore from 'store/settings/integrationsStore'
import classNames from 'classnames'
import styles from 'src/routes/settings/integrations/modal/connectIntegrationsModal/styles.module.scss'
import {
  AccountIntegrationType,
  useSetupIntegrationMutation,
} from 'src/generated/graphql'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { InputText } from 'components/Input/InputText/InputText'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Modal } from 'components/Modal/Modal'
import { useEffect, useState } from 'react'

export const CommonIntegrationsModal = observer(() => {
  const [errorWebUrl, setErrorWebUrl] = useState('')
  const [errorKey, setErrorKey] = useState('')
  const [setupIntegration, { loading }] = useSetupIntegrationMutation()
  const [apiUrl, setApiUrl] = useState('')
  const [apiKey, setApiKey] = useState('')

  useEffect(() => {
    setApiUrl('')
    setApiKey('')
    setErrorWebUrl('')
    setErrorKey('')
  }, [integrationsStore.connectIntegrationModal])
  const onClose = () => {
    integrationsStore.setConnectIntegrationModal(null)
  }
  const onApply = async () => {
    try {
      if (integrationsStore.connectIntegrationModal) {
        if (
          integrationsStore.connectIntegrationModal.type ===
          AccountIntegrationType.ActiveCampaign
        ) {
          const { data } = await setupIntegration({
            variables: {
              input: {
                activeCampaign: {
                  apiKey,
                  apiUrl,
                },
              },
            },
          })
          if (data?.setupIntegration?.activeCampaign?.connected) {
            integrationsStore.setAccountIntegrationsData(data.setupIntegration)
            integrationsStore.setConnectIntegration(
              integrationsStore.connectIntegrationModal
            )
            if (integrationsStore.connectIntegrationCB) {
              integrationsStore.connectIntegrationCB().catch(console.error)
            }
            integrationsStore.setConnectIntegrationModal(null)
          } else {
            setErrorWebUrl('Please enter a valid Active Campaign Web URL')
            setErrorKey('Please enter a valid Active Campaign API Key')
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  const Logo = integrationsStore.connectIntegrationModal?.Logo
  return (
    <Modal
      open={!!integrationsStore.connectIntegrationModal}
      onClose={onClose}
      title={
        <div className={'row8'}>
          <div className={classNames(styles.wrapTitleIcon)}>
            {Logo && <Logo />}
          </div>
          <h3 className={'bold'}>
            Connect {integrationsStore.connectIntegrationModal?.title}
          </h3>
        </div>
      }
    >
      <div className={'mediumModal'}>
        <Alert
          title={`Enter "${integrationsStore.connectIntegrationModal?.title}" API Key to connect your account to Call Loop`}
          type={AlertTypeEnum.notify2}
        />

        <div className={styles.wrapFields}>
          <InputText
            label={'Web Url'}
            placeholder={'https://'}
            value={apiUrl}
            onChange={(e) => setApiUrl(e.target.value)}
            onClickRightIcon={() => setApiUrl('')}
            error={errorWebUrl}
            onFocus={() => setErrorWebUrl('')}
          />
          <InputText
            label={'API Key'}
            placeholder={'Enter API Key'}
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            onClickRightIcon={() => setApiKey('')}
            error={errorKey}
            onFocus={() => setErrorKey('')}
          />
        </div>
        <div className={'row12'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            disabled={!apiUrl || !apiKey || !!errorKey || !!errorWebUrl}
            onClick={onApply}
            loading={loading}
          >
            Apply
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
