import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Link } from 'icons/16px/Link.svg'
import { ReactComponent as Question } from 'icons/16px/Question.svg'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { InputText } from 'components/Input/InputText/InputText'
import React, { FC, useEffect, useState } from 'react'
import { SizedBox } from 'components/SizedBox'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { observer } from 'mobx-react-lite'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'

type Props = {
  onAddShortenLink: (link: string, withError?: boolean) => Promise<void>
}
export const ShortenLink: FC<Props> = observer(({ onAddShortenLink }) => {
  const { trigger, showTT, setTrigger, onTriggerClick, setShowTT } =
    useFixedTooltip()
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    setLink('')
    setError('')
  }, [showTT])

  const onConfirm = async () => {
    try {
      setLoading(true)
      await onAddShortenLink(link, true)
      setShowTT(false)
      setLink('')
    } catch (e) {
      setError('Invalid url provided')
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <FixedTooltip
        trigger={trigger}
        visible={showTT}
        white
        noArrow
        width={286}
        position={TTPositionEnum.bottomLeft}
        noOpacity
        addLeftPosition={-32}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <span className={'bold'}>Add link</span>
            <Tooltip text={'Info'}>
              <Question />
            </Tooltip>
          </div>
          <InputText
            placeholder={'website.com'}
            value={link}
            onChange={(event) => setLink(event.target.value)}
            onClickRightIcon={() => setLink('')}
            onFocus={() => setError('')}
            error={error}
          />
          <SizedBox height={16} />
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.small}
            p12
            onClick={onConfirm}
            disabled={!link}
            loading={loading}
          >
            Confirm
          </Button>
        </div>
      </FixedTooltip>
      <Tooltip nowrapText={'Shorten link'} disableVisible={showTT}>
        <div ref={setTrigger}>
          <Button
            size={BtnSize.small}
            hoverGray
            active={showTT}
            onClick={onTriggerClick}
          >
            <Link />
          </Button>
        </div>
      </Tooltip>
    </>
  )
})
