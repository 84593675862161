// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZwEgHfquTbJUMMqLwQIZ{min-width:440px;border-left:1px solid var(--primary-color-gray-3);padding:20px 32px 32px 32px}.ZVzC5eMtD92f4EOPE6bE{display:flex;justify-content:space-between;align-items:center}.s7EsdBYxAICeTg0sfWRT{position:relative;z-index:2;border:1px solid var(--primary-color-gray-3)}.s7EsdBYxAICeTg0sfWRT:before{z-index:3;position:absolute;content:"";width:100%;height:100%;border:1px solid var(--primary-color-gray-3);border-radius:12px;outline:10px solid var(--primary-color-white)}`, "",{"version":3,"sources":["webpack://./src/routes/main/dashboard/Objectives/ActiveObjective/CourseContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iDAAA,CACA,2BAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAEF,sBAEE,iBAAA,CACA,SAAA,CACA,4CAAA,CACA,6BACE,SAAA,CACA,iBAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,4CAAA,CACA,kBAAA,CACA,6CAAA","sourcesContent":[".wrap{\n  min-width: 440px;\n  border-left: 1px solid var(--primary-color-gray-3);\n  padding: 20px 32px 32px 32px;\n}\n.row{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.lessonsWrap{\n\n  position: relative;\n  z-index: 2;\n  border: 1px solid var(--primary-color-gray-3);\n  &:before{\n    z-index: 3;\n    position: absolute;\n    content: \"\";\n    width: 100%;\n    height: 100%;\n    border: 1px solid var(--primary-color-gray-3);\n    border-radius: 12px;\n    outline: 10px solid var(--primary-color-white);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ZwEgHfquTbJUMMqLwQIZ`,
	"row": `ZVzC5eMtD92f4EOPE6bE`,
	"lessonsWrap": `s7EsdBYxAICeTg0sfWRT`
};
export default ___CSS_LOADER_EXPORT___;
