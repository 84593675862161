import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { CreateElementBtn } from 'components/Button/CreateElementBtn'
import { useWebFormFunctions } from 'src/routes/main/webForms/hooks/useWebFormFunctions'
import webFormStore from 'store/webForms/webFormStore'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import { AppElements } from 'src/enums/appElements'

export const Filters = observer(() => {
  const { onDelete } = useWebFormFunctions()
  const tableStore = webFormStore.tableStore
  return (
    <div className={styles.wrapActions}>
      <CreateElementBtn appElement={AppElements.WebForm} />
      {!!tableStore.checkedRowsIds.length && (
        <DeleteBtn onClick={() => onDelete(tableStore.checkedRowsIds)} medium />
      )}
    </div>
  )
})
