import { UnauthorizedRoutes } from 'src/routes/routes'
import { SizedBox } from 'components/SizedBox'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import styles from 'src/routes/auth/styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { HorizontalDivider } from 'components/HorizontalDevider/HorizontalDivider'
import { LegacyRef, useEffect, useRef, useState } from 'react'
import { InputText } from 'components/Input/InputText/InputText'
import auth from 'store/auth'
import { observer } from 'mobx-react-lite'
import { Alert } from 'components/Alert/Alert'
import { AppGoogleLogin } from 'components/GoogleLogin/AppGoogleLogin'
import ReCAPTCHA from 'react-google-recaptcha'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { ErrorsEnum } from 'src/static/errors'
import { SignupPolicy } from 'src/routes/auth/components/SignupPolicy/SignupPolicy'
import configStore from 'store/configStore'
import { usePostHog } from 'posthog-js/react'

dayjs.extend(utc)
dayjs.extend(timezone)

export const SignUp = observer(() => {
  const { search } = useLocation()
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const fromMigration = pathname.includes(UnauthorizedRoutes.migration)
  const timeZoneCode = dayjs.tz.guess()
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [isRecaptchaSuccess, setRecaptchaSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(auth.migrationParams.email || '')
  const [password, setPassword] = useState('')
  const [typePassword, setTypePassword] = useState('password')
  const [isValidPassword, setValidPassword] = useState(false)
  const [isUseEmail, setIsUseEmail] = useState(false)
  const [isWrongEmail, setIsWrongEmail] = useState(false)

  const postHog = usePostHog()

  useEffect(() => {
    const migrationParams: typeof auth.migrationParams = {}
    params.forEach((value, key) => {
      migrationParams[key as keyof typeof auth.migrationParams] = value
    })
    auth.setMigrationParams(migrationParams)
    if (auth.migrationParams.email) {
      navigate(UnauthorizedRoutes.migration + search)
    }
  }, [params])

  useEffect(() => {
    if (auth.migrationParams.email) {
      setEmail(auth.migrationParams.email)
    }
  }, [auth.migrationParams.email])

  useEffect(() => {
    auth.setAuthError('')
    if (postHog) {
      if (!configStore.signUpFlow) {
        const signUpFlow = postHog.getFeatureFlag('SignUpFlow')
        if (signUpFlow) {
          configStore.setSignUpFlow(signUpFlow)
        }
      } else {
        postHog.featureFlags.override({ SignUpFlow: configStore.signUpFlow })
      }
    }
  }, [])

  const onSignUp = async () => {
    try {
      setLoading(true)
      setIsUseEmail(false)
      let isRecaptcha = isRecaptchaSuccess
      if (!isRecaptcha) {
        isRecaptcha = !!(await recaptchaRef.current?.executeAsync())
      }

      if (isRecaptcha) {
        setRecaptchaSuccess(true)

        await auth.onSignUp(email.trim(), password, timeZoneCode)
      }
    } finally {
      setLoading(false)
    }
  }
  const isDisabled = !(
    email.trim() &&
    password.trim() &&
    isValidPassword &&
    !isWrongEmail
  )
  const checkEmail = () => {
    setIsWrongEmail(!(email.includes('@') && email.includes('.')))
  }
  const titleContent = fromMigration ? (
    <h3 className={'bold'}>Create your Call Loop account</h3>
  ) : (
    <>
      <h2>Start Your 14-Day Trial</h2>
      <SizedBox height={8} />
      <p className={'par2 gray1 medium'}>
        Already have an account?&nbsp;
        <Link to={UnauthorizedRoutes.login} className={styles.link}>
          Sign in
        </Link>
      </p>
    </>
  )
  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef as LegacyRef<ReCAPTCHA>}
        sitekey={process.env.RECAPTCHA_KEY || ''}
        size={'invisible'}
        hidden
      />
      {titleContent}
      <SizedBox height={32} />
      {auth.authError &&
        (auth.authError === ErrorsEnum.alreadyUseEmail ? (
          <>
            <Alert
              title={'This email is already in use'}
              rightContent={
                fromMigration ? undefined : (
                  <Link
                    to={UnauthorizedRoutes.forgot}
                    className={styles.wrapLink}
                  >
                    <span className={'bold link'}>Recover password</span>
                  </Link>
                )
              }
              text={
                fromMigration ? (
                  <div>
                    <div className={'par2 black'}>
                      Please recover your password or choose another email
                    </div>
                    <div className={styles.wrapUse}>
                      <Link to={UnauthorizedRoutes.login2}>
                        <span className={'bold link'}>Log in</span>
                      </Link>
                      <Link to={UnauthorizedRoutes.forgot}>
                        <span className={'bold link'}>Recover password</span>
                      </Link>
                    </div>
                  </div>
                ) : (
                  'Please recover your password or choose another email'
                )
              }
              type={AlertTypeEnum.error}
            />
            <SizedBox height={20} />
          </>
        ) : (
          <>
            <Alert text={auth.authError} type={AlertTypeEnum.error} />
            <SizedBox height={20} />
          </>
        ))}
      {isWrongEmail && (
        <>
          <Alert
            title={'There’s something wrong with your email'}
            text={'Perhaps you missed “@” or “.”'}
            type={AlertTypeEnum.error}
          />
          <SizedBox height={20} />
        </>
      )}
      <AppGoogleLogin fromSignUp />
      <HorizontalDivider color={'PrimaryColorGray2'} />
      <SizedBox height={20} />
      <InputText
        id={'email'}
        label={'Email address'}
        placeholder={'email@address.com'}
        value={email}
        onChange={(e) => setEmail(e.target.value.trim())}
        onClickRightIcon={() => setEmail('')}
        error={isUseEmail || isWrongEmail ? ErrorsEnum.verifyEmail : undefined}
        onBlur={checkEmail}
        focusOnMount
      />
      <SizedBox height={16} />
      <InputText
        setValidPassword={setValidPassword}
        valid
        password
        id={'password'}
        label={'Password'}
        placeholder={'Enter your password'}
        value={password}
        type={typePassword}
        onChange={(e) => setPassword(e.target.value.trim())}
        onClickRightIcon={() =>
          setTypePassword(typePassword === 'password' ? 'text' : 'password')
        }
      />
      <SizedBox height={40} />
      <Button
        size={BtnSize.large}
        typeBtn={BtnType.primary}
        onClick={onSignUp}
        disabled={isDisabled}
        loading={loading}
      >
        Sign up
      </Button>
      <SizedBox height={16} />
      <SignupPolicy />
    </>
  )
})
