import { AccountIntegrationType } from 'src/generated/graphql'
import { getAccountIntegrationTypeIcon } from 'src/routes/main/triggers/create/components/getAccountIntegrationTypeIcon'
import { IOption } from 'src/types/IOption'

export const mockApps: IOption<AccountIntegrationType>[] = [
  {
    value: AccountIntegrationType.ActiveCampaign,
    title: 'Active Campaign',
    LeftIcon: getAccountIntegrationTypeIcon(
      AccountIntegrationType.ActiveCampaign
    ),
    iconClass: 'box24',
  },
  // {
  //   value: AccountIntegrationType.Drip,
  //   title: 'Drip',
  //   LeftIcon: getAccountIntegrationTypeIcon(AccountIntegrationType.Drip),
  //   iconClass: 'box24',
  // },
  {
    value: AccountIntegrationType.HubSpot,
    title: 'HubSpot',
    LeftIcon: getAccountIntegrationTypeIcon(AccountIntegrationType.HubSpot),
    iconClass: 'box24',
  },
  {
    value: AccountIntegrationType.Keap,
    title: 'Keap',
    LeftIcon: getAccountIntegrationTypeIcon(AccountIntegrationType.Keap),
    iconClass: 'box24',
  },
  {
    value: AccountIntegrationType.Custom,
    title: 'Custom',
    LeftIcon: getAccountIntegrationTypeIcon(AccountIntegrationType.Custom),
    iconClass: 'box24',
  },
]

export const mockPhoneFields: IOption[] = [
  {
    value: 'Phone Number',
    title: 'Phone Number',
  },
  {
    value: 'MPHONE',
    title: 'MPHONE',
  },
]
