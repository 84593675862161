import { makeAutoObservable } from 'mobx'
import {
  IIntegration,
  integrationCardsMap,
} from 'src/routes/settings/integrations/content/integrationCards'
import alertStore from 'store/alertStore'
import {
  AccountIntegrationsResponse,
  AccountIntegrationType,
  ActiveCampaignIntegration,
} from 'src/generated/graphql'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { IOauth2Integration } from 'src/types/IOauth2Integration'
import { GraphQLError } from 'graphql/error'

class IntegrationsStore {
  constructor() {
    makeAutoObservable(this)
  }

  activeCampaign: ActiveCampaignIntegration | null = null
  oAuth2IntegrationsMap: Map<AccountIntegrationType, IOauth2Integration> =
    new Map()

  openCallLoopApiKeyModal = false
  connectIntegrationModalType: AccountIntegrationType | null = null
  connectIntegrationCB: (() => Promise<void>) | null = null
  initLoading = false

  errorHubspotConnect: GraphQLError | null = null

  get invalidatedIntegrations(): IOauth2Integration[] {
    return Array.from(this.oAuth2IntegrationsMap.values()).filter(
      (item) => item.invalidated
    )
  }

  get connectIntegrationModal() {
    if (this.connectIntegrationModalType) {
      return integrationCardsMap.get(this.connectIntegrationModalType)
    }
    return null
  }

  get connectedIntegrationsMap(): Map<AccountIntegrationType, boolean> {
    const integrations: Array<[AccountIntegrationType, boolean]> = []
    integrations.push([
      AccountIntegrationType.ActiveCampaign,
      !!this.activeCampaign?.connected,
    ])
    this.oAuth2IntegrationsMap.forEach((integration) => {
      integrations.push([integration.type, !!integration?.connected])
    })

    return new Map(integrations)
  }

  setConnectIntegrationModal(
    type: AccountIntegrationType | null,
    cb?: () => Promise<void>
  ) {
    this.connectIntegrationModalType = type
    this.connectIntegrationCB = cb || null
  }
  setConnectIntegration(integration: IIntegration) {
    alertStore.setAlert({
      title: `${integration.title} connected!`,
      type: AlertTypeEnum.success,
    })
  }
  setOpenCallLoopApiKeyModal(state: boolean) {
    this.openCallLoopApiKeyModal = state
  }

  setAccountIntegrationsData(data: AccountIntegrationsResponse) {
    this.initLoading = true
    this.activeCampaign = data.activeCampaign || null
    const oAuth2Integrations: IOauth2Integration[] = []
    data.OAuth2Integrations?.forEach((integration) => {
      if (integration && integration.type) {
        oAuth2Integrations.push(integration as IOauth2Integration)
      }
    })
    this.oAuth2IntegrationsMap = new Map(
      oAuth2Integrations.map((integration) => [integration.type, integration])
    )
  }
  clearConnectIntegrationCB() {
    this.connectIntegrationCB = null
  }

  setHubspotErrorConnect = (errorConnect: GraphQLError | null) => {
    this.errorHubspotConnect = errorConnect
  }
}

export default new IntegrationsStore()
