import React, { useEffect, useRef } from 'react'
import { IResponseVoice } from 'src/Modals/CreateTextToSpeechModal/store/type'
import { observer } from 'mobx-react-lite'
import { Icon } from 'components/Icon/Icon'
import styles from './styles.module.scss'
import audioStore from 'store/audioStore/audioStore'
import createTextToSpeechStore from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechStore'

export const VoicePreviewPlayer = observer(
  ({ voice }: { voice: IResponseVoice }) => {
    const refAudio = useRef<HTMLAudioElement>(null)
    const { handlePlayPreview, playingPreviewId, audioPreviewSrc } =
      createTextToSpeechStore

    const id = `${voice.voiceId}${voice.type}`
    const playing = playingPreviewId === id

    useEffect(() => {
      if (audioPreviewSrc && playing) {
        audioStore.setPlayAudioId(playingPreviewId)
        refAudio.current?.play()
      } else {
        if (audioPreviewSrc && !playing) {
          refAudio.current?.pause()
        }
      }
      if (audioStore.playAudioId !== id && playing) {
        refAudio.current?.pause()
      }
    }, [audioPreviewSrc, playingPreviewId, audioStore.playAudioId])

    return (
      <>
        <button
          onClick={(event) => {
            event.stopPropagation()
            handlePlayPreview(voice)
          }}
          className={styles.wrapPlay}
        >
          {playing ? (
            <Icon icon={'pause'} color={'PrimaryColorBlue1'} />
          ) : (
            <Icon icon={'play'} color={'PrimaryColorBlack'} />
          )}
        </button>
        {audioPreviewSrc && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio
            ref={refAudio}
            src={audioPreviewSrc}
            style={{ display: 'none' }}
            onEnded={() => handlePlayPreview(voice)}
          ></audio>
        )}
      </>
    )
  }
)
