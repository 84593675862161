import { ReactComponent as AnalyticsIcon } from 'icons/16pxNoMask/Analytics.svg'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import styles from './styles.module.scss'
import React from 'react'
import clicksDetailStore from 'components/Clicks/clicksDetailStore'
import classNames from 'classnames'
import { ShortUrlClickStatistics } from 'src/generated/graphql'

export const Clicks = ({
  shortUrlClickStatistics,
  fromCard,
}: {
  shortUrlClickStatistics: ShortUrlClickStatistics
  fromCard?: boolean
}) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (!shortUrlClickStatistics.shortUrlVisits?.length) return
    clicksDetailStore.setShortUrlClickStatistics(shortUrlClickStatistics)
  }
  const title = `Link${
    shortUrlClickStatistics?.totalVisits > 1 ? 's' : ''
  } details`
  return (
    <div className={'row8'}>
      {fromCard ? (
        <MuiTooltip
          title={shortUrlClickStatistics.shortUrlVisits?.length ? title : ''}
          arrow
          placement={'top'}
        >
          <button
            onClick={onClick}
            className={classNames(styles.clickBtn, 'detailClicksBtn')}
          >
            Clicks
            <AnalyticsIcon />
          </button>
        </MuiTooltip>
      ) : (
        <>
          {!!shortUrlClickStatistics?.totalVisits && (
            <MuiTooltip title={title} arrow placement={'top'}>
              <button className={styles.btn} onClick={onClick}>
                <AnalyticsIcon />
              </button>
            </MuiTooltip>
          )}
          <span>{shortUrlClickStatistics?.totalVisits}</span>
        </>
      )}
    </div>
  )
}
