import { ActionBtnProps } from 'components/NewTable/types'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import * as React from 'react'
import { api } from 'src/apiRest/api'
import { onExport } from 'src/actions/onExport'
import { IBroadcast } from 'src/types/IBroadcast'

export const BroadcastExportBtn = (props: ActionBtnProps<IBroadcast>) => {
  const { row } = props

  const handleExport = async () => {
    if (row?.id) {
      await onExport({
        info: 'broadcast report',
        request: api.getReport('broadcast', row.id),
      })
    }
  }
  return (
    <>
      <TableBtn
        {...props}
        action={ActionsEnum.export}
        textBtn={'Export report'}
        onAction={handleExport}
      />
    </>
  )
}
