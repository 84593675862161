import { nanoid } from 'nanoid'
import styles from './styles.module.scss'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { Templates } from 'src/widgets/MessageTemplate/MessageTemplatesModal/Templates/Templates'
import { IMessageTemplate } from 'src/widgets/MessageTemplate'

const mockSuggested: IMessageTemplate[] = [
  {
    id: nanoid(),
    name: 'Interview Followup',
    smsMessage: {
      compliance: false,
      text: "Hello {firstName}, you've been selected to move on to the next round of interviews with [company name]! Please schedule an interview at your nearest convenience: [link here]",
    },
  },
  {
    id: nanoid(),
    name: 'Coupon Code',
    smsMessage: {
      compliance: false,
      text: 'Flash sale alert! For the next 5 hours you can save $25 dollars on your order of $50 or more. Just use the code [keyword] at checkout.',
    },
  },
  {
    id: nanoid(),
    name: 'Appointment Reminder',
    smsMessage: {
      compliance: false,
      text: "Hi {firstName}, you're due for your annual checkup in a few weeks. Click here to schedule: [link here]",
    },
  },
  {
    id: nanoid(),
    name: 'Upcoming Event',
    smsMessage: {
      compliance: false,
      text: "You're invited to the annual [company name] conference! Join us at 1600 Pennsylvania Ave on Mon, Dec 16 at 7 p.m.",
    },
  },
  {
    id: nanoid(),
    name: 'Canceled Event',
    smsMessage: {
      compliance: false,
      text: "Due to inclement weather, tonight's event has been cancelled. We will inform you via text when a new date has been scheduled.",
    },
  },
]

export const SuggestedTab = ({ store }: { store: CreateStoreTypes }) => {
  return (
    <div className={styles.wrap}>
      <Templates templates={mockSuggested} withoutMore store={store} />
    </div>
  )
}
