import styles from 'components/Clicks/styles.module.scss'
import { SizedBox } from 'components/SizedBox'
import { AppIcon, ImportIcons } from 'components/AppIcon/AppIcon'
import { SpecificUrlClickStatistics } from 'src/generated/graphql'
import { numberString } from 'src/util/functions'

interface Props extends SpecificUrlClickStatistics {
  index: number
  length: number
}

export const ClickDetailComponent = ({
  totalVisits,
  shortUrl,
  longUrl,
  percentage,
  totalVisitsToday,
  length,
  index,
}: Props) => {
  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <div className={'row8'}>
          {length > 1 && (
            <div className={styles.counter}>
              <span className={'blue1'}>{index + 1}</span>
            </div>
          )}
          <h3>Link</h3>
        </div>

        <div className={'row16'}>
          <div className={'row8'}>
            <h3>
              <b>{numberString({ val: totalVisits })}</b>
            </h3>
            <div className={'point'} />
            <h3 className={'gray1'}>
              {numberString({ val: percentage || 0, maximumFractionDigits: 1 })}
              %
            </h3>
          </div>
          <div className={styles.tag}>
            <span className={'s2 medium gray1'}>Today</span>
            <span className={'s2 medium'}>
              {numberString({ val: totalVisitsToday })}
            </span>
          </div>
        </div>
      </div>
      <SizedBox height={24} />
      <div className={'col12'}>
        <div className={styles.row}>
          <div className={'row8'}>
            <AppIcon icon={ImportIcons.link} />
            <span className={'gray1'}>Shorted</span>
          </div>
          {shortUrl && (
            <a
              href={shortUrl}
              target={'_blank'}
              rel="noreferrer"
              className={'link-14-underline'}
            >
              {shortUrl}
            </a>
          )}
        </div>
        <div className={styles.row}>
          <div className={'row8'}>
            <AppIcon icon={ImportIcons.earth} />
            <span className={'gray1'}>Source</span>
          </div>
          {longUrl && (
            <a
              href={longUrl}
              target={'_blank'}
              rel="noreferrer"
              className={'link-14-underline'}
            >
              {longUrl}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
