// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i2IlRo70p_iychlIctvv{display:grid;row-gap:12px}.XgZQMthM06Fiopo5GSxh{display:flex;column-gap:12px;align-items:center;color:var(--primary-color-black);font-size:14px;line-height:20px}`, "",{"version":3,"sources":["webpack://./src/components/Ul/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,eAAA,CACA,kBAAA,CACA,gCAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".ul{\n  display: grid;\n  row-gap: 12px;\n}\n\n.wrap{\n  display: flex;\n  column-gap: 12px;\n  align-items: center;\n  color: var(--primary-color-black);\n  font-size: 14px;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ul": `i2IlRo70p_iychlIctvv`,
	"wrap": `XgZQMthM06Fiopo5GSxh`
};
export default ___CSS_LOADER_EXPORT___;
