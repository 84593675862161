import * as React from 'react'
import { FC } from 'react'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { ActionBtnProps } from 'components/NewTable/types'
import { BtnSize } from 'components/Button/Button'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'

type ActionsProps<T> = {
  actions: Array<FC<ActionBtnProps<T>>>
  extraBtnSize?: BtnSize
  visibleActionsCount?: number
  position?: TTPositionEnum
  tooltip?: string
}

export const Actions = <T,>({
  actions,
  extraBtnSize,
  visibleActionsCount = 4,
  position,
  tooltip = 'More',
}: ActionsProps<T>) => {
  const rowActions = actions.slice(0, visibleActionsCount)
  const moreActions = actions.slice(visibleActionsCount)
  return (
    <>
      {rowActions.map((Action, index) => (
        <Action key={index} medium extraBtnSize={extraBtnSize} />
      ))}
      {!!moreActions?.length && (
        <MoreBtn
          menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
          tooltip={tooltip}
          medium
          extraBtnSize={extraBtnSize}
          position={position}
        />
      )}
    </>
  )
}
