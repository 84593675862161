import {
  CampaignResponse,
  CampaignStepResponse,
  Status,
} from 'src/generated/graphql'
import { ICampaign } from 'src/types/ICampaign'
import { IItem } from 'components/NewTable/cell/ItemsRender/ItemsRender'

export const transformCampaign = (
  campaignResponse: CampaignResponse
): ICampaign => {
  const steps: CampaignStepResponse[] = []
  const items: IItem[] = []
  campaignResponse.campaignStepResponses?.forEach((step) => {
    if (step) {
      steps.push(step)
    }
  })
  campaignResponse.campaignListResponses?.forEach((list) => {
    if (list && list.listName) {
      items.push({
        id: list.listId,
        name: list.listName,
        group: 'list',
      })
    }
  })
  return {
    ...campaignResponse,
    id: String(campaignResponse.id),
    contactsListsIds: campaignResponse.campaignListResponses?.map(
      (list) => list?.listId
    ),
    items,
    campaignStepResponses: steps,
    status: campaignResponse.status as Status,
  }
}
