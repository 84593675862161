import styles from './styles.module.scss'
import classNames from 'classnames'

export const DetailRow = ({
  leftContent,
  rightContent,
  startLabel,
}: {
  leftContent: JSX.Element
  rightContent: JSX.Element
  startLabel?: boolean
}) => (
  <div className={styles.rowTable}>
    <div className={classNames(styles.left, startLabel && styles.start)}>
      {leftContent}
    </div>
    {rightContent}
  </div>
)
