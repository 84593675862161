import { ReactComponent as Card } from 'assets/icons/Cards/Property 1=No Card.svg'
import { ReactComponent as Visa } from 'assets/icons/Cards/Property 1=Visa.svg'
import { ReactComponent as Mastercard } from 'assets/icons/Cards/Property 1=Mastercard.svg'
import { ReactComponent as AmericanExpress } from 'assets/icons/Cards/Property 1=American Express.svg'
import { ReactComponent as Diners } from 'assets/icons/Cards/Property 1=Diners Club.svg'
import { ReactComponent as Discover } from 'assets/icons/Cards/Property 1=Discover.svg'
import { ReactComponent as JCB } from 'assets/icons/Cards/Property 1=JCB.svg'
import { ReactComponent as UnionPay } from 'assets/icons/Cards/Property 1=UnionPay.svg'
import { getCardType } from 'src/util/getCardType'
import { CardType } from 'src/enums/cardType'

export const CardIcon = ({
  value,
  cardTypeProps,
}: {
  value?: JSX.IntrinsicElements['input']['value']
  cardTypeProps?: CardType
}) => {
  const cardType = cardTypeProps || getCardType(String(value))
  if (cardType === CardType.mastercard) {
    return <Mastercard />
  }
  if (cardType === CardType.visa) {
    return <Visa />
  }
  if (cardType === CardType.amex) {
    return <AmericanExpress />
  }
  if (cardType === CardType.diners) {
    return <Diners />
  }
  if (cardType === CardType.discover) {
    return <Discover />
  }
  if (cardType === CardType.jcb) {
    return <JCB />
  }
  if (cardType === CardType.unionpay) {
    return <UnionPay />
  }
  return <Card />
}
