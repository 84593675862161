import { Modal } from 'components/Modal/Modal'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { numberString } from 'src/util/functions'

export const SuccessByCreditsModal = observer(() => {
  const onClose = () => {
    billingStore.setSuccessBuyCredits(0)
  }
  return (
    <Modal
      open={!!billingStore.successBuyCredits}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon />
        <h3>
          You`ve successfully purchased{' '}
          {numberString({ val: billingStore.successBuyCredits })} credits
        </h3>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          fullWidth
          onClick={onClose}
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
