import { useEffect, useState } from 'react'
import { Chart } from 'chart.js'
import dayjs from 'lib/dayjs'
import { SizedBox } from 'components/SizedBox'
import { Table, TableVariant } from 'components/Table/Table'
import { Column } from 'react-table'
import { getAbsoluteDay } from 'src/util/functions'
import { ColorsValues } from 'styles/variables'
import { observer } from 'mobx-react-lite'
import performanceStore from 'store/dashboard/performanceStore'

const getDay = (prevDay: number) => dayjs().add(prevDay, 'day').format('MMM DD')

const getMockDate = (
  max: number,
  startDate: Date | null,
  endDate: Date | null
) => {
  const countDays: number =
    getAbsoluteDay(endDate || new Date()) -
    getAbsoluteDay(startDate || new Date())
  const data = {}
  if (countDays <= 0) {
    return {}
  }
  let newArr: Array<number> = new Array(countDays)
    .fill(0)
    .map((i, index) => -index)
    .reverse()

  if (newArr.length > 14) {
    newArr = [
      newArr[0],
      newArr[Math.ceil(newArr.length / 14)],
      newArr[Math.ceil((2 * newArr.length) / 14)],
      newArr[Math.ceil((3 * newArr.length) / 14)],
      newArr[Math.ceil((4 * newArr.length) / 14)],
      newArr[Math.ceil((5 * newArr.length) / 14)],
      newArr[Math.ceil((6 * newArr.length) / 14)],
      newArr[Math.ceil((7 * newArr.length) / 14)],
      newArr[Math.ceil((8 * newArr.length) / 14)],
      newArr[Math.ceil((9 * newArr.length) / 14)],
      newArr[Math.ceil((10 * newArr.length) / 14)],
      newArr[Math.ceil((11 * newArr.length) / 14)],
      newArr[Math.ceil((12 * newArr.length) / 14)],
      newArr[newArr.length - 1],
    ]
  }
  newArr.forEach((el) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data[getDay(el)] = Math.round(Math.random() * 600)
  })
  return data
}

interface RequiredFields {
  name: string | number
  color: string
}

type Props<T> = {
  data: T[]
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  columns: Column<T>[]
  idChart: string
}

function LineChart<T extends RequiredFields>({ data, columns }: Props<T>) {
  const [ref, setRef] = useState<HTMLCanvasElement | null>(null)
  const [chart, setChart] = useState<Chart<
    'line',
    number | unknown | unknown[]
  > | null>(null)

  const labels = Object.keys(
    getMockDate(
      600,
      performanceStore.rangeDatePickerStore.startDate,
      performanceStore.rangeDatePickerStore.endDate
    )
  )

  const datasets = data.map((item) => ({
    label: item.name,
    data: Object.values(
      getMockDate(
        600,
        performanceStore.rangeDatePickerStore.startDate,
        performanceStore.rangeDatePickerStore.endDate
      )
    ),
    fill: false,
    borderColor: item.color,
    tension: 0.1,
    borderWidth: 2,
    pointRadius: 2,
  }))

  useEffect(() => {
    if (chart) {
      chart.data.labels = labels
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chart.data.datasets = datasets
      chart.update()
    }
  }, [
    performanceStore.rangeDatePickerStore.startDate,
    performanceStore.rangeDatePickerStore.endDate,
  ])
  useEffect(() => {
    if (ref) {
      const newChart = new Chart(ref, {
        type: 'line',
        data: {
          labels,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          datasets,
        },
        options: {
          // responsive: true,
          // parsing: false,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
          },
          scales: {
            y: {
              grid: {
                borderDash: [6],
                tickLength: 12,
                tickWidth: 0,
                lineWidth: 0,
                borderWidth: 0,
              },
              ticks: {
                color: ColorsValues.gray1,
                font: { size: 10 },
              },
            },
            x: {
              grid: {
                borderDash: [6],
                tickLength: 12,
                tickWidth: 0,
                borderWidth: 0,
              },
              ticks: {
                color: ColorsValues.gray1,
                font: { size: 10 },
                maxRotation: 0,
                callback: function (value, index, ticks) {
                  return index % 2 && ticks.length >= 14
                    ? ''
                    : this.getLabelForValue(Number(value))
                },
                // Disabled rotation for performance
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            decimation: {
              enabled: false,
              algorithm: 'min-max',
            },
          },
        },
      })
      setChart(newChart)
    }
  }, [ref])

  return (
    <>
      <canvas id="myChart" width="460" height="160" ref={setRef} />
      <SizedBox height={24} />
      <Table<T>
        columns={columns}
        data={data}
        variant={TableVariant.variant1}
        tdHeight={48}
      />
    </>
  )
}

export default observer(LineChart)
