import { SegmentContent } from 'src/routes/main/contacts/segments/DetailSegment/components/SegmentContent/SegmentContent'
import { InputText } from 'components/Input/InputText/InputText'
import { HeaderWrapper } from 'src/routes/main/contacts/segments/DetailSegment/components/header/HeaderWrapper'
import { useSegmentDetailContext } from 'src/routes/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { observer } from 'mobx-react-lite'
import { useElementName } from 'src/hooks/useElementName'

export const NewSegmentPage = observer(() => {
  const { name, setName } = useSegmentDetailContext()
  const { error, onBlur, onFocus } = useElementName({
    name,
    setName,
    element: 'Segment',
  })
  return (
    <>
      <HeaderWrapper>
        <InputText
          withoutBorder
          placeholder={'Segment name'}
          header2
          withoutClear
          value={name}
          onChangeValue={setName}
          error={error}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </HeaderWrapper>
      <SegmentContent />
    </>
  )
})
