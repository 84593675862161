// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b6yUxqmhSqPbldwnkYou{height:20px;display:flex;align-items:center;column-gap:8px;padding:2px 8px;border-radius:8px;width:fit-content}`, "",{"version":3,"sources":["webpack://./src/components/TypeContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".type{\n  height: 20px;\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n  padding: 2px 8px;\n  border-radius: 8px;\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"type": `b6yUxqmhSqPbldwnkYou`
};
export default ___CSS_LOADER_EXPORT___;
