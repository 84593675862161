// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wtaq2BqL3LyrK0G9ksBA{display:flex;justify-content:space-between;width:100%}.M0vOVnQ_UtTn_XclhDUw{margin:4px 8px 8px 8px}.I5UQ61CaiorjGfzPUuBO{width:100%;position:relative}.Pv3UQtBC7sjb_L1ne2FK{position:absolute;right:4px;top:4px;display:flex;column-gap:16px}.VEblBMN_ZzD1oCi8jsF8{cursor:pointer}.VEblBMN_ZzD1oCi8jsF8:hover span{color:var(--primary-color-black)}`, "",{"version":3,"sources":["webpack://./src/components/TestItBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,UAAA,CAEF,sBACE,sBAAA,CAEF,sBACE,UAAA,CACA,iBAAA,CAEF,sBACE,iBAAA,CACA,SAAA,CACA,OAAA,CACA,YAAA,CACA,eAAA,CAEF,sBACE,cAAA,CAEE,iCACE,gCAAA","sourcesContent":[".row{\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n.title{\n  margin: 4px 8px 8px 8px;\n}\n.btn{\n  width: 100%;\n  position: relative;\n}\n.additionalContent{\n  position: absolute;\n  right: 4px;\n  top: 4px;\n  display: flex;\n  column-gap: 16px;\n}\n.cancel{\n  cursor: pointer;\n  &:hover{\n    span{\n      color: var(--primary-color-black);\n    }\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `wtaq2BqL3LyrK0G9ksBA`,
	"title": `M0vOVnQ_UtTn_XclhDUw`,
	"btn": `I5UQ61CaiorjGfzPUuBO`,
	"additionalContent": `Pv3UQtBC7sjb_L1ne2FK`,
	"cancel": `VEblBMN_ZzD1oCi8jsF8`
};
export default ___CSS_LOADER_EXPORT___;
