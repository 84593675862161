import { IBroadcast } from 'src/types/IBroadcast'
import { getActionsByStatus } from 'src/routes/main/broadcasts/functions/getActionsByStatus'
import { ActionsEnum } from 'components/Button/types'
import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { broadcastBtnByAction } from 'src/routes/main/broadcasts/functions/broadcastBtnByAction'

const manyActionsStatics = [
  ActionsEnum.stop,
  ActionsEnum.pause,
  ActionsEnum.resume,
  ActionsEnum.delete,
]

export const getManyBroadcastsActions = (
  broadcasts: IBroadcast[]
): Array<FC<ActionBtnProps<IBroadcast>>> => {
  const broadcastsActions: ActionsEnum[][] = []
  const broadcastsStatuses = broadcasts.map(
    (broadcast) => broadcast.simplifiedStatus
  )

  broadcastsStatuses.forEach((status) => {
    if (status) {
      broadcastsActions.push(getActionsByStatus(status))
    }
  })

  const manyActions = manyActionsStatics.filter((action) =>
    broadcastsActions.every((bActions) => bActions.includes(action))
  )

  const actionsFC: Array<FC<ActionBtnProps<IBroadcast>>> = []

  manyActions.forEach((action) => {
    const btn: FC<ActionBtnProps<IBroadcast>> | undefined =
      broadcastBtnByAction[action as keyof typeof broadcastBtnByAction]
    if (btn) {
      actionsFC.push((props) => btn({ ...props, rows: broadcasts }))
    }
  })
  return actionsFC
}
