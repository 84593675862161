// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t6UjXUAZhuP3ukqRdJ9j{padding:0 8px 32px 32px}.qxXe9_iWYhQukV9zyOw9{margin-right:24px;display:grid;grid-template-columns:250px 214px;background-color:var(--primary-color-gray-4);border-radius:8px 8px 0 0}.qxXe9_iWYhQukV9zyOw9 div{padding:12px}.fpo4UMaBsHQKew3wKSbQ{padding:8px 18px 16px 0}.IudWxBt2rJyWpNCXIKBG{display:grid;grid-template-columns:216px 1fr max-content;column-gap:12px;align-items:center;padding:8px 12px 8px 8px}.dXspHBmmHissTiT8TZ8g{display:flex;align-items:center;column-gap:8px}.QkXdWhLuNtOzKILZprQx{max-height:349px;overflow:scroll;margin-bottom:32px}.Fh35fPLq8Ztjl1lTXIQ8{padding-left:32px}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/customFields/modals/CustomFieldsModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,uBAAA,CAEF,sBACE,iBAAA,CACA,YAAA,CACA,iCAAA,CACA,4CAAA,CACA,yBAAA,CACA,0BACE,YAAA,CAIJ,sBACE,uBAAA,CAEF,sBACE,YAAA,CACA,2CAAA,CACA,eAAA,CACA,kBAAA,CACA,wBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CAEF,sBACE,gBAAA,CACA,eAAA,CACA,kBAAA,CAEF,sBACE,iBAAA","sourcesContent":[".wrap{\n  padding: 0 8px 32px 32px;\n}\n.header{\n  margin-right: 24px;\n  display: grid;\n  grid-template-columns: 250px 214px;\n  background-color: var(--primary-color-gray-4);\n  border-radius: 8px 8px 0 0;\n  div{\n    padding: 12px;\n  }\n\n}\n.content{\n  padding: 8px 18px 16px 0;\n}\n.item{\n  display: grid;\n  grid-template-columns: 216px 1fr max-content;\n  column-gap: 12px;\n  align-items: center;\n  padding: 8px 12px 8px 8px;\n}\n.wrapInput{\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n.wrapContent{\n  max-height: 349px;\n  overflow: scroll;\n  margin-bottom: 32px;\n}\n.wrapError{\n  padding-left: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `t6UjXUAZhuP3ukqRdJ9j`,
	"header": `qxXe9_iWYhQukV9zyOw9`,
	"content": `fpo4UMaBsHQKew3wKSbQ`,
	"item": `IudWxBt2rJyWpNCXIKBG`,
	"wrapInput": `dXspHBmmHissTiT8TZ8g`,
	"wrapContent": `QkXdWhLuNtOzKILZprQx`,
	"wrapError": `Fh35fPLq8Ztjl1lTXIQ8`
};
export default ___CSS_LOADER_EXPORT___;
