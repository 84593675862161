import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as ScheduleIcon } from 'icons/16pxNoMask/Schedule.svg'
import styles from './styles.module.scss'
import { FC, useEffect, useState } from 'react'
import { BroadcastScheduleStore } from 'components/Schedule/store/broadcastScheduleStore'
import { observer } from 'mobx-react-lite'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ClickAwayListener } from '@mui/material'
// import { BetweenContent } from 'components/Schedule/BetweenContent/BetweenContent'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { CampaignScheduleStore } from 'components/Schedule/store/campaignScheduleStore'
import { ScheduleContent } from 'components/Schedule/ScheduleContent'

type Props = {
  scheduleStore: BroadcastScheduleStore | CampaignScheduleStore
  tabIndex?: number
  noLabel?: boolean
}

export type ScheduleProps = {
  scheduleStore: BroadcastScheduleStore | CampaignScheduleStore
}

export const Schedule: FC<Props> = observer(
  ({ scheduleStore, tabIndex, noLabel }) => {
    const [open, setOpen] = useState(false)
    const [ref, setRef] = useState<HTMLDivElement | null>(null)

    useEffect(() => {
      scheduleStore.setRefModalContent(ref)
    }, [ref])
    return (
      <>
        {!noLabel && <span className={'s2 medium gray1 mb8'}>Schedule</span>}
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={styles.muiTooltip}>
            <MuiTooltip
              padding={0}
              placement={
                scheduleStore instanceof CampaignScheduleStore
                  ? 'bottom'
                  : 'top-start'
              }
              width={350}
              open={open}
              zIndex={100}
              title={
                <div className={styles.wrapTT} ref={setRef}>
                  <ScheduleContent scheduleStore={scheduleStore} />
                </div>
              }
              white
            >
              <div className={styles.wrap}>
                <Button
                  typeBtn={BtnType.secondaryBlue}
                  size={BtnSize.small}
                  p12
                  onClick={() => setOpen(true)}
                  tabIndex={tabIndex}
                >
                  <AppIcon color={ColorsNames.blue2}>
                    <ScheduleIcon />
                  </AppIcon>

                  <span className={'blue2 bold'}>
                    {scheduleStore.sendTimeString}
                  </span>

                  {scheduleStore.suffixString && (
                    <span className={'blue2Opacity6'}>
                      {scheduleStore.suffixString}
                    </span>
                  )}
                </Button>
                {scheduleStore.scheduleError && (
                  <ValidItem text={scheduleStore.scheduleError} mt={8} />
                )}
              </div>
            </MuiTooltip>
          </div>
        </ClickAwayListener>
      </>
    )
  }
)
