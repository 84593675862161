import { makeAutoObservable } from 'mobx'
import { RangeDatePickerStore } from 'store/rangeDatePickerStore/rangeDatePickerStore'

class PerformanceStore {
  rangeDatePickerStore: RangeDatePickerStore = new RangeDatePickerStore()
  constructor() {
    makeAutoObservable(this)
    this.rangeDatePickerStore = new RangeDatePickerStore()
  }
}

export default new PerformanceStore()
