import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import { CopyBtn } from 'components/Button/CopyBtn'
import { BtnSize } from 'components/Button/Button'
import { AddContactsToListDropdown } from 'src/Modals/ImportContactModal/StepsContent/Step3/AddContactsToListDropdown'
import React, { useEffect } from 'react'
import { InputText } from 'components/Input/InputText/InputText'
import styles from './styles.module.scss'
import { CustomFieldsContent } from 'src/routes/settings/integrations/modal/CustomIntegrationModal/CustomFieldsContent/CustomFieldsContent'
import customIntegrationModalStore from 'src/routes/settings/integrations/modal/CustomIntegrationModal/store/customIntegrationModalStore'
import { useGetContactWebhookUrlQuery } from 'src/generated/graphql'

export const CustomIntegrationModal = observer(() => {
  const {
    openModal,
    setOpenModal,
    customWebhookLink,
    setContactWebhookUrl,
    dropdownContactListsStore,
  } = customIntegrationModalStore

  const { data } = useGetContactWebhookUrlQuery({
    skip: !!customWebhookLink || !openModal,
  })
  useEffect(() => {
    if (data?.getContactWebhookUrl) {
      setContactWebhookUrl(data.getContactWebhookUrl)
    }
  }, [data])

  const onClose = () => {
    setOpenModal(false)
  }
  return (
    <Modal
      open={openModal}
      onClose={onClose}
      title={
        <div className={'row8'}>
          <h3 className={'bold'}>Add contacts</h3>
        </div>
      }
    >
      <div className={'mediumModal'}>
        <CustomFieldsContent />
        <AddContactsToListDropdown store={dropdownContactListsStore} />
        <div className={styles.wrapCustom}>
          <InputText value={customWebhookLink} disabled />
          <CopyBtn
            content={customWebhookLink}
            size={BtnSize.medium}
            linkIcon
            textBtn={'Copy link'}
          />
        </div>
      </div>
    </Modal>
  )
})
