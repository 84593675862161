import classNames from 'classnames'
import styles from './styles.module.scss'
import { FC } from 'react'

type Props = JSX.IntrinsicElements['button'] & {
  description: string | JSX.Element
  icon: JSX.Element
  active: boolean
  positive?: boolean
  negative?: boolean
  neutral?: boolean
  svgColor?: boolean
  svgColorRect?: boolean
  border?: boolean
  example?: string
}

export const CardButton: FC<Props> = ({
  description,
  icon,
  active,
  positive,
  negative,
  neutral,
  svgColor,
  svgColorRect,
  border,
  example,
  ...props
}) => {
  return (
    <button
      className={classNames(styles.cardBtn, active && styles.active)}
      {...props}
    >
      <div
        className={classNames(
          styles.wrapIcon,
          border && styles.border,
          positive && styles.positive,
          negative && styles.negative,
          neutral && styles.neutral,
          svgColor && styles.svgColor,
          svgColorRect && styles.svgColorRect
        )}
      >
        {icon}
      </div>
      <span className={'s2'}>{description}</span>
      {example && (
        <a
          href={example}
          target={'_blank'}
          rel="noreferrer"
          className={'s4'}
          onClick={(e) => e.stopPropagation()}
        >
          Example
        </a>
      )}
    </button>
  )
}
