import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import { AppApolloProvider } from 'src/providers/AppApolloProvider'
import { AuthorizedAppContent } from 'components/App/AuthorizedApp/AuthorizedAppContent'
import { GlobalAlert } from 'components/Alert/GlobalAlert'
import { OAuth } from 'components/App/AuthorizedApp/OAuth'
import { CommonComponents } from 'components/App/AuthorizedApp/CommonComponents'

export const AuthorizedApp = () => (
  <AppApolloProvider>
    <GlobalAlert />
    <CommonComponents />
    <OAuth />
    <AuthorizedAppContent />
  </AppApolloProvider>
)
