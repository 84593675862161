import {
  Listing,
  useGetShortMyOrganizationListsQuery,
} from 'src/generated/graphql'
import contactStore from 'store/contacts/contactStore'
import { useEffect, useState } from 'react'
import { useRefetch } from 'src/hooks/useRefetch'
import uiStore from 'store/uiStore'

export function useGetOrganizationLists(skip?: boolean) {
  const [firstLoading, setFirstLoading] = useState(true)
  const { data, refetch } = useGetShortMyOrganizationListsQuery({
    variables: {
      page: {
        pageNumber: 0,
        pageSize: 1000,
      },
      listingSearch: {
        searchPattern: null,
      },
    },
    skip,
  })
  useEffect(() => {
    if (data?.getMyOrganizationLists) {
      setFirstLoading(false)
      const listings: Listing[] = []
      data?.getMyOrganizationLists?.content?.forEach((field) => {
        if (field) {
          listings.push(<Listing>field)
        }
      })
      contactStore.addListing(listings)
    }
  }, [data])
  useRefetch(refetch)
  useEffect(() => {
    uiStore.setLoadingContent(['lists', firstLoading])
  }, [firstLoading])
}
