// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rT7TclKunPeIOrwNd0yu{z-index:2;position:relative}.eriHnLYneE2UJVboG8PG{padding:16px}.brFXBhd7a3tlATimZxG0{align-items:center;display:flex;margin-bottom:16px;gap:4px}.brFXBhd7a3tlATimZxG0 span{height:16px}.brFXBhd7a3tlATimZxG0 svg{color:var(--primary-color-gray-2)}.brFXBhd7a3tlATimZxG0 svg:hover{cursor:pointer;color:var(--primary-color-gray-1)}`, "",{"version":3,"sources":["webpack://./src/components/IdFieldButton/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,iBAAA,CAEF,sBACE,YAAA,CAEF,sBACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,2BACE,WAAA,CAEF,0BACE,iCAAA,CACA,gCACE,cAAA,CACA,iCAAA","sourcesContent":["@import \"src/styles/mixin\";\n\n.wrap{\n  z-index: 2;\n  position: relative;\n}\n.content{\n  padding: 16px;\n}\n.header{\n  align-items: center;\n  display: flex;\n  margin-bottom: 16px;\n  gap: 4px;\n  span{\n    height: 16px;\n  }\n  svg{\n    color: var(--primary-color-gray-2);\n    &:hover{\n      cursor: pointer;\n      color: var(--primary-color-gray-1);\n    }\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `rT7TclKunPeIOrwNd0yu`,
	"content": `eriHnLYneE2UJVboG8PG`,
	"header": `brFXBhd7a3tlATimZxG0`
};
export default ___CSS_LOADER_EXPORT___;
