import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useGetWebFormContactsQuery } from 'src/generated/graphql'
import { useWebFormDetailContext } from 'src/routes/main/webForms/detail/webFormDetailPage'
import { AdditionalContent } from 'src/routes/main/webForms/detail/WebFromDetailTable/AdditionalContent/AdditionalContent'
import { LeftStickyContent } from 'src/routes/main/webForms/detail/WebFromDetailTable/LeftStickyContent/LeftStickyContent'
import { observer } from 'mobx-react-lite'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { useSetData } from 'src/hooks/useSetData'
import { IContact } from 'src/types/IContact'

export const WebFromDetailTable = observer(() => {
  const webFormDetailStore = useWebFormDetailContext()
  const { webForm, tableStore, contacts } = webFormDetailStore
  const options = useTablePaginationOptions(tableStore)

  options.variables.uuid = webForm?.uuid || ''
  const queryResult = useGetWebFormContactsQuery({
    ...options,
    skip: !options.variables.dateRange
      ? false
      : !(options.variables.dateRange?.from && options.variables.dateRange?.to),
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => webFormDetailStore.setWebFormContactsData(data),
    refreshDeps: [tableStore.refreshTrigger],
    disabledRefetch: !(
      (options.variables.dateRange?.from && options.variables.dateRange?.to) ||
      tableStore.rangeDatePickerStore.allTime
    ),
  })

  return (
    <NewTable<IContact>
      // withCheckbox
      columns={tableStore.visibleColumns}
      rows={contacts}
      // withSearch
      maxHeight={'calc(100vh - 172px - 65px)'}
      additionalContent={<AdditionalContent />}
      leftStickyContent={<LeftStickyContent />}
      emptyContent={
        contacts.length ? undefined : (
          <EmptyStateStatistic
            description={
              'There are not any contacts that received the message yet'
            }
          />
        )
      }
      tableStore={tableStore}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      contentLoading={loading && !contacts.length}
    />
  )
})
