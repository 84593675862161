import styles from './styles.module.scss'
import { FC } from 'react'
import { Asset } from 'src/generated/graphql'

type Props = {
  images?: Asset[]
}

export const ImagesPreview: FC<Props> = ({ images }) => {
  return (
    <>
      {images?.map((img) => (
        <div className={styles.wrapImg} key={img.id}>
          <div
            style={{ backgroundImage: `url(${img.compressedUrl})` }}
            className={styles.blur}
          />
          <img
            src={img.compressedUrl || ''}
            className={styles.img}
            alt={'img'}
          />
        </div>
      ))}
    </>
  )
}
