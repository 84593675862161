import { observer } from 'mobx-react-lite'
import { SizedBox } from 'components/SizedBox'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { Dispatch, SetStateAction } from 'react'
import { InputCheckboxGroup } from 'components/Input/InputCheckbox/InputCheckboxGroup'
import styles from './styles.module.scss'
import { SimpleTextarea } from 'components/SimpleTextarea/SimpleTextarea'

export enum FeedbackEnum {
  bugs = 'Issues or bugs in the software',
  support = 'Quality of support',
  tooHard = 'It was too hard or difficult to use',
  temporarily = 'I only needed Call Loop temporarily',
  anotherSolution = 'I found another solution',
  budgetReasons = 'I can no longer afford it / budget reasons',
  other = 'Other',
}

export const mockAnswers = Object.values(FeedbackEnum)

export const FeedbackContent = observer(
  ({
    answers = mockAnswers,
    selectedAnswer,
    setSelectedAnswer,
    title = 'We`d like to know why you`re pausing your subscription?',
    withoutBudgetReason,
  }: {
    title?: string
    answers?: string[]
    selectedAnswer: string
    setSelectedAnswer: Dispatch<SetStateAction<string>>
    withoutBudgetReason?: boolean
  }) => {
    return (
      <div>
        <span className={'s2 medium gray1 mb8'}>{title}</span>
        <InputCheckboxGroup>
          {answers
            ?.filter((answer) =>
              withoutBudgetReason ? answer !== FeedbackEnum.budgetReasons : true
            )
            .map((answer) => (
              <InputCheckbox
                key={answer}
                label={answer}
                checked={selectedAnswer === answer}
                onChecked={() => setSelectedAnswer(answer)}
                radio
              />
            ))}
        </InputCheckboxGroup>
        {selectedAnswer ? (
          <div className={styles.wrapAnswer}>
            <SimpleTextarea
              label={'Is there anything else we should to know? (optional)'}
            />
          </div>
        ) : (
          <SizedBox height={121} />
        )}
      </div>
    )
  }
)
