import { makeAutoObservable } from 'mobx'
import { TableStore } from 'components/NewTable/store/TableStore'
import {
  AdminOrganizationComplianceListQuery,
  BillingStatus,
  BusinessProfileStatus,
  Direction,
  TollFreeVerificationProfile,
  TollFreeVerificationStatus,
} from 'src/generated/graphql'
import { IOrganizationComplianceResponse } from 'src/types/IOrganizationComplianceResponse'
import { superAdminComplianceColumns } from 'components/NewTable/columns/superAdmin/superAdminComplianceColumns'

class SuperAdminComplianceStore {
  tableStore: TableStore<IOrganizationComplianceResponse>
  constructor() {
    this.tableStore = new TableStore({
      columns: superAdminComplianceColumns,
      tableName: 'SuperAdminComplianceTable',
      orderBy: 'createdAt',
      order: Direction.Desc,
    })
    makeAutoObservable(this)
  }

  complianceOrganizationsMap: Map<string, IOrganizationComplianceResponse> =
    new Map()
  bpStatuses: BusinessProfileStatus[] = [BusinessProfileStatus.ReviewRequired]
  tfStatuses: TollFreeVerificationStatus[] = [
    TollFreeVerificationStatus.ReviewRequired,
  ]
  billingStatuses: BillingStatus[] = [BillingStatus.Active, BillingStatus.Trial]

  editTFProfile: TollFreeVerificationProfile | null = null

  get complianceOrganizations(): IOrganizationComplianceResponse[] {
    return Array.from(this.complianceOrganizationsMap.values())
  }

  setAdminOrganizationComplianceList(
    response: AdminOrganizationComplianceListQuery
  ) {
    const filteredData: IOrganizationComplianceResponse[] = []
    response.adminOrganizationComplianceList?.content?.forEach((item) => {
      if (item) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        filteredData.push({ ...item, id: item.id || '' })
      }
    })
    this.complianceOrganizationsMap = new Map<
      string,
      IOrganizationComplianceResponse
    >(filteredData.map((state) => [state.id, state]))
    this.tableStore.setTotal(
      response?.adminOrganizationComplianceList?.total || 0
    )
  }

  setBPStatuses = (statuses: BusinessProfileStatus[]) => {
    this.bpStatuses = statuses
  }

  setTfStatuses = (statuses: TollFreeVerificationStatus[]) => {
    this.tfStatuses = statuses
  }
  setBillingStatuses = (statuses: BillingStatus[]) => {
    this.billingStatuses = statuses
  }
}

export default new SuperAdminComplianceStore()
