import styles from './styles.module.scss'
import { Maybe } from 'graphql/jsutils/Maybe'

export const CopyField = ({ link }: { link?: Maybe<string> }) => {
  return (
    <div className={styles.wrap}>
      <span className={'ellipsisNowrap gray1'}>{link}</span>
    </div>
  )
}
