import { InputText } from 'components/Input/InputText/InputText'
import user from 'store/user/user'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { VirtualContactCard } from 'src/routes/settings/account/routes/MyProfile/VirtualContactCard/VirtualContactCard'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import { useEffect, useMemo, useState } from 'react'
import {
  useGetAllUserTimezonesQuery,
  useUpdateMemberMutation,
} from 'src/generated/graphql'
import accountStore from 'store/settings/accountStore'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const MyProfile = observer(() => {
  const { data } = useGetAllUserTimezonesQuery()
  const [updateMember, { loading }] = useUpdateMemberMutation()
  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [phone, setPhone] = useState(user.phone)
  const [timeZoneOption, setTimeZoneOption] = useState(user.timezoneOption)

  const setInit = () => {
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setPhone(user.phone)
    setTimeZoneOption(user.timezoneOption)
  }

  useEffect(() => {
    setInit()
  }, [user.member])

  useEffect(() => {
    if (data?.getAllUserTimezones) {
      accountStore.setTimezonesData(data?.getAllUserTimezones)
    }
  }, [data])

  const onSave = async () => {
    try {
      const { data } = await updateMember({
        variables: {
          member: {
            firstName,
            lastName,
            phone,
            timeZoneCode: timeZoneOption?.value,
          },
        },
      })
      if (data?.updateMember) {
        user.setMember(data.updateMember)
        alertStore.setAlert({
          type: AlertTypeEnum.success,
          title: 'Your profile is successfully updated!',
        })
      } else {
        setInit()
      }
    } catch (e) {
      console.error(e)
      setInit()
    }
  }
  const onCancel = () => {
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setPhone(user.phone)
    setTimeZoneOption(user.timezoneOption)
  }
  const haveChanges = useMemo(
    () =>
      firstName !== user.firstName ||
      lastName !== user.lastName ||
      phone !== user.phone ||
      timeZoneOption?.value !== user.timezoneOption?.value,
    [
      firstName,
      lastName,
      phone,
      timeZoneOption,
      user.timezone,
      user.firstName,
      user.lastName,
      user.phone,
    ]
  )
  return (
    <div className={styles.wrap}>
      <div>
        <div className={styles.content}>
          <div className={styles.row}>
            <InputText
              label={'First Name'}
              placeholder={'e.g. Adam'}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onClickRightIcon={() => setFirstName('')}
            />
            <InputText
              label={'Last Name'}
              placeholder={'e.g. Robertson'}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onClickRightIcon={() => setLastName('')}
            />
          </div>
          <InputPhone
            label={'Phone number'}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Dropdown
            labelInput={'Timezone'}
            options={accountStore.timezonesOptions}
            selectedOption={timeZoneOption}
            onSelect={(opt) => setTimeZoneOption(opt)}
            maxHeightDropdown={510}
          />
        </div>
        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onSave}
            disabled={!haveChanges}
            loading={loading}
          >
            Save profile
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onCancel}
            disabled={!haveChanges}
          >
            Cancel
          </Button>
        </div>
      </div>
      <VirtualContactCard />
    </div>
  )
})
