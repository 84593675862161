import { makeAutoObservable } from 'mobx'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import { IOption } from 'src/types/IOption'
import { nanoid } from 'nanoid'

class CustomIntegrationModalStore {
  dropdownContactListsStore = new DropdownContactListsStore()
  constructor() {
    makeAutoObservable(this)
  }

  openModal = false
  contactWebhookUrl = ''

  fieldsMap: Map<string, IOption | null> = new Map()

  get fields() {
    return Array.from(this.fieldsMap)
  }
  get customFields() {
    return Array.from(this.fieldsMap.values())
  }

  // customFieldsOptions

  get customWebhookLink() {
    const customFields =
      this.customFields
        .map((field) => (field?.value ? `&${field?.value}=` : ''))
        .join('') || ''
    const lists = this.dropdownContactListsStore.selectedContactListsIds.length
      ? `&listIds=${this.dropdownContactListsStore.selectedContactListsIds.join(
          ','
        )}`
      : ''

    return this.contactWebhookUrl + customFields + lists
  }

  setOpenModal = (value: boolean) => {
    this.openModal = value
  }

  setContactWebhookUrl = (url: string) => {
    this.contactWebhookUrl = url
  }

  addField = () => {
    this.fieldsMap.set(nanoid(), null)
  }

  removeField = (id: string) => {
    this.fieldsMap.delete(id)
  }

  updateField = (id: string, item: IOption | null) => {
    this.fieldsMap.set(id, item)
  }
}

export default new CustomIntegrationModalStore()
