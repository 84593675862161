import styles from './styles.module.scss'
import { useRef } from 'react'
import { Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16px/CloseCustom.svg'
import { ReactComponent as LeftArrow } from 'icons/16px/Angle-Left.svg'
import { ReactComponent as RightArrow } from 'icons/16px/Angle-Right.svg'
import { observer } from 'mobx-react-lite'
import presentationStore from 'store/presentationStore'
import { bytesToSize } from 'src/util/functions'

export const PresentationImages = observer(() => {
  const refWrapHover = useRef(false)
  const isPrev = presentationStore.currentIndex > 0
  const isNext =
    presentationStore.currentIndex <
    presentationStore.presentationImages.length - 1
  const onWrapClick = () => {
    if (!refWrapHover.current) {
      presentationStore.setPresentationImages([])
    }
  }
  const onMouseEnter = () => {
    refWrapHover.current = true
  }
  const onMouseLeave = () => {
    refWrapHover.current = false
  }
  if (
    !presentationStore.openPresentationMode ||
    !presentationStore.currentImg
  ) {
    return <></>
  }
  return (
    <div className={styles.wrap} onClick={onWrapClick} aria-hidden={true}>
      <img
        src={
          presentationStore.currentImg.previewUrl ||
          presentationStore.currentImg.sourceUrl ||
          ''
        }
        alt=""
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {isPrev && (
        <div
          className={styles.leftArrow}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Button modalNav onClick={() => presentationStore.onPrev()}>
            <LeftArrow />
            <span className={'s1 bold'}>Prev</span>
          </Button>
        </div>
      )}
      {isNext && (
        <div
          className={styles.rightArrow}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Button modalNav onClick={() => presentationStore.onNext()}>
            <span className={'s1 bold'}>Next</span>
            <RightArrow />
          </Button>
        </div>
      )}
      <div
        className={styles.imageInfo}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={styles.row}>
          <div className={styles.ext}>
            <span className={'s2 medium'}>
              {presentationStore.currentImg.extension}
            </span>
          </div>
          <p className={'par2 medium white'}>
            {bytesToSize(presentationStore.currentImg.sourceSize)}
          </p>
          <Button
            className={styles.close}
            onClick={() => presentationStore.setPresentationImages([])}
          >
            <Close />
          </Button>
        </div>
        <p className={'par2 gray1'}>
          {presentationStore.currentImg.originalFileName}
        </p>
      </div>
    </div>
  )
})
