import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  Status,
  useCreateTriggerMutation,
  useRemoveTriggersMutation,
  useUpdateTriggerMutation,
} from 'src/generated/graphql'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import triggersStore from 'store/triggers/triggersStore'
import { useCreateTriggerContext } from 'src/routes/main/triggers/create/state/CreateTriggerState'
import uiStore from 'store/uiStore'
import { acceptedCreate } from 'src/routes/settings/billing/functions/acceptedCreate'
import { transformTrigger } from 'store/triggers/functions'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { CloseCreateBtn } from 'components/Page/components/CloseCreateBtn'
import { AppElements } from 'src/enums/appElements'
import { useApolloError } from 'src/hooks/useApolloError'

export const ActionsContent = observer(() => {
  const createTriggerStore = useCreateTriggerContext()
  const { setTrigger, trigger, onTriggerClick, showTT, setShowTT } =
    useFixedTooltip()
  const navigate = useNavigate()
  const [createTrigger, { loading: loadingCreate, error: errorCreate }] =
    useCreateTriggerMutation()
  const [updateTrigger, { loading: loadingUpdate, error: errorUpdate }] =
    useUpdateTriggerMutation()
  const [removeTrigger] = useRemoveTriggersMutation()

  const { ErrorAlert } = useApolloError(errorCreate || errorUpdate)

  const onCreate = () => {
    const acceptedCB = () => {
      if (createTriggerStore.edit) {
        updateTrigger({
          variables: {
            triggerInput: createTriggerStore.updateTriggerInput,
          },
        })
          .then((res) => {
            if (res.data?.updateTrigger) {
              triggersStore.setTrigger(
                transformTrigger(res.data?.updateTrigger)
              )
            }
            navigate(RoutesEnum.triggers)
            uiStore.setNextRoute('')
          })
          .catch((e) => console.error(e))
      } else {
        createTrigger({
          variables: {
            triggerInput: createTriggerStore.createTriggerInput,
          },
        })
          .then((res) => {
            if (res.data?.createTrigger?.id) {
              triggersStore.setCreateTriggerId(
                String(res.data?.createTrigger?.id)
              )
              if (res.data?.createTrigger) {
                triggersStore.setTrigger(
                  transformTrigger(res.data?.createTrigger)
                )
              }
            }
            navigate(RoutesEnum.triggers)
            uiStore.setNextRoute('')
          })
          .catch((e) => console.error(e))
      }
    }

    acceptedCreate({
      limitFor: AppElements.Trigger,
      acceptedCB,
    })
  }
  const onArchive = () => {
    createTriggerStore.setStatus(Status.Archived)
  }
  const onDelete = () => {
    removeTrigger({
      variables: {
        triggersIds: [createTriggerStore.editTriggerId],
      },
    })
      .then(() => {
        navigate(RoutesEnum.triggers)
        uiStore.setNextRoute('')
      })
      .catch((e) => console.error(e))
  }
  return (
    <div className={styles.wrap}>
      <ErrorAlert />
      <div className={styles.actionsWrap}>
        {!createTriggerStore.openTest && (
          <div className={styles.left}>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={onCreate}
              disabled={createTriggerStore.disabledCreate}
              loading={loadingCreate || loadingUpdate}
            >
              {createTriggerStore.edit
                ? 'Save'
                : createTriggerStore.createBtnText}
            </Button>
            <CloseCreateBtn fromActions />
          </div>
        )}
        {createTriggerStore.edit && !createTriggerStore.openTest ? (
          <>
            <FixedTooltip
              visible={showTT}
              trigger={trigger}
              white
              noArrow
              position={TTPositionEnum.topRight}
              addLeftPosition={8}
            >
              <div className={styles.menuWrap}>
                <MenuBtn
                  type={
                    createTriggerStore.activeTab === 0
                      ? MenuEnum.testMessage
                      : MenuEnum.testAudio
                  }
                  onClick={() => {
                    createTriggerStore.setOpenTest(true)
                    setShowTT(false)
                  }}
                />
                <MenuBtn type={MenuEnum.archive} onClick={onArchive} />
                <MenuBtn type={MenuEnum.delete} onClick={onDelete} />
              </div>
            </FixedTooltip>
            <Button
              ref={setTrigger}
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={onTriggerClick}
              icon
            >
              <More />
            </Button>
          </>
        ) : (
          <TestItBtn
            fromCreate
            setOpenTest={(state) => createTriggerStore.setOpenTest(state)}
            openTest={createTriggerStore.openTest}
            testMessageInput={
              createTriggerStore.activeTab === 0
                ? {
                    smsMessage: {
                      ...createTriggerStore.createTriggerInput.smsMessage,
                      compliance:
                        !!createTriggerStore.createTriggerInput.smsMessage
                          ?.compliance,
                      text:
                        createTriggerStore.createTriggerInput.smsMessage
                          ?.text || '',
                    },
                    toNumber: '',
                    fromNumberId:
                      createTriggerStore.createTriggerInput.sendFromPhoneNumber
                        ?.id,
                  }
                : undefined
            }
            testCallMessageRequestInput={
              createTriggerStore.activeTab === 1
                ? {
                    voiceMessage:
                      createTriggerStore.createTriggerInput.voiceMessage,
                    toNumber: '',
                    fromNumberId:
                      createTriggerStore.createTriggerInput.sendFromPhoneNumber
                        ?.id,
                  }
                : undefined
            }
            testRinglessMessageRequestInput={
              createTriggerStore.activeTab === 2
                ? {
                    ...createTriggerStore.createTriggerInput.ringlessMessage,
                    toNumber: '',
                    fromNumberId:
                      createTriggerStore.createTriggerInput.sendFromPhoneNumber
                        ?.id,
                  }
                : undefined
            }
            fromAudio={createTriggerStore.activeTab !== 0}
          />
        )}
      </div>
    </div>
  )
})
