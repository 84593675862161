import { ActionsEnum } from 'components/Button/types'
import { ContactBulkOperationRequestInput, Status } from 'src/generated/graphql'
import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { IContact } from 'src/types/IContact'
import { ContactEditBtn } from 'src/routes/main/contacts/components/ContactsEditBtn'
import { ContactsValidateBtn } from 'src/routes/main/contacts/components/ContactsValidateBtn'
import { ContactsUnsubscribeBtn } from 'src/routes/main/contacts/components/ContactsUnsubscribeBtn'
import { ContactsArchiveBtn } from 'src/routes/main/contacts/components/ContactsArchiveBtn'
import { ContactsDeleteBtn } from 'src/routes/main/contacts/components/ContactsDeleteBtn'
import { ContactsSubscribeBtn } from 'src/routes/main/contacts/components/ContactsSubscribeBtn'
import { ContactsReloadBtn } from 'src/routes/main/contacts/components/ContactsReloadBtn'
import { BtnSize } from 'components/Button/Button'
import { ContactsEditLists } from 'src/routes/main/contacts/components/ContactsEditLists'
import { ContactsUnarchivedBtn } from 'src/routes/main/contacts/components/ContactsUnarchivedBtn'

export type IContactActions = ActionBtnProps<IContact> & {
  extraBtnSize?: BtnSize
  bulk?: ContactBulkOperationRequestInput
  isNoEdit?: boolean
}

export function getContactActions(
  props: IContactActions
): Array<FC<ActionBtnProps<IContact>>> {
  const actions: { [key: string]: FC<ActionBtnProps<IContact>> } = {
    [ActionsEnum.edit]: (actionProps) =>
      props.isNoEdit ? null : ContactEditBtn({ ...actionProps, ...props }),
    [ActionsEnum.editLists]: (actionProps) =>
      ContactsEditLists({ ...actionProps, ...props }),
    [ActionsEnum.resubscribe]: (actionProps) =>
      ContactsSubscribeBtn({ ...actionProps, ...props }),
    [ActionsEnum.unsubscribe]: (actionProps) =>
      ContactsUnsubscribeBtn({ ...actionProps, ...props }),
    [ActionsEnum.validatePhone]: (actionProps) =>
      ContactsValidateBtn({
        ...actionProps,
        ...props,
      }),
    [ActionsEnum.archive]: (actionProps) =>
      ContactsArchiveBtn({
        ...actionProps,
        ...props,
      }),
    [ActionsEnum.unarchive]: (actionProps) =>
      ContactsUnarchivedBtn({
        ...actionProps,
        ...props,
      }),
    [ActionsEnum.delete]: (actionProps) =>
      ContactsDeleteBtn({
        ...actionProps,
        ...props,
      }),
    [ActionsEnum.reload]: (actionProps) =>
      ContactsReloadBtn({ ...actionProps, ...props }),
  }

  const obj: { [key: string]: Array<FC<ActionBtnProps<IContact>>> } = {
    [Status.Active]: [
      actions[ActionsEnum.edit],
      actions[ActionsEnum.validatePhone],
      actions[ActionsEnum.unsubscribe],
      actions[ActionsEnum.archive],
      actions[ActionsEnum.delete],
    ],
    [Status.Unsubscribed]: [
      actions[ActionsEnum.edit],
      actions[ActionsEnum.resubscribe],
      actions[ActionsEnum.archive],
      actions[ActionsEnum.delete],
    ],
    [Status.Invalid]: [
      actions[ActionsEnum.edit],
      actions[ActionsEnum.archive],
      actions[ActionsEnum.delete],
    ],
    [Status.Archived]: [
      actions[ActionsEnum.unarchive],
      actions[ActionsEnum.delete],
    ],
    [Status.Inactive]: [
      actions[ActionsEnum.reload],
      actions[ActionsEnum.edit],
      actions[ActionsEnum.archive],
      actions[ActionsEnum.delete],
    ],
  }

  const manyActions: Array<FC<ActionBtnProps<IContact>>> = [
    actions[ActionsEnum.editLists],
    actions[ActionsEnum.validatePhone],
    actions[ActionsEnum.resubscribe],
    actions[ActionsEnum.unsubscribe],
    actions[ActionsEnum.archive],
    actions[ActionsEnum.unarchive],
    actions[ActionsEnum.delete],
  ]

  const status = props.row?.status
  if (status) {
    return obj[status] || []
  }
  if (props.bulk) {
    return manyActions
  }
  return []
}
