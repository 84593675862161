// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me7_nkTpYqPAJTV3nhiA{display:flex;column-gap:4px;flex-wrap:nowrap}.Bj4u1OK8sNLIKeW9aqFB{padding-top:4px}.Bj4u1OK8sNLIKeW9aqFB .xvwtDD9GDn31ByX9aeG4{margin-top:8px;max-height:128px;overflow:auto}.Bj4u1OK8sNLIKeW9aqFB .xvwtDD9GDn31ByX9aeG4 .U3mtsUayjqiJ_aYDKnTh{max-width:250px;overflow:hidden}.Bj4u1OK8sNLIKeW9aqFB .xvwtDD9GDn31ByX9aeG4 .U3mtsUayjqiJ_aYDKnTh .labelTag{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.Bj4u1OK8sNLIKeW9aqFB .lafFV01huL71aeBXhvcS{padding-right:4px;padding-left:4px;width:100%;justify-content:left}`, "",{"version":3,"sources":["webpack://./src/components/Dropdown/TagsRender/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,gBAAA,CAEF,sBACE,eAAA,CACA,4CACE,cAAA,CACA,gBAAA,CACA,aAAA,CACA,kEACE,eAAA,CACA,eAAA,CAEE,4EACE,eAAA,CACA,kBAAA,CACA,sBAAA,CAKR,4CACE,iBAAA,CACA,gBAAA,CACA,UAAA,CACA,oBAAA","sourcesContent":[".tagWrap{\n  display: flex;\n  column-gap: 4px;\n  flex-wrap: nowrap;\n}\n.tooltipContent{\n  padding-top: 4px;\n  .optionContent{\n    margin-top: 8px;\n    max-height: 128px;\n    overflow: auto;\n    .tag{\n      max-width: 250px;\n      overflow: hidden;\n      :global{\n        .labelTag{\n          overflow: hidden;\n          white-space: nowrap;\n          text-overflow: ellipsis;\n        }\n      }\n    }\n  }\n  .optBtn{\n    padding-right: 4px;\n    padding-left: 4px;\n    width: 100%;\n    justify-content: left;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagWrap": `me7_nkTpYqPAJTV3nhiA`,
	"tooltipContent": `Bj4u1OK8sNLIKeW9aqFB`,
	"optionContent": `xvwtDD9GDn31ByX9aeG4`,
	"tag": `U3mtsUayjqiJ_aYDKnTh`,
	"optBtn": `lafFV01huL71aeBXhvcS`
};
export default ___CSS_LOADER_EXPORT___;
