import { makeObservable, runInAction } from 'mobx'
import {
  GetMyOrganizationListsQueryVariables,
  GetShortMyOrganizationListsDocument,
  GetShortMyOrganizationListsQuery,
  ListResponseFragment,
} from 'src/generated/graphql'
import { contactListToOption } from 'store/contacts/functions'
import apiSore from 'store/apiSore'
import { ApolloQueryResult } from '@apollo/client'

class ListsStore {
  constructor() {
    makeObservable(this)
  }

  isInit = false
  initRequest: null | Promise<
    ApolloQueryResult<GetShortMyOrganizationListsQuery>
  > = null

  listMap: Map<number, ListResponseFragment> = new Map()

  initData = async () => {
    try {
      if (this.isInit) return
      if (this.initRequest) return this.initRequest.then()
      this.initRequest = apiSore.client.query<
        GetShortMyOrganizationListsQuery,
        GetMyOrganizationListsQueryVariables
      >({
        query: GetShortMyOrganizationListsDocument,
        variables: {
          page: {
            pageNumber: 0,
            pageSize: 1000,
          },
          listingSearch: {
            searchPattern: '',
          },
        },
      })
      const { data } = await this.initRequest
      if (data) {
        data.getMyOrganizationLists?.content?.forEach((list) => {
          if (list) {
            this.listMap.set(list.id, list)
          }
        })
      }
      runInAction(() => {
        this.isInit = true
      })
    } catch (e) {
      console.error(e)
    }
  }

  getList = async (id: number) => {
    await this.initData()
    const list = this.listMap.get(id)
    if (list) {
      return list
    }
  }

  getListOption = async (id: number) => {
    const list = await this.getList(id)
    if (list) {
      return contactListToOption(list)
    }
  }
}

export default new ListsStore()
