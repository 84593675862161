import React, { FC, ReactNode } from 'react'
import { ReactComponent as ArrowDown } from 'icons/12px/Arrow-Down.svg'
import { ClassesEnum } from 'styles/classes'
import styles from './styles.module.scss'
import classNames from 'classnames'

type Props = {
  active: boolean
  direction: string
  onClick: () => void
  children?: ReactNode
}

export const SortLabel: FC<Props> = ({
  children,
  onClick,
  active,
  direction,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.sortBtn, active && styles.active)}
    >
      {children}
      {active &&
        (direction === 'asc' ? (
          <span className={ClassesEnum.rotate}>
            <ArrowDown />
          </span>
        ) : (
          <ArrowDown />
        ))}
    </button>
  )
}
