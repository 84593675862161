import React, { useEffect, useRef, useState } from 'react'
import styles from 'src/Modals/VerifyModal/styles.module.scss'
import classNames from 'classnames'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { SizedBox } from 'components/SizedBox'
import accountStore from 'store/settings/accountStore'
import alertStore from 'store/alertStore'

export const VerifyContent = observer(
  ({ fromForgotPage }: { fromForgotPage?: boolean }) => {
    const [loading, setLoading] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)
    const [error, setError] = useState(false)
    const [resendSeconds, setResendsSeconds] = useState(0)
    const ref0 = useRef<HTMLInputElement>(null)
    const ref1 = useRef<HTMLInputElement>(null)
    const ref2 = useRef<HTMLInputElement>(null)
    const ref3 = useRef<HTMLInputElement>(null)
    const ref4 = useRef<HTMLInputElement>(null)
    const ref5 = useRef<HTMLInputElement>(null)
    const refBtn = useRef<HTMLButtonElement>(null)
    const initValues = Array(accountStore.verifySetting?.size || 4)
      .fill(0)
      .map(() => '')
    const [value, setValue] = useState(initValues)
    const onCancel = () => {
      if (accountStore.verifySetting?.onCancel) {
        accountStore.verifySetting?.onCancel()
      }
      accountStore.setOpenVerifyNumberModal(false)
    }
    const onVerify = async () => {
      const code = value.join('')
      if (accountStore.verifySetting?.onVerify) {
        try {
          setLoading(true)
          await accountStore.verifySetting?.onVerify(code)
          onCancel()
          setLoading(false)
        } catch (e) {
          console.error(e)
          if (e instanceof Error) {
            alertStore.disabledErrorAlert(e.message)
          }
          setError(true)
          setLoading(false)
        }
      } else {
        onCancel()
      }
    }

    const onValueChange = (val: string, index: number) => {
      if (!val.match(/[0-9]/g)) {
        return
      }
      setValue((prevState) =>
        prevState.map((item, indexArr) =>
          indexArr === index ? val.replace(/_/g, '') : item
        )
      )
      if (index === 0) {
        ref1.current?.focus()
      }
      if (index === 1) {
        ref2.current?.focus()
      }
      if (index === 2) {
        ref3.current?.focus()
      }
      if (
        !accountStore.verifySetting?.size ||
        accountStore.verifySetting?.size === 4
      ) {
        if (index === 3) {
          setTimeout(() => {
            refBtn.current?.focus()
          })
        }
      }
      if (accountStore.verifySetting?.size === 6) {
        if (index === 3) {
          ref4.current?.focus()
        }
        if (index === 4) {
          ref5.current?.focus()
        }
        if (index === 5) {
          setTimeout(() => {
            refBtn.current?.focus()
          })
        }
      }
    }
    const onFocus = (index: number) => {
      setValue((prevState) =>
        prevState.map((item, indexArr) => (indexArr === index ? '_' : item))
      )
    }
    const onPaste: React.ClipboardEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      const numbers = parseInt(text)
      if (numbers) {
        setValue(String(numbers).split(''))
      }
    }

    useEffect(() => {
      if (fromForgotPage) {
        setValue(initValues)
        ref0.current?.focus()
        setResendsSeconds(
          accountStore.verifySetting?.resendRemainingSeconds || 15
        )
      }
    }, [fromForgotPage])
    useEffect(() => {
      if (accountStore.openVerifyNumberModal) {
        setValue(initValues)
        ref0.current?.focus()
        setResendsSeconds(
          accountStore.verifySetting?.resendRemainingSeconds || 15
        )
      }
    }, [accountStore.openVerifyNumberModal])
    useEffect(() => {
      if (resendSeconds > 0) {
        setTimeout(() => {
          setResendsSeconds(resendSeconds - 1)
        }, 1000)
      }
    }, [resendSeconds])
    const handleResend = async () => {
      if (accountStore.verifySetting?.onResend) {
        try {
          setResendLoading(true)
          await accountStore.verifySetting.onResend()
          setResendsSeconds(
            accountStore.verifySetting?.resendRemainingSeconds || 15
          )
        } catch (e) {
          console.error(e)
        } finally {
          setResendLoading(false)
        }
      }
    }
    return (
      <div
        className={classNames(
          styles.wrap,
          fromForgotPage && styles.fromForgotPage
        )}
      >
        <p className={'medium mb8'}>
          Enter the {accountStore.verifySetting?.size || 4}-digit code
        </p>
        <span className={'gray1'}>{accountStore.verifySetting?.info1}</span>
        <div
          className={classNames(
            styles.inputsWrap,
            accountStore.verifySetting?.size === 6 && styles.size6
          )}
        >
          <input
            onPaste={onPaste}
            ref={ref0}
            type="text"
            value={value[0]}
            onChange={(e) => onValueChange(e.target.value, 0)}
            onFocus={() => onFocus(0)}
          />
          <input
            ref={ref1}
            type="text"
            value={value[1]}
            onChange={(e) => onValueChange(e.target.value, 1)}
            onFocus={() => onFocus(1)}
          />
          <input
            ref={ref2}
            type="text"
            value={value[2]}
            onChange={(e) => onValueChange(e.target.value, 2)}
            onFocus={() => onFocus(2)}
          />
          <input
            ref={ref3}
            type="text"
            value={value[3]}
            onChange={(e) => onValueChange(e.target.value, 3)}
            onFocus={() => onFocus(3)}
          />
          {accountStore.verifySetting?.size === 6 && (
            <>
              <input
                ref={ref4}
                type="text"
                value={value[4]}
                onChange={(e) => onValueChange(e.target.value, 4)}
                onFocus={() => onFocus(4)}
              />
              <input
                ref={ref5}
                type="text"
                value={value[5]}
                onChange={(e) => onValueChange(e.target.value, 5)}
                onFocus={() => onFocus(5)}
              />
            </>
          )}
        </div>
        {error && (
          <>
            <SizedBox height={16} />
            <ValidItem
              text={
                'The verification code you entered doesn’t match the one we provided'
              }
            />
          </>
        )}
        <SizedBox height={32} />

        <span className={'gray1 mb8'}>{accountStore.verifySetting?.info2}</span>
        <br />
        {resendSeconds ? (
          <span className={'gray1'}>
            Resend again in 0:
            {resendSeconds >= 10 ? resendSeconds : `0${resendSeconds}`}
          </span>
        ) : (
          <button
            className={'link'}
            onClick={handleResend}
            disabled={resendLoading}
          >
            <b>Request again</b>
          </button>
        )}

        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onVerify}
            ref={refBtn}
            loading={loading}
            disabled={
              value.filter((val) => val !== '_').join('').length !==
              (accountStore.verifySetting?.size || 4)
            }
            fullWidth={fromForgotPage}
          >
            Verify
          </Button>
          {!fromForgotPage && (
            <Button
              size={BtnSize.medium}
              typeBtn={BtnType.secondaryGray}
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    )
  }
)
