import { observer } from 'mobx-react-lite'
import numbersStore from 'store/settings/numbers/numbersStore'
import { PhoneType, VerificationStatus } from 'src/generated/graphql'
import * as React from 'react'
import { ForwardCallsAction } from 'src/routes/settings/numbers/actions/ForwardCallsAction'
import { VerifyNumberAction } from 'src/routes/settings/numbers/actions/VerifyNumberAction'

const VerifyBtn = observer(() => {
  if (
    numbersStore.tableStore.checkedRows.length &&
    numbersStore.tableStore.checkedRows.every(
      (row) =>
        row.type === PhoneType.TollFree &&
        row.verificationStatus === VerificationStatus.Unverified
    )
  ) {
    return <VerifyNumberAction medium />
  }
  return <></>
})

export const ManySelectContent = observer(() => {
  return (
    <div className={'row12'}>
      <VerifyBtn />
      {numbersStore.tableStore.checkedRows.every(
        (row) => row.type === PhoneType.TollFree
      ) && (
        <ForwardCallsAction rows={numbersStore.tableStore.checkedRows} medium />
      )}
    </div>
  )
})
