import { useMemo } from 'react'
import { CreateKeywordState } from 'src/routes/main/keywords/create/CreateKeywordState'
import { EditKeywordContent } from 'src/routes/main/keywords/create/EditKeywordContent'

export const EditKeywordPage = () => {
  return useMemo(
    () => (
      <CreateKeywordState>
        <EditKeywordContent />
      </CreateKeywordState>
    ),
    []
  )
}
