import { makeAutoObservable } from 'mobx'
import { UserTimeZone } from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'

export const timezoneToOption = (timezone?: UserTimeZone | null): IOption => ({
  title: timezone?.fullName || '',
  value: timezone?.zoneId,
})

class AccountStore {
  constructor() {
    makeAutoObservable(this)
  }

  openVerifyNumberModal = false
  openCreatePasswordModal = false

  verificationCode = 0

  verifySetting: {
    title: string
    info1: string
    info2: string
    phone?: string
    size: number
    resendRemainingSeconds?: number
    onVerify: (code: string) => Promise<any>
    onResend: () => Promise<any>
    onCancel?: () => void
  } | null = null

  timezonesOptions: IOption[] = []

  setOpenVerifyNumberModal(
    state: boolean,
    verifySetting: typeof this.verifySetting | null = null
  ) {
    this.verifySetting = verifySetting
    this.openVerifyNumberModal = state
  }

  setOpenCreatePasswordModal(state: boolean) {
    this.openCreatePasswordModal = state
  }

  setVerificationCode(code: number) {
    this.verificationCode = code
  }
  setTimezonesData(timeZones: Array<UserTimeZone | null>) {
    const timeZonesOptions: IOption[] = []
    timeZones.forEach((tz) => {
      if (tz) {
        timeZonesOptions.push(timezoneToOption(tz))
      }
    })

    this.timezonesOptions = timeZonesOptions
  }

  setVerifySetting(verifySetting: typeof this.verifySetting | null = null) {
    this.verifySetting = verifySetting
  }
}

export default new AccountStore()
