import styles from './styles.module.scss'
import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'
import { NavItem } from 'components/NavBar/NavItem'
import { navItemsSettings } from 'components/NavBar/navItems'
import user from 'store/user/user'
import uiStore from 'store/uiStore'

export const WrapPageSuperAdmin: FC<{
  title: string
  actions?: ReactNode
  children?: ReactNode
}> = ({ title, children, actions }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    uiStore.setMobilePage(title)
    return () => {
      uiStore.setMobilePage('')
    }
  }, [title])
  return (
    <div className={classNames(styles.page)}>
      <div
        className={classNames(
          styles.content,
          'main-container',
          id && styles.orgPage
        )}
      >
        <div>
          {id && !uiStore.isMobile && <BreadCrumbs />}
          <div className={styles.header}>
            <div className={'row8'}>
              {id && (
                <Button
                  hoverGray
                  size={BtnSize.small}
                  onClick={() => navigate(-1)}
                >
                  <Angle />
                </Button>
              )}
              {!uiStore.isMobile && <h1>{title}</h1>}
            </div>
            <div className={'row12'}>
              {actions}
              {!id && user.isSysadmin && (
                <NavItem
                  navItem={navItemsSettings[navItemsSettings.length - 1]}
                />
              )}
            </div>
          </div>
        </div>

        {children}
      </div>
    </div>
  )
}
