import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import { observer } from 'mobx-react-lite'
import { useWebFormDetailContext } from 'src/routes/main/webForms/detail/webFormDetailPage'

export const LeftStickyContent = observer(() => {
  const webFormDetailStore = useWebFormDetailContext()
  return (
    <div>
      <RangeDatePicker
        rangeDatePickerStore={
          webFormDetailStore.tableStore.rangeDatePickerStore
        }
      />
    </div>
  )
})
