// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GLkIMWQlUtB_qLT33cI6{position:relative;width:100%;height:100%;background-color:var(--primary-color-white)}.qw3HzNR7t_PXGg1IOzfB{display:grid;gap:48px;margin-left:64px;padding-bottom:64px}@media(max-width: 800px){.qw3HzNR7t_PXGg1IOzfB{margin-left:0;max-width:100vw}}@media(max-height: 600px){.qw3HzNR7t_PXGg1IOzfB{margin-left:0;max-width:100vw}}.OIOhPN6Mfif9sRVCEJJX{display:grid;gap:48px;grid-template-columns:1fr 1fr}@media(max-width: 1500px){.OIOhPN6Mfif9sRVCEJJX{grid-template-columns:1fr}}`, "",{"version":3,"sources":["webpack://./src/routes/superAdmin/routes/organization/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,2CAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,gBAAA,CACA,mBAAA,CACA,yBALF,sBAMI,aAAA,CACA,eAAA,CAAA,CAEF,0BATF,sBAUI,aAAA,CACA,eAAA,CAAA,CAGJ,sBACE,YAAA,CACA,QAAA,CACA,6BAAA,CACA,0BAJF,sBAKI,yBAAA,CAAA","sourcesContent":[".emptyContent{\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background-color: var(--primary-color-white);\n}\n\n.content{\n  display: grid;\n  gap: 48px;\n  margin-left: 64px;\n  padding-bottom: 64px;\n  @media (max-width: 800px) {\n    margin-left: 0;\n    max-width: 100vw;\n  }\n  @media (max-height: 600px) {\n    margin-left: 0;\n    max-width: 100vw;\n  }\n}\n.info{\n  display: grid;\n  gap: 48px;\n  grid-template-columns: 1fr 1fr;\n  @media (max-width: 1500px) {\n    grid-template-columns: 1fr;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyContent": `GLkIMWQlUtB_qLT33cI6`,
	"content": `qw3HzNR7t_PXGg1IOzfB`,
	"info": `OIOhPN6Mfif9sRVCEJJX`
};
export default ___CSS_LOADER_EXPORT___;
