import { DetailsModalContent } from 'components/DetailsModalContent/DetailsModalContent'
import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import campaignsStore from 'store/campaigns/campaignsStore'

export const CampaignDetailModal = observer(() => {
  const onClose = () => {
    campaignsStore.setCampaignDetailModal(null)
  }
  return (
    <Modal
      open={campaignsStore.openCampaignDetailModal}
      onClose={onClose}
      title={'Details'}
      topRight
    >
      {campaignsStore.campaignDetailModal && (
        <DetailsModalContent element={campaignsStore.campaignDetailModal} />
      )}
    </Modal>
  )
})
