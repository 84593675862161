import styles from './styles.module.scss'
import classNames from 'classnames'
import { Icon, IIcon } from 'components/Icon/Icon'

type Props = {
  type?:
    | 'info'
    | 'success'
    | 'warning'
    | 'upgrade'
    | 'infoWarning1'
    | 'infoError1'
  icon?: IIcon
  fontSize?: number
}

export const ModalIcon = ({ type = 'success', icon, fontSize }: Props) => {
  const getIcon = (): IIcon => {
    if (type === 'success') {
      return 'check'
    }
    if (type === 'warning') {
      return 'alert'
    }
    if (type === 'upgrade') {
      return 'tick'
    }
    return 'info1'
  }

  const getFontSize = () => {
    if (type === 'upgrade') {
      return 32
    }
  }
  return (
    <div className={classNames(styles.iconWrap, styles[type])}>
      <div className={classNames(styles.icon, styles[type])}>
        <Icon icon={icon || getIcon()} fontSize={fontSize || getFontSize()} />
      </div>
    </div>
  )
}
