import { makeAutoObservable } from 'mobx'
import { SIZE_LIMIT_5_MB } from 'src/static/constants'
import { TableStore } from 'components/NewTable/store/TableStore'
import { audioColumns } from 'store/audioStore/audioColumns'
import { IAudioItem } from 'src/types/IAudioItem'
import axios from 'axios'
import {
  AudioItem,
  AudioType,
  GetAllAudioItemsQuery,
} from 'src/generated/graphql'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

class AudioStore {
  tableStore: TableStore<IAudioItem>
  constructor() {
    this.tableStore = new TableStore({
      orderBy: 'createdAt',
      columns: audioColumns,
      tableName: 'AudioTable',
    })
    makeAutoObservable(this)
  }
  durationsMap: Map<string, number> = new Map()
  openModal = false
  forVoice = false
  openUploadModal = false
  openCreateToSpeechModal = false
  successUploadFIleCallback: ((file: IAudioItem) => void) | null = null
  audioFilesMap: Map<string, IAudioItem> = new Map<string, IAudioItem>()

  selectedMicroGroupId = ''
  selectedMicroDeviceId = ''
  playAudioId = ''

  isGranted = false

  setGranted(isGranted: boolean) {
    this.isGranted = isGranted
  }

  setSelectedMicro(groupId: string, deviceId: string) {
    this.selectedMicroGroupId = groupId
    this.selectedMicroDeviceId = deviceId
  }

  setAudioItemsData(data: GetAllAudioItemsQuery) {
    if (data.getAllAudioItems) {
      this.tableStore.setTotal(data.getAllAudioItems.total)
      data.getAllAudioItems.content?.forEach((item) => {
        if (item) {
          this.audioFilesMap.set(item.id, { id: item.id, ...item })
        }
      })
    }
  }

  onOpenModal(forVoice?: boolean) {
    this.forVoice = !!forVoice
    this.openModal = true
  }
  onCloseModal() {
    this.forVoice = false
    this.openModal = false
    this.successUploadFIleCallback = null
  }
  onOpenUploadModal() {
    this.openUploadModal = true
  }
  onCloseUploadModal() {
    this.openUploadModal = false
  }

  async uploadAudio(file: File | Blob, type: AudioType, fileName?: string) {
    const formData = new FormData()
    const fileJson = new File([JSON.stringify({ type })], 'fileInfo.json', {
      type: 'application/json',
    })
    formData.append('file', file, file instanceof File ? file.name : fileName)
    formData.append('fileInfo', fileJson)
    try {
      const { data } = await axios.post('assets/upload/audio', formData)
      this.audioFilesMap.set(data.id, data)
      alertStore.setAlert({
        type: AlertTypeEnum.success,
        title: 'Audio added',
      })
      this.tableStore.onRefresh()
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message)
      }
      console.error(e)
    }
  }
  async onAddFiles(files: File[], withError?: boolean) {
    const acceptFiles = files.filter((file) => file.size <= SIZE_LIMIT_5_MB)
    const promises: Promise<void>[] = []
    if (acceptFiles.length) {
      acceptFiles.forEach((file) => {
        promises.push(this.uploadAudio(file, AudioType.Uploaded))
      })
    }
    try {
      await Promise.all(promises)
      this.openUploadModal = false
    } catch (e) {
      if (withError) {
        throw e
      }
      console.error(e)
    }
  }
  onSelectAudio(id: string) {
    if (this.successUploadFIleCallback) {
      const audio = this.audioFilesMap.get(id)
      if (audio) {
        this.successUploadFIleCallback(audio)
      }
      this.onCloseModal()
    }
  }
  setSuccessUploadFIleCallback(fn: (file: IAudioItem) => void) {
    this.successUploadFIleCallback = fn
  }
  deleteAudios(ids: string[]) {
    this.tableStore.setCheckedRows(
      this.tableStore.checkedRows.filter(
        (broadcast) => !ids.includes(broadcast.id)
      )
    )
    this.tableStore.onRefresh()
  }
  onEditNameAudio(id: string, name: string) {
    const prevAudio = this.audioFilesMap.get(id)
    if (prevAudio) {
      this.audioFilesMap.set(prevAudio.id, { ...prevAudio, name })
    }
  }
  updateAudioItem(audioItem: AudioItem) {
    this.audioFilesMap.set(audioItem.id, { id: audioItem.id, ...audioItem })
  }
  setPlayAudioId(id: string) {
    this.playAudioId = id
  }

  setDuration = (src: string, duration: number) => {
    this.durationsMap.set(src, duration)
  }
}

export default new AudioStore()
