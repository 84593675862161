import { ActionBtnProps } from 'components/NewTable/types'
import { IBroadcast } from 'src/types/IBroadcast'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useBroadcastFunctions } from 'src/routes/main/broadcasts/hooks/useBroadcastFunctions'

export const BroadcastDuplicateBtn = (props: ActionBtnProps<IBroadcast>) => {
  const { row, rows } = props
  const { onDuplicate } = useBroadcastFunctions(row, rows)
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.duplicate}
      onAction={onDuplicate}
    />
  )
}
