import styles from './styles.module.scss'
import { Tabs } from 'components/Tabs/Tabs'
import { UploadContent } from 'components/Textarea/AddMediaContent/UploadContent/UploadContent'
import { MyImagesContent } from 'components/Textarea/AddMediaContent/MyImagesContent/MyImagesContent'
import classNames from 'classnames'
import { FC } from 'react'
import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import mediaStore from 'store/mediaStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { ErrorsEnum } from 'src/static/errors'

type Props = {
  activeModalTab: number
  setActiveModalTab: (val: number) => void
  uploadSelectedIds: string[]
  disabledAddImages: boolean
  showFailedUpload?: boolean
  selectedUploadSizeString: string
  onDeselectUploadImagesAll: () => void
  onSelectUploadImagesAll: () => void
  onAddImage: () => void
  onSelectUploadImg: (id: string) => void
  isCheckedUploadImg: (id: string) => boolean
  removeAttachImages: (ids: string[]) => void
}

export const AddMediaContent: FC<Props> = observer(
  ({
    activeModalTab,
    setActiveModalTab,
    disabledAddImages,
    uploadSelectedIds,
    selectedUploadSizeString,
    onSelectUploadImagesAll,
    onDeselectUploadImagesAll,
    onAddImage,
    onSelectUploadImg,
    isCheckedUploadImg,
    removeAttachImages,
  }) => {
    return (
      <div className={classNames(styles.wrap)}>
        <div className={styles.infoWrap}>
          {mediaStore.showError ? (
            <Alert
              type={AlertTypeEnum.error}
              title={`Upload failed`}
              text={
                mediaStore.subTitleError || (
                  <>
                    Please upload the following file types:{' '}
                    <b>*.png, *.jpg, *.gif, *.vcard</b>
                  </>
                )
              }
            />
          ) : (
            <Alert
              type={
                disabledAddImages && !!uploadSelectedIds.length
                  ? AlertTypeEnum.error
                  : AlertTypeEnum.info
              }
              title={`${uploadSelectedIds.length} image selected ${
                !!uploadSelectedIds.length
                  ? '-' + ' ' + selectedUploadSizeString
                  : ''
              }`}
              text={ErrorsEnum.fileLimit5Mb}
            />
          )}
        </div>
        <div className={styles.tabsWrap}>
          <Tabs
            tabs={['Upload', 'My images']}
            activeTabIndex={activeModalTab}
            onTabClick={setActiveModalTab}
          />
        </div>

        <div className={styles.tabWrap}>
          {activeModalTab === 0 && (
            <UploadContent setActiveModalTab={setActiveModalTab} />
          )}
          {activeModalTab === 1 && (
            <MyImagesContent
              uploadSelectedIds={uploadSelectedIds}
              onSelectUploadImagesAll={onSelectUploadImagesAll}
              onDeselectUploadImagesAll={onDeselectUploadImagesAll}
              onAddImage={onAddImage}
              onSelectUploadImg={onSelectUploadImg}
              isCheckedUploadImg={isCheckedUploadImg}
              disabledAddImages={disabledAddImages}
              removeAttachImages={removeAttachImages}
            />
          )}
        </div>
      </div>
    )
  }
)
