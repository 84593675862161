export enum ColorsNames {
  blue1 = 'blue1',
  blue1Disabled = 'blue1Disabled',
  blue2 = 'blue2',
  blue3 = 'blue3',
  blue3Disabled = 'blue3Disabled',
  blue4 = 'blue4',
  blue5 = 'blue5',
  blue6 = 'blue6',
  black = 'black',
  gray1 = 'gray1',
  gray2 = 'gray2',
  gray2D = 'gray2D',
  gray2Light = 'gray2Light',
  gray3 = 'gray3',
  gray4 = 'gray4',
  white = 'white',

  purple1 = 'purple1',
  purple2 = 'purple2',
  purple3 = 'purple3',
  orange1 = 'orange1',
  orange2 = 'orange2',
  orange3 = 'orange3',
  aqua1 = 'aqua1',
  aqua2 = 'aqua2',

  success1 = 'success1',
  success2 = 'success2',
  success3 = 'success3',
  warning1 = 'warning1',
  warning2 = 'warning2',
  warning3 = 'warning3',
  warning4 = 'warning4',
  error1 = 'error1',
  error2 = 'error2',
  error3 = 'error3',
  notify1 = 'notify1',
  notify2 = 'notify2',

  text = 'text',
}

export enum ColorsValues {
  blue1 = '#1364F9',
  blue1Disabled = 'rgba(19, 100, 249, 0.4)',
  blue2 = '#1854EA',
  blue3 = '#1885EA',
  blue3Disabled = 'rgba(24, 133, 234, 0.4)',
  blue4 = '#1362AC',
  blue5 = '#E7EFFE',
  blue6 = '#D0E0FE',
  black = '#14161A',
  blackRGB = '20, 22, 26',
  gray1 = '#757D8A',
  gray2 = '#B7BFC8',
  gray2D = 'rgba(183, 191, 200, 0.45)',
  gray2Light = 'rgba(183, 191, 200, 0.3)',
  gray3 = '#E0E6EE',
  gray4 = '#F3F5F8',
  white = '#fff',

  purple1 = '#7A16F5',
  purple2 = '#C6AFE2',
  purple3 = '#F0E4FF',
  orange1 = '#F86919',
  orange2 = '#D6BAAA',
  orange3 = '#FFEEE4',
  orange4Test = 'rgba(248, 105, 25, 0.1)',
  aqua1 = '#A4C5DB',
  aqua2 = '#E4F4FF',

  success1 = '#05944F',
  success2 = '#026233',
  success3 = '#E7F6ED',
  warning1 = '#FFC043',
  warning2 = '#BA7D04',
  warning3 = '#855A04',
  warning4 = '#FFF8E9',
  error1 = '#E11900',
  error2 = '#A11200',
  error3 = '#FFF1F0',
  notify1 = '#2E9AE4',
  notify2 = '#E6F7FF',

  text = '#2C2D2E',
}
