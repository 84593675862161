import styles from './styles.module.scss'
import { ReactComponent as Clock } from 'icons/16px/Clock.svg'
import { ReactComponent as Book } from 'icons/16px/Book.svg'
import { FC } from 'react'

type Props = {
  durationMin: number
  current: number
  count: number
  withoutLessons?: boolean
}

export const StepInfo: FC<Props> = ({
  durationMin,
  current,
  count,
  withoutLessons,
}) => (
  <div className={styles.wrap}>
    <div className={styles.wrapIcon}>
      <Clock />
      <span className={'gray1'}>{durationMin} min</span>
    </div>
    <div className={'point'} />
    <div className={styles.wrapIcon}>
      <Book />
      <span className={'gray1'}>
        {current}/{count} {!withoutLessons && 'lessons'}
      </span>
    </div>
  </div>
)
