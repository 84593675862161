// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mbc59OVNBnYJOzcBR2TH{padding:16px 16px 14px 16px;background-color:var(--primary-color-gray-4);border-radius:16px;margin-top:20px}.CTTIa03N4ZmA62nlsvkT{display:flex;justify-content:space-between;align-items:center;align-content:center}.a13vDDzdrD6GgRbQPvj7{margin-top:4px;color:var(--states-color-error-2)}.pBMnGaqJ_jkcqje8pXwC{margin-top:4px;color:var(--states-color-success-1)}.X2Fgy8Xe3JD_kR58RrPX{width:208px;margin:12px 0}.jp_aRTC0OapFNZ0u6Vte{display:flex;justify-content:center;align-items:center;width:20px;height:20px;background:#fff;box-shadow:0px 4px 8px -2px rgba(20,22,25,.1);border-radius:10px;margin-right:4px}`, "",{"version":3,"sources":["webpack://./src/components/Plan/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CACA,4CAAA,CACA,kBAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,oBAAA,CAEF,sBACE,cAAA,CACA,iCAAA,CAEF,sBACE,cAAA,CACA,mCAAA,CAEF,sBACE,WAAA,CACA,aAAA,CAEF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,6CAAA,CACA,kBAAA,CACA,gBAAA","sourcesContent":[".wrap{\n  padding: 16px 16px 14px 16px;\n  background-color: var(--primary-color-gray-4);\n  border-radius: 16px;\n  margin-top: 20px;\n}\n.row{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  align-content: center;\n}\n.ends{\n  margin-top: 4px;\n  color: var(--states-color-error-2);\n}\n.ups{\n  margin-top: 4px;\n  color: var(--states-color-success-1);\n}\n.wrapBar{\n  width: 208px;\n  margin: 12px 0;\n}\n.starWrap{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 20px;\n  height: 20px;\n  background: #FFFFFF;\n  box-shadow: 0px 4px 8px -2px rgba(20, 22, 25, 0.1);\n  border-radius: 10px;\n  margin-right: 4px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `mbc59OVNBnYJOzcBR2TH`,
	"row": `CTTIa03N4ZmA62nlsvkT`,
	"ends": `a13vDDzdrD6GgRbQPvj7`,
	"ups": `pBMnGaqJ_jkcqje8pXwC`,
	"wrapBar": `X2Fgy8Xe3JD_kR58RrPX`,
	"starWrap": `jp_aRTC0OapFNZ0u6Vte`
};
export default ___CSS_LOADER_EXPORT___;
