// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q90N7QrVSS8rekNswFig{margin-top:40px;border:1px solid var(--primary-color-gray-3);border-radius:12px}.g_NkpjyIbRqhILwrgo1W{height:86px;display:grid;grid-template-columns:1fr max-content;column-gap:16px;justify-content:space-between;align-items:center;border-bottom:1px solid var(--primary-color-gray-3);padding:0 24px}.nVRiBrIH58oSoNTTD_9W{display:grid;grid-template-columns:216px 360px;column-gap:12px}.H0rQmDmtfdHkaqXPwcgl button{background-color:#fff}.XJpkSs6P_4tmw5lSnbmk{max-width:75%}.dkfpB5Fi97vFlaEAnrOf{width:100%;display:grid;row-gap:12px}.SiKYIqj2Ofy5r_ewoQBk{margin-top:8px;padding:8px;background-color:rgba(117,125,138,.4);border-radius:4px;max-width:253px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/components/CreditsContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,4CAAA,CACA,kBAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,qCAAA,CACA,eAAA,CACA,6BAAA,CACA,kBAAA,CACA,mDAAA,CACA,cAAA,CAEF,sBACE,YAAA,CACA,iCAAA,CACA,eAAA,CAGA,6BACE,qBAAA,CAGJ,sBACE,aAAA,CAEF,sBACE,UAAA,CACA,YAAA,CACA,YAAA,CAEF,sBACE,cAAA,CACA,WAAA,CACA,qCAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".wrap{\n  margin-top: 40px;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 12px;\n}\n.header{\n  height: 86px;\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  column-gap: 16px;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid var(--primary-color-gray-3);\n  padding: 0 24px;\n}\n.leftExpand{\n  display: grid;\n  grid-template-columns: 216px 360px;\n  column-gap: 12px;\n}\n.dropdown{\n  button{\n    background-color: white;\n  }\n}\n.wrapBar{\n  max-width: 75%;\n}\n.wrapLeft{\n  width: 100%;\n  display: grid;\n  row-gap: 12px;\n}\n.info{\n  margin-top: 8px;\n  padding: 8px;\n  background-color: rgba(117, 125, 138, 0.4);\n  border-radius: 4px;\n  max-width: 253px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `q90N7QrVSS8rekNswFig`,
	"header": `g_NkpjyIbRqhILwrgo1W`,
	"leftExpand": `nVRiBrIH58oSoNTTD_9W`,
	"dropdown": `H0rQmDmtfdHkaqXPwcgl`,
	"wrapBar": `XJpkSs6P_4tmw5lSnbmk`,
	"wrapLeft": `dkfpB5Fi97vFlaEAnrOf`,
	"info": `SiKYIqj2Ofy5r_ewoQBk`
};
export default ___CSS_LOADER_EXPORT___;
