import { nanoid } from 'nanoid'

export function getBlob(url: string, onBlob: (blob: Blob) => void) {
  let isTryAgain = false

  const tryDownload = () => {
    fetch(`${url}${isTryAgain ? `?hash=${nanoid()}` : ''}`)
      .then((response) => response.blob())
      .then(onBlob)
      .catch((e) => {
        if (!isTryAgain) {
          isTryAgain = true
          tryDownload()
        }

        console.error(e)
      })
  }
  tryDownload()
}
