import { Alert } from 'components/Alert/Alert'
import { AttachmentFile } from 'components/AttachmentFIle/AttachmentFile'
import { observer } from 'mobx-react-lite'
import { Table } from 'components/Table/Table'
import { SizedBox } from 'components/SizedBox'
import { useMemo } from 'react'
import { Column } from 'react-table'
import styles from './styles.module.scss'
import { MapFieldsTableHeader } from 'src/Modals/ImportContactModal/StepsContent/Step2/MapFieldsTableHeader'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { runInAction } from 'mobx'
import { AgreeTermsInput } from 'src/Modals/ImportContactModal/StepsContent/AgreeTermsInput'
import importContactStore from 'store/contacts/importContactStore'
import { IUploadContactData } from 'src/types/IUploadContactData'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const Step2 = observer(() => {
  const data: IUploadContactData[] = useMemo(() => {
    if (!importContactStore.uploadContactsData) {
      return []
    }
    return importContactStore.uploadContactsData.slice(0, 5)
  }, [importContactStore.uploadContactsData, importContactStore.removeColumn])

  const columns: Column<IUploadContactData>[] = useMemo(() => {
    if (!importContactStore.defaultColumns.length) {
      return []
    }
    return importContactStore.defaultColumns.map((key) => ({
      Header: (
        <MapFieldsTableHeader
          columnKey={key}
          disableDelete={
            Object.keys(importContactStore.uploadContactsData[0]).length === 1
          }
        />
      ),
      accessor: key,
    }))
  }, [
    importContactStore.uploadContactsData,
    importContactStore.removeColumn,
    importContactStore.defaultColumns,
    importContactStore.resetMapFields,
  ])

  const onSkipFirstRow = () => {
    runInAction(() => {
      importContactStore.skipFirstRow = !importContactStore.skipFirstRow
    })
  }
  return (
    <>
      <Alert
        title={
          importContactStore.disabledToStep3
            ? 'Please map a column to phone'
            : 'Below is a preview of your file'
        }
        rightContent={
          <div className={styles.wrapFile}>
            {!!importContactStore.uploadFiles.length && (
              <AttachmentFile name={importContactStore.uploadFiles[0].name} />
            )}
          </div>
        }
        text={
          importContactStore.disabledToStep3
            ? 'Phone numbers in Call Loop are the key point of reference for a contact.'
            : 'Change the contact fields your data will be uploaded to. The first row will not be imported.'
        }
        type={
          importContactStore.disabledToStep3
            ? AlertTypeEnum.error
            : AlertTypeEnum.info
        }
      />
      <SizedBox height={24} />
      <div className={styles.wrapTable}>
        <Table<IUploadContactData>
          data={data}
          columns={columns}
          minWidthColumn={207}
        />
      </div>
      <div className={styles.wrapFooterTable}>
        <button className={styles.checkBox} onClick={onSkipFirstRow}>
          <CheckBox checked={importContactStore.skipFirstRow} />
          <span>Skip first row import</span>
        </button>
        <span className={'gray1'}>
          Showing {data.length > 5 ? 5 : data.length} of{' '}
          {importContactStore.importContactData?.importPayload?.contactsCount}{' '}
          rows
        </span>
      </div>
      {importContactStore.fromList && (
        <div className={styles.wrapTerms}>
          <AgreeTermsInput fromStep2 />
        </div>
      )}
    </>
  )
})
