import { IBroadcast } from 'src/types/IBroadcast'
import { getActionsByStatus } from 'src/routes/main/broadcasts/functions/getActionsByStatus'
import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { broadcastBtnByAction } from 'src/routes/main/broadcasts/functions/broadcastBtnByAction'
import { Status } from 'src/generated/graphql'

export const getBroadcastActions = (
  broadcast?: IBroadcast
): Array<FC<ActionBtnProps<IBroadcast>>> => {
  const actions = broadcast
    ? getActionsByStatus(
        broadcast.simplifiedStatus || broadcast.status || Status.Draft
      )
    : []

  const actionsFC: Array<FC<ActionBtnProps<IBroadcast>>> = []

  actions.forEach((action) => {
    const btn: FC<ActionBtnProps<IBroadcast>> | undefined =
      broadcastBtnByAction[action as keyof typeof broadcastBtnByAction]
    if (btn) {
      actionsFC.push((props) => btn({ ...props, row: broadcast }))
    }
  })
  return actionsFC
}
