import { observer } from 'mobx-react-lite'
import { Preview } from 'components/Preview/Preview'
import createCampaignStore from 'store/campaigns/createCampaignStore'

export const PreviewCampaign = observer(() => {
  return (
    <Preview
      messages={createCampaignStore.messages.map((m) => m.previewContent)}
    />
  )
})
