import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { TollFreeVerificationContent } from 'src/routes/settings/compliance/TollFreeVerification/TollFreeVerificationContent'
import classNames from 'classnames'
import styles from './styles.module.scss'

export const EditTFModal = observer(() => {
  const { openEditModal, setOpenEditModal } = tollFreeVerificationStore
  const onClose = () => {
    setOpenEditModal(false)
  }
  return (
    <Modal
      open={openEditModal}
      onClose={onClose}
      title={'Toll-Free verification'}
    >
      <div className={classNames(styles.wrap, 'pModal')}>
        <TollFreeVerificationContent isModal />
      </div>
    </Modal>
  )
})
