import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FC, useMemo } from 'react'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { ICampaign } from 'src/types/ICampaign'
import { getCampaignsActions } from 'src/routes/main/campaigns/functions/getCampaignsActions'

export const OneSelectContent = observer(
  ({ campaign }: { campaign: ICampaign }) => {
    const actions = getCampaignsActions(campaign, true)

    return useMemo(() => {
      const rowActions = actions.slice(0, 4)
      const moreActions = actions.slice(4)
      return (
        <>
          {rowActions.map((Action, index) => (
            <Action key={index} medium />
          ))}
          {!!moreActions?.length && (
            <MoreBtn
              menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
              tooltip={'More'}
              medium
            />
          )}
        </>
      )
    }, [actions])
  }
)
