import contactStore from 'store/contacts/contactStore'
import { useGetMyOrganizationContactsQuery } from 'src/generated/graphql'
import { ContactsTable } from 'src/routes/main/contacts/ContactsTable/ContactsTable'
import { observer } from 'mobx-react-lite'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { useSetData } from 'src/hooks/useSetData'

export const ContactsTablePage = observer(() => {
  const options = useTablePaginationOptions(contactStore.tableStoreContacts)

  const queryResult = useGetMyOrganizationContactsQuery({
    ...options,
    variables: {
      page: options.variables.page,
      contactSearch: {
        status: options.variables.status,
        searchPattern: options.variables.searchPattern,
      },
    },
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => contactStore.setDataContacts(data),
    refreshDeps: [contactStore.tableStoreContacts.refreshTrigger],
  })

  return (
    <ContactsTable
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      tableStore={contactStore.tableStoreContacts}
      withSearch
    />
  )
})
