import styles from './styles.module.scss'
import classNames from 'classnames'
import { IOption } from 'src/types/IOption'

type Props<T> = {
  options: IOption<T>[]
  activeValue: T
  onOptionClick?: (value: T) => void
  fullWidth?: boolean
}

export const ToggleBtn = <T,>({
  activeValue,
  onOptionClick,
  options,
  fullWidth,
}: Props<T>) => {
  return (
    <div className={classNames(styles.wrap, fullWidth && styles.fullWidth)}>
      {options.map((opt) => (
        <button
          key={opt.value as string}
          className={classNames(
            styles.btn,
            activeValue === opt.value && styles.active
          )}
          onClick={() => onOptionClick && onOptionClick(opt.value as T)}
        >
          {opt.title}
          {opt.discount && (
            <div
              className={classNames(
                styles.discount,
                activeValue === opt.value && styles.active
              )}
            >
              <span className={'s4 medium success1'}>{opt.discount}</span>
            </div>
          )}
        </button>
      ))}
    </div>
  )
}
