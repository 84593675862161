import { ReactComponent as Search } from 'icons/16px/Search.svg'
import { InputText, InputTextProps } from 'components/Input/InputText/InputText'
import { FC } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

interface Props extends InputTextProps<string> {
  value: string
  onSearch: (val: string) => void
  small?: boolean
}

export const SearchInput: FC<Props> = ({ onSearch, small, ...props }) => {
  return (
    <InputText
      className={classNames(props.className, small && styles.small)}
      placeholder={'Search...'}
      leftIcon={<Search />}
      withoutClear
      onChange={(event) => onSearch(event.target.value)}
      {...props}
    />
  )
}
