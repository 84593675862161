import { InputText } from 'components/Input/InputText/InputText'
import { isHttps } from 'src/util/validators'

type Props = {
  website: string
  isError: boolean
  setWebsite: (value: string) => void
  setError: (key: string, value: boolean) => void
}

export const BusinessCompanyWebsite = ({
  website,
  setWebsite,
  isError,
  setError,
}: Props) => {
  const onBlur = () => {
    const value = website
    const isWrong = !!value && !isHttps(value)
    setError('website', isWrong)
  }
  const clearError = () => {
    setError('website', false)
  }
  return (
    <InputText
      label={'Website URL'}
      tooltip={
        'You need a website to register. If you don’t have an official website and are using a Facebook page, then paste that URL here instead.'
      }
      tooltipWidth={239}
      value={website}
      onChangeValue={setWebsite}
      placeholder={'e.g. https://www.example.com'}
      onBlur={onBlur}
      error={
        (isError && 'Please enter a full url (e.g. https://www.site.com)') || ''
      }
      onFocus={clearError}
    />
  )
}
