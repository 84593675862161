import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { BusinessProfileCard } from 'src/routes/settings/compliance/BusinessProfileCard/BusinessProfileCard'
import { TollFreeVerificationCard } from 'src/routes/settings/compliance/TollFreeVerificationCard/TollFreeVerificationCard'
import { NewRequirements } from 'src/routes/settings/compliance/components/NewRequirements/NewRequirements'
import companyStore from 'store/settings/company/companyStore'

export const Compliance = observer(() => {
  useEffect(() => {
    companyStore.setShowNewReq(true)
  }, [])
  return (
    <div className={'small-container'}>
      <h1 className={'mb32'}>Compliance</h1>
      {companyStore.showNewReq && <NewRequirements fromCompany />}
      <div className={'col16'}>
        <BusinessProfileCard />
        {/*<A2P10DLCCard />*/}
        <TollFreeVerificationCard />
      </div>
    </div>
  )
})
