import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import billingStore from 'store/settings/billing/billingStore'
import { observer } from 'mobx-react-lite'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { PayGoLimitsContent } from 'src/Modals/UpgradePlanModal/Step1Upgrade/cards/PayGoLimitsContent'

export const CurrentPlan = observer(() => {
  return (
    <div className={styles.wrap}>
      <div className={styles.label}>
        <span className={'s2 medium white'}>Current plan</span>
      </div>
      <div className={styles.header}>
        <div className={styles.left}>
          <span className={'bold'}>{billingStore.clSubscriptionTitle}</span>
          <span className={'s2 gray1'}>
            {billingStore.clSubscriptionDescription}
          </span>
        </div>
        <div className={'row12'}>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
            id={'billing_upgrade_plan'}
          >
            Upgrade plan
          </Button>
        </div>
      </div>
      {billingStore.isPayGo && (
        <div className={styles.content}>
          <PayGoLimitsContent formBilling />
        </div>
      )}
    </div>
  )
})
