import styles from './styles.module.scss'
import { FC } from 'react'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import classNames from 'classnames'

type Props = JSX.IntrinsicElements['textarea'] & {
  label?: string
  labelTooltip?: string | JSX.Element
  pLabel?: string
  height?: number
  error?: string
  onChangeValue?: (value: string) => void
}

export const SimpleTextarea: FC<Props> = ({
  label,
  pLabel,
  placeholder = 'Type here...',
  height,
  onChangeValue,
  error,
  maxLength,
  minLength,
  labelTooltip,
  className,
  ...props
}) => {
  return (
    <div className={styles.wrap}>
      {label && (
        <div className={styles.wrapLabel}>
          <span className={'s2 medium gray1'}>{label}</span>
          {labelTooltip && <InfoTooltip title={labelTooltip} />}
        </div>
      )}
      {pLabel && (
        <p className={classNames('bold mb24 row4')}>
          {pLabel}
          {labelTooltip && <InfoTooltip title={labelTooltip} />}
        </p>
      )}
      <textarea
        placeholder={placeholder}
        className={classNames(styles.textarea, className)}
        style={{ height }}
        {...props}
        onChange={(event) => {
          props.onChange && props.onChange(event)
          onChangeValue && onChangeValue(event.target.value)
        }}
      />
      {error && <ValidItem text={error} mt={8} />}
      {(maxLength || minLength) && (
        <div className={'flex-space-between mt8'}>
          <div>
            {maxLength && String(props.value || '').length > maxLength && (
              <ValidItem
                text={`Please enter no more than ${maxLength} characters`}
              />
            )}
            {minLength && String(props.value || '').length < minLength && (
              <ValidItem
                text={`Please enter more than ${minLength} characters`}
              />
            )}
          </div>

          <span className={'s2 gray2'}>{`${
            String(props.value || '').length
          } / ${maxLength}`}</span>
        </div>
      )}
    </div>
  )
}
