// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RenX4d52x6mfz1fw3P4u{overflow-y:hidden;padding-right:64px}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/contctLists/ListssTable/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,kBAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrap{\n  overflow-y: hidden;\n  padding-right: 64px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `RenX4d52x6mfz1fw3P4u`
};
export default ___CSS_LOADER_EXPORT___;
