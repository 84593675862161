import { useEffect } from 'react'
import user from 'store/user/user'

export function useHubspotRegisterCompany(trigger?: string) {
  useEffect(() => {
    if (trigger ?? true) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = '//js.hsforms.net/forms/embed/v2.js'
      script.onload = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        hbspt &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          hbspt.forms.create({
            region: process.env.FE_HUBSPOT_REGION,
            portalId: process.env.FE_HUBSPOT_PORTAL_ID,
            formId: process.env.FE_HUBSPOT_TRIAL_FORM_ID,
            onFormReady: (form: HTMLFormElement) => {
              const firstNameInput = form.querySelector(
                "input[name='firstname']"
              ) as HTMLInputElement
              const lastnameInput = form.querySelector(
                "input[name='lastname']"
              ) as HTMLInputElement
              const emailInput = form.querySelector(
                "input[name='email']"
              ) as HTMLInputElement
              const phoneInput = form.querySelector(
                "input[name='phone']"
              ) as HTMLInputElement
              if (firstNameInput) {
                firstNameInput.value = user.firstName
              }
              if (lastnameInput) {
                lastnameInput.value = user.lastName
              }
              if (emailInput) {
                emailInput.value = user.email
              }
              if (phoneInput) {
                phoneInput.value = user.phone
              }
              form.submit()
            },
          })
      }
      document.body.appendChild(script)
    }
  }, [trigger])
}
