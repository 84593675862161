// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pXfSDkQ0KMkVQPOQygme{height:44px;padding-inline:16px;display:grid;gap:16px;grid-template-columns:max-content 1fr max-content;align-items:center}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/components/MigrationCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,mBAAA,CACA,YAAA,CACA,QAAA,CACA,iDAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  height: 44px;\n  padding-inline: 16px;\n  display: grid;\n  gap: 16px;\n  grid-template-columns: max-content 1fr max-content;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `pXfSDkQ0KMkVQPOQygme`
};
export default ___CSS_LOADER_EXPORT___;
