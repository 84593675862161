import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import keywordsStore from 'store/keywords/keywordsStore'
import {
  Keyword,
  KeywordInput,
  Maybe,
  PhoneNumber,
  Status,
  useRemoveKeywordsMutation,
  useUpdateKeywordMutation,
} from 'src/generated/graphql'
import { iKeywordToInput } from 'src/routes/main/keywords/functions/iKeywordToInput'
import { transformKeyword } from 'src/routes/main/keywords/functions/transformKeyword'
import { IKeyword } from 'src/types/IKeyword'

type Props = {
  keyword?: IKeyword | null
  keywordInput?: KeywordInput
  phonesProps?: Maybe<PhoneNumber>[]
  isNeedSave?: boolean
}

export function useKeywordFunctions(props?: Props) {
  const keyword = props?.keyword
  const keywordInput = props?.keywordInput
  const phonesProps = props?.phonesProps
  const isNeedSave = props?.isNeedSave

  const [removeKeywords] = useRemoveKeywordsMutation()
  const [updateKeyword] = useUpdateKeywordMutation()
  const navigate = useNavigate()

  let phones: Maybe<PhoneNumber>[] = []

  const name = keyword?.name || keywordInput?.name

  if (keyword) {
    phones = keyword.keywordPhoneNumbers || []
  } else if (phonesProps) {
    phones = phonesProps
  }
  const getTextTooltipTest = () => {
    if (
      keyword?.status === Status.Draft ||
      keyword?.status === Status.Disabled ||
      keyword?.status === Status.Archived
    ) {
      return 'Enable keyword before testing'
    }
    if (isNeedSave) {
      return 'Save keyword before testing'
    }
    if (name) {
      if (phones?.length === 1) {
        return (
          <span className={'gray2'}>
            Text <span className={'white'}>{name}</span> to{' '}
            <span className={'white'}>{phones[0]?.friendlyName}</span>
            <br /> to test the keyword
          </span>
        )
      }
      if (phones?.length) {
        return (
          <span className={'gray2'} style={{ width: 147 }}>
            Text <span className={'white'}>{name}</span> to one of the phone
            numbers to test the keyword
          </span>
        )
      }
    } else {
      return ''
    }
  }

  const onEdit = () => {
    if (keyword) {
      navigate(`${RoutesEnum.keywords}/${keyword.id}/edit`)
    }
  }

  const onDuplicate = () => {
    if (keyword) {
      navigate(`${RoutesEnum.keywords}/${keyword.id}/duplicate`)
    }
  }
  const onDelete = (keywordIds: string[]) => {
    removeKeywords({
      variables: {
        keywordIds: keywordIds.map((id) => +id),
      },
    })
      .then(() => {
        keywordsStore.delete(keywordIds)
        keywordsStore.tableStore.onRefresh()
        keywordsStore.tableStore.setCheckedRows(
          keywordsStore.tableStore.checkedRows.filter(
            (keyword) => !keywordIds.includes(keyword.id)
          )
        )
        navigate(RoutesEnum.keywords)
      })
      .catch((e) => console.error(e))
  }
  const onTest = () => {
    if (keyword) {
      keywordsStore.setTestKeyword(keyword)
    }
  }

  const onSwitch = () => {
    let input: KeywordInput | null = null
    if (keyword) {
      input = {
        ...iKeywordToInput(keyword),
        status:
          keyword.status === Status.Active ? Status.Disabled : Status.Active,
      }
    }
    if (keywordInput) {
      input = {
        ...keywordInput,
        status:
          keywordInput?.status === Status.Active
            ? Status.Disabled
            : Status.Active,
      }
    }

    if (input) {
      updateKeyword({
        variables: {
          keywordInput: input,
        },
      })
        .then((res) => {
          if (res.data?.updateKeyword) {
            const keyword = transformKeyword(res.data?.updateKeyword as Keyword)
            keywordsStore.setKeyword(keyword)
          }
        })
        .catch(console.error)
    }
  }

  return {
    textTooltipTest: getTextTooltipTest(),
    onEdit,
    onDuplicate,
    onDelete,
    onTest,
    onSwitch,
  }
}
