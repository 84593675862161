import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { ReactComponent as Chat } from 'icons/16px/Chat.svg'
import { onOpenIntercomWidget } from 'src/util/intercom'

export const BannedAccount = () => {
  return (
    <div className={styles.wrapBanned}>
      <div className={styles.wrapInfo}>
        <ModalIcon type={'infoError1'} />
      </div>

      <h2 className={'bold'}>Account suspended</h2>
      <p className={'par2 gray1'}>
        Your Call Loop account has been suspended <br />
        for violating our terms of services
      </p>
      <Button
        typeBtn={BtnType.secondaryGray}
        size={BtnSize.large}
        onClick={onOpenIntercomWidget}
      >
        <Chat />
        Talk to support
      </Button>
    </div>
  )
}
