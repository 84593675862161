// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pKLfsiGw4Ns6lvwGdtDy{padding:0 32px 32px 32px}.oKIPD4lepEnhA84_z2kP{width:936px}.zn5hcfvFXvtX9Gmf8Fuw{height:20px;display:flex;align-items:center;background-color:rgba(0,0,0,0)}.xi7q1OUQKYckaoSqVAjf{margin-top:40px;display:flex;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/Modals/ImportContactModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACA,wBAAA,CAEA,sBACE,WAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,8BAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,eAAA","sourcesContent":[".wrap{\npadding: 0 32px 32px 32px;\n}\n.w936{\n  width: 936px;\n}\n.upgrade{\n  height: 20px;\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n}\n.actions{\n  margin-top: 40px;\n  display: flex;\n  column-gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `pKLfsiGw4Ns6lvwGdtDy`,
	"w936": `oKIPD4lepEnhA84_z2kP`,
	"upgrade": `zn5hcfvFXvtX9Gmf8Fuw`,
	"actions": `xi7q1OUQKYckaoSqVAjf`
};
export default ___CSS_LOADER_EXPORT___;
