import styles from './styles.module.scss'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { BtnSize, Button } from 'components/Button/Button'
import Picker, { IEmojiData } from 'emoji-picker-react'
import React, { FC } from 'react'
import { ReactComponent as Emoji } from 'icons/16px/Emoji.svg'
import { observer } from 'mobx-react-lite'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'

type Props = {
  onAddEmoji: (emoji: string) => void
}

export const EmojiPicker: FC<Props> = observer(({ onAddEmoji }) => {
  const { trigger, showTT, setTrigger, onTriggerClick, setShowTT } =
    useFixedTooltip()

  const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
    setShowTT(false)
    onAddEmoji(emojiObject.emoji)
  }

  return (
    <div className={styles.emojiWrap}>
      <Tooltip text={'Emoji'} disableVisible={showTT}>
        <div ref={setTrigger}>
          <Button
            size={BtnSize.small}
            hoverGray
            onClick={onTriggerClick}
            active={showTT}
          >
            <Emoji />
          </Button>
        </div>
      </Tooltip>
      <FixedTooltip
        trigger={trigger}
        visible={showTT}
        white
        noArrow
        width={280}
        position={TTPositionEnum.bottomCenter}
        noOpacity
      >
        <div className={styles.emoji}>
          <Picker onEmojiClick={onEmojiClick} />
        </div>
      </FixedTooltip>
    </div>
  )
})
