import { makeAutoObservable } from 'mobx'
import { IModal } from 'src/widgets/ModalContainer/store/types'
import { nanoid } from 'nanoid'

class ModalStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalsMap = new Map<string, IModal>()

  get modals() {
    return Array.from(this.modalsMap.values())
  }

  addModal = (modal: Omit<IModal, 'id'> & { id?: string }) => {
    const newModal = { ...modal, id: modal.id || nanoid() }
    this.modalsMap.set(newModal.id, newModal)
  }

  removeModal = (id: string) => {
    this.modalsMap.delete(id)
  }

  closeModal = (id: string) => {
    const modal = this.modalsMap.get(id)
    if (modal?.onClose) {
      modal?.onClose()
    } else {
      this.removeModal(id)
    }
  }
}

export default new ModalStore()
