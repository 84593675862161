import { AudioPlayer } from 'components/AudioPlayer/AudioPlayer'
import { FC } from 'react'
import styles from './styles.module.scss'
import { Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16px/CloseCustom.svg'
import { IAudioItem } from 'src/types/IAudioItem'

type Props = {
  audio: IAudioItem
  onRemove?: () => void
  from: string
  rightTitleContent?: string
  maxCanvasWidth?: number
  fullWidth?: boolean
}

export const AudioCard: FC<Props> = ({
  audio,
  rightTitleContent,
  onRemove,
  from,
  maxCanvasWidth = 317,
  fullWidth,
}) => {
  if (!audio) {
    return <></>
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <span className={'ellipsisNowrap'}>{audio.name}</span>
        {rightTitleContent && (
          <span className={'s2 gray1 nowrap'}>{rightTitleContent}</span>
        )}
        {onRemove && (
          <Button onClick={onRemove} className={styles.close}>
            <Close />
          </Button>
        )}
      </div>
      <AudioPlayer
        src={audio.url || ''}
        id={audio.id + from}
        className={styles.player}
        maxCanvasWidth={maxCanvasWidth}
        fullWidth={fullWidth}
      />
    </div>
  )
}
