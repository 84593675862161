import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Marker } from 'icons/16pxNoMask/Marker.svg'
import classNames from 'classnames'
import styles from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/styles.module.scss'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { IOption } from 'src/types/IOption'
import { Location, useFindCitiesQuery } from 'src/generated/graphql'

//city: "New York", state: "NY", __typename: "Location"}

const locationToOptionsCity = (
  location?: {
    city?: string | null
    state?: string | null
  } | null
): IOption => ({
  title: (
    <div className={'row8'}>
      <span>{location?.city}</span>
      <div className={'point'} />
      <span className={'gray1'}>{location?.state}</span>
    </div>
  ),
  value: `${location?.city || ''}${location?.state || ''}`,
  data: location,
})

export const CityDropdown = observer(() => {
  const { data } = useFindCitiesQuery({
    variables: {
      country: addNumberStore.selectedCountryCode,
      cityName: addNumberStore.searchCity,
    },
  })
  return (
    <Dropdown
      options={data?.findCities?.map(locationToOptionsCity)}
      selectedValue={
        addNumberStore.selectedFilterLocalNumber === 'area'
          ? addNumberStore.areaCode
          : addNumberStore.selectedCityLocalNumberValue
      }
      onSelectValue={(value, data) =>
        addNumberStore.setSelectedCityLocalNumber(value, data as Location)
      }
      onDeleteTag={() => addNumberStore.removeSelectedCityLocalNumber()}
      leftIcon={
        <AppIcon color={ColorsNames.gray1}>
          <Marker />
        </AppIcon>
      }
      rightBtn={
        <div className={'flex'}>
          <button
            className={classNames(
              styles.filterBtn,
              addNumberStore.selectedFilterLocalNumber === 'city' &&
                styles.active
            )}
            onClick={() => addNumberStore.setSelectedFilterLocalNumber('city')}
          >
            City
          </button>
          <button
            className={classNames(
              styles.filterBtn,
              addNumberStore.selectedFilterLocalNumber === 'area' &&
                styles.active
            )}
            onClick={() => addNumberStore.setSelectedFilterLocalNumber('area')}
          >
            Area code
          </button>
        </div>
      }
      search={
        addNumberStore.selectedFilterLocalNumber === 'city'
          ? addNumberStore.searchCity
          : addNumberStore.areaCode
      }
      setSearch={(value) =>
        addNumberStore.selectedFilterLocalNumber === 'city'
          ? addNumberStore.setSearchCity(value)
          : addNumberStore.setAreaCode(String(parseInt(value) || ''))
      }
      withSearch
      noBottomRadius
      withRemove
      selectedTag={addNumberStore.selectedFilterLocalNumber === 'city'}
      withNoResults
      withNoData
      placeholder={
        addNumberStore.selectedFilterLocalNumber === 'city'
          ? 'Enter a city name'
          : 'Enter an area code'
      }
      hideDropdown={addNumberStore.selectedFilterLocalNumber === 'area'}
    />
  )
})
