import { placeCaretAtEnd } from 'src/util/functions'
import { Dispatch, SetStateAction } from 'react'
import { RequiredFields } from 'components/NewTable/types'

type Props<T> = {
  tr?: HTMLTableRowElement | null
  row: T
  onSave: (id: string, text: string) => Promise<void>
  error?: string
  name?: string
  setCreateListError?: Dispatch<SetStateAction<string>>
}

export function onRenameTableCell<T extends RequiredFields<T>>({
  tr,
  row,
  onSave,
  error,
  setCreateListError,
}: Props<T>) {
  const nameTd: Element | undefined | null =
    tr?.children[1] || document.getElementById(row.id)?.children[1]
  if (nameTd) {
    nameTd.classList.add('edited')
    error && nameTd.classList.add('error')
    const editDiv: HTMLDivElement | null = nameTd.querySelector('.edit')
    if (editDiv) {
      editDiv.setAttribute('contenteditable', 'true')
      editDiv.focus()
      placeCaretAtEnd(editDiv)
      const saveChanges = () => {
        editDiv.setAttribute('contenteditable', 'false')
        nameTd.classList.remove('edited')
        onSave(row.id, editDiv.innerText).catch((e) => {
          nameTd.classList.add('error')
          const errorDiv = document.createElement('div')
          errorDiv.classList.add('error-info')
          errorDiv.innerText = e.message

          nameTd.classList.add('edited')
          editDiv.setAttribute('contenteditable', 'true')
          editDiv.focus()
          placeCaretAtEnd(editDiv)

          nameTd.appendChild(errorDiv)
        })
      }
      editDiv.onkeydown = (e) => {
        setCreateListError && setCreateListError('')
        nameTd.classList.remove('error')
        const errorDiv = nameTd.querySelector('.error-info')
        if (errorDiv) {
          nameTd.removeChild(errorDiv)
        }

        if (e.key === 'Enter') {
          e.preventDefault()
          saveChanges()
          return
        }
      }
      editDiv.onblur = () => {
        saveChanges()
      }
      editDiv.onclick = (e) => {
        e.stopPropagation()
      }
    }
  }
}
