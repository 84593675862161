import { CompanyPage } from 'components/Page/CompanyPage/CompanyPage'

import { A2P10DLCRegistrationContent } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/A2P10DLCRegistrationContent'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import { RoutesEnum } from 'src/routes/routes'

export const A2P10DLCRegistration = observer(() => {
  const navigate = useNavigate()
  if (!a2p10DLCStore.initLoaded) {
    navigate(RoutesEnum.settingsCompliance)
  }
  return (
    <CompanyPage>
      <A2P10DLCRegistrationContent />
    </CompanyPage>
  )
})
