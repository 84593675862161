import { useEffect, useState } from 'react'
import { generateName } from 'src/util/functions'
import { DayjsFormats } from 'lib/dayjs'

export function useElementName({
  name,
  element,
  formatName,
  setName,
  noGenerateName,
}: {
  name: string
  noGenerateName?: boolean
  element: string
  setName: (value: string) => void
  formatName?: DayjsFormats
}) {
  const [error, setError] = useState('')
  useEffect(() => {
    if (!name && !noGenerateName) {
      setName(generateName(element, formatName))
    }
  }, [])

  const onFocus = () => setError('')
  const onBlur = () =>
    setError(name ? '' : `Please enter the ${element.toLowerCase()} name`)

  return { error, onFocus, onBlur }
}
