export const ReserveKeywordWords = [
  'STOP',
  'UNSUBSCRIBE',
  'CANCEL',
  'END',
  'QUIT',
  'START',
  'YES',
  'UNSTOP',
  'HELP',
  'INFO',
]
