import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Pointer } from 'icons/Bottom pointer.svg'
import classNames from 'classnames'
import { Portal } from 'src/components/Portal'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { nanoid } from 'nanoid'
import { GlobalClasses } from 'styles/classes'
import { useRelativePosition } from 'components/Tooltip/FixedTooltip/hooks/useRelativePosition'

export enum TTPositionEnum {
  topRight,
  topCenter,
  topLeft,
  bottomLeft,
  bottomRight,
  bottomCenter,
}

type Props = {
  visible?: boolean
  nowrapText?: string
  text?: string
  subText?: string
  trigger?: HTMLElement | null
  hover?: boolean
  disableVisible?: boolean
  white?: boolean
  noArrow?: boolean
  width?: number | string
  minWidth?: number | string
  addLeftPosition?: number
  addTopPosition?: number
  noOpacity?: boolean
  position?: TTPositionEnum
  triggerChange?: string
  globalClasses?: Array<GlobalClasses>
  children?: ReactNode
}

export const FixedTooltip: FC<Props> = observer(
  ({
    visible,
    trigger,
    triggerChange,
    nowrapText,
    text,
    subText,
    hover,
    disableVisible,
    white,
    noArrow,
    width,
    minWidth,
    noOpacity,
    addLeftPosition = 0,
    addTopPosition = 0,
    position,
    globalClasses = [],
    children,
  }) => {
    const [refContent, setRefContent] = useState<HTMLDivElement | null>(null)
    const [visibleHover, setVisibleHover] = useState(false)
    const [update, setUpdate] = useState('')
    const refEvent = useRef(false)
    const refHoverContent = useRef(false)

    const [relativePosition, setRelativePosition] = useState(position)

    useEffect(() => {
      setRelativePosition(position)
    }, [visible])

    const triggerRects = trigger?.getClientRects()[0]
    const contentRects = refContent?.getClientRects()[0]
    const top = useMemo(() => {
      if (!triggerRects || !contentRects) {
        return 0
      }
      if (
        relativePosition === TTPositionEnum.bottomLeft ||
        relativePosition === TTPositionEnum.bottomCenter ||
        relativePosition === TTPositionEnum.bottomRight
      ) {
        return triggerRects.top + 8 + triggerRects.height + addTopPosition
      }
      return triggerRects.top - 8 - contentRects.height
    }, [triggerRects, contentRects, update, addTopPosition, relativePosition])
    const left = useMemo(() => {
      if (!triggerRects || !contentRects) {
        return 0
      }
      if (relativePosition === TTPositionEnum.bottomLeft) {
        return triggerRects.left + addLeftPosition
      }
      if (
        relativePosition === TTPositionEnum.bottomCenter ||
        relativePosition === TTPositionEnum.topCenter
      ) {
        return (
          triggerRects.left -
          contentRects.width / 2 +
          triggerRects.width / 2 +
          addLeftPosition
        )
      }
      if (relativePosition === TTPositionEnum.topLeft) {
        return triggerRects.left + addLeftPosition
      }
      if (
        relativePosition === TTPositionEnum.topRight ||
        relativePosition === TTPositionEnum.bottomRight
      ) {
        return triggerRects.right - contentRects.width + addLeftPosition
      }
      return triggerRects.left - contentRects.width / 2 + triggerRects.width / 2
    }, [triggerRects, contentRects, update, addLeftPosition, relativePosition])

    useEffect(() => {
      const onEnter = () => {
        setVisibleHover(true)
      }
      const onLeave = () => {
        setVisibleHover(false)
      }
      if (trigger && !refEvent.current && hover) {
        trigger.addEventListener('mouseenter', onEnter)
        trigger.addEventListener('mouseleave', onLeave)
        refEvent.current = true
      }
      return () => {
        if (trigger && hover) {
          trigger.removeEventListener('mouseenter', onEnter)
          trigger.removeEventListener('mouseleave', onLeave)
        }
      }
    }, [trigger])

    useEffect(() => {
      if (refContent) {
        const height = top + refContent?.offsetHeight + 32
        uiStore.setMinRootHeight(height)
        setUpdate(nanoid())
      }
    }, [uiStore.triggerHeight])

    useEffect(() => {
      setUpdate(nanoid())
      setTimeout(() => setUpdate(nanoid()), 50)
    }, [uiStore.triggerUpdate, triggerChange])

    useEffect(() => {
      function onScroll() {
        setUpdate(nanoid())
      }
      window.addEventListener('scroll', onScroll, true)
      return () => {
        uiStore.setMinRootHeight(0)
        window.removeEventListener('scroll', onScroll, true)
      }
    }, [])

    useRelativePosition({
      setRelativePosition,
      relativePosition,
      contentRects,
      triggerRects,
      position,
      refContent,
      visible,
    })

    if (disableVisible) {
      return <></>
    }
    return visible || visibleHover ? (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Portal>
        <div
          ref={setRefContent}
          className={classNames(
            styles.content,
            white && styles.white,
            noOpacity && styles.noOpacity,
            [...globalClasses]
          )}
          style={{ top, left, width, minWidth }}
        >
          <div className={styles.innerContent}>
            {!noArrow && (
              <div
                className={classNames(
                  styles.arrow,
                  relativePosition === TTPositionEnum.bottomCenter &&
                    styles.bottomCenter
                )}
              >
                <Pointer />
              </div>
            )}

            <div
              onMouseLeave={() => {
                refHoverContent.current = false
              }}
              onMouseEnter={() => {
                refHoverContent.current = true
              }}
            >
              {(text || nowrapText || subText) && (
                <div className={styles.innerText}>
                  <div className={styles.nowrap}>{nowrapText}</div>
                  {text}
                  <div className={styles.subText}>{subText}</div>
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </Portal>
    ) : (
      <></>
    )
  }
)
