import { observer } from 'mobx-react-lite'
import { getManyTriggersActions } from 'src/routes/main/triggers/functions/getManyTriggersActions'
import triggersStore from 'store/triggers/triggersStore'

export const ManySelectContent = observer(() => {
  const actions = getManyTriggersActions(triggersStore.tableStore.checkedRows)

  return (
    <>
      {actions.map((Action, index) => (
        <Action key={index} medium />
      ))}
    </>
  )
})
