// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jdFs9BTxaOIngHnq1fo4{display:flex;column-gap:12px;margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/company/routes/CompanyProfile/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,eAAA,CACA,eAAA","sourcesContent":["\n.actions{\n  display: flex;\n  column-gap: 12px;\n  margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `jdFs9BTxaOIngHnq1fo4`
};
export default ___CSS_LOADER_EXPORT___;
