import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { AppIcon, IconColor, IIconType } from 'components/AppIcon/AppIcon'
import { FC } from 'react'
import { ActionsEnum } from 'components/Button/types'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ColorsNames } from 'styles/variables'

interface Props {
  onClick?: () => void
  type: ActionsEnum
  typeBtn?: BtnType
  primary?: boolean
  loading?: boolean
  disabled?: boolean
  noText?: boolean
  text?: string
  menu?: boolean
  iconType?: IIconType
  iconColor?: IconColor
  comingSoon?: boolean
  extraBtnSize?: BtnSize
}

export const MediumBtn: FC<Props> = ({
  onClick,
  type,
  text,
  loading,
  disabled,
  menu,
  iconType,
  iconColor,
  noText,
  typeBtn,
  comingSoon,
  extraBtnSize,
}) => {
  const btn = (
    <Button
      size={extraBtnSize || (menu ? BtnSize.small : BtnSize.medium)}
      typeBtn={menu ? undefined : typeBtn || BtnType.secondaryGray}
      className={menu ? 'nowrap' : ''}
      withIcon
      onClick={onClick}
      loading={loading}
      disabled={disabled || comingSoon}
      hoverGray={menu}
      menuBtn={menu}
      icon={noText}
      p12={extraBtnSize === BtnSize.small}
    >
      <AppIcon
        icon={type}
        type={iconType}
        color={comingSoon ? ColorsNames.gray2 : iconColor}
      />
      {!noText && (text || type)}
    </Button>
  )
  if (comingSoon) {
    return (
      <MuiTooltip title={'Coming soon'} arrow placement={'top'}>
        <div>{btn}</div>
      </MuiTooltip>
    )
  }
  return btn
}
