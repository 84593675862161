import { useNavigate, Navigate } from 'react-router-dom'
import { useEffect } from 'react'

export const NoMatch = ({ redirectRoute }: { redirectRoute: string }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(redirectRoute)
  }, [])
  return <Navigate to={redirectRoute} />
}
