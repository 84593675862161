// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ux1oA600odT84Y3UwIwJ{background:var(--primary-color-gray-4);border-radius:16px;padding:20px 16px}`, "",{"version":3,"sources":["webpack://./src/Modals/AddNumberModal/steps/PaymentContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":[".phoneInfoCard{\n  background: var(--primary-color-gray-4);\n  border-radius: 16px;\n  padding: 20px 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneInfoCard": `Ux1oA600odT84Y3UwIwJ`
};
export default ___CSS_LOADER_EXPORT___;
