// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._EJ8rqv7O46loqxMReEf{display:grid;grid-template-columns:152px 304px;column-gap:8px}._EJ8rqv7O46loqxMReEf.w_BZ7I8Jybh_kopIPCVv{align-items:center}`, "",{"version":3,"sources":["webpack://./src/routes/settings/compliance/modals/components/Row/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iCAAA,CACA,cAAA,CACA,2CACE,kBAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: 152px 304px;\n  column-gap: 8px;\n  &.center{\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `_EJ8rqv7O46loqxMReEf`,
	"center": `w_BZ7I8Jybh_kopIPCVv`
};
export default ___CSS_LOADER_EXPORT___;
