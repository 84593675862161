import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import { Compliance } from 'src/Modals/AddNumberModal/steps/Compliance/Compliance'
import { ChooseNumber } from 'src/Modals/AddNumberModal/steps/ChooseNumber/ChooseNumber'
import numbersStore from 'store/settings/numbers/numbersStore'
import { RangeBar } from 'components/RangeBar/RangeBar'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { useSetFullBusinessProfile } from 'src/routes/settings/compliance/BusinessProfile/hooks/useSetFullBusinessProfile'
import { useEffect } from 'react'
import businessProfileStore from 'store/settings/company/businessProfileStore'

export const AddNumberModal = observer(() => {
  const { loading } = useSetFullBusinessProfile(
    !addNumberStore.openAddNumberModal
  )
  const onCancel = () => {
    addNumberStore.setOpenAddNumberModal(false)
  }
  const onContinue = () => {
    addNumberStore.setModalStep(1)
    addNumberStore.setOnlyTollFree(true)
    addNumberStore.setActiveTabIndexChoose(1)
  }

  useEffect(() => {
    if (businessProfileStore.completed) {
      onContinue()
    }
  }, [businessProfileStore.completed])

  return (
    <Modal
      open={addNumberStore.openAddNumberModal}
      onClose={onCancel}
      loading={loading}
      withoutHeader={loading}
      minWidth={600}
      title={
        addNumberStore.modalStep === 0 ? (
          'Compliance'
        ) : addNumberStore.stepChooseCount ? (
          <div className={'fullWidth'}>
            <div className={'flex-space-between mb16'}>
              <h3 className={'bold'}>
                {addNumberStore.stepChoose === 1 ? 'Choose number' : 'Payment'}
              </h3>
              <span className={'s2 medium gray1'}>
                Step {addNumberStore.stepChoose} /{' '}
                {addNumberStore.stepChooseCount}
              </span>
            </div>

            <RangeBar progress={addNumberStore.progressChoose} style2 />
          </div>
        ) : (
          'Choose number'
        )
      }
      subTitle={
        (addNumberStore.modalStep === 1 &&
          `${numbersStore.isFirstNumber ? 'Buy' : 'Add'} number`) ||
        ''
      }
    >
      <div className={classNames(styles.wrap, 'pModal')}>
        {addNumberStore.modalStep === 0 && (
          <Compliance onCancel={onCancel} onContinue={onContinue} />
        )}
        {addNumberStore.modalStep === 1 && <ChooseNumber />}
      </div>
    </Modal>
  )
})
