import { Route, Routes } from 'react-router-dom'
import { SegmentDetailLayout } from 'src/routes/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { NewSegmentPage } from 'src/routes/main/contacts/segments/DetailSegment/pages/NewSegmentPage'
import { DetailSegmentPage } from 'src/routes/main/contacts/segments/DetailSegment/pages/DetailSegmentPage'
import { DuplicateSegmentPage } from 'src/routes/main/contacts/segments/DetailSegment/pages/DuplicateSegmentPage'

export const SegmentDetailRoutes = () => {
  return (
    <Routes>
      <Route element={<SegmentDetailLayout />}>
        <Route path={'new'} element={<NewSegmentPage />} />
        <Route path={':id'} element={<DetailSegmentPage />} />
        <Route path={':id/duplicate'} element={<DuplicateSegmentPage />} />
      </Route>
    </Routes>
  )
}
