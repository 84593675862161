import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { Status, useGetKeywordContactsQuery } from 'src/generated/graphql'
import { useKeywordDetailContext } from 'src/routes/main/keywords/detail/keywordDetailPage'
import { AdditionalContent } from 'src/routes/main/keywords/detail/KeywordDetailTable/AdditionalContent/AdditionalContent'
import { LeftStickyContent } from 'src/routes/main/keywords/detail/KeywordDetailTable/LeftStickyContent/LeftStickyContent'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { observer } from 'mobx-react-lite'
import { useSetData } from 'src/hooks/useSetData'
import { IKeywordContact } from 'src/types/IKeywordContact'

export interface ITable {
  id: string
  name: string
  status: Status
  phone: string
  addedTime: number
}

export const KeywordDetailTable = observer(() => {
  const context = useKeywordDetailContext()
  const { keyword, tableStore, contacts } = context

  const options = useTablePaginationOptions(tableStore)

  const queryResult = useGetKeywordContactsQuery({
    variables: {
      ...options.variables,
      keywordId: +(keyword?.id || 0),
    },
    skip: !options.variables.dateRange
      ? false
      : !(options.variables.dateRange?.from && options.variables.dateRange?.to),
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => context.setContactsData(data),
    refreshDeps: [tableStore.refreshTrigger],
    disabledRefetch: !(
      (options.variables.dateRange?.from && options.variables.dateRange?.to) ||
      tableStore.rangeDatePickerStore.allTime
    ),
  })

  return (
    <NewTable<IKeywordContact>
      // withCheckbox
      columns={tableStore.visibleColumns}
      rows={contacts}
      withSearch
      maxHeight={'calc(100vh - 172px - 65px)'}
      additionalContent={<AdditionalContent />}
      leftStickyContent={<LeftStickyContent />}
      tableStore={tableStore}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      emptyContent={contacts.length ? undefined : <EmptyStateStatistic />}
      contentLoading={loading && !contacts.length}
    />
  )
})
