import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import user from 'store/user/user'
import { FC } from 'react'
import { ReactComponent as User } from 'icons/16pxNoMask/User.svg'

type Props = {
  imageUrl?: string
  firstName: string
  lastName: string
  color?: string
}

export const Avatar: FC<Props> = observer(
  ({ color, imageUrl, lastName, firstName }) => {
    const isNoData = !lastName && !firstName
    return imageUrl ? (
      <img
        src={user.imageUrl}
        alt=""
        width={48}
        height={48}
        className={styles.wrap}
      />
    ) : (
      <div className={styles.wrap} style={{ backgroundColor: color }}>
        {isNoData ? (
          <User />
        ) : (
          <p className={styles.logo}>
            {firstName.substring(0, 1)}
            {lastName.substring(0, 1)}
          </p>
        )}
      </div>
    )
  }
)
