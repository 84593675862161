import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import { PerformanceChartTypes } from 'src/routes/main/dashboard/Performance/Perfomance'
import { BroadcastsChart } from 'src/routes/main/dashboard/Performance/PerformanceChart/BroadcastsChart'
import { Column } from 'react-table'
import styles from 'src/routes/main/dashboard/Performance/PerformanceChart/styles.module.scss'
import { numberString } from 'src/util/functions'
import LineChart from 'src/routes/main/dashboard/Performance/PerformanceChart/LineChart'
import { ChartInfo } from 'src/routes/main/dashboard/Performance/PerformanceChart/ChartInfo'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
)

interface ICCampaignsTable {
  name: string
  total: number
  success: number
  scheduled: number
  unsubscribed: number
  failed: number
  color: string
}

interface IKeywordTable {
  name: string
  subscribers: number
  unsubscribed: number
  active: number
  color: string
}

export const PerformanceChart = ({ type }: { type: PerformanceChartTypes }) => {
  if (type === PerformanceChartTypes.broadcasts) {
    return (
      <>
        <ChartInfo
          info={{
            label: 'Success messages',
            count: 2137,
            profit: 0,
          }}
        />
        <BroadcastsChart />
      </>
    )
  }
  if (type === PerformanceChartTypes.campaigns) {
    const data: ICCampaignsTable[] = [
      {
        name: 'Digital camp',
        total: 762,
        success: 738,
        scheduled: 80,
        unsubscribed: 36,
        failed: 24,
        color: '#7E31F4',
      },
      {
        name: 'Sun workshop',
        total: 1804,
        success: 1804,
        scheduled: 200,
        unsubscribed: 10,
        failed: 0,
        color: '#FFC043',
      },
      {
        name: 'Marketing',
        total: 3065,
        success: 3060,
        scheduled: 104,
        unsubscribed: 24,
        failed: 5,
        color: '#FC7327',
      },
    ]
    const columns: Column<ICCampaignsTable>[] = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          value,
          row: {
            original: { color },
          },
        }) => (
          <div className={styles.nameCell}>
            <div className={styles.marker} style={{ background: color }} />
            {value}
          </div>
        ),
      },
      {
        Header: 'Total',
        accessor: 'total',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Success',
        accessor: 'success',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Scheduled',
        accessor: 'scheduled',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Unsubscribed',
        accessor: 'unsubscribed',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Failed',
        accessor: 'failed',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
    ]
    return (
      <>
        <ChartInfo
          info={{
            label: 'Success messages',
            count: 5602,
            profit: 25,
          }}
        />
        <LineChart data={data} columns={columns} idChart={'campaignsChart'} />
      </>
    )
  }
  if (type === PerformanceChartTypes.keywords) {
    const data: IKeywordTable[] = [
      {
        name: 'Desire',
        subscribers: 130,
        unsubscribed: 7,
        active: 123,
        color: '#236DF4',
      },
      {
        name: 'Elevate',
        subscribers: 90,
        unsubscribed: 4,
        active: 86,
        color: '#A2D2F2',
      },
      {
        name: 'Boost',
        subscribers: 220,
        unsubscribed: 26,
        active: 214,
        color: '#DA3BF3',
      },
    ]

    const columns: Column<IKeywordTable>[] = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          value,
          row: {
            original: { color },
          },
        }) => (
          <div className={styles.nameCell}>
            <div className={styles.marker} style={{ background: color }} />
            {value}
          </div>
        ),
      },
      {
        Header: 'Subscribers',
        accessor: 'subscribers',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Unsubscribed',
        accessor: 'unsubscribed',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
    ]
    return (
      <>
        <ChartInfo
          info={{
            label: 'Subscribers',
            count: 440,
            profit: 18,
          }}
        />
        <LineChart data={data} columns={columns} idChart={'keywordsChart'} />
      </>
    )
  }
  return <></>
}
