import { makeAutoObservable } from 'mobx'
import {
  Address,
  ContactCardFragment,
  ContactCardRequestInput,
} from 'src/generated/graphql'
import { EnumVCardFields } from 'src/enums/EnumVCardFields'

class CreateVCardStore {
  constructor() {
    makeAutoObservable(this)
  }
  callbackOnSave: ((vCard: ContactCardFragment) => void) | null = null
  name = ''
  tagline = ''
  formattedPhoneNumber = ''
  website = ''
  email = ''
  address: Address = {}
  facebookUrl = ''
  twitterUrl = ''
  instagramUrl = ''
  linkedinUrl = ''
  youtubeUrl = ''

  isOpenModal = false

  editId = 0
  memberId = 0

  get cardInput(): ContactCardRequestInput {
    return {
      name: this.name || null,
      tagline: this.tagline || null,
      phoneNumber: this.formattedPhoneNumber || null,
      website: this.website || null,
      email: this.email || null,
      street: this.address.street || null,
      city: this.address.city || null,
      state: this.address.state || null,
      zipCode: this.address.zipCode || null,
      countryCode: this.address.country?.countryCode || null,
      facebookUrl: this.facebookUrl || null,
      twitterUrl: this.twitterUrl || null,
      instagramUrl: this.instagramUrl || null,
      linkedinUrl: this.linkedinUrl || null,
      youtubeUrl: this.youtubeUrl || null,
    }
  }

  get disabledSave() {
    return (
      !this.name.trim() ||
      !this.formattedPhoneNumber ||
      this.formattedPhoneNumber.includes('_')
    )
  }

  setValue(value: string, key: EnumVCardFields) {
    this[key] = value
  }
  setAddress(address: Address = {}) {
    this.address = address
  }
  private setInitValue(vCard: ContactCardFragment) {
    this.editId = vCard.id
    this.memberId = vCard.member?.id
    Object.values(EnumVCardFields).forEach((key) => {
      this[key] = vCard[key] || ''
    })
    this.address = vCard.address || {}
  }
  private clearData() {
    Object.values(EnumVCardFields).forEach((key) => {
      this[key] = ''
    })
    this.address = {}
  }

  setCallbackOnSave(fn: ((vCard: ContactCardFragment) => void) | null) {
    this.callbackOnSave = fn
  }

  openModal(vCard?: ContactCardFragment) {
    this.isOpenModal = true
    if (vCard) {
      this.setInitValue(vCard)
    }
  }
  closeModal() {
    this.isOpenModal = false
    this.clearData()
  }
}

export default new CreateVCardStore()
