// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YWOMirAQMFRFqEfmcjqJ{margin-top:40px;display:flex;justify-content:space-between}.YWOMirAQMFRFqEfmcjqJ .hAj07dzMFWf76FJyWpHw{display:flex;column-gap:12px}.Udb6dzWiNqFbB0PMQ6Xv{padding:8px}`, "",{"version":3,"sources":["webpack://./src/routes/main/broadcasts/createBroadcast/newBroadcast/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,6BAAA,CACA,4CACE,YAAA,CACA,eAAA,CAGJ,sBACE,WAAA","sourcesContent":[".actionsWrap{\n  margin-top: 40px;\n  display: flex;\n  justify-content: space-between;\n  .left{\n    display: flex;\n    column-gap: 12px;\n  }\n}\n.menuWrap{\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsWrap": `YWOMirAQMFRFqEfmcjqJ`,
	"left": `hAj07dzMFWf76FJyWpHw`,
	"menuWrap": `Udb6dzWiNqFbB0PMQ6Xv`
};
export default ___CSS_LOADER_EXPORT___;
