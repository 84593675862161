import { FC, useEffect, useState } from 'react'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { ReactComponent as DragIcon } from 'icons/16px/Drag Drop.svg'
import { Textarea } from 'components/Textarea/Textarea'
import { ITextareaDataCollectionItem, TextareaStore } from 'store/textareaStore'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'

type Props = {
  item: ITextareaDataCollectionItem
  textareaStore: TextareaStore
  fromTemplate?: boolean
}

export const DataCollectionItem: FC<Props> = observer(
  ({ item, fromTemplate, textareaStore }) => {
    const [newTextareaStore] = useState(new TextareaStore({}))
    const onChecked = () => {
      uiStore.setTriggerHeight()
      textareaStore.updateDataCollectionItem(item.key, {
        ...item,
        active: !item.active,
      })
    }

    useEffect(() => {
      textareaStore.updateDataCollectionItem(item.key, {
        ...item,
        messageHtml: newTextareaStore.messageHtml,
      })
    }, [newTextareaStore.messageHtml])

    useEffect(() => {
      textareaStore.updateDataCollectionItem(item.key, {
        ...item,
        attachImagesIds: newTextareaStore.attachImagesIds,
      })
    }, [newTextareaStore.attachImagesIds])

    useEffect(() => {
      textareaStore.updateDataCollectionItem(item.key, {
        ...item,
        messageId: newTextareaStore.messageId,
      })
    }, [newTextareaStore.messageId])

    useEffect(() => {
      newTextareaStore.setMessageText(item.messageHtml, item.messageInnerText)
      newTextareaStore.setAttachImages(item.attachImagesIds)
      newTextareaStore.setTriggerMessageUpdate()
    }, [])

    return (
      <>
        <InputCheckbox
          label={item.title}
          checked={item.active}
          onChecked={onChecked}
          fromDropdown
          rightIcon={<DragIcon />}
        />
        {item.active && (
          <div className={styles.wrapResponse}>
            <div className={styles.form}>
              <div className={styles.headerForm}>
                <span className={'s2 gray1'}>Response</span>
              </div>
              <Textarea
                textareaStore={newTextareaStore}
                className={styles.textarea}
                fromTemplate={fromTemplate}
                fromDataCollectionItem
                smallGallery
              />
            </div>
          </div>
        )}
      </>
    )
  }
)
