import styles from './styles.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import auth from 'store/auth'
import { useEffect } from 'react'
import mainStore from 'store/mainStore'

export const OAuthLogin = () => {
  const params = useSearchParams()
  const navigate = useNavigate()
  auth.setLoginSearchParams(params[0])
  useEffect(() => {
    const client_id = auth.loginSearchParams?.get('client_id')
    if (!client_id) {
      navigate(mainStore.redirectPathAfterLogin)
    }
  }, [auth.loginSearchParams])
  return (
    <div className={styles.wrap}>
      <AbsoluteLoader />
    </div>
  )
}
