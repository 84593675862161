import styles from './styles.module.scss'
import { AppSymbols } from 'src/util/symbols'
import { Maybe } from 'src/generated/graphql'
import { ReactNode } from 'react'
import classNames from 'classnames'

export const Row = ({
  title,
  value = AppSymbols.dash,
  values,
  center,
}: {
  title: string
  value?: Maybe<string> | ReactNode
  values?: Maybe<string>[]
  center?: boolean
}) => {
  return (
    <div className={classNames(styles.wrap, center && styles.center)}>
      <p className={'par2 gray1'}>{title}</p>
      {values ? (
        <div>
          {values.map((item) => (
            <p key={item} className={'par2'}>
              {item}
            </p>
          ))}
        </div>
      ) : (
        <p className={'par2'}>{value}</p>
      )}
    </div>
  )
}
