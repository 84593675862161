import { useGetAllNumbersQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import { useRefetch } from 'src/hooks/useRefetch'
import numbersStore from 'store/settings/numbers/numbersStore'

export function useSetNumbers() {
  const { data, refetch, loading } = useGetAllNumbersQuery()

  useEffect(() => {
    if (data) {
      numbersStore.setAllNumbersData(data)
    }
  }, [data])
  useRefetch(refetch)

  return { loading }
}
