// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PBMLiWokkPwYpogroqB_{max-height:calc(100vh - 88px - 64px - 64px - 40px - 64px);overflow-y:scroll;position:relative}`, "",{"version":3,"sources":["webpack://./src/components/Wrappers/ScrollWrapper/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,yDAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".wrapContent{\n  max-height: calc(100vh - 88px - 64px - 64px - 40px - 64px);\n  overflow-y: scroll;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapContent": `PBMLiWokkPwYpogroqB_`
};
export default ___CSS_LOADER_EXPORT___;
