import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16px/Plus.svg'
import customPlansStore from 'store/superAdmin/customPlansStore'

export const Actions = observer(() => {
  const handleCreate = () => {
    customPlansStore.onOpenModal()
  }
  return (
    <div>
      <Button
        size={BtnSize.medium}
        typeBtn={BtnType.primary}
        onClick={handleCreate}
        withIcon
      >
        <Plus />
        New Plan
      </Button>
    </div>
  )
})
