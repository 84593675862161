import styles from './styles.module.scss'
import { SMSMessageContent } from 'components/Preview/SMSMessageContent'
import { AudioMessageContent } from 'components/Preview/AudioMessageContent'
import { RinglessContent } from 'components/Preview/RinglessContent'
import classNames from 'classnames'
import { IPreviewContent } from 'components/Preview/types'
import { ContentType } from 'src/generated/graphql'
import { DisabledMessage } from 'components/Preview/DisabledMessage/DisabledMessage'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { useEffect } from 'react'

export enum PreviewEnum {
  sms,
  voice,
  ringless,
}

export const Preview = observer(
  ({ messages }: { messages: IPreviewContent[] }) => {
    useEffect(() => {
      if (uiStore.isMobile) {
        uiStore.setMobilePage('Preview messages')
        uiStore.setCloseAction(() => uiStore.setIsPreview(false))
      }
      return () => {
        if (uiStore.isMobile) {
          uiStore.setMobilePage('')
          uiStore.setCloseAction(null)
        }
      }
    }, [uiStore.isMobile])
    return (
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.innerContent}>
            {messages.map((message, index) => {
              const getContent = () => {
                if (message.disabled) {
                  return <DisabledMessage />
                } else {
                  if (message.type === ContentType.Sms) {
                    return <SMSMessageContent {...message.smsMessages} />
                  }
                  if (message.type === ContentType.Voice) {
                    return <AudioMessageContent {...message.voiceMessage} />
                  }
                  if (message.type === ContentType.Ringless) {
                    return <RinglessContent {...message.ringlessMessage} />
                  }
                  return <></>
                }
              }
              return (
                <div key={index}>
                  {message.userAnswer && (
                    <div
                      className={classNames(
                        styles.answerWrap,
                        styles.userAnswer
                      )}
                    >
                      <div className={styles.answer}>
                        <div className={styles.text}>{message.userAnswer}</div>
                      </div>
                    </div>
                  )}
                  <div className={styles.header}>
                    <span className={'s4 medium gray1'}>
                      {message.sendTimeString}
                    </span>
                  </div>
                  {getContent()}
                </div>
              )
            })}
          </div>
          {uiStore.isMobile && uiStore.mobileActions && (
            <div className={styles.wrapActions}>{uiStore.mobileActions}</div>
          )}
        </div>
      </div>
    )
  }
)
