import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import styles from './syles.module.scss'
import { observer } from 'mobx-react-lite'
import { RequiredFields, TableProps } from 'components/NewTable/types'
import { useEffect } from 'react'

type AllSelectedProps<T extends RequiredFields<T>> = {
  colSpan: number
  tableStore: TableProps<T>['tableStore']
}

export const AllSelected = observer(
  <T extends RequiredFields<T>>({
    colSpan,
    tableStore,
  }: AllSelectedProps<T>) => {
    const { checkedRowsIds, isSelectALl, total, element, setSelectALl } =
      tableStore

    const isShow = checkedRowsIds.length

    if (!isShow) {
      return null
    }
    return (
      <TableRow className={styles.row}>
        <th colSpan={colSpan}>
          <div className={styles.content}>
            {isSelectALl ? (
              <>
                <span>
                  All {total} {element + 's'} are selected.
                </span>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span
                  onClick={() => setSelectALl(false)}
                  className={'bold blue3 link'}
                >
                  Clear selection
                </span>
              </>
            ) : (
              <>
                <span>
                  You have selected {checkedRowsIds.length} of {total}{' '}
                  {element + 's'}
                </span>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span
                  onClick={() => setSelectALl(true)}
                  className={'bold blue3 link'}
                >
                  Select all {element + 's'}
                </span>
              </>
            )}
          </div>
        </th>
      </TableRow>
    )
  }
)
