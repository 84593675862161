import { makeAutoObservable } from 'mobx'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { AppSymbols } from 'src/util/symbols'
import {
  monthlyToggleOptions,
  onTheDayOptions,
  onTheOrderOptions,
  operatorsOptions,
  periodsOptions,
} from 'components/Schedule/store/mockData'
import {
  CampaignDelayPeriod,
  CampaignDelayType,
  CampaignScheduleType,
  CampaignStepDelayRequestInput,
  CampaignStepDelayResponse,
  CustomFieldType,
  CustomMonthRepeatType,
  DayOfWeek,
  OnMonthDayOrdinal,
  OnMonthDayType,
  RepeatType,
  SendLaterRepeatType,
} from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'
import {
  iCustomFieldToOption,
  transformCustomFieldsFromBack,
} from 'store/contacts/customFields/functions'
import { ICustomField } from 'src/types/ICustomField'

export class CampaignScheduleStore {
  constructor() {
    makeAutoObservable(this)
  }
  laterCount: number | string = 1
  laterPeriod = periodsOptions[0]
  laterDate: Date | null = null
  laterAdvanced = false
  laterAdvancedTime: Date | null = null

  calendarDate: Date | null = null

  repeatMonthlyToggle: IOption = monthlyToggleOptions[0]
  selectedOnTheOrderOptions: IOption = onTheOrderOptions[0]
  selectedOnTheDayOptions: IOption = onTheDayOptions[0]
  onlyImmediatelyBetween = false
  betweenStartTime: Date | null = new Date(dayjs().startOf('day').valueOf())
  betweenEndTime: Date | null = new Date(
    dayjs().startOf('day').add(18, 'hours').valueOf()
  )

  sendTypeCampaign: CampaignDelayType = CampaignDelayType.Immediate
  sendLaterConfigRepeatType: SendLaterRepeatType = SendLaterRepeatType.Never
  repeatConfigEndDate: Date | null = null
  customRepeatConfigRepeatCount = 1
  customRepeatConfigRepeatType: RepeatType = RepeatType.Daily
  customMonthRepeatType: CustomMonthRepeatType = CustomMonthRepeatType.Each
  customEachMonthDayConfigDays: Array<number | null> = []
  customOnMonthDayConfigDayOrdinal: OnMonthDayOrdinal = OnMonthDayOrdinal.First
  customOnMonthDayConfigDayType: OnMonthDayType = OnMonthDayType.Sunday
  customRepeatWeekConfigDays: Array<DayOfWeek | null> = [
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
  ]

  refModalContent: HTMLDivElement | null = null

  contactDateFieldCount: number | string = 1
  contactDateFieldPeriod = periodsOptions[0]
  contactDateFieldOperator = operatorsOptions[0]
  contactDateField: IOption<ICustomField> | null = null
  contactDateFieldDate: Date | null = null

  get laterDateBackend() {
    if (
      this.laterDate &&
      this.laterPeriod?.value !== CampaignDelayPeriod.Hour &&
      this.laterPeriod?.value !== CampaignDelayPeriod.Minute
    ) {
      return this.laterDate
    }
    return null
  }

  get delayInput(): CampaignStepDelayRequestInput | null {
    if (this.activeSendLater) {
      return {
        value: +this.laterCount || 1,
        period: this.laterPeriod.value as CampaignDelayPeriod,
        hour: this.laterDateBackend ? +dayjs(this.laterDate).format('H') : 0,
        minute: this.laterDateBackend ? +dayjs(this.laterDate).format('m') : 0,
      }
    }
    if (this.activeCalendarDate) {
      return {
        value: 0,
        sendDate:
          this.calendarDate && dayjs(this.calendarDate).utc().toISOString(),
      }
    }
    if (this.activeContactDateField) {
      return {
        value: +this.contactDateFieldCount,
        period: this.contactDateFieldPeriod.value as CampaignDelayPeriod,
        scheduleType: this.contactDateFieldOperator
          .value as CampaignScheduleType,
        customFieldId: this.contactDateField?.idLong,
        hour: this.contactDateFieldDate
          ? +dayjs(this.contactDateFieldDate).format('H')
          : 0,
        minute: this.contactDateFieldDate
          ? +dayjs(this.contactDateFieldDate).format('m')
          : 0,
      }
    }
    return null
  }

  get scheduleError() {
    return ''
  }

  get activeSendImmediately() {
    return this.sendTypeCampaign === CampaignDelayType.Immediate
  }
  get activeSendLater() {
    return this.sendTypeCampaign === CampaignDelayType.Delay
  }

  get activeCalendarDate() {
    return this.sendTypeCampaign === CampaignDelayType.Date
  }

  get activeContactDateField() {
    return this.sendTypeCampaign === CampaignDelayType.DelayCustomField
  }

  get sendTimeString() {
    if (this.activeSendImmediately) {
      return 'Immediately'
    }
    if (this.activeSendLater) {
      return `${this.laterCount} ${this.laterPeriod.title}${
        Number(this.laterCount) > 1 ? 's' : ''
      } after ${
        this.laterAdvancedTime
          ? `- ${dayjs(this.laterAdvancedTime).format('h:mm a')}`
          : ''
      }`
    }
    if (this.activeCalendarDate) {
      return (
        (this.calendarDate &&
          `${dayjs(this.calendarDate).format(DayjsFormats.fullWithAt)}`) ||
        ''
      )
    }
    if (this.activeContactDateField) {
      if (this.contactDateFieldOperator.value === CampaignScheduleType.On) {
        return `On the ${this.contactDateField?.title || ''}`
      }
      return `${this.contactDateFieldCount} ${
        this.contactDateFieldPeriod.title
      }${this.contactDateFieldCount > 1 ? 's' : ''} ${
        this.contactDateFieldOperator.title
      } ${this.contactDateField ? `the ${this.contactDateField.title}` : ''}`
    }
    return ''
  }

  get suffixString() {
    if (this.activeSendImmediately) {
      if (this.onlyImmediatelyBetween) {
        return ` ${AppSymbols.point} ${dayjs(this.betweenStartTime).format(
          'h:mm A'
        )} - ${dayjs(this.betweenEndTime).format('h:mm A')}`
      }
      return ''
    }
    if (this.activeSendLater) {
      if (this.laterDateBackend) {
        return ` ${AppSymbols.point} ${dayjs(this.laterDateBackend).format(
          'h:mm A'
        )}`
      }

      // if (this.laterAdvanced) {
      //   if (this.customRepeatWeekConfigDays.length > 2) {
      //     return `${AppSymbols.point} ${getChainDaysString(
      //       this.customRepeatWeekConfigDays
      //     )}`
      //   } else {
      //     return `${AppSymbols.point} ${this.customRepeatWeekConfigDays
      //       .map((day) => day && DaysWeeklyStringObjMap.get(day)?.short)
      //       .join(', ')}`
      //   }
      // }
    }
    if (
      this.activeContactDateField &&
      this.contactDateField?.data?.type === CustomFieldType.Date &&
      this.contactDateFieldDate
    )
      return ` ${AppSymbols.point} ${dayjs(this.contactDateFieldDate).format(
        'h:mm A'
      )}`
  }

  setSendTypeCampaign(sendTyp: CampaignDelayType) {
    this.sendTypeCampaign = sendTyp
  }

  setCampaignDelay = (
    delayType?: CampaignDelayType,
    delay?: CampaignStepDelayResponse
  ) => {
    this.sendTypeCampaign = delayType || CampaignDelayType.Immediate
    if (delayType === CampaignDelayType.Delay) {
      if (delay?.period) {
        const findPeriod = periodsOptions.find(
          (period) => period.value === delay.period
        )
        if (findPeriod) {
          this.laterPeriod = findPeriod
        }
      }
      if (delay?.value) {
        this.laterCount = delay.value
      }
      if (delay?.hour || delay?.minute) {
        this.laterDate = dayjs()
          .startOf('day')
          .add(delay.hour || 0, 'hour')
          .add(delay.minute || 0, 'minute')
          .toDate()
      }
    }
    if (delayType === CampaignDelayType.Date && delay?.sendDate) {
      this.calendarDate = dayjs(delay.sendDate).toDate()
    }
    if (delayType === CampaignDelayType.DelayCustomField && delay) {
      this.contactDateFieldCount = delay.value
      this.contactDateFieldPeriod =
        periodsOptions.find((period) => period.value === delay.period) ||
        periodsOptions[0]
      this.contactDateFieldOperator =
        operatorsOptions.find(
          (operator) => operator.value === delay.scheduleType
        ) || operatorsOptions[0]
      if (delay.customField) {
        this.contactDateField = iCustomFieldToOption(
          transformCustomFieldsFromBack([delay.customField])[0]
        )
      }
      if (delay.hour || delay.minute) {
        this.contactDateFieldDate = dayjs()
          .startOf('day')
          .add(delay.hour || 0, 'hour')
          .add(delay.minute || 0, 'minute')
          .toDate()
      }
    }
  }
  setLaterCount = (count: number | string) => {
    this.laterCount = count
  }
  setLaterPeriod(opt: IOption) {
    this.laterPeriod = opt
  }
  setLaterDate = (date: typeof this.laterDate) => {
    this.laterDate = date
  }
  setLaterAdvanced(state: boolean) {
    this.laterAdvanced = state
  }
  setLaterAdvancedTime(time: Date | null) {
    this.laterAdvancedTime = time
  }
  selectLaterWeeklyDay(day: DayOfWeek | null) {
    if (this.customRepeatWeekConfigDays.includes(day)) {
      this.customRepeatWeekConfigDays = this.customRepeatWeekConfigDays.filter(
        (daySelected) => day !== daySelected
      )
    } else {
      this.customRepeatWeekConfigDays.push(day)
    }
  }
  onPlusRepeats() {
    this.customRepeatConfigRepeatCount += 1
  }
  onMinusRepeats() {
    if (this.customRepeatConfigRepeatCount === 1) {
      return
    }
    this.customRepeatConfigRepeatCount -= 1
  }
  setSendLaterConfigRepeatType(ending: SendLaterRepeatType) {
    this.sendLaterConfigRepeatType = ending
  }
  setCustomRepeatConfigRepeatType(type: RepeatType) {
    this.customRepeatConfigRepeatType = type
  }
  selectWeeklyDay(day: DayOfWeek | null) {
    if (this.customRepeatWeekConfigDays.includes(day)) {
      this.customRepeatWeekConfigDays = this.customRepeatWeekConfigDays.filter(
        (daySelected) => day !== daySelected
      )
    } else {
      this.customRepeatWeekConfigDays.push(day)
    }
  }
  selectMonthlyDay(day: number) {
    if (this.customEachMonthDayConfigDays.includes(day)) {
      this.customEachMonthDayConfigDays =
        this.customEachMonthDayConfigDays.filter(
          (daySelected) => day !== daySelected
        )
    } else {
      this.customEachMonthDayConfigDays.push(day)
    }
  }
  setCustomMonthRepeatType(value: CustomMonthRepeatType) {
    this.customMonthRepeatType = value
  }

  setCustomOnMonthDayConfigDayOrdinal(value: OnMonthDayOrdinal) {
    this.customOnMonthDayConfigDayOrdinal = value
  }
  setCustomOnMonthDayConfigDayType(value: OnMonthDayType) {
    this.customOnMonthDayConfigDayType = value
  }
  setRepeatConfigEndDate(date: Date | null) {
    this.repeatConfigEndDate = date
  }
  setOnlyImmediatelyBetween(state: boolean) {
    this.onlyImmediatelyBetween = state
  }
  setBetweenStartTime(time: Date | null) {
    this.betweenStartTime = time
  }
  setBetweenEndTime(time: Date | null) {
    this.betweenEndTime = time
  }

  setRefModalContent = (element: typeof this.refModalContent) => {
    this.refModalContent = element
  }

  setCalendarDate = (date: typeof this.calendarDate) => {
    this.calendarDate = date || null
  }

  setContactDateFieldCount = (count: number | string) => {
    this.contactDateFieldCount = count
  }
  setContactDateFieldPeriod = (opt: IOption) => {
    this.contactDateFieldPeriod = opt
  }
  setContactDateFieldOperator = (opt: IOption) => {
    this.contactDateFieldOperator = opt
  }
  setContactDateFieldId = (opt: IOption) => {
    this.contactDateField = opt
  }
  setContactDateFieldDate = (date: typeof this.contactDateFieldDate) => {
    this.contactDateFieldDate = date
  }
}
