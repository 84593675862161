// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uVFBJXw87USwZ_diH6FN{display:grid;grid-template-columns:1fr 240px}.aWUxTQ6BXUsWZjPGKF6r{display:flex;column-gap:12px}.Y3TcIKfNGFl5ecc0ohyF{width:fit-content}`, "",{"version":3,"sources":["webpack://./src/routes/main/keywords/detail/KeywordDetailTable/LeftStickyContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+BAAA,CAEF,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,iBAAA","sourcesContent":[".row{\n  display: grid;\n  grid-template-columns: 1fr 240px;\n}\n.wrapActions{\n  display: flex;\n  column-gap: 12px;\n}\n.wrapRangePicker{\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `uVFBJXw87USwZ_diH6FN`,
	"wrapActions": `aWUxTQ6BXUsWZjPGKF6r`,
	"wrapRangePicker": `Y3TcIKfNGFl5ecc0ohyF`
};
export default ___CSS_LOADER_EXPORT___;
