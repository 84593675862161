import React, { useState } from 'react'
import styles from './styles.module.scss'
import { NumericFormat } from 'react-number-format'
import classNames from 'classnames'

type Props = {
  number?: boolean
  value?: string
  setValue: (val: string) => void
  rightContent?: JSX.Element
}
export const NewTextInput = ({
  number,
  value,
  setValue,
  rightContent,
}: Props) => {
  const [focus, setFocus] = useState(false)

  const onFocus = () => {
    setFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
  }
  return (
    <div className={classNames(styles.wrap, focus && styles.focus)}>
      {number ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <NumericFormat
          value={value}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={(event) => setValue(event.target.value)}
          placeholder={'Enter value'}
          className={styles.input}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      ) : (
        <input
          value={value}
          onChange={(event) => setValue(event.target.value)}
          placeholder={'Enter value'}
          className={styles.input}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      {rightContent}
    </div>
  )
}
