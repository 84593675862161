import classNames from 'classnames'
import styles from 'components/Dropdown/styles.module.scss'
import { Option } from 'components/Dropdown/Option/Option'
import React from 'react'
import { IOption } from 'src/types/IOption'
import { OptionsRenderProps } from 'components/Dropdown/types'

export const onSelectOption = ({
  option,
  isSelect,
  multi,
  onMultiSelect,
  selectedOptions,
  onSelect,
  setShowTT,
}: {
  option: IOption
  isSelect?: boolean
  multi?: boolean
  onMultiSelect?: (options: IOption[]) => void
  onSelect?: (options: IOption) => void
  selectedOptions?: IOption[]
  setShowTT: (val: boolean) => void
}) => {
  if (multi) {
    if (isSelect) {
      onMultiSelect &&
        onMultiSelect(
          selectedOptions?.filter((opt) => opt.value !== option.value) || []
        )
    } else {
      onMultiSelect &&
        onMultiSelect(selectedOptions ? [...selectedOptions, option] : [])
    }
  } else {
    onSelect && onSelect(option)
    setShowTT(false)
  }
}

export const OptionRender = <T,>({
  option,
  selectedOption,
  selectedOptions,
  onSelect,
  onMultiSelect,
  multi,
  setShowTT,
  minHeightOption,
  value,
  withInput,
  showTT,
}: OptionsRenderProps<T> & {
  option: IOption
}) => {
  const isSelect =
    (selectedOption?.value === option.value &&
      !(withInput && value !== option.value)) ||
    !!selectedOptions?.map((opt) => opt.value).includes(option.value)
  if (option.isLabel) {
    return (
      <div className={classNames('s2 medium gray1', styles.optionLabel)}>
        {option.title}
      </div>
    )
  }
  return (
    <Option
      multi={multi}
      showTT={showTT}
      option={option}
      isSelect={isSelect}
      onSelectOption={(option) =>
        onSelectOption({
          option,
          isSelect,
          onSelect,
          selectedOptions,
          onMultiSelect,
          multi,
          setShowTT,
        })
      }
      key={option.value}
      minHeightOption={minHeightOption}
      isOneChecked={!!selectedOption || !!selectedOptions?.length}
    />
  )
}
