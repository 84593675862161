import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { OptInFilter } from 'store/contacts/segment/filters/OptInFilter'
import { useMemo, useState } from 'react'
import { useGetAllKeywordsQuery } from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'

export const KeywordDropdown = observer(
  ({ filter }: { filter: OptInFilter }) => {
    const [search, setSearch] = useState('')
    const { data, loading } = useGetAllKeywordsQuery({
      variables: {
        page: {
          pageNumber: 0,
          pageSize: 20,
        },
        searchPattern: search,
      },
    })
    const options: IOption[] = useMemo(() => {
      const options: IOption[] = []
      data?.getAllKeywords?.content?.forEach((keyword) => {
        if (keyword) {
          options.push({
            title: keyword.name,
            value: keyword.id,
          })
        }
      })

      return options
    }, [data])
    return (
      <Dropdown
        search={search}
        setSearch={setSearch}
        loadingSearchData={loading}
        withSearch
        options={options}
        multi
        selectedValues={filter.keywordsIds}
        onMultiSelectValues={filter.setKeywordsIds}
        selectedTag
        onDeleteTag={(id) => filter.onDeleteKeywordsId(id as number)}
        placeholder={'Select'}
        withSelectALl
        emptyText={'You don’t have any keywords yet'}
      />
    )
  }
)
