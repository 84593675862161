import { ActionsEnum } from 'components/Button/types'
import { BroadcastDetailsBtn } from 'src/routes/main/broadcasts/components/BroadcastDetailsBtn'
import { BroadcastPauseBtn } from 'src/routes/main/broadcasts/components/BroadcastPauseBtn'
import { BroadcastResumeBtn } from 'src/routes/main/broadcasts/components/BroadcastResumeBtn'
import { BroadcastRestoreBtn } from 'src/routes/main/broadcasts/components/BroadcastRestoreBtn'
import { BroadcastEditBtn } from 'src/routes/main/broadcasts/components/BroadcastEditBtn'
import { BroadcastStopBtn } from 'src/routes/main/broadcasts/components/BroadcastStopBtn'
import { BroadcastStartBtn } from 'src/routes/main/broadcasts/components/BroadcastStartBtn'
import { BroadcastDuplicateBtn } from 'src/routes/main/broadcasts/components/BroadcastDuplicateBtn'
import { BroadcastAnalyticsBtn } from 'src/routes/main/broadcasts/components/BroadcastAnalyticsBtn'
import { BroadcastArchiveBtn } from 'src/routes/main/broadcasts/components/BroadcastArchiveBtn'
import { BroadcastDeleteBtn } from 'src/routes/main/broadcasts/components/BroadcastDeleteBtn'
import { BroadcastExportBtn } from 'src/routes/main/broadcasts/components/BroadcastExportBtn'

export const broadcastBtnByAction = {
  [ActionsEnum.details]: BroadcastDetailsBtn,
  [ActionsEnum.pause]: BroadcastPauseBtn,
  [ActionsEnum.resume]: BroadcastResumeBtn,
  [ActionsEnum.restore]: BroadcastRestoreBtn,
  [ActionsEnum.edit]: BroadcastEditBtn,
  [ActionsEnum.stop]: BroadcastStopBtn,
  [ActionsEnum.start]: BroadcastStartBtn,
  [ActionsEnum.duplicate]: BroadcastDuplicateBtn,
  [ActionsEnum.analytics]: BroadcastAnalyticsBtn,
  [ActionsEnum.export]: BroadcastExportBtn,
  [ActionsEnum.archive]: BroadcastArchiveBtn,
  [ActionsEnum.delete]: BroadcastDeleteBtn,
}
