import styles from './styles.module.scss'
import classNames from 'classnames'
import { numberString } from 'src/util/functions'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Angle } from 'icons/12px/NoMask/Angle-Up.svg'
import { useState } from 'react'

export const CreditsItem = ({
  infoText,
  count,
  label,
  mb8,
}: {
  infoText: string
  count: number
  label: string
  mb8?: boolean
}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={classNames(mb8 && 'mb8')}>
      <div
        className={' pointer fullWidth leftAlign'}
        onClick={() => setOpen(!open)}
        aria-hidden={true}
      >
        <span className={'white'}>
          {numberString({ val: count, maximumFractionDigits: 1 })} credits
        </span>
        <div className={'row8'}>
          <span className={'s2 gray2'}>{label}</span>
          <AppIcon
            type={IIconType.stroke}
            color={ColorsNames.gray1}
            className={classNames(!open && 'rotate')}
          >
            <Angle />
          </AppIcon>
        </div>
      </div>
      {open && (
        <div className={classNames(styles.info, 's2 gray2')}>{infoText}</div>
      )}
    </div>
  )
}
