import { ForwardedRef, forwardRef, useState } from 'react'
import { ColorsNames } from 'styles/variables'
import { AppIcon, ImportIcons } from 'components/AppIcon/AppIcon'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { Colors } from 'styles/colors'

type Props = JSX.IntrinsicElements['button'] & {
  title: string
}

// eslint-disable-next-line react/display-name
export const PlusBtn = forwardRef(
  ({ title, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    const [hover, setHover] = useState(false)
    return (
      <button
        {...props}
        ref={ref}
        className={classNames(props.className, styles.wrap, 'row8')}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className={styles.wrapIcon}
          style={{
            backgroundColor: hover
              ? Colors['PrimaryColorBlue6']
              : Colors['PrimaryColorBlue5'],
          }}
        >
          <AppIcon
            color={hover ? ColorsNames.blue4 : ColorsNames.blue3}
            icon={ImportIcons.plus}
          />
        </div>
        <span className={classNames('bold', hover ? 'blue4' : 'blue3')}>
          {title}
        </span>
      </button>
    )
  }
)
