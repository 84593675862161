import { TablePage } from 'components/Page/TablePage'
import { TriggersTable } from 'src/routes/main/triggers/TriggersTable/TriggersTable'

export const Triggers = () => {
  return (
    <TablePage>
      <h1>Triggers</h1>
      <TriggersTable />
    </TablePage>
  )
}
