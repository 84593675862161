import { makeAutoObservable } from 'mobx'
import {
  Member,
  PaymentRegistrationSelectPricesQuery,
  SimplePrice,
} from 'src/generated/graphql'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'
import { payGoLite, simplePriceToTitle } from 'store/settings/billing/hellpers'

class MigrationStore {
  constructor() {
    makeAutoObservable(this)
  }

  openConfirmKeepCurrentMigrationPlanModal = false

  memberRegistration: Member['memberRegistration'] | null = null
  prices: SimplePrice[] = []
  rangeValue = 1
  activePeriod = PlanPeriodsEnum.Annual

  activeCurrentPlan = false

  monthlyPrice: SimplePrice | null = null
  annualPrice: SimplePrice | null = null

  get isPayAsYouGo() {
    return (
      this.oldMigrationSubscription?.price?.planTitle === payGoLite &&
      !this.memberRegistration?.selectedPriceId
    )
  }

  get isOldPayAsYouGo() {
    return this.oldMigrationSubscription?.price?.planTitle === payGoLite
  }

  get savedMoney() {
    return (
      (this.monthlyPrice?.priceValue || 0) * 12 -
      (this.annualPrice?.priceValue || 0)
    )
  }

  get withExpiredCredits() {
    return !!this.memberRegistration?.oldCreditsForPurchase
  }

  get planName() {
    return this.selectedSimplePrice?.planName
  }

  get planTitle() {
    if (this.selectedSimplePrice?.planLine === 'Migrated') {
      return this.selectedSimplePrice?.planTitle
    }
    return simplePriceToTitle(this.selectedSimplePrice)
  }

  get saveCredits() {
    return this.memberRegistration?.saveCredits
  }

  get totalPriceValue() {
    if (this.saveCredits) {
      return this.bonusPriceValue * 12
    }
    return this.selectedSimplePrice?.priceValue || 0
  }

  get selectedSimplePrice(): SimplePrice | undefined | null {
    if (this.migrationStep === 5) {
      if (this.saveCredits) {
        return this.annualPrice
      }
      if (this.isPayAsYouGo) {
        return this.monthlyPrice
      }
      return this.activePeriod === PlanPeriodsEnum.Annual
        ? this.annualPrice
        : this.monthlyPrice
    }
    return this.proPricesForPeriod[this.rangeValue]
  }

  get annualSimplePrice(): SimplePrice | undefined | null {
    if (this.migrationStep === 5) {
      return this.annualPrice
    }
    return this.annualProPrices[this.rangeValue]
  }

  get priceValue() {
    return (
      (this.activePeriod === PlanPeriodsEnum.Monthly
        ? this.selectedSimplePrice?.priceValue
        : (this.selectedSimplePrice?.priceValue || 0) / 12) || 0
    )
  }

  get bonusPriceValue() {
    return (
      ((this.annualSimplePrice?.priceValue || 0) +
        this.oldCreditsForPurchase * 0.02) /
      12
    )
  }

  get bonusPriceValueThrough() {
    return (
      ((this.annualSimplePrice?.priceValue || 0) +
        this.oldCreditsForPurchase * 0.06) /
      12
    )
  }

  get migrationStep() {
    return this.memberRegistration?.currentStep
  }

  get proPricesForPeriod(): SimplePrice[] {
    return this.prices
      .filter((price) => price.period === this.activePeriod)
      .sort((a, b) => Number(a.creditsIncluded) - Number(b.creditsIncluded))
  }

  get annualProPrices(): SimplePrice[] {
    return this.prices
      .filter((price) => price.period === PlanPeriodsEnum.Annual)
      .sort((a, b) => Number(a.creditsIncluded) - Number(b.creditsIncluded))
  }

  get oldMigrationSubscription() {
    return this?.memberRegistration?.oldSubscription
  }

  get fromMigration() {
    return !!this.migrationStep
  }

  get additionalCreditPrice() {
    return (this.selectedSimplePrice?.additionalCreditPrice || 0) * 100
  }

  get annualAdditionalCreditPrice() {
    return (this.annualSimplePrice?.additionalCreditPrice || 0) * 100
  }

  get creditsIncluded() {
    return this.selectedSimplePrice?.creditsIncluded || 0
  }

  get creditsMigrated() {
    return this.oldCreditsForPurchase + this.oldCreditsFree
  }

  get oldCreditsForPurchase() {
    return this.memberRegistration?.oldCreditsForPurchase || 0
  }

  get oldCreditsFree() {
    return this.memberRegistration?.oldCreditsFree || 0
  }

  get bonusCredits() {
    if (this.withExpiredCredits) {
      return 0
    }
    return this.selectedSimplePrice?.bonusValue || 0
  }

  get billedPercent() {
    return Math.round(
      (1 - this.bonusPriceValue / this.bonusPriceValueThrough) * 100
    )
  }

  get allBonusCredits() {
    return (
      (this.annualSimplePrice?.creditsIncluded || 0) +
      this.oldCreditsFree +
      this.oldCreditsForPurchase
    )
  }

  get bonusAdditionalCreditPrice() {
    return ((this.bonusPriceValue * 12) / this.allBonusCredits) * 100
  }

  setMemberRegistration(memberRegistration: Member['memberRegistration']) {
    this.memberRegistration = memberRegistration
  }

  setPrices = (prices: SimplePrice[]) => {
    this.prices = prices
  }
  setSelectedRangeValue = (val: number) => {
    this.activeCurrentPlan = false
    this.rangeValue = val
  }
  setActivePeriod = (period: PlanPeriodsEnum) => {
    this.activePeriod = period
  }

  setActiveCurrentPlan = (value: boolean) => {
    this.activeCurrentPlan = value
  }

  setPaymentRegistrationSelectPricesQuery = (
    data: PaymentRegistrationSelectPricesQuery
  ) => {
    this.annualPrice =
      data?.paymentRegistrationSelectPrices?.annualPrice || null
    this.monthlyPrice =
      data?.paymentRegistrationSelectPrices?.monthlyPrice || null
  }
  setOpenConfirmKeepCurrentMigrationPlanModal = (value: boolean) => {
    this.openConfirmKeepCurrentMigrationPlanModal = value
  }
}

export default new MigrationStore()
