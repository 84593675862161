import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { ISegment } from 'src/types/ISegment'
import segmentStore from 'store/contacts/segment/segmentStore'

export const SegmentDeleteBtn = (props: ActionBtnProps<ISegment>) => {
  const { row, rows } = props
  const { deleteSegments } = segmentStore
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.delete}
      onAction={() => {
        if (row?.id) {
          return deleteSegments([+row.id], props.onSuccessAction)
        }
        if (rows) {
          return deleteSegments(rows.map((row) => +row.id))
        }
      }}
    />
  )
}
