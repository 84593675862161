import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { InputText } from 'components/Input/InputText/InputText'
import contactStore from 'store/contacts/contactStore'
import { HeaderContentList } from './HeaderContentList'
import { useState } from 'react'
import { useListsFunctions } from 'src/routes/main/contacts/contctLists/hooks/useListsFunctions'
import { observer } from 'mobx-react-lite'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'
import { RoutesEnum } from 'src/routes/routes'
import { IContactList } from 'src/types/IContactList'

export const HeaderContent = observer(() => {
  const navigate = useNavigate()
  const params = useParams()
  const isNew = params.id === 'new'
  const [title, setTitle] = useState('')
  const [createListError, setCreateListError] = useState('')
  const { onCreate } = useListsFunctions()
  if (!params.id) return <></>
  const currentList: IContactList | undefined =
    contactStore.contactListsMap.get(params.id)

  if (!isNew && !currentList) {
    return <></>
  }

  return (
    <div>
      <BreadCrumbs />
      <div className={styles.content}>
        <Button
          hoverGray
          size={BtnSize.small}
          onClick={() => navigate(RoutesEnum.contacts + '/' + RoutesEnum.lists)}
        >
          <Angle />
        </Button>

        <div className={styles.row}>
          {isNew ? (
            <InputText
              withoutBorder
              placeholder={'Enter list name'}
              header2
              withoutClear
              value={title}
              onChange={(e) => {
                setCreateListError('')
                setTitle(e.target.value)
              }}
              //eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              error={
                createListError
                  ? 'List with this name already exists'
                  : undefined
              }
            />
          ) : (
            <HeaderContentList />
          )}
        </div>
      </div>
      {isNew && (
        <div className={styles.actions}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            disabled={!title || !!createListError}
            onClick={() => onCreate(title, setCreateListError)}
          >
            Create
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  )
})
