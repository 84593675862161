import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useContactFunctions } from 'src/routes/main/contacts/hooks/useContactFunctions'
import { IContactActions } from 'src/routes/main/contacts/functions/getContactActions'

export const ContactsUnsubscribeBtn = (props: IContactActions) => {
  const { onUnsubscribe } = useContactFunctions(props)

  const handleClick = async () => {
    await onUnsubscribe()
    if (props.onCloseTT) {
      props.onCloseTT()
    }
    if (props.onSuccessAction) {
      props.onSuccessAction()
    }
  }

  return (
    <TableBtn
      {...props}
      action={ActionsEnum.unsubscribe}
      onAction={handleClick}
    />
  )
}
