import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import companyStore from 'store/settings/company/companyStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'

export const ActivatePhoneCheckrModal = observer(() => {
  const onDone = async () => {
    companyStore.setActivatePhoneCheckrModal(false)
  }
  const onClose = () => {
    companyStore.setActivatePhoneCheckrModal(false)
  }

  return (
    <Modal
      open={companyStore.activatePhoneCheckrModal}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon />
        <h3>PhoneCheckr is active!</h3>
        <p className={'par2 gray1'}>
          {companyStore.verifyExisting
            ? 'PhoneCheckr is checking contacts’ phone numbers. Be patient, processing time depends on the phone numbers volume.'
            : `PhoneCheckr will lookup new contacts’ phone numbers.`}
        </p>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onDone}
          fullWidth
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
