import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { IAudioItem } from 'src/types/IAudioItem'

export const AudioCopyUrlBtn = (props: ActionBtnProps<IAudioItem>) => {
  const { row } = props
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.copy}
      textBtn={'Copy url'}
      copyContent={row?.url || ''}
    />
  )
}
