import styles from './styles.module.scss'
import { Link, useLocation } from 'react-router-dom'
import { Fragment } from 'react'

export const BreadCrumbs = ({
  paddingLeft,
  paddingTop,
}: {
  paddingLeft?: string | number
  paddingTop?: string | number
}) => {
  const { pathname } = useLocation()
  const splitPath = pathname
    .split('/')
    .filter((_, index, self) => index !== 0 && index !== self.length - 1)
  return (
    <div className={styles.wrap} style={{ paddingLeft, paddingTop }}>
      {splitPath.map((path, index) => (
        <Fragment key={path}>
          <Link to={'/' + splitPath.slice(0, index + 1).join('/')}>
            <span className={'capitalize'}>
              {path === 'webForms' ? 'Web Forms' : path}
            </span>
          </Link>
          {index !== splitPath.length - 1 && <span className={'gray1'}>/</span>}
        </Fragment>
      ))}
    </div>
  )
}
