import { useMemo } from 'react'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import { NoSelectContent } from 'src/routes/main/broadcasts/BroadcastsTable/Filters/NoSelectContent'
import { OneSelectContent } from 'src/routes/main/broadcasts/BroadcastsTable/Filters/OneSelectContent'
import { ManySelectContent } from 'src/routes/main/broadcasts/BroadcastsTable/Filters/ManySelectContent'

export const Filters = observer(() => {
  const content = useMemo(() => {
    if (broadcastsStore.tableStore.checkedRowsIds.length) {
      if (
        broadcastsStore.tableStore.checkedRows.length &&
        broadcastsStore.tableStore.checkedRows.length === 1
      ) {
        return <OneSelectContent />
      }
      return <ManySelectContent />
    }
    return <NoSelectContent />
  }, [broadcastsStore.tableStore.checkedRowsIds])
  return <div className={styles.wrapActions}>{content}</div>
})
