// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y1rYoo3EyxHGEiC3hhdT{margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/MessageTemplate/MessageTemplatesModal/SuggestedTab/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA","sourcesContent":[".wrap{\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Y1rYoo3EyxHGEiC3hhdT`
};
export default ___CSS_LOADER_EXPORT___;
