import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { numberString } from 'src/util/functions'
import { payGoLite } from 'store/settings/billing/hellpers'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import migrationStore from 'store/migration/migrationStore'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'
import { SubscriptionCard } from 'src/Modals/UpgradePlanModal/Step1Upgrade/cards/ui/SubscriptionCard'
import classNames from 'classnames'

export const OldMigrationPlanCard = observer(() => {
  const onKeep = async () => {
    migrationStore.setOpenConfirmKeepCurrentMigrationPlanModal(true)
  }

  if (migrationStore.oldMigrationSubscription?.price?.planTitle === payGoLite) {
    return (
      <SubscriptionCard
        topContent={
          <div className={classNames('col8')}>
            {migrationStore.withExpiredCredits && (
              <span className={'error1'}>Don’t Save My Expired Credits</span>
            )}
            <span className={'mt8'}>
              {migrationStore.oldMigrationSubscription?.price?.planTitle}
            </span>
            <div className={'flex'}>
              <span
                className={classNames(
                  's2',
                  migrationStore.withExpiredCredits ? 'gray1' : 'error1'
                )}
              >
                This plan will soon be discontinued
              </span>
              <InfoTooltip
                title={
                  <div>
                    <span className={'white mt4'}>
                      Pay & Go (Lite) will be discontinued in 30 days
                    </span>
                    <span className={'s2 gray2'}>
                      If you choose to keep this plan, after 30 days, you’ll be
                      upgraded to the Pro Monthly 250, $15/month.
                    </span>
                  </div>
                }
                width={220}
              />
            </div>
          </div>
        }
        centerContent={{
          isBilledAnnually:
            migrationStore.oldMigrationSubscription?.price?.period ===
            PlanPeriodsEnum.Annual,
          price:
            migrationStore.oldMigrationSubscription?.price?.priceValue || 0,
          additionalCreditPrice:
            (migrationStore.oldMigrationSubscription?.price
              ?.additionalCreditPrice || 0) * 100,
          creditsIncluded:
            migrationStore.oldMigrationSubscription?.price?.creditsIncluded ||
            0,
          descTooltip: !!migrationStore.oldCreditsFree && (
            <InfoTooltip
              title={`+ ${numberString({
                val: migrationStore.oldCreditsFree,
              })} credits`}
            />
          ),
          noAdditionalCreditsString: true,
        }}
        bottomContent={
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            fullWidth
            onClick={onKeep}
            disabled={!migrationStore.oldMigrationSubscription}
          >
            Keep my current plan
          </Button>
        }
      />
    )
  }

  return (
    <SubscriptionCard
      topContent={
        <>
          {migrationStore.withExpiredCredits && (
            <span className={'error1'}>Don’t Save My Expired Credits</span>
          )}

          <span className={'mt8'}>
            {migrationStore.oldMigrationSubscription?.price?.planTitle}
          </span>
        </>
      }
      centerContent={{
        isBilledAnnually:
          migrationStore.oldMigrationSubscription?.price?.period ===
          PlanPeriodsEnum.Annual,
        price: migrationStore.oldMigrationSubscription?.price?.priceValue || 0,
        descPeriodString:
          migrationStore.oldMigrationSubscription?.price?.period ===
          PlanPeriodsEnum.Annual
            ? '/ year'
            : '/ mo',
        additionalCreditPrice:
          (migrationStore.oldMigrationSubscription?.price
            ?.additionalCreditPrice || 0) * 100,
        creditsIncluded:
          migrationStore.oldMigrationSubscription?.price?.creditsIncluded || 0,
        // descTooltip: !!migrationStore.oldCreditsFree && (
        //   <InfoTooltip
        //     title={`+ ${numberString({
        //       val: migrationStore.oldCreditsFree,
        //     })} credits`}
        //   />
        // ),
      }}
      bottomContent={
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          fullWidth
          onClick={onKeep}
          disabled={!migrationStore.oldMigrationSubscription}
        >
          Keep my current plan
        </Button>
      }
    />
  )
})
