// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MnuJsVWpouy7zcAj_Vfx{display:flex;column-gap:12px}.ce0s5szTEBbSymV1iTIw{display:flex;column-gap:8px;align-items:center}.ce0s5szTEBbSymV1iTIw svg g path{fill:var(--primary-color-gray-1)}`, "",{"version":3,"sources":["webpack://./src/routes/main/dashboard/Objectives/ActiveObjective/StepInfo/styles.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,cAAA,CACA,kBAAA,CCLI,iCACE,gCDKa","sourcesContent":["@import \"src/styles/mixin\";\n.wrap{\n  display: flex;\n  column-gap: 12px;\n}\n.wrapIcon{\n  display: flex;\n  column-gap: 8px;\n  align-items: center;\n  @include svg-color(var(--primary-color-gray-1))\n}","@mixin svg-color($color){\n  svg {\n    g {\n      path {\n        fill: $color;\n      }\n    }\n\n  }\n}\n@mixin svg-color-rect($color){\n  svg {\n    g {\n      rect {\n        fill: $color;\n      }\n    }\n\n  }\n}\n\n@mixin svg-color-stroke($color){\n  svg{\n    path{\n      stroke: $color;\n    }\n  }\n}\n@mixin svg-color-circle($color){\n  svg{\n    circle{\n      fill: $color;\n    }\n  }\n}\n@mixin svg-color-fill($color){\n  svg{\n    path{\n      fill: $color;\n    }\n  }\n}\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `MnuJsVWpouy7zcAj_Vfx`,
	"wrapIcon": `ce0s5szTEBbSymV1iTIw`
};
export default ___CSS_LOADER_EXPORT___;
