import { useGetIntegrationsQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import integrationsStore from 'store/settings/integrationsStore'
import { useRefetch } from 'src/hooks/useRefetch'

export function useSetIntegrations(skip?: boolean) {
  const { data, loading, refetch } = useGetIntegrationsQuery({ skip })
  useEffect(() => {
    if (data?.getIntegrations) {
      integrationsStore.setAccountIntegrationsData(data.getIntegrations)
    }
  }, [data])
  useRefetch(refetch)
  return { loading }
}
