import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import contactStore from 'store/contacts/contactStore'
import { useState } from 'react'
import { InputText } from 'components/Input/InputText/InputText'
import { useListsFunctions } from 'src/routes/main/contacts/contctLists/hooks/useListsFunctions'
import { IContactList } from 'src/types/IContactList'
import { MoreBtn } from 'components/Button/MoreBtn'
import { RenameBtnList } from 'src/routes/main/contacts/contctLists/DetailList/components/RenameBtnList'
import { DeleteBtnList } from 'src/routes/main/contacts/contctLists/DetailList/components/DeleteBtnList'
import { ListExportBtn } from 'src/routes/main/contacts/contctLists/components/ListExportBtn'

export const HeaderContentList = observer(() => {
  const { onUpdate } = useListsFunctions()
  const [edit, setEdit] = useState(false)

  const params = useParams()
  if (!params.id) return <></>
  const currentList: IContactList | undefined =
    contactStore.contactListsMap.get(params.id)

  if (!currentList) {
    return <></>
  }
  const [title, setTitle] = useState(currentList?.name)

  const onBlur = () => {
    onUpdate(currentList.id, title)
    setEdit(false)
  }
  return (
    <div className={styles.row}>
      {edit ? (
        <InputText
          header2
          withoutBorder
          withoutClear
          focusOnMount
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={onBlur}
        />
      ) : (
        <h1>{currentList.name}</h1>
      )}

      <MoreBtn
        menuItems={[
          (props) => RenameBtnList({ ...props, setEdit }),
          (props) => ListExportBtn({ ...props, row: currentList }),
          DeleteBtnList,
        ]}
        medium
      />
    </div>
  )
})
