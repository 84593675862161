// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hAD1MN4EeN0Ux0XdYLZv{margin-right:32px}.oaYev2FUD_VZmQnB9gOw .Z4xkUz7lqr76WSRRSs3K{font-size:14px;line-height:16px;font-weight:bold;font-family:Inter-SemiBold;border-bottom-right-radius:0;border-bottom-left-radius:0;border-color:var(--primary-color-gray-3) !important;outline:none !important}.oaYev2FUD_VZmQnB9gOw .Z4xkUz7lqr76WSRRSs3K:hover{color:var(--primary-color-black)}.G9wJkTiIQVAEbedYUy6F{margin-top:32px}.oOWLAXAIyj3TxznVELq_{display:flex;column-gap:12px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/widgets/MessageTemplate/MessageTemplatesModal/MyTemplatesTab/NewTemplate/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGA,4CACE,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,0BAAA,CACA,4BAAA,CACA,2BAAA,CACA,mDAAA,CACA,uBAAA,CACA,kDACE,gCAAA,CAIN,sBACE,eAAA,CAEF,sBACE,YAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".wrap{\n  margin-right: 32px;\n}\n.inputWrap{\n  .input{\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: bold;\n    font-family: Inter-SemiBold;\n    border-bottom-right-radius: 0;\n    border-bottom-left-radius: 0;\n    border-color: var(--primary-color-gray-3)!important;\n    outline: none!important;\n    &:hover{\n      color: var(--primary-color-black);\n    }\n  }\n}\n.error{\n  margin-top: 32px;\n}\n.actions{\n  display: flex;\n  column-gap: 12px;\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `hAD1MN4EeN0Ux0XdYLZv`,
	"inputWrap": `oaYev2FUD_VZmQnB9gOw`,
	"input": `Z4xkUz7lqr76WSRRSs3K`,
	"error": `G9wJkTiIQVAEbedYUy6F`,
	"actions": `oOWLAXAIyj3TxznVELq_`
};
export default ___CSS_LOADER_EXPORT___;
