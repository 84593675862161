import { TablePage } from 'components/Page/TablePage'
import { observer } from 'mobx-react-lite'
import { CampaignsTable } from 'src/routes/main/campaigns/CampaignsTable/CampaignsTable'

export const Campaigns = observer(() => {
  return (
    <TablePage>
      <h1>Campaigns</h1>
      <CampaignsTable />
    </TablePage>
  )
})
