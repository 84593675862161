import { BroadcastsTable } from 'src/routes/main/broadcasts/BroadcastsTable/BroadcastsTable'
import { TablePage } from 'components/Page/TablePage'

export const Broadcasts = () => {
  return (
    <TablePage>
      <h1>Broadcasts</h1>
      <BroadcastsTable />
    </TablePage>
  )
}
