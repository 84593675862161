import styles from './styles.module.scss'
import { SizedBox } from 'components/SizedBox'
import classNames from 'classnames'

export const PayGoLimitsContent = ({
  formBilling,
}: {
  formBilling?: boolean
}) => (
  <>
    <span className={classNames('bold', !formBilling && 'mt8')}>Limits</span>
    <SizedBox height={formBilling ? 20 : 16} />
    <div
      className={classNames(
        styles.wrapLimits,
        formBilling && styles.formBilling
      )}
    >
      <li>Contacts: 1,000</li>
      <li>Users: 1</li>
      <li>Integrations: 1</li>
      <li>Broadcasts: 3</li>
      <li>Campaigns: 3</li>
      <li>Triggers: 3</li>
      <li>Keywords: 1</li>
      <li>Web Forms: 1</li>
      <li>Automations: 1</li>
    </div>
  </>
)
