import { Navigate, Route, Routes } from 'react-router-dom'
import { AppLayout } from 'src/layout/AppLayout'
import { SettingsWrap } from 'src/routes/settings/settings'
import { RoutesEnum, UnauthorizedRoutes } from 'src/routes/routes'
import { Account } from 'src/routes/settings/account/account'
import { Contacts } from 'src/routes/main/contacts/contacts'
import { ContactsTablePage } from 'src/routes/main/contacts/ContactsTablePage'
import { ListsTable } from 'src/routes/main/contacts/contctLists/ListssTable/ListsTable'
import { SegmentsTable } from 'src/routes/main/contacts/segments/SegmentsTable/SegmentsTable'
import { MyProfile } from 'src/routes/settings/account/routes/MyProfile/MyProfile'
import { renderRoute } from 'src/util/route'
import {
  accountRoutes,
  adminRoutes,
  companyRoutes,
  mainRoutes,
  settingRoutes,
} from 'components/App/AuthorizedApp/routes'
import user from 'store/user/user'
import { Company } from 'src/routes/settings/company/Company'
import { CompanyProfile } from 'src/routes/settings/company/routes/CompanyProfile/CompanyProfile'
import { BusinessProfile } from 'src/routes/settings/compliance/BusinessProfile/BusinessProfile'
import { TollFreeVerification } from 'src/routes/settings/compliance/TollFreeVerification/TollFreeVerification'
import { CreateMessages } from 'src/routes/main/createMessages/createMessages'
import { A2P10DLCRegistration } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/A2P10DLCRegistration'
import { OAuthLogin } from 'src/routes/auth/login/OAuthLogin'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { NewOrganization } from 'src/routes/main/newOrganization/NewOrganization'
import { InviteExistAuth } from 'src/routes/invite/InviteExistAuth'
import mainStore from 'store/mainStore'

export const MainRoutes = () => {
  return (
    <Routes>
      <Route path={RoutesEnum.inviteExist} element={<InviteExistAuth />} />
      <Route path={UnauthorizedRoutes.login2} element={<OAuthLogin />} />
      <Route element={<AuthPage />}>
        <Route
          path={RoutesEnum.newOrganization}
          element={<NewOrganization />}
        />
      </Route>

      <Route element={<AppLayout />}>
        {user.isAdmin && (
          <Route path={RoutesEnum.admin + '/*'}>
            {adminRoutes.map(renderRoute)}
          </Route>
        )}
        <Route
          path={`${RoutesEnum.settingsCompliance}/${RoutesEnum.business}`}
          element={<BusinessProfile />}
        />
        <Route
          path={`${RoutesEnum.settingsCompliance}/${RoutesEnum.A2P10DLCRegistration}`}
          element={<A2P10DLCRegistration />}
        />
        <Route
          path={`${RoutesEnum.settingsCompliance}/${RoutesEnum.tollFreeVerification}`}
          element={<TollFreeVerification />}
        />
        <Route path={RoutesEnum.settings + '/*'} element={<SettingsWrap />}>
          <Route path={`${RoutesEnum.account}/*`} element={<Account />}>
            <Route index element={<MyProfile />} />
            {accountRoutes.map(renderRoute)}
          </Route>
          <Route path={`${RoutesEnum.company}/*`} element={<Company />}>
            <Route index element={<CompanyProfile />} />
            {companyRoutes.map(renderRoute)}
          </Route>
          {(user.isOwner
            ? settingRoutes
            : settingRoutes.filter((route) => route.path !== RoutesEnum.billing)
          ).map(renderRoute)}
        </Route>
        {mainRoutes.map(renderRoute)}
        <Route path={RoutesEnum.contacts} element={<Contacts />}>
          <Route index element={<ContactsTablePage />} />
          <Route path={RoutesEnum.lists} element={<ListsTable />} />
          <Route path={RoutesEnum.segments} element={<SegmentsTable />} />
        </Route>

        <Route path={'/'} element={<CreateMessages />} />
        <Route
          path={'/*'}
          element={<Navigate to={mainStore.redirectPathAfterLogin} />}
        />
      </Route>
    </Routes>
  )
}
