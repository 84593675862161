import styles from 'src/routes/auth/styles.module.scss'

export const FooterRegister = ({ text }: { text?: string }) => (
  <p className={styles.footerText}>
    {text || (
      <>
        After your 15-day free trial is over, you will be upgraded to the
        <br />
        Pro 500 plan ($25/mo).
      </>
    )}
  </p>
)
