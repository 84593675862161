import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Switch } from 'components/Switch/Switch'
import companyStore from 'store/settings/company/companyStore'
import classNames from 'classnames'

export const PhoneCheckrCard = observer(
  ({ fromModal }: { fromModal?: boolean }) => {
    const onChange = async () => {
      if (companyStore.phoneCheckerEnabled) {
        companyStore.setTurnOffPhoneCheckerModal(true)
      } else {
        companyStore.setPhoneCheckerModal({ state: true })
      }
    }
    return (
      <>
        <div className={classNames(styles.wrap, fromModal && styles.fromModal)}>
          <div className={'col4'}>
            <span className={'bold'}>PhoneCheckr</span>
            <span className={'s2 gray1'}>
              {fromModal
                ? 'Turning OFF will increase the risk of sending messages to invalid numbers, thus losing credits and getting blocked by carriers.'
                : 'Validate and verify your contacts’ phone numbers (0.25 credit per lookup).'}
            </span>
          </div>
          <div className={'row8'}>
            {!fromModal && (
              <span>
                {companyStore.phoneCheckerEnabled ? 'Enabled' : 'Disabled'}
              </span>
            )}
            <Switch
              active={companyStore.phoneCheckerEnabled}
              onChange={onChange}
            />
          </div>
        </div>
      </>
    )
  }
)
