import contactStore from 'store/contacts/contactStore'
import { useGetListContactsQuery } from 'src/generated/graphql'
import styles from './styles.module.scss'
import { ContactsTable } from 'src/routes/main/contacts/ContactsTable/ContactsTable'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useSetTotalContactsCount } from 'src/routes/main/contacts/hooks/useSetTotalContactsCount'
import { useSetData } from 'src/hooks/useSetData'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { useEffect } from 'react'

export const WrapContactsTable = observer(() => {
  useSetTotalContactsCount(false)
  const { id } = useParams()

  const options = useTablePaginationOptions(contactStore.tableStoreContacts)

  useEffect(() => {
    if (id) {
      contactStore.setListPageId(+id)
    }
    return () => {
      contactStore.setListPageId(null)
    }
  }, [id])

  const queryResult = useGetListContactsQuery({
    variables: {
      page: options.variables.page,
      listId: Number(id),
      contactSearch: {
        status: options.variables.status,
        searchPattern: options.variables.searchPattern,
      },
    },
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => contactStore.setDataListContacts(data),
    refreshDeps: [contactStore.tableStoreContacts.refreshTrigger],
  })

  return (
    <div className={styles.tableWrap}>
      <ContactsTable
        withSearch
        pageLoading={loading || refetchLoading}
        firstLoading={firstLoading}
        tableStore={contactStore.tableStoreContacts}
      />
    </div>
  )
})
