import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { Counter } from 'components/Counter/Counter'
import {
  operatorsOptions,
  periodsOptions,
} from 'components/Schedule/store/mockData'
import { CampaignScheduleStore } from 'components/Schedule/store/campaignScheduleStore'
import classNames from 'classnames'
import { AddCustomField } from 'src/routes/main/contacts/customFields/modals/CustomFieldsModal/AddCustomField'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { CampaignScheduleType, CustomFieldType } from 'src/generated/graphql'
import { TimePicker } from 'components/TimePicker/TimePicker'
import dayjs from 'lib/dayjs'
import { useGetCustomFields } from 'src/routes/main/contacts/customFields/hooks/useGetCustomFields'

export const ContactDateFieldContent: FC<{
  scheduleStore: CampaignScheduleStore
}> = observer(({ scheduleStore }) => {
  useGetCustomFields(
    !!customFieldsStore.customFieldsOptions.length ||
      !scheduleStore.activeContactDateField
  )
  useEffect(() => {
    if (
      scheduleStore.contactDateField?.data?.type === CustomFieldType.Date &&
      !scheduleStore.contactDateFieldDate
    ) {
      scheduleStore.setContactDateFieldDate(
        dayjs().startOf('day').add(12, 'hours').toDate()
      )
    }
  }, [
    scheduleStore.activeContactDateField,
    scheduleStore.contactDateField?.data?.type,
  ])
  if (!scheduleStore.activeContactDateField) {
    return <></>
  }
  return (
    <div className={classNames('col16', styles.wrap)}>
      {scheduleStore.contactDateFieldOperator.value !==
        CampaignScheduleType.On && (
        <div className={styles.counter}>
          <Counter
            count={scheduleStore.contactDateFieldCount}
            onMinus={() =>
              scheduleStore.setContactDateFieldCount(
                +scheduleStore.contactDateFieldCount - 1
              )
            }
            onPlus={() =>
              scheduleStore.setContactDateFieldCount(
                +scheduleStore.contactDateFieldCount + 1
              )
            }
            onChange={scheduleStore.setContactDateFieldCount}
          />
          <Dropdown
            options={periodsOptions}
            selectedOption={scheduleStore.contactDateFieldPeriod}
            onSelect={(opt) => scheduleStore.setContactDateFieldPeriod(opt)}
            parentElement={scheduleStore.refModalContent || undefined}
          />
        </div>
      )}
      <Dropdown
        options={operatorsOptions}
        selectedOption={scheduleStore.contactDateFieldOperator}
        onSelect={scheduleStore.setContactDateFieldOperator}
        parentElement={scheduleStore.refModalContent || undefined}
      />
      <Dropdown
        options={customFieldsStore.customFieldsTimeOptions}
        placeholder={'Select filed'}
        selectedOption={scheduleStore.contactDateField}
        onSelect={scheduleStore.setContactDateFieldId}
        parentElement={scheduleStore.refModalContent || undefined}
        BottomDropdownButton={(props) => AddCustomField({ ...props })}
      />
      {scheduleStore.contactDateField?.data?.type === CustomFieldType.Date && (
        <TimePicker
          startTime={scheduleStore.contactDateFieldDate}
          onChange={(time) => scheduleStore.setContactDateFieldDate(time)}
        />
      )}
    </div>
  )
})
