import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import styles from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/styles.module.scss'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import {
  TwPhoneNumber,
  useGetAvailablePhoneNumbersQuery,
} from 'src/generated/graphql'
import { useEffect, useState } from 'react'

export const NumbersContent = observer(() => {
  const [refetchLoading, setRefetchLoading] = useState(false)

  const {
    data,
    refetch,
    loading: loadingData,
  } = useGetAvailablePhoneNumbersQuery({
    variables: addNumberStore.getAvailablePhoneNumbersQueryVariables,
  })

  useEffect(() => {
    addNumberStore.setLoadingData(loadingData)
    if (loadingData) {
      addNumberStore.setChosenNumber(null)
    }
  }, [loadingData])

  useEffect(() => {
    if (data) {
      if (data?.getAvailablePhoneNumbers?.availablePhoneNumbers) {
        const newNumbers: TwPhoneNumber[] = []
        data.getAvailablePhoneNumbers?.availablePhoneNumbers.forEach(
          (phone) => {
            if (phone?.phoneNumber && phone?.friendlyName) {
              newNumbers.push(phone)
            }
          }
        )
        const phones = newNumbers.map((number) => number.phoneNumber)
        if (!phones.includes(addNumberStore.chosenNumber?.phoneNumber)) {
          addNumberStore.setChosenNumber(newNumbers[0])
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (addNumberStore.refreshTrigger) {
      setRefetchLoading(true)
      refetch().finally(() => setRefetchLoading(false))
    }
  }, [addNumberStore.refreshTrigger])
  const handleCheck = (number: TwPhoneNumber) => {
    addNumberStore.setChosenNumber(number)
  }
  return (
    <div
      className={classNames(
        styles.numbers,
        !addNumberStore.isLocalTab && styles.tollFree
      )}
    >
      {(loadingData || refetchLoading) && (
        <div className={styles.loading}>
          <AbsoluteLoader />
        </div>
      )}
      {!(loadingData || refetchLoading) &&
        data?.getAvailablePhoneNumbers?.availablePhoneNumbers?.map(
          (number) =>
            number && (
              <InputCheckbox
                radio
                key={number.phoneNumber}
                label={
                  <div className={'flex-space-between'}>
                    {number.friendlyName || ''}
                    {addNumberStore.isLocalTab && (
                      <span className={'s2 gray1'}>
                        {[number.locality, number.region]
                          .filter((value) => value)
                          .join(', ')}
                      </span>
                    )}
                  </div>
                }
                onChecked={() => handleCheck(number)}
                checked={
                  number.phoneNumber ===
                  addNumberStore.chosenNumber?.phoneNumber
                }
                noBordered2
                fullWidthLabel
              />
            )
        )}
      {!data?.getAvailablePhoneNumbers?.availablePhoneNumbers?.length &&
        !loadingData && (
          <div className={styles.empty}>
            <span className={'gray1'}>No numbers found</span>
          </div>
        )}
    </div>
  )
})
