import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { ClassesEnum } from 'styles/classes'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { SmallBtn } from 'components/Button/SmallBtn'
import { ActionsEnum } from 'components/Button/types'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CampaignMessageStore } from 'store/campaigns/campaignMessageStore'
import uiStore from 'store/uiStore'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import createCampaignStore from 'store/campaigns/createCampaignStore'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'

export const SettingMessageCampaign = observer(
  ({ createStore }: { createStore: CampaignMessageStore }) => {
    const [isTest, setIsTest] = useState(false)
    const { showTT, setShowTT, trigger, setTrigger, onTriggerClick } =
      useFixedTooltip()
    const onCloseTooltip = () => {
      setShowTT(false)
    }

    const onTest = () => {
      uiStore.setTriggerUpdate()
      setIsTest(true)
    }

    useEffect(() => {
      setIsTest(false)
    }, [showTT])

    const onDuplicate = () => {
      onCloseTooltip()
      createCampaignStore.duplicateMessage(createStore)
    }
    return (
      <>
        <FixedTooltip
          visible={showTT}
          trigger={trigger}
          width={isTest ? 236 : 148}
          white
          noArrow
          position={TTPositionEnum.bottomRight}
          globalClasses={[isTest ? ClassesEnum.p16 : ClassesEnum.p8]}
          addLeftPosition={8}
        >
          {isTest ? (
            <TestItBtn
              onSuccess={onCloseTooltip}
              staticContent
              type={createStore.type}
              testMessageInput={{
                fromNumberId: createStore.smsPhoneFull?.id,
                smsMessage: createStore.textareaStore.smsMessageRequestInput,
                toNumber: '',
              }}
              testCallMessageRequestInput={{
                fromNumberId: createStore.voicePhoneFull?.id,
                voiceMessage: createStore.voiceStore.voiceMessage,
                toNumber: '',
              }}
              testRinglessMessageRequestInput={{
                fromNumberId: createStore.voicePhoneFull?.id,
                audioId: createStore.ringlessStore.ringlessMessage.audioId,
                toNumber: '',
              }}
            />
          ) : (
            <>
              <MenuBtn type={MenuEnum.testMessage} onClick={onTest} />
              <MenuBtn type={MenuEnum.duplicate} onClick={onDuplicate} />
              <MenuBtn
                type={MenuEnum.delete}
                onClick={() =>
                  createCampaignStore.deleteMessage(createStore.id)
                }
              />
            </>
          )}
        </FixedTooltip>
        <div ref={setTrigger}>
          <SmallBtn
            type={ActionsEnum.more}
            tooltip={'Setting'}
            onClick={onTriggerClick}
            active={showTT}
          />
        </div>
      </>
    )
  }
)
