// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CjHlzOQyBjTUNqAQikFC{padding:0 32px 32px 32px;width:576px}.EwMJBOGR0bcpcSQYJvnl{display:flex;margin-top:40px;column-gap:12px}.hLkTQV9EsEabybf2bwoL{display:flex;justify-content:space-between}.DIjYOYT9KBt_RnVnmqrf{width:100%}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/modals/BuyCreditsModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,WAAA,CAEF,sBACE,YAAA,CACA,eAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CAEF,sBACE,UAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n  width: 576px;\n}\n.actions{\n  display: flex;\n  margin-top: 40px;\n  column-gap: 12px;\n}\n.row{\n  display: flex;\n  justify-content: space-between;\n}\n.dropdown{\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `CjHlzOQyBjTUNqAQikFC`,
	"actions": `EwMJBOGR0bcpcSQYJvnl`,
	"row": `hLkTQV9EsEabybf2bwoL`,
	"dropdown": `DIjYOYT9KBt_RnVnmqrf`
};
export default ___CSS_LOADER_EXPORT___;
