// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pe1in2HOaC3I_TzCe29L{display:flex;flex-direction:column;align-items:center;align-content:center;justify-content:center;margin-bottom:140px;margin-right:64px;height:calc(100vh - 140px - 140px)}.pe1in2HOaC3I_TzCe29L.XaRykWYanba4E8RcLqRJ{height:calc(100vh - 198px - 198px)}@media(max-width: 800px){.pe1in2HOaC3I_TzCe29L{margin-right:0;height:100%}}@media(max-height: 600px){.pe1in2HOaC3I_TzCe29L{margin-right:0;height:100%}}.NHzYC4wvJeqVMHFS5F5A{display:flex;flex-direction:column;align-items:center;justify-content:center;row-gap:20px;margin-top:160px;color:var(--primary-color-gray-1);text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/EmptyState/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,oBAAA,CACA,sBAAA,CACA,mBAAA,CACA,iBAAA,CACA,kCAAA,CACA,2CACE,kCAAA,CAEF,yBAZF,sBAaI,cAAA,CACA,WAAA,CAAA,CAEF,0BAhBF,sBAiBI,cAAA,CACA,WAAA,CAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,gBAAA,CACA,iCAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  margin-bottom: 140px;\n  margin-right: 64px;\n  height: calc(100vh - 140px - 140px);\n  &.heightV2{\n    height: calc(100vh - 198px - 198px);\n  }\n  @media (max-width: 800px) {\n    margin-right: 0;\n    height: 100%;\n  }\n  @media (max-height: 600px) {\n    margin-right: 0;\n    height: 100%;\n  }\n}\n\n.wrapStatistics{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  row-gap: 20px;\n  margin-top: 160px;\n  color: var(--primary-color-gray-1);\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `pe1in2HOaC3I_TzCe29L`,
	"heightV2": `XaRykWYanba4E8RcLqRJ`,
	"wrapStatistics": `NHzYC4wvJeqVMHFS5F5A`
};
export default ___CSS_LOADER_EXPORT___;
