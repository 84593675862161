import { observer } from 'mobx-react-lite'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Dial } from 'icons/16pxNoMask/Dial.svg'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { InputText } from 'components/Input/InputText/InputText'

export const TollNumbersFilters = observer(() => (
  <InputText
    leftIcon={
      <AppIcon color={ColorsNames.gray1}>
        <Dial />
      </AppIcon>
    }
    placeholder={'Filter by digits'}
    value={addNumberStore.tollFreeDigits}
    onChangeValue={(value) =>
      addNumberStore.setTollFreeDigits(String(parseInt(value) || ''))
    }
  />
))
