import { AllowImportStatus, SimplePrice } from 'src/generated/graphql'
import { ISimplePrice } from 'src/types/ISimplePrice'

export const allowImportStatusToText = {
  [AllowImportStatus.Approved]: 'Approved',
  [AllowImportStatus.NeedsReview]: 'Needs Review',
  [AllowImportStatus.Rejected]: 'Rejected',
}

export const transformSimplePriceToISimplePrice = (
  price: SimplePrice
): ISimplePrice => ({ id: price.priceCatalogId, ...price })
