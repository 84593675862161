import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { AccountIntegrationType } from 'src/generated/graphql'

export const IntegrationsOAuth = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const { type } = useParams()
  let oAuthType: AccountIntegrationType | undefined
  if (type === 'keap') {
    oAuthType = AccountIntegrationType.Keap
  }
  if (type === 'hubspot') {
    oAuthType = AccountIntegrationType.HubSpot
  }
  useEffect(() => {
    if (oAuthType) {
      const codeString = JSON.stringify({
        code,
        oAuthType,
      })
      localStorage.setItem('OAuthCode', codeString)
      window.close()
    }
  }, [code, oAuthType])
  return <div />
}
