import { makeAutoObservable, runInAction } from 'mobx'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { IAlert } from 'src/types/IAlert'
import billingStore from 'store/settings/billing/billingStore'

class AlertStore {
  constructor() {
    makeAutoObservable(this)
  }
  alert: IAlert | null = null
  errorAlert: IAlert | null = null
  downgradeAlert: IAlert | null = null
  importContactAlert: IAlert | null = null
  cancelSubscriptionAlert: IAlert | null = null
  timeoutId: NodeJS.Timeout | undefined
  isHideDowngrade = JSON.parse(localStorage.getItem('hideDowngrade') || 'false')
  disabledErrorMap: Map<string, number> = new Map()

  setDowngradeAlert(alert: IAlert | null) {
    if (this.isHideDowngrade && billingStore.isPayGoLite) {
      return
    }
    this.downgradeAlert = alert
    this.isHideDowngrade = false
  }
  setCancelSubscriptionAlert(alert: IAlert | null) {
    this.cancelSubscriptionAlert = alert
  }
  hideDowngradeAlert() {
    localStorage.setItem('hideDowngrade', 'true')
    this.downgradeAlert = null
    this.isHideDowngrade = true
  }

  setAlert(alert: IAlert | null, withoutClose = false) {
    this.timeoutId && clearTimeout(this.timeoutId)
    if (alert?.type === AlertTypeEnum.error) {
      this.timeoutId = setTimeout(() => {
        this.alert = alert
      }, 1000)
    } else {
      this.alert = alert
    }

    if (!withoutClose) {
      this.timeoutId = setTimeout(() => {
        runInAction(() => {
          this.alert = null
        })
      }, alert?.delay || (alert?.type === AlertTypeEnum.error && 10000) || 3000)
    }
  }
  setErrorAlert({
    message,
    title,
    withoutClose,
    delay,
  }: {
    message: string
    title?: string
    withoutClose?: boolean
    delay?: number
  }) {
    setTimeout(() => {
      const timestamp = this.disabledErrorMap.get(message) || 0
      const relative = Date.now() - timestamp
      if (!timestamp || relative > 5000) {
        this.errorAlert = {
          type: AlertTypeEnum.error,
          title: title || message,
        }
      }
      if (!withoutClose) {
        setTimeout(() => {
          runInAction(() => {
            this.alert = null
          })
        }, delay || 10000)
      }
    }, 200)
  }
  closeErrorAlert() {
    this.errorAlert = null
  }
  setSuccessAlert(title: string) {
    this.setAlert({
      type: AlertTypeEnum.success,
      title,
    })
  }
  disabledAlertForImportId: string[] = []
  loadingContactImportIds: string[] = []
  setImportContactAlert(alert: IAlert | null, id = '') {
    if (!this.loadingContactImportIds.includes(id) && alert) {
      this.loadingContactImportIds.push(id)
    }
    if (
      !this.disabledAlertForImportId.includes(id) ||
      alert?.type !== AlertTypeEnum.blue3 ||
      !alert
    ) {
      this.importContactAlert = alert
    }
    if (!alert && id) {
      this.disabledAlertForImportId.push(id)
    }
  }
  disabledErrorAlert(message: string) {
    this.disabledErrorMap.set(message, Date.now())
  }
}

export default new AlertStore()
