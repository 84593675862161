import styles from './styles.module.scss'
import { ReactComponent as Pointer } from 'icons/Bottom pointer.svg'
import { FC, useMemo } from 'react'
import { Button } from 'components/Button/Button'
import { compactFormatter } from 'src/util/functions'
import migrationStore from 'store/migration/migrationStore'

export type RangeInputProps = {
  rangeValues: number[]
  step?: number
  min?: number
  max: number
  value: number
  onChangeValue: (val: number) => void
  disabled?: boolean
  hideSteps?: boolean
}

export const RangeInput: FC<RangeInputProps> = ({
  rangeValues,
  step,
  min,
  max,
  value,
  onChangeValue,
  disabled,
  hideSteps,
}) => {
  const widthRange = useMemo(() => {
    const defaultValue =
      (value / rangeValues.length + 1 / rangeValues.length / 2) * 100 + '%'
    if (migrationStore.fromMigration) {
      return defaultValue
    }
    if (value === 0) {
      return 0
    }
    if (value === max) {
      return 'calc(100% - 12px)'
    }
    return defaultValue
  }, [value, rangeValues, migrationStore.fromMigration])

  const leftThumb = useMemo(() => {
    const defaultValue =
      (value / rangeValues.length + 1 / rangeValues.length / 2) * 100 + '%'
    if (migrationStore.fromMigration) {
      return defaultValue
    }
    if (value === 0) {
      return '12px'
    }
    if (value === max) {
      return 'calc(100% - 12px)'
    }
    return defaultValue
  }, [value, rangeValues, migrationStore.fromMigration])
  return (
    <div className={styles.wrap}>
      <input
        type="range"
        step={step}
        min={min}
        max={max}
        value={disabled ? 0 : value}
        onChange={(e) => {
          onChangeValue(Number(e.target.value))
        }}
      />
      <div className={styles.inputWrap}>
        {!disabled && (
          <div className={styles.progressBar} style={{ width: widthRange }} />
        )}
        {!disabled && (
          <div className={styles.thumbWrap} style={{ left: leftThumb }}>
            <div className={styles.thumb}>
              <div />
              <div />
              <div />
            </div>
            <div className={styles.ttWrap}>
              <div className={styles.arrow}>
                <Pointer />
              </div>
              <span className={'s2 bold white'}>
                {compactFormatter.format(rangeValues[value])}
              </span>
            </div>
          </div>
        )}

        <div
          className={styles.steps}
          style={{ gridTemplateColumns: `repeat(${rangeValues.length}, 1fr)` }}
        >
          {[...rangeValues].map((val, index) => (
            <Button
              key={val}
              onClick={() => onChangeValue(index)}
              className={styles.btn}
              // disabled={hideSteps}
            >
              {hideSteps &&
              !(index === 0 || index === rangeValues.length - 1) ? (
                <div className={styles.hideSteps} />
              ) : (
                <span>{compactFormatter.format(val)}</span>
              )}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}
