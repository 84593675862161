import styles from 'src/routes/auth/login/styles.module.scss'
import { Card } from 'components/Card/Card'
import { useState } from 'react'
import { PreviewImg } from 'src/routes/auth/login/PreviewImg'
import uiStore from 'store/uiStore'
import classNames from 'classnames'

export const LoginCard = ({
  logo,
  title,
  info,
  previewSrc,
  previewSrcFull,
  loginBtn,
}: {
  logo: JSX.Element
  title: string
  info: JSX.Element
  previewSrc: string
  previewSrcFull: string
  loginBtn: JSX.Element
}) => {
  const [showPreviewFull, setShowPreviewFull] = useState(false)
  return (
    <>
      {!uiStore.isMobile && (
        <PreviewImg
          previewSrcFull={previewSrcFull}
          showPreviewFull={showPreviewFull}
          setShowPreviewFull={setShowPreviewFull}
        />
      )}
      <Card width={480} margin={0}>
        <div className={styles.cardContent}>
          {logo}
          <h2 className={'bold mt32'}>{title}</h2>
          <p className={'p2 gray1 mt8 text-center'}>{info}</p>
          <img
            src={previewSrc}
            alt="preview"
            className={classNames('mt40 pointer', styles.cardImg)}
            onClick={() => setShowPreviewFull(true)}
            aria-hidden={'true'}
          />
          {loginBtn}
        </div>
      </Card>
    </>
  )
}
