import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import mediaStore from 'store/mediaStore'
import { DragAndDropInput } from 'components/DragnDropInput/DragAndDropInput/DragAndDropInput'
import styles from './styles.module.scss'
import { SIZE_LIMIT_5_MB } from 'src/static/constants'

type Props = {
  setActiveModalTab: (val: number) => void
}

export const UploadContent: FC<Props> = observer(({ setActiveModalTab }) => {
  const successCb = () => {
    setActiveModalTab(1)
  }
  const onAddFiles = (files: File[]) => {
    mediaStore.setUploadImages(files, successCb)
  }
  return (
    <div className={styles.wrap}>
      <DragAndDropInput
        onAddFiles={onAddFiles}
        accept={'image/jpeg, image/png, image/gif, image/vcard'}
        withLink
        subtitle={'png, jpg, gif, vcard - up to 5MB'}
        fileLimitSize={SIZE_LIMIT_5_MB}
        inputLoading={mediaStore.assetLoading}
        onUploadFileURL={(url) => mediaStore.onUploadFileURL(url, successCb)}
      />
    </div>
  )
})
