import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { SimplePrice } from 'src/generated/graphql'
import {
  simplePriceToTitle,
  SubscriptionsTitles,
} from 'store/settings/billing/hellpers'
import billingStore from 'store/settings/billing/billingStore'
import companyStore from 'store/settings/company/companyStore'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'

class UpgradePlanStore {
  billingStore: typeof billingStore
  constructor() {
    makeAutoObservable(this)
    this.billingStore = billingStore
    reaction(
      () => this.modalSimplePrice,
      (price) => {
        if (this.billingStore.isTrial) {
          return false
        }
        if (this.modalSimplePrice?.planName?.includes('Pro')) {
          this.isDowngradeSubscription =
            (price?.creditsIncluded || 0) <
            (this.currentPrice?.creditsIncluded || 0)
        }
      }
    )
    reaction(
      () => this.isPayAsYouGo,
      () => {
        this.isDowngradeSubscription =
          !this.billingStore.isTrial && !this.billingStore.canceledSubscription
      }
    )
    reaction(
      () => this.prices,
      () => {
        runInAction(() => {
          this.billingStore.prices = this.prices
        })
      }
    )
  }

  openUpgradePlanModal = false
  prices: SimplePrice[] = []
  isDowngradeSubscription = false

  modalActiveStep = 0
  modalActivePeriod: PlanPeriodsEnum = PlanPeriodsEnum.Monthly
  modalSelectedRangeValue = 7

  isCustomPlane = false
  isPayAsYouGo = false

  get planNames(): string[] {
    const planNames: string[] = []
    if (this.modalSimplePriceAnnual?.planName) {
      planNames.push(this.modalSimplePriceAnnual.planName)
    }
    if (this.modalSimplePriceMonthly?.planName) {
      planNames.push(this.modalSimplePriceMonthly.planName)
    }
    if (this.planName && !planNames.includes(this.planName)) {
      planNames.push(this.planName)
    }
    return planNames
  }

  get modalStep() {
    return this.modalActiveStep
  }

  get openModal() {
    return this.openUpgradePlanModal
  }

  // get creditPrices() {
  //   const creditPricesFiltered: CreditPrice[] = []
  //   this.modalSimplePrice?.creditPrices?.forEach((price) => {
  //     if (price) {
  //       creditPricesFiltered.push(price)
  //     }
  //   })
  //   return creditPricesFiltered
  // }

  get isCurrent() {
    if (this.isPayAsYouGo) {
      return this.currentPrice?.planName === this.payAsYouGoPrice?.planName
    }
    if (this.isCustomPlane) {
      return false
    }
    return this.currentPrice?.planName === this.modalSimplePrice?.planName
  }

  get planName() {
    if (this.isCustomPlane) {
      return SubscriptionsTitles.custom
    }
    if (this.isPayAsYouGo) {
      return this.payAsYouGoPrice?.planName
    }
    return this.modalSimplePrice?.planName
  }

  get planTitle() {
    if (this.modalSimplePrice?.planLine === 'Migrated') {
      return this.modalSimplePrice?.planTitle
    }
    if (this.isCustomPlane) {
      return SubscriptionsTitles.custom
    }
    return simplePriceToTitle(this.modalSimplePrice)
  }

  get priceValue() {
    if (this.isCustomPlane) {
      return 0
    }
    if (this.isPayAsYouGo) {
      return (this.modalSimplePrice?.priceValue || 0) / 12 || 0
    }
    return (
      (this.modalActivePeriod === PlanPeriodsEnum.Monthly
        ? this.modalSimplePrice?.priceValue
        : (this.modalSimplePrice?.priceValue || 0) / 12) || 0
    )
  }

  get totalPriceValue() {
    if (this.isCustomPlane) {
      return 0
    }
    if (this.isPayAsYouGo) {
      return this.payAsYouGoPrice?.priceValue || 0
    }
    return this.modalSimplePrice?.priceValue || 0
  }

  get creditsIncluded() {
    if (this.isCustomPlane) {
      return 0
    }
    if (this.isPayAsYouGo) {
      return this.payAsYouGoPrice?.creditsIncluded || 0
    }
    return this.modalSimplePrice?.creditsIncluded || 0
  }

  get bonusCredits() {
    if (this.modalSimplePrice?.bonusValue) {
      return this.creditsIncluded + this.modalSimplePrice?.bonusValue
    }
    return 0
  }

  get additionalCreditPrice() {
    if (this.isCustomPlane) {
      return 1.5
    }
    if (this.isPayAsYouGo) {
      return (this.payAsYouGoPrice?.additionalCreditPrice || 0) * 100
    }
    return (this.modalSimplePrice?.additionalCreditPrice || 0) * 100
  }

  get payAsYouGoPrice() {
    return this.prices.find((price) => price.planName === 'PayAsYouGo')
  }

  get currentPrice() {
    if (this.billingStore.canceledSubscription) {
      return undefined
    }
    return this.billingStore.clSubscription?.price
  }

  get proPrices(): SimplePrice[] {
    if (this.prices) {
      return this.prices?.filter((price) => price.planLine === 'Pro')
    }
    return []
  }

  get proPricesForPeriod(): SimplePrice[] {
    return this.proPrices
      .filter((price) => price.period === this.modalActivePeriod)
      .sort((a, b) => Number(a.creditsIncluded) - Number(b.creditsIncluded))
  }

  get proMonthlyPrices(): SimplePrice[] {
    return this.proPrices
      .filter((price) => price.period === 'Monthly')
      .sort((a, b) => Number(a.creditsIncluded) - Number(b.creditsIncluded))
  }

  get proAnnualPrices(): SimplePrice[] {
    return this.proPrices
      .filter((price) => price.period === 'Annual')
      .sort((a, b) => Number(a.creditsIncluded) - Number(b.creditsIncluded))
  }

  get modalSimplePrice(): SimplePrice | undefined {
    if (this.isPayAsYouGo) {
      return this.payAsYouGoPrice
    }
    return this.proPricesForPeriod[this.modalSelectedRangeValue]
  }

  get modalSimplePriceMonthly(): SimplePrice | undefined {
    return this.proMonthlyPrices[this.modalSelectedRangeValue]
  }

  get modalSimplePriceAnnual(): SimplePrice | undefined {
    if (this.isPayAsYouGo) {
      return this.payAsYouGoPrice
    }
    return this.proAnnualPrices[this.modalSelectedRangeValue]
  }

  get modalActivePeriodString() {
    return '/ mo'
  }

  get modalActivePeriodStringForRangeBar() {
    return this.modalActivePeriod === PlanPeriodsEnum.Monthly ? 'month' : 'year'
  }

  get modalSavedMoney() {
    return (
      (this.modalSimplePriceMonthly?.priceValue || 0) * 12 -
      (this.modalSimplePriceAnnual?.priceValue || 0)
    )
  }

  setIsCustomPlane(val: boolean) {
    this.isCustomPlane = val
  }
  setIsPayAsYouGo(val: boolean) {
    this.isPayAsYouGo = val
  }

  setIsDowngradeSubscription(state: boolean) {
    this.isDowngradeSubscription = state
  }

  setPrices(prices: SimplePrice[]) {
    this.prices = prices
    this.initModalState()
  }

  setActiveModalStep(index: number) {
    this.modalActiveStep = index
  }

  setActiveModalPeriod(period: PlanPeriodsEnum) {
    this.modalActivePeriod = period
  }

  setSelectedModalRangeValue(val: number) {
    this.modalSelectedRangeValue = val
  }

  private withDowngrade = false

  private initModalState() {
    if (this.currentPrice && !this.billingStore.canceledSubscription) {
      if (this.currentPrice.planLine === 'Pro') {
        this.isPayAsYouGo = false
        if (this.currentPrice.period === 'Monthly') {
          this.modalActivePeriod = PlanPeriodsEnum.Monthly
          this.modalSelectedRangeValue = this.proMonthlyPrices.findIndex(
            (price) => price.planName === this.currentPrice?.planName
          )
        } else {
          this.modalActivePeriod = PlanPeriodsEnum.Annual
          this.modalSelectedRangeValue = this.proAnnualPrices.findIndex(
            (price) => price.planName === this.currentPrice?.planName
          )
        }
        if (
          this.withDowngrade &&
          this.currentPrice.planName !== 'ProAnnual3000'
        ) {
          this.modalSelectedRangeValue = this.modalSelectedRangeValue - 1
        }
        if (
          this.withDowngrade &&
          this.currentPrice.planName === 'ProAnnual3000'
        ) {
          this.modalActivePeriod = PlanPeriodsEnum.Monthly
        }
      } else if (this.currentPrice?.planName === 'PayAsYouGo') {
        this.modalSelectedRangeValue = 7
        this.isPayAsYouGo = true
      }
    }

    this.modalActiveStep = 0
    this.billingStore.clearCardElement()
  }

  setOpenUpgradePlanModal(state: boolean, withDowngrade?: boolean) {
    if (state && companyStore.isContract) {
      return
    }
    if (state) {
      this.billingStore.expandPaymentCard = false
      this.withDowngrade = !!withDowngrade
      this.initModalState()
    }
    this.openUpgradePlanModal = state
  }

  onConfirm() {
    if (this.isDowngradeSubscription && this.modalSimplePrice) {
      this.billingStore.downgradedPrice = this.modalSimplePrice
    }
    this.billingStore.clearCardElement()
    this.modalActiveStep = 0
    this.openUpgradePlanModal = false
    this.billingStore.openBuySubscriptionModal = true
  }

  onClose() {
    this.initModalState()
    this.openUpgradePlanModal = false
  }
}

export default new UpgradePlanStore()
