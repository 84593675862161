import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { PrimaryCardDropdown } from 'src/routes/settings/billing/components/PrimaryCardDropdown'
import { Modal } from 'components/Modal/Modal'
import React, { useState } from 'react'
import { useCardDropdownOptions } from 'src/routes/settings/billing/components/hooks/useCardDropdownOptions'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import alertStore from 'store/alertStore'
import { useCarrierFeePayMutation } from 'src/generated/graphql'
import companyStore from 'store/settings/company/companyStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const UpdateCardModal = observer(() => {
  const [selectedValue, onSelectValue] = useCardDropdownOptions()
  const [uiLoading, setUiLoading] = useState(false)
  const [carrierFeePay, { loading }] = useCarrierFeePayMutation()
  const onClose = () => {
    billingStore.setOpenUpdateCardModal(false)
  }

  const onNext = async (cardId?: string) => {
    try {
      const res = await carrierFeePay({
        variables: {
          paymentMethodId: cardId || selectedValue,
        },
      })
      if (res.data?.carrierFeePay) {
        companyStore.setOrganization(res.data?.carrierFeePay)
      }
      alertStore.setAlert({
        type: AlertTypeEnum.success,
        title: 'Credit card updated',
      })
      onClose()
    } catch (e) {
      billingStore.setUpgradeCardId(cardId || selectedValue)
      if (e instanceof Error) {
        alertStore.disabledErrorAlert(e.message)
      }
      console.error(e)
    } finally {
      setUiLoading(false)
    }
  }

  const onUpgrade = () => {
    setUiLoading(true)
    if (billingStore.showNewCard) {
      billingStore.setAddActionNewCardCb(onNext)
      billingStore.newCardElement?.click()
    } else {
      onNext()
    }
  }
  return (
    <Modal
      open={billingStore.openUpdateCardModal}
      onClose={onClose}
      title={'Update card'}
    >
      <div className={'mediumModal'}>
        <PrimaryCardDropdown
          withNewCard={true}
          selectedValue={selectedValue}
          onSelectValue={onSelectValue}
          onFinish={() => setUiLoading(false)}
        />
        <div className={'row12 mt40'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            loading={loading || uiLoading}
            disabled={
              billingStore.showNewCard
                ? !billingStore.readyNewCard
                : !selectedValue || selectedValue === billingStore.upgradeCardId
            }
            onClick={onUpgrade}
          >
            Update card
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
