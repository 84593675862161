import { Avatar } from 'components/Avatar/Avatar'
import styles from './styles.module.scss'
import { SizedBox } from 'components/SizedBox'
import { ReactComponent as Scheduled } from 'icons/16px/Status/Scheduled.svg'
import { FC, useMemo } from 'react'
import {
  InvitationRole,
  MemberResponseFragment,
  MemberRole,
  MemberState,
  useRemoveMemberMutation,
  useResendInvitationMutation,
} from 'src/generated/graphql'
import user from 'store/user/user'
import { observer } from 'mobx-react-lite'
import { CopyBtn } from 'components/Button/CopyBtn'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import teamStore from 'store/settings/teamStore'

interface Props extends MemberResponseFragment {
  onEdit: () => void
}

export const getRole = (role?: MemberRole | InvitationRole | null) => {
  if (role === MemberRole.User || role === InvitationRole.Member) {
    return 'Member'
  }
  if (role === MemberRole.Owner) {
    return 'Owner'
  }
  if (role === MemberRole.Admin || role === InvitationRole.Admin) {
    return 'Admin'
  }
  return ''
}

export const MemberCard = observer(
  ({
    firstName,
    lastName,
    email,
    role,
    state,
    onEdit,
    invitationLink,
    invitationBid,
    id,
  }: Props) => {
    const [resendInvitation, { loading }] = useResendInvitationMutation()
    const [removeMember] = useRemoveMemberMutation()
    const onResend = async (onCloseTT?: () => void) => {
      try {
        onCloseTT && onCloseTT()
        if (invitationBid?.id) {
          await resendInvitation({
            variables: {
              invitationId: invitationBid.id,
            },
          })
          teamStore.setSentInvite({
            email: invitationBid?.email || '',
            id: invitationBid?.id,
            isResent: true,
          })
        }
      } catch (e) {
        console.error(e)
      }
    }

    const EditBtn = ({ onCloseTT }: MoreBtnItemProps) => {
      return (
        <MenuBtn
          type={MenuEnum.edit}
          onClick={() => {
            onCloseTT && onCloseTT()
            onEdit()
          }}
          title={'Edit member'}
        />
      )
    }

    const ResendBtn = ({ onCloseTT }: MoreBtnItemProps) => {
      return (
        <MenuBtn
          type={MenuEnum.refresh}
          onClick={() => onResend(onCloseTT)}
          title={'Resend invite'}
          loading={loading}
        />
      )
    }

    const CopyInvite = () => {
      return (
        <CopyBtn
          content={invitationLink || ''}
          menuBtn
          linkIcon
          withCopiedIcon
          textBtn={'Copy invite link'}
        />
      )
    }
    const DeleteMemberBtn = () => {
      return (
        <MenuBtn
          type={MenuEnum.delete}
          onClick={() => {
            removeMember({
              variables: {
                memberId: id,
              },
            })
              .then(() => {
                teamStore.removeMember(id)
              })
              .catch(console.error)
          }}
        />
      )
    }
    const menuItems = useMemo(() => {
      const items: Array<FC<MoreBtnItemProps>> = [EditBtn]
      if (state === MemberState.EmailConfirmationAwait) {
        items.push(ResendBtn)
      }
      if (state === MemberState.EmailConfirmationAwait && invitationLink) {
        items.push(CopyInvite)
      }
      items.push(DeleteMemberBtn)
      return items
    }, [state])
    return (
      <div className={styles.wrap}>
        <Avatar firstName={firstName || ''} lastName={lastName || ''} />
        <div className={styles.grid}>
          <span className={'medium'}>
            {`${firstName || ''} ${lastName || ''}`.trim() || '—'}
          </span>
          <SizedBox height={4} />
          <span className={'s2 blue3'}>{email}</span>
        </div>
        <div className={styles.role}>
          <span className={'s2 medium'}>{getRole(role)}</span>
        </div>
        {state === MemberState.EmailConfirmationAwait ? (
          <div className={'notify1 flexIcon'}>
            <Scheduled />
            <span className={'notify1'}>Waiting for email confirmation</span>
          </div>
        ) : (
          <div />
        )}

        {role !== MemberRole.Owner && user.role === MemberRole.Owner && (
          <MoreBtn menuItems={menuItems} width={173} />
        )}
      </div>
    )
  }
)
