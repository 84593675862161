// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wo5dG99O3AKGCCz4hGgh{padding:40px 0 32px 0;border-top:1px solid var(--primary-color-gray-3);width:438px}`, "",{"version":3,"sources":["webpack://./src/routes/main/webForms/modals/TestWebFormModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,gDAAA,CACA,WAAA","sourcesContent":[".wrapWebForm{\n  padding: 40px 0 32px 0;\n  border-top: 1px solid var(--primary-color-gray-3);\n  width: 438px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapWebForm": `wo5dG99O3AKGCCz4hGgh`
};
export default ___CSS_LOADER_EXPORT___;
