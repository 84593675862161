import { ActionBtnProps } from 'components/NewTable/types'
import { IBroadcast } from 'src/types/IBroadcast'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useBroadcastFunctions } from 'src/routes/main/broadcasts/hooks/useBroadcastFunctions'
import { useEffect, useState } from 'react'

export const BroadcastStopBtn = (props: ActionBtnProps<IBroadcast>) => {
  const { row, rows } = props
  const { onStop } = useBroadcastFunctions(row, rows)

  const [isConfirm, setConfirm] = useState(false)
  const [idInterval, setIdInterval] = useState<NodeJS.Timeout | null>(null)
  const onAction = () => {
    if (isConfirm) {
      onStop()
    } else {
      setConfirm(true)
      const id = setTimeout(() => {
        setConfirm(false)
      }, 3000)
      setIdInterval(id)
    }
  }
  useEffect(() => {
    return () => {
      if (idInterval) {
        clearInterval(idInterval)
      }
    }
  }, [])

  return (
    <TableBtn
      {...props}
      textBtn={isConfirm ? 'Sure?' : undefined}
      action={ActionsEnum.stop}
      onAction={onAction}
    />
  )
}
