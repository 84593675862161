import { TablePage } from 'components/Page/TablePage'
import { observer } from 'mobx-react-lite'
import { WebFormsTable } from 'src/routes/main/webForms/WebformsTable/WebFormsTable'

export const WebForms = observer(() => {
  return (
    <TablePage>
      <h1>Web Forms</h1>
      <WebFormsTable />
    </TablePage>
  )
})
