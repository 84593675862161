import { observer } from 'mobx-react-lite'
import superAdminStore from 'store/superAdmin/superAdminStore'
import { Modal } from 'components/Modal/Modal'
import classNames from 'classnames'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useEffect, useState } from 'react'
import { useAdminCreateCallerIdPhoneNumberMutation } from 'src/generated/graphql'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const AddCallerIdModal = observer(() => {
  const {
    openAddCallerIdModalOrganizationId,
    setOpenAddCallerIdModalOrganizationId,
  } = superAdminStore

  const [error, setError] = useState('')

  const [createCallerIdPhoneNumber, { loading }] =
    useAdminCreateCallerIdPhoneNumberMutation()

  const onClose = () => {
    setOpenAddCallerIdModalOrganizationId('')
  }

  const [phone, setPhone] = useState('')

  useEffect(() => {
    if (openAddCallerIdModalOrganizationId) {
      setPhone('')
    }
  }, [openAddCallerIdModalOrganizationId])

  const addCallerId = async () => {
    try {
      await createCallerIdPhoneNumber({
        variables: {
          organizationId: Number(openAddCallerIdModalOrganizationId),
          phoneNumber: phone,
        },
      })
      alertStore.setAlert({
        title: 'Caller Id Added',
        type: AlertTypeEnum.success,
      })
      onClose()
    } catch (e) {
      if (e instanceof Error) {
        if (
          e.message.includes(
            'Caller ID number already reserved within an organization'
          )
        ) {
          setError('Caller ID number already reserved within an organization')
          alertStore.disabledErrorAlert(e.message)
        }
      }
      console.error(e)
    }
  }

  return (
    <Modal
      open={!!openAddCallerIdModalOrganizationId}
      title={'Add caller ID'}
      onClose={onClose}
    >
      <div className={classNames('medium3Modal')}>
        <InputPhone value={phone} onChangeValue={setPhone} error={error} />
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={addCallerId}
            disabled={!phone.length || phone.includes('_')}
            loading={loading}
          >
            Add caller ID
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
