// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xubglt8ue0lGRrKZEa7H{width:48px;height:48px;border-radius:50%;background-color:var(--primary-color-gray-4);display:flex;justify-content:center;align-items:center}.Y67RANx8moIWoOod5aYM{color:var(--states-color-warning-2)}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,4CAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,mCAAA","sourcesContent":[".wrap{\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  background-color: var(--primary-color-gray-4);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.logo{\n  color: var(--states-color-warning-2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `xubglt8ue0lGRrKZEa7H`,
	"logo": `Y67RANx8moIWoOod5aYM`
};
export default ___CSS_LOADER_EXPORT___;
