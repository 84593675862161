import React, { FC, MouseEventHandler, useEffect, useMemo, useRef } from 'react'
import { Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import { ReactComponent as Check } from 'icons/16px/Check.svg'
import classNames from 'classnames'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import uiStore from 'store/uiStore'
import { IOption } from 'src/types/IOption'
import { Icon } from 'components/Icon/Icon'

type Props = {
  option: IOption
  onSelectOption: (opt: IOption) => void
  isSelect: boolean
  minHeightOption?: number
  isOneChecked: boolean
  showTT?: boolean
  multi?: boolean
}

export const Option: FC<Props> = ({
  option,
  onSelectOption,
  isSelect,
  minHeightOption,
  isOneChecked,
  showTT,
  multi,
}) => {
  const ref = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (showTT && !multi && isSelect) {
      ref.current?.scrollIntoView()
    }
  }, [showTT, ref])
  const { LeftIcon, count, tooltip, onClick, leftIcon } = option
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick) {
      onClick(e)
    } else {
      if (option.disabled && !isSelect) {
        return
      }
      uiStore.setTriggerUpdate()
      onSelectOption(option)
    }
  }
  const mark = useMemo(
    () =>
      (isOneChecked || option.leftMark) && (
        <div
          className={classNames(
            styles.check,
            isOneChecked && styles.isOneChecked,
            option.leftMark && styles.leftMark
          )}
        >
          {isSelect && <Check />}
        </div>
      ),
    [isOneChecked, isSelect, option]
  )
  return (
    <Button
      ref={ref}
      key={option.value}
      className={classNames(styles.option, option.optionClass)}
      onClick={handleClick}
      style={{
        minHeight: minHeightOption || option.minHeight,
        padding: option.padding,
      }}
    >
      <div className={styles.wrapTitle}>
        {option.leftMark && mark}
        {tooltip && <InfoTooltip title={tooltip} placement={'left'} />}
        {leftIcon && (
          <div className={classNames(styles.leftIcon, option.iconClass)}>
            <Icon {...leftIcon} />
          </div>
        )}
        {LeftIcon && (
          <div className={classNames(styles.icon, option.iconClass)}>
            <LeftIcon />
          </div>
        )}

        <div>
          <div className={'flex'}>
            <div
              className={classNames(
                styles.optionTitle,
                option.p8 && styles.p8,
                option.disabled && styles.disabled
              )}
              style={{ backgroundColor: option.titleBGColor }}
            >
              {option.leftTitleContent}
              {option.optionPrefix}
              {option.title}
            </div>
            {!!count && <span className={'gray1'}>({count})</span>}
          </div>
          {option.subTitle && (
            <span className={classNames('s2 gray1', styles.optionSubTitle)}>
              {option.subTitle}
            </span>
          )}
        </div>
      </div>

      <div className={styles.row}>
        {option.rightContent}
        <div className={styles.flex}>
          {option.optionRightContent}
          {option.disabled && option.disabledTooltip && (
            <InfoTooltip title={option.disabledTooltip} />
          )}
          {!option.leftMark && mark}
        </div>
      </div>
    </Button>
  )
}
