import { Dropdown } from 'components/Dropdown/Dropdown'
import { InputText } from 'components/Input/InputText/InputText'
import { observer } from 'mobx-react-lite'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { isHttps } from 'src/util/validators'
import { PropsBusinessStep } from 'src/routes/settings/compliance/BusinessProfile/types'
import {
  BusinessProfileResponse,
  useAdminInitBusinessInfoMutation,
  useInitBusinessInfoMutation,
} from 'src/generated/graphql'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { BusinessCompanyWebsite } from 'src/routes/settings/compliance/BusinessProfile/steps/components/BusinessCompanyWebsite'

export const BusinessInfo = observer(
  ({ onCancel, loadingPrevious, onSuccess }: PropsBusinessStep) => {
    const { adminOrgId } = businessProfileStore
    const [initBusinessInfo, { loading }] = useInitBusinessInfoMutation()
    const [adminInitBusinessInfo, { loading: loadingAdmin }] =
      useAdminInitBusinessInfoMutation()
    const onContinue = async () => {
      try {
        let profile: BusinessProfileResponse | undefined | null
        if (adminOrgId) {
          const { data } = await adminInitBusinessInfo({
            variables: {
              input: businessProfileStore.businessInfoRequestInput,
              organizationId: adminOrgId,
            },
          })
          profile = data?.adminInitBusinessInfo
        } else {
          const { data } = await initBusinessInfo({
            variables: {
              input: businessProfileStore.businessInfoRequestInput,
            },
          })
          profile = data?.initBusinessInfo
        }

        profile && onSuccess(profile)
      } catch (e) {
        console.error(e)
      }
    }

    const onBlur = (key: 'website' | 'socialMediaProfile') => {
      let value = ''
      if (key === 'website') {
        value = businessProfileStore.businessInfo.website || ''
      }
      if (key === 'socialMediaProfile') {
        value = businessProfileStore.businessInfo.socialMediaProfile || ''
      }
      const isWrong = !!value && !isHttps(value)
      businessProfileStore.setError(key, isWrong)
    }
    const clearError = (key: string) => {
      businessProfileStore.setError(key, false)
    }
    return (
      <>
        <div className={'col24'}>
          <div className={'row12'}>
            <Dropdown
              fullWidth
              selectedValue={businessProfileStore.businessInfo.type}
              onSelectValue={(type) =>
                businessProfileStore.setBusinessInfo({
                  ...businessProfileStore.businessInfo,
                  type,
                })
              }
              labelInput={'Business type'}
              labelTooltip={'Please select your business entity type'}
              placeholder={'e.g. LLC'}
              options={businessProfileStore.businessTypesOptions}
            />
            <Dropdown
              fullWidth
              selectedValue={businessProfileStore.businessInfo.industry}
              onSelectValue={(industry) =>
                businessProfileStore.setBusinessInfo({
                  ...businessProfileStore.businessInfo,
                  industry,
                })
              }
              labelInput={'Business industry'}
              placeholder={'e.g. Online'}
              options={businessProfileStore.businessIndustryOptions}
              withSearch
            />
          </div>
          <BusinessCompanyWebsite
            website={businessProfileStore.businessInfo.website || ''}
            setWebsite={(website) =>
              businessProfileStore.setBusinessInfo({
                ...businessProfileStore.businessInfo,
                website,
              })
            }
            isError={!!businessProfileStore.mapErrors.get('website')}
            setError={businessProfileStore.setError}
          />
          <InputText
            label={'Social media profile (Optional)'}
            tooltip={
              'Have a Facebook, Instagram, or company Twitter account? Enter the URL to your social media profile.'
            }
            tooltipWidth={209}
            value={businessProfileStore.businessInfo.socialMediaProfile || ''}
            onChangeValue={(socialMediaProfile) =>
              businessProfileStore.setBusinessInfo({
                ...businessProfileStore.businessInfo,
                socialMediaProfile,
              })
            }
            placeholder={'e.g. https://www.facebook.com/example'}
            onBlur={() => onBlur('socialMediaProfile')}
            error={
              (businessProfileStore.mapErrors.get('socialMediaProfile') &&
                'Please enter a full url (e.g. https://www.site.com)') ||
              ''
            }
            onFocus={() => clearError('socialMediaProfile')}
          />
          <Dropdown
            selectedValues={
              businessProfileStore.businessInfo.operationRegions || []
            }
            onMultiSelectValues={(operationRegions) =>
              businessProfileStore.setBusinessInfo({
                ...businessProfileStore.businessInfo,
                operationRegions,
              })
            }
            labelInput={'Business regions of operations'}
            labelTooltip={
              'Where do you do business? If you have customers all around the world, go ahead and select all options. Otherwise, just select the ones where your customers are primarily located. '
            }
            labelTooltipWidth={266}
            selectedTag
            placeholder={'e.g. USA and Canada'}
            options={businessProfileStore.businessRegionsOptions}
            multi
            onDeleteTag={(value) =>
              businessProfileStore.setBusinessInfo({
                ...businessProfileStore.businessInfo,
                operationRegions:
                  businessProfileStore.businessInfo.operationRegions?.filter(
                    (region) => region !== value
                  ),
              })
            }
          />
        </div>
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            disabled={businessProfileStore.disabledBusinessInfo}
            loading={loading || loadingAdmin}
          >
            {businessProfileStore.continueText}
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onCancel}
            loading={loadingPrevious}
          >
            {businessProfileStore.step === 1 ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </>
    )
  }
)
