import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { CampaignDetailContent } from 'src/routes/main/campaigns/detail/CampaignDetailContent'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import {
  CampaignResponse,
  useGetCampaignByIdQuery,
} from 'src/generated/graphql'
import { transformCampaign } from 'store/campaigns/transformCampaign'
import { useEffect } from 'react'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'

export const CampaignDetailPage = observer(() => {
  const { id } = useParams()

  const { data, loading, refetch } = useGetCampaignByIdQuery({
    variables: {
      campaignId: +(id || 0),
    },
  })

  useEffect(() => {
    campaignDetailStore.reset()
  }, [id])

  useEffect(() => {
    if (campaignDetailStore.refreshTrigger) {
      refetch()
    }
  }, [campaignDetailStore.refreshTrigger])

  useEffect(() => {
    if (data?.getCampaignById) {
      campaignDetailStore.setCampaign(
        transformCampaign(data.getCampaignById as CampaignResponse)
      )
    }
  }, [data])

  if (loading) {
    return <AbsoluteLoader />
  }

  if (campaignDetailStore?.campaign) {
    return <CampaignDetailContent />
  }

  return null
})
