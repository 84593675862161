// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SIALtDIj1LfpEHBn1ASQ{border-radius:20px;background:var(--gradient-2);height:fit-content;padding:32px}.PXPEIjN8XYRLtwFzzKPg{padding:32px;box-shadow:var(--shadow-2);background-color:var(--primary-color-white);border-radius:12px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/account/routes/MyProfile/VirtualContactCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4BAAA,CACA,kBAAA,CACA,YAAA,CAEF,sBACE,YAAA,CACA,0BAAA,CACA,2CAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  border-radius: 20px;\n  background: var(--gradient-2);\n  height: fit-content;\n  padding: 32px;\n}\n.emptyCard{\n  padding: 32px;\n  box-shadow: var(--shadow-2);\n  background-color: var(--primary-color-white);\n  border-radius: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `SIALtDIj1LfpEHBn1ASQ`,
	"emptyCard": `PXPEIjN8XYRLtwFzzKPg`
};
export default ___CSS_LOADER_EXPORT___;
