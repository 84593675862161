import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { BtnSize, BtnType, Button } from 'components/Button/Button'

export const CancelSubscriptionModal = observer(() => {
  const onClose = () => {
    billingStore.setOpenCancelSubscriptionModal(false)
  }
  const onPause = () => {
    billingStore.setOpenPauseSubscriptionModal(true)
    onClose()
  }
  const onContinue = () => {
    onClose()
    billingStore.setOpenContinueCancelSubscriptionModal(true)
  }
  return (
    <Modal
      open={billingStore.openCancelSubscriptionModal}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon type={'info'} />
        <h3>
          Before you cancel, did you know you can pause your subscription?
        </h3>
        <p className={'par2 gray1'}>
          Need to temporarily stay away? No problem! Pause your membership, for
          up to 3 months.
        </p>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          fullWidth
          className={'mb8'}
          onClick={onPause}
        >
          Pause instead
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          fullWidth
          onClick={onContinue}
        >
          Continue with cancellation
        </Button>
      </div>
    </Modal>
  )
})
