import styles from 'components/Preview/styles.module.scss'
import { MessageCardContent } from 'components/Preview/MessageCardContent'
import { FC } from 'react'
import { IPreviewVoiceMessage } from 'components/Preview/types'

export const AudioMessageContent: FC<IPreviewVoiceMessage> = ({
  liveAnswerEnabled,
  voiceMailEnabled,
  liveAnswerEnabledAudio,
  voiceMailEnabledAudio,
  phone,
}) => {
  return (
    <div className={styles.messageWrap}>
      <div className={styles.audioMessage}>
        {!liveAnswerEnabled && !voiceMailEnabled && (
          <span className={'s2 medium gray2'}>Add an audio</span>
        )}
        {liveAnswerEnabled && (
          <MessageCardContent
            title={'Live answer'}
            from={'liveAnswer'}
            audio={liveAnswerEnabledAudio}
            phone={phone}
          />
        )}
        {voiceMailEnabled && (
          <MessageCardContent
            title={'Voicemail'}
            from={'voicemail'}
            audio={voiceMailEnabledAudio}
            phone={phone}
          />
        )}
      </div>
    </div>
  )
}
