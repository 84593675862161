import { Modal } from 'components/Modal/Modal'
import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import integrationsStore from 'store/settings/integrationsStore'
import styles from './styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
// import { ReactComponent as Reload } from 'icons/16px/Reload.svg'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { CopyBtn } from 'components/Button/CopyBtn'
import { useEffect, useState } from 'react'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import companyStore from 'store/settings/company/companyStore'

export const CallLoopApiKeyModal = observer(() => {
  const [isGenerate, setGenerate] = useState(false)
  const [isNewKey, setNewKey] = useState(false)
  const onClose = () => {
    integrationsStore.setOpenCallLoopApiKeyModal(false)
  }
  const apiKey = companyStore.organization?.uuid
  // const onRefresh = () => {
  //   setGenerate(true)
  // }
  const onCancel = () => {
    setGenerate(false)
  }
  const onConfirm = () => {
    setNewKey(true)
    setGenerate(false)
  }
  useEffect(() => {
    setNewKey(false)
    setGenerate(false)
  }, [integrationsStore.openCallLoopApiKeyModal])
  return (
    <Modal
      open={integrationsStore.openCallLoopApiKeyModal}
      onClose={onClose}
      title={'Call Loop API key'}
    >
      <div className={'mediumModal'}>
        {!isGenerate && (
          <Alert
            title={
              isNewKey
                ? 'You have generated a new API key'
                : 'Use Call Loop API to connect Call Loop to 3rd party apps'
            }
            type={isNewKey ? AlertTypeEnum.success3 : AlertTypeEnum.notify2}
            text={
              isNewKey
                ? 'If you had connected Call Loop to 3rd party apps, you need to update the API key manually.'
                : undefined
            }
          />
        )}
        {isGenerate && (
          <Alert
            title={'Warning!'}
            text={
              'Generating new API Key will affect your current connections with 3rd party apps.'
            }
            type={AlertTypeEnum.warning}
          />
        )}
        <div className={styles.wrapFields}>
          <InputText
            label={'Web Url'}
            placeholder={'https://'}
            value={apiKey}
            showRightContent
            withoutClear
            // rightContent={
            //   <Button
            //     size={BtnSize.small}
            //     typeBtn={BtnType.secondaryGray}
            //     onClick={onRefresh}
            //   >
            //     <Reload />
            //   </Button>
            // }
            // onClickRightIcon={() => setUrl('')}
          />
        </div>

        {isGenerate ? (
          <div className={'row12'}>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={onConfirm}
            >
              Confirm generate
            </Button>
          </div>
        ) : (
          <CopyBtn
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            content={apiKey}
            textBtn={'Copy API key'}
          />
        )}
      </div>
    </Modal>
  )
})
