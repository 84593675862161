import { makeAutoObservable } from 'mobx'
import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import { columnsDetailKeyword } from 'components/NewTable/columns/keywords/columnsDetailKeyword'
import {
  GetKeywordContactsQuery,
  KeywordContactStatisticsResponse,
  KeywordFragmentFragment,
} from 'src/generated/graphql'
import { transformKeyword } from 'src/routes/main/keywords/functions/transformKeyword'
import { IKeyword } from 'src/types/IKeyword'
import { IKeywordContact } from 'src/types/IKeywordContact'

export class KeywordDetailStore {
  tableStore: TableStore<IKeywordContact>
  constructor() {
    makeAutoObservable(this)
    this.tableStore = new TableStore({
      orderBy: 'addedTime',
      tableName: 'KeywordsDetailPage',
      columns: columnsDetailKeyword,
      withDataRange: true,
      status: TableStatuses.totalCount,
    })
  }
  keyword: IKeyword | null = null

  contactStatistics: KeywordContactStatisticsResponse | null = null
  contacts: IKeywordContact[] = []

  get totalCharged() {
    return this.contactStatistics?.chargeStatistics?.totalCharged || 0
  }

  setKeyword(keyword: KeywordFragmentFragment) {
    this.keyword = transformKeyword(keyword)
  }

  setMessageStatistics(
    contactStatistics: KeywordContactStatisticsResponse | null
  ) {
    this.contactStatistics = contactStatistics
  }

  setContactsData(data: GetKeywordContactsQuery) {
    this.tableStore.total = data.getKeywordContacts?.total || 0
    const contacts: IKeywordContact[] = []

    data.getKeywordContacts?.content?.forEach((contact) => {
      if (contact) {
        contacts.push({ ...contact, id: contact.phone || '' })
      }
    })
    this.contacts = contacts
  }
}
