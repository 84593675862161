// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z9Lc0jn4c2gHX0JTl_bS{padding:8px}.QsAcdZQOqRIVGpLQ2gxt{display:flex;padding:4px 8px 8px 8px}`, "",{"version":3,"sources":["webpack://./src/components/SendToDropDownWithContacts/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEF,sBACE,YAAA,CACA,uBAAA","sourcesContent":[".wrapTT{\n  padding: 8px;\n}\n.label{\n  display: flex;\n  padding: 4px 8px 8px 8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTT": `z9Lc0jn4c2gHX0JTl_bS`,
	"label": `QsAcdZQOqRIVGpLQ2gxt`
};
export default ___CSS_LOADER_EXPORT___;
