import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { ClassesEnum } from 'styles/classes'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import * as React from 'react'
import { FC, useEffect } from 'react'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'

export interface MoreBtnItemProps {
  onCloseTT?: () => void
  medium?: boolean
  menu?: boolean
}

export const MoreBtn = ({
  menuItems,
  width = 148,
  fromMobile,
  medium,
  hoverGray,
  tooltip,
  setOpen,
  defaultVisibleMoreBtnContent,
  onHideDefaultVisible,
  extraBtnSize,
  position = TTPositionEnum.bottomRight,
}: {
  menuItems: Array<FC<MoreBtnItemProps>>
  width?: number | string
  fromMobile?: boolean
  hoverGray?: boolean
  medium?: boolean
  setOpen?: (val: boolean) => void
  tooltip?: string
  defaultVisibleMoreBtnContent?: JSX.Element
  onHideDefaultVisible?: () => void
  extraBtnSize?: BtnSize
  position?: TTPositionEnum
}) => {
  const { showTT, setShowTT, trigger, setTrigger, onTriggerClick } =
    useFixedTooltip(undefined, onHideDefaultVisible)
  const onCloseTT = () => {
    setShowTT(false)
  }
  useEffect(() => {
    setOpen && setOpen(showTT)
  }, [showTT])
  useEffect(() => {
    if (defaultVisibleMoreBtnContent) {
      setShowTT(true)
    }
  }, [defaultVisibleMoreBtnContent])
  return (
    <>
      <FixedTooltip
        visible={showTT}
        trigger={trigger}
        width={width}
        minWidth={'fit-content'}
        white
        noArrow
        position={position}
        globalClasses={[ClassesEnum.p8]}
      >
        {defaultVisibleMoreBtnContent
          ? defaultVisibleMoreBtnContent
          : menuItems.map((Item, index) => (
              <Item onCloseTT={onCloseTT} key={index} menu />
            ))}
      </FixedTooltip>
      <div ref={setTrigger}>
        <MuiTooltip title={tooltip} arrow placement={'top'}>
          <Button
            size={
              extraBtnSize ||
              (medium
                ? BtnSize.medium
                : fromMobile
                ? BtnSize.large
                : BtnSize.small)
            }
            typeBtn={
              fromMobile || hoverGray ? undefined : BtnType.secondaryGray
            }
            hoverGray2={hoverGray}
            // hoverGray={hoverGray}
            onClick={onTriggerClick}
            active={showTT}
            icon
          >
            <More />
          </Button>
        </MuiTooltip>
      </div>
    </>
  )
}
