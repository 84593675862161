export const isHttps = (value: string) => /(http(s?)):\/\//i.test(value)
export const requiredDetector = <T>(targetObject: T, keys: Array<keyof T>) => {
  if (!targetObject) {
    return true
  }
  const values = keys
    .map((key) => [key, targetObject[key]])
    .filter(([key, value]) => {
      if (typeof value === 'string' && key === 'phoneNumber') {
        return !value.trim() || value.includes('_')
      }
      if (typeof value === 'string') {
        return !value.trim()
      }
      return !value
    })
  return !!values.length
}
