import styles from './styles.module.scss'
import { ReactComponent as Start } from 'icons/16px/Start.svg'
import { ReactComponent as Start24 } from 'icons/24px/Start.svg'
import { ReactComponent as Pause } from 'icons/16px/Status/Running.svg'
import { ReactComponent as Pause24 } from 'icons/24px/Running.svg'
import { useEffect, useRef, useState } from 'react'
import { secondToMinutes } from 'src/util/functions'
import WaveSurfer from 'wavesurfer.js'
import { ColorsValues } from 'styles/variables'
import { BtnSize } from 'components/Button/Button'
import classNames from 'classnames'
import { SizedBox } from 'components/SizedBox'
import { observer } from 'mobx-react-lite'
import audioStore from 'store/audioStore/audioStore'
import { getBlob } from 'src/util/getBlob'

export const AudioPlayer = observer(
  ({
    src,
    size,
    id,
    className,
    maxCanvasWidth,
    fullWidth,
    fromPreview,
  }: {
    src: string
    size?: BtnSize
    durationSeconds?: number
    className?: string
    fromPreview?: boolean
    fromRecoder?: boolean
    maxCanvasWidth: number
    fullWidth?: boolean
    id: string
  }) => {
    const waveformElementRef = useRef<HTMLDivElement>(null)
    const [waveform, setWaveform] = useState<WaveSurfer | null>(null)
    const [duration, setDuration] = useState(0)
    const [playing, setPlaying] = useState(false)
    const onPlay = () => {
      if (waveform) {
        audioStore.setPlayAudioId(id)
        waveform.playPause()
        setPlaying(waveform?.isPlaying())
      }
    }
    useEffect(() => {
      if (id !== audioStore.playAudioId && playing) {
        if (waveform) {
          waveform.playPause()
          setPlaying(waveform?.isPlaying())
        }
      }
    }, [audioStore.playAudioId, playing])
    useEffect(() => {
      if (!waveformElementRef.current) return
      const w = WaveSurfer.create({
        barWidth: 2,
        barRadius: 2,
        barGap: 1,
        cursorWidth: 1,
        container: waveformElementRef.current,
        height: 11,
        progressColor: ColorsValues.blue1,
        waveColor: ColorsValues.gray2,
        cursorColor: 'transparent',
      })
      getBlob(src, (blob) => {
        w.loadBlob(blob).then(() => {
          const duration = w.getDuration()
          setWaveform(w)
          setDuration(duration)
          audioStore.setDuration(src, duration)
        })
        w.on('play', () => setPlaying(true))
        w.on('pause', () => setPlaying(false))
        w.on('interaction', (time) => {
          setDuration(w.getDuration() - time)
        })
        w.on('audioprocess', (time) => {
          if (time === w.getDuration()) {
            setPlaying(false)
            setDuration(w.getDuration())
          } else {
            setDuration(w.getDuration() - time)
          }
        })
      })
      setWaveform(w)
      return () => {
        waveform?.stop()
        waveform?.destroy()
        if (waveformElementRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          waveformElementRef.current.innerHTML = ''
        }
      }
    }, [])

    return (
      <div
        className={classNames(
          styles.wrapAudio,
          size && styles[size],
          fullWidth && styles.fullWidth,
          fromPreview && styles.fromPreview,
          className,
          'player'
        )}
      >
        <SizedBox width={4} />
        <button onClick={onPlay}>
          {!playing ? (
            size === BtnSize.small ? (
              <Start24 />
            ) : (
              <Start />
            )
          ) : (
            <div className={styles.pause}>
              {size === BtnSize.small ? <Pause24 /> : <Pause />}
            </div>
          )}
        </button>
        <SizedBox width={8} />
        <span className={styles.duration}>{secondToMinutes(duration)}</span>
        <div ref={waveformElementRef} style={{ width: maxCanvasWidth }} />
        <SizedBox width={16} />
      </div>
    )
  }
)
