import { observer } from 'mobx-react-lite'
import React from 'react'
import configStore from 'store/configStore'
import { RegisterCompanyInfoB } from 'src/routes/auth/signUp/RegisterCompanyInfoB'
import { RegisterMemberInfoA } from 'src/routes/auth/signUp/RegisterMemberInfoA'

export const RegisterStep1 = observer(() => {
  if (configStore.signUpFlow === 'B') {
    return <RegisterCompanyInfoB />
  }
  return <RegisterMemberInfoA />
})
