import { ActionsEnum } from 'components/Button/types'
import { IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { SmallBtn } from 'components/Button/SmallBtn'
import * as React from 'react'
import {
  AllowImportStatus,
  useAdminChangeImportStateMutation,
} from 'src/generated/graphql'
import { IContactImportState } from 'src/routes/main/contacts/importHistory/ImportHistoryTable/types'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionBtnProps } from 'components/NewTable/types'
import superAdminImportsStore from 'store/superAdmin/superAdminImportsStore'

export const ApproveImportBtn = ({
  row,
  menu,
}: ActionBtnProps<IContactImportState>) => {
  const [adminChangeImportState, { loading }] =
    useAdminChangeImportStateMutation()
  const onApprove = async () => {
    try {
      const { data } = await adminChangeImportState({
        variables: {
          allowStatus: AllowImportStatus.Approved,
          importId: row?.id,
        },
      })
      if (data?.adminChangeImportState) {
        superAdminImportsStore.tableStore.onRefresh()
      }
    } catch (e) {
      console.error(e)
    }
  }
  if (menu) {
    return (
      <MediumBtn
        type={ActionsEnum.check}
        onClick={onApprove}
        iconType={IIconType.stroke}
        iconColor={ColorsNames.success1}
        disabled={loading}
        text={'Approve'}
        menu
      />
    )
  }
  return (
    <SmallBtn
      type={ActionsEnum.check}
      onClick={onApprove}
      iconType={IIconType.stroke}
      tooltip={'Approve'}
      iconColor={ColorsNames.success1}
      disabled={loading}
    />
  )
}
