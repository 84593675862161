// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iTDJKL8b9LLSobzo2D9A{margin-top:28px;padding-left:24px;margin-right:64px;display:flex;column-gap:8px;align-items:center}.WOa6rRfafYkVvMRneI7Q{width:100%;display:grid;grid-template-columns:1fr min-content;align-items:center}.gnGCXquX2sTBVPcfrnow{margin-top:32px;margin-left:64px;display:flex;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/contctLists/DetailList/header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CAEF,sBACE,UAAA,CACA,YAAA,CACA,qCAAA,CACA,kBAAA,CAEF,sBACE,eAAA,CACA,gBAAA,CACA,YAAA,CACA,eAAA","sourcesContent":[".content{\n  margin-top: 28px;\n  padding-left: 24px;\n  margin-right: 64px;\n  display: flex;\n  column-gap: 8px;\n  align-items: center;\n}\n.row{\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr min-content;\n  align-items: center;\n}\n.actions{\n  margin-top: 32px;\n  margin-left: 64px;\n  display: flex;\n  column-gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `iTDJKL8b9LLSobzo2D9A`,
	"row": `WOa6rRfafYkVvMRneI7Q`,
	"actions": `gnGCXquX2sTBVPcfrnow`
};
export default ___CSS_LOADER_EXPORT___;
