import styles from './styles.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { ReactComponent as Star } from 'icons/16pxNoMask/Star.svg'
import { observer } from 'mobx-react-lite'
import { useKeywordFunctions } from 'src/routes/main/keywords/hooks/useKeywordFunctions'
import { DetailsBtn } from 'src/routes/main/keywords/detail/header/DetailsBtn'
import { MoreBtnKeyword } from 'src/routes/main/keywords/detail/header/MoreBtnKeyword'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { RoutesEnum } from 'src/routes/routes'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { Status } from 'src/generated/graphql'
import { Switch } from 'components/Switch/Switch'
import * as React from 'react'
import { useKeywordDetailContext } from 'src/routes/main/keywords/detail/keywordDetailPage'

export const HeaderContent = observer(() => {
  const navigate = useNavigate()
  const { keyword, totalCharged } = useKeywordDetailContext()
  const { onEdit, onSwitch } = useKeywordFunctions({ keyword })
  if (!keyword) {
    return <></>
  }
  return (
    <div className={styles.header}>
      <Link to={RoutesEnum.keywords}>
        <div className={styles.subtitle}>
          <span>Keywords</span>
        </div>
      </Link>
      <div className={styles.nav}>
        <div className={styles.left}>
          <div>
            <Button hoverGray size={BtnSize.small} onClick={() => navigate(-1)}>
              <Angle />
            </Button>
          </div>
          <h1>{keyword?.name}</h1>
        </div>
        <div className={styles.right}>
          <div className={styles.wrapSwitch}>
            <span>{keyword.status}</span>
            <Switch
              active={keyword.status === Status.Active}
              onChange={onSwitch}
            />
          </div>
          <DetailsBtn />
          <MediumBtn type={ActionsEnum.edit} onClick={onEdit} />
          <MoreBtnKeyword />
        </div>
      </div>
      <div className={styles.rowInfo}>
        {keyword?.status && (
          <>
            <StatusContent status={keyword?.status} />
            <div className={'point'} />
          </>
        )}
        <div className={styles.creditCount}>
          <Star />
          <span>{totalCharged} credits</span>
        </div>
      </div>
    </div>
  )
})
