import { PhoneType, VerificationStatus } from 'src/generated/graphql'
import { FC } from 'react'
import { MoreBtnItemProps } from 'components/Button/MoreBtn'
import { IPhoneNumber } from 'src/types/IPhoneNumber'
import { VerifyNumberAction } from 'src/routes/settings/numbers/actions/VerifyNumberAction'
import { ForwardCallsAction } from 'src/routes/settings/numbers/actions/ForwardCallsAction'

export function getNumbersActions(
  row?: IPhoneNumber
): Array<FC<MoreBtnItemProps>> {
  const actions: Array<FC<MoreBtnItemProps>> = []
  if (!row) return actions

  if (
    row.type === PhoneType.TollFree &&
    row.verificationStatus === VerificationStatus.Unverified
  ) {
    actions.push((props) => VerifyNumberAction({ ...props, row }))
  }
  if (row.type === PhoneType.TollFree) {
    actions.push((props) => ForwardCallsAction({ ...props, row }))
    // actions.push((props) => ReleaseAction({ ...props, row }))
  }

  return actions
}
