// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SdcTXmtorZnS5J8fMIAx{display:flex;justify-content:space-between}.BfadQ4zrNOP_UpLl_lqS{margin-top:24px;display:grid;grid-template-columns:1fr 1fr;column-gap:24px}`, "",{"version":3,"sources":["webpack://./src/Modals/UpgradePlanModal/Step1Upgrade/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":["@import \"src/styles/mixin\";\n\n.row{\n  display: flex;\n  justify-content: space-between;\n}\n\n.cards{\n  margin-top: 24px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 24px;\n}\n.wrapInfo{\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `SdcTXmtorZnS5J8fMIAx`,
	"cards": `BfadQ4zrNOP_UpLl_lqS`
};
export default ___CSS_LOADER_EXPORT___;
