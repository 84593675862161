import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { useState } from 'react'
import { Status } from 'src/generated/graphql'
import { Switch } from 'components/Switch/Switch'
import { ActionsEnum } from 'components/Button/types'
import { TableBtn } from 'components/Button/TableBtn'
import { IIconType } from 'components/AppIcon/AppIcon'
import uiStore from 'store/uiStore'
import { ICampaign } from 'src/types/ICampaign'
import { useCampaignFunctions } from 'src/routes/main/campaigns/hooks/useCampaignFunctions'

export const CampaignSwitchBtn = (props: ActionBtnProps<ICampaign>) => {
  const [loading, setLoading] = useState(false)

  const { row, rows, medium, fromLeftStickyContent } = props
  const { onSwitch } = useCampaignFunctions(row, rows)

  const active =
    row?.status === Status.Active ||
    !!rows?.every((row) => row.status === Status.Active)

  const handleSwitch = async () => {
    try {
      setLoading(true)
      await onSwitch(!active)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const switchComponent = (
    <Switch
      active={active}
      onChange={handleSwitch}
      marginInline={8}
      disabled={loading}
    />
  )
  if (fromLeftStickyContent || uiStore.isMobile) {
    return (
      <TableBtn
        {...props}
        action={active ? ActionsEnum.turnOff : ActionsEnum.turnOn}
        onAction={handleSwitch}
        iconType={active ? IIconType.circle : IIconType.fill}
        disabled={loading}
      />
    )
  }
  if (medium) {
    return (
      <div className={'flex'}>
        <span>{active ? 'Active' : 'Disabled'}</span>
        {switchComponent}
      </div>
    )
  }
  return switchComponent
}
