import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'

export const LeftStickyContent = observer(() => {
  return (
    <div className={styles.wrapRangePicker}>
      <RangeDatePicker
        rangeDatePickerStore={
          campaignDetailStore.stepStatisticTableStore.rangeDatePickerStore
        }
      />
    </div>
  )
})
