import { nanoid } from 'nanoid'
import alertStore from 'store/alertStore'
import { useState } from 'react'

export const useHandleMemberRegisterError = (
  setCardError: (error: string) => void
) => {
  const [phoneError, setPhoneError] = useState('')
  const handleError = (error: string) => {
    if (error.includes('Number is invalid')) {
      setPhoneError('Number is invalid')
    }
    if (error.includes('Trial cannot be started.')) {
      setCardError(nanoid())
    }
    alertStore.disabledErrorAlert(error)
  }
  const clearPhoneError = () => {
    setPhoneError('')
  }
  return { handleError, phoneError, clearPhoneError }
}
