import { ReactComponent as Text } from 'icons/16px/Text.svg'
import { ReactComponent as Number } from 'icons/16px/Number.svg'
import { ReactComponent as Calendar } from 'icons/16px/Calendar.svg'
import { ReactComponent as Date } from 'icons/16px/Date & Time.svg'
import { ReactComponent as Link } from 'icons/16px/Link.svg'
import { CustomFieldType } from 'src/generated/graphql'
import { FiledTypeEnum } from 'src/enums/FiledTypeEnum'
import { OperatorsEnum } from 'src/enums/OperatorsEnum'
import { IOption } from 'src/types/IOption'

export const staticContactFieldsOptions: IOption[] = [
  {
    title: 'First Name',
    value: 'firstName',
    placeholder: 'e.g. Adam',
    data: {
      type: CustomFieldType.Text,
    },
  },
  {
    title: 'Last Name',
    value: 'lastName',
    placeholder: 'e.g. Robertson',
    data: {
      type: CustomFieldType.Text,
    },
  },
  {
    title: 'Email',
    value: 'email',
    placeholder: 'email@address.com',
    data: {
      type: CustomFieldType.Text,
    },
  },
  {
    title: 'Phone',
    value: 'phoneNumber',
    data: {
      type: FiledTypeEnum.phone,
    },
  },
]

export const optionsTypeField: IOption<CustomFieldType>[] = [
  {
    title: 'Text',
    value: CustomFieldType.Text,
    LeftIcon: Text,
    iconClass: 'gray1',
    padding: '0 0 0 8px',
  },
  {
    title: 'Number',
    value: CustomFieldType.Number,
    LeftIcon: Number,
    iconClass: 'gray1',
    padding: '0 0 0 8px',
  },
  {
    title: 'Date',
    value: CustomFieldType.Date,
    LeftIcon: Calendar,
    iconClass: 'gray1',
    padding: '0 0 0 8px',
  },
  {
    title: 'Date & Time',
    value: CustomFieldType.DateTime,
    LeftIcon: Date,
    iconClass: 'gray1',
    padding: '0 0 0 8px',
  },
  {
    title: 'URL',
    value: CustomFieldType.Url,
    LeftIcon: Link,
    iconClass: 'gray1',
    padding: '0 0 0 8px',
  },
]

export const mockFileResponse = {
  importPayload: {
    id: 8,
    createdAt: 1660204430244,
    updatedAt: null,
    memberId: 48,
    organizationId: 48,
    status: 'Pending',
    fileKey: '48/import/fa40445c-46e8-4424-b948-975ad25dfa0d.csv',
    contactsCount: 10000,
  },
  filePreview: {
    firstRow: [
      'FirstNameDemoWithNumber1',
      'LastNameDemoWithNumber1',
      'userEmailTestWithNumber1@gmail.com',
      '+1(289) 735-001',
      'custom11',
      'custom.21',
      'custom.31',
      'custom.41',
      'custom.51',
      'custom.61',
      'custom.71',
      'custom.81',
      'custom.91',
    ],
    previewRows: [
      [
        'FirstNameDemoWithNumber2',
        'LastNameDemoWithNumber2',
        'userEmailTestWithNumber2@gmail.com',
        '+1(289) 735-002',
        'custom.12',
        'custom.22',
        'custom.32',
        'custom.42',
        'custom.52',
        'custom.62',
        'custom.72',
        'custom.82',
        'custom.92',
      ],
      [
        'FirstNameDemoWithNumber3',
        'LastNameDemoWithNumber3',
        'userEmailTestWithNumber3@gmail.com',
        '+1(289) 735-003',
        'custom.13',
        'custom.23',
        'custom.33',
        'custom.43',
        'custom.53',
        'custom.63',
        'custom.73',
        'custom.83',
        'custom.93',
      ],
      [
        'FirstNameDemoWithNumber4',
        'LastNameDemoWithNumber4',
        'userEmailTestWithNumber4@gmail.com',
        '+1(289) 735-004',
        'custom.14',
        'custom.24',
        'custom.34',
        'custom.44',
        'custom.54',
        'custom.64',
        'custom.74',
        'custom.84',
        'custom.94',
      ],
      [
        'FirstNameDemoWithNumber5',
        'LastNameDemoWithNumber5',
        'userEmailTestWithNumber5@gmail.com',
        '+1(289) 735-005',
        'custom.15',
        'custom.25',
        'custom.35',
        'custom.45',
        'custom.55',
        'custom.65',
        'custom.75',
        'custom.85',
        'custom.95',
      ],
      [
        'FirstNameDemoWithNumber6',
        'LastNameDemoWithNumber6',
        'userEmailTestWithNumber6@gmail.com',
        '+1(289) 735-006',
        'custom.16',
        'custom.26',
        'custom.36',
        'custom.46',
        'custom.56',
        'custom.66',
        'custom.76',
        'custom.86',
        'custom.96',
      ],
    ],
    linesCount: 100,
  },
  fields: [
    { key: 'firstName', name: 'First Name' },
    { key: 'lastName', name: 'Last Name' },
    { key: 'email', name: 'Email' },
    { key: 'phoneNumber', name: 'Phone' },
  ],
}

export const mockCompareOperators: IOption<OperatorsEnum>[] = Object.values(
  OperatorsEnum
).map((value) => ({ title: value, value }))

export const mockCompareOperatorsMap: Map<
  string | number,
  IOption<OperatorsEnum>
> = new Map(mockCompareOperators.map((opt) => [opt.value, opt]))
