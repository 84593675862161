// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zEa390QbVvwa4qiXPPbH{padding-bottom:24px}.z1Qg0SZ9Bo0wQjNCCAVg{display:flex;column-gap:12px;margin-bottom:16px}`, "",{"version":3,"sources":["webpack://./src/routes/main/campaigns/detail/OverviewDetailTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAEF,sBACE,YAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  padding-bottom: 24px;\n}\n.wrapDate{\n  display: flex;\n  column-gap: 12px;\n  margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `zEa390QbVvwa4qiXPPbH`,
	"wrapDate": `z1Qg0SZ9Bo0wQjNCCAVg`
};
export default ___CSS_LOADER_EXPORT___;
