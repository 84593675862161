import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import mergeTokenStore from 'src/routes/main/contacts/customFields/modals/MergeTokenModal/mergeTokenStore'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { InputText } from 'components/Input/InputText/InputText'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useEffect, useState } from 'react'
import { IdFieldButton } from 'components/IdFieldButton/IdFieldButton'
import { camelCase } from 'lodash'

export const MergeTokenModal = observer(() => {
  const [name, setName] = useState('')
  const { openModal, setOpenModal, onAddPersonalize } = mergeTokenStore

  const key = `merge.${camelCase(name)}`

  const onClose = () => {
    setOpenModal(false)
  }

  const onAdd = () => {
    onAddPersonalize && onAddPersonalize(name, key)
    onClose()
  }

  useEffect(() => {
    setName('')
  }, [openModal])
  return (
    <Modal
      open={openModal}
      onClose={onClose}
      title={'Custom fields'}
      titleTooltip={'Info'}
    >
      <div className={'smallModal'}>
        <Alert
          type={AlertTypeEnum.notify2}
          paddingRight={8}
          text={[
            `Insert information in your texts to personalize a message. `,
            <a key={'link'} href={'/'} className={'par2 bold'}>
              Learn more
            </a>,
          ]}
        />
        <SizedBox height={32} />
        <InputText
          label={'Merge token'}
          placeholder={'Enter'}
          value={name}
          onChangeValue={setName}
          withoutClear
          showRightContent={true}
          rightContent={<IdFieldButton id={name.trim() && key} />}
        />
        <div className={'row12 mt40'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            disabled={!name.trim()}
            onClick={onAdd}
          >
            Add
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
