import { Button } from 'components/Button/Button'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import DatePickerLib from 'react-datepicker'
import React, { CSSProperties, FC, useState } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as AngleUp } from 'icons/16px/Angle-Up.svg'
import { ReactComponent as AngleDown } from 'icons/16px/Angle-Down.svg'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ClickAwayListener } from '@mui/material'
import { TimePickerWithoutTZ } from 'components/TimePicker/TimePickerWithoutTZ'

type Props = {
  startDate: Date | null | undefined
  onChange: (val: Date | null) => void
  label?: string
  position?: TTPositionEnum
  required?: boolean
  withoutTime?: boolean
  width?: CSSProperties['width']
}

export const MuiDateEndTimePickerWithoutTZ: FC<Props> = observer(
  ({ startDate, onChange, label, required, withoutTime, width = 312 }) => {
    const [open, setOpen] = useState(false)

    const onRemove = () => {
      onChange(null)
    }

    return (
      <div>
        <div className={styles.rowBetween}>
          {label ? (
            <div>
              <span className={'s2 medium gray1 mb8'}>{label}</span>
              &nbsp;
              {required && <span className={'s2 medium error1'}>*</span>}
            </div>
          ) : (
            <div />
          )}
          {startDate && (
            <Button onClick={onRemove}>
              <span className={'s2 bold blue3 link'}>Remove</span>
            </Button>
          )}
        </div>
        <MuiTooltip
          open={open}
          padding={0}
          width={width}
          white
          placement={'bottom-start'}
          title={
            <div>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div className={classNames(styles.wrapContent)}>
                  <DatePickerLib
                    onChange={onChange}
                    inline
                    selected={startDate}
                  />
                  {!withoutTime && (
                    <div className={styles.wrapTime}>
                      <TimePickerWithoutTZ
                        startTime={startDate}
                        onChange={onChange}
                      />
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            </div>
          }
        >
          <Button
            className={classNames(styles.dropdown, open && styles.active)}
            onClick={() => setOpen(true)}
          >
            <DatePickerLib
              id={'input-time-date-picker'}
              placeholderText={
                withoutTime ? 'Select date' : 'Select date and time'
              }
              onChange={onChange}
              dateFormat={
                withoutTime ? `MMM d, yyyy` : `MMM d, yyyy 'at' h:mm a`
              }
              selected={startDate}
              showTimeSelectOnly
              wrapperClassName={'input-time-date-picker-overlay'}
            />
            {open ? <AngleUp /> : <AngleDown />}
          </Button>
        </MuiTooltip>
      </div>
    )
  }
)
