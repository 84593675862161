// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._SGRVw2Roj1cm9opEDGd{height:100%;width:100%;overflow-y:hidden;padding-left:40px}.BrrgvLczW98epmLCYSpP{width:fit-content;display:flex;align-items:center;border-radius:4px;background:var(--primary-color-gray-3);padding:4px 8px;gap:8px}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/importHistory/ImportHistoryTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,UAAA,CACA,iBAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,sCAAA,CACA,eAAA,CACA,OAAA","sourcesContent":[".wrap{\n  height: 100%;\n  width: 100%;\n  overflow-y: hidden;\n  padding-left: 40px;\n}\n\n.file{\n  width: fit-content;\n  display: flex;\n  align-items: center;\n  border-radius: 4px;\n  background: var(--primary-color-gray-3);\n  padding: 4px 8px;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `_SGRVw2Roj1cm9opEDGd`,
	"file": `BrrgvLczW98epmLCYSpP`
};
export default ___CSS_LOADER_EXPORT___;
