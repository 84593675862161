import { ReactComponent as Drip } from 'icons/16pxNoMask/DripLogo.svg'
import { ReactComponent as HubSpotLogo } from 'icons/16pxNoMask/HubSpotLogo.svg'
import { ReactComponent as CampaignLogo } from 'icons/16pxNoMask/CampaignLogo.svg'
import { ReactComponent as KeapLogo } from 'icons/16pxNoMask/KeapLogo.svg'
import { ReactComponent as Plus } from 'icons/12px/NoMask/Plus.svg'
import { AccountIntegrationType } from 'src/generated/graphql'
import { FC } from 'react'

export const getAccountIntegrationTypeIcon = (
  type: AccountIntegrationType
): FC => {
  const obj = {
    [AccountIntegrationType.Custom]: Plus,
    [AccountIntegrationType.ActiveCampaign]: CampaignLogo,
    [AccountIntegrationType.HubSpot]: HubSpotLogo,
    [AccountIntegrationType.Keap]: KeapLogo,
    [AccountIntegrationType.Drip]: Drip,
    [AccountIntegrationType.Zapier]: Plus,
  }
  return obj[type]
}
