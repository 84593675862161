import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import segmentStore from 'store/contacts/segment/segmentStore'
import { useSegmentDetailContext } from 'src/routes/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { FilterDataType } from 'src/generated/graphql'
import { ListFilter } from 'store/contacts/segment/filters/ListFilter'
import { Filter } from 'store/contacts/segment/types'
import { TextFilter } from 'store/contacts/segment/filters/TextFilter'
import { SelectFilter } from 'store/contacts/segment/filters/SelectFilter'
import { CommonFilterProps } from 'store/contacts/segment/filters/CommonFilter'
import { OptInFilter } from 'store/contacts/segment/filters/OptInFilter'
import { EmptyFilter } from 'store/contacts/segment/filters/EmptyFilter'
import { DateFilter } from 'store/contacts/segment/filters/DateFilter'

type SelectFilterDropdownProps = {
  filter: Filter | EmptyFilter
}

export const SelectFilterDropdown = observer(
  ({ filter }: SelectFilterDropdownProps) => {
    const { fieldsOptions } = segmentStore
    const { setFilter } = useSegmentDetailContext()
    const onSelectCriteria = (
      value: string,
      data?: { dataType: FilterDataType }
    ) => {
      if (!data?.dataType) {
        return
      }
      const props: CommonFilterProps = {
        orIndex: filter.orIndex,
        andIndex: filter.andIndex,
        dataType: data?.dataType,
        field: value,
      }
      if (data?.dataType === FilterDataType.List) {
        setFilter(new ListFilter(props))
        return
      }
      if (
        data?.dataType === FilterDataType.Url ||
        data?.dataType === FilterDataType.Text ||
        data?.dataType === FilterDataType.Phone ||
        data?.dataType === FilterDataType.Number
      ) {
        setFilter(new TextFilter(props))
        return
      }
      if (value === 'OPT_IN') {
        setFilter(new OptInFilter(props))
        return
      }
      if (data?.dataType === FilterDataType.Select) {
        setFilter(new SelectFilter(props))
        return
      }
      if (
        data?.dataType === FilterDataType.Date ||
        data?.dataType === FilterDataType.DateTime
      ) {
        setFilter(new DateFilter(props))
        return
      }
    }
    return (
      <Dropdown
        options={fieldsOptions}
        selectedValue={filter.field}
        placeholder={'Select criteria'}
        onSelectValue={onSelectCriteria}
        maxHeightDropdown={220}
      />
    )
  }
)
