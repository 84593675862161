import { makeAutoObservable } from 'mobx'
import alertStore from 'store/alertStore'
import {
  GetAllNumbersQuery,
  GetNumbersPageQuery,
  PhoneNumber,
  PhoneType,
  UpdatePhoneNumbersMutation,
  VerificationStatus,
} from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'
import { numberToOption } from 'store/settings/numbers/numberToOption'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { TableStore } from 'components/NewTable/store/TableStore'
import { IPhoneNumber } from 'src/types/IPhoneNumber'
import { numbersTableColumns } from 'components/NewTable/columns/numbersTableColumns'
import modalStore from 'src/widgets/ModalContainer/store/modalStore'
import dayjs, { DayjsFormats } from 'lib/dayjs'

class NumbersStore {
  tableStore: TableStore<IPhoneNumber>
  constructor() {
    this.tableStore = new TableStore<IPhoneNumber>({
      columns: numbersTableColumns,
      tableName: 'NumbersTable',
      orderBy: 'createdAt',
      defaultHiddenColumn: ['useCase'],
    })
    makeAutoObservable(this)
  }
  numbersMap: Map<string, PhoneNumber> = new Map<string, PhoneNumber>()
  numbersUseCaseTitleMap: Map<string, string> = new Map<string, string>()
  numbersMapByFriendly: Map<string, PhoneNumber> = new Map()

  numbersPage: IPhoneNumber[] = []
  openCallForwardingPhoneNumbers: IPhoneNumber[] = []
  openReleaseNumbers: IPhoneNumber[] = []

  openVerifyCallerIdModal = false
  uuidCode = ''

  openUnverifiedNumberModal = false
  pendingVerifiedNumberModal = false

  get openCallForwardingModal() {
    return !!this.openCallForwardingPhoneNumbers.length
  }

  get openReleaseNumberModal() {
    return !!this.openReleaseNumbers.length
  }

  get isSMSNumber() {
    return !!this.smsPhoneNumbers.length
  }

  get isFirstNumber() {
    return !!this.toolFreeNumbers.length || !!this.localNumbers.length
  }

  get phoneNumbers() {
    return Array.from(this.numbersMap.values())
  }

  get nonShortCodeNumbers() {
    return this.phoneNumbers.filter(
      (phone) => phone.type !== PhoneType.ShortCode
    )
  }

  get shortCodeNumbers() {
    return this.phoneNumbers.filter(
      (phone) => phone.type === PhoneType.ShortCode
    )
  }

  get toolFreeNumbers() {
    return this.phoneNumbers.filter(
      (phone) => phone.type === PhoneType.TollFree
    )
  }
  get localNumbers() {
    return this.phoneNumbers.filter((phone) => phone.type === PhoneType.Local)
  }

  get smsPhoneNumbers() {
    return this.phoneNumbers.filter((phone) => phone.sms)
  }
  get voicePhoneNumbers() {
    return this.phoneNumbers.filter((phone) => phone.voice)
  }

  get shortCodeNumbersOptions(): IOption[] {
    if (this.shortCodeNumbers.length) {
      return this.shortCodeNumbers.map((number) => numberToOption(number))
    }
    return []
  }

  get toolFreeNumberOptions(): IOption[] {
    if (this.toolFreeNumbers.length) {
      return this.toolFreeNumbers.map((number) => numberToOption(number))
    }
    return []
  }

  get toolFreeNumberOptionsForUseCase(): IOption[] {
    if (this.toolFreeNumbers.length) {
      return this.toolFreeNumbers
        .filter(
          (number) =>
            number.verificationStatus === VerificationStatus.Unverified
        )
        .map((number) => numberToOption(number))
    }
    return []
  }

  get localNumberOptions(): IOption[] {
    if (this.localNumbers.length) {
      return this.localNumbers.map((number) => numberToOption(number))
    }
    return []
  }

  get numberOptions(): IOption[] {
    if (this.phoneNumbers.length) {
      return this.phoneNumbers.map((number) => numberToOption(number))
    }
    return []
  }

  get phonesWithGlobalNumbers(): IOption[] {
    let phones: IOption[] = []
    if (this.shortCodeNumbersOptions) {
      phones = phones.concat(this.shortCodeNumbersOptions)
    }
    if (this.toolFreeNumberOptions) {
      phones = phones.concat(this.toolFreeNumberOptions)
    }
    return phones
  }

  get smsPhoneNumbersOptions(): IOption[] {
    return this.smsPhoneNumbers.map((number) => numberToOption(number, true))
  }
  get smsPhoneNumbersVerificationsOptions(): IOption[] {
    return this.smsPhoneNumbersOptions.filter(
      (number) => number.data.verificationStatus === VerificationStatus.Verified
    )
  }
  get smsPhoneNumbersOptionsNoShortCode() {
    return this.smsPhoneNumbersOptions.filter(
      (opt) => opt.group !== PhoneType.ShortCode
    )
  }
  get voicePhoneNumbersOptions(): IOption[] {
    return this.voicePhoneNumbers.map((number) => numberToOption(number))
  }

  setNumbers = (numbers: PhoneNumber[]) => {
    numbers.map((number) => {
      this.numbersMap.set(number.id || '', number)
      this.numbersMapByFriendly.set(number.friendlyName || '', number)
    })
    this.tableStore.onRefresh()
  }

  setAllNumbersData = (data: GetAllNumbersQuery) => {
    const numbers: PhoneNumber[] = []
    data.getAllNumbers?.forEach((numberModel) => {
      if (numberModel) {
        const { phoneNumber, useCaseTitle } = numberModel
        if (useCaseTitle && phoneNumber?.id) {
          this.numbersUseCaseTitleMap.set(phoneNumber.id, useCaseTitle)
        }
        if (phoneNumber) {
          numbers.push({ id: phoneNumber.id, ...phoneNumber })
        }
      }
    })
    this.setNumbers(numbers)
  }
  setVerifiedNumber = (phone: PhoneNumber) => {
    this.numbersMap.set(phone.id || '', phone)
    this.numbersMapByFriendly.set(phone.friendlyName || '', phone)
    alertStore.setAlert({
      type: AlertTypeEnum.success,
      title: `Number ${phone.friendlyName} is verified`,
    })
  }
  setNumbersPage = (data: GetNumbersPageQuery) => {
    this.tableStore.setTotal(data.getNumbersPage?.total || 0)
    const numbersPage: IPhoneNumber[] = []
    data.getNumbersPage?.content?.forEach((numberModel) => {
      if (numberModel) {
        const { phoneNumber, useCaseTitle } = numberModel
        if (useCaseTitle && phoneNumber?.id) {
          this.numbersUseCaseTitleMap.set(phoneNumber.id, useCaseTitle)
        }
        numbersPage.push({ id: phoneNumber?.id, ...phoneNumber })
      }
    })
    this.numbersPage = numbersPage
  }

  setOpenCallForwardingModal = (numbers: IPhoneNumber[]) => {
    this.openCallForwardingPhoneNumbers = numbers
  }
  setOpenReleaseNumberModal = (numbers: IPhoneNumber[]) => {
    this.openReleaseNumbers = numbers
  }
  onRelease = () => {
    alertStore.setAlert({
      title: `Number ${this.openReleaseNumbers[0].friendlyName} released`,
      type: AlertTypeEnum.success,
    })
    this.openReleaseNumbers = []
    this.tableStore.setCheckedRows([])
    this.tableStore.onRefresh()
  }
  onCallForward = (data: UpdatePhoneNumbersMutation) => {
    this.openCallForwardingPhoneNumbers = []
    data.updatePhoneNumbers &&
      this.setNumbers(data.updatePhoneNumbers as PhoneNumber[])
    this.tableStore.setCheckedRows([])
  }

  setOpenVerifyCallerIdModal = (value: boolean) => {
    this.openVerifyCallerIdModal = value
  }

  setUuidCode = (code: string) => {
    this.uuidCode = code
  }

  setOpenUnverifiedNumberModal = (value: boolean) => {
    this.openUnverifiedNumberModal = value
  }
  openPendingVerifiedNumberModal = (date: string) => {
    modalStore.addModal({
      infoModalProps: {
        title: 'Toll-Free number in carrier review',
        subTitle: `Please allow 3-6 days for carriers to review your number. Submitted on ${dayjs(
          date
        ).format(DayjsFormats.fullWithAt2)}.`,
      },
    })
  }
}

export default new NumbersStore()
