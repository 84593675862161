// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ib9Boe3UPBm1i2yAPZLQ{display:grid;grid-template-columns:180px minmax(300px, auto);align-items:center;border-bottom:1px solid var(--primary-color-gray-3)}.ib9Boe3UPBm1i2yAPZLQ .JgIE8sHp4eS9pPRcexQg{padding:8px}.ib9Boe3UPBm1i2yAPZLQ .XWAEnPujV6iwNQniSsv0{padding-block:8px}`, "",{"version":3,"sources":["webpack://./src/routes/superAdmin/routes/organization/info/Row/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+CAAA,CACA,kBAAA,CAEA,mDAAA,CACA,4CACE,WAAA,CAEF,4CACE,iBAAA","sourcesContent":[".row{\n  display: grid;\n  grid-template-columns: 180px minmax(300px, auto);\n  align-items: center;\n  //width: 600px;\n  border-bottom: 1px solid var(--primary-color-gray-3);\n  .right{\n    padding: 8px;\n  }\n  .left{\n    padding-block: 8px;\n    //border-right: 1px solid var(--primary-color-gray-3);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `ib9Boe3UPBm1i2yAPZLQ`,
	"right": `JgIE8sHp4eS9pPRcexQg`,
	"left": `XWAEnPujV6iwNQniSsv0`
};
export default ___CSS_LOADER_EXPORT___;
