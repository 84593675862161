import styles from 'src/routes/main/dashboard/Performance/PerformanceChart/styles.module.scss'
import { numberString } from 'src/util/functions'
import { ReactComponent as UpArrow } from 'icons/12px/UpArrow.svg'
import { SizedBox } from 'components/SizedBox'
import { FC } from 'react'

type Props = {
  info: {
    count: number
    profit: number
    label: string
  }
}

export const ChartInfo: FC<Props> = ({ info }) => {
  return (
    <>
      <div className={styles.success}>
        <h2 className={'regular'}>{numberString({ val: info.count })}</h2>
        {!!info.profit && (
          <div className={styles.improve}>
            <UpArrow />
            <span className={'success1'}>{info.profit}%</span>
          </div>
        )}
      </div>
      <SizedBox height={8} />
      <span>{info.label}</span>
      <SizedBox height={40} />
    </>
  )
}
