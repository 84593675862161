// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ySclMvBg4360NoWcNOG5{margin:0 auto;background-color:var(--primary-color-white);width:100%;box-shadow:var(--shadow-2);border-radius:20px;padding:32px}.ySclMvBg4360NoWcNOG5.iKMnunQrEYhvlQ9A6O4r{border-radius:0}@media(max-width: 800px){.ySclMvBg4360NoWcNOG5{padding:24px}}@media(max-height: 600px){.ySclMvBg4360NoWcNOG5{padding:24px}}`, "",{"version":3,"sources":["webpack://./src/components/Card/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,aAAA,CACA,2CAAA,CACA,UAAA,CACA,0BAAA,CACA,kBAAA,CACA,YAAA,CACA,2CACE,eAAA,CAEF,yBAVF,sBAWI,YAAA,CAAA,CAEF,0BAbF,sBAcI,YAAA,CAAA","sourcesContent":["@import \"src/styles/mixin\";\n.card {\n  margin: 0 auto;\n  background-color: var(--primary-color-white);\n  width: 100%;\n  box-shadow: var(--shadow-2);\n  border-radius: 20px;\n  padding: 32px;\n  &.noMobileRadius{\n    border-radius: 0;\n  }\n  @media (max-width: 800px) {\n    padding: 24px;\n  }\n  @media (max-height: 600px) {\n    padding: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ySclMvBg4360NoWcNOG5`,
	"noMobileRadius": `iKMnunQrEYhvlQ9A6O4r`
};
export default ___CSS_LOADER_EXPORT___;
