// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.giRT0AX7URlb5N9tkwBV{background:var(--primary-color-white);width:100%;height:100%;display:grid;grid-template-rows:max-content 1fr}.LR1WzJ002fr_y3rR1DBs{overflow:auto;display:grid;grid-template-columns:1fr 440px;height:100%}`, "",{"version":3,"sources":["webpack://./src/routes/main/dashboard/Objectives/ActiveObjective/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,qCAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,kCAAA,CAEF,sBACE,aAAA,CACA,YAAA,CACA,+BAAA,CACA,WAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrap{\n  background: var(--primary-color-white);\n  width: 100%;\n  height: 100%;\n  display: grid;\n  grid-template-rows: max-content 1fr;\n}\n.content{\n  overflow: auto;\n  display: grid;\n  grid-template-columns: 1fr 440px;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `giRT0AX7URlb5N9tkwBV`,
	"content": `LR1WzJ002fr_y3rR1DBs`
};
export default ___CSS_LOADER_EXPORT___;
