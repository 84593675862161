import { observer } from 'mobx-react-lite'
import styles from 'components/Schedule/styles.module.scss'
import { ScheduleBtn } from 'components/Schedule/ScheduleBtn'
import { CampaignDelayType } from 'src/generated/graphql'
import { CampaignScheduleStore } from 'components/Schedule/store/campaignScheduleStore'
import { ContactDateFieldContent } from 'components/Schedule/ContactDateFieldCard/ContactDateFieldContent/ContactDateFieldContent'

export const ContactDateFieldCard = observer(
  ({ scheduleStore }: { scheduleStore: CampaignScheduleStore }) => {
    const onClick = () => {
      scheduleStore.setSendTypeCampaign(CampaignDelayType.DelayCustomField)
    }
    return (
      <div className={styles.row}>
        <ScheduleBtn
          active={scheduleStore.activeContactDateField}
          title={'Contact Date Field'}
          onClick={onClick}
        />
        <ContactDateFieldContent scheduleStore={scheduleStore} />
      </div>
    )
  }
)
