export const SIZE_LIMIT_5_MB = 5242880

export const REQUIRED_COMPLIANCE_TEXT = 'Reply STOP to end. '
export const NOT_REQUIRED_COMPLIANCE_TEXT =
  'HELP for help. Msg freq will vary. Msg&Data rates may apply.'
export const COMPLIANCE_TEXT =
  REQUIRED_COMPLIANCE_TEXT + NOT_REQUIRED_COMPLIANCE_TEXT

export const REQUIRED_DOUBLE_OPT_IN_TEXT = 'Reply YES or Y to confirm.'
export const NOT_REQUIRED_DOUBLE_OPT_IN_TEXT =
  'Thank you for subscribing to Call Loop. '
export const DOUBLE_OPT_IN_TEXT =
  NOT_REQUIRED_DOUBLE_OPT_IN_TEXT + REQUIRED_DOUBLE_OPT_IN_TEXT

export const PERCENT_BONUS_CREDITS = '15%'
