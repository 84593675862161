// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ivPe379QBo1UFyuZfHLA{padding:16px;margin-top:24px;border:1px solid var(--primary-color-gray-3);border-radius:8px}.ivPe379QBo1UFyuZfHLA.xKkqfaAg7dod4P9Jh_2i{padding:24px;margin:0;border:none}.mUSYJMpPzSDnjFVtRF4A{display:grid;grid-template-columns:1fr 32px;align-items:center;gap:12px}.ariugIMoxIvs6nWDvd1A{margin-top:8px;margin-left:1px}.yM0YbX4LSSL9O5o3Tlg7{margin-top:24px;display:grid;justify-content:space-between;grid-template-columns:1fr 1fr;align-items:center;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/components/AutoRecahrge/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,4CAAA,CACA,iBAAA,CACA,2CACE,YAAA,CACA,QAAA,CACA,WAAA,CAGJ,sBACE,YAAA,CACA,8BAAA,CACA,kBAAA,CACA,QAAA,CAEF,sBACE,cAAA,CACA,eAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,6BAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".autoRecharge{\n  padding: 16px;\n  margin-top: 24px;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 8px;\n  &.onlyContent{\n    padding: 24px;\n    margin: 0;\n    border: none;\n  }\n}\n.row{\n  display: grid;\n  grid-template-columns: 1fr 32px;\n  align-items: center;\n  gap: 12px;\n}\n.wrapAutoText{\n  margin-top: 8px;\n  margin-left: 1px;\n}\n.rowGrid{\n  margin-top: 24px;\n  display: grid;\n  justify-content: space-between;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n  column-gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoRecharge": `ivPe379QBo1UFyuZfHLA`,
	"onlyContent": `xKkqfaAg7dod4P9Jh_2i`,
	"row": `mUSYJMpPzSDnjFVtRF4A`,
	"wrapAutoText": `ariugIMoxIvs6nWDvd1A`,
	"rowGrid": `yM0YbX4LSSL9O5o3Tlg7`
};
export default ___CSS_LOADER_EXPORT___;
