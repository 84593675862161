import styles from './styles.module.scss'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { Button } from 'components/Button/Button'
import { ReactComponent as Question } from 'icons/16px/Question.svg'
import { ReactComponent as Phone } from 'icons/16pxNoMask/Phone.svg'
import { ReactComponent as Web } from 'icons/16pxNoMask/Web.svg'
import { ReactComponent as Mail } from 'icons/16pxNoMask/Mail.svg'
import { ReactComponent as Marker } from 'icons/16pxNoMask/Marker.svg'
import { Avatar } from 'components/Avatar/Avatar'
import { SizedBox } from 'components/SizedBox'
import vCardStore from 'store/settings/vCardStore'
import { ColorsValues } from 'styles/variables'
import { MoreBtn } from 'components/Button/MoreBtn'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import createVCardStore from 'store/settings/createVCardStore'
import {
  ContactCardFragment,
  useRemoveContactCardsMutation,
} from 'src/generated/graphql'
import { SocialMediaEnums } from 'src/enums/SocialMediaEnums'
import { SocialMediaItem } from 'src/routes/settings/account/routes/MyProfile/VirtualContactCard/FullCardContent/SocialMediaItem'

export const FullCardContent = ({ vCard }: { vCard: ContactCardFragment }) => {
  const [removeContactCards] = useRemoveContactCardsMutation()
  const MenuItemEdit = ({ onCloseTT }: { onCloseTT?: () => void }) => {
    const onClick = () => {
      createVCardStore.openModal(vCard)
      onCloseTT && onCloseTT()
    }
    return <MenuBtn type={MenuEnum.edit} onClick={onClick} />
  }
  const MenuItemDelete = ({ onCloseTT }: { onCloseTT?: () => void }) => {
    const onClick = async () => {
      try {
        await removeContactCards({
          variables: {
            cardIds: [vCard.id],
          },
        })
        vCardStore.removeCard(vCard.id)
        onCloseTT && onCloseTT()
      } catch (e) {
        console.error(e)
      }
    }
    return <DeleteBtn onClick={onClick} />
  }

  const isSocialMedia = !!(
    vCard.facebookUrl ||
    vCard.twitterUrl ||
    vCard.linkedinUrl ||
    vCard.instagramUrl ||
    vCard.youtubeUrl
  )

  const addressText = vCard.address
    ? `${vCard.address.street || ''} ${vCard.address.city || ''} ${
        vCard.address.state || ''
      } ${vCard.address.zipCode || ''} ${
        vCard.address.country?.countryName || ''
      }`.trim()
    : ''

  return (
    <div className={styles.cardFull}>
      <div className={styles.header}>
        <div className={styles.rowTitle}>
          <p className={'par2 bold'}>Virtual Contact Card</p>
          <MuiTooltip
            title={
              'Send your business card over SMS so your recipients can easily access your contact information'
            }
            arrow
            placement={'top'}
          >
            <div>
              <Button className={styles.question}>
                <Question />
              </Button>
            </div>
          </MuiTooltip>
        </div>
        <MoreBtn menuItems={[MenuItemEdit, MenuItemDelete]} />
      </div>
      <div className={styles.content}>
        <div className={styles.nameBlock}>
          <Avatar
            firstName={vCard.name?.split(' ')[0] || ''}
            lastName={vCard.name?.split(' ')[1] || ''}
            color={ColorsValues.warning4}
          />
          <div className={styles.nameWrap}>
            <p className={'bold'}>{vCard.name}</p>
            <SizedBox height={4} />
            <span className={'s2 gray1'}>{vCard.tagline}</span>
          </div>
        </div>
        <div className={styles.infoBlock}>
          <div className={styles.infoItem}>
            <div className={styles.svg}>
              <Phone />
            </div>
            <span className={'s2'}>{vCard.formattedPhoneNumber}</span>
          </div>
          {vCard.website && (
            <div className={styles.infoItem}>
              <div className={styles.svg}>
                <Web />
              </div>
              <span className={'s2'}>{vCard.website}</span>
            </div>
          )}
          {vCard.email && (
            <div className={styles.infoItem}>
              <div className={styles.svg}>
                <Mail />
              </div>

              <span className={'s2'}>{vCard.email}</span>
            </div>
          )}
          {addressText && (
            <div className={styles.infoItem}>
              <div className={styles.svg}>
                <Marker />
              </div>
              <span className={'s2'}>{addressText}</span>
            </div>
          )}
        </div>
      </div>
      {isSocialMedia && (
        <div className={styles.footer}>
          {vCard.facebookUrl && (
            <SocialMediaItem
              type={SocialMediaEnums.Facebook}
              url={vCard.facebookUrl}
            />
          )}
          {vCard.twitterUrl && (
            <SocialMediaItem
              type={SocialMediaEnums.Twitter}
              url={vCard.twitterUrl}
            />
          )}
          {vCard?.instagramUrl && (
            <SocialMediaItem
              type={SocialMediaEnums.Instagram}
              url={vCard.instagramUrl}
            />
          )}
          {vCard.linkedinUrl && (
            <SocialMediaItem
              type={SocialMediaEnums.Linkedin}
              url={vCard.linkedinUrl}
            />
          )}
          {vCard.youtubeUrl && (
            <SocialMediaItem
              type={SocialMediaEnums.Youtube}
              url={vCard.youtubeUrl}
            />
          )}
        </div>
      )}
    </div>
  )
}
