import { observer } from 'mobx-react-lite'
import companyStore from 'store/settings/company/companyStore'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Modal } from 'components/Modal/Modal'

export const SuccessValidateModal = observer(() => {
  const onClose = () => {
    companyStore.setSuccessValidateModal(false)
    if (!companyStore.phoneCheckerEnabled) {
      companyStore.setTurnOnAutoRechargeModal('phoneChecker')
    }
  }
  const onDone = () => {
    onClose()
  }
  return (
    <Modal
      open={companyStore.successValidateModal}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon />
        <h3>PhoneCheckr is checking the phone numbers</h3>
        <p className={'par2 gray1'}>
          Be patient, processing time depends on the phone numbers volume.
        </p>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onDone}
          fullWidth
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
