// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gER2xKwlnobsIz4BLhGJ{margin:0;width:32px;height:20px;background-color:var(--primary-color-gray-3);border-radius:12px;appearance:none;-webkit-appearance:none;outline:none;position:relative;cursor:pointer}.gER2xKwlnobsIz4BLhGJ:disabled{cursor:unset}.gER2xKwlnobsIz4BLhGJ:disabled:checked{background-color:var(--primary-color-blue-1-disabled)}.gER2xKwlnobsIz4BLhGJ:before{transition:.3s;content:"";position:absolute;left:4px;top:4px;width:12px;height:12px;border-radius:100px;background-color:var(--primary-color-white)}.gER2xKwlnobsIz4BLhGJ:checked{background-color:var(--primary-color-blue-1)}.gER2xKwlnobsIz4BLhGJ:checked:before{left:16px;top:4px}`, "",{"version":3,"sources":["webpack://./src/components/Switch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,UAAA,CACA,WAAA,CACA,4CAAA,CACA,kBAAA,CACA,eAAA,CACA,uBAAA,CACA,YAAA,CACA,iBAAA,CACA,cAAA,CACA,+BACE,YAAA,CACA,uCACE,qDAAA,CAGJ,6BACE,cAAA,CACA,UAAA,CACA,iBAAA,CACA,QAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CACA,2CAAA,CAEF,8BACE,4CAAA,CACA,qCACE,SAAA,CACA,OAAA","sourcesContent":[".switch{\n  margin: 0;\n  width: 32px;\n  height: 20px;\n  background-color: var(--primary-color-gray-3);\n  border-radius: 12px;\n  appearance: none;\n  -webkit-appearance: none;\n  outline: none;\n  position: relative;\n  cursor: pointer;\n  &:disabled{\n    cursor: unset;\n    &:checked{\n      background-color: var(--primary-color-blue-1-disabled);\n    }\n  }\n  &:before{\n    transition: .3s;\n    content: '';\n    position: absolute;\n    left: 4px;\n    top: 4px;\n    width: 12px;\n    height: 12px;\n    border-radius: 100px;\n    background-color: var(--primary-color-white);\n  }\n  &:checked{\n    background-color: var(--primary-color-blue-1);\n    &:before{\n      left: 16px;\n      top: 4px;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `gER2xKwlnobsIz4BLhGJ`
};
export default ___CSS_LOADER_EXPORT___;
