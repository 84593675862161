import { makeAutoObservable } from 'mobx'
import { api } from 'src/apiRest/api'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

class ShortenLinkStore {
  constructor() {
    makeAutoObservable(this)
  }

  shortUrlDomains: string[] = []

  setShortUrlDomains = (shortUrlDomains?: Array<string | null> | null) => {
    this.shortUrlDomains = []
    shortUrlDomains?.forEach((domain) => {
      if (domain) {
        this.shortUrlDomains.push(domain)
      }
    })
  }

  getShortenLink = async (originalUrl: string, withError?: boolean) => {
    try {
      const { data } = await api.getShortenLink(originalUrl)
      return data.shortUrl
    } catch (e) {
      if (withError) {
        throw e
      } else {
        if (e instanceof Error)
          alertStore.setAlert({
            type: AlertTypeEnum.error,
            text: 'Invalid url provided',
          })
        console.error(e)
      }
    }
  }

  isShorten = (value: string) => {
    const split = value.split('/')
    if (split.length > 1) {
      return this.shortUrlDomains.includes(split[0])
    }
    return false
  }
}

export default new ShortenLinkStore()
