import { makeAutoObservable } from 'mobx'
import { IObjective } from 'store/dashboard/objectives/types'
import { objectives } from 'store/dashboard/objectives/data'

class ObjectivesStore {
  constructor() {
    makeAutoObservable(this)
  }
  objectivesMap: Map<number, IObjective> = new Map<number, IObjective>(
    objectives.map((item) => [item.step, item])
  )
  get objectives() {
    return Array.from(this.objectivesMap.values())
  }
  changeObjective(objective: IObjective) {
    this.objectivesMap.set(objective.step, objective)
  }
}

export default new ObjectivesStore()
