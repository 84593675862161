import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { SizedBox } from 'components/SizedBox'
import { Card } from 'components/Card/Card'
import React, { useEffect, useMemo, useState } from 'react'
import {
  usePaymentRegistrationSelectPricesQuery,
  usePaymentRegistrationStepMutation,
} from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { useCardDropdownOptions } from 'src/routes/settings/billing/components/hooks/useCardDropdownOptions'
import { observer } from 'mobx-react-lite'
import migrationStore from 'store/migration/migrationStore'
import { MigrationBonusAlert } from 'src/routes/auth/migration/components/MigrationBonusAlert'
import styles from './styles.module.scss'
import { PaymentDetailCard } from 'src/widgets/SubscriptionDetailCard/PaymentDetailCard'
import { PrimaryCardDropdown } from 'src/routes/settings/billing/components/PrimaryCardDropdown'
import { AutoRechargeCard } from 'components/AutoRecahrge/AutoRechargeCard'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import billingStore from 'store/settings/billing/billingStore'
import auth from 'store/auth'
import user from 'store/user/user'
import alertStore from 'store/alertStore'
import { BackMigrationBtn } from 'src/routes/auth/migration/BackMigrationBtn'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'
import { Currencies, numberString } from 'src/util/functions'

export const PaymentStep = observer(() => {
  const { data, loading, refetch } = usePaymentRegistrationSelectPricesQuery()
  const [selectedValue, onSelectValue] = useCardDropdownOptions()
  const [uiLoading, setUiLoading] = useState(false)

  const {
    withExpiredCredits,
    planTitle,
    creditsIncluded,
    additionalCreditPrice,
    totalPriceValue,
    oldCreditsForPurchase,
    oldCreditsFree,
    planName,
    activePeriod,
    setActivePeriod,
    oldMigrationSubscription,
    isPayAsYouGo,
    annualPrice,
    monthlyPrice,
    savedMoney,
    bonusCredits,
    saveCredits,
    bonusPriceValue,
    bonusAdditionalCreditPrice,
    allBonusCredits,
  } = migrationStore

  const [paymentRegistrationStep, { loading: loadingMigration }] =
    usePaymentRegistrationStepMutation()

  const handleUpgrade = async (cardId?: string) => {
    if (!planName) return
    try {
      const { data } = await paymentRegistrationStep({
        variables: {
          price: planName,
          paymentMethodId: cardId || selectedValue,
        },
      })
      if (data?.paymentRegistrationStep) {
        auth.setRefetchMainInfo()
        user.setMember(data.paymentRegistrationStep)
      }
    } catch (e) {
      billingStore.setUpgradeCardId(cardId || selectedValue || '')
      if (e instanceof Error) {
        alertStore.disabledErrorAlert(e.message)
      }
      console.error(e)
    } finally {
      setUiLoading(false)
    }
  }

  const onAddCard = () => {
    billingStore.setAddActionNewCardCb(handleUpgrade)
    billingStore.newCardElement?.click()
  }

  const onConfirm = () => {
    setUiLoading(true)
    if (billingStore.showNewCard) {
      onAddCard()
    } else {
      handleUpgrade()
    }
  }

  useEffect(() => {
    if (data?.paymentRegistrationSelectPrices) {
      migrationStore.setPaymentRegistrationSelectPricesQuery(data)
    }
  }, [data])
  useEffect(() => {
    billingStore.clearCardElement()
    refetch()
  }, [])

  const alert = useMemo(() => {
    if (
      migrationStore.memberRegistration?.oldSubscription &&
      migrationStore.memberRegistration?.selectedPriceId &&
      saveCredits
    ) {
      return <MigrationBonusAlert />
    }
    if (isPayAsYouGo) {
      return (
        <Alert
          type={AlertTypeEnum.warning}
          title={'Pay & Go (Lite) will be discontinued in 30 days'}
          text={
            'If you choose to keep this plan, after 30 days, you’ll be upgraded to the Pro Monthly 250, $15/month.'
          }
        />
      )
    }
    return null
  }, [
    migrationStore.memberRegistration?.selectedPriceId,
    migrationStore.oldMigrationSubscription,
    isPayAsYouGo,
  ])

  const billingContent = () => {
    if (saveCredits) {
      return (
        <InputCheckbox
          label={'Pay Annually'}
          checked={activePeriod === PlanPeriodsEnum.Annual}
          onChecked={() => setActivePeriod(PlanPeriodsEnum.Annual)}
          rightIcon={
            <div className={styles.rightInputContent}>
              {savedMoney > 0 && (
                <div className={styles.save}>
                  <span className={'s2 success1'}>
                    {isPayAsYouGo
                      ? '50% off'
                      : `Save ${numberString({
                          val: savedMoney,
                          currency: Currencies.usd,
                          maximumFractionDigits: 0,
                        })}`}
                  </span>
                </div>
              )}
              <span className={'nowrap'}>
                <span className={'bold'}>
                  {numberString({
                    val: bonusPriceValue,
                    currency: Currencies.usd,
                    maximumFractionDigits: 0,
                  })}
                </span>
                <span>/month billed annually</span>
              </span>
            </div>
          }
          radio
        />
      )
    }
    if (isPayAsYouGo) {
      return (
        <InputCheckbox
          label={'Pay Monthly'}
          checked={true}
          onChecked={() => setActivePeriod(PlanPeriodsEnum.Monthly)}
          rightIcon={
            <div className={styles.rightInputContent}>
              <span className={'nowrap'}>
                <span className={'bold'}>
                  {numberString({
                    val: monthlyPrice?.priceValue || 0,
                    currency: Currencies.usd,
                    maximumFractionDigits: 0,
                  })}
                </span>
                <span>/month</span>
              </span>
            </div>
          }
          radio
        />
      )
    }
    return (
      <>
        {monthlyPrice?.priceValue && !!oldMigrationSubscription && (
          <InputCheckbox
            label={'Pay Monthly'}
            checked={activePeriod === 'Monthly'}
            onChecked={() => setActivePeriod(PlanPeriodsEnum.Monthly)}
            rightIcon={
              <div className={styles.rightInputContent}>
                <span className={'nowrap'}>
                  <span className={'bold'}>
                    {numberString({
                      val: monthlyPrice?.priceValue || 0,
                      currency: Currencies.usd,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                  <span>/month</span>
                </span>
              </div>
            }
            radio
          />
        )}
        <InputCheckbox
          label={'Pay Annually'}
          checked={activePeriod === PlanPeriodsEnum.Annual || isPayAsYouGo}
          onChecked={() =>
            !isPayAsYouGo && setActivePeriod(PlanPeriodsEnum.Annual)
          }
          rightIcon={
            <div className={styles.rightInputContent}>
              {savedMoney > 0 && (
                <div className={styles.save}>
                  <span className={'s2 success1'}>
                    {isPayAsYouGo
                      ? '50% off'
                      : `Save ${numberString({
                          val: savedMoney,
                          currency: Currencies.usd,
                          maximumFractionDigits: 0,
                        })}`}
                  </span>
                </div>
              )}
              <span className={'nowrap'}>
                <span className={'bold'}>
                  {numberString({
                    val: (annualPrice?.priceValue || 0) / 12,
                    currency: Currencies.usd,
                    maximumFractionDigits: 0,
                  })}
                </span>
                <span>/month billed annually</span>
              </span>
            </div>
          }
          radio
        />
      </>
    )
  }

  if (loading) {
    return <AbsoluteLoader />
  }
  return (
    <Card width={800}>
      <h3 className={'bold mb32'}>Payment</h3>
      {alert && (
        <>
          {alert}
          <SizedBox height={32} />
        </>
      )}

      <div className={styles.wrap}>
        <div>
          <span className={'s2 medium gray1 mb8'}>Billing</span>
          <div className={'col8'}>{billingContent()}</div>
          <SizedBox height={40} />
          <PrimaryCardDropdown
            selectedValue={selectedValue}
            onSelectValue={onSelectValue}
            onFinish={() => setUiLoading(false)}
            withNewCard={true}
          />
          <AutoRechargeCard planName={planName || ''} fromUpgrade />
        </div>
        <PaymentDetailCard
          planTitle={planTitle || ''}
          creditsIncluded={creditsIncluded}
          isPerCredit={withExpiredCredits && saveCredits}
          additionalCreditPrice={
            withExpiredCredits && saveCredits
              ? bonusAdditionalCreditPrice
              : additionalCreditPrice
          }
          creditsMigrated={
            withExpiredCredits && saveCredits && oldCreditsForPurchase
          }
          unexpiredCredits={oldCreditsFree}
          totalCredits={withExpiredCredits && saveCredits && allBonusCredits}
          totalPriceValue={totalPriceValue}
          bonusCredits={bonusCredits}
        />
      </div>
      <div className={'row12'}>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onConfirm}
          disabled={
            !planName || billingStore.showNewCard
              ? !billingStore.readyNewCard
              : !selectedValue || selectedValue === billingStore.upgradeCardId
          }
          loading={loading || uiLoading || loadingMigration}
        >
          {migrationStore.memberRegistration?.selectedPriceId
            ? `Upgrade to ${planTitle}`
            : 'Confirm'}
        </Button>
        <BackMigrationBtn />
      </div>
    </Card>
  )
})
