import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import { FC } from 'react'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import billingStore from 'store/settings/billing/billingStore'

interface Props extends IBottomDropdownButtonProps {
  onAction: () => void
  withAddBtn?: boolean
}

export const AddNewCard: FC<Props> = ({ onClose, onAction, withAddBtn }) =>
  billingStore.cardsOptions.length > 2 && !withAddBtn ? (
    <></>
  ) : (
    <Button
      size={BtnSize.small}
      menuBtn
      hoverGray
      onClick={() => {
        onClose && onClose()
        onAction()
      }}
    >
      <Plus />
      <span className={'bold blue1'}>Add new card</span>
    </Button>
  )
