// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.njGwhceCimDfwwfzKlra{width:100%;height:100%;padding:64px 64px 0 64px;background-color:var(--primary-color-white);overflow:scroll}@media(max-width: 800px){.njGwhceCimDfwwfzKlra{padding:0}}@media(max-height: 600px){.njGwhceCimDfwwfzKlra{padding:0}}`, "",{"version":3,"sources":["webpack://./src/routes/settings/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,wBAAA,CACA,2CAAA,CACA,eAAA,CAEA,yBAPF,sBAQI,SAAA,CAAA,CAEF,0BAVF,sBAWI,SAAA,CAAA","sourcesContent":[".wrap{\n  width: 100%;\n  height: 100%;\n  padding: 64px 64px 0 64px;\n  background-color: var(--primary-color-white);\n  overflow: scroll;\n\n  @media (max-width: 800px) {\n    padding: 0;\n  }\n  @media (max-height: 600px) {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `njGwhceCimDfwwfzKlra`
};
export default ___CSS_LOADER_EXPORT___;
