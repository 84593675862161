import styles from './styles.module.scss'
import parse from 'html-react-parser'
import React, { FC } from 'react'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as More } from 'icons/16px/More.svg'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { AttachmentGallery } from 'components/AttachmentGallery/AttachmentGallery'
import { ClassesEnum } from 'styles/classes'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import messageTemplateStore from 'src/widgets/MessageTemplate/store/MessageTemplateStore'
import { IMessageTemplate } from 'src/widgets/MessageTemplate'
import { textToHtml } from 'src/util/functions'
import { Asset, ContactCard } from 'src/generated/graphql'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { observer } from 'mobx-react-lite'

type Props = {
  withoutMore?: boolean
  store: CreateStoreTypes
  template: IMessageTemplate
}

export const TemplateCard: FC<Props> = observer(
  ({ template, withoutMore, store }) => {
    const { smsMessage, id, name } = template
    const { trigger, showTT, setTrigger, onTriggerClick, setShowTT } =
      useFixedTooltip()

    const { onClose, editeMyTemplate, deleteMyTemplate } = messageTemplateStore

    const onCardClick = () => {
      onClose()
      store.textareaStore.setSmsMessage(smsMessage)
    }

    const onEdit = (e: React.MouseEvent) => {
      e.stopPropagation()
      setShowTT(false)
      editeMyTemplate(template)
    }
    const onDelete = () => {
      setShowTT(false)
      deleteMyTemplate(template.id)
    }

    return (
      <div
        key={id}
        className={styles.card}
        onClick={onCardClick}
        aria-hidden={true}
      >
        <div className={styles.header}>
          <span className={'bold'}>{name}</span>
          <FixedTooltip
            trigger={trigger}
            visible={showTT}
            white
            noArrow
            width={118}
            position={TTPositionEnum.topRight}
            noOpacity
            addLeftPosition={8}
            globalClasses={[ClassesEnum.p4]}
          >
            <MenuBtn type={MenuEnum.edit} onClick={onEdit} />
            <DeleteBtn onClick={onDelete} stopPropagation />
          </FixedTooltip>
          <FixedTooltip
            text={'Settings'}
            trigger={trigger}
            hover
            disableVisible={showTT}
            globalClasses={[ClassesEnum.p4]}
          />
          {!withoutMore && (
            <div ref={setTrigger}>
              <Button
                hoverGray2
                size={BtnSize.small}
                onClick={(e) => {
                  e.stopPropagation()
                  onTriggerClick()
                }}
                active={showTT}
              >
                <More />
              </Button>
            </div>
          )}
        </div>
        <p className={'par2'}>{parse(textToHtml(smsMessage?.text || ''))}</p>
        {(!!smsMessage?.messageAssets?.length ||
          !!smsMessage?.messageCards?.length) && (
          <div className={styles.wrapGallery}>
            <AttachmentGallery
              attachImages={
                smsMessage?.messageAssets
                  ?.map((item) => item?.asset)
                  .filter(Boolean) as Asset[]
              }
              small
              vCards={
                (smsMessage.messageCards
                  ?.map((item) => item?.contactCard)
                  ?.filter(Boolean) as ContactCard[]) || []
              }
              stopPropagation
            />
          </div>
        )}
      </div>
    )
  }
)
