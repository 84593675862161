// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n86t0WXrht4Ms5iSeF5F{margin-right:12px}.OrIC_I6Es0LtSY6yCbzN.yoepDWxwSEX3kertpiRD a{background:var(--secondary-color-orange-1)}.OrIC_I6Es0LtSY6yCbzN a{background:var(--primary-color-blue-1);width:20px;height:20px;border-radius:20px;display:flex;align-items:center;justify-content:center;font-size:10px;color:var(--primary-color-white);font-weight:normal}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/components/AdminNotification/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAME,6CACE,0CAAA,CAGJ,wBACE,sCAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,gCAAA,CACA,kBAAA","sourcesContent":[".wrapIcons{\n  margin-right: 12px;\n}\n\n.wrap{\n\n  &.compliance{\n    a{\n      background: var(--secondary-color-orange-1);\n    }\n  }\n  a{\n    background: var(--primary-color-blue-1);\n    width: 20px;\n    height: 20px;\n    border-radius: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 10px;\n    color: var(--primary-color-white);\n    font-weight: normal;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapIcons": `n86t0WXrht4Ms5iSeF5F`,
	"wrap": `OrIC_I6Es0LtSY6yCbzN`,
	"compliance": `yoepDWxwSEX3kertpiRD`
};
export default ___CSS_LOADER_EXPORT___;
