import { makeAutoObservable } from 'mobx'
import { TableStore } from 'components/NewTable/store/TableStore'
import { transformWebForm } from 'store/webForms/functions'
import { webFormsColumns } from 'components/NewTable/columns/webForms/webFormsColumns'
import { GetWebFormsQuery } from 'src/generated/graphql'
import { IWebForm } from 'src/types/IWebForm'
import { IOption } from 'src/types/IOption'

class WebFormStore {
  tableStore: TableStore<IWebForm>
  constructor() {
    this.tableStore = new TableStore({
      orderBy: 'createdAt',
      columns: webFormsColumns,
      tableName: 'WebFormTable',
      defaultHiddenColumn: [
        'contactsLists',
        'contactStatusStatistics.unsubscribed',
      ],
    })
    makeAutoObservable(this)
  }
  webFormsMap: Map<string, IWebForm> = new Map()
  testWebForm: IWebForm | null = null
  getCodeWebForm: IWebForm | null = null

  openNewWebFormModal = false
  webFormModalCB: ((options: IOption[]) => void) | null = null

  setTestWebForm(form: IWebForm | null) {
    this.testWebForm = form
  }
  setGetCodeWebForm(form: IWebForm | null) {
    this.getCodeWebForm = form
  }

  get webForms() {
    return Array.from(this.webFormsMap.values())
  }
  get webFormsOptions(): IOption[] {
    return this.webForms.map((webForm) => ({
      title: webForm.name || '',
      value: webForm.id,
    }))
  }
  delete(ids: string[]) {
    ids.forEach((id) => {
      this.webFormsMap.delete(id)
    })
    this.tableStore.setCheckedRows(
      this.tableStore.checkedRows.filter((webForm) => !ids.includes(webForm.id))
    )
    this.tableStore.onRefresh()
  }

  setData(data: GetWebFormsQuery) {
    this.webFormsMap.clear()
    const filteredWebForms: IWebForm[] = []
    data?.getWebForms?.content?.forEach((webForm) => {
      if (webForm) {
        filteredWebForms.push(transformWebForm(webForm))
      }
    })
    if (data?.getWebForms?.total) {
      this.tableStore.setTotal(data?.getWebForms?.total)
    }
    filteredWebForms.forEach((webForm) => {
      this.webFormsMap.set(webForm.id, webForm)
    })
  }

  setOpenWebFormModal(
    state: boolean,
    cb: ((options: IOption[]) => void) | null = null
  ) {
    this.openNewWebFormModal = state
    this.webFormModalCB = cb
  }
}

export default new WebFormStore()
