import { makeAutoObservable } from 'mobx'
import {
  ContactCardFragment,
  GetMyContactCardsQuery,
} from 'src/generated/graphql'

class VCardStore {
  constructor() {
    makeAutoObservable(this)
  }

  vCard: ContactCardFragment | null = null
  vCardsMap: Map<number, ContactCardFragment> = new Map()

  get isCreate() {
    return !!this.vCards.length
  }

  get vCards() {
    return Array.from(this.vCardsMap.values())
  }

  setContactCard(card: ContactCardFragment) {
    this.vCardsMap.set(card.id, card)
  }

  setMyContactsCardData(data: GetMyContactCardsQuery) {
    if (data.getMyContactCards) {
      data.getMyContactCards.forEach((card) => {
        if (card) {
          this.vCardsMap.set(card.id, card)
        }
      })
    }
  }

  removeCard(id: number) {
    this.vCardsMap.delete(id)
  }
}

export default new VCardStore()
