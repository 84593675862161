// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lPt9qR_HoKsj0J9NdumL{padding:8px;border-bottom:1px solid var(--primary-color-gray-3)}.iXFw8uUiWgHeFNhg2DQs{padding:8px}.wqki6dwmcfbCM63gqF5O{display:flex}.wqki6dwmcfbCM63gqF5O div{width:calc(100% - 40px);text-align:left}.wqki6dwmcfbCM63gqF5O:disabled{pointer-events:all}.wqki6dwmcfbCM63gqF5O:disabled svg g path{fill:var(--primary-color-gray-1) !important}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/MoreFeatures/styles.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AACA,sBACE,WAAA,CACA,mDAAA,CAEF,sBACE,WAAA,CAEF,sBACE,YAAA,CACA,0BACE,uBAAA,CACA,eAAA,CAEF,+BACE,kBAAA,CCZE,0CACE,2CDYe","sourcesContent":["@import 'src/styles/mixin';\n.header{\n  padding: 8px;\n  border-bottom: 1px solid var(--primary-color-gray-3);\n}\n.content{\n  padding: 8px;\n}\n.menuBtn{\n  display: flex;\n  div{\n    width: calc(100% - 40px);\n    text-align: left;\n  }\n  &:disabled{\n    pointer-events: all;\n    @include svg-color(var(--primary-color-gray-1)!important)\n  }\n}\n","@mixin svg-color($color){\n  svg {\n    g {\n      path {\n        fill: $color;\n      }\n    }\n\n  }\n}\n@mixin svg-color-rect($color){\n  svg {\n    g {\n      rect {\n        fill: $color;\n      }\n    }\n\n  }\n}\n\n@mixin svg-color-stroke($color){\n  svg{\n    path{\n      stroke: $color;\n    }\n  }\n}\n@mixin svg-color-circle($color){\n  svg{\n    circle{\n      fill: $color;\n    }\n  }\n}\n@mixin svg-color-fill($color){\n  svg{\n    path{\n      fill: $color;\n    }\n  }\n}\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `lPt9qR_HoKsj0J9NdumL`,
	"content": `iXFw8uUiWgHeFNhg2DQs`,
	"menuBtn": `wqki6dwmcfbCM63gqF5O`
};
export default ___CSS_LOADER_EXPORT___;
