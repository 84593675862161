import {
  transformTrigger,
  transformTriggerToBack,
} from 'store/triggers/functions'
import {
  Status,
  useRemoveTriggersMutation,
  useUpdateTriggerMutation,
} from 'src/generated/graphql'
import triggersStore from 'store/triggers/triggersStore'
import { ITrigger } from 'src/types/ITrigger'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import companyStore from 'store/settings/company/companyStore'
import billingStore from 'store/settings/billing/billingStore'

export function useTriggerFunctions(row?: ITrigger, rows?: ITrigger[]) {
  const [deleteTrigger] = useRemoveTriggersMutation()
  const [updateTrigger] = useUpdateTriggerMutation()
  const navigate = useNavigate()
  const onSwitch = async () => {
    const onTurnOn = async () => {
      if (row) {
        try {
          const { data } = await updateTrigger({
            variables: {
              triggerInput: {
                ...transformTriggerToBack(row),
                status:
                  row.status === Status.Active
                    ? Status.Disabled
                    : Status.Active,
              },
            },
          })
          if (data?.updateTrigger) {
            triggersStore.updateTrigger(transformTrigger(data.updateTrigger))
          }
        } catch (e) {
          console.error(e)
        }
      }
    }

    if (row?.status === Status.Disabled && !billingStore.autoRecharge) {
      companyStore.setTurnOnAutoRechargeModal('trigger', onTurnOn)
      return
    }

    await onTurnOn()
  }
  const oDetails = () => {
    if (row) {
      triggersStore.setDetailModal(row)
    }
  }
  const onEdit = () => {
    if (row) {
      navigate(`${RoutesEnum.triggers}/${row.id}/edit`)
    }
  }
  const onArchive = async () => {
    if (row) {
      try {
        const { data } = await updateTrigger({
          variables: {
            triggerInput: {
              ...transformTriggerToBack(row),
              status: Status.Archived,
            },
          },
        })
        if (data?.updateTrigger) {
          triggersStore.updateTrigger(transformTrigger(data.updateTrigger))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onRestore = async () => {
    if (row) {
      try {
        const { data } = await updateTrigger({
          variables: {
            triggerInput: {
              ...transformTriggerToBack(row),
              status: Status.Disabled,
            },
          },
        })
        if (data?.updateTrigger) {
          triggersStore.updateTrigger(transformTrigger(data.updateTrigger))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onDuplicate = () => {
    if (row) {
      navigate(`${RoutesEnum.triggers}/${row.id}/duplicate`)
    }
  }
  const onDelete = async () => {
    if (row || rows) {
      try {
        const ids: number[] =
          (row ? [+row.id] : rows?.map((trigger) => +trigger.id)) || []
        await deleteTrigger({
          variables: {
            triggersIds: ids,
          },
        })
        triggersStore.deleteTriggers(triggersStore.tableStore.checkedRowsIds)
      } catch (e) {
        console.error()
      }
    }
  }
  return {
    onSwitch,
    onEdit,
    onArchive,
    onRestore,
    onDuplicate,
    onDelete,
    oDetails,
  }
}
