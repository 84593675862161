import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import modalStore from 'src/widgets/ModalContainer/store/modalStore'

export const ModalContainer = observer(() => {
  return (
    <>
      {modalStore.modals.map((modal) => {
        return (
          <Modal
            key={modal.id}
            {...modal}
            onClose={() => modalStore.closeModal(modal.id)}
            open={true}
          />
        )
      })}
    </>
  )
})
