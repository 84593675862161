import { makeAutoObservable } from 'mobx'
import {
  BusinessIndustry,
  BusinessJobPosition,
  BusinessOperationRegion,
  BusinessProfileStatus,
  BusinessType,
  GetTypesQuery,
  StockExchange,
  TenDlcOptInMethod,
  TenDlcPackageType,
  TenDlcProfileStatus,
  TenDlcUseCaseType,
  TollFreeVerificationMessageAmount,
  TollFreeVerificationOptInMethod,
  TollFreeVerificationStatus,
  VerificationStatus,
} from 'src/generated/graphql'
import { objToMap } from 'src/util/functions'

export type OptInTypeMap = {
  id: number
  optInMethod: TollFreeVerificationOptInMethod
  approvedImageUrl: string
  rejectedImageUrl: string
  infoUrl: string
  title: string
}

class TypesStore {
  constructor() {
    makeAutoObservable(this)
  }

  loadTypes = false

  businessTypeMapping: Map<string, string> = new Map()
  businessIndustryMapping: Map<BusinessIndustry, string> = new Map()
  businessOperationRegionMapping: Map<BusinessOperationRegion, string> =
    new Map()
  businessJobPositionMapping: Map<BusinessJobPosition, string> = new Map()
  businessProfileStatusMapping: Map<BusinessProfileStatus, string> = new Map()

  a2pOptInMethodsMapping: Map<TenDlcOptInMethod, string> = new Map()
  a2pUseCaseTypeMapping: Map<TenDlcUseCaseType, string> = new Map()
  a2pStockExchangesMapping: Map<StockExchange, string> = new Map()
  a2pStatusMapping: Map<TenDlcProfileStatus, string> = new Map()
  a2pPackageTypes: Map<
    TenDlcPackageType,
    {
      brandRegistrationFees: number
      campaignVerificationFees: number
      reoccurringCampaignUseCaseFees: number
    }
  > = new Map()

  tfUseCaseMapping: Map<string, string> = new Map()
  tfVerificationStatusMapping: Map<VerificationStatus, string> = new Map()
  tfMessageAmountMapping: Map<TollFreeVerificationMessageAmount, string> =
    new Map()
  tfOptInMethodMapping: Map<TollFreeVerificationOptInMethod, OptInTypeMap> =
    new Map()
  tfStatusMapping: Map<TollFreeVerificationStatus, string> = new Map()

  get tollFreeMapping(): Map<string, string> {
    return new Map([
      ...this.tfUseCaseMapping,
      ...this.tfVerificationStatusMapping,
      ...this.tfMessageAmountMapping,
      ...this.tfStatusMapping,
    ])
  }

  get businessMapping(): Map<string, string> {
    return new Map([
      ...this.businessTypeMapping,
      ...this.businessIndustryMapping,
      ...this.businessOperationRegionMapping,
      ...this.businessJobPositionMapping,
      ...this.businessProfileStatusMapping,
    ])
  }

  setTypes = (data: GetTypesQuery) => {
    this.loadTypes = true
    if (data.getBusinessProfileTypes) {
      const {
        businessTypeMapping,
        businessIndustryMapping,
        businessOperationRegionMapping,
        businessJobPositionMapping,
        businessProfileStatusMapping,
      } = data.getBusinessProfileTypes

      if (businessTypeMapping) {
        this.businessTypeMapping = objToMap<BusinessType>(businessTypeMapping)
      }
      if (businessIndustryMapping) {
        this.businessIndustryMapping = objToMap<BusinessIndustry>(
          businessIndustryMapping
        )
      }
      if (businessOperationRegionMapping) {
        this.businessOperationRegionMapping = objToMap<BusinessOperationRegion>(
          businessOperationRegionMapping
        )
      }
      if (businessJobPositionMapping) {
        this.businessJobPositionMapping = objToMap<BusinessJobPosition>(
          businessJobPositionMapping
        )
      }
      if (businessProfileStatusMapping) {
        this.businessProfileStatusMapping = objToMap<BusinessProfileStatus>(
          businessProfileStatusMapping
        )
      }
    }

    if (data.getTenDlcTypes) {
      const {
        optInMethods,
        useCaseTypes,
        stockExchanges,
        statusMap,
        packageTypes,
      } = data.getTenDlcTypes

      if (optInMethods) {
        this.a2pOptInMethodsMapping = objToMap<TenDlcOptInMethod>(optInMethods)
      }
      if (useCaseTypes) {
        this.a2pUseCaseTypeMapping = objToMap<TenDlcUseCaseType>(useCaseTypes)
      }
      if (stockExchanges) {
        this.a2pStockExchangesMapping = objToMap<StockExchange>(stockExchanges)
      }
      if (statusMap) {
        this.a2pStatusMapping = objToMap<TenDlcProfileStatus>(statusMap)
      }
      if (packageTypes) {
        this.a2pPackageTypes = new Map(
          Object.entries(packageTypes)
        ) as typeof this.a2pPackageTypes
      }
    }

    if (data.getTollFreeVerificationProfileTypes) {
      const {
        useCaseMapping,
        verificationStatusMapping,
        messageAmountMapping,
        optInMethodMapping,
        tollFreeVerificationStatusMapping,
      } = data.getTollFreeVerificationProfileTypes
      if (useCaseMapping) {
        this.tfUseCaseMapping = objToMap(useCaseMapping)
      }
      if (verificationStatusMapping) {
        this.tfVerificationStatusMapping = objToMap<VerificationStatus>(
          verificationStatusMapping
        )
      }
      if (messageAmountMapping) {
        this.tfMessageAmountMapping =
          objToMap<TollFreeVerificationMessageAmount>(messageAmountMapping)
      }
      if (optInMethodMapping) {
        this.tfOptInMethodMapping = new Map<
          TollFreeVerificationOptInMethod,
          OptInTypeMap
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        >(Object.entries(optInMethodMapping))
      }
      if (tollFreeVerificationStatusMapping) {
        this.tfStatusMapping = objToMap<TollFreeVerificationStatus>(
          tollFreeVerificationStatusMapping
        )
      }
    }
  }
}

export default new TypesStore()
