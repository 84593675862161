import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Icon } from 'components/Icon/Icon'
import React from 'react'
import styles from './styles.module.scss'
import customIntegrationModalStore from 'src/routes/settings/integrations/modal/CustomIntegrationModal/store/customIntegrationModalStore'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { useGetCustomFields } from 'src/routes/main/contacts/customFields/hooks/useGetCustomFields'
import { CustomFieldItem } from 'src/routes/settings/integrations/modal/CustomIntegrationModal/CustomFieldsContent/CustomFieldItem'

export const CustomFieldsContent = observer(() => {
  const { fields, addField } = customIntegrationModalStore
  useGetCustomFields(
    !!customFieldsStore.customFieldsOptions.length && !fields.length
  )

  return (
    <div className={styles.wrap}>
      {!!fields.length && (
        <div className={styles.list}>
          {fields.map(([id, item]) => (
            <CustomFieldItem key={id} id={id} item={item} />
          ))}
        </div>
      )}
      <Button
        typeBtn={BtnType.secondaryBlue}
        size={BtnSize.small}
        fullWidth
        onClick={addField}
      >
        <Icon icon={'plus'} color={'PrimaryColorBlue1'} />
        Add field
      </Button>
    </div>
  )
})
