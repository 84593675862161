export enum AlertTypeEnum {
  info = 'info',
  error = 'error',
  warning = 'warning',
  warning1 = 'warning1',
  notify2 = 'notify2',
  delete = 'delete',
  success = 'success',
  success3 = 'success3',
  blue3 = 'blue3',
}
