import { makeAutoObservable } from 'mobx'

class MergeTokenStore {
  constructor() {
    makeAutoObservable(this)
  }
  onAddPersonalize?: ((name: string, key: string) => void) | null = null
  openModal = false

  setOpenModal = (
    value: boolean,
    onAddPersonalize: typeof this.onAddPersonalize = null
  ) => {
    this.openModal = value
    this.onAddPersonalize = onAddPersonalize
  }
}

export default new MergeTokenStore()
