import { useGetCreditPriceSelectionForPlansQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import billingStore from 'store/settings/billing/billingStore'

export function useSetCreditPrices(planName: string, skip?: boolean) {
  const filteredPlanNames: string[] = []
  if (!billingStore.creditPricesMap.get(planName)) {
    filteredPlanNames.push(planName)
  }

  const { data } = useGetCreditPriceSelectionForPlansQuery({
    variables: {
      planNames: filteredPlanNames,
    },
    skip: !filteredPlanNames.length || skip,
  })

  useEffect(() => {
    if (data) {
      billingStore.setCreditPriceSelection(data)
    }
  }, [data])
}
