import { observer } from 'mobx-react-lite'
import { PaymentDetailCard } from 'src/widgets/SubscriptionDetailCard/PaymentDetailCard'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'

export const SubscriptionDetailCard = observer(() => (
  <PaymentDetailCard
    planTitle={upgradePlanStore.planTitle || ''}
    creditsIncluded={upgradePlanStore.creditsIncluded}
    additionalCreditPrice={upgradePlanStore.additionalCreditPrice}
    totalPriceValue={upgradePlanStore.totalPriceValue}
  />
))
