import React from 'react'
import classNames from 'classnames'
import styles from '../styles.module.scss'
import { IOption } from 'src/types/IOption'
import { PatternFormat } from 'react-number-format'

export type DropdownSearchInputProps = {
  selectedTag?: boolean
  isEditable?: boolean
  placeholder?: string
  search?: string
  selectedOption?: IOption
  onChangeInput?: (val: string) => void
  width?: number
  disabled?: boolean
  searchPatternFormat?: string
}

export const DropdownSearchInput = ({
  selectedTag,
  selectedOption,
  placeholder,
  search,
  isEditable,
  onChangeInput,
  width,
  disabled,
  searchPatternFormat,
}: DropdownSearchInputProps) => {
  if (searchPatternFormat) {
    return (
      <PatternFormat
        format={searchPatternFormat}
        style={{ width }}
        className={classNames('input-text', styles.inputText)}
        placeholder={placeholder}
        value={
          search ||
          (!isEditable && !selectedTag && (selectedOption?.title as string)) ||
          ''
        }
        onChange={(e) => onChangeInput && onChangeInput(e.target.value)}
        disabled={disabled}
      />
    )
  }
  return (
    <input
      style={{ width }}
      className={classNames('input-text', styles.inputText)}
      placeholder={placeholder}
      value={
        search ||
        (!isEditable && !selectedTag && (selectedOption?.title as string)) ||
        ''
      }
      onChange={(e) => onChangeInput && onChangeInput(e.target.value)}
      disabled={disabled}
    />
  )
}
