import { CreatePage } from 'components/Page/CreatePage'
import { PreviewTrigger } from 'src/routes/main/triggers/create/preview/PreviewTrigger'
import { NewTrigger } from 'src/routes/main/triggers/create/components/NewTrigger'
import { CreateTriggerContext } from 'src/routes/main/triggers/create/state/CreateTriggerState'
import { useEffect, useState } from 'react'
import { CreateTriggerStore } from 'store/triggers/createTriggerStore'
import { useLocation, useParams } from 'react-router-dom'
import triggersStore from 'store/triggers/triggersStore'
import { useGetIntegrationsFields } from 'src/routes/main/triggers/hooks/useGetIntegrationsFields'
import { useGetTriggerLazyQuery } from 'src/generated/graphql'
import { transformTrigger } from 'store/triggers/functions'
import { runInAction } from 'mobx'

export const CreateTriggerPage = () => {
  const [store] = useState(() => new CreateTriggerStore())

  const { pathname } = useLocation()
  const isDuplicate = pathname.includes('duplicate')
  const { id } = useParams()
  const currentTrigger = triggersStore.triggersMap.get(id as string)

  const onFinish = () => {
    store.textareaStore.setTriggerMessageUpdate()
  }
  const { loadingFields } = useGetIntegrationsFields({
    onFinish,
    byPassCache: true,
  })
  const [getTriggerById, { loading }] = useGetTriggerLazyQuery()

  useEffect(() => {
    if (id) {
      getTriggerById({
        variables: {
          id: +id,
        },
      })
        .then(({ data }) => {
          if (data?.getTriggerById) {
            const newTrigger = transformTrigger(data.getTriggerById)
            runInAction(() => {
              triggersStore.setTrigger(newTrigger)
            })
            store.setEdit(newTrigger, isDuplicate)
          } else {
            if (currentTrigger) {
              store.setEdit(currentTrigger, isDuplicate)
            }
          }
        })
        .catch((e) => console.error(e))
    }
  }, [id])

  return (
    <CreateTriggerContext.Provider value={store}>
      <CreatePage
        store={store}
        createElement={<NewTrigger />}
        previewElement={<PreviewTrigger />}
        loading={loading || loadingFields}
      />
    </CreateTriggerContext.Provider>
  )
}
