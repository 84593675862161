import { makeAutoObservable } from 'mobx'
import { RinglessMessage } from 'src/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { IAudioItem } from 'src/types/IAudioItem'

export class RinglessStore {
  constructor() {
    makeAutoObservable(this)
  }
  ringlessVoicemailAudio: IAudioItem | null = null

  get disabledCreate() {
    return !this.ringlessVoicemailAudio
  }

  get ringlessMessage(): RinglessMessage {
    return {
      audioId: this.ringlessVoicemailAudio?.id || null,
    }
  }
  get ringlessMessageInit(): RinglessMessage {
    return {
      voiceMail: this.ringlessVoicemailAudio,
      audioId: this.ringlessVoicemailAudio?.id || null,
    }
  }

  setInit(ringlessMessage: Maybe<RinglessMessage>) {
    this.ringlessVoicemailAudio = ringlessMessage?.voiceMail as IAudioItem
  }

  setringlessVoicemailAudio(audio: IAudioItem | null) {
    this.ringlessVoicemailAudio = audio
  }
}
