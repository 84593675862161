import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import auth from 'store/auth'

export const NoOrganizationModal = observer(() => {
  const onClose = () => {
    auth.closeNoOrganizationModal()
  }
  return (
    <Modal open={auth.noOrganizationModal} onClose={onClose} withoutHeader>
      <div className={'pModalInfo'}>
        <ModalIcon type={'info'} />
        <h3>
          Oh-oh! Looks like the Call Loop account you were part of is no longer
          available.
        </h3>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          fullWidth
          onClick={auth.onCreateOrganization}
          mb={8}
        >
          Create new account
        </Button>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          fullWidth
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
})
