import { createContext, useContext } from 'react'
import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'

export const CreateBroadcastContext = createContext<CreateBroadCastStore>(
  new CreateBroadCastStore()
)

export function useCreateBroadcastContext() {
  return useContext(CreateBroadcastContext)
}
