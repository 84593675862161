import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as User } from 'icons/16px/User.svg'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { FC } from 'react'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { PersonalizeContent } from 'components/Textarea/Personolize/PersonalizeContent/PersonalizeContent'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'

type Props = {
  onAddPersonalize: (name: string, key: string) => void
  disabled: boolean
}

export const Personalize: FC<Props> = ({ onAddPersonalize, disabled }) => {
  const { trigger, showTT, setTrigger, onTriggerClick, setShowTT } =
    useFixedTooltip()

  const onAdd = (name: string, key: string) => {
    onAddPersonalize(name, key)
    setShowTT(false)
  }
  return (
    <>
      <FixedTooltip
        trigger={trigger}
        visible={showTT}
        white
        noArrow
        width={300}
        position={TTPositionEnum.bottomLeft}
        addLeftPosition={-8}
        noOpacity
      >
        <PersonalizeContent onAddPersonalize={onAdd} />
      </FixedTooltip>
      <Tooltip
        text={
          <div>
            Personalize
            <br />
            {disabled && (
              <span className={classNames('s2 gray2', styles.subText)}>
                To use merge fields you must first select the trigger app
              </span>
            )}
          </div>
        }
        disableVisible={showTT}
      >
        <div ref={setTrigger}>
          <Button
            size={BtnSize.small}
            hoverGray
            onClick={onTriggerClick}
            disabled={disabled}
            className={styles.btn}
          >
            <User />
          </Button>
        </div>
      </Tooltip>
    </>
  )
}
