import { ColorsNames, ColorsValues } from 'styles/variables'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { ContentType } from 'src/generated/graphql'

export const TypeContent = ({
  type,
  gray,
}: {
  type: ContentType
  gray?: boolean
}) => {
  if (type === ContentType.Sms) {
    return (
      <div
        className={classNames(ColorsNames.notify1, styles.type)}
        style={{
          backgroundColor: gray ? ColorsValues.gray4 : ColorsValues.notify2,
        }}
      >
        <span
          className={classNames(
            's2 medium',
            gray ? ColorsValues.gray4 : ColorsNames.notify1
          )}
        >
          SMS
        </span>
      </div>
    )
  }
  if (type === ContentType.Voice) {
    return (
      <div
        className={classNames(ColorsNames.purple1, styles.type)}
        style={{
          backgroundColor: gray ? ColorsValues.gray4 : ColorsValues.purple3,
        }}
      >
        <span
          className={classNames(
            's2 medium',
            gray ? ColorsValues.gray4 : ColorsNames.purple1
          )}
        >
          Voice
        </span>
      </div>
    )
  }
  if (type === ContentType.Ringless) {
    return (
      <div
        className={classNames(ColorsNames.orange1, styles.type)}
        style={{
          backgroundColor: gray ? ColorsValues.gray4 : ColorsValues.orange3,
        }}
      >
        <span
          className={classNames(
            's2 medium',
            gray ? ColorsValues.gray4 : ColorsNames.orange1
          )}
        >
          Ringless
        </span>
      </div>
    )
  }

  return <></>
}
