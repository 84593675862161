import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import styles from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/styles.module.scss'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Dial } from 'icons/16pxNoMask/Dial.svg'
import { useState } from 'react'
import addNumberStore from 'store/settings/numbers/addNumberStore'

export const FilterByDigitsInput = observer(() => {
  const [focusDigit, setFocusDigit] = useState(false)

  return (
    <div
      className={classNames(styles.wrapInputDigit, focusDigit && styles.focus)}
    >
      <AppIcon color={ColorsNames.gray1}>
        <Dial />
      </AppIcon>
      <input
        type="text"
        placeholder={'Filter by digits'}
        onFocus={() => setFocusDigit(true)}
        onBlur={() => setFocusDigit(false)}
        value={addNumberStore.localDigits}
        onChange={(e) =>
          addNumberStore.setLocalDigits(String(parseInt(e.target.value) || ''))
        }
      />
    </div>
  )
})
