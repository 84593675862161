import { Route, Routes } from 'react-router-dom'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { RoutesEnum, UnauthorizedRoutes } from 'src/routes/routes'
import { NoMatch } from 'src/routes/noMatch'
import { renderRoute } from 'src/util/route'
import { unauthorizedRoutes } from 'components/App/UnauthorizedApp/routes'
import { useEffect } from 'react'
import { Login } from 'src/routes/auth/login/login'
import { Migration } from 'src/routes/auth/migration/migration'
import TagManager from 'react-gtm-module'
import auth from 'store/auth'
import { firstPromoter } from 'src/scripts/firstPromoter'
import { NoOrganizationModal } from 'src/Modals/NoOrganizationModal/NoOrganizationModal'
import { InviteExistUnAuth } from 'src/routes/invite/InviteExistUnAuth'

export const UnauthorizedApp = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.GTM_ID || '',
    })
    auth.setInitTagManager()
    if (analytics.reset) {
      analytics.reset()
    }
    if (analytics.identify) {
      analytics.reset()
      analytics.identify()
    }
    if (!auth.initFirstPromoter) {
      firstPromoter()
    }
  }, [])

  return (
    <>
      <NoOrganizationModal />
      <Routes>
        <Route path={RoutesEnum.inviteExist} element={<InviteExistUnAuth />} />
        <Route index element={<Login />} />
        <Route path={UnauthorizedRoutes.login} element={<Login />} />
        <Route path={UnauthorizedRoutes.migration} element={<Migration />} />
        <Route element={<AuthPage />}>
          {unauthorizedRoutes.map(renderRoute)}
        </Route>
        <Route
          path="*"
          element={<NoMatch redirectRoute={UnauthorizedRoutes.login} />}
        />
      </Routes>
    </>
  )
}
