import { observer } from 'mobx-react-lite'
import { Row } from 'src/routes/settings/compliance/modals/components/Row/Row'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'

export const UseCase = observer(() => {
  const {
    useCaseName,
    useCaseDescription,
    sampleMessage,
    getTitleByType,
    messageAmount,
  } = tollFreeVerificationStore
  return (
    <div className={'col16'}>
      <p className={'par2'}>
        <b>Use case</b>
      </p>
      <div className={'col12'}>
        <Row title={'Use case'} value={useCaseName} />
        <Row title={'Description'} value={useCaseDescription} />
        <Row title={'Sample message'} value={sampleMessage} />
        <Row
          title={'Expected monthly messages'}
          value={getTitleByType(messageAmount)}
        />
      </div>
    </div>
  )
})
