import * as React from 'react'
import { useEffect, useState } from 'react'
import { SizedBox } from 'components/SizedBox'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import NewTable from 'components/NewTable/NewTable'
import { observer } from 'mobx-react-lite'
import { useOrganizationPageContext } from 'src/routes/superAdmin/routes/organization/OrganizationPage'
import { IMember } from 'src/types/IMember'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { useAdminGetOrganizationMembersQuery } from 'src/generated/graphql'
import superAdminStore from 'store/superAdmin/superAdminStore'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { ActionBtnProps } from 'components/NewTable/types'
import classNames from 'classnames'
import styles from '../styles.module.scss'
import { SmallBtn } from 'components/Button/SmallBtn'
import { DisplayStateEnum } from 'components/StatusContent/types'
import { AppElements } from 'src/enums/appElements'
import { Icon } from 'components/Icon/Icon'

export const OrganizationsUsers = observer(() => {
  const context = useOrganizationPageContext()
  const { members, tableStore, organization } = context
  const options = useTablePaginationOptions(tableStore)
  const { data, error, loading, refetch } = useAdminGetOrganizationMembersQuery(
    {
      variables: {
        page: options.variables.page,
        organizationId: organization?.id,
      },
    }
  )

  const [refetchLoading, setRefetchLoading] = useState(false)
  const [firstLoading, setFirstLoading] = useState(true)

  useEffect(() => {
    if (error) {
      setFirstLoading(false)
      setRefetchLoading(false)
    }
  }, [error])

  useEffect(() => {
    if (tableStore.refreshTrigger) {
      setRefetchLoading(true)
      refetch()
        .catch((e) => console.error(e))
        .finally(() => {
          setRefetchLoading(false)
        })
    }
  }, [tableStore.refreshTrigger])

  useEffect(() => {
    if (data) {
      context.setMembersData(data)
      setFirstLoading(false)
    }
  }, [data])

  const ResetMenuItem = ({
    onCloseTT,
    row,
    menu,
    medium,
  }: ActionBtnProps<IMember>) => {
    const onReset = () => {
      onCloseTT && onCloseTT()
      superAdminStore.setResetPasswordUserId(row?.id)
    }
    if (row?.displayState !== DisplayStateEnum.Active) {
      return <></>
    }
    return medium || menu ? (
      <MediumBtn
        type={ActionsEnum.refresh}
        text={'Reset password'}
        onClick={onReset}
        menu={menu}
      />
    ) : (
      <SmallBtn
        type={ActionsEnum.refresh}
        onClick={onReset}
        tooltip={'Reset password'}
        // disabled={loading}
        // tooltip={'Rename'}
      />
    )
  }
  const ChangeOwnerMenuItem = ({
    onCloseTT,
    row,
    menu,
    medium,
  }: ActionBtnProps<IMember>) => {
    const onAction = () => {
      onCloseTT && onCloseTT()
      superAdminStore.makeAsOwner(row?.id, refetch)
    }
    if (row?.role === 'OWNER' || row?.state !== 'ACTIVE') {
      return <></>
    }
    return medium || menu ? (
      <MediumBtn
        type={ActionsEnum.switch}
        text={'Make an Owner'}
        onClick={onAction}
        menu={menu}
      />
    ) : (
      <SmallBtn
        icon={<Icon icon={'user'} color={'PrimaryColorGray1'} />}
        onClick={onAction}
        tooltip={'Make an Owner'}
      />
    )
  }
  return (
    <div>
      <p className={classNames('bold', styles.wrapBlock)}>Team members</p>
      <SizedBox height={24} />
      {!firstLoading && (
        <NewTable<IMember>
          tableStore={tableStore}
          rows={members.map((member) => ({
            ...member,
            actions: [
              (props) => ChangeOwnerMenuItem({ ...props, row: member }),
              (props) => ResetMenuItem({ ...props, row: member }),
            ],
          }))}
          columns={tableStore.visibleColumns}
          pageLoading={loading || refetchLoading}
          withSearch
          emptyContent={
            !members.length ? (
              <EmptyStateSearch element={AppElements.Organizations} />
            ) : undefined
          }
          noStickyHeader
          noPagination={
            tableStore.page === 0 && members.length < tableStore.rowsPerPage
          }
          firstPaddingLeft16
        />
      )}
    </div>
  )
})
