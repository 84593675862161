import { observer } from 'mobx-react-lite'
import { Row } from 'src/routes/settings/compliance/modals/components/Row/Row'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { AppSymbols } from 'src/util/symbols'

export const BusinessInfo = observer(() => {
  return (
    <div className={'col20'}>
      <p className={'par2'}>
        <b>Business info</b>
      </p>
      <div className={'col12'}>
        <Row
          title={'Business type'}
          value={businessProfileStore.getTitleByType(
            businessProfileStore.businessInfo.type
          )}
        />
        <Row
          title={'Business industry'}
          value={businessProfileStore.getTitleByType(
            businessProfileStore.businessInfo.industry
          )}
        />
        <Row
          title={'Website URL'}
          value={businessProfileStore.businessInfo.website || ''}
        />
        {businessProfileStore.businessInfo.socialMediaProfile && (
          <Row
            title={'Social media profile'}
            value={businessProfileStore.businessInfo.socialMediaProfile}
          />
        )}
        <Row
          title={'Business regions of operations'}
          values={businessProfileStore.businessInfo.operationRegions?.map(
            (type) =>
              `${AppSymbols.dash} ${businessProfileStore.getTitleByType(type)}`
          )}
        />
      </div>
    </div>
  )
})
