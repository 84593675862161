import { observer } from 'mobx-react-lite'
import { CreateTagInput } from 'components/Input/CreateTagInput/CreateTagInput'
import { TextFilter } from 'store/contacts/segment/filters/TextFilter'

interface Props {
  filter: TextFilter
}

export const TextFilterContent = observer(({ filter }: Props) => {
  return (
    <CreateTagInput
      number={filter.isNumber}
      options={filter.options || []}
      onCreateTag={filter.addValue}
      onDeleteTag={(id) => filter.onDeleteId(id as string)}
    />
  )
})
