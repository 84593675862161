import { Row } from 'src/routes/settings/compliance/modals/components/Row/Row'
import { BusinessAuthorizedUser } from 'src/generated/graphql'
import typesStore from 'store/typesStore'

export const UserBlock = ({
  firstName,
  lastName,
  email,
  title,
  phoneNumber,
  jobPosition,
}: BusinessAuthorizedUser) => {
  return (
    <div className={'col12'}>
      <Row title={'Name'} value={firstName + ' ' + lastName} />
      <Row title={'Business email'} value={email} />
      <Row title={'Business title'} value={title} />
      <Row title={'Phone number'} value={phoneNumber} />
      <Row
        title={'Job position'}
        value={
          (jobPosition &&
            typesStore.businessJobPositionMapping.get(jobPosition)) ||
          jobPosition
        }
      />
    </div>
  )
}
