import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { Step1Upgrade } from 'src/Modals/UpgradePlanModal/Step1Upgrade/Step1Upgrade'
import { Step2Upgrade } from 'src/Modals/UpgradePlanModal/Step2Upgrade/Step2Upgrade'
import {
  downgradeModalSteps,
  upgradeModalSteps,
} from 'store/settings/billing/hellpers'
import { Alert } from 'components/Alert/Alert'
import { SizedBox } from 'components/SizedBox'
import { Step3Downgrade } from 'src/Modals/UpgradePlanModal/Step3Downgrade/Step3Downgrade'
import { SimplePrice, useGetPricesQuery } from 'src/generated/graphql'
import { useEffect, useMemo, useState } from 'react'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useCardDropdownOptions } from 'src/routes/settings/billing/components/hooks/useCardDropdownOptions'
import billingStore from 'store/settings/billing/billingStore'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const UpgradePlanModal = observer(
  ({ fromCancel }: { fromCancel?: boolean }) => {
    const [skip, setSkip] = useState(false)
    const navigate = useNavigate()
    const [selectedValue, onSelectValue] = useCardDropdownOptions()
    const { data, refetch, loading } = useGetPricesQuery({
      skip: skip || !upgradePlanStore.openModal,
    })
    useEffect(() => {
      if (data?.getPrices) {
        const filtered: SimplePrice[] = []
        data.getPrices?.forEach((price) => {
          if (price) {
            filtered.push(price)
          }
        })
        upgradePlanStore.setPrices(filtered)
        setSkip(true)
      }
    }, [data])

    useEffect(() => {
      if (upgradePlanStore.openModal) {
        refetch()
      }
    }, [upgradePlanStore.openModal])
    const onClose = () => {
      if (fromCancel) {
        navigate(-1)
      }
      upgradePlanStore.onClose()
      billingStore.setDisableHideAutoRechargeFor('')
    }

    const title = useMemo(() => {
      return upgradePlanStore.isDowngradeSubscription
        ? 'Downgrade plan'
        : 'Upgrade plan'
    }, [upgradePlanStore.isDowngradeSubscription])

    const steps =
      upgradePlanStore.modalStep !== 0 &&
      upgradePlanStore.isDowngradeSubscription
        ? downgradeModalSteps
        : upgradeModalSteps
    return (
      <Modal
        open={upgradePlanStore.openModal}
        onClose={onClose}
        title={title}
        steps={steps}
        activeStep={upgradePlanStore.modalStep}
        staticContent={fromCancel}
        loading={loading}
      >
        <div
          className={classNames(styles.wrap, fromCancel && styles.fromCancel)}
        >
          {upgradePlanStore.modalStep !== 0 &&
            upgradePlanStore.isDowngradeSubscription && (
              <>
                <Alert
                  type={AlertTypeEnum.notify2}
                  titleContent={
                    <div className={styles.alertTitle}>
                      <p className={'par2 bold blue4'}>
                        The downgrade will take affect at the end of the billing
                        cycle on{' '}
                        <span className={'par2 bold error1'}>
                          {billingStore.clSubscriptionRenews}
                        </span>
                      </p>
                    </div>
                  }
                />
                <SizedBox height={32} />
              </>
            )}
          {upgradePlanStore.modalStep === 0 && <Step1Upgrade />}
          {upgradePlanStore.modalStep === 1 && (
            <Step2Upgrade
              onSelectValue={onSelectValue}
              selectedValue={selectedValue}
            />
          )}
          {upgradePlanStore.modalStep === 2 && (
            <Step3Downgrade
              onSelectValue={onSelectValue}
              selectedValue={selectedValue}
            />
          )}
        </div>
      </Modal>
    )
  }
)
