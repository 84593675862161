import {
  CustomFieldType,
  Status,
  WebFormCustomField,
  WebFormFragmentFragment,
} from 'src/generated/graphql'
import { ICustomField } from 'src/types/ICustomField'
import { IWebForm } from 'src/types/IWebForm'
import { IItem } from 'components/NewTable/cell/ItemsRender/ItemsRender'

export const transformWebForm = (
  webFrom?: WebFormFragmentFragment
): IWebForm => {
  const fieldsToCollect: WebFormCustomField[] = []
  const items: IItem[] = []
  webFrom?.fieldsToCollect?.forEach((field) => {
    if (field) {
      fieldsToCollect.push(field)
    }
  })
  webFrom?.contactsLists?.forEach((list) => {
    if (list?.listing?.name) {
      items.push({
        id: list?.listing?.id,
        name: list?.listing?.name,
        count: list?.listing?.activeContactsCount,
        group: 'list',
      })
    }
  })
  return {
    ...webFrom,
    name: webFrom?.name || '',
    id: String(webFrom?.id),
    contactsListsIds: webFrom?.contactsLists?.map((list) => list?.listing?.id),
    items,
    fieldsToCollect,
    status: webFrom?.status || Status.Active,
  }
}

export const transformWebFormCustomFieldToICustomField = (
  webFormField: WebFormCustomField
): ICustomField => ({
  used: webFormField.field?.used || false,
  idLong: webFormField.field?.id,
  id: webFormField.field?.key || '',
  key: webFormField.field?.key || '',
  type: webFormField.field?.type || CustomFieldType.Text,
  name: webFormField.field?.name || '',
  required: !!webFormField.required,
})

export const transformICustomFieldToWebFormCustomField = (
  webFormField: ICustomField
): WebFormCustomField => ({
  field: {
    id: webFormField.key,
    name: webFormField.name,
    key: webFormField.key,
    type: webFormField.type as CustomFieldType.Text,
    used: !!webFormField.used,
  },
  required: webFormField.required,
})
