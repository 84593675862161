import { observer } from 'mobx-react-lite'
import { SizedBox } from 'components/SizedBox'
import styles from './styles.module.scss'
import { StatusContent } from 'components/StatusContent/StatusContent'
import {
  BusinessProfileStatus,
  TollFreeVerificationStatus,
  useSyncTwilioTollFreeDataMutation,
} from 'src/generated/graphql'
import * as React from 'react'
import { useOrganizationPageContext } from 'src/routes/superAdmin/routes/organization/OrganizationPage'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { Row } from 'src/routes/superAdmin/routes/organization/info/Row/Row'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import typesStore from 'store/typesStore'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { useState } from 'react'

export const TollFreeInfo = observer(() => {
  const context = useOrganizationPageContext()
  const { setOpenModal } = tollFreeVerificationStore
  const { organization } = context
  if (!organization) {
    return <></>
  }

  const onOpenBusinessProfile = () => {
    if (organization.businessProfile) {
      businessProfileStore.setOpenModal(
        true,
        organization.businessProfile,
        Number(organization.id)
      )
    }
  }

  const onOpenUseCaseProfile = () => {
    if (organization.tollFreeVerificationProfile) {
      setOpenModal(
        true,
        organization.tollFreeVerificationProfile,
        Number(organization.id)
      )
    }
  }

  const businessProfileDate = dayjs(
    organization.businessProfile?.businessProfile?.updatedAt
  ).format(DayjsFormats.date)

  const useCaseProfileDate = dayjs(
    organization.tollFreeVerificationProfile?.updatedAt
  ).format(DayjsFormats.date)

  const [syncData, { loading }] = useSyncTwilioTollFreeDataMutation()

  const [text, setText] = useState('Sync Toll-Free data')

  const handleSync = async () => {
    try {
      if (text === 'Synchronized') {
        return
      }
      await syncData({
        variables: {
          organizationId: organization.id,
        },
      })
      setText('Synchronized')
      setTimeout(() => {
        setText('Sync Toll-Free data')
      }, 2000)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={styles.wrapBlock}>
      <div className={'row12'}>
        <p className={'bold'}>Toll-Free Compliance</p>
        <MediumBtn
          type={ActionsEnum.refresh}
          text={text}
          onClick={handleSync}
          disabled={loading}
        />
      </div>

      <SizedBox height={24} />
      <div className={styles.rows}>
        {
          <Row
            title={'Business Profile Name'}
            value={
              <a
                href={`https://console.twilio.com/us1/account/trust-hub/customer-profiles/${organization.businessProfile?.businessInfo?.twilioSid}/details`}
                className={'s1 regular'}
                target={'_blank'}
                rel="noreferrer"
              >
                {organization.businessProfile?.businessGeneralInfo?.name}
              </a>
            }
          />
        }
        <Row
          title={'Business Profile Status'}
          value={
            <div className={'row8'}>
              <StatusContent
                status={
                  organization.businessProfile?.businessProfile
                    ?.status as BusinessProfileStatus
                }
                noIcon
                text={
                  organization.businessProfile?.businessProfile?.status
                    ? typesStore.businessMapping.get(
                        organization.businessProfile?.businessProfile?.status
                      )
                    : ''
                }
              />

              {organization.businessProfile && (
                <>
                  {businessProfileDate}
                  <div className={'point'} />
                  <button onClick={onOpenBusinessProfile} className={'link s1'}>
                    View Submission
                  </button>
                </>
              )}
            </div>
          }
        />

        {organization.tollFreeVerificationProfile?.useCaseName && (
          <Row
            title={organization.tollFreeVerificationProfile?.useCaseName || ''}
            value={
              <div className={'row8'}>
                <StatusContent
                  status={
                    organization.tollFreeVerificationProfile
                      ?.status as TollFreeVerificationStatus
                  }
                  noIcon
                  text={
                    organization.tollFreeVerificationProfile?.status
                      ? typesStore.tfStatusMapping.get(
                          organization.tollFreeVerificationProfile?.status
                        )
                      : ''
                  }
                />

                {organization.tollFreeVerificationProfile && (
                  <>
                    {useCaseProfileDate}
                    <div className={'point'} />
                    <button
                      onClick={onOpenUseCaseProfile}
                      className={'link s1'}
                    >
                      View Submission
                    </button>
                  </>
                )}
              </div>
            }
          />
        )}
      </div>
    </div>
  )
})
