import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { InputText } from 'components/Input/InputText/InputText'
import { ReactComponent as DragIcon } from 'icons/16pxNoMask/Drag Drop.svg'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { SmallBtn } from 'components/Button/SmallBtn'
import { optionsTypeField } from 'store/contacts/mockData'
import { ActionsEnum } from 'components/Button/types'
import customFieldsStore, {
  reservedNamesForCustomFields,
} from 'store/contacts/customFields/customFieldsStore'
import { CustomFieldType } from 'src/generated/graphql'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ICustomField } from 'src/types/ICustomField'
import { IdFieldButton } from 'components/IdFieldButton/IdFieldButton'
import { useState } from 'react'

const errorText =
  'Custom Field with the same name already exists. Please try another.'

export const FieldItem = observer((filed: ICustomField) => {
  const [focus, setFocus] = useState(false)
  const onChangeFiledName = (val: string) => {
    const usedNames = [
      ...customFieldsStore.alreadyUsedNames
        .filter((nameFiled) => nameFiled.id !== filed.id)
        .map((nameFiled) => nameFiled.name.toLowerCase()),
      ...reservedNamesForCustomFields,
    ]

    if (usedNames.includes(val.toLowerCase())) {
      customFieldsStore.addErrorAlreadyUsedName(filed.id)
    } else {
      customFieldsStore.removeErrorAlreadyUsedName(filed.id)
    }
    customFieldsStore.updateCustomField({ ...filed, name: val })
  }

  const onChangeFiledType = (val: CustomFieldType) => {
    customFieldsStore.updateCustomField({ ...filed, type: val })
  }

  const isError = customFieldsStore.isErrorAlreadyUsedName.has(filed.id)

  return (
    <div>
      <MuiTooltip
        arrow
        width={248}
        placement={'top'}
        title={
          filed.used
            ? 'Custom fields that are connected to Broadcasts, Campaigns and Triggers can’t be deleted'
            : ''
        }
      >
        <div className={styles.item}>
          <div className={styles.wrapInput}>
            <DragIcon />
            <InputText
              customDisabled={filed.used}
              value={filed.name}
              onChangeValue={onChangeFiledName}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              // onClickRightIcon={() => {
              //   onChangeFiledName('')
              // }}
              placeholder={'Name the field'}
              error={isError ? errorText : undefined}
              noShowErrorMessage
              focusOnMount={customFieldsStore.focusedCustomFieldId === filed.id}
              showRightContent={!focus}
              withoutClear={!focus}
              rightContent={
                !focus ? <IdFieldButton id={filed.id} /> : undefined
              }
            />
          </div>
          <Dropdown
            disabled={filed.used}
            options={optionsTypeField}
            withIconRender
            onSelect={(opt) => onChangeFiledType(opt.value as CustomFieldType)}
            selectedOption={optionsTypeField.find(
              (opt) => opt.value === filed.type
            )}
          />
          <SmallBtn
            disabled={filed.used}
            type={ActionsEnum.delete2}
            onClick={() => customFieldsStore.deleteCustomFieldModal(filed.id)}
          />
        </div>
      </MuiTooltip>
      {isError && (
        <div className={styles.wrapError}>
          <ValidItem text={errorText} neutral={false} isValid={false} />
        </div>
      )}
    </div>
  )
})
