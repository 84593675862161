import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as More } from 'icons/16px/More.svg'
import { ReactComponent as User } from 'icons/16px/User.svg'
import { ReactComponent as VCard } from 'icons/16px/vCard.svg'
import { ReactComponent as AngleLeft } from 'icons/16px/Angle-Left.svg'
import { Tooltip } from 'components/Tooltip/Tooltip'
import React, { FC, useEffect, useState } from 'react'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { PersonalizeContent } from 'components/Textarea/Personolize/PersonalizeContent/PersonalizeContent'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import vCardStore from 'store/settings/vCardStore'
import { DataCollectionContent } from 'components/Textarea/DataCollection/DataCollectionContent'
import { TextareaStore } from 'store/textareaStore'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import createVCardStore from 'store/settings/createVCardStore'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { ContactCard } from 'src/generated/graphql'

interface IMenuItem {
  title: string
  content: EnumFutureContent
  Icon: FC
  disabled?: boolean
  tooltipDisabled?: string
}
export enum EnumFutureContent {
  personalize = 'Personalize',
  vCard = 'Share contact card',
  dc = 'Data Collection',
}

type Props = {
  textareaStore: TextareaStore
  onAddPersonalize: (name: string, key: string) => void
  onAddVCard: (vCard: ContactCard) => void
  filterFutureOption?: EnumFutureContent
  fromTemplate?: boolean
  disablePersonalize?: boolean
}

export const MoreFeatures: FC<Props> = observer(
  ({
    onAddPersonalize,
    filterFutureOption,
    onAddVCard,
    textareaStore,
    fromTemplate,
    disablePersonalize,
  }) => {
    const { trigger, showTT, setTrigger, onTriggerClick, setShowTT } =
      useFixedTooltip()
    const [widthTT, setWidthTT] = useState(200)

    const menuItems: IMenuItem[] = [
      {
        title: 'Personalize',
        Icon: User,
        content: EnumFutureContent.personalize,
        disabled: disablePersonalize,
        tooltipDisabled:
          'To use merge fields you must first select the trigger app',
      },
      {
        title: 'Share contact card',
        Icon: VCard,
        content: EnumFutureContent.vCard,
      },
      // {
      //   title: 'Data Collection',
      //   Icon: DataCollection,
      //   content: EnumFutureContent.dc,
      // },
    ]

    const [showContent, setShowContent] = useState<EnumFutureContent | null>(
      null
    )

    const onMenuClick = (content: EnumFutureContent) => {
      if (content === EnumFutureContent.vCard) {
        if (vCardStore.vCards.length) {
          onAddVCard(vCardStore.vCards[0])
        } else {
          createVCardStore.openModal()
          createVCardStore.setCallbackOnSave(onAddVCard)
        }
      } else {
        setShowContent(content)
      }
    }
    const onAddPersonalizeLocal = (name: string, key: string) => {
      setShowContent(null)
      setShowTT(false)
      onAddPersonalize(name, key)
    }
    useEffect(() => {
      if (showContent === EnumFutureContent.dc) {
        setWidthTT(400)
      } else if (showContent === EnumFutureContent.personalize) {
        setWidthTT(272)
      } else {
        setWidthTT(200)
      }
    }, [showContent])
    return (
      <>
        <FixedTooltip
          trigger={trigger}
          visible={showTT}
          white
          noArrow
          width={widthTT}
          position={TTPositionEnum.bottomLeft}
          addLeftPosition={-8}
          noOpacity
        >
          <>
            {showContent === null && (
              <div className={styles.content}>
                {menuItems
                  .filter((item) => item.content !== filterFutureOption)
                  .map(
                    ({ content, Icon, title, disabled, tooltipDisabled }) => (
                      <Button
                        key={content}
                        size={BtnSize.small}
                        typeBtn={BtnType.secondaryGray}
                        menuBtn
                        disabled={disabled}
                        // spaceBetween
                        onClick={() => onMenuClick(content)}
                        className={styles.menuBtn}
                      >
                        <Icon /> <div>{title}</div>
                        {disabled && tooltipDisabled && (
                          <InfoTooltip
                            title={tooltipDisabled}
                            placement={'right'}
                            width={151}
                          />
                        )}
                      </Button>
                    )
                  )}
              </div>
            )}
            {showContent && (
              <>
                <div className={styles.header}>
                  <Button
                    size={BtnSize.small}
                    typeBtn={BtnType.secondaryGray}
                    menuBtn
                    // spaceBetween
                    onClick={() => setShowContent(null)}
                  >
                    <AngleLeft /> <span className={'bold'}>{showContent}</span>
                  </Button>
                </div>

                {showContent === EnumFutureContent.personalize && (
                  <PersonalizeContent
                    onAddPersonalize={onAddPersonalizeLocal}
                    fromMore
                  />
                )}
                {showContent === EnumFutureContent.dc && (
                  <DataCollectionContent
                    textareaStore={textareaStore}
                    fromTemplate={fromTemplate}
                  />
                )}
              </>
            )}
          </>
        </FixedTooltip>
        <Tooltip nowrapText={'More features'} disableVisible={showTT}>
          <div ref={setTrigger}>
            <Button
              size={BtnSize.small}
              hoverGray
              onClick={onTriggerClick}
              active={showTT}
            >
              <More />
            </Button>
          </div>
        </Tooltip>
      </>
    )
  }
)
