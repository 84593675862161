import { makeAutoObservable } from 'mobx'
import axios from 'axios'
import { Asset } from 'src/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { TableStore } from 'components/NewTable/store/TableStore'
import { IAsset } from 'src/types/IAsset'

class MediaStore {
  tableStore: TableStore<IAsset>
  constructor() {
    makeAutoObservable(this)
    this.tableStore = new TableStore({
      tableName: 'MediaTable',
      columns: [],
      rowsPerPage: 24,
      noSort: true,
    })
  }
  showError = false
  subTitleError = ''
  assetsMap: Map<string, Asset> = new Map<string, Asset>()
  assetLoading = false
  get uploadImages() {
    return Array.from(this.assetsMap.values())
  }
  get uploadImagesIds() {
    return this.uploadImages.map((img) => img.id)
  }
  async setUploadImages(files: File[], successCb: (assets: Asset[]) => void) {
    this.showError = false
    this.assetLoading = true
    const formData = new FormData()
    files.forEach((file) => {
      formData.append('files', file, file.name)
    })

    try {
      const { data } = await axios.post('assets/upload', formData)

      const assets: Asset[] = data.uploaded || []
      assets.forEach((asset) => {
        this.assetsMap.set(asset.id, asset)
      })
      successCb(assets)
    } catch (e) {
      this.showError = true
      console.error(e)
    } finally {
      this.assetLoading = false
    }
  }
  onDeleteUploadImages(ids: string[]) {
    ids.forEach((id) => {
      this.assetsMap.delete(id)
    })
  }
  getImagesByIds(ids: string[]) {
    const images: Asset[] = []
    ids.forEach((id) => {
      const img = this.assetsMap.get(id)
      if (img) {
        images.push(img)
      }
    })
    return images
  }
  setShowError(val: boolean, subTitle?: string) {
    this.showError = val
    this.subTitleError = subTitle || ''
  }
  onUploadFileURL(url: string, successCb: () => void) {
    this.showError = false
    this.assetLoading = true
    axios
      .post('assets/upload', { url })
      .then((res) => {
        const assets: Asset[] = res.data.uploaded || []
        assets.forEach((asset) => {
          this.assetsMap.set(asset.id, asset)
        })
        successCb()
      })
      .catch(() => {
        this.showError = true
      })
      .finally(() => {
        this.assetLoading = false
      })
  }
  setAssets(assets: Array<Maybe<Asset>>) {
    const filtering: Asset[] = []
    assets.forEach((item) => {
      if (item) {
        filtering.push(item)
      }
    })
    filtering.forEach((asset) => {
      this.assetsMap.set(asset.id, asset)
    })
  }
}

export default new MediaStore()
