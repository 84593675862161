type WasteChar = '-' | '(' | ')'
type NormalChar = '.'

type NormalProp<Prop extends string> = Prop extends `${infer Char}${infer Rest}`
  ? `${Char extends WasteChar ? NormalChar : Char}${NormalProp<Rest>}`
  : Prop

type NormalName<Name extends string> =
  Name extends `${infer Part}${NormalChar}${infer Rest}`
    ? Capitalize<`${Part}${Capitalize<NormalName<Rest>>}`>
    : Name

type NormalValue<Name extends string> = `var(${Name})`

type ColorName<Name extends string> = NormalName<NormalProp<Name>>
type ColorValue<Key extends string> = NormalValue<Key>

const colors = [
  '--primary-color-blue-1',
  '--primary-color-blue-1-rgb',
  '--primary-color-blue-1-disabled',
  '--primary-color-blue-2',
  '--primary-color-blue-2-opacity6',
  '--primary-color-blue-3',
  '--primary-color-blue-3-disabled',
  '--primary-color-blue-4',
  '--primary-color-blue-5',
  '--primary-color-blue-6',
  '--primary-color-black',
  '--primary-color-black-rgb',
  '--primary-color-black-tooltip',
  '--primary-color-gray-1',
  '--primary-color-gray-1-rgb',
  '--primary-color-gray-2',
  '--primary-color-gray-2-disabled',
  '--primary-color-gray-2-light',
  '--primary-color-gray-3',
  '--primary-color-gray-4',
  '--primary-color-white',

  '--secondary-color-purple-1',
  '--secondary-color-purple-2',
  '--secondary-color-purple-3',
  '--secondary-color-orange-1',
  '--secondary-color-orange-2',
  '--secondary-color-orange-3',
  '--secondary-color-aqua-1',
  '--secondary-color-aqua-2',

  '--states-color-success-1',
  '--states-color-success-2',
  '--states-color-success-3',
  '--states-color-success-4',
  '--states-color-warning-1',
  '--states-color-warning-2',
  '--states-color-warning-3',
  '--states-color-warning-4',
  '--states-color-error-1',
  '--states-color-error-2',
  '--states-color-error-3',
  '--states-color-notify-1',
  '--states-color-notify-2',
  '--color-text',

  '--gradient-1',
  '--gradient-2',
  '--hover-img-gradient',
  '--hover-small-img-gradient',
] as const

export const Colors: {
  [Key in (typeof colors)[number] as ColorName<Key>]: ColorValue<Key>
} = {
  PrimaryColorBlue1: 'var(--primary-color-blue-1)',
  PrimaryColorBlue1Rgb: 'var(--primary-color-blue-1-rgb)',
  PrimaryColorBlue1Disabled: 'var(--primary-color-blue-1-disabled)',
  PrimaryColorBlue2: 'var(--primary-color-blue-2)',
  PrimaryColorBlue2Opacity6: 'var(--primary-color-blue-2-opacity6)',
  PrimaryColorBlue3: 'var(--primary-color-blue-3)',
  PrimaryColorBlue3Disabled: 'var(--primary-color-blue-3-disabled)',
  PrimaryColorBlue4: 'var(--primary-color-blue-4)',
  PrimaryColorBlue5: 'var(--primary-color-blue-5)',
  PrimaryColorBlue6: 'var(--primary-color-blue-6)',
  PrimaryColorBlack: 'var(--primary-color-black)',
  PrimaryColorBlackRgb: 'var(--primary-color-black-rgb)',
  PrimaryColorBlackTooltip: 'var(--primary-color-black-tooltip)',
  PrimaryColorGray1: 'var(--primary-color-gray-1)',
  PrimaryColorGray1Rgb: 'var(--primary-color-gray-1-rgb)',
  PrimaryColorGray2: 'var(--primary-color-gray-2)',
  PrimaryColorGray2Disabled: 'var(--primary-color-gray-2-disabled)',
  PrimaryColorGray2Light: 'var(--primary-color-gray-2-light)',
  PrimaryColorGray3: 'var(--primary-color-gray-3)',
  PrimaryColorGray4: 'var(--primary-color-gray-4)',
  PrimaryColorWhite: 'var(--primary-color-white)',
  SecondaryColorPurple1: 'var(--secondary-color-purple-1)',
  SecondaryColorPurple2: 'var(--secondary-color-purple-2)',
  SecondaryColorPurple3: 'var(--secondary-color-purple-3)',
  SecondaryColorOrange1: 'var(--secondary-color-orange-1)',
  SecondaryColorOrange2: 'var(--secondary-color-orange-2)',
  SecondaryColorOrange3: 'var(--secondary-color-orange-3)',
  SecondaryColorAqua1: 'var(--secondary-color-aqua-1)',
  SecondaryColorAqua2: 'var(--secondary-color-aqua-2)',
  StatesColorSuccess1: 'var(--states-color-success-1)',
  StatesColorSuccess2: 'var(--states-color-success-2)',
  StatesColorSuccess3: 'var(--states-color-success-3)',
  StatesColorSuccess4: 'var(--states-color-success-4)',
  StatesColorWarning1: 'var(--states-color-warning-1)',
  StatesColorWarning2: 'var(--states-color-warning-2)',
  StatesColorWarning3: 'var(--states-color-warning-3)',
  StatesColorWarning4: 'var(--states-color-warning-4)',
  StatesColorError1: 'var(--states-color-error-1)',
  StatesColorError2: 'var(--states-color-error-2)',
  StatesColorError3: 'var(--states-color-error-3)',
  StatesColorNotify1: 'var(--states-color-notify-1)',
  StatesColorNotify2: 'var(--states-color-notify-2)',
  ColorText: 'var(--color-text)',
  Gradient1: 'var(--gradient-1)',
  Gradient2: 'var(--gradient-2)',
  HoverImgGradient: 'var(--hover-img-gradient)',
  HoverSmallImgGradient: 'var(--hover-small-img-gradient)',
}

export type IColor = keyof typeof Colors
