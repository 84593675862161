// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eoQ0jqWWYCDgCR_hRFMm{position:relative;height:100vh;background-color:#fff;display:grid;grid-template-rows:min-content 1fr}.JEb0nZdIYeFzALWRZGs1{display:grid;height:100%;padding-top:24px;padding-left:64px}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/contctLists/DetailList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,kCAAA,CAEF,sBACE,YAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  position: relative;\n  height: 100vh;\n  background-color: white;\n  display: grid;\n  grid-template-rows: min-content 1fr;\n}\n.tableWrap{\n  display: grid;\n  height: 100%;\n  padding-top: 24px;\n  padding-left: 64px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `eoQ0jqWWYCDgCR_hRFMm`,
	"tableWrap": `JEb0nZdIYeFzALWRZGs1`
};
export default ___CSS_LOADER_EXPORT___;
