import { ActionsEnum } from 'components/Button/types'
import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { IAudioItem } from 'src/types/IAudioItem'
import { audioBtnByAction } from 'src/routes/settings/audio/functions/audioBtnByAction'

const manyActions = [ActionsEnum.download, ActionsEnum.delete]

export const getManyAudioActions = (
  audioItems: IAudioItem[]
): Array<FC<ActionBtnProps<IAudioItem>>> => {
  const actionsFC: Array<FC<ActionBtnProps<IAudioItem>>> = []

  manyActions.forEach((action) => {
    const btn: FC<ActionBtnProps<IAudioItem>> | undefined =
      audioBtnByAction[action as keyof typeof audioBtnByAction]
    if (btn) {
      actionsFC.push((props) => btn({ ...props, rows: audioItems }))
    }
  })
  return actionsFC
}
