// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HxSozRLGiaf1kAiFBtzt{border:1px solid var(--primary-color-gray-3);border-radius:12px;padding:23px;display:grid;grid-template-columns:24px 1fr max-content;column-gap:24px;align-items:center}.HxSozRLGiaf1kAiFBtzt:hover{cursor:pointer;border-color:rgba(19,100,249,.4);outline:3px solid rgba(208,224,254,.4)}.HxSozRLGiaf1kAiFBtzt.ull7jqNAZdyXgrTWAY0x{pointer-events:none;opacity:.4}.fWU3f1CvFxPFguUw1WAO{display:flex;padding:2px 8px;background-color:var(--primary-color-gray-3);border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/components/Input/InputCheckboxCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,kBAAA,CACA,YAAA,CACA,YAAA,CACA,0CAAA,CACA,eAAA,CACA,kBAAA,CACA,4BACE,cAAA,CACA,gCAAA,CACA,sCAAA,CAEF,2CACE,mBAAA,CACA,UAAA,CAGJ,sBACE,YAAA,CACA,eAAA,CACA,4CAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  border: 1px solid  var(--primary-color-gray-3);\n  border-radius: 12px;\n  padding: 23px;\n  display: grid;\n  grid-template-columns: 24px 1fr max-content;\n  column-gap: 24px;\n  align-items: center;\n  &:hover{\n    cursor: pointer;\n    border-color: rgba(19, 100, 249, 0.4);\n    outline: 3px solid rgba(208, 224, 254, 0.4);\n  }\n  &.disabled{\n    pointer-events: none;\n    opacity: 0.4;\n  }\n}\n.recommended{\n  display: flex;\n  padding: 2px 8px;\n  background-color: var(--primary-color-gray-3);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `HxSozRLGiaf1kAiFBtzt`,
	"disabled": `ull7jqNAZdyXgrTWAY0x`,
	"recommended": `fWU3f1CvFxPFguUw1WAO`
};
export default ___CSS_LOADER_EXPORT___;
