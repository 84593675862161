// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dlijFRFIuElTOZBHe0_h{padding:16px;display:flex;flex-direction:column;justify-content:center;align-items:center;min-width:280px}.dlijFRFIuElTOZBHe0_h h3{text-align:center}`, "",{"version":3,"sources":["webpack://./src/Modals/CongratsModal/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,yBACE,iBAAA","sourcesContent":["@import \"src/styles/mixin\";\n\n.wrap{\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  min-width: 280px;\n  h3{\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `dlijFRFIuElTOZBHe0_h`
};
export default ___CSS_LOADER_EXPORT___;
