import { Page } from 'components/Page/Page'
import styles from './styles.module.scss'
import { SizedBox } from 'components/SizedBox'
import { Tabs } from 'components/Tabs/Tabs'
import { Performance } from 'src/routes/main/dashboard/Performance/Perfomance'
import { Objectives } from 'src/routes/main/dashboard/Objectives/Objectives'
import { observer } from 'mobx-react-lite'
import { ActiveObjective } from 'src/routes/main/dashboard/Objectives/ActiveObjective/ActiveObjective'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { DashboardRoutesEnum } from 'src/routes/main/dashboard/dashboardRoutes'
import { RoutesEnum } from 'src/routes/routes'
import { ComingSoon } from 'src/components/CommingSoon/ComingSoon'

export const Dashboard = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Page>
      <Routes>
        <Route
          path={DashboardRoutesEnum.objective + '/:step'}
          element={<ActiveObjective />}
        />
        <Route
          path={'/*'}
          element={
            <>
              <ComingSoon
                title={'Dashboard is coming soon'}
                description={'Get an overview of your performance'}
                className={styles.comingSoon}
                // width={'calc(100% - 64px)'}
                // height={'calc(100vh - 140px)'}
              >
                <div className={styles.comingSoonContent} />
              </ComingSoon>
              <div className={styles.wrap}>
                <h1>Dashboard</h1>

                <SizedBox height={4} />
                <Tabs
                  tabs={['Performance', 'Objectives']}
                  activeTabIndex={
                    location.pathname.includes(DashboardRoutesEnum.objectives)
                      ? 1
                      : 0
                  }
                  onTabClick={(index) =>
                    index === 0
                      ? navigate(
                          RoutesEnum.dashboard +
                            '/' +
                            DashboardRoutesEnum.performance
                        )
                      : navigate(
                          RoutesEnum.dashboard +
                            '/' +
                            DashboardRoutesEnum.objectives
                        )
                  }
                />
                <Routes>
                  <Route
                    element={<Performance />}
                    path={DashboardRoutesEnum.performance}
                  />
                  <Route
                    element={<Objectives />}
                    path={DashboardRoutesEnum.objectives}
                  />
                  <Route element={<Performance />} path={'/'} />
                </Routes>
              </div>
            </>
          }
        />
      </Routes>
    </Page>
  )
})
