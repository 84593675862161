import { Status } from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { CustomStatuses } from 'components/StatusContent/types'
import { IColor } from 'styles/colors'

const backgroundColor: { [key: string]: IColor | undefined } = {
  [Status.Active]: 'StatesColorSuccess3',
  [CustomStatuses.Migrated]: 'StatesColorSuccess3',
  [Status.Invalid]: 'StatesColorError3',
  [Status.Unsubscribed]: 'PrimaryColorGray2Light',
  [Status.Inactive]: 'PrimaryColorGray2Light',
  [Status.Archived]: 'StatesColorWarning4',
}

export function statusToOptions(
  status: Status,
  label: string
): IOption<Status> {
  return {
    title: <StatusContent status={status} />,
    value: label || status,
    group: 'status',
    backgroundColor: backgroundColor[status],
  }
}
