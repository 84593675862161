import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { usePlanSelectionRegistrationStepMutation } from 'src/generated/graphql'
import user from 'store/user/user'
import { PERCENT_BONUS_CREDITS } from 'src/static/constants'
import migrationStore from 'store/migration/migrationStore'

export const KeepCurrentMigrationPlanModal = observer(() => {
  const [planSelectionRegistrationStep, { loading }] =
    usePlanSelectionRegistrationStepMutation()
  const onClose = () => {
    migrationStore.setOpenConfirmKeepCurrentMigrationPlanModal(false)
  }
  const onKeep = async () => {
    try {
      const { data } = await planSelectionRegistrationStep({
        variables: {
          price: null,
          saveCredits: false,
        },
      })
      if (data?.planSelectionRegistrationStep) {
        user.setMember(data.planSelectionRegistrationStep)
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal
      open={migrationStore.openConfirmKeepCurrentMigrationPlanModal}
      onClose={onClose}
      withoutHeader
    >
      <div
        className={
          migrationStore.withExpiredCredits ? 'pModalInfo2' : 'pModalInfo'
        }
      >
        <ModalIcon type={'info'} />
        <h3>Are you sure?</h3>
        <p className={'par2 gray1'}>
          {migrationStore.withExpiredCredits ? (
            `This is a special one-time offer that is only being offered this one time. If you close this page, you won't have another chance to migrate your expired credits.`
          ) : (
            <>
              This is a special one-time offer that is only being offered this
              one
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              time. If you close this page, you won't have another chance to
              receive {PERCENT_BONUS_CREDITS} credits.
            </>
          )}
        </p>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onClose}
          fullWidth
          mb={8}
        >
          {migrationStore.withExpiredCredits
            ? 'Yes, migrate my expired credits'
            : 'Yes, I want free credits'}
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onKeep}
          fullWidth
          loading={loading}
        >
          {migrationStore.withExpiredCredits
            ? "No, don't migrate my expired credits"
            : "No, I don't want free credits"}
        </Button>
      </div>
    </Modal>
  )
})
