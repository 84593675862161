import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import auth from 'store/auth'
import billingStore from 'store/settings/billing/billingStore'

export const OAuth = observer(() => {
  useEffect(() => {
    if (auth.loginSearchParams && billingStore.activeSubscription) {
      const response_type = auth.loginSearchParams.get('response_type')
      if (response_type) {
        document.cookie = `Authorization=${auth.token}; domain=.callloop.com; max-age=30`
        window.location.href = `${
          process.env.API
        }/auth-service/oauth2/authorize?${auth.loginSearchParams.toString()}`
      }
    }
  }, [auth.loginSearchParams, billingStore.activeSubscription])
  return <></>
})
