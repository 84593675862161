import { useGetOrganizationBillingInfoQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import billingStore from 'store/settings/billing/billingStore'
import { useRefetch } from 'src/hooks/useRefetch'

export function useSetBillingInfo() {
  const { data, refetch } = useGetOrganizationBillingInfoQuery()
  useEffect(() => {
    if (data?.getSubscription) {
      billingStore.setClSubscription(data.getSubscription)
    }
    if (data?.getOrganizationBillingInfo) {
      billingStore.setOrganizationBillingInfo(data.getOrganizationBillingInfo)
    }
  }, [data])

  useRefetch(refetch)
}
