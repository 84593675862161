import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { Dispatch, FC, SetStateAction } from 'react'
import { MoreBtnItemProps } from 'components/Button/MoreBtn'

export const RenameBtnList: FC<
  MoreBtnItemProps & { setEdit: Dispatch<SetStateAction<boolean>> }
> = ({ onCloseTT, setEdit }) => {
  const onRename = () => {
    onCloseTT && onCloseTT()
    setEdit(true)
  }
  return <MenuBtn type={MenuEnum.edit} title={'Rename'} onClick={onRename} />
}
