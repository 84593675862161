import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import styles from './styles.module.scss'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16px/CloseCustom.svg'
import importContactStore from 'store/contacts/importContactStore'
import { AddCustomFieldSticky } from 'src/routes/main/contacts/customFields/modals/CustomFieldsModal/AddCustomField'
import { IOption } from 'src/types/IOption'

type Props = {
  columnKey: string
  disableDelete: boolean
}

export const MapFieldsTableHeader: FC<Props> = observer(
  ({ columnKey, disableDelete }) => {
    const onSelect = (opt: IOption) => {
      importContactStore.setUploadContactsDataColumnsValue(opt, columnKey)
    }
    return (
      <div className={styles.wrapHeader}>
        <Dropdown
          BottomDropdownButton={AddCustomFieldSticky}
          options={importContactStore.contactFieldsOptions}
          dropDownContentWidth={200}
          selectedOption={importContactStore.contactFieldsOptions.find(
            (opt) =>
              opt.value ===
              importContactStore.uploadContactsDataColumnsMap.get(columnKey)
                ?.selectName
          )}
          onSelect={onSelect}
          grouping
        />
        {!disableDelete && (
          <Button
            className={styles.close}
            onClick={() => importContactStore.removeColumn(columnKey)}
          >
            <Close />
          </Button>
        )}
      </div>
    )
  }
)
