import { ActionsEnum } from 'components/Button/types'
import { CampaignDetailsBtn } from 'src/routes/main/campaigns/components/CampaignDetailsBtn'
import { CampaignSwitchBtn } from 'src/routes/main/campaigns/components/CampaignSwitchBtn'
import { CampaignEditBtn } from 'src/routes/main/campaigns/components/CampaignEditBtn'
import { CampaignDeleteBtn } from 'src/routes/main/campaigns/components/CampaignDeleteBtn'
import { CampaignArchiveBtn } from 'src/routes/main/campaigns/components/CampaignArchiveBtn'
import { CampaignRestoreBtn } from 'src/routes/main/campaigns/components/CampaignRestoreBtn'
import { CampaignDuplicateBtn } from 'src/routes/main/campaigns/components/CampaignDuplicateBtn'
import { CampaignExportBtn } from 'src/routes/main/campaigns/components/CampaignExportBtn'

export const campaignsBtnByAction = {
  [ActionsEnum.switch]: CampaignSwitchBtn,
  [ActionsEnum.details]: CampaignDetailsBtn,
  [ActionsEnum.edit]: CampaignEditBtn,
  [ActionsEnum.export]: CampaignExportBtn,
  [ActionsEnum.duplicate]: CampaignDuplicateBtn,
  [ActionsEnum.archive]: CampaignArchiveBtn,
  [ActionsEnum.restore]: CampaignRestoreBtn,
  [ActionsEnum.delete]: CampaignDeleteBtn,
}
