import styles from './styles.module.scss'
import { ReactNode } from 'react'
import { Currencies, numberString } from 'src/util/functions'
import classNames from 'classnames'

type SubscriptionCardProps = {
  topContent: ReactNode
  centerContent: {
    price: number
    priceThrough?: number
    isBilledAnnually?: boolean
    creditsIncluded: number
    additionalCreditPrice: number
    descPeriodString?: string
    bonusCredits?: number | false
    billedText?: string
    descTooltip?: ReactNode
    noAdditionalCreditsString?: boolean
  }
  bottomContent: ReactNode
}

export const SubscriptionCard = ({
  topContent,
  centerContent: {
    price,
    priceThrough,
    isBilledAnnually,
    creditsIncluded,
    additionalCreditPrice,
    descPeriodString,
    descTooltip,
    bonusCredits,
    billedText,
    noAdditionalCreditsString,
  },
  bottomContent,
}: SubscriptionCardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.top}> {topContent}</div>

      <div className={styles.center}>
        <div className={styles.priceWrap}>
          <div className={styles.price}>
            {priceThrough && (
              <h1 className={'line-through mr8'}>
                {numberString({
                  val: priceThrough,
                  currency: Currencies.usd,
                  maximumFractionDigits: 0,
                })}
              </h1>
            )}
            <h1>
              {numberString({
                val: price,
                currency: Currencies.usd,
                maximumFractionDigits: 0,
              })}
            </h1>
            <span className={'gray1'}>/ mo</span>
          </div>
          {billedText && (
            <span className={'s4 medium gray1'}>{billedText}</span>
          )}
          {isBilledAnnually && (
            <span className={'s4 medium gray1'}>billed annually</span>
          )}
        </div>
        <div className={'col4'}>
          <div className={styles.flexCenter}>
            <p>
              for{' '}
              <span
                className={classNames('mr4', {
                  'line-through': !!bonusCredits,
                })}
              >
                {numberString({
                  val: creditsIncluded,
                })}
              </span>
              {!!bonusCredits && (
                <span className={classNames('mr4')}>
                  {numberString({
                    val: creditsIncluded + bonusCredits,
                  })}
                </span>
              )}
              credits {descPeriodString}
            </p>
            {descTooltip}
          </div>

          <div className={'s2 gray1'}>
            {noAdditionalCreditsString
              ? ''
              : `${numberString({
                  maximumFractionDigits: 2,
                  val: additionalCreditPrice,
                })}¢ per additional credit`}
          </div>
        </div>
      </div>

      <div className={styles.bottom}>{bottomContent}</div>
    </div>
  )
}
