// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oDBqgrCD3tY3eBYoQMA1{background-color:var(--primary-color-white);border:1px solid var(--primary-color-gray-3);border-radius:8px;padding:7px;display:grid;grid-template-columns:max-content 1fr max-content;justify-content:space-between;align-items:center;gap:4px}.oDBqgrCD3tY3eBYoQMA1 span{text-align:center}.oDBqgrCD3tY3eBYoQMA1 input{text-align:center;width:100%;border:none;outline:none;font-size:14px;line-height:16px;padding:0}`, "",{"version":3,"sources":["webpack://./src/components/Counter/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2CAAA,CACA,4CAAA,CACA,iBAAA,CACA,WAAA,CACA,YAAA,CACA,iDAAA,CACA,6BAAA,CACA,kBAAA,CACA,OAAA,CACA,2BACE,iBAAA,CAEF,4BACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CACA,SAAA","sourcesContent":[".wrap{\n  background-color: var(--primary-color-white);\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 8px;\n  padding: 7px;\n  display: grid;\n  grid-template-columns: max-content 1fr max-content;\n  justify-content: space-between;\n  align-items: center;\n  gap: 4px;\n  span{\n    text-align: center;\n  }\n  input{\n    text-align: center;\n    width: 100%;\n    border: none;\n    outline: none;\n    font-size: 14px;\n    line-height: 16px;\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `oDBqgrCD3tY3eBYoQMA1`
};
export default ___CSS_LOADER_EXPORT___;
