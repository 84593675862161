import { observer } from 'mobx-react-lite'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import styles from './styles.module.scss'
import { Currencies, numberString } from 'src/util/functions'
import { Separator } from 'components/Separator/Separator'
import classNames from 'classnames'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { BtnSize, BtnType, Button } from 'components/Button/Button'

export const PaymentContent = observer(() => {
  // const [reservePhoneNumber, { loading: loadingReserveToolNumber }] =
  //   useReservePhoneNumberMutation()
  const phoneNumber = addNumberStore.chosenNumber
  if (!phoneNumber) {
    return <></>
  }
  const onBuy = async () => {
    // try {
    //   if (phoneNumber.phoneNumber) {
    //     const { data } = await reservePhoneNumber({
    //       variables: {
    //         number: phoneNumber.phoneNumber,
    //       },
    //     })
    //     if (data?.reservePhoneNumber) {
    //       addNumberStore.onAddPhoneNumber(data.reservePhoneNumber)
    //     }
    //   }
    // } catch (e) {
    //   console.error(e)
    // }
  }
  const onBack = () => {
    addNumberStore.setChooseStep(1)
  }
  return (
    <>
      <h2 className={'regular mb8'}>{phoneNumber.friendlyName}</h2>
      <p className={'par2 gray1 mb40 row8'}>
        {addNumberStore.isLocalTab ? 'Local number' : 'Toll-Free number'}
        {addNumberStore.isLocalTab && (
          <>
            <div className={'point'} />
            <p
              className={'par2 gray1'}
            >{`${phoneNumber.locality}, ${phoneNumber.region}`}</p>
          </>
        )}
      </p>
      <div className={styles.phoneInfoCard}>
        <Row
          grayLabel
          label={'Phone number'}
          value={`${numberString({
            val: 5,
            currency: Currencies.usd,
            maximumFractionDigits: 0,
          })} / month`}
        />
        <Separator marginBlock={16} color={'PrimaryColorGray2'} />
        <div className={'col8'}>
          <Row
            label={'Monthly total'}
            value={`${numberString({
              val: 5,
              currency: Currencies.usd,
              maximumFractionDigits: 0,
            })}`}
          />
          <Row
            label={'Unused time'}
            value={`${numberString({
              val: -2.47,
              currency: Currencies.usd,
              maximumFractionDigits: 2,
            })}`}
          />
        </div>
        <Separator marginBlock={16} color={'PrimaryColorGray2'} />
        <Row
          label={'Total charged today'}
          value={`${numberString({
            val: 2.53,
            currency: Currencies.usd,
            maximumFractionDigits: 2,
          })}`}
          infoLabel={
            <>
              <span className={'white mb4'}>
                The next charge will be{' '}
                {numberString({
                  val: 5,
                  currency: Currencies.usd,
                  maximumFractionDigits: 0,
                })}
              </span>
              <div>
                <span className={'s2 gray2'}>
                  Changes to your plan will be reflected <br /> in the next
                  billing cycle:{' '}
                  <span className={'s2 white'}>Jan 17, 2023</span>
                </span>
              </div>
            </>
          }
        />
      </div>
      <div className={'row12 mt40'}>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onBuy}
          disabled={!phoneNumber}
          // loading={loadingReserveToolNumber}
        >
          Buy number
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onBack}
        >
          Back
        </Button>
      </div>
    </>
  )
})

const Row = ({
  label,
  value,
  grayLabel,
  infoLabel,
}: {
  label: string
  infoLabel?: string | JSX.Element
  value: string
  grayLabel?: boolean
}) => (
  <div className={'flex-space-between'}>
    <p className={classNames('par2', grayLabel && 'gray1', 'flex')}>
      {label}
      {infoLabel && <InfoTooltip title={infoLabel} />}
    </p>
    <p className={'par2'}>{value}</p>
  </div>
)
