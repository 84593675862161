// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h_UPg0oZ7ythxcWO3guv{margin-top:40px;display:grid;gap:32px}.urlh6UC7fj_N6vEwjPxD{display:flex;justify-content:space-between}.urlh6UC7fj_N6vEwjPxD .voGGPua3m2ZzC2ox_KKi{display:flex;column-gap:12px}.n5B1N_mqpg_eiCFpIqFv{padding:8px}`, "",{"version":3,"sources":["webpack://./src/routes/main/triggers/create/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,4CACE,YAAA,CACA,eAAA,CAGJ,sBACE,WAAA","sourcesContent":[".wrap{\n  margin-top: 40px;\n  display: grid;\n  gap: 32px;\n}\n\n.actionsWrap{\n  display: flex;\n  justify-content: space-between;\n  .left{\n    display: flex;\n    column-gap: 12px;\n  }\n}\n.menuWrap{\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `h_UPg0oZ7ythxcWO3guv`,
	"actionsWrap": `urlh6UC7fj_N6vEwjPxD`,
	"left": `voGGPua3m2ZzC2ox_KKi`,
	"menuWrap": `n5B1N_mqpg_eiCFpIqFv`
};
export default ___CSS_LOADER_EXPORT___;
