import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { ReactComponent as Infinity } from 'icons/16px/Infinity.svg'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Chat } from 'icons/16px/Chat.svg'
import { onOpenIntercomWidget } from 'src/util/intercom'

export const CustomCard = observer(() => {
  return (
    <div className={styles.card}>
      <div className={styles.customHeader}>
        <span>Custom</span>
      </div>
      <div className={styles.middle}>
        <h2>
          Custom plan for
          <br /> advanced users
        </h2>
        <div className={'mb4'}>
          <div className={styles.rowFlex}>
            <Infinity />
            <p>credits</p>
          </div>
        </div>
        <div className={'s2 gray1'}>As low as 1.5¢/credit</div>
      </div>

      <div className={classNames(styles.actionWrap)}>
        {upgradePlanStore.isCustomPlane ? (
          <div className={styles.current}>
            <span className={'s2 medium white'}>Current</span>
          </div>
        ) : (
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            fullWidth
            onClick={onOpenIntercomWidget}
          >
            <Chat /> Get in touch
          </Button>
        )}
      </div>
    </div>
  )
})
