import React, { useEffect, useState } from 'react'
import { DropdownSearchInput } from 'components/Dropdown/components/SearchInput'
import { OptionTagWrapper } from 'components/Dropdown/TagsRender/OptionTagWrapper'
import { IOption } from 'src/types/IOption'
import { observer } from 'mobx-react-lite'
import { PlusItemsProps } from 'components/PlusItems/PlusItems'

export type TagsRenderProps = {
  selectedOptions?: IOption[]
  onDeleteTag?: (id: string | number, group?: string) => void
  selectedTag?: boolean
  isEditable?: boolean
  showInput?: boolean
  tagsNoWrap?: boolean
  setShowInput?: (val: boolean) => void
  placeholder?: string
  search?: string
  selectedOption?: IOption
  onChangeInput?: (val: string) => void
  searchPrefix?: string
  plusItemsProps?: PlusItemsProps
}

export const TagsRender = observer((props: TagsRenderProps) => {
  const {
    selectedOptions,
    placeholder,
    search,
    isEditable,
    selectedTag,
    selectedOption,
    onChangeInput,
    tagsNoWrap,
    showInput,
    searchPrefix,
  } = props
  const [invisibleOptions, setInvisibleOptions] = useState<IOption[]>([])

  useEffect(() => {
    setInvisibleOptions((prevState) =>
      prevState.filter((option) =>
        selectedOptions?.find((sOption) => sOption.value === option.value)
      )
    )
  }, [selectedOptions])

  const inputComponent = (
    <>
      {searchPrefix && <span className={'gray1'}>{searchPrefix}</span>}
      <DropdownSearchInput
        width={200}
        placeholder={placeholder}
        search={
          search ||
          (!isEditable && !selectedTag && (selectedOption?.title as string)) ||
          ''
        }
        onChangeInput={(val) => onChangeInput && onChangeInput(val)}
      />
    </>
  )

  if (!selectedOptions?.length && tagsNoWrap) {
    return inputComponent
  }

  if (!selectedOptions?.length && !showInput) {
    return <span className={'gray2'}>{placeholder}</span>
  }

  return (
    <>
      {selectedOptions?.map((option, index) => (
        <OptionTagWrapper
          {...props}
          key={option.id || option.value}
          length={selectedOptions?.length}
          inputComponent={inputComponent}
          option={option}
          index={index}
          invisibleOptions={invisibleOptions}
          setInvisibleOptions={setInvisibleOptions}
        />
      ))}
    </>
  )
})
