import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { IAudioItem } from 'src/types/IAudioItem'
import { useAudioFunctions } from 'src/routes/settings/audio/hooks/useAudioFunctions'

export const AudioRenameBtn = (props: ActionBtnProps<IAudioItem>) => {
  const { row, tr } = props
  const { onRename } = useAudioFunctions({
    audioItem: row,
    tr,
  })

  return <TableBtn {...props} action={ActionsEnum.rename} onAction={onRename} />
}
