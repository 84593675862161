import { appLinks } from 'src/util/links'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { Alert } from 'components/Alert/Alert'

export const TollNumbersAlert = () => (
  <Alert
    title={'You are required by the carriers to verify your toll-free numbers'}
    text={
      <>
        To avoid carrier filtering and ensure better deliverability, after
        adding the number, please complete{' '}
        <a
          href={appLinks.callLoopCompliance}
          className={'s1'}
          target={'_blank'}
          rel="noreferrer"
        >
          the toll-free verification. Learn more
        </a>
      </>
    }
    type={AlertTypeEnum.notify2}
  />
)
