import { DetailsModalContent } from 'components/DetailsModalContent/DetailsModalContent'
import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import triggersStore from 'store/triggers/triggersStore'

export const TriggerDetailModal = observer(() => {
  const onClose = () => {
    triggersStore.setDetailModal(null)
  }
  return (
    <Modal
      open={triggersStore.openDetailModal}
      onClose={onClose}
      title={'Details'}
      topRight
    >
      {triggersStore.detailModal && (
        <DetailsModalContent element={triggersStore.detailModal} />
      )}
    </Modal>
  )
})
