import dayjs, { DayjsFormats } from 'lib/dayjs'
import { observer } from 'mobx-react-lite'
import user from 'store/user/user'

export const UserDateContent = observer(({ value }: { value: Date }) => {
  return (
    <>
      {dayjs(value).format(DayjsFormats.fullWithAtDash)}{' '}
      {user.timezoneShortName}
    </>
  )
})
