// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AeXgcE1FGYNP0cmq5rs0{margin-right:8px;display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/routes/main/keywords/KeywordsTable/HoverRowComponent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".wrapSwitch{\n  margin-right: 8px;\n  display: flex;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapSwitch": `AeXgcE1FGYNP0cmq5rs0`
};
export default ___CSS_LOADER_EXPORT___;
