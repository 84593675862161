// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lz75uxhadfZCCcPGpBMu{border:1px solid var(--primary-color-gray-3);padding:0 24px;display:grid;column-gap:12px;grid-template-columns:max-content 232px 150px 1fr max-content;align-items:center;min-height:72px;min-width:600px;border-top:none}.Lz75uxhadfZCCcPGpBMu:first-child{border-top:1px solid var(--primary-color-gray-3);border-top-left-radius:12px;border-top-right-radius:12px}.Lz75uxhadfZCCcPGpBMu:last-child{border-bottom-left-radius:12px;border-bottom-right-radius:12px}.W2HD0Im2oEASb1Gr3nVF{display:grid}.R4aKQivrjg1cz2vv_KLA{display:flex;width:fit-content;padding:2px 8px;background-color:var(--primary-color-gray-3);border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/company/routes/CompanyTeam/MemberCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,cAAA,CACA,YAAA,CACA,eAAA,CACA,6DAAA,CACA,kBAAA,CACA,eAAA,CAEA,eAAA,CACA,eAAA,CACA,kCACE,gDAAA,CACA,2BAAA,CACA,4BAAA,CAEF,iCACE,8BAAA,CACA,+BAAA,CAGJ,sBACE,YAAA,CAEF,sBACE,YAAA,CACA,iBAAA,CACA,eAAA,CACA,4CAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  border: 1px solid var(--primary-color-gray-3);\n  padding: 0 24px;\n  display: grid;\n  column-gap: 12px;\n  grid-template-columns: max-content 232px 150px 1fr max-content;\n  align-items: center;\n  min-height: 72px;\n  //border-radius: 12px;\n  min-width: 600px;\n  border-top: none;\n  &:first-child{\n    border-top: 1px solid var(--primary-color-gray-3);\n    border-top-left-radius: 12px;\n    border-top-right-radius: 12px;\n  }\n  &:last-child{\n    border-bottom-left-radius: 12px;\n    border-bottom-right-radius: 12px;\n  }\n}\n.grid{\n  display: grid;\n}\n.role{\n  display: flex;\n  width: fit-content;\n  padding: 2px 8px;\n  background-color: var(--primary-color-gray-3);\n  border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Lz75uxhadfZCCcPGpBMu`,
	"grid": `W2HD0Im2oEASb1Gr3nVF`,
	"role": `R4aKQivrjg1cz2vv_KLA`
};
export default ___CSS_LOADER_EXPORT___;
