// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EKBiT8FBzr2jH9ZQT3nX{padding:8px 8px 20px 8px}.YO8hoz6vGBDY8_Ceqla9{display:grid;grid-template-columns:104px 1fr;column-gap:8px}`, "",{"version":3,"sources":["webpack://./src/components/Schedule/ContactDateFieldCard/ContactDateFieldContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,YAAA,CACA,+BAAA,CACA,cAAA","sourcesContent":[".wrap{\n  padding: 8px 8px 20px 8px;\n}\n.counter{\n  display: grid;\n  grid-template-columns: 104px 1fr;\n  column-gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `EKBiT8FBzr2jH9ZQT3nX`,
	"counter": `YO8hoz6vGBDY8_Ceqla9`
};
export default ___CSS_LOADER_EXPORT___;
