import { useGetTypesQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import typesStore from 'store/typesStore'

export function useSetTypes() {
  const { data } = useGetTypesQuery({ skip: typesStore.loadTypes })
  useEffect(() => {
    if (data) {
      typesStore.setTypes(data)
    }
  }, [data])
}
