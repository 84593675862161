// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ua8lsfCZt4krEOKUegcm{position:relative;cursor:pointer;border-radius:8px;background:var(--primary-color-gray-4, #F3F5F8)}.ua8lsfCZt4krEOKUegcm:hover:before{content:"";z-index:2;position:absolute;top:0;right:0;left:0;bottom:0;background:rgba(17,0,0,.3);opacity:.2;border-radius:8px}.NWKMgx6yErRHRq5ohdcQ{width:100%;height:100%;background-repeat:no-repeat;background-position:50% 50%;background-size:contain;border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/components/SampleImageCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,+CAAA,CAEE,mCACE,UAAA,CACA,SAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,MAAA,CACA,QAAA,CACA,0BAAA,CACA,UAAA,CACA,iBAAA,CAKN,sBACE,UAAA,CACA,WAAA,CACA,2BAAA,CACA,2BAAA,CACA,uBAAA,CACA,iBAAA","sourcesContent":[".imgWrap{\n  position: relative;\n  cursor: pointer;\n  border-radius: 8px;\n  background: var(--primary-color-gray-4, #F3F5F8);\n  &:hover{\n    &:before{\n      content: '';\n      z-index: 2;\n      position: absolute;\n      top: 0;\n      right: 0;\n      left: 0;\n      bottom: 0;\n      background: rgba(17, 0, 0, 0.30);\n      opacity: 0.2;\n      border-radius: 8px;\n    }\n  }\n\n}\n.img{\n  width: 100%;\n  height: 100%;\n  background-repeat: no-repeat;\n  background-position: 50% 50%;\n  background-size: contain;\n  border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgWrap": `ua8lsfCZt4krEOKUegcm`,
	"img": `NWKMgx6yErRHRq5ohdcQ`
};
export default ___CSS_LOADER_EXPORT___;
