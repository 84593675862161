import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Delete } from 'icons/16pxNoMask/Delete.svg'
import { ReactComponent as Delete16 } from 'icons/16px/Delete.svg'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { ActionsEnum } from 'components/Button/types'
import { SmallBtn } from 'components/Button/SmallBtn'

export const DeleteBtn = ({
  onClick,
  medium,
  noIcon,
  text = 'Delete',
  confirmDelete = true,
  small,
  extraBtnSize,
  stopPropagation,
}: {
  onClick: (e: React.MouseEvent) => void
  medium?: boolean
  small?: boolean
  noIcon?: boolean
  text?: string
  confirmDelete?: boolean
  extraBtnSize?: BtnSize
  stopPropagation?: boolean
}) => {
  const [isConfirmDelete, setConfirmDelete] = useState(false)
  const [idInterval, setIdInterval] = useState<NodeJS.Timeout | null>(null)
  const onDelete = (e: React.MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation()
    }
    if (isConfirmDelete || !confirmDelete) {
      onClick(e)
    } else {
      setConfirmDelete(true)
      const id = setTimeout(() => {
        setConfirmDelete(false)
      }, 3000)
      setIdInterval(id)
    }
  }
  useEffect(() => {
    return () => {
      if (idInterval) {
        clearInterval(idInterval)
      }
    }
  }, [])
  if (small) {
    return (
      <SmallBtn
        type={ActionsEnum.delete}
        onClick={onClick}
        tooltip={text}
        confirmDelete={confirmDelete}
      />
    )
  }
  return (
    <Button
      size={extraBtnSize || (medium ? BtnSize.medium : BtnSize.small)}
      menuBtn={!medium}
      typeBtn={BtnType.secondaryDelete}
      onClick={onDelete}
      withIcon={medium && !noIcon}
      p12={extraBtnSize === BtnSize.small}
    >
      {medium ? (
        <>
          <Delete16 />
          {isConfirmDelete && confirmDelete ? 'Sure?' : text}
        </>
      ) : (
        <>
          {!noIcon && (
            <AppIcon color={ColorsNames.error1}>
              <Delete />
            </AppIcon>
          )}
          <span className={'error1'}>
            {isConfirmDelete && confirmDelete ? 'Sure?' : text}
          </span>
        </>
      )}
    </Button>
  )
}
