import { observer } from 'mobx-react-lite'
import { SizedBox } from 'components/SizedBox'
import styles from './styles.module.scss'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ClStatus, Status } from 'src/generated/graphql'
import * as React from 'react'
import { useOrganizationPageContext } from 'src/routes/superAdmin/routes/organization/OrganizationPage'
import { simplePriceToTitle } from 'store/settings/billing/hellpers'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import user from 'store/user/user'
import { Row } from 'src/routes/superAdmin/routes/organization/info/Row/Row'
import { CustomStatuses } from 'components/StatusContent/types'

export const StripeInfo = observer(() => {
  const context = useOrganizationPageContext()
  const {
    organization,
    autoRechargeString,
    activeSubscriberString,
    subscriptionAmountString,
    totalSpendString,
    mrrString,
  } = context
  if (!organization) {
    return <></>
  }

  const status = organization.subscription?.status

  return (
    <div className={styles.wrapBlock}>
      <p className={'bold'}>Stripe Information</p>
      <SizedBox height={24} />
      <div className={styles.rows}>
        <Row
          title={'Signup Date'}
          value={
            <>
              {dayjs(organization.signupDate).format(DayjsFormats.full2)}{' '}
              {user.timezone?.shortName}
            </>
          }
        />
        <Row
          title={'Subscription Status'}
          value={
            <div className={'row4'}>
              {(status === ClStatus.Active || status === ClStatus.Trialing) &&
              organization.subscription?.cancelAt ? (
                <>
                  <div className={'flex'}>
                    <StatusContent status={status} noIcon text={status} />
                    <span className={'error1'}>(Pending Cancellation)</span>
                  </div>
                  (ends on{' '}
                  {dayjs(organization.subscription?.cancelAt).format(
                    DayjsFormats.date
                  )}
                  )
                </>
              ) : (
                <>
                  {organization.subscription?.status === ClStatus.Active ? (
                    <>
                      <StatusContent
                        status={organization.subscription?.status as ClStatus}
                        noIcon
                      />
                      {organization.subscription?.status ===
                        ClStatus.Active && (
                        <>
                          (renews{' '}
                          {dayjs(organization.subscription?.renews).format(
                            DayjsFormats.date
                          )}
                          )
                        </>
                      )}
                    </>
                  ) : (
                    <StatusContent
                      status={
                        (organization.subscription?.status as ClStatus) ||
                        CustomStatuses.Lead
                      }
                      noIcon
                    />
                  )}
                </>
              )}
            </div>
          }
        />

        <Row title={'Active Subscriber'} value={activeSubscriberString} />
        <Row title={'MRR'} value={mrrString} />
        <Row title={'Subscription amount'} value={subscriptionAmountString} />
        <Row title={'Total Spend'} value={totalSpendString} />
        <Row
          title={'Auto-recharge'}
          value={
            <div className={'row4'}>
              <StatusContent
                status={
                  organization.billingInfo?.autoRecharge
                    ? Status.Active
                    : Status.Disabled
                }
                noIcon
              />
              {autoRechargeString}
            </div>
          }
        />

        <Row
          title={'Plan'}
          value={simplePriceToTitle(organization.subscription?.price)}
        />
        <Row
          title={'Plan Interval'}
          value={organization.subscription?.price?.period}
        />
        <Row
          title={'Churned Date'}
          value={
            organization.subscription?.canceledAt &&
            dayjs(organization.subscription?.canceledAt).format(
              DayjsFormats.full2
            )
          }
        />
      </div>
    </div>
  )
})
