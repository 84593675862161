// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BpUe0bfHUfT6KA00CDJ_{padding:0 32px 32px 32px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/account/routes/PrivacyAccount/password/CreateNewPasswordModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `BpUe0bfHUfT6KA00CDJ_`
};
export default ___CSS_LOADER_EXPORT___;
