import { makeAutoObservable } from 'mobx'
import {
  Asset,
  BusinessAuthorizedUser,
  BusinessAuthorizedUserRequestInput,
  BusinessGeneralInfo,
  BusinessGeneralInfoEinRequestInput,
  BusinessGeneralInfoEinResponse,
  BusinessGeneralInfoRequestInput,
  BusinessInfo,
  BusinessInfoRequestInput,
  BusinessProfile,
  BusinessProfileEvaluationErrors,
  BusinessProfileResponse,
  BusinessProfileStatus,
  CountryCode,
  Maybe,
  SecondStreetAddress,
} from 'src/generated/graphql'
import { requiredDetector } from 'src/util/validators'
import { IOption } from 'src/types/IOption'
import { ErrorsEnum } from 'src/static/errors'
import typesStore from 'store/typesStore'
import { AppSymbols } from 'src/util/symbols'

class BusinessProfileStore {
  constructor() {
    makeAutoObservable(this)
  }

  adminOrgId: number | null = null

  activeTabGeneralInfo = 0

  initLoaded = false
  businessProfile: BusinessProfile | null = null
  errors: BusinessProfileEvaluationErrors[] = []
  hideErrorAlert = false
  businessGeneralInfo: BusinessGeneralInfo | null = null
  manualBusinessGeneralInfo: BusinessGeneralInfo | null = null

  openModal = false
  openEditModal = false

  operationCountries: CountryCode[] = []
  isHaveEIN: boolean | null = null

  irsFiles?: File[] = []
  irsAsset?: Asset | null = null

  haveSecondAuthUser = false
  confirmContacted = false
  declareInformation = false
  selectedEIN = ''
  manualEIN = ''

  businessInfo: BusinessInfo = {}
  user1: BusinessAuthorizedUser = {}
  user2: BusinessAuthorizedUser = {}

  mapErrors: Map<string, boolean> = new Map()

  step = 1

  ownerFullBusinessProfile: BusinessProfileResponse | null = null
  modalFullBusinessProfile: BusinessProfileResponse | null = null

  reset = () => {
    this.activeTabGeneralInfo = 0

    this.initLoaded = false
    this.businessProfile = null
    this.errors = []
    this.hideErrorAlert = false
    this.businessGeneralInfo = null
    this.manualBusinessGeneralInfo = null

    this.openModal = false

    this.operationCountries = []
    this.isHaveEIN = null

    this.irsFiles = []
    this.irsAsset = null

    this.haveSecondAuthUser = false
    this.confirmContacted = false
    this.declareInformation = false
    this.selectedEIN = ''
    this.manualEIN = ''

    this.businessInfo = {}
    this.user1 = {}
    this.user2 = {}

    this.mapErrors = new Map()

    this.step = 1
  }

  get isSuperAdminModal() {
    return !!this.adminOrgId
  }

  get selectedEINString() {
    return this.einToString(this.selectedEIN)
  }

  get isErrorAlert() {
    return (
      this.businessProfile?.status === BusinessProfileStatus.TwilioRejected &&
      !!this.errors.length
    )
  }

  get einLookupError() {
    if (this.selectedEIN && this.businessGeneralInfo?.address) {
      const requiredKeys: Array<keyof BusinessGeneralInfo> = ['name']
      const addressKeys: Array<keyof SecondStreetAddress> = [
        'street',
        'city',
        'state',
        'zipCode',
      ]
      return (
        requiredDetector(this.businessGeneralInfo, requiredKeys) ||
        requiredDetector(this.businessGeneralInfo?.address, addressKeys)
      )
    }
  }

  get isUSOperation() {
    return this.operationCountries.includes(CountryCode.Us)
  }

  get isCanadaOperation() {
    return this.operationCountries.includes(CountryCode.Ca)
  }

  get errorManualEin() {
    if (this.mapErrors.get('manualErrorEin')) {
      return ErrorsEnum.einError
    } else {
      return ''
    }
  }

  get initLoadedBusinessTypes() {
    return !!typesStore.businessMapping.size
  }

  // get step() {
  //   return this.businessProfile?.currentStep || 1
  // }

  get completed() {
    return (
      !!this.businessProfile?.status &&
      this.businessProfile?.status !== BusinessProfileStatus.Draft
    )
  }

  get completedStep() {
    return this.step - 1
  }

  get businessNameString() {
    return this.businessGeneralInfo?.name
    // if (this.businessGeneralInfo?.name) {
    //   return this.businessGeneralInfo?.name
    //     .split(' ')
    //     .map((word) => capitalize(word))
    //     .join(' ')
    // } else {
    //   return this.businessGeneralInfo?.name
    // }
  }

  get addressString() {
    const street = `${this.businessGeneralInfo?.address?.street || ''} ${
      this.businessGeneralInfo?.address?.secondStreet || ''
    }`.trim()
    return `${street}, ${this.businessGeneralInfo?.address?.city}, ${this.businessGeneralInfo?.address?.state} ${this.businessGeneralInfo?.address?.zipCode}, ${this.businessGeneralInfo?.address?.country?.countryName}`
  }

  get businessGeneralInfoRequestInput(): BusinessGeneralInfoRequestInput {
    return {
      countryCode: this.businessGeneralInfo?.address?.country?.countryCode,
      name: this.businessGeneralInfo?.name,
      street: this.businessGeneralInfo?.address?.street,
      secondStreet: this.businessGeneralInfo?.address?.secondStreet,
      state: this.businessGeneralInfo?.address?.state,
      zipCode: this.businessGeneralInfo?.address?.zipCode,
      city: this.businessGeneralInfo?.address?.city,
    }
  }

  get businessInfoRequestInput(): BusinessInfoRequestInput {
    return {
      type: this.businessInfo.type,
      industry: this.businessInfo.industry,
      website: this.businessInfo.website,
      socialMediaProfile: this.businessInfo.socialMediaProfile,
      operationRegions: this.businessInfo.operationRegions,
    }
  }

  get businessAuthorizedUserRequestInput(): BusinessAuthorizedUserRequestInput[] {
    const userToInput = (
      user: BusinessAuthorizedUser
    ): BusinessAuthorizedUserRequestInput => ({
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
      jobPosition: user.jobPosition,
      phoneNumber: user.phoneNumber,
      email: user.email,
    })

    const userInputs: BusinessAuthorizedUserRequestInput[] = [
      userToInput(this.user1),
    ]
    if (this.haveSecondAuthUser) {
      userInputs.push(userToInput(this.user2))
    }
    return userInputs
  }

  get businessGeneralInfoEinRequestInput(): BusinessGeneralInfoEinRequestInput {
    return {
      ein: this.manualEIN.replace('-', ''),
      einAssetId: this.irsAsset?.id || null,
      name: this.manualBusinessGeneralInfo?.name,
      street: this.manualBusinessGeneralInfo?.address?.street,
      secondStreet: this.manualBusinessGeneralInfo?.address?.secondStreet,
      city: this.manualBusinessGeneralInfo?.address?.city,
      state: this.manualBusinessGeneralInfo?.address?.state,
      zipCode: this.manualBusinessGeneralInfo?.address?.zipCode,
      countryCode: 'US',
    }
  }

  get disabledOperations() {
    return !this.isUSOperation && !this.isCanadaOperation
  }

  get disabledCompanyType() {
    return this.isHaveEIN === null
  }

  get disabledGeneralInfoWithEIN() {
    if (this.einLookupError) {
      return true
    }
    return !this.selectedEIN
  }

  get disabledGeneralInfoWithEINManual() {
    const requiredKeys: Array<keyof BusinessGeneralInfo> = ['name']
    // if (!this.irsAsset) {
    //   return true
    // }
    if (!this.manualEIN) {
      return true
    }
    if (this.mapErrors.get('manualErrorEin')) {
      return true
    }
    if (
      this.manualBusinessGeneralInfo &&
      this.manualBusinessGeneralInfo.address
    ) {
      const addressKeys: Array<keyof SecondStreetAddress> = [
        'street',
        'city',
        'state',
        'zipCode',
      ]
      return (
        requiredDetector(this.manualBusinessGeneralInfo, requiredKeys) ||
        requiredDetector(this.manualBusinessGeneralInfo.address, addressKeys)
      )
    }
    return true
  }

  get disabledGeneralInfoWithoutEIN() {
    const requiredKeys: Array<keyof BusinessGeneralInfo> = ['name']
    if (this.businessGeneralInfo && this.businessGeneralInfo.address) {
      const addressKeys: Array<keyof SecondStreetAddress> = [
        'country',
        'street',
        'city',
        'state',
        'zipCode',
      ]
      return (
        requiredDetector(this.businessGeneralInfo, requiredKeys) ||
        requiredDetector(this.businessGeneralInfo.address, addressKeys)
      )
    }
    return true
  }

  get disabledBusinessInfo() {
    if (
      this.mapErrors.get('website') ||
      this.mapErrors.get('socialMediaProfile')
    ) {
      return true
    }
    if (this.businessInfo) {
      const requiredKeys: Array<keyof BusinessInfo> = [
        'type',
        'industry',
        'website',
        'operationRegions',
      ]
      return requiredDetector(this.businessInfo, requiredKeys)
    } else {
      return true
    }
  }

  get disabledPeopleToContact() {
    if (!this.confirmContacted) {
      return true
    }
    if (this.mapErrors.get('user1email')) {
      return true
    }
    if (this.mapErrors.get('user1phoneNumber')) {
      return true
    }
    if (this.mapErrors.get('user2phoneNumber')) {
      return true
    }
    if (this.haveSecondAuthUser && this.mapErrors.get('user2email')) {
      return true
    }
    const requiredUserKeys: Array<keyof BusinessAuthorizedUser> = [
      'firstName',
      'lastName',
      'title',
      'jobPosition',
      'email',
      'phoneNumber',
    ]
    if (this.haveSecondAuthUser) {
      return (
        requiredDetector(this.user1, requiredUserKeys) ||
        requiredDetector(this.user2, requiredUserKeys)
      )
    }

    return requiredDetector(this.user1, requiredUserKeys)
  }

  get disabledBusinessTerms() {
    return !this.declareInformation
  }

  get continueText() {
    if (this.step === 4 && !this.isUSOperation) {
      return 'Save & complete'
    }
    if (this.step === 6) {
      return 'Submit for review'
    }
    if (this.isHaveEIN && this.isUSOperation && this.step === 5) {
      return 'Continue'
    }
    if (this.step === 5) {
      return 'Save & complete'
    }
    return 'Continue'
  }

  get progressPercent() {
    return (this.step / this.stepCount) * 100
  }

  get completedPercent() {
    return ((this.step - 1) / this.stepCount) * 100
  }

  get stepTitle() {
    if (this.step === 1) {
      return 'Country of operations'
    }
    if (this.isUSOperation) {
      if (this.step === 2) {
        return 'Company type'
      }
      if (this.step === 3) {
        return 'General info'
      }
      if (this.step === 4) {
        return 'Business info'
      }
      if (this.step === 5) {
        return 'People to contact'
      }
      if (this.step === 6) {
        return 'Terms'
      }
    } else {
      if (this.step === 2) {
        return 'General info'
      }
      if (this.step === 3) {
        return 'Business info'
      }
      if (this.step === 4) {
        return 'People to contact'
      }
    }

    return ''
  }

  get stepCount() {
    if (this.isUSOperation && this.isHaveEIN) {
      return 6
    }
    if (this.isUSOperation) {
      return 5
    }
    return 4
  }

  get businessTypesOptions() {
    const options: IOption[] = []
    typesStore.businessTypeMapping.forEach((title, value) => {
      options.push({
        title,
        value,
      })
    })
    return options
  }
  get businessIndustryOptions() {
    const options: IOption[] = []
    typesStore.businessIndustryMapping.forEach((title, value) => {
      options.push({
        title,
        value,
      })
    })
    return options
  }
  get businessRegionsOptions() {
    const options: IOption[] = []
    typesStore.businessOperationRegionMapping.forEach((title, value) => {
      options.push({
        title,
        value,
      })
    })
    return options
  }
  get businessJobPositionOptions() {
    const options: IOption[] = []
    typesStore.businessJobPositionMapping.forEach((title, value) => {
      options.push({
        title,
        value,
      })
    })
    return options
  }

  einToString = (ein?: number | string | null) => {
    if (!ein) {
      return AppSymbols.dash
    }
    const strEin = String(ein)
    return `${strEin.substring(0, 2)}-${strEin.substring(2, 9)}`
  }

  getTitleByType = (type?: string | null) => {
    if (!type) {
      return ''
    }
    const founded = typesStore.businessMapping.get(type)
    return founded || ''
  }

  setOpenModal = (
    state: boolean,
    modalBusinessProfile?: BusinessProfileResponse,
    adminOrgId?: number
  ) => {
    if (!state) {
      if (this.ownerFullBusinessProfile) {
        this.setFullBusinessProfile(this.ownerFullBusinessProfile)
      } else {
        this.reset()
      }
    }
    if (state && modalBusinessProfile) {
      this.adminOrgId = adminOrgId || null
      this.setFullBusinessProfile(modalBusinessProfile, true)
    }
    this.openModal = state
  }

  setOperationCountries = (code: CountryCode) => {
    if (!this.operationCountries.includes(code)) {
      this.operationCountries.push(code)
    } else {
      this.operationCountries = this.operationCountries.filter(
        (countryCode) => countryCode !== code
      )
    }
  }

  setIsHaveEIN = (isHaveEIN: boolean | null) => {
    this.isHaveEIN = isHaveEIN
  }

  setUser = (user: BusinessAuthorizedUser, number: 1 | 2) => {
    if (number === 1) {
      this.user1 = user
    }
    if (number === 2) {
      this.user2 = user
    }
  }

  setHaveSecondAuthUser = (state: boolean) => {
    this.haveSecondAuthUser = state
  }
  setConfirmContacted = (state: boolean) => {
    this.confirmContacted = state
  }
  setDeclareInformation = (state: boolean) => {
    this.declareInformation = state
  }
  setSelectedEIN = (ein: string, data?: BusinessGeneralInfoEinResponse) => {
    if (data) {
      this.businessGeneralInfo = {
        name: data.name,
        address: {
          street: data.street,
          secondStreet: data.secondStreet,
          city: data.city,
          zipCode: data.zipCode,
          state: data.state,
          country: {
            countryCode: data.countryCode,
          },
        },
      }
    }

    this.selectedEIN = ein
  }

  removeSelectedEin = () => {
    this.selectedEIN = ''
    this.businessGeneralInfo = null
  }

  setBusinessGeneralInfo = (businessGeneralInfo: BusinessGeneralInfo) => {
    this.businessGeneralInfo = businessGeneralInfo
  }

  setManualBusinessGeneralInfo = (businessGeneralInfo: BusinessGeneralInfo) => {
    this.manualBusinessGeneralInfo = businessGeneralInfo
  }

  setBusinessInfo = (businessInfo: BusinessInfo) => {
    this.businessInfo = businessInfo
  }

  setBusinessAuthorizedUser = (
    businessAuthorizedUser: Maybe<BusinessAuthorizedUser>[]
  ) => {
    if (businessAuthorizedUser.length) {
      if (businessAuthorizedUser[0]) {
        this.user1 = {
          ...businessAuthorizedUser[0],
          phoneNumber: businessAuthorizedUser[0].formattedPhoneNumber,
        }
      }
      if (businessAuthorizedUser[1]) {
        this.haveSecondAuthUser = true
        this.user2 = {
          ...businessAuthorizedUser[1],
          phoneNumber: businessAuthorizedUser[1].formattedPhoneNumber,
        }
      }
    }
  }

  setFullBusinessProfile = (
    fullBusinessProfile: BusinessProfileResponse,
    fromModal?: boolean
  ) => {
    if (!fromModal) {
      this.ownerFullBusinessProfile = fullBusinessProfile
    } else {
      this.modalFullBusinessProfile = fullBusinessProfile
    }

    this.initLoaded = true
    const errors: typeof this.errors = []
    fullBusinessProfile.errors?.forEach((error) => {
      if (error) {
        errors.push(error)
      }
    })
    this.errors = errors
    if (fullBusinessProfile.businessProfile) {
      this.setBusinessProfile(fullBusinessProfile.businessProfile)
    }
    if (fullBusinessProfile.businessGeneralInfo) {
      if (fullBusinessProfile.businessGeneralInfo.manual) {
        this.activeTabGeneralInfo = 1
        this.manualEIN = fullBusinessProfile.businessProfile?.ein || ''
        this.manualBusinessGeneralInfo = fullBusinessProfile.businessGeneralInfo
      } else {
        this.activeTabGeneralInfo = 0
        this.selectedEIN = fullBusinessProfile.businessProfile?.ein || ''
      }
      this.setBusinessGeneralInfo(fullBusinessProfile.businessGeneralInfo)
    }
    if (fullBusinessProfile.businessInfo) {
      this.setBusinessInfo(fullBusinessProfile.businessInfo)
    }
    if (fullBusinessProfile.businessAuthorizedUsers) {
      this.setBusinessAuthorizedUser(
        fullBusinessProfile.businessAuthorizedUsers
      )
    }
    this.step = (fullBusinessProfile.businessProfile?.currentStep || 0) + 1 || 1

    this.irsAsset = fullBusinessProfile.businessGeneralInfo?.einDocument
    this.operationCountries =
      (fullBusinessProfile.businessProfile
        ?.operationCountries as CountryCode[]) || []

    if (this.step > this.stepCount) {
      this.step = 1
    }
  }

  setBusinessProfile = (businessProfile: BusinessProfile) => {
    this.businessProfile = businessProfile
    this.isHaveEIN = businessProfile.hasEin
  }

  setError = (key: string, value: boolean) => {
    this.mapErrors.set(key, value)
  }

  setActiveTabGeneralInfo = (index: number) => {
    if (index === 1 && this.selectedEIN) {
      this.manualEIN = this.selectedEIN
      this.manualBusinessGeneralInfo = { ...this.businessGeneralInfo }
    }
    this.activeTabGeneralInfo = index
  }

  setManualEIN = (ein: string) => {
    this.manualEIN = ein
  }

  addIrsFiles = (files?: File[]) => {
    this.irsFiles = files ? [files[0]] : []
  }
  addIrsAssets = (assets?: Asset[]) => {
    this.irsAsset = assets ? assets[0] : null
  }
  onRemoveIrsFile = () => {
    this.irsAsset = null
  }
  setStep = (step: number) => {
    this.step = step
  }
  setHideErrorAlert = (value: boolean) => {
    this.hideErrorAlert = value
  }

  setOpenEditModal = (
    state: boolean,
    modalProfile?: BusinessProfileResponse,
    adminOrgId?: number
  ) => {
    if (!state) {
      if (this.ownerFullBusinessProfile) {
        this.setFullBusinessProfile(this.ownerFullBusinessProfile)
      } else {
        this.reset()
      }
    }
    if (state && modalProfile) {
      this.openModal = false
      this.adminOrgId = adminOrgId || null
      this.setFullBusinessProfile(modalProfile, true)
    }
    this.openEditModal = state
    if (!state) {
      this.adminOrgId = null
    }
  }
}

export default new BusinessProfileStore()
