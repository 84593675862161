import { InputText } from 'components/Input/InputText/InputText'
import React, { useState } from 'react'
import { Asset, SecondStreetAddress } from 'src/generated/graphql'
import { StatesDropdown } from 'components/Dropdown/components/StatesDropdown'
import { CountriesDropdown } from 'components/Dropdown/components/CountriesDropdown'
import { Maybe } from 'graphql/jsutils/Maybe'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { DragAndDropInput } from 'components/DragnDropInput/DragAndDropInput/DragAndDropInput'
import { Separator } from 'components/Separator/Separator'
import { SizedBox } from 'components/SizedBox'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { appLinks } from 'src/util/links'

export const AmericanAddressBlock = ({
  disabledEdit,
  address,
  setAddress,
  noEIN,
  name,
  setName,
  ein,
  setEin,
  onBlurEin,
  onFocusEin,
  errorEin,
  countryCode,
  irsAssets,
  onAddIrsFiles,
  onRemoveFile,
  irsLoading,
}: {
  name?: string
  setName?: (value: string) => void
  ein?: string
  setEin?: (value: string) => void
  disabledEdit?: boolean
  noEIN?: boolean
  withRemoveCountry?: boolean
  address?: Maybe<SecondStreetAddress>
  setAddress: (address: SecondStreetAddress) => void
  onBlurEin?: () => void
  onFocusEin?: () => void
  errorEin?: string
  countryCode?: string
  irsAssets?: Asset[]
  irsLoading?: boolean
  onAddIrsFiles?: (files: File[]) => void
  onRemoveFile?: (id: string) => void
}) => {
  const [isRejectionsFiles, setRejectionsFiles] = useState(false)
  const onFilesRejections = (files: File[]) => {
    setRejectionsFiles(!!files.length)
  }
  const localCountryCode = countryCode || address?.country?.countryCode || ''

  const isRequired = (value?: Maybe<string>): string | undefined =>
    disabledEdit && !value?.trim() ? 'Field required' : undefined
  return (
    <div className={'col24'}>
      {noEIN ? (
        <>
          <CountriesDropdown
            setCountry={(value) => {
              if (address?.state && address.country?.countryCode !== value) {
                setAddress({ ...address, country: value, state: undefined })
              } else {
                setAddress({ ...address, country: value })
              }
            }}
            country={address?.country}
          />
          {setName && (
            <InputText
              label={'Business name'}
              placeholder={'e.g. Example Inc.'}
              value={name || ''}
              onChangeValue={(value) => setName(value)}
              disabled={disabledEdit}
            />
          )}
        </>
      ) : (
        <div className={'row12 flex-start'}>
          <InputText
            label={'EIN'}
            placeholder={'e.g. 12-3456789'}
            value={ein || ''}
            onChangeValue={(value) => {
              setEin && setEin(value)
            }}
            disabled={disabledEdit}
            patternFormat
            format={'##-#######'}
            onBlur={onBlurEin}
            error={errorEin}
            onFocus={onFocusEin}
          />
          {setName && (
            <InputText
              label={'Business name'}
              placeholder={'e.g. Example Inc.'}
              value={name || ''}
              onChangeValue={(value) => setName(value)}
              disabled={disabledEdit}
              error={isRequired(name)}
            />
          )}
        </div>
      )}

      {onAddIrsFiles && (
        <div>
          <div className={'flex'}>
            <span className={'s2 medium'}>IRS EIN document</span>
            <InfoTooltip
              title={
                <>
                  Upload the IRS EIN Document of your company so the carriers
                  can see your business name and EIN number are legit.{' '}
                  <a
                    href={appLinks.a2p10dlcRegistration}
                    target={'_blank'}
                    className={'s1'}
                    rel="noreferrer"
                  >
                    See example.
                  </a>
                </>
              }
              width={270}
            />
          </div>
          <span className={'s2 gray1 mt4 mb16'}>
            Browse or drag and drop the document. Acceptable file types: pdf,
            png, jpg
          </span>
          <DragAndDropInput
            onAddFiles={onAddIrsFiles}
            assets={irsAssets}
            accept={'image/jpeg, image/png, application/pdf'}
            variant2
            onRemoveFile={onRemoveFile}
            onFilesRejections={onFilesRejections}
            inputLoading={irsLoading}
          />
          {isRejectionsFiles && (
            <ValidItem
              isValid={false}
              text={
                'Please upload the following file types: *pdf, *.png, *.jpg'
              }
              mt={16}
            />
          )}
          <SizedBox height={24} />
          <Separator
            width={'calc(100% + 64px)'}
            marginLeft={-32}
            marginBlock={16}
          />
        </div>
      )}

      <InputText
        label={'Street address 1'}
        placeholder={'e.g. Acme Street 1'}
        value={address?.street || ''}
        onChangeValue={(value) => setAddress({ ...address, street: value })}
        disabled={disabledEdit}
        error={isRequired(address?.street)}
      />
      <InputText
        label={'Street address 2 (Optional)'}
        placeholder={'e.g. Acme Street 2'}
        value={address?.secondStreet || ''}
        onChangeValue={(value) =>
          setAddress({ ...address, secondStreet: value })
        }
        disabled={disabledEdit}
      />
      <InputText
        label={'City'}
        placeholder={
          localCountryCode === 'US'
            ? 'e.g. Los Angeles'
            : localCountryCode === 'CA'
            ? 'e.g. Calgary'
            : 'Enter city'
        }
        value={address?.city || ''}
        onChangeValue={(value) => setAddress({ ...address, city: value })}
        disabled={disabledEdit}
        error={isRequired(address?.city)}
      />
      <div className={'row12 flex-start'}>
        {localCountryCode === 'US' || localCountryCode === 'CA' ? (
          <StatesDropdown
            countryCode={localCountryCode}
            state={address?.state || undefined}
            setState={(state) => setAddress({ ...address, state })}
            disabled={disabledEdit}
            error={isRequired(address?.state)}
          />
        ) : (
          <InputText
            label={'Region'}
            placeholder={'Enter region'}
            value={address?.state || ''}
            onChangeValue={(value) => setAddress({ ...address, state: value })}
            disabled={disabledEdit}
            error={isRequired(address?.state)}
          />
        )}

        <InputText
          label={localCountryCode === 'US' ? 'ZIP Code' : 'Postal code'}
          placeholder={
            localCountryCode === 'US'
              ? 'e.g. 90011'
              : localCountryCode === 'CA'
              ? 'e.g. T2P'
              : 'Enter postal code'
          }
          value={address?.zipCode || ''}
          onChangeValue={(value) => setAddress({ ...address, zipCode: value })}
          disabled={disabledEdit}
          error={isRequired(address?.zipCode)}
        />
      </div>
    </div>
  )
}
