import { ToggleBtn } from 'components/ToggleBtn/ToggleBrn'
import { observer } from 'mobx-react-lite'
import { PlanPeriods } from 'store/settings/billing/hellpers'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import user from 'store/user/user'
import { usePlanSelectionRegistrationStepMutation } from 'src/generated/graphql'
import migrationStore from 'store/migration/migrationStore'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'
import { SubscriptionCard } from 'src/Modals/UpgradePlanModal/Step1Upgrade/cards/ui/SubscriptionCard'
import { numberString } from 'src/util/functions'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'

export const MigrationSubscriptionCard = observer(
  ({ withBonusCredits }: { withBonusCredits?: boolean }) => {
    const [planSelectionRegistrationStep, { loading }] =
      usePlanSelectionRegistrationStepMutation()
    const onContinue = async () => {
      try {
        const { data } = await planSelectionRegistrationStep({
          variables: {
            price: migrationStore.planName,
            saveCredits: false,
          },
        })
        if (data?.planSelectionRegistrationStep) {
          user.setMember(data.planSelectionRegistrationStep)
        }
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <SubscriptionCard
        topContent={
          <>
            {migrationStore?.memberRegistration?.oldSubscription ? (
              <>
                {migrationStore.withExpiredCredits && (
                  <span className={'error1'}>
                    Don’t Save My Expired Credits
                  </span>
                )}
                <ToggleBtn
                  options={PlanPeriods}
                  activeValue={migrationStore.activePeriod}
                  onOptionClick={migrationStore.setActivePeriod}
                />
              </>
            ) : (
              <span>Annually</span>
            )}
          </>
        }
        centerContent={{
          isBilledAnnually:
            migrationStore.activePeriod === PlanPeriodsEnum.Annual,
          price: migrationStore.priceValue,
          descPeriodString:
            migrationStore.activePeriod === PlanPeriodsEnum.Annual
              ? '/ year'
              : '/ mo',
          additionalCreditPrice: migrationStore.additionalCreditPrice,
          creditsIncluded: migrationStore.creditsIncluded,
          bonusCredits: withBonusCredits && migrationStore.bonusCredits,
          descTooltip: !withBonusCredits && (
            <InfoTooltip
              title={
                <div>
                  <span className={'white'}>
                    +{' '}
                    {numberString({
                      val: migrationStore.oldCreditsFree,
                    })}{' '}
                    credits
                  </span>
                  <br />
                  <span className={'s2 gray1'}>Unexpired</span>
                </div>
              }
            />
          ),
          noAdditionalCreditsString: true,
        }}
        bottomContent={
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onContinue}
            fullWidth
            loading={loading}
          >
            Continue
          </Button>
        }
      />
    )
  }
)
