import { IOrganization } from 'src/types/IOrganization'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { BillingStatus, ClStatus, Status } from 'src/generated/graphql'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { numberString } from 'src/util/functions'
import { ColumnType } from 'components/NewTable/types'
import { CustomStatuses } from 'components/StatusContent/types'
import * as React from 'react'

export const organizationColumns: ColumnType<IOrganization>[] = [
  { id: 'id', label: 'ID' },
  {
    id: 'organizationStatus',
    label: 'Organization Status',
    format: (status) => (
      <StatusContent
        status={
          status === Status.Pending ? CustomStatuses.Lead : (status as Status)
        }
        noIcon
      />
    ),
  },
  {
    id: 'status',
    label: 'Subscription Status',
    format: (status, { cancelAccountAt }) =>
      (status === ClStatus.Active ||
        status === BillingStatus.Trial ||
        status === ClStatus.Trialing) &&
      cancelAccountAt ? (
        <div className={'flex'}>
          <StatusContent status={status} noIcon text={status} />
          <span className={'error1'}>(Pending Cancellation)</span>
        </div>
      ) : (
        <StatusContent
          status={
            status === Status.Pending ? CustomStatuses.Lead : (status as Status)
          }
          noIcon
        />
      ),
  },
  {
    id: 'ipCountryName',
    label: 'Country',
  },
  {
    id: 'companyName',
    label: 'Company Name',
    minWidth: 200,
  },
  {
    id: 'isMigrated',
    label: 'Migration',
    format: (value) =>
      value ? (
        <span className={'success1'}>true</span>
      ) : (
        <span className={'error1'}>false</span>
      ),
  },
  {
    id: 'ownerName',
    label: 'Owner Name',
  },
  { id: 'ownerEmail', label: 'Owner Email' },
  {
    id: 'signupDate',
    label: 'Signup Date',
    format: (value) => dayjs(value as Date).format(DayjsFormats.dateSort),
  },
  {
    id: 'cancelAccountAt',
    label: 'Cancel At',
    format: (value) =>
      value && dayjs(value as Date).format(DayjsFormats.dateSort),
  },
  {
    id: 'credits',
    label: 'Credits',
    format: (credits) => numberString({ val: credits as number }),
  },
  {
    id: 'canUseGlobalNumber',
    label: 'Shared Number',
    format: (value) =>
      value ? (
        <span className={'success1'}>true</span>
      ) : (
        <span className={'error1'}>false</span>
      ),
  },
  {
    id: 'ipAddress',
    label: 'IP Address',
  },
]

export const organizationNoSortsColumns = [
  'status',
  'ownerName',
  'ownerId',
  'ownerEmail',
  'credits',
  'isMigrated',
]
