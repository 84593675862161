import { observer } from 'mobx-react-lite'
import { Separator } from 'components/Separator/Separator'
import React from 'react'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { TollFreeVerificationStatus } from 'src/generated/graphql'
import styles from './styles.module.scss'
import { StatusContent } from 'components/StatusContent/StatusContent'
import classNames from 'classnames'
import { SampleImageCard } from 'components/SampleImageCard/SampleImageCard'
import { optInLinks } from 'store/settings/company/mockData'
import { UploadOptInImageContent } from 'src/routes/settings/compliance/TollFreeVerification/steps/OptInMethod/UploadOptInImageContent'
import typesStore from 'store/typesStore'

export const OptInMethodContent = observer(() => {
  const { optInMethod } = tollFreeVerificationStore
  const { tfStatusMapping, tfOptInMethodMapping } = typesStore
  if (!optInMethod) {
    return null
  }

  const item = tfOptInMethodMapping.get(optInMethod)

  if (!item) {
    return null
  }

  return (
    <>
      <Separator
        width={'calc(100% + 64px)'}
        marginLeft={-32}
        marginBlock={32}
      />
      <div className={'mb40'}>
        <div className={'flex-space-between mb12'}>
          <span>
            <b>Opt-in image</b>
          </span>
          <a
            href={optInLinks[optInMethod]}
            target={'_blank'}
            className={'s1'}
            rel="noreferrer"
          >
            See examples
          </a>
        </div>
        <div className={styles.wrapExamples}>
          <div className={styles.block}>
            <div className={classNames(styles.status, styles.approved)}>
              <StatusContent
                status={TollFreeVerificationStatus.Approved}
                text={tfStatusMapping.get(TollFreeVerificationStatus.Approved)}
              />
            </div>
            <SampleImageCard
              url={item.approvedImageUrl}
              className={styles.img}
            />
          </div>
          <div className={styles.block}>
            <div className={classNames(styles.status, styles.rejected)}>
              <StatusContent
                status={TollFreeVerificationStatus.Rejected}
                text={tfStatusMapping.get(TollFreeVerificationStatus.Rejected)}
              />
            </div>
            <SampleImageCard
              url={item.rejectedImageUrl}
              className={styles.img}
            />
          </div>
        </div>
      </div>
      <UploadOptInImageContent />
    </>
  )
})
