import { ActionsEnum } from 'components/Button/types'
import { TriggerEditBtn } from 'src/routes/main/triggers/components/TriggerEditBtn'
import { TriggerSwitchBtn } from 'src/routes/main/triggers/components/TriggerSwitchBtn'
import { TriggerDetailsBtn } from 'src/routes/main/triggers/components/TriggerDetailsBtn'
import { TriggerCopyWebhookBtn } from 'src/routes/main/triggers/components/TriggerCopyWebhookBtn'
import { TriggerDuplicateBtn } from 'src/routes/main/triggers/components/TriggerDuplicateBtn'
import { TriggerArchiveBtn } from 'src/routes/main/triggers/components/TriggerArchiveBtn'
import { TriggerRestoreBtn } from 'src/routes/main/triggers/components/TriggerRestoreBtn'
import { TriggerDeleteBtn } from 'src/routes/main/triggers/components/TriggerDeleteBtn'
import { TriggerExportBtn } from 'src/routes/main/triggers/components/TriggerExportBtn'

export const triggerBtnByAction = {
  [ActionsEnum.switch]: TriggerSwitchBtn,
  [ActionsEnum.details]: TriggerDetailsBtn,
  [ActionsEnum.edit]: TriggerEditBtn,
  [ActionsEnum.copy]: TriggerCopyWebhookBtn,
  [ActionsEnum.export]: TriggerExportBtn,
  [ActionsEnum.duplicate]: TriggerDuplicateBtn,
  [ActionsEnum.archive]: TriggerArchiveBtn,
  [ActionsEnum.restore]: TriggerRestoreBtn,
  [ActionsEnum.delete]: TriggerDeleteBtn,
}
