import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { WrapPageSuperAdmin } from 'src/routes/superAdmin/components/WrapPageSuperAdmin/WrapPageSuperAdmin'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import {
  BillingStatus,
  BusinessProfileStatus,
  TollFreeVerificationStatus,
  useAdminOrganizationComplianceListQuery,
} from 'src/generated/graphql'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import { Filters } from './Filters'
import superAdminComplianceStore from 'store/superAdmin/superAdminComplianceStore'
import { IOrganizationComplianceResponse } from 'src/types/IOrganizationComplianceResponse'

export const ComplianceReviewSystem = observer(() => {
  const [fistLoading, setFirstLoading] = useState(true)
  const [refetchLoading, setRefetchLoading] = useState(false)

  const tableStore = superAdminComplianceStore.tableStore
  const options = useTablePaginationOptions(tableStore)

  const { data, refetch, loading } = useAdminOrganizationComplianceListQuery({
    ...options,
    variables: {
      ...options.variables,
      search: {
        bpStatuses: !superAdminComplianceStore.bpStatuses.length
          ? Object.values(BusinessProfileStatus)
          : superAdminComplianceStore.bpStatuses,
        tfStatuses: !superAdminComplianceStore.tfStatuses.length
          ? Object.values(TollFreeVerificationStatus)
          : superAdminComplianceStore.tfStatuses,
        billingStatuses: !superAdminComplianceStore.billingStatuses.length
          ? Object.values(BillingStatus)
          : superAdminComplianceStore.billingStatuses,
      },
    },
  })

  useEffect(() => {
    if (data?.adminOrganizationComplianceList) {
      setFirstLoading(false)
      superAdminComplianceStore.setAdminOrganizationComplianceList(data)
    }
  }, [data])

  useEffect(() => {
    setRefetchLoading(true)
    refetch()
      .catch((e) => console.error(e))
      .finally(() => setRefetchLoading(false))
  }, [tableStore.refreshTrigger])

  return (
    <>
      <WrapPageSuperAdmin title={'Compliance Review System'}>
        <NewTable<IOrganizationComplianceResponse>
          columns={tableStore.visibleColumns}
          withCalcMaxHeight
          rows={superAdminComplianceStore.complianceOrganizations}
          leftStickyContent={<Filters />}
          withSearch
          heightSticky91
          tableStore={tableStore}
          firstLoading={fistLoading}
          pageLoading={loading || refetchLoading}
          emptyContent={
            !superAdminComplianceStore.complianceOrganizations.length ? (
              <EmptyStateSearch />
            ) : undefined
          }
        />
      </WrapPageSuperAdmin>
    </>
  )
})
