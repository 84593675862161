import { makeAutoObservable, reaction } from 'mobx'
import {
  CountryCode,
  GetAvailablePhoneNumbersQueryVariables,
  Location,
  PhoneNumber,
  PhoneNumberType,
  TwPhoneNumber,
} from 'src/generated/graphql'
import numbersStore from 'store/settings/numbers/numbersStore'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { nanoid } from 'nanoid'
import { checkLimitsNumbers } from 'store/settings/numbers/functions'

class AddNumberStore {
  constructor() {
    makeAutoObservable(this)
    reaction(
      () => numbersStore.isFirstNumber,
      (isFree) => {
        if (isFree) {
          this.stepChoose = 1
          this.stepChooseCount = 2
        }
      }
    )
  }

  modalStep = 0
  stepChoose = 0
  stepChooseCount = 0

  activeTabIndexChoose = 0

  chosenNumber: TwPhoneNumber | null = null

  addNumberCallback: ((number: string) => void) | null = null
  openAddNumberModal = false
  openFirstTollFreeNumberModal = false
  onlyTollFree = true

  congratsNumberModal = false
  congratsNumber: PhoneNumber | null = null

  refreshTrigger = ''

  //for local number tab
  selectedCountryCode = CountryCode.Us
  selectedFilterLocalNumber: 'city' | 'area' = 'city'
  selectedCityLocalNumberValue = ''
  selectedCityLocalNumber = ''
  selectedCityStateLocalNumber = ''
  searchCity = ''
  areaCode = ''
  localDigits = ''

  //for toll-free tab
  tollFreeDigits = ''

  loadingData = false

  get isLocalTab() {
    return this.activeTabIndexChoose === 0
  }

  get getAvailablePhoneNumbersQueryVariables(): GetAvailablePhoneNumbersQueryVariables {
    if (this.isLocalTab) {
      return {
        pageSize: 4,
        city:
          this.selectedFilterLocalNumber === 'city'
            ? this.selectedCityLocalNumber
            : undefined,
        state:
          this.selectedFilterLocalNumber === 'city'
            ? this.selectedCityStateLocalNumber
            : undefined,
        areaCode:
          this.selectedFilterLocalNumber === 'area' ? this.areaCode : undefined,
        partOfNumber: this.localDigits,
        phoneNumberType: PhoneNumberType.Local,
        country: this.isLocalTab ? this.selectedCountryCode : undefined,
      }
    }
    return {
      pageSize: 12,
      partOfNumber: this.tollFreeDigits,
      phoneNumberType: PhoneNumberType.TollFree,
    }
  }

  get progressChoose() {
    return (this.stepChoose / this.stepChooseCount) * 100
  }

  setOpenAddNumberModal(
    state: boolean,
    cb: ((number: string) => void) | null = null,
    onlyTollFree = true
  ) {
    if (state) {
      const isAllow = checkLimitsNumbers()
      if (!isAllow) {
        return
      }
    }
    this.openAddNumberModal = state
    this.addNumberCallback = cb
    this.onlyTollFree = onlyTollFree
    if (onlyTollFree) {
      this.modalStep = 1
      this.activeTabIndexChoose = 1
    }

    if (!state) {
      this.modalStep = 0
      this.activeTabIndexChoose = 0
      this.stepChoose = numbersStore.isFirstNumber ? 1 : 0
      this.chosenNumber = null
      this.onlyTollFree = true
    }
  }

  setCongratsNumberModal(state: boolean, phone?: PhoneNumber) {
    this.congratsNumberModal = state
    this.congratsNumber = phone || null
  }

  setModalStep(step: number) {
    this.modalStep = step
  }
  setChooseStep(step: number) {
    this.stepChoose = step
  }

  setChosenNumber(number: TwPhoneNumber | null) {
    this.chosenNumber = number
  }

  setActiveTabIndexChoose(index: number) {
    this.activeTabIndexChoose = index
  }

  onAddNumber(phoneNumber: PhoneNumber) {
    numbersStore.setNumbers([phoneNumber])
    this.addNumberCallback &&
      this.addNumberCallback(phoneNumber.friendlyName || '')
  }

  onFirstNumberReserve(phoneNumber: PhoneNumber) {
    this.onAddNumber(phoneNumber)
    this.setOpenAddNumberModal(false)
    this.setCongratsNumberModal(true, phoneNumber)
  }

  onAddPhoneNumber(phoneNumber: PhoneNumber) {
    this.onAddNumber(phoneNumber)
    this.setOpenAddNumberModal(false)
    alertStore.setAlert({
      type: AlertTypeEnum.success,
      title: `Number ${phoneNumber.friendlyName} purchased`,
    })
  }

  //for local number tab

  setSelectedCountryCode(selectedCountryCode: typeof this.selectedCountryCode) {
    this.selectedCountryCode = selectedCountryCode
  }

  setSelectedFilterLocalNumber(
    selectedFilterLocalNumber: typeof this.selectedFilterLocalNumber
  ) {
    this.selectedFilterLocalNumber = selectedFilterLocalNumber
  }

  setSelectedCityLocalNumber(value: string, data: Location) {
    this.selectedCityLocalNumberValue = value
    this.selectedCityLocalNumber = data.city || ''
    this.selectedCityStateLocalNumber = data.state || ''
  }
  removeSelectedCityLocalNumber() {
    this.searchCity = ''
    this.selectedCityLocalNumberValue = ''
    this.selectedCityLocalNumber = ''
    this.selectedCityStateLocalNumber = ''
  }

  setSearchCity(searchCity: string) {
    this.searchCity = searchCity
  }
  setAreaCode(areaCode: string) {
    this.areaCode = areaCode
  }
  setLocalDigits(digits: string) {
    this.localDigits = digits
  }
  setTollFreeDigits(digits: string) {
    this.tollFreeDigits = digits
  }
  setRefreshTrigger() {
    this.refreshTrigger = nanoid()
  }
  setOpenFirstTollFreeNumberModal(value: boolean) {
    this.openFirstTollFreeNumberModal = value
  }
  setOnlyTollFree(value: boolean) {
    this.onlyTollFree = value
  }

  setLoadingData(loadingData: boolean) {
    this.loadingData = loadingData
  }
}

export default new AddNumberStore()
