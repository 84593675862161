// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PuJMTnA_jdVO79c8s8bC .MuiTooltip-popper{z-index:100 !important}.OZK8b6DsVHBto7V8z8Q5{max-height:507px;overflow-y:auto}.bK7NN_C1RJjZQtP3_pEc{width:fit-content}.ZBQF77nTThNsbnOAJ8jP,.O630paJCoHInAAt4bVEN{min-height:48px;align-items:center;padding-top:8px;padding-left:8px;padding-right:8px}.ZBQF77nTThNsbnOAJ8jP:not(:last-child),.O630paJCoHInAAt4bVEN:not(:last-child){border-bottom:1px solid var(--primary-color-gray-3)}.O630paJCoHInAAt4bVEN{padding-left:0;padding-right:0}.d_vRzQtMhjqOLIxseiBq{padding:0 8px}.F4Yuy0z28WzCWDeKPyKg{justify-content:space-between;padding:0 8px}`, "",{"version":3,"sources":["webpack://./src/components/Schedule/styles.module.scss"],"names":[],"mappings":"AAGI,yCACE,sBAAA,CAIN,sBACE,gBAAA,CACA,eAAA,CAEF,sBAEE,iBAAA,CAEF,4CACE,eAAA,CAKA,kBAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CAPA,8EACE,mDAAA,CAQJ,sBACE,cAAA,CACA,eAAA,CAEF,sBACE,aAAA,CAGF,sBACE,6BAAA,CACA,aAAA","sourcesContent":["\n.muiTooltip{\n  :global{\n    .MuiTooltip-popper{\n      z-index: 100!important;\n    }\n  }\n}\n.wrapTT{\n  max-height: 507px;\n  overflow-y: auto;\n}\n.wrap{\n  //display: flex;\n  width: fit-content;\n}\n.row, .rowRecurring{\n  min-height: 48px;\n  &:not(:last-child){\n    border-bottom: 1px solid var(--primary-color-gray-3);\n  }\n\n  align-items: center;\n  padding-top: 8px;\n  padding-left: 8px;\n  padding-right: 8px;\n}\n.rowRecurring{\n  padding-left: 0;\n  padding-right: 0;\n}\n.wrapBtnRecurring{\n  padding: 0 8px;\n}\n\n.btn{\n  justify-content: space-between;\n  padding: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"muiTooltip": `PuJMTnA_jdVO79c8s8bC`,
	"wrapTT": `OZK8b6DsVHBto7V8z8Q5`,
	"wrap": `bK7NN_C1RJjZQtP3_pEc`,
	"row": `ZBQF77nTThNsbnOAJ8jP`,
	"rowRecurring": `O630paJCoHInAAt4bVEN`,
	"wrapBtnRecurring": `d_vRzQtMhjqOLIxseiBq`,
	"btn": `F4Yuy0z28WzCWDeKPyKg`
};
export default ___CSS_LOADER_EXPORT___;
