// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pydm44bdqYtONpYPHBfH{margin-left:4px;cursor:pointer;display:inline-flex}.Pydm44bdqYtONpYPHBfH.GPRNR20P74npfVVBz7qg{margin-left:8px}.Pydm44bdqYtONpYPHBfH.P4OopA1cUGQKI8TVsLqw svg path{fill:var(--states-color-error-1)}.Pydm44bdqYtONpYPHBfH.P4OopA1cUGQKI8TVsLqw:hover svg path{fill:var(--states-color-error-2)}.Pydm44bdqYtONpYPHBfH:hover svg path{fill:var(--primary-color-gray-1)}`, "",{"version":3,"sources":["webpack://./src/components/InfoTooltip/styles.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AACA,sBACE,eAAA,CACA,cAAA,CACA,mBAAA,CACA,2CACE,eAAA,CC+BA,oDACE,gCD7BsB,CC4BxB,0DACE,gCD3BwB,CC0B1B,qCACE,gCDvBsB","sourcesContent":["@import \"src/styles/mixin\";\n.wrap{\n  margin-left: 4px;\n  cursor: pointer;\n  display: inline-flex;\n  &.large{\n    margin-left: 8px;\n  }\n  &.error{\n    @include svg-color-fill(var(--states-color-error-1));\n    &:hover{\n      @include svg-color-fill(var(--states-color-error-2))\n    }\n  }\n  &:hover{\n    @include svg-color-fill(var(--primary-color-gray-1))\n  }\n\n}","@mixin svg-color($color){\n  svg {\n    g {\n      path {\n        fill: $color;\n      }\n    }\n\n  }\n}\n@mixin svg-color-rect($color){\n  svg {\n    g {\n      rect {\n        fill: $color;\n      }\n    }\n\n  }\n}\n\n@mixin svg-color-stroke($color){\n  svg{\n    path{\n      stroke: $color;\n    }\n  }\n}\n@mixin svg-color-circle($color){\n  svg{\n    circle{\n      fill: $color;\n    }\n  }\n}\n@mixin svg-color-fill($color){\n  svg{\n    path{\n      fill: $color;\n    }\n  }\n}\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Pydm44bdqYtONpYPHBfH`,
	"large": `GPRNR20P74npfVVBz7qg`,
	"error": `P4OopA1cUGQKI8TVsLqw`
};
export default ___CSS_LOADER_EXPORT___;
