import styles from './styles.module.scss'
import { SizedBox } from 'components/SizedBox'
import { ObjectiveCard } from 'src/routes/main/dashboard/Objectives/ObjectiveCard/ObjectiveCard'
import objectivesStore from 'store/dashboard/objectives/objectivesStore'
import { observer } from 'mobx-react-lite'

export const Objectives = observer(() => {
  return (
    <>
      <SizedBox height={48} />
      <div className={styles.wrap}>
        <h2 className={'gray2'}>
          Get to your objectives by completing simple steps lessons
        </h2>
        <SizedBox height={56} />
        <div className={styles.cardsWrap}>
          {objectivesStore.objectives.map((objective) => (
            <ObjectiveCard key={objective.step} {...objective} />
          ))}
        </div>
      </div>
    </>
  )
})
