import { observer } from 'mobx-react-lite'
import {
  ContentType,
  useGetCampaignStepStatisticsQuery,
} from 'src/generated/graphql'
import { useEffect } from 'react'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'
import { MessageStatisticCards } from 'components/MessageStatisticCards/MessageStatisticCards'

export const AdditionalContent = observer(() => {
  const { activeStep, stepStatisticTableStore, setStepMessageStatistics } =
    campaignDetailStore

  const { data, refetch } = useGetCampaignStepStatisticsQuery({
    variables: {
      uuid: activeStep?.uuid,
      dateRange: stepStatisticTableStore.rangeDatePickerStore.dateRange,
    },
    skip: stepStatisticTableStore.rangeDatePickerStore.isSkipByDateRange,
  })

  useEffect(() => {
    setStepMessageStatistics(data?.getCampaignStepStatistics || null)
  }, [data])

  useEffect(() => {
    refetch().catch(console.error)
  }, [stepStatisticTableStore.refreshTrigger])
  return (
    <MessageStatisticCards
      messageStatistic={data?.getCampaignStepStatistics}
      type={activeStep?.type || ContentType.Sms}
      tableStore={stepStatisticTableStore}
    />
  )
})
