import { observer } from 'mobx-react-lite'
import campaignsStore from 'store/campaigns/campaignsStore'
import { getManyCampaignsActions } from 'src/routes/main/campaigns/functions/getManyCampaignsActions'

export const ManySelectContent = observer(() => {
  const actions = getManyCampaignsActions(
    campaignsStore.tableStore.checkedRows,
    true
  )

  return (
    <>
      {actions.map((Action, index) => (
        <Action key={index} medium />
      ))}
    </>
  )
})
