import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import superAdminStore from 'store/superAdmin/superAdminStore'
import { NewPasswordContent } from 'src/routes/settings/account/routes/PrivacyAccount/password/NewPasswordContent'
import { useResetMemberPasswordMutation } from 'src/generated/graphql'

export const ResetPasswordModal = observer(() => {
  const [resetMemberPassword, { loading }] = useResetMemberPasswordMutation()
  const onClose = () => {
    superAdminStore.setResetPasswordUserId('')
  }
  const onResetFromAdmin = async (password: string) => {
    try {
      await resetMemberPassword({
        variables: {
          memberId: superAdminStore.resetPasswordUserId,
          password,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Modal
      open={!!superAdminStore.resetPasswordUserId}
      onClose={onClose}
      title={'Reset password'}
      minWidth={528}
    >
      <div className={'pModal'}>
        <NewPasswordContent
          onCancel={onClose}
          fromAdmin
          onResetFromAdmin={onResetFromAdmin}
          loadingFromAdmin={loading}
        />
      </div>
    </Modal>
  )
})
