// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WYhKUnX3K2w8VBV0VANx{padding:0 32px 32px 32px}.tDAuzaoPUrgVb7lenzOg{margin-top:40px;display:flex;column-gap:12px}.VWGDOG1_7WqouAy4iGpE{display:flex;justify-content:space-between;margin-bottom:8px}.TKOEz2cN8JXILPJSsh10{cursor:pointer}.TKOEz2cN8JXILPJSsh10 span:hover{color:var(--states-color-error-2)}.arjLbFOr0oc82qHcP6VY{cursor:pointer}.arjLbFOr0oc82qHcP6VY span:hover{color:var(--primary-color-blue-4)}.Jx15h948fh3SJysPLCYG{display:grid;row-gap:24px}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/UnsubscribeContactModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,iBAAA,CAEF,sBACE,cAAA,CAEE,iCACE,iCAAA,CAIN,sBACE,cAAA,CAEE,iCACE,iCAAA,CAIN,sBACE,YAAA,CACA,YAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n}\n.actions{\n  margin-top: 40px;\n  display: flex;\n  column-gap: 12px;\n}\n.row{\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n.unsubscribe{\n  cursor: pointer;\n  span{\n    &:hover{\n      color: var(--states-color-error-2);\n    }\n  }\n}\n.resubscribe{\n  cursor: pointer;\n  span{\n    &:hover{\n      color: var(--primary-color-blue-4);\n    }\n  }\n}\n.wrapContent{\n  display: grid;\n  row-gap: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `WYhKUnX3K2w8VBV0VANx`,
	"actions": `tDAuzaoPUrgVb7lenzOg`,
	"row": `VWGDOG1_7WqouAy4iGpE`,
	"unsubscribe": `TKOEz2cN8JXILPJSsh10`,
	"resubscribe": `arjLbFOr0oc82qHcP6VY`,
	"wrapContent": `Jx15h948fh3SJysPLCYG`
};
export default ___CSS_LOADER_EXPORT___;
