import styles from './styles.module.scss'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Phone } from 'icons/16pxNoMask/Phone.svg'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { ReactComponent as Deliver } from 'icons/16pxNoMask/Deliver.svg'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { IBroadcast } from 'src/types/IBroadcast'
import { ReactComponent as StatusIcon } from 'icons/16pxNoMask/Status/Status.svg'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ReactComponent as Calendar } from 'icons/16pxNoMask/Calendar.svg'
import { ReactComponent as Contacts } from 'icons/16pxNoMask/ContactsFill.svg'
import { ContentType } from 'src/generated/graphql'
import { DetailTypeContent } from 'components/DetailsModalContent/DetailTypeContent'
import { ItemsRender } from 'components/NewTable/cell/ItemsRender/ItemsRender'
import { UserDateContent } from 'components/Date/UserDateContent'

export const DetailBroadcastContent = ({
  broadcast,
}: {
  broadcast: IBroadcast
}) => {
  return (
    <>
      <div className={styles.row}>
        <h3>{broadcast.name}</h3>
        <TestItBtn
          testMessageInput={
            broadcast.type === ContentType.Sms && broadcast.smsMessageInput
              ? {
                  fromNumberId: broadcast.sendFromPhoneNumber?.id,
                  smsMessage: broadcast.smsMessageInput,
                  toNumber: '',
                }
              : undefined
          }
          testCallMessageRequestInput={
            broadcast.type === ContentType.Voice
              ? {
                  voiceMessage: broadcast.voiceMessageInput,
                  toNumber: '',
                  fromNumberId: broadcast.sendFromPhoneNumber?.id,
                }
              : undefined
          }
          testRinglessMessageRequestInput={
            broadcast.type === ContentType.Ringless
              ? {
                  ...broadcast.ringlessMessageInput,
                  toNumber: '',
                  fromNumberId: broadcast.sendFromPhoneNumber?.id,
                }
              : undefined
          }
          fromAudio={broadcast.type !== ContentType.Sms}
        />
      </div>
      <div className={styles.grid}>
        <div className={styles.rowTable}>
          <div className={styles.left}>
            <StatusIcon />
            <span className={ColorsNames.gray1}>Status</span>
          </div>
          {broadcast?.simplifiedStatus && (
            <StatusContent status={broadcast?.simplifiedStatus} />
          )}
        </div>
        <div className={styles.rowTable}>
          <div className={styles.left}>
            <Calendar />
            <span className={ColorsNames.gray1}>Send Time</span>
          </div>
          <span>
            <UserDateContent value={broadcast?.sendTime} />
          </span>
        </div>
        <div className={styles.rowTable}>
          <div className={styles.left}>
            <Contacts />
            <span className={ColorsNames.gray1}>Send to</span>
          </div>
          <ItemsRender items={broadcast.items} />
        </div>

        <div className={styles.rowTable}>
          <div className={styles.left}>
            <Phone />
            <span className={ColorsNames.gray1}>Send from</span>
          </div>
          <span>{broadcast.sendFromPhoneNumber?.friendlyName}</span>
        </div>
        <div className={styles.rowTable}>
          <div className={styles.left}>
            <Deliver />
            <span className={ColorsNames.gray1}>Message type</span>
          </div>
          {broadcast.type && <TypeContent type={broadcast.type} />}
        </div>
      </div>
      <DetailTypeContent
        smsMessage={broadcast.smsMessage}
        ringlessMessage={broadcast.ringlessMessage}
        voiceMessage={broadcast.voiceMessage}
      />
    </>
  )
}
