import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import TableRow from '@mui/material/TableRow'
import styles from 'components/NewTable/styles.module.scss'
import TableCell from '@mui/material/TableCell'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { ReactComponent as HOVER } from 'icons/HOVER.svg'
import { ReactComponent as HOVERACTIVE } from 'icons/HOVER-ACTIVE.svg'
import classNames from 'classnames'
import { mobileActionColumn } from 'components/NewTable/store/mobileActionColumn'
import uiStore from 'store/uiStore'
import {
  ActionBtnProps,
  ColumnType,
  RequiredFields,
} from 'components/NewTable/types'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { AppSymbols } from 'src/util/symbols'

export interface HoverRowComponentProps<T> {
  row: T
  setHover: (val: boolean) => void
  tr: HTMLTableRowElement | null
}

type Props<T> = {
  row: T
  columns: Array<ColumnType<T>>
  HoverRowComponent?: FC<HoverRowComponentProps<T>>
  onRowClick?: (id: string, row: T) => void
  checkedRowsIds: string[]
  checkedRows: T[]
  setCheckedRows: (rows: T[]) => void
  withCheckbox?: boolean
  isLeftScroll?: boolean
  noSetting?: boolean
  showVisibleActionsCount?: number
  actionsSize?: number
  isSelectALl?: boolean
}

export function RowComponent<T extends RequiredFields<T>>({
  row,
  checkedRowsIds,
  setCheckedRows,
  columns,
  HoverRowComponent,
  onRowClick,
  withCheckbox,
  checkedRows,
  isLeftScroll,
  noSetting,
  showVisibleActionsCount,
  actionsSize,
}: Props<T>) {
  const [hover, setHover] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [tr, setTr] = useState<HTMLTableRowElement | null>(null)
  const isChecked = checkedRowsIds.includes(row.id)
  const onChecked = () => {
    if (isChecked) {
      setCheckedRows(
        checkedRows.filter((checkedRow) => checkedRow.id !== row.id)
      )
    } else {
      setCheckedRows([...checkedRows, row])
    }
  }
  useEffect(() => {
    setTr(ref.current?.parentElement as HTMLTableRowElement)
  }, [])
  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      className={classNames(
        hover && styles.hover,
        onRowClick && styles.clickRow,
        isChecked && styles.selectedRow
      )}
      id={row.id}
    >
      {/*<div className={styles.hoverRowContent}>dddd</div>*/}
      {withCheckbox && (
        <TableCell className={isChecked && styles.selectedRow}>
          <div className={styles.wrapCheckbox}>
            <CheckBox checked={isChecked} onChecked={onChecked} />
          </div>
        </TableCell>
      )}
      {columns.map((column) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const value = row[column.id]
        const formattedValue = column.format ? column.format(value, row) : value
        const title = column.tooltipTitle
          ? column.tooltipTitle(value, row)
          : typeof formattedValue === 'string'
          ? formattedValue
          : value
        return (
          <TableCell
            key={String(column.id)}
            align={column.align}
            className={classNames(
              isLeftScroll && column.sticky && styles.stickyCell,
              column.stickyRight && styles.stickyRight
            )}
            onClick={() =>
              column.id !== mobileActionColumn.id &&
              onRowClick &&
              onRowClick(row.id, row)
            }
            title={title || undefined}
          >
            {formattedValue || (
              <span className={'s1 gray2'}>{AppSymbols.dash}</span>
            )}
          </TableCell>
        )
      })}
      {((HoverRowComponent && !row.disabledHover) ||
        (row.actions && !uiStore.isMobile)) && (
        <div
          className={classNames(
            styles.hoverRowContent,
            noSetting && styles.noSetting
          )}
          ref={ref}
        >
          <div
            className={classNames(
              styles.background,
              noSetting && styles.noSetting
            )}
          >
            <div
              className={classNames(
                styles.hoverSvgBg,
                actionsSize && styles[`size${actionsSize}`],
                row.actions &&
                  styles[
                    `size${row.actions?.length > 4 ? 4 : row.actions?.length}`
                  ]
              )}
            >
              {isChecked ? <HOVERACTIVE /> : <HOVER />}
            </div>

            <div className={styles.wrap}>
              {!uiStore.isMobile && (
                <Actions
                  actions={row.actions}
                  setHover={(val) => setHover(val)}
                  defaultVisibleMoreBtnContent={
                    row.defaultVisibleMoreBtnContent
                  }
                  onHideDefaultVisible={row.onHideDefaultVisible}
                  showVisibleActionsCount={showVisibleActionsCount}
                  tr={tr}
                />
              )}
              {HoverRowComponent && (
                <HoverRowComponent
                  row={row}
                  setHover={(val) => setHover(val)}
                  tr={tr}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </TableRow>
  )
}

const Actions = <T,>({
  actions,
  setHover,
  defaultVisibleMoreBtnContent,
  onHideDefaultVisible,
  showVisibleActionsCount = 3,
  tr,
}: {
  actions?: React.FC<ActionBtnProps<T>>[]
  setHover: (val: boolean) => void
  defaultVisibleMoreBtnContent?: JSX.Element
  onHideDefaultVisible?: () => void
  showVisibleActionsCount?: number
  tr?: HTMLTableRowElement | null
}) => {
  const rowActions = actions?.slice(0, showVisibleActionsCount)
  const moreActions = actions?.slice(showVisibleActionsCount)
  useEffect(() => {
    if (defaultVisibleMoreBtnContent) {
      setHover(true)
    }
  }, [])
  return (
    <>
      {rowActions?.map((Action, index) => {
        return <Action key={index} menu={false} tr={tr} />
      })}
      {!!moreActions?.length && moreActions?.length > 1 && (
        <MoreBtn
          menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
          defaultVisibleMoreBtnContent={defaultVisibleMoreBtnContent}
          onHideDefaultVisible={onHideDefaultVisible}
          hoverGray
          tooltip={'More'}
          setOpen={setHover}
        />
      )}
      {moreActions?.length === 1 &&
        moreActions?.map((Action, index) => {
          return <Action key={index} menu={false} />
        })}
    </>
  )
}
