import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import manualContactStore from 'store/contacts/manualContactStore'
import { CommonContent } from 'src/routes/main/contacts/modals/AddContactModal/CommonContent'
import { useCreateContactMutation } from 'src/generated/graphql'
import React, { useEffect, useState } from 'react'
import contactStore from 'store/contacts/contactStore'
import billingStore from 'store/settings/billing/billingStore'
import importContactStore from 'store/contacts/importContactStore'
import { ScrollWrapper } from 'components/Wrappers/ScrollWrapper/ScrollWrapper'

export const AddContactModal = observer(() => {
  const onClose = () => manualContactStore.closeAddContactModal()
  const [createContact, { error }] = useCreateContactMutation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (error?.graphQLErrors?.length && error?.graphQLErrors[0].extensions.id) {
      manualContactStore.setErrorContactId(
        error?.graphQLErrors[0].extensions.id as number
      )
    }
  }, [error?.graphQLErrors])

  const onAdd = () => {
    setLoading(true)
    createContact({
      variables: {
        createContactInput: manualContactStore.createContactInput,
      },
    })
      .then((res) => {
        manualContactStore.onSuccessEditCb &&
          manualContactStore.onSuccessEditCb()
        if (res.data?.createContact) {
          contactStore.tableStoreContacts.onRefresh()
        }
        manualContactStore.closeAddContactModal()
        contactStore.setStatisticTrigger()
      })
      .catch((e) => {
        manualContactStore.setError(e?.message)
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (
      manualContactStore.openContactModal &&
      !manualContactStore.acceptNewAddContact
    ) {
      billingStore.setLimitModalFor('contacts')
    }
  }, [
    manualContactStore.acceptNewAddContact,
    manualContactStore.openContactModal,
  ])
  if (
    (!importContactStore.noLimitContact &&
      manualContactStore.loadingCheckContactsLimit) ||
    !manualContactStore.acceptNewAddContact
  ) {
    return <></>
  }
  return (
    <Modal
      open={manualContactStore.openContactModal}
      onClose={onClose}
      title={'Add contact'}
      centered
    >
      <div className={styles.wrap}>
        <ScrollWrapper>
          <CommonContent />
        </ScrollWrapper>
        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            disabled={manualContactStore.disabled || loading}
            onClick={onAdd}
          >
            Add contact
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
