import styles from './styles.module.scss'
import NewTable from 'components/NewTable/NewTable'
import { IInvoice } from 'src/routes/settings/billing/Invoices/types'
import billingStore from 'store/settings/billing/billingStore'
import { GetInvoicesQuery, useGetInvoicesQuery } from 'src/generated/graphql'
import { useState } from 'react'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as AngleDown } from 'icons/16px/Angle-Down.svg'
import { useSetData } from 'src/hooks/useSetData'

export const Invoices = observer(() => {
  const options = useTablePaginationOptions(billingStore.invoiceTableStore)
  const queryResult = useGetInvoicesQuery(options)

  const setData = (data: GetInvoicesQuery) => {
    if (data.getInvoices) {
      data.getInvoices.content &&
        billingStore.setInvoices(data.getInvoices.content)
      setTotal(data?.getInvoices?.total || 0)
    }
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData,
    refreshDeps: [billingStore.invoiceTableStore.refreshTrigger],
  })

  const [total, setTotal] = useState(0)

  const invoices = billingStore.invoices

  if (!invoices.length) {
    return <></>
  }

  const isShowMore = invoices.length && invoices.length < total

  const showMore = () => {
    billingStore.invoiceTableStore.setRowsPerPage(
      billingStore.invoiceTableStore.rowsPerPage + 10
    )
  }

  return (
    <div className={styles.wrap}>
      <span className={'s2 medium gray1 mb8'}>Invoices</span>
      <div className={styles.wrapTable}>
        <NewTable<IInvoice>
          tableStore={billingStore.invoiceTableStore}
          rows={invoices}
          columns={billingStore.invoiceTableStore.visibleColumns}
          maxHeight={'100%'}
          noStickyHeader
          firstPaddingLeft16
          pageLoading={loading || refetchLoading}
          firstLoading={firstLoading}
          noPagination
        />
      </div>
      {isShowMore && (
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.small}
          onClick={showMore}
          disabled={loading}
          className={'mt16'}
          p12
        >
          <AngleDown />
          Show more
        </Button>
      )}
    </div>
  )
})
