import { IObjective } from 'store/dashboard/objectives/types'
import { nanoid } from 'nanoid'

export const objectives: IObjective[] = [
  {
    step: 1,
    title: 'Getting started',
    currentLesson: 0,
    lessons: [
      {
        id: nanoid(),
        title: 'Import Contacts',
        durationMin: 5,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Send your first message',
        durationMin: 5,
        complete: false,
      },
    ],
  },
  {
    step: 2,
    title: 'Send alerts and notifications',
    currentLesson: 0,
    lessons: [
      {
        id: nanoid(),
        title: 'Send alerts and notifications 1',
        durationMin: 1,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Send alerts and notifications 2',
        durationMin: 2,
        complete: false,
      },
    ],
  },
  {
    step: 3,
    title: 'Capture leads',
    currentLesson: 0,
    lessons: [
      {
        id: nanoid(),
        title: 'Capture leads 1',
        durationMin: 1,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Capture leads 2',
        durationMin: 1,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Capture leads 3',
        durationMin: 1,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Capture leads 4',
        durationMin: 1,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Capture leads 5',
        durationMin: 1,
        complete: false,
      },
    ],
  },
  {
    step: 4,
    title: 'Drive sales with promotional messages',
    currentLesson: 0,
    lessons: [
      {
        id: nanoid(),
        title: 'Drive sales with promotional messages 1',
        durationMin: 2,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Drive sales with promotional messages 2',
        durationMin: 2,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Drive sales with promotional messages 3',
        durationMin: 3,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Drive sales with promotional messages 4',
        durationMin: 1,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Drive sales with promotional messages 5',
        durationMin: 2,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Drive sales with promotional messages 6',
        durationMin: 3,
        complete: false,
      },
    ],
  },
  {
    step: 5,
    title: 'Integration',
    currentLesson: 0,
    lessons: [
      {
        id: nanoid(),
        title: 'Integration 1',
        durationMin: 2,
        complete: false,
      },
      {
        id: nanoid(),
        title: 'Integration 2',
        durationMin: 3,
        complete: false,
      },
    ],
  },
]
