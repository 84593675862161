// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jnJuQz8ElBH7eqVhvMrq{max-width:512px}.H2u1akydLAzfrBinaKYK{padding:0 32px 32px 32px}.GAWT1uyInr9MoUWJkowB{min-width:280px;padding:16px;display:flex;justify-content:center;align-items:center;flex-direction:column}.GAWT1uyInr9MoUWJkowB p{padding:0 8px;text-align:center}.qrSVdAAW1a71eTpVh5rh{display:grid;grid-template-rows:1fr min-content;min-height:calc(100% - 122px)}`, "",{"version":3,"sources":["webpack://./src/routes/settings/account/routes/PrivacyAccount/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,wBAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,wBACE,aAAA,CACA,iBAAA,CAGJ,sBACE,YAAA,CACA,kCAAA,CACA,6BAAA","sourcesContent":[".wrap{\n  max-width: 512px;\n}\n.modalContent{\n  padding: 0 32px 32px 32px;\n}\n.checkModal{\n  min-width: 280px;\n  padding: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  p{\n    padding: 0 8px;\n    text-align: center;\n  }\n}\n.wrapPrivacyTab{\n  display: grid;\n  grid-template-rows: 1fr min-content;\n  min-height: calc(100% - 122px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `jnJuQz8ElBH7eqVhvMrq`,
	"modalContent": `H2u1akydLAzfrBinaKYK`,
	"checkModal": `GAWT1uyInr9MoUWJkowB`,
	"wrapPrivacyTab": `qrSVdAAW1a71eTpVh5rh`
};
export default ___CSS_LOADER_EXPORT___;
