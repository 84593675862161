import { CardType } from 'src/enums/cardType'

export const getCardType = (value: string): CardType => {
  if (!value) return CardType.unknown
  const replaceValue = String(value).replace(/ /g, '')
  const isVisa = /^4[0-9]*$/
  const isMasterCard = /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]*$/
  if (isVisa.test(replaceValue)) {
    return CardType.visa
  }
  if (isMasterCard.test(replaceValue)) {
    return CardType.mastercard
  }
  return CardType.unknown
}
