import { ActionsEnum } from 'components/Button/types'
import { Status } from 'src/generated/graphql'
import { CustomStatuses } from 'components/StatusContent/types'

export function getActionsByStatus(
  status: Status | CustomStatuses
): ActionsEnum[] {
  if (status === Status.Active || status === Status.Disabled) {
    return [
      ActionsEnum.switch,
      ActionsEnum.details,
      ActionsEnum.edit,
      // ActionsEnum.analytics,
      ActionsEnum.export,
      ActionsEnum.duplicate,
      ActionsEnum.archive,
      ActionsEnum.delete,
    ]
  }
  if (status === Status.Draft) {
    return [
      ActionsEnum.details,
      ActionsEnum.edit,
      ActionsEnum.duplicate,
      ActionsEnum.delete,
    ]
  }
  if (status === Status.Archived) {
    return [
      ActionsEnum.details,
      // ActionsEnum.analytics,
      ActionsEnum.export,
      ActionsEnum.duplicate,
      ActionsEnum.restore,
      ActionsEnum.delete,
    ]
  }
  return []
}
