import { useEffect } from 'react'
import companyStore from 'store/settings/company/companyStore'
import user from 'store/user/user'
import billingStore from 'store/settings/billing/billingStore'
import mainStore from 'store/mainStore'
import { ClStatus, Status } from 'src/generated/graphql'
import { usePostHog } from 'posthog-js/react'
import { isPostHog } from 'src/util/variables'
import TagManager from 'react-gtm-module'
import auth from 'store/auth'
import configStore from 'store/configStore'
import { initPostHogFeaturesFlags } from 'src/util/posthog/initPostHogFeaturesFlags'

export function useInitAnalytics() {
  const posthog = usePostHog()
  useEffect(() => {
    if (companyStore.id) {
      if (!mainStore.analyticTriggerUpdate) {
        if (analytics.reset) {
          analytics.reset()
        }
      }

      const companyId = companyStore.id

      const companyInfo = {
        name: companyStore.companyName,
        createdAt: companyStore.organization?.createdAt,
        plan: billingStore.clSubscription?.price?.planTitle,
        monthly_spend: billingStore.clSubscription?.price?.priceValue,
      }
      const userStatus = billingStore.clSubscription?.status || 'Lead'

      const userInfo = {
        userId: user.member?.uniqueId,
        userHash: user.member?.memberHash,
        email: user.member?.email,
        name: user.member?.name,
        firstName: user.member?.firstName,
        lastName: user.member?.lastName,
        phone: user.member?.internationalPhone,
        userRole: user.member?.role,
        completeRegister: !!billingStore.clSubscription,
        userStatus:
          userStatus === ClStatus.Trialing ? Status.Trial : userStatus,

        company: {
          id: companyId,
        },
      }

      if (isPostHog && posthog) {
        const postfix =
          process.env.ENV === 'prod'
            ? ''
            : `-${(process.env.API || '').replace('https://api.', '')}`

        const postHogCompanyId = `${companyId}${postfix}`

        userInfo.userId &&
          posthog?.identify(userInfo.userId, {
            ...userInfo,
            company: {
              id: postHogCompanyId,
            },
          })

        companyId && posthog?.group('company', postHogCompanyId, companyInfo)
        initPostHogFeaturesFlags(posthog)
      } else {
        configStore.setShowSegmentsByApi()
      }

      if (analytics.identify) {
        analytics.identify(user.member?.uniqueId, userInfo, {
          integrations: {
            Intercom: {
              user_hash: user.member?.memberHash,
            },
          },
        })
      }

      analytics.group && analytics.group(companyId, companyInfo)
      analytics.page && analytics.page()

      const dataLayer = {
        user_id: userInfo.userId,
        user_created_at: companyStore.organization?.createdAt,
        user_name: userInfo.name,
        user_phone_number: userInfo.phone,
        email: userInfo.email,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        user_role: userInfo.userRole,
        user_status: userInfo.userStatus,
        user_hash: userInfo.userHash,
        company_id: companyId,
        company_name: companyStore.companyName,
        company_created_at: companyStore.organization?.createdAt,
        plan: billingStore.clSubscription?.price?.planTitle,
        plan_status: billingStore.clSubscription?.status,
        monthly_spend: billingStore.clSubscription?.price?.priceValue,
        auto_recharge_status: billingStore.autoRecharge,
        company_size: companyStore.organization?.employees,
      }

      if (!auth.initTagManager) {
        auth.setInitTagManager()

        TagManager.initialize({
          gtmId: process.env.GTM_ID || '',
          dataLayer,
        })
      } else {
        TagManager.dataLayer({
          dataLayer,
        })
      }
    }
  }, [companyStore.id, mainStore.analyticTriggerUpdate])
}
