import { TriggerFragmentFragment, TriggerInput } from 'src/generated/graphql'
import { ITrigger } from 'src/types/ITrigger'
import { mockApps } from 'store/triggers/mockData'
import { transformToInputs } from 'src/util/transform'

export const transformTrigger = (
  trigger: TriggerFragmentFragment
): ITrigger => {
  const contactsListsIds: string[] = []
  trigger.triggerLists?.forEach((list) => {
    const id = list?.listing?.id
    if (id !== undefined) {
      contactsListsIds.push(id)
    }
  })
  return {
    ...trigger,
    id: String(trigger.id),
    listIds: contactsListsIds,
    ...transformToInputs(trigger),
    appOption:
      mockApps.find((app) => app.value === trigger.app) ||
      mockApps[mockApps.length - 1],
  }
}

export const transformTriggerToBack = (trigger: ITrigger): TriggerInput => {
  return {
    app: trigger.app,
    appPhoneField: trigger.appPhoneField,
    id: trigger.id || undefined,
    name: trigger.name.trim(),
    sendFromCompany: trigger.sendFromCompany?.trim() || '',
    smsMessage: trigger.smsMessageInput,
    status: trigger.status,
    sendFromPhoneNumber: trigger.sendFromPhoneNumber
      ? { id: trigger.sendFromPhoneNumber?.id }
      : undefined,
    voiceMessage: trigger.voiceMessageInput,
    ringlessMessage: trigger.ringlessMessageInput,
    listIds: trigger.listIds,
  }
}
