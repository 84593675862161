import { Modal } from 'components/Modal/Modal'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'

export const SuccessPausedSubscriptionModal = observer(() => {
  const onClose = () => {
    billingStore.setOpenSuccessPauseSubscriptionModal(false)
  }
  return (
    <Modal
      open={billingStore.openSuccessPauseSubscriptionModal}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon />
        <h3>Your subscription has been paused</h3>
        <p className={'par2 gray1'}>
          Your subscription will be paused at the end of the billing cycle:{' '}
          <b>Jan 1, 2022</b>. Pause period is up to 3 months. After that, your
          plan will automatically resume.
        </p>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          fullWidth
          onClick={onClose}
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
