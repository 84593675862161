import { useGetAllBroadcastsQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import billingStore from 'store/settings/billing/billingStore'

export function useGetBroadcast() {
  const { data } = useGetAllBroadcastsQuery({
    variables: {
      page: {
        pageNumber: 0,
        pageSize: 25,
      },
    },
    skip: !!broadcastsStore.broadcasts.length || !billingStore.isTrial,
  })
  useEffect(() => {
    if (data) {
      broadcastsStore.setData(data)
    }
  }, [data])
}
