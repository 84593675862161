import { ReactComponent as Facebook } from 'icons/16px/Facebook.svg'
import { ReactComponent as Twitter } from 'icons/16px/Twitter.svg'
import { ReactComponent as Instagram } from 'icons/16px/Instagram.svg'
import { ReactComponent as Linkedin } from 'icons/16px/Linkedin.svg'
import { ReactComponent as Youtube } from 'icons/16px/Youtube.svg'
import { SocialMediaEnums } from 'src/enums/SocialMediaEnums'

const icons = {
  [SocialMediaEnums.Facebook]: <Facebook />,
  [SocialMediaEnums.Twitter]: <Twitter />,
  [SocialMediaEnums.Instagram]: <Instagram />,
  [SocialMediaEnums.Linkedin]: <Linkedin />,
  [SocialMediaEnums.Youtube]: <Youtube />,
}

export const SocialMediaIcon = ({ icon }: { icon: SocialMediaEnums }) =>
  icons[icon]
