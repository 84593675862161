import { useEffect, useState } from 'react'
import { ApolloError, QueryResult } from '@apollo/client'

export function useSetData<T>({
  queryResult,
  setData,
  refreshDeps = [],
  disabledRefetch,
  onError,
}: {
  queryResult: QueryResult<T, any>
  setData: (data: T) => void
  refreshDeps?: string[]
  disabledRefetch?: boolean
  onError?: (error: ApolloError) => void
}) {
  const [refetchLoading, setRefetchLoading] = useState(true)
  const [firstLoading, setFirstLoading] = useState(true)
  const { data, error, refetch, loading } = queryResult
  useEffect(() => {
    if (error) {
      setFirstLoading(false)
      setRefetchLoading(false)
      onError && onError(error)
    }
  }, [error])

  useEffect(() => {
    if (!disabledRefetch) {
      setRefetchLoading(true)
      refetch()
        .catch((e) => console.error(e))
        .finally(() => {
          setRefetchLoading(false)
        })
    } else {
      setRefetchLoading(false)
    }
  }, refreshDeps)

  useEffect(() => {
    if (data) {
      setFirstLoading(false)
      setData(data)
    }
  }, [data])

  return {
    refetchLoading,
    firstLoading,
    loading,
  }
}
