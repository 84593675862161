// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C3WUMolOK1tzh8A27EFf{padding:0 32px 32px 32px}.x0WdHGTjrmA2roNdS_I1{display:flex;column-gap:12px;padding-top:40px}.LIzEZsho3hC8fCJcy0Ou{display:grid;grid-template-columns:512px 200px;column-gap:24px}.DbKFD8Lt0LyqjL76qNS3{height:fit-content;padding:20px 16px;background-color:var(--primary-color-gray-4);border-radius:16px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/modals/PauseSubscriptionModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,YAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,iCAAA,CACA,eAAA,CAEF,sBACE,kBAAA,CACA,iBAAA,CACA,4CAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  padding: 0 32px 32px 32px;\n}\n.actions{\n  display: flex;\n  column-gap: 12px;\n  padding-top: 40px;\n}\n\n.step0Wrap{\n  display: grid;\n  grid-template-columns: 512px 200px;\n  column-gap: 24px;\n}\n.card{\n  height: fit-content;\n  padding: 20px 16px;\n  background-color: var(--primary-color-gray-4);\n  border-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `C3WUMolOK1tzh8A27EFf`,
	"actions": `x0WdHGTjrmA2roNdS_I1`,
	"step0Wrap": `LIzEZsho3hC8fCJcy0Ou`,
	"card": `DbKFD8Lt0LyqjL76qNS3`
};
export default ___CSS_LOADER_EXPORT___;
