import { SizedBox } from 'components/SizedBox'
import styles from 'src/routes/main/broadcasts/createBroadcast/newBroadcast/style.module.scss'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { VerifyNumber } from 'components/CreateElement/VerifyNumber'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { IOption } from 'src/types/IOption'
import numbersStore from 'store/settings/numbers/numbersStore'
import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import { Separator } from 'components/Separator/Separator'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ReactComponent as Phone } from 'icons/16px/Phone.svg'
import { VerifyCallerIdModal } from 'components/CreateElement/modals/VerifyCallerIdModal'

export const SendFromComponentVR = observer(
  ({ store }: { store: CreateStoreTypes }) => {
    const onSelectNumber = (option: IOption) => {
      store.setVoicePhone(String(option.value))
    }
    const selectedOption = useMemo(
      () =>
        numbersStore.voicePhoneNumbersOptions.find(
          (opt) => opt.value === store.voicePhone
        ),
      [numbersStore.voicePhoneNumbersOptions, store.voicePhone]
    )

    const BottomDropdownButton = (props: IBottomDropdownButtonProps) => {
      return (
        <div className={'stickyBottom'}>
          {!!numbersStore.voicePhoneNumbersOptions.length && (
            <Separator marginBlock={8} />
          )}
          <VerifyNumber
            {...props}
            setPhone={(phone) => store.setVoicePhone(phone)}
          />
          {/*<AddNumber*/}
          {/*  {...props}*/}
          {/*  setPhone={(phone) => store.setSMSPhone(phone)}*/}
          {/*/>*/}
        </div>
      )
    }

    return (
      <>
        <VerifyCallerIdModal />
        <SizedBox height={40} />
        <div className={styles.sendWrap}>
          <Dropdown
            selectedOption={selectedOption}
            onSelect={onSelectNumber}
            labelInput={'Send from'}
            options={numbersStore.voicePhoneNumbersOptions}
            openOnFocus
            BottomDropdownButton={BottomDropdownButton}
            // dropDownContentWidth={270}
            // tabIndex={3}
            // withPhoneInput
            leftIcon={
              <AppIcon>
                <Phone />
              </AppIcon>
            }
            inputValue={
              String(
                numbersStore.numbersMapByFriendly.get(store.voicePhone)
                  ?.friendlyName || ''
              ) || store.voicePhone
            }
            setInputValue={(val) => store.setVoicePhone(String(val))}
            // setRefInputPhone={setRefInput}
            grouping
            // dropDownContentWidth={450}
          />
        </div>
      </>
    )
  }
)
