// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jOXXLp88bCTpIxwaAzhH{width:512px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/account/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA","sourcesContent":[".wrapTab{\n  width: 512px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTab": `jOXXLp88bCTpIxwaAzhH`
};
export default ___CSS_LOADER_EXPORT___;
