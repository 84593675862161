import styles from './style.module.scss'
import {
  MassMessageCard,
  MassMessageItem,
} from 'components/MassMessageCard/MassMessageCard'
import { RoutesEnum } from 'src/routes/routes'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'

const massMessageItems: MassMessageItem[] = [
  {
    title: 'Broadcast',
    description: 'Send instant text and voice messages',
    icon: 'broadcasts',
    to: RoutesEnum.newBroadcast,
  },
  {
    title: 'Campaign',
    description:
      'Send text and voice messages automatically in on simple sequence',
    icon: 'campaigns',
    to: RoutesEnum.newCampaign,
  },
  {
    title: 'Trigger',
    description: 'Send instant text and voice messages via API or webhooks',
    icon: 'triggers',
    to: RoutesEnum.newTrigger,
  },
]

const title = 'Call Loop Mass Messages'

export const CreateMessages = observer(() => {
  return (
    <div className={classNames(styles.wrap, styles.center)}>
      {uiStore.isMobile ? <h3>{title}</h3> : <h1>{title}</h1>}
      <p className={'gray1'}>What would you like to create for today?</p>
      <div className={styles.massMessageItemsWrap}>
        {massMessageItems.map((item) => (
          <MassMessageCard {...item} key={item.title} />
        ))}
      </div>
    </div>
  )
})
