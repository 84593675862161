import { FC, useState } from 'react'
import { MoreBtnItemProps } from 'components/Button/MoreBtn'
import { RoutesEnum } from 'src/routes/routes'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { useNavigate, useParams } from 'react-router-dom'
import { useListsFunctions } from 'src/routes/main/contacts/contctLists/hooks/useListsFunctions'
import { IContactList } from 'src/types/IContactList'
import contactStore from 'store/contacts/contactStore'

export const DeleteBtnList: FC<MoreBtnItemProps> = ({ onCloseTT }) => {
  const params = useParams()

  const { onDeleteLists } = useListsFunctions()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  if (!params.id) return <></>
  const currentList: IContactList | undefined =
    contactStore.contactListsMap.get(params.id)

  if (!currentList) {
    return <></>
  }

  const onDelete = async () => {
    try {
      setLoading(true)
      await onDeleteLists([currentList.id])
      onCloseTT && onCloseTT()
      navigate(RoutesEnum.contactsLists)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }
  return <MenuBtn type={MenuEnum.delete} onClick={onDelete} loading={loading} />
}
