import { makeAutoObservable, runInAction } from 'mobx'
import {
  IResponseVoice,
  IResponseVoiceType,
  responseVoiceToOption,
} from 'src/Modals/CreateTextToSpeechModal/store/type'
import { IOption } from 'src/types/IOption'
import axios, { AxiosResponse } from 'axios'
import audioStore from 'store/audioStore/audioStore'

class CreateTextToSpeechStore {
  constructor() {
    makeAutoObservable(this)
  }

  openModal = false

  name = ''
  text = ''
  playing = false
  playingPreviewId = ''

  activeTabIndex = 0

  voiceMap: Map<IResponseVoiceType, IResponseVoice[]> = new Map()

  search = ''

  audioFilesMap: Map<string, { file: File; duration?: number }> = new Map()
  previewAudioFilesMap: Map<string, File> = new Map()

  selectedOption: IOption<IResponseVoice> | null = null

  loadingCreate = false
  loadingPreview = false

  get audioFile() {
    return this.audioFilesMap.get(this.textKey)?.file
  }

  get audioFileDuration() {
    return this.audioFilesMap.get(this.textKey)?.duration
  }

  get audioSrc() {
    const file = this.audioFile
    return file && URL.createObjectURL(file)
  }

  get audioPreviewSrc() {
    const file = this.previewAudioFilesMap.get(this.playingPreviewId)
    return file && URL.createObjectURL(file)
  }

  get type(): IResponseVoiceType {
    if (this.activeTabIndex === 0) {
      return 'STANDARD'
    }
    return 'PREMIUM'
  }

  get standardLengthString() {
    return this.voiceMap.get('STANDARD')?.length || ''
  }
  get premiumLengthString() {
    return this.voiceMap.get('PREMIUM')?.length || ''
  }

  get voices() {
    return this.voiceMap.get(this.type) || []
  }

  get voiceOptions(): IOption[] {
    return this.voices
      .filter((voice) =>
        voice.voiceName.toLowerCase().includes(this.search.toString())
      )
      .map(responseVoiceToOption)
  }

  get disabledPlay() {
    return !this.text.trim()
  }
  get disabledCreate() {
    return !this.text.trim() || !this.name.trim() || this.isError
  }

  get duration() {
    return Math.ceil(this.text.trim().length / 12)
  }

  get textKey() {
    return `${this.selectedOption?.value}${
      this.selectedOption?.data?.type
    }${this.text.trim()}`
  }

  get isError() {
    return this.text.trim().length > 2500
  }

  reset = () => {
    this.name = ''
    this.text = ''
    this.playing = false
    this.playingPreviewId = ''
  }

  setOpenModal = (value: boolean) => {
    if (value && !this.voiceMap.size) {
      this.getVoices()
    }
    if (!value) {
      this.reset()
    }
    this.openModal = value
  }

  setName = (value: string) => {
    this.name = value
  }

  setText = (value: string) => {
    this.text = value
  }

  getVoices = async () => {
    try {
      const { data }: AxiosResponse<IResponseVoice[]> = await axios.get(
        'tts/voice'
      )
      runInAction(() => {
        if (data) {
          data.forEach((voice) => {
            if (voice.types.includes('STANDARD')) {
              const old = this.voiceMap.get('STANDARD') || []
              this.voiceMap.set('STANDARD', [
                ...old,
                { ...voice, type: 'STANDARD' },
              ])
            }
            if (voice.types.includes('PREMIUM')) {
              const old = this.voiceMap.get('PREMIUM') || []
              this.voiceMap.set('PREMIUM', [
                ...old,
                { ...voice, type: 'PREMIUM' },
              ])
            }
          })
        }
        const TTSVoiceType = window.localStorage.getItem(
          'TTSVoiceType'
        ) as IResponseVoiceType
        const TTSVoiceId = window.localStorage.getItem('TTSVoiceId')
        if (TTSVoiceType && TTSVoiceId) {
          const savedVoice = this.voiceMap
            .get(TTSVoiceType as IResponseVoiceType)
            ?.find((voice) => voice.voiceId === TTSVoiceId)

          if (savedVoice) {
            this.activeTabIndex = TTSVoiceType === 'STANDARD' ? 0 : 1
            this.selectedOption = responseVoiceToOption(savedVoice)
            return
          }
        }

        this.selectedOption = this.voiceOptions[0] || null
      })
    } catch (e) {
      console.error(e)
    }
  }

  setActiveTabIndex = (index: number) => {
    this.activeTabIndex = index
  }

  setSearch = (value: string) => {
    this.search = value
  }

  onSelect = (option: IOption) => {
    window.localStorage.setItem('TTSVoiceType', this.type)
    window.localStorage.setItem('TTSVoiceId', option.value)
    this.selectedOption = option
  }

  handlePlay = async () => {
    try {
      if (!this.audioSrc) {
        this.loadingPreview = true
        const { data }: AxiosResponse<File> = await axios.post(
          'tts/play',
          {
            text: this.text.trim(),
            voiceId: this.selectedOption?.value,
            type: this.selectedOption?.data?.type,
          },
          {
            responseType: 'blob',
          }
        )

        this.audioFilesMap.set(this.textKey, {
          file: data,
        })
      }

      this.setPlaying(!this.playing)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingPreview = false
      })
    }
  }

  handlePlayPreview = async (voice: IResponseVoice) => {
    try {
      const id = `${voice.voiceId}${voice.type}`
      if (id === this.playingPreviewId) {
        this.playingPreviewId = ''
      } else {
        this.playingPreviewId = id
        if (!this.audioPreviewSrc) {
          const { data }: AxiosResponse<File> = await axios.post(
            'tts/play',
            {
              voiceId: voice.voiceId,
              type: voice.type,
              voicePreview: true,
            },
            {
              responseType: 'blob',
            }
          )

          this.previewAudioFilesMap.set(id, data)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  setPlaying = (value: boolean) => {
    this.playing = value
  }

  handleCreate = async () => {
    try {
      this.loadingCreate = true
      const formData = new FormData()

      formData.append(
        'request',
        new Blob(
          [
            JSON.stringify({
              text: this.text.trim(),
              voiceId: this.selectedOption?.value,
              type: this.type,
              audioName: this.name.trim(),
            }),
          ],
          {
            type: 'application/json',
          }
        )
      )
      if (this.audioFile) {
        formData.append('audioData', this.audioFile)
      }
      await axios.post('tts/save', formData)
      runInAction(() => {
        this.loadingCreate = false
      })
      audioStore.tableStore.onRefresh()
      this.setOpenModal(false)
    } catch (e) {
      console.error(e)
    }
  }

  setAudioDuration = (id: string, duration: number) => {
    const item = this.audioFilesMap.get(id)
    if (item) {
      this.audioFilesMap.set(id, { ...item, duration })
    }
  }
}

export default new CreateTextToSpeechStore()
