// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZeuLVd80yMbKG6Wg89Vr{width:800px;padding:0 32px 32px 32px}.Cgx9Geq9ce4ATKW6Z4kB{display:flex;align-items:center;height:100%}`, "",{"version":3,"sources":["webpack://./src/Modals/UpgradePlanModal/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,wBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":["@import \"src/styles/mixin\";\n\n.wrap{\n  width: 800px;\n  padding: 0 32px 32px 32px;\n}\n.alertTitle{\n  display: flex;\n  align-items: center;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ZeuLVd80yMbKG6Wg89Vr`,
	"alertTitle": `Cgx9Geq9ce4ATKW6Z4kB`
};
export default ___CSS_LOADER_EXPORT___;
