import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16px/Plus.svg'
import { useNavigate } from 'react-router-dom'
import { acceptedCreate } from 'src/routes/settings/billing/functions/acceptedCreate'
import { AppElements } from 'src/enums/appElements'

export const CreateElementBtn = ({
  appElement,
  createText,
  onCreate,
}: {
  appElement: AppElements
  createText?: string
  onCreate?: () => void
}) => {
  const navigate = useNavigate()
  const handleCreate = () => {
    if (onCreate) {
      return onCreate()
    }
    acceptedCreate({
      limitFor: appElement,
      acceptedCB: () => navigate('new'),
    })
  }
  return (
    <Button
      size={BtnSize.medium}
      typeBtn={BtnType.primary}
      onClick={handleCreate}
      withIcon
    >
      <Plus />
      {createText ? createText : 'New'} {appElement}
    </Button>
  )
}
