import { useGetFullBusinessProfileQuery } from 'src/generated/graphql'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { useEffect } from 'react'

export function useSetFullBusinessProfile(skip?: boolean) {
  const { data, loading } = useGetFullBusinessProfileQuery({
    skip,
  })
  useEffect(() => {
    if (data?.getFullBusinessProfile) {
      businessProfileStore.setFullBusinessProfile(data?.getFullBusinessProfile)
    }
  }, [data])

  return { loading }
}
