import { onOpenIntercomWidget } from 'src/util/intercom'
import * as React from 'react'
import { ReactNode } from 'react'

export const tollFreeErrors: {
  [key: string]: { cause: string; solutions: ReactNode }
} = {
  'edit time expired': {
    cause:
      'There were no edits to the verification within 7 days of its rejection.',
    solutions: (
      <div className={'par2 black'}>
        Edit and submit the toll-free verification for the number fixing the
        rejection reasons. This will be handled as a new submission.{' '}
        <button className={'link s1 bold'} onClick={onOpenIntercomWidget}>
          Contact us.
        </button>
      </div>
    ),
  },
  'unknown error': {
    cause: 'Unknown',
    solutions: (
      <div className={'par2 black'}>
        <button className={'link s1 bold'} onClick={onOpenIntercomWidget}>
          Contact us.
        </button>
      </div>
    ),
  },
  'content violation': {
    cause:
      'The toll-free phone number cannot be verified because the submission indicated that the business was part of a prohibited SHAFT category or the content was potentially sending SHAFT material.',
    solutions: (
      <div className={'par2 black'}>
        Ineligible for resubmission. If you feel that this was in error,{' '}
        <button className={'link s1 bold'} onClick={onOpenIntercomWidget}>
          contact us.
        </button>
      </div>
    ),
  },
  'campaign violation': {
    cause:
      'The Toll-Free phone number cannot be verified because the campaign was found to be a known Spam or Phishing campaign.',
    solutions: (
      <div className={'par2 black'}>
        Ineligible for resubmission. If you feel that this was in error,{' '}
        <button className={'link s1 bold'} onClick={onOpenIntercomWidget}>
          contact us.
        </button>
      </div>
    ),
  },
  'disallowed content': {
    cause:
      'The Toll-Free phone number cannot be verified because your campaign has disallowed content types, such as: Loan Marketing, 3rd party debt collection, gambling, sweepstakes, stock alerts, cryptocurrency, risk investments, debt reduction, credit repair, 3rd party lead generation, federally illegal substances.',
    solutions: (
      <div className={'par2 black'}>
        Ineligible for resubmission. If you feel that this was in error,{' '}
        <button className={'link s1 bold'} onClick={onOpenIntercomWidget}>
          contact us.
        </button>
      </div>
    ),
  },
  'high risk': {
    cause:
      'The Toll-Free phone number cannot be verified because your campaign is considered Fraud or Deceptive Marketing.',
    solutions: (
      <div className={'par2 black'}>
        Ineligible for resubmission. If you feel that this was in error,{' '}
        <button className={'link s1 bold'} onClick={onOpenIntercomWidget}>
          contact us.
        </button>
      </div>
    ),
  },
  'invalid information': {
    cause:
      'The Toll-Free phone number cannot be verified because of invalid information. The Business Information such as business name or address that was submitted could not be verified or the website URL could be not validated because it is not accessible or available.',
    solutions: (
      <div className={'par2 black'}>
        Verify that all the business information fields are correct. Resubmit
        the Toll-Free verification.
        <br />
        <br />
        Ensure that you have a live publicly accessible website URL. Resubmit
        the Toll-Free verification.
      </div>
    ),
  },
  'opt-in error': {
    cause:
      'The Opt-in workflow provided is not sufficient for campaign type and Express Consent is required. Consent for messaging is a requirement for the service. Opt-in is shared with 3rd Parties.',
    solutions: (
      <div className={'par2 black'}>
        Resubmit the toll free verification after completion of one of these: *
        Correct opt-in via CTIA guidelines and resubmit * Remove necessity to
        opt-in for service and resubmit * Remove language for 3rd party opt-in
        sharing and resubmit once complete and live on your website.
      </div>
    ),
  },
  'phone number error': {
    cause:
      'The Toll-Free phone number phone number is already in use. It may not be a Twilio Toll-Free number.',
    solutions: (
      <div className={'par2 black'}>
        Ensure that the Toll-Free phone number you want to verify is fully
        provisioned at Twilio for your organization.
        <br />
        <br />
        If you are an ISV, ensure that the Toll-Free phone number is not already
        assigned to another vendor.
      </div>
    ),
  },
  'age gate': {
    cause:
      'The Toll-Free phone number cannot be verified because an Age Gate is not present or is not acceptable on your website and/or opt-in policy.',
    solutions: (
      <div className={'par2 black'}>
        Add a robust age gate to your website or opt-in policy. Resubmit the
        toll free verification with the updated age gate information.
      </div>
    ),
  },
  'sample message url issues in sample message': {
    cause:
      'The Toll-Free phone number cannot be verified because the website URL in the sample message that you submitted was either from a public URL shortener or is non-secured.',
    solutions: (
      <div className={'par2 black'}>
        Replace call to action (CTA) in sample message with a branded URL.
        Resubmit the Toll-Free verification with an updated sample message.
        <br />
        <br />
        Replace call to action (CTA) in sample message with https:// link.
        Resubmit the Toll-Free verification with an updated sample message.
      </div>
    ),
  },
}
