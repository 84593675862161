import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import clicksDetailStore from 'components/Clicks/clicksDetailStore'
import { ClickDetailComponent } from 'components/Clicks/ClickDetailComponent'
import * as React from 'react'

export const ClicksDetailsModal = observer(() => {
  const onClose = () => {
    clicksDetailStore.setShortUrlClickStatistics(null)
  }
  return (
    <Modal
      open={clicksDetailStore.openModal}
      onClose={onClose}
      title={'Click Details'}
      minWidth={528}
      topRight
    >
      <div className={'mediumModal col32'}>
        {clicksDetailStore.shortUrlClickStatistics?.shortUrlVisits?.map(
          (detail, index) => (
            <ClickDetailComponent
              key={detail?.shortUrl}
              {...detail}
              index={index}
              length={
                clicksDetailStore.shortUrlClickStatistics?.shortUrlVisits
                  ?.length || 0
              }
            />
          )
        )}
      </div>
    </Modal>
  )
})
