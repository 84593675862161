import { CreateElementBtn } from 'components/Button/CreateElementBtn'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { ReactComponent as StatusIcon } from 'icons/16px/Status/Status.svg'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { Status } from 'src/generated/graphql'
import campaignsStore from 'store/campaigns/campaignsStore'
import { observer } from 'mobx-react-lite'
import { IOption } from 'src/types/IOption'
import { AppElements } from 'src/enums/appElements'

const options: IOption[] = [
  {
    title: <StatusContent status={Status.Active} />,
    value: Status.Active,
    padding: '0 8px 0 0',
  },
  {
    title: <StatusContent status={Status.Disabled} />,
    value: Status.Disabled,
    padding: '0 8px 0 0',
  },
  {
    title: <StatusContent status={Status.Draft} />,
    value: Status.Draft,
    padding: '0 8px 0 0',
  },
  {
    title: <StatusContent status={Status.Archived} />,
    value: Status.Archived,
    padding: '0 8px 0 0',
  },
]

export const NoSelectContent = observer(() => {
  // const [currentStatus, setCurrentStatus] = useState<IOption[]>([])
  // const [currentTypes, setCurrentTypes] = useState<IOption[]>([])
  return (
    <>
      <CreateElementBtn appElement={AppElements.Campaign} />
      <Dropdown
        dropDownContentWidth={196}
        options={options}
        selectedOptions={campaignsStore.tableStore.currentStatuses}
        onMultiSelect={(options) =>
          campaignsStore.tableStore.setCurrentStatus(options)
        }
        leftIcon={<StatusIcon />}
        multi
        withSelectALl
        unit={'status'}
        units={'Status'}
      />
    </>
  )
})
