import { observer } from 'mobx-react-lite'
import { useSegmentDetailContext } from 'src/routes/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { ListsFilterContent } from 'src/routes/main/contacts/segments/DetailSegment/components/SegmentContent/content/ListsFilterContent'
import { ListFilter } from 'store/contacts/segment/filters/ListFilter'
import { EmptyFilter } from 'store/contacts/segment/filters/EmptyFilter'
import { Filter } from 'store/contacts/segment/types'
import styles from './styles.module.scss'
import { useMemo } from 'react'
import { Icon } from 'components/Icon/Icon'
import { SelectFilterDropdown } from 'src/routes/main/contacts/segments/DetailSegment/components/SegmentContent/components/SelectFilterDropdown'
import { FilterConditionDropdown } from 'src/routes/main/contacts/segments/DetailSegment/components/SegmentContent/components/FilterConditionDropdown'
import { TextFilterContent } from 'src/routes/main/contacts/segments/DetailSegment/components/SegmentContent/content/TextFilterContent'
import { TextFilter } from 'store/contacts/segment/filters/TextFilter'
import { SelectFilterContent } from 'src/routes/main/contacts/segments/DetailSegment/components/SegmentContent/content/SelectFilterContent'
import { SelectFilter } from 'store/contacts/segment/filters/SelectFilter'
import { OptIntFilterContent } from 'src/routes/main/contacts/segments/DetailSegment/components/SegmentContent/content/OptIntFilterContent/OptIntFilterContent'
import { OptInFilter } from 'store/contacts/segment/filters/OptInFilter'
import { DateFilterContent } from 'src/routes/main/contacts/segments/DetailSegment/components/SegmentContent/content/DateFilterContent'
import { DateFilter } from 'store/contacts/segment/filters/DateFilter'
import { FilterCondition } from 'src/generated/graphql'

type SegmentFilterComponentProps = {
  filter: Filter | EmptyFilter
}

export const SegmentFilterComponent = observer(
  ({ filter }: SegmentFilterComponentProps) => {
    // const { fieldsOptions } = segmentStore
    const { onDeleteFilter, filtersMap } = useSegmentDetailContext()

    const getFilterContent = (filter: Filter) => {
      if (
        filter.condition === FilterCondition.Exists ||
        filter.condition === FilterCondition.NotExists
      ) {
        return <div />
      }
      if (filter instanceof ListFilter) {
        return <ListsFilterContent filter={filter} />
      }
      if (filter instanceof OptInFilter) {
        return <OptIntFilterContent filter={filter} />
      }
      if (filter instanceof SelectFilter) {
        return <SelectFilterContent filter={filter} />
      }
      if (filter instanceof DateFilter) {
        return <DateFilterContent filter={filter} />
      }
      if (filter instanceof TextFilter) {
        return <TextFilterContent filter={filter} />
      }

      return null
    }

    const conditionContent = useMemo(() => {
      if (filter instanceof EmptyFilter) {
        return null
      }
      return <FilterConditionDropdown filter={filter} />
    }, [filter])

    return (
      <div className={styles.row}>
        <SelectFilterDropdown filter={filter} />
        {conditionContent}
        {filter instanceof EmptyFilter ? (
          <>
            <div />
            <div />
          </>
        ) : (
          getFilterContent(filter)
        )}
        {filter instanceof EmptyFilter && filtersMap.size === 1 ? null : (
          <button
            className={styles.deleteBtn}
            onClick={() => {
              onDeleteFilter(filter)
            }}
          >
            <Icon icon={'delete'} />
          </button>
        )}
      </div>
    )
  }
)
