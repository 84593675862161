import { Card } from 'components/Card/Card'
import { BtnSize } from 'components/Button/Button'
import { BackMigrationBtn } from 'src/routes/auth/migration/BackMigrationBtn'
import companyStore from 'store/settings/company/companyStore'
import { RegisterOrganization } from 'src/widgets/RegisterOrganization/RegisterOrganization'
import { useCompanyDetailsRegistrationStepMutation } from 'src/generated/graphql'
import user from 'store/user/user'
import mainStore from 'store/mainStore'
import { observer } from 'mobx-react-lite'
import { useHubspotRegisterCompany } from 'src/hooks/useHubspotRegisterCompany'

export const CompanyDetailsStep = observer(() => {
  const [companyDetailsRegistrationStep, { loading }] =
    useCompanyDetailsRegistrationStepMutation()
  const onContinue = async (companyName: string, employees: string | null) => {
    try {
      const { data } = await companyDetailsRegistrationStep({
        variables: {
          input: {
            employees: employees,
            companyName: companyName.trim(),
          },
        },
      })
      if (data?.companyDetailsRegistrationStep) {
        user.setMember(data.companyDetailsRegistrationStep)
        mainStore.updateAnalyticTrigger()
      }
    } catch (e) {
      console.error(e)
    }
  }

  useHubspotRegisterCompany()

  return (
    <Card width={480} margin={0}>
      <RegisterOrganization
        isLargeTitle={true}
        loading={loading}
        btnPrimarySize={BtnSize.medium}
        btnPrimaryText={'Continue'}
        bottomContent={<BackMigrationBtn />}
        onContinue={onContinue}
        initCompanyName={companyStore.companyName}
        actionClassName={'row12'}
      />
    </Card>
  )
})
