import {
  AllowImportStatus,
  BillingStatus,
  BusinessProfileStatus,
  ClStatus,
  OrganizationStatus,
  Status,
  TenDlcProfileStatus,
  TollFreeVerificationStatus,
  VerificationStatus,
} from 'src/generated/graphql'

export enum CustomStatuses {
  Limited = 'Limited',
  Paid = 'Paid',
  Stopped2 = 'Stopped2',
  Pending = 'PENDING',
  Open = 'Open',
  Void = 'Void',
  Uncollectible = 'Uncollectible',
  Paused = 'Paused',
  Finished = 'Finished',
  Inactive = 'Inactive',
  InReview = 'In Review',
  Canceled = 'Canceled',
  Lead = 'Lead',
  Blocked = 'Blocked',
  BLOCKED = 'BLOCKED',
  REVIEW_REQUIRED = 'REVIEW_REQUIRED',
  Migrated = 'Migrated',
}

export enum DisplayStateEnum {
  Active = 'Active',
  Removed = 'Removed',
  Pending = 'Pending',
}

export type IStatus =
  | Status
  | CustomStatuses
  | BusinessProfileStatus
  | TollFreeVerificationStatus
  | VerificationStatus
  | OrganizationStatus
  | ClStatus
  | DisplayStateEnum
  | AllowImportStatus
  | TenDlcProfileStatus
  | BillingStatus
export const successStatuses: IStatus[] = [
  Status.Success,
  Status.Received,
  Status.Sent,
  Status.Active,
  CustomStatuses.Paid,
  Status.Delivered,
  Status.Completed,
  Status.Finished,
  Status.Answered,
  BusinessProfileStatus.Approved,
  TollFreeVerificationStatus.Approved,
  VerificationStatus.Verified,
  OrganizationStatus.Active,
  AllowImportStatus.Approved,
  CustomStatuses.Migrated,
]
export const failedStatuses: IStatus[] = [
  Status.Failed,
  Status.Undelivered,
  CustomStatuses.Void,
  CustomStatuses.Canceled,
  CustomStatuses.Blocked,
  CustomStatuses.BLOCKED,
  VerificationStatus.Blocked,
  OrganizationStatus.Suspended,
  OrganizationStatus.Banned,
  DisplayStateEnum.Removed,
  BusinessProfileStatus.TwilioRejected,
  TollFreeVerificationStatus.Rejected,
  TollFreeVerificationStatus.Expired,
  AllowImportStatus.Rejected,
  BillingStatus.PendingCancellation,
]
export const reviewStatuses: IStatus[] = [
  Status.Scheduled,
  CustomStatuses.Open,
  CustomStatuses.InReview,
  TollFreeVerificationStatus.InReview,
  TollFreeVerificationStatus.PendingReview,
  BusinessProfileStatus.InReview,
  VerificationStatus.InReview,
  AllowImportStatus.NeedsReview,
  CustomStatuses.Pending,
  TollFreeVerificationStatus.ReviewRequired,
]

export const pendingStatuses: IStatus[] = [
  Status.Pending,
  CustomStatuses.Lead,
  ClStatus.Trialing,
  Status.Trial,
]

export const disabledStatuses: IStatus[] = [
  Status.Disabled,
  Status.Unsubscribed,
  Status.Inactive,
]

export const limitedStatuses: IStatus[] = [
  CustomStatuses.Limited,
  CustomStatuses.Uncollectible,
]

export const draftStatuses: IStatus[] = [
  Status.Draft,
  DisplayStateEnum.Pending,
  BusinessProfileStatus.Draft,
]
