import { KeywordFragmentFragment, PhoneNumber } from 'src/generated/graphql'
import { IKeyword } from 'src/types/IKeyword'
import { IItem } from 'components/NewTable/cell/ItemsRender/ItemsRender'

export function transformKeyword(keyword: KeywordFragmentFragment): IKeyword {
  const items: IItem[] = []
  const contactsListsIds: number[] = []
  keyword.keywordLists?.forEach((kList) => {
    const id = kList?.listing?.id
    if (id !== undefined) {
      if (kList?.listing?.id && kList?.listing?.name) {
        items.push({
          id: kList?.listing?.id,
          name: kList?.listing?.name,
          group: 'list',
        })
      }

      contactsListsIds.push(id)
    }
  })
  const keywordPhoneNumbers: PhoneNumber[] = []
  keyword.keywordPhoneNumbers?.forEach((kPhone) => {
    const phoneNumber = kPhone?.phoneNumber
    if (phoneNumber) {
      keywordPhoneNumbers.push(phoneNumber)
    }
  })
  return {
    ...keyword,
    id: String(keyword.id),
    items,
    shortUrlClickStatistics: keyword.shortUrlClickStatistics,
    contactsListsIds,
    keywordPhoneNumbers,
    contactStatusStatistics: keyword.contactStatusStatistics,
  }
}
