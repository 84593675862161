import styles from '../../../../../../Modals/UpgradePlanModal/Step1Upgrade/cards/styles.module.scss'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import billingStore from 'store/settings/billing/billingStore'
import auth from 'store/auth'
import { useSetPaymentsMethods } from 'src/routes/settings/billing/hooks/useSetPaymentsMethods'
import { useStartTrialRegistrationStepMutation } from 'src/generated/graphql'

export const MigrationPlanCardWithNoSubscription = observer(() => {
  useSetPaymentsMethods()
  const [startTrialRegistrationStep, { loading }] =
    useStartTrialRegistrationStepMutation()
  const onStart = async () => {
    try {
      const { data } = await startTrialRegistrationStep({
        variables: {
          paymentMethodId: billingStore.primaryPaymentMethodId,
        },
      })
      if (data?.startTrialRegistrationStep) {
        auth.setRefetchMainInfo()
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={styles.card}>
      <div className={styles.migrationHeader}>
        <span>Call Loop 2.0 Free Trial</span>
        <div className={'flex'}>
          <span className={'error1 s2'}>
            All of your credits will be deleted
          </span>
        </div>
      </div>
      <div className={styles.middle}>
        <div className={styles.price}>
          <h2>Decline & Start Trial</h2>
        </div>
        <div className={styles.billedAnnually}></div>
        <div className={'mb4'}>
          <p>for 25 credits</p>
        </div>
        <div className={'s2 gray1'}>
          After your 14-day trial is over, you will be upgraded to the Pro
          Monthly 500 $25/month. Cancel anytime.
        </div>
      </div>

      <div className={classNames(styles.actionWrap)}>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          fullWidth
          onClick={onStart}
          loading={loading}
        >
          Start trial
        </Button>
      </div>
    </div>
  )
})
