import { observer } from 'mobx-react-lite'
import styles from 'components/Schedule/styles.module.scss'
import { ScheduleBtn } from 'components/Schedule/ScheduleBtn'
import { BroadcastSendType, CampaignDelayType } from 'src/generated/graphql'
import { BroadcastScheduleStore } from 'components/Schedule/store/broadcastScheduleStore'
import { ScheduleProps } from 'components/Schedule/Schedule'
import { LaterContent } from 'components/Schedule/LaterCard/LaterContent'

export const LaterCard = observer(({ scheduleStore }: ScheduleProps) => {
  const onClick = () => {
    if (scheduleStore instanceof BroadcastScheduleStore) {
      scheduleStore.setSendTypeBroadcast(BroadcastSendType.Later)
    } else {
      scheduleStore.setSendTypeCampaign(CampaignDelayType.Delay)
    }
  }
  return (
    <div className={styles.row}>
      <ScheduleBtn
        active={scheduleStore.activeSendLater}
        title={'Send Later'}
        onClick={onClick}
      />
      <LaterContent scheduleStore={scheduleStore} />
    </div>
  )
})
