import { useGetTotalContactsQuery } from 'src/generated/graphql'
import importContactStore from 'store/contacts/importContactStore'
import { useEffect } from 'react'
import contactStore from 'store/contacts/contactStore'
import manualContactStore from 'store/contacts/manualContactStore'
import billingStore from 'store/settings/billing/billingStore'

export function useSetTotalContactsCount(skip: boolean) {
  const { data, refetch, loading } = useGetTotalContactsQuery({
    skip:
      skip || Number(billingStore.clSubscription?.limits?.contacts) > 100_000,
  })

  useEffect(() => {
    if (importContactStore.openImportModal) {
      refetch()
    }
  }, [importContactStore.openImportModal])

  useEffect(() => {
    if (
      manualContactStore.openContactModal &&
      Number(billingStore.clSubscription?.limits?.contacts) < 100_000
    ) {
      manualContactStore.setLoadingCheckContactsLimit(true)
      refetch().finally(() =>
        manualContactStore.setLoadingCheckContactsLimit(false)
      )
    }
  }, [manualContactStore.openContactModal])

  useEffect(() => {
    if (data) {
      contactStore.setTotalContactCount(
        data.getMyOrganizationContacts?.total || 0
      )
    }
  }, [data])
  return loading
}
