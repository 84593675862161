import { Route, Routes } from 'react-router-dom'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { RoutesEnum } from 'src/routes/routes'
import { NoMatch } from 'src/routes/noMatch'
import { NewOrganization } from 'src/routes/main/newOrganization/NewOrganization'

export const NoOrganization = () => {
  return (
    <Routes>
      <Route element={<AuthPage isLogoutOnClose />}>
        <Route
          path={RoutesEnum.newOrganization}
          element={<NewOrganization />}
        />
        <Route
          path="*"
          element={<NoMatch redirectRoute={RoutesEnum.newOrganization} />}
        />
      </Route>
    </Routes>
  )
}
