import { observer } from 'mobx-react-lite'
import { StripeCoupon } from 'src/widgets/StripeCoupon/StripeCoupon'

export const MainComponents = observer(() => {
  return (
    <>
      <StripeCoupon />
    </>
  )
})
