import { Outlet, useLocation } from 'react-router-dom'
import styles from './styles.module.scss'
import { RoutesEnum } from 'src/routes/routes'

export const SettingsWrap = () => {
  const { pathname } = useLocation()
  const isIntegrations = pathname.includes(RoutesEnum.integrations)

  return (
    <div
      className={styles.wrap}
      style={{ paddingInline: isIntegrations ? 44 : undefined }}
    >
      <div className={'main-container'}>
        <Outlet />
      </div>
    </div>
  )
}
