import { ReactComponent as Dot } from 'icons/16pxNoMask/Dot.svg'
import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'
import { AppIcon, IconColor, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'

export const Li: FC<{
  color?: IconColor
  children?: ReactNode
}> = ({ children, color = ColorsNames.blue1 }) => {
  return (
    <div className={styles.wrap}>
      <AppIcon color={color} type={IIconType.circle}>
        <Dot />
      </AppIcon>

      {children}
    </div>
  )
}
