import { TagsRender } from 'components/Dropdown/TagsRender/TagsRender'
import React, { useRef, useState } from 'react'
import styles from './styles.module.scss'
import { NumericFormat } from 'react-number-format'
import classNames from 'classnames'
import { useTagsWidth } from 'components/Input/CreateTagInput/useTagsWidth'
import { IOption } from 'src/types/IOption'
import { observer } from 'mobx-react-lite'

type Props = {
  options: IOption[]
  onCreateTag: (val: string) => void
  onDeleteTag?: (id: string | number, group?: string) => void
  number?: boolean
}
export const CreateTagInput = observer(
  ({ options, onCreateTag, onDeleteTag, number }: Props) => {
    const ref = useRef<HTMLDivElement>(null)
    const [value, setValue] = useState('')
    const [focus, setFocus] = useState(false)
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onCreateTag(value)
        setValue('')
      }
    }

    const { widthTags } = useTagsWidth(ref.current, onDeleteTag, options)

    const onFocus = () => {
      setFocus(true)
    }
    const onBlur = () => {
      setFocus(false)
      if (value) {
        onCreateTag(value)
      }
      setValue('')
    }
    return (
      <div className={classNames(styles.wrap, focus && styles.focus)} ref={ref}>
        <div className={styles.wrapTags}>
          <TagsRender selectedOptions={options} onDeleteTag={onDeleteTag} />
        </div>
        {number ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <NumericFormat
            value={value}
            style={{ left: widthTags > 0 ? widthTags + 17 : 9 }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={onKeyDown}
            placeholder={'Enter value and press enter'}
            className={styles.input}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        ) : (
          <input
            value={value}
            style={{ left: widthTags > 0 ? widthTags + 17 : 9 }}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={onKeyDown}
            placeholder={'Enter value and press enter'}
            className={styles.input}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
      </div>
    )
  }
)
