import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FC, useMemo } from 'react'
import { getBroadcastActions } from 'src/routes/main/broadcasts/functions/getBroadcastActions'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import broadcastsStore from 'store/broadcasts/broadcastsStore'

export const OneSelectContent = observer(() => {
  const broadcast = broadcastsStore.broadcastsMap.get(
    broadcastsStore.tableStore.checkedRowsIds[0]
  )
  const actions = getBroadcastActions(broadcast)

  return useMemo(() => {
    const rowActions = actions.slice(0, 4)
    const moreActions = actions.slice(4)
    return (
      <>
        {rowActions.map((Action, index) => (
          <Action key={index} medium />
        ))}
        {!!moreActions?.length && (
          <MoreBtn
            menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
            tooltip={'More'}
            medium
          />
        )}
      </>
    )
  }, [actions])
})
