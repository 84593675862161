// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bh8nVfK91wPrwbaZIoT4{padding:8px;width:176px;height:48px;background-color:var(--primary-color-gray-4);border-radius:8px;cursor:pointer;display:grid;grid-template-columns:max-content 1fr;position:relative;column-gap:8px}.Bh8nVfK91wPrwbaZIoT4:hover{background-color:var(--primary-color-gray-2-light)}.Bh8nVfK91wPrwbaZIoT4.ADNzA5Y1gT6thsSDLJfV{width:150px;height:44px}.FOf3YRx69fTVLfMd_p78{position:absolute;top:4px;right:4px}.JF7ji1331yEqVncb2KTA{height:32px;width:32px;background-color:var(--primary-color-white);border-radius:50%;display:flex;justify-content:center;align-items:center}.JF7ji1331yEqVncb2KTA span{text-transform:uppercase}.k3lPLFuFxHWv2IGlafuw{display:grid}.k3lPLFuFxHWv2IGlafuw div{display:flex}`, "",{"version":3,"sources":["webpack://./src/components/VCard/VCardGallery/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,WAAA,CACA,WAAA,CACA,4CAAA,CACA,iBAAA,CACA,cAAA,CACA,YAAA,CACA,qCAAA,CACA,iBAAA,CACA,cAAA,CACA,4BACE,kDAAA,CAEF,2CACE,WAAA,CACA,WAAA,CAGJ,sBACE,iBAAA,CACA,OAAA,CACA,SAAA,CAEF,sBACE,WAAA,CACA,UAAA,CACA,2CAAA,CACA,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,2BACE,wBAAA,CAGJ,sBACE,YAAA,CACA,0BACE,YAAA","sourcesContent":[".wrap{\n  padding: 8px;\n  width: 176px;\n  height: 48px;\n  background-color: var(--primary-color-gray-4);\n  border-radius: 8px;\n  cursor: pointer;\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  position: relative;\n  column-gap: 8px;\n  &:hover{\n    background-color: var(--primary-color-gray-2-light);\n  }\n  &.small{\n    width: 150px;\n    height: 44px;\n  }\n}\n.closeX{\n  position: absolute;\n  top: 4px;\n  right: 4px;\n}\n.logo{\n  height: 32px;\n  width: 32px;\n  background-color: var(--primary-color-white);\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  span{\n    text-transform: uppercase;\n  }\n}\n.content{\n  display: grid;\n  div{\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Bh8nVfK91wPrwbaZIoT4`,
	"small": `ADNzA5Y1gT6thsSDLJfV`,
	"closeX": `FOf3YRx69fTVLfMd_p78`,
	"logo": `JF7ji1331yEqVncb2KTA`,
	"content": `k3lPLFuFxHWv2IGlafuw`
};
export default ___CSS_LOADER_EXPORT___;
