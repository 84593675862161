import apiSore from 'store/apiSore'
import {
  CreateMessageTemplateDocument,
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables,
  DeleteMessageTemplatesDocument,
  DeleteMessageTemplatesMutation,
  DeleteMessageTemplatesMutationVariables,
  GetMessageTemplatesDocument,
  GetMessageTemplatesQuery,
  GetMessageTemplatesQueryVariables,
  UpdateMessageTemplateDocument,
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables,
} from 'src/generated/graphql'
import { FetchPolicy } from '@apollo/client'

export const MessageTemplateApi = {
  getList: (
    variables: GetMessageTemplatesQueryVariables,
    fetchPolicy?: FetchPolicy
  ) =>
    apiSore.client.query<
      GetMessageTemplatesQuery,
      GetMessageTemplatesQueryVariables
    >({
      query: GetMessageTemplatesDocument,
      variables,
      fetchPolicy,
    }),

  create: (variables: CreateMessageTemplateMutationVariables) =>
    apiSore.client.mutate<
      CreateMessageTemplateMutation,
      CreateMessageTemplateMutationVariables
    >({
      mutation: CreateMessageTemplateDocument,
      variables,
    }),
  update: (variables: UpdateMessageTemplateMutationVariables) =>
    apiSore.client.mutate<
      UpdateMessageTemplateMutation,
      UpdateMessageTemplateMutationVariables
    >({
      mutation: UpdateMessageTemplateDocument,
      variables,
    }),

  delete: (variables: DeleteMessageTemplatesMutationVariables) =>
    apiSore.client.mutate<
      DeleteMessageTemplatesMutation,
      DeleteMessageTemplatesMutationVariables
    >({
      mutation: DeleteMessageTemplatesDocument,
      variables,
    }),
}
