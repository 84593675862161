import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'
import {
  useGetMyOrganizationContactsQuery,
  useGetSegmentsQuery,
  useGetShortMyOrganizationListsQuery,
  useSearchListsAndContactsQuery,
} from 'src/generated/graphql'
import { useMemo, useState } from 'react'
import { AddContactsBy } from 'components/AddContactsBy/AddContactsBy'
import { contactListToOption, segmentToOption } from 'store/contacts/functions'
import { contactToOption } from 'store/contacts/contactToOption'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { BtnType, Button } from 'components/Button/Button'
import { DropdownProps } from 'components/Dropdown/types'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import configStore from 'store/configStore'

const emptyText =
  'You don’t have any contacts. Click on the right icon to import or add manually contacts.'

export const SendToDropDownWithContacts = observer(
  ({
    label = 'Send to',
    dropdownContactListsStore,
    labelTooltip,
    tabIndex,
    maxWidth,
    required,
    noKeyword,
    noWebForm,
    noRightBth,
  }: {
    dropdownContactListsStore: DropdownContactListsStore
    tabIndex?: number
    withSegments?: boolean
    label?: string
    labelTooltip?: string
    required?: boolean
    noKeyword?: boolean
    noWebForm?: boolean
    maxWidth?: number
    noRightBth?: boolean
    fromBroadcast?: boolean
  }) => {
    const {
      selectedOptions,
      onSelectOptions,
      onDeleteTag,
      selectedContactListsIds,
      selectedSegmentsIds,
      selectedContactIds,
    } = dropdownContactListsStore
    const [contentType, setContentType] = useState<
      'all' | 'lists' | 'contacts' | 'segments'
    >('all')
    const [search, setSearch] = useState('')
    const { data: dataAll, loading: loadingAll } =
      useSearchListsAndContactsQuery({
        variables: {
          search: {
            searchPattern: search,
            excludedContactIds: selectedContactIds,
            excludedListIds: selectedContactListsIds,
            excludedSegmentIds: selectedSegmentsIds,
          },
        },
        skip: contentType !== 'all',
      })

    const { data: dataLists, loading: loadingLists } =
      useGetShortMyOrganizationListsQuery({
        variables: {
          page: {
            pageNumber: 0,
            pageSize: 20,
          },
          listingSearch: {
            searchPattern: search,
          },
        },
        skip: contentType !== 'lists',
      })

    const { data: dataSegments, loading: loadingSegments } =
      useGetSegmentsQuery({
        variables: {
          page: {
            pageNumber: 0,
            pageSize: 20,
          },
        },
        skip: contentType !== 'segments',
      })

    const { data: dataContacts, loading: loadingContacts } =
      useGetMyOrganizationContactsQuery({
        variables: {
          page: {
            pageNumber: 0,
            pageSize: 20,
          },
          contactSearch: {
            searchPattern: search,
          },
        },
        skip: contentType !== 'contacts',
      })

    const options: IOption[] = useMemo(() => {
      const options: IOption[] = []
      if (contentType === 'all') {
        dataAll?.searchListsAndContacts?.listings?.forEach((list) => {
          if (list) {
            options.push(contactListToOption(list))
          }
        })
        dataAll?.searchListsAndContacts?.contacts?.forEach((contact) => {
          if (contact) {
            options.push(contactToOption(contact))
          }
        })

        if (configStore.isSegments) {
          dataAll?.searchListsAndContacts?.segments?.forEach((segment) => {
            if (segment) {
              options.push(segmentToOption(segment))
            }
          })
        }
      }
      if (contentType === 'lists') {
        dataLists?.getMyOrganizationLists?.content?.forEach((list) => {
          if (list) {
            options.push(contactListToOption(list))
          }
        })
      }
      if (contentType === 'contacts') {
        dataContacts?.getMyOrganizationContacts?.content?.forEach((contact) => {
          if (contact) {
            options.push(contactToOption(contact))
          }
        })
      }
      if (contentType === 'segments') {
        dataSegments?.getSegments?.content?.forEach((segment) => {
          if (segment) {
            options.push(segmentToOption({ ...segment, id: segment.id }))
          }
        })
      }

      return options
    }, [dataAll, dataLists, dataContacts, contentType, dataSegments])

    const handleDeleteTag = (id: string | number, group?: string) => {
      onDeleteTag(id as string, group)
    }
    const onAddListsFromModal = (options: IOption[]) => {
      onSelectOptions && onSelectOptions([...selectedOptions, ...options])
    }

    const onShowList = () => {
      setContentType('lists')
    }
    const onShowSegments = () => {
      setContentType('segments')
    }
    const onShowContacts = () => {
      setContentType('contacts')
    }

    const groupActions: DropdownProps<unknown>['groupActions'] = useMemo(() => {
      if (
        contentType === 'contacts' ||
        contentType === 'lists' ||
        contentType === 'segments'
      ) {
        return {
          contact: {
            label: `Contacts`,
            count: dataContacts?.getMyOrganizationContacts?.total,
          },
          list: {
            label: `Lists`,
            count: dataLists?.getMyOrganizationLists?.total,
          },
          segment: {
            label: `Segments`,
            count: dataSegments?.getSegments?.total,
          },
        }
      }
      return {
        contact: {
          label: `Contacts`,
          count: dataAll?.searchListsAndContacts?.totalContacts,
          action: (
            <Button typeBtn={BtnType.blueAction} onClick={onShowContacts}>
              Show all
            </Button>
          ),
        },
        list: {
          label: `Lists`,
          count: dataAll?.searchListsAndContacts?.totalListings,
          action: (
            <Button typeBtn={BtnType.blueAction} onClick={onShowList}>
              Show all
            </Button>
          ),
        },
        segment: {
          label: `Segments`,
          count: dataAll?.searchListsAndContacts?.totalSegments,
          action: (
            <Button typeBtn={BtnType.blueAction} onClick={onShowSegments}>
              Show all
            </Button>
          ),
        },
      }
    }, [contentType, dataLists, dataAll, dataContacts, dataSegments])

    const loading = useMemo(() => {
      if (contentType === 'all') {
        return loadingAll
      }
      if (contentType === 'contacts') {
        return loadingContacts
      }
      if (contentType === 'lists') {
        return loadingLists
      }
      if (contentType === 'segments') {
        return loadingSegments
      }
    }, [
      contentType,
      loadingAll,
      loadingLists,
      loadingContacts,
      loadingSegments,
    ])

    const onTriggerClick = () => {
      setContentType('all')
      setSearch('')
    }

    const searchPrefix = useMemo(() => {
      if (contentType === 'contacts') {
        return 'Contacts:'
      }
      if (contentType === 'lists') {
        return 'Lists:'
      }
      if (contentType === 'segments') {
        return 'Segments:'
      }
      return ''
    }, [contentType])

    return (
      <Dropdown
        searchPrefix={searchPrefix}
        onBlur={onTriggerClick}
        loadingSearchData={loading}
        search={search}
        setSearch={setSearch}
        position={TTPositionEnum.topCenter}
        tabIndex={tabIndex}
        labelInput={label}
        labelTooltip={labelTooltip}
        emptyText={search || loading ? '' : emptyText}
        placeholder={'Select'}
        options={options}
        onMultiSelect={onSelectOptions}
        selectedOptions={selectedOptions}
        required={required}
        maxWidth={maxWidth}
        maxHeightDropdown={440}
        groupActions={groupActions}
        onDeleteTag={handleDeleteTag}
        rightBtn={
          noRightBth ? undefined : (
            <AddContactsBy
              onAddListsFromModal={onAddListsFromModal}
              noWebForm={noWebForm}
              noKeyword={noKeyword}
            />
          )
        }
        grouping
        withSearch
        multi
        selectedTag
        withNoResults
      />
    )
  }
)
