import 'react-datepicker/dist/react-datepicker.css'
import 'styles/all-webform.scss'
import { useState } from 'react'
import { ThankYouPageContent } from 'src/webform/ThankYouPageContent'
import { SubscribeContent } from 'src/webform/SubscribeContent'
import styles from './styles.module.scss'

export interface DataItem {
  type: string
  required: boolean
  key: string
  name: string
  placeholder: string
}

export const AppWebForm = ({
  data,
  thankYouPage,
  termsUrl,
  complianceUrl,
  fromApp,
  uuid,
  env,
  subscribeBtnText,
}: {
  data: DataItem[]
  thankYouPage: string
  termsUrl: string
  complianceUrl: string
  fromApp?: boolean
  uuid?: string
  env?: string
  subscribeBtnText?: string | null
}) => {
  const [isThankYouPage, setIsThankYouPage] = useState(false)

  const openThank = () => {
    if (thankYouPage) {
      const href = thankYouPage.startsWith('http')
        ? thankYouPage
        : '//' + thankYouPage
      const link = document.createElement('a')
      link.setAttribute('href', href)
      if (fromApp) {
        link.setAttribute('target', '_blank')
      }
      link.click()
    } else {
      setIsThankYouPage(true)
    }
  }

  return (
    <div className={styles.wrap}>
      {isThankYouPage ? (
        <ThankYouPageContent />
      ) : (
        <SubscribeContent
          data={data}
          openThank={openThank}
          termsUrl={termsUrl}
          complianceUrl={complianceUrl}
          fromApp={fromApp}
          uuid={uuid}
          env={env}
          subscribeBtnText={subscribeBtnText}
        />
      )}
    </div>
  )
}
