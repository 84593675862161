import classNames from 'classnames'
import styles from './styles.module.scss'
import { Icon } from 'components/Icon/Icon'
import { IStatusProps } from 'components/Status/type'
import { Colors } from 'styles/colors'

export const Status = ({ title, icon, color, status }: IStatusProps) => {
  return (
    <div className={classNames(styles.wrap)} style={{ color: Colors[color] }}>
      {icon && <Icon icon={icon} fontSize={16} />}
      <span className={classNames('s1', styles.title)}>{title || status}</span>
    </div>
  )
}
