import { makeAutoObservable, reaction } from 'mobx'
import {
  Asset,
  Maybe,
  StockExchange,
  TenDlcBusinessType,
  TenDlcMessageSampleRequestInput,
  TenDlcOptInDescriptionRequestInput,
  TenDlcOptInImagesRequestInput,
  TenDlcOptInMethod,
  TenDlcOptInMethodRequestInput,
  TenDlcPackageType,
  TenDlcProfile,
  TenDlcProfileStatus,
  TenDlcProfileStep,
  TenDlcSelectPackageRequestInput,
  TenDlcUseCaseRequestInput,
  TenDlcUseCaseType,
} from 'src/generated/graphql'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import companyStore from 'store/settings/company/companyStore'
import typesStore from 'store/typesStore'

export class A2p10DLCStore {
  constructor() {
    makeAutoObservable(this)
    reaction(
      () => businessProfileStore.isHaveEIN,
      (isHaveEIN) => {
        if (this.tenDlcProfile?.packageType) {
          return
        }
        this.selectedPackage = isHaveEIN
          ? TenDlcPackageType.LowVolumeStandard
          : TenDlcPackageType.SoleProprietor
      }
    )
  }

  tenDlcProfile: TenDlcProfile | null = null
  initLoaded = false
  initLoadedTypes = false

  selectedPackage: TenDlcPackageType = TenDlcPackageType.SoleProprietor
  businessType: TenDlcBusinessType | null = null
  stockExchange: StockExchange | null = null
  companyStockTicker = ''
  useCaseType: Maybe<TenDlcUseCaseType> = null
  useCaseDescription = ''
  optInMethods: TenDlcOptInMethod[] = []
  optInDescriptionsMap: Map<TenDlcOptInMethod, string> = new Map(
    Object.values(TenDlcOptInMethod).map((key) => [key, ''])
  )
  optInImagesMap: Map<TenDlcOptInMethod, Asset[]> = new Map(
    Object.values(TenDlcOptInMethod).map((key) => [key, []])
  )
  firstMessage = ''
  secondMessage = ''

  openBrandA2P10DLCModal = false
  openUseCaseA2P10DLCModal = false

  get status() {
    return this.tenDlcProfile?.status || TenDlcProfileStatus.Draft
  }

  get statusString() {
    return typesStore.a2pStatusMapping.get(this.status)
  }

  get currentStep() {
    return this.tenDlcProfile?.currentStep || TenDlcProfileStep.SelectPackage
  }

  get step() {
    const obj = {
      [TenDlcProfileStep.SelectPackage]: 1,
      [TenDlcProfileStep.UseCase]: 2,
      [TenDlcProfileStep.OptInMethod]: 3,
      [TenDlcProfileStep.OptInDescription]: 4,
      [TenDlcProfileStep.OptInImages]: 5,
      [TenDlcProfileStep.SampleMessages]: 6,
      [TenDlcProfileStep.Payment]: 7,
      [TenDlcProfileStep.InReview]: 8,
    }
    return obj[this.currentStep]
  }

  get brand() {
    return companyStore.companyName
  }

  get useCaseTypeString() {
    const type = this.tenDlcProfile?.tenDlcUseCase?.useCaseType
    if (type) {
      return typesStore.a2pUseCaseTypeMapping.get(type) || type
    }
    return ''
  }

  get completed() {
    return (
      this.tenDlcProfile &&
      this.tenDlcProfile?.status &&
      this.tenDlcProfile?.status !== TenDlcProfileStatus.Draft
    )
  }

  get completedStep() {
    return this.step - 1
  }

  get brandRegistrationFees() {
    return (
      typesStore.a2pPackageTypes.get(this.selectedPackage)
        ?.brandRegistrationFees || 0
    )
  }
  get campaignVerificationFees() {
    return (
      typesStore.a2pPackageTypes.get(this.selectedPackage)
        ?.campaignVerificationFees || 0
    )
  }
  get reoccurringCampaignUseCaseFees() {
    return (
      typesStore.a2pPackageTypes.get(this.selectedPackage)
        ?.reoccurringCampaignUseCaseFees || 0
    )
  }
  get total() {
    return (
      this.brandRegistrationFees +
      this.campaignVerificationFees +
      this.reoccurringCampaignUseCaseFees
    )
  }

  get tenDlcSelectPackageRequestInput(): TenDlcSelectPackageRequestInput {
    return {
      packageType: this.selectedPackage,
      businessType: this.businessType,
      companyStockTicker: this.companyStockTicker,
      stockExchange: this.stockExchange,
    }
  }

  get tenDlcUseCaseRequestInput(): TenDlcUseCaseRequestInput {
    return {
      useCase: {
        useCaseType: this.useCaseType,
        useCaseDescription: this.useCaseDescription,
      },
    }
  }

  get tenDlcOptInMethodRequestInput(): TenDlcOptInMethodRequestInput {
    return {
      optInMethods: this.optInMethods,
    }
  }

  get tenDlcOptInDescriptionRequestInput(): TenDlcOptInDescriptionRequestInput {
    return {
      optInDescriptions: this.optInDescriptions,
    }
  }

  get tenDlcOptInImagesRequestInput(): TenDlcOptInImagesRequestInput {
    return {
      optInImageIDs: this.optInImageIDs,
    }
  }

  get tenDlcMessageSampleRequestInput(): TenDlcMessageSampleRequestInput {
    return {
      firstMessage: this.firstMessage,
      secondMessage: this.secondMessage,
    }
  }

  get optInDescriptions() {
    const optInDescriptions: {
      [key: string]: string
    } = {}
    this.optInMethods.forEach((method) => {
      optInDescriptions[method] = this.optInDescriptionsMap.get(method) || ''
    })

    return optInDescriptions
  }

  get optInImagesIds() {
    const optInImagesIds: {
      [key: string]: string[]
    } = {}
    this.optInMethods.forEach((method) => {
      optInImagesIds[method] =
        this.optInImagesMap.get(method)?.map((asset) => asset.id) || []
    })

    return optInImagesIds
  }

  get optInImageIDs() {
    const optInImageIDs: {
      [key: string]: string[]
    } = {}
    this.optInMethods.forEach((method) => {
      optInImageIDs[method] =
        this.optInImagesMap.get(method)?.map((asset) => asset.id || '') || []
    })

    return optInImageIDs
  }

  get disabledSelectedPackage() {
    if (!this.businessType) {
      return true
    }
    if (this.businessType === TenDlcBusinessType.Public) {
      return !this.stockExchange || !this.companyStockTicker
    }
    return false
  }

  get disabledUseCase() {
    if (this.useCaseDescriptionError) {
      return true
    }
    return !this.useCaseType || !this.useCaseDescription.trim()
  }

  get useCaseDescriptionError() {
    if (this.useCaseDescription && this.useCaseDescription.trim().length < 40) {
      return 'The description must be at least 40 characters'
    } else {
      return ''
    }
  }

  get disabledOptInMethods() {
    return !this.optInMethods.length
  }

  get disabledOptInMethodsDesc() {
    return (
      !Object.values(this.optInDescriptions).length ||
      Object.values(this.optInDescriptions).some((value) => !value.trim())
    )
  }

  get disabledOptInMethodsImages() {
    return Object.values(this.optInImagesIds).some((value) => !value.length)
  }

  get disabledSamples() {
    return !this.firstMessage.trim() || !this.secondMessage.trim()
  }

  get continueText() {
    if (this.step === this.stepCount) {
      return 'Submit my application'
    }
    return 'Continue'
  }

  get progressPercent() {
    return (this.step / this.stepCount) * 100
  }

  get completedPercent() {
    return ((this.step - 1) / this.stepCount) * 100
  }

  get stepTitle() {
    const obj = {
      [TenDlcProfileStep.SelectPackage]: 'Select package',
      [TenDlcProfileStep.UseCase]: 'Campaign use case',
      [TenDlcProfileStep.OptInMethod]: 'Opt-in method',
      [TenDlcProfileStep.OptInDescription]: 'Opt-in description',
      [TenDlcProfileStep.OptInImages]: 'Opt-in image',
      [TenDlcProfileStep.SampleMessages]: 'Sample messages',
      [TenDlcProfileStep.Payment]: 'Payment',
      [TenDlcProfileStep.InReview]: 'In Review',
    }
    return obj[this.currentStep]
  }

  get stepCount() {
    return 7
  }

  setOpenBrandA2P10DLCModal(state: boolean) {
    this.openBrandA2P10DLCModal = state
  }

  setOpenUseCaseA2P10DLCModal(state: boolean) {
    this.openUseCaseA2P10DLCModal = state
  }

  setTenDlcProfile(profile?: TenDlcProfile | null) {
    if (!profile) {
      this.initLoaded = true
      return
    }
    this.tenDlcProfile = profile
    this.setSelectedPackage(profile.packageType)
    this.setSelectedBusinessType(profile.businessType)
    this.setStockExchange(profile.stockExchange)
    this.setCompanyStockTicker(profile.companyStockTicker)
    this.setUseCaseType(profile.tenDlcUseCase?.useCaseType)
    this.setUseCaseDescription(profile.tenDlcUseCase?.useCaseDescription)

    const optInMethods: TenDlcOptInMethod[] = []

    profile.optInMethods?.forEach((method) => {
      if (method) {
        optInMethods.push(method)
      }
    })
    if (profile.optInDescriptions) {
      Object.entries(profile.optInDescriptions).forEach(([key, value]) => {
        this.optInDescriptionsMap.set(
          key as TenDlcOptInMethod,
          (value as string) || ''
        )
      })
    }
    if (profile.optInAssets) {
      Object.entries(profile.optInAssets).forEach(([key, value]) => {
        this.optInImagesMap.set(key as TenDlcOptInMethod, value as Asset[])
      })
    }

    this.optInMethods = optInMethods

    this.firstMessage = profile.firstSampleMessage || ''
    this.secondMessage = profile.secondSampleMessage || ''
    this.initLoaded = true
  }

  setSelectedPackage(selectedPackage?: TenDlcPackageType | null) {
    if (selectedPackage) {
      this.selectedPackage = selectedPackage
    }
  }

  setSelectedBusinessType(businessType?: TenDlcBusinessType | null) {
    this.businessType = businessType || null
  }
  setStockExchange(stockExchange?: StockExchange | null) {
    this.stockExchange = stockExchange || null
  }

  setCompanyStockTicker(companyStockTicker?: string | null) {
    this.companyStockTicker = companyStockTicker || ''
  }
  setUseCaseType(value?: TenDlcUseCaseType | null) {
    this.useCaseType = value || null
  }
  setUseCaseDescription(value?: string | null) {
    this.useCaseDescription = value || ''
  }
  // checkUseCaseDescriptionError() {
  //   if (this.useCaseDescription.trim().length < 40) {
  //     this.useCaseDescriptionError =
  //       'The description must be at least 40 characters'
  //   } else {
  //     this.useCaseDescriptionError = ''
  //   }
  // }
  setOptInMethods(key: TenDlcOptInMethod) {
    if (!this.optInMethods.includes(key)) {
      this.optInMethods.push(key)
    } else {
      this.optInMethods = this.optInMethods.filter((method) => method !== key)
    }
  }

  setOptInDescription(key: TenDlcOptInMethod, value: string) {
    this.optInDescriptionsMap.set(key, value)
  }

  setOptInImages(key: TenDlcOptInMethod, value: Asset[]) {
    const prev = this.optInImagesMap.get(key) || []
    this.optInImagesMap.set(key, [...prev, ...value].slice(-3))
  }

  removeOptInImages(key: TenDlcOptInMethod, ids: string[]) {
    const prev = this.optInImagesMap.get(key) || []
    this.optInImagesMap.set(
      key,
      prev.filter((asset) => !ids.includes(asset.id))
    )
  }

  setFirstMessage(value: string) {
    this.firstMessage = value
  }
  setSecondMessage(value: string) {
    this.secondMessage = value
  }
}

export default new A2p10DLCStore()
