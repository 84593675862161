import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { PhoneType, useReservePhoneNumberMutation } from 'src/generated/graphql'

export const ActionsChoose = observer(() => {
  const [reservePhoneNumber, { loading }] = useReservePhoneNumberMutation()
  const onConfirm = async () => {
    if (!addNumberStore.chosenNumber?.phoneNumber) return
    if (addNumberStore.stepChoose !== addNumberStore.stepChooseCount) {
      addNumberStore.setChooseStep(addNumberStore.stepChoose + 1)
    } else {
      try {
        const { data } = await reservePhoneNumber({
          variables: {
            input: {
              inputPhoneNumber: addNumberStore.chosenNumber.phoneNumber,
              phoneType: addNumberStore.isLocalTab
                ? PhoneType.Local
                : PhoneType.TollFree,
              city: addNumberStore.isLocalTab
                ? addNumberStore.chosenNumber.locality
                : undefined,
              state: addNumberStore.isLocalTab
                ? addNumberStore.chosenNumber.region
                : undefined,
            },
          },
        })
        if (data?.reservePhoneNumber) {
          addNumberStore.onFirstNumberReserve(data?.reservePhoneNumber)
        } else {
          console.error('Dont reserve phone number')
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  const handleRefresh = () => {
    addNumberStore.setRefreshTrigger()
  }
  return (
    <div className={'row12 mt40'}>
      <Button
        typeBtn={BtnType.primary}
        size={BtnSize.medium}
        onClick={onConfirm}
        disabled={!addNumberStore.chosenNumber || addNumberStore.loadingData}
        loading={loading}
      >
        {addNumberStore.stepChoose === addNumberStore.stepChooseCount
          ? 'Confirm'
          : 'Continue'}
      </Button>
      <MediumBtn
        type={ActionsEnum.reload}
        text={'Refresh'}
        onClick={handleRefresh}
      />
    </div>
  )
})
