import { Modal } from 'components/Modal/Modal'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { numberString } from 'src/util/functions'
import { useState } from 'react'

type CampaignEnrollModalProps = {
  open: boolean
  onEnroll: () => Promise<void>
  onDontEnroll: () => Promise<void>
  onCancel: () => void
  count: number
}

export const CampaignEnrollModal = ({
  open,
  count,
  onCancel,
  onEnroll,
  onDontEnroll,
}: CampaignEnrollModalProps) => {
  const [loadingEnroll, setLoadingEnroll] = useState(false)
  const [loadingDontEnroll, setLoadingDontEnroll] = useState(false)
  const handleEnroll = async () => {
    setLoadingEnroll(true)
    await onEnroll()
    setLoadingEnroll(false)
  }
  const handleDontEnroll = async () => {
    setLoadingDontEnroll(true)
    await onDontEnroll()
    setLoadingDontEnroll(false)
  }
  return (
    <Modal open={open} onClose={onCancel} withoutHeader>
      <div className={'pModalInfo'}>
        <ModalIcon type={'info'} />
        <h3>
          Do you want to enroll your {numberString({ val: count })} contacts in
          your campaign?
        </h3>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={handleEnroll}
          loading={loadingEnroll}
          fullWidth
          mb={8}
        >
          Enroll {numberString({ val: count })} contacts
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={handleDontEnroll}
          loading={loadingDontEnroll}
          fullWidth
          mb={16}
        >
          Don’t enroll
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onCancel}
          fullWidth
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
