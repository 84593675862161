import { Column, Row, usePagination, useTable } from 'react-table'
import styles from './style.module.scss'
import classNames from 'classnames'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Refresh } from 'icons/16px/Refresh.svg'
import { ReactComponent as AngleLeft } from 'icons/16px/Angle-Left.svg'
import { ReactComponent as AngleRight } from 'icons/16px/Angle-Right.svg'

export enum TableVariant {
  variant1 = 'variant1',
}

interface Props<T> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  columns: Column<T>[]
  data: T[]
  minWidthColumn?: number
  variant?: TableVariant
  withPagination?: boolean
  paginationClassName?: string
  pageSize?: number
  dataLength?: number
  tdHeight?: number
}

export function Table<T>({
  columns,
  data,
  minWidthColumn,
  variant,
  withPagination,
  paginationClassName,
  pageSize = 10,
  dataLength,
  tdHeight,
}: Props<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    page,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    canPreviousPage,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    canNextPage,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    nextPage,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    previousPage,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state: { pageIndex },
  } =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useTable<T>(
      { columns, data, initialState: { pageIndex: 0, pageSize } },
      usePagination
    )

  return (
    <>
      <table
        {...getTableProps()}
        className={classNames(styles.table, variant && styles[variant])}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps()}
                  style={{ minWidth: minWidthColumn }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*// @ts-ignore */}
          {page.map((row: Row<T>) => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()} style={{ height: tdHeight }}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {withPagination && (
        <div className={classNames(styles.pagination, paginationClassName)}>
          <Button size={BtnSize.mini} icon>
            <Refresh />
          </Button>
          <div className={styles.nav}>
            <Button
              size={BtnSize.mini}
              icon
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <AngleLeft />
            </Button>
            <div className={styles.page}>{pageIndex + 1}</div>
            <Button
              size={BtnSize.mini}
              icon
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <AngleRight />
            </Button>
          </div>
          <div>
            <span className={'gray1'}>
              Showing
              <span className={classNames(styles.count, 'blue1')}>25</span>
              of {dataLength}
            </span>
          </div>
        </div>
      )}
    </>
  )
}
