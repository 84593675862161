import styles from './styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TextareaStore } from 'store/textareaStore'
import { Textarea } from 'components/Textarea/Textarea'
import { EnumFutureContent } from 'components/Textarea/MoreFeatures/MoreFeatures'
import messageTemplateStore from 'src/widgets/MessageTemplate/store/MessageTemplateStore'
import { IMessageTemplate } from 'src/widgets/MessageTemplate'
import { useApolloError } from 'src/hooks/useApolloError'

type Props = {
  editTemplate?: IMessageTemplate | null
}

export const NewTemplate: FC<Props> = observer(({ editTemplate }) => {
  const [textareaStore] = useState(() => new TextareaStore({}))
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const {
    onCloseCreateMode,
    createTemplate,
    updateTemplate,
    apolloError,
    setApolloError,
  } = messageTemplateStore
  const onClose = () => {
    onCloseCreateMode()
  }
  const onSave = async () => {
    setLoading(true)
    if (editTemplate) {
      await updateTemplate({
        id: editTemplate.id,
        name,
        smsMessage: textareaStore.smsMessageRequestInput,
      })
    } else {
      await createTemplate({
        name,
        smsMessage: textareaStore.smsMessageRequestInput,
      })
    }

    setLoading(false)
  }
  const onCancel = () => {
    onClose()
  }

  const { ErrorAlert } = useApolloError(apolloError)

  useEffect(() => {
    return () => {
      setApolloError(null)
    }
  }, [])

  useEffect(() => {
    if (editTemplate) {
      setName(editTemplate.name || '')
      textareaStore.setSmsMessage(editTemplate.smsMessage)
    }
  }, [editTemplate])

  return (
    <div className={styles.wrap}>
      <div className={styles.inputWrap}>
        <InputText
          placeholder={'Template name...'}
          className={styles.input}
          value={name}
          onChangeValue={setName}
        />
      </div>
      <Textarea
        textareaStore={textareaStore}
        compose
        filterFutureOption={EnumFutureContent.personalize}
        smallGallery
        fromTemplate
      />
      <ErrorAlert className={styles.error} />
      <div className={styles.actions}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          disabled={!name || !textareaStore.messageHtml}
          onClick={onSave}
          loading={loading}
        >
          Save template
        </Button>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
})
