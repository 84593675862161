// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.njc7jY50wrHcXf66u4XK{display:grid;grid-template-columns:1fr 100px;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/components/CreateElement/modals/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,+BAAA,CACA,eAAA","sourcesContent":[".wrapNumber{\n  display: grid;\n  grid-template-columns: 1fr 100px;\n  column-gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapNumber": `njc7jY50wrHcXf66u4XK`
};
export default ___CSS_LOADER_EXPORT___;
