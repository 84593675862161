import { TableStore } from 'components/NewTable/store/TableStore'
import { useMemo } from 'react'
import { NullHandling } from 'src/generated/graphql'
import { tableStatusToBack } from 'src/util/tableStatusToBack'
import { RequiredFields } from 'components/NewTable/types'

export function useTablePaginationOptions<T extends RequiredFields<T>>(
  tableStore: TableStore<T>
) {
  return useMemo(
    () => ({
      variables: {
        page: {
          pageNumber: tableStore.page,
          pageSize: tableStore.rowsPerPage,
          sort: tableStore.noSort
            ? undefined
            : {
                orders: [
                  {
                    direction: tableStore.order,
                    property: tableStore.orderBy,
                    nullHandlingHint: NullHandling.Native,
                    ignoreCase: tableStore.orderBy !== 'id',
                  },
                ],
              },
        },
        status: tableStatusToBack(tableStore.status),
        uuid: undefined as unknown as string,
        searchPattern: tableStore.search,
        pattern: tableStore.search || null,
        dateRange: tableStore.rangeDatePickerStore.dateRange,
      },
    }),
    [
      tableStore.order,
      tableStore.orderBy,
      tableStore.page,
      tableStore.rowsPerPage,
      tableStore.status,
      tableStore.rangeDatePickerStore.startDate,
      tableStore.rangeDatePickerStore.endDate,
      tableStore.search,
    ]
  )
}
