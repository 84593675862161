import { FC } from 'react'
import { ScheduleProps } from 'components/Schedule/Schedule'
import { CampaignScheduleStore } from 'components/Schedule/store/campaignScheduleStore'
import styles from 'components/Schedule/styles.module.scss'
import { ScheduleBtn } from 'components/Schedule/ScheduleBtn'
import { CalendarDateContent } from 'components/Schedule/CalendarDateCard/CalendarDateContent/CalendarDateContent'
import { CampaignDelayType } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'

export const CalendarDateCard: FC<ScheduleProps> = observer(
  ({ scheduleStore }) => {
    if (scheduleStore instanceof CampaignScheduleStore) {
      return (
        <>
          <div className={styles.row}>
            <ScheduleBtn
              active={scheduleStore.activeCalendarDate}
              title={'Calendar Date'}
              onClick={() => {
                scheduleStore.setSendTypeCampaign(CampaignDelayType.Date)
              }}
            />
            <CalendarDateContent scheduleStore={scheduleStore} />
          </div>
        </>
      )
    }
    return null
  }
)
