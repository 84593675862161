import styles from './styles.module.scss'
import billingStore from 'store/settings/billing/billingStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { FeedbackContent } from 'components/Feedbackontent/FeedbackContent'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useSwitchPlanMutation } from 'src/generated/graphql'
import { CardDropdownProps } from 'src/routes/settings/billing/components/PrimaryCardDropdown'
import { SubscriptionDetailCard } from 'src/Modals/UpgradePlanModal/components/SubscriptionDetailCard'

export const mockAnswers = [
  'I was on the wrong plan',
  'I can no longer afford it / budget reasons',
  'My business is seasonal',
  'I didn’t use the credits',
  'Too many credits',
  'Other',
]

export const Step3Downgrade = observer(
  ({ selectedValue }: CardDropdownProps) => {
    const [selectedAnswer, setSelectedAnswer] = useState<string>('')
    const [upgradePlan, { loading }] = useSwitchPlanMutation()

    const handleUpgrade = async () => {
      if (!upgradePlanStore.planName) return
      try {
        const res = await upgradePlan({
          variables: {
            price: upgradePlanStore.planName,
            paymentMethodId: selectedValue,
          },
        })
        if (res.data?.switchPlan?.subscription) {
          billingStore.setClSubscription(res.data.switchPlan.subscription)
          res.data?.switchPlan?.balances &&
            billingStore.setBalances(res.data?.switchPlan?.balances)
          upgradePlanStore.onConfirm()
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onBack = () => {
      upgradePlanStore.setActiveModalStep(0)
    }
    return (
      <>
        <div className={styles.wrap}>
          <FeedbackContent
            selectedAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
            answers={mockAnswers}
            title={`We'd like to know why you're downgrading:`}
          />
          <SubscriptionDetailCard />
          <div className={styles.actions}>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={handleUpgrade}
              disabled={!selectedAnswer}
              loading={loading}
            >
              Downgrade to {upgradePlanStore.planTitle}
            </Button>
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={onBack}
            >
              Back
            </Button>
            <div />
          </div>
        </div>
      </>
    )
  }
)
