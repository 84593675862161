import styles from './styles.module.scss'
import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { CSSProperties, FC, ReactNode } from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'

type Props = {
  tabs: Array<string | ReactNode>
  activeTabIndex: number
  onTabClick: (index: number) => void
  mb?: number
  comingSoonTabs?: number[]
  paddingInline?: CSSProperties['paddingInline']
}

export const Tabs: FC<Props> = ({
  tabs,
  activeTabIndex,
  mb,
  onTabClick,
  comingSoonTabs,
  paddingInline,
}) => (
  <div className={styles.wrap} style={{ marginBottom: mb, paddingInline }}>
    {tabs.map((tab, index) => (
      <MuiTooltip
        key={index}
        title={
          (comingSoonTabs && comingSoonTabs.includes(index) && 'Coming soon') ||
          ''
        }
        arrow
        placement={'top'}
      >
        <div className={styles.wrapBtn}>
          <Button
            key={index}
            className={classNames(
              styles.tab,
              index === activeTabIndex && styles.active
            )}
            onClick={() => onTabClick(index)}
            disabled={comingSoonTabs && comingSoonTabs.includes(index)}
          >
            {tab}
          </Button>
        </div>
      </MuiTooltip>
    ))}
    <div className={styles.bottomBorder} />
  </div>
)
