// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oNIW9DhoSBec46HtTUDT{position:relative;min-height:calc(100vh - 70px)}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,6BAAA","sourcesContent":[".wrap{\n  position: relative;\n  min-height: calc(100vh - 70px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `oNIW9DhoSBec46HtTUDT`
};
export default ___CSS_LOADER_EXPORT___;
