import NewTable from 'components/NewTable/NewTable'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Filters } from './Filters/Filters'
import { HoverRowComponent } from './HoverRowComponent/HoverRowComponent'
import { Status, useGetAllKeywordsQuery } from 'src/generated/graphql'
import keywordsStore from 'store/keywords/keywordsStore'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { EmptyState } from 'components/EmptyState/EmptyState'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import { noSortsColumns } from 'components/NewTable/columns/keywords/columnsKeywords'
import { useSetData } from 'src/hooks/useSetData'
import { IKeyword } from 'src/types/IKeyword'
import { AppElements } from 'src/enums/appElements'

export const KeywordsTable = observer(() => {
  const { tableStore, keywords } = keywordsStore
  const options = useTablePaginationOptions(tableStore)

  const queryResult = useGetAllKeywordsQuery(options)
  const navigate = useNavigate()
  const onRowClick = (id: string, row: IKeyword) => {
    if (row.status === Status.Draft) {
      navigate(id + '/edit')
    } else {
      navigate(id)
    }
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => keywordsStore.setData(data),
    refreshDeps: [keywordsStore.tableStore.refreshTrigger],
  })

  if (!loading && !keywords.length && !tableStore.search) {
    return (
      <EmptyState
        element={AppElements.Keyword}
        description={'Use keywords to capture leads'}
      />
    )
  }

  return (
    <div className={'p64withMobile'}>
      <NewTable<IKeyword>
        noSortsColumns={noSortsColumns}
        withCheckbox
        columns={tableStore.visibleColumns}
        rows={keywords}
        leftStickyContent={<Filters />}
        withSearch
        onRowClick={onRowClick}
        HoverRowComponent={HoverRowComponent}
        // maxHeight={CustomHeight.tableHeight}
        emptyContent={
          !keywords.length ? (
            <EmptyStateSearch element={AppElements.Keyword} />
          ) : undefined
        }
        tableStore={tableStore}
        pageLoading={loading || refetchLoading}
        firstLoading={firstLoading}
        withCalcMaxHeight
      />
    </div>
  )
})
