import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import { useMemo } from 'react'
import webFormStore from 'store/webForms/webFormStore'
import styles from './styles.module.scss'
import { CopyBtn } from 'components/Button/CopyBtn'
import { BtnSize } from 'components/Button/Button'
import companyStore from 'store/settings/company/companyStore'

export const GetWebFromCodeModal = observer(() => {
  const onClose = () => {
    webFormStore.setGetCodeWebForm(null)
  }
  const origin =
    window.origin === 'http://localhost:3000'
      ? 'https://dev.callloop.com'
      : window.origin
  const originEnv = window.origin.split('https://').pop()?.split('.').shift()
  const env = window.origin === 'http://localhost:3000' ? 'dev' : originEnv

  const code = useMemo(() => {
    if (webFormStore.getCodeWebForm) {
      return `<div id='call-loop-form' style='width: 376px' data-uuid='${
        webFormStore.getCodeWebForm.uuid
      }' data-env='${env}'>
      ${webFormStore.getCodeWebForm.fieldsToCollect
        .map(
          (field) =>
            `<div data-id="call-loop-form-field" data-type="${field.field?.type}" data-required="${field.required}" data-name="${field.field?.name}" data-key="${field.field?.key}"></div>`
        )
        .join('\n      ')}
      <div id='call-loop-thank-you-page' data-url='${
        webFormStore.getCodeWebForm.thankYouPage
      }'></div>
      <div id='call-loop-terms-url' data-url='${companyStore.termsUrl}'></div>
      <div id='call-loop-privacy-policy-url' data-url='${
        companyStore.complianceUrl
      }'></div>
      <div id='call-loop-subscribe-btn-text' data-text='${
        webFormStore.getCodeWebForm.subscribeBtnText
      }'></div>
</div>
<script src='${origin}/callLoopForm.js'></script>`
    }
    return ''
  }, [webFormStore.getCodeWebForm])
  return (
    <Modal
      open={!!webFormStore.getCodeWebForm}
      onClose={onClose}
      title={'Get Code'}
    >
      <div className={'mediumModal'}>
        <div className={styles.content}>
          <div className={styles.innerContent}>
            <pre>{code}</pre>
          </div>
        </div>
        <CopyBtn
          content={code}
          size={BtnSize.medium}
          className={styles.copyBtn}
        />
      </div>
    </Modal>
  )
})
