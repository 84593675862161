import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import React, { FC } from 'react'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import { Separator } from 'components/Separator/Separator'

export const AddCustomField: FC<IBottomDropdownButtonProps> = ({ onClose }) => (
  <div className={'stickyBottom'}>
    <Button
      size={BtnSize.small}
      menuBtn
      hoverGray
      onClick={() => {
        onClose && onClose()
        customFieldsStore.setOpenCustomFieldsModalModal(true)
      }}
    >
      <Plus />
      <span className={'bold blue1'}>Add custom field</span>
    </Button>
  </div>
)

export const AddCustomFieldSticky = (props: IBottomDropdownButtonProps) => {
  return (
    <div className={'stickyBottom'}>
      {!!customFieldsStore.customFieldsOptions.length && (
        <Separator marginBlock={8} />
      )}
      <AddCustomField {...props} />
    </div>
  )
}
