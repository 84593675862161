// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l7BtrHLcwgPjMJwX_sf_{padding-left:32px;padding-bottom:32px;width:528px}.ns09SrM_I6uZ9Z5YfHyg{margin-right:32px}`, "",{"version":3,"sources":["webpack://./src/widgets/MessageTemplate/MessageTemplatesModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,mBAAA,CACA,WAAA,CAGF,sBACE,iBAAA","sourcesContent":[".wrap{\n  padding-left: 32px;\n  padding-bottom: 32px;\n  width: 528px;\n  //margin-right: 8px;\n}\n.wrapTabs{\n  margin-right: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `l7BtrHLcwgPjMJwX_sf_`,
	"wrapTabs": `ns09SrM_I6uZ9Z5YfHyg`
};
export default ___CSS_LOADER_EXPORT___;
