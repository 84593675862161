import { CreateElementBtn } from 'components/Button/CreateElementBtn'

import { AppElements } from 'src/enums/appElements'

export const NoSelectContent = () => {
  return (
    <>
      <CreateElementBtn appElement={AppElements.Trigger} />
    </>
  )
}
