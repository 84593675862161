// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fW4qHkp5c9ARKa2GWOWn{height:100vh;background-color:#fff;display:grid;grid-template-rows:min-content 1fr}.EuYMs2Qg_vCWSPU2Htza{display:grid;height:100%;padding-top:24px;padding-left:64px}.YnUgk3JgVLRiKodNHARo{margin-top:18px;padding-left:24px;margin-right:64px;display:flex;column-gap:8px;align-items:center}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/importHistory/FailedImportHistory/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,kCAAA,CAEF,sBACE,YAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CAEF,sBACE,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  height: 100vh;\n  background-color: white;\n  display: grid;\n  grid-template-rows: min-content 1fr;\n}\n.tableWrap{\n  display: grid;\n  height: 100%;\n  padding-top: 24px;\n  padding-left: 64px;\n}\n.content{\n  margin-top: 18px;\n  padding-left: 24px;\n  margin-right: 64px;\n  display: flex;\n  column-gap: 8px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `fW4qHkp5c9ARKa2GWOWn`,
	"tableWrap": `EuYMs2Qg_vCWSPU2Htza`,
	"content": `YnUgk3JgVLRiKodNHARo`
};
export default ___CSS_LOADER_EXPORT___;
