import { ColorsNames } from 'styles/variables'

export enum ClassesEnum {
  par0 = 'par0',
  par2 = 'par2',
  s2 = 's2',
  s3 = 's3',
  s4 = 's4',
  p4 = 'p4',
  p8 = 'p8',
  p16 = 'p16',
  mb4 = 'mb4',
  mb8 = 'mb8',
  none = 'none',
  white = 'white',
  black = 'black',
  bold = 'bold',
  regular = 'regular',
  medium = 'medium',
  placeholder = 'placeholder',
  personalize = 'personalize',
  link = 'link',
  linkOrange = 'linkOrange',
  linkRed = 'linkRed',
  capitalize = 'capitalize',
  rotate = 'rotate',
  nowrap = 'nowrap',
  flexIcon = 'flexIcon',
}

export type GlobalClasses = ColorsNames | ClassesEnum
