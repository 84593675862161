import { Colors, IColor } from 'styles/colors'

export const Separator = ({
  color = Colors['PrimaryColorGray3'] as IColor,
  width = '100%',
  marginLeft = 0,
  marginBlock = 0,
}) => (
  <div
    style={{
      height: 1,
      width,
      background: Colors[color],
      marginLeft,
      marginBlock,
    }}
  />
)
