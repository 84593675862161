// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wX4kgRXnWs8KCGUC5pk1{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);z-index:2}`, "",{"version":3,"sources":["webpack://./src/components/Loader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,QAAA,CACA,OAAA,CACA,+BAAA,CACA,SAAA","sourcesContent":[".loader{\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `wX4kgRXnWs8KCGUC5pk1`
};
export default ___CSS_LOADER_EXPORT___;
