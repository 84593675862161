import { observer } from 'mobx-react-lite'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import migrationStore from 'store/migration/migrationStore'
import { PERCENT_BONUS_CREDITS } from 'src/static/constants'
import { Alert } from 'components/Alert/Alert'
import React from 'react'

export const MigrationBonusAlert = observer(() => {
  return (
    <Alert
      type={AlertTypeEnum.notify2}
      title={
        migrationStore.withExpiredCredits
          ? `Migrate your expired credits at a big discount!`
          : `Switch to an annual plan now and receive ${PERCENT_BONUS_CREDITS} bonus credits FREE!`
      }
      text={
        "This exclusive offer is a one-time opportunity and won't be available ever again"
      }
    />
  )
})
