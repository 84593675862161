import styles from './styles.module.scss'
import classNames from 'classnames'
import { FC } from 'react'

type Props = {
  activeStep: number
  steps: string[]
}

export const Steps: FC<Props> = ({ activeStep, steps }) => (
  <div className={styles.wrap}>
    {steps.map((title, index) => (
      <div
        key={title}
        className={classNames(
          styles.wrapStep,
          activeStep === index && styles.active,
          activeStep > index && styles.complete
        )}
      >
        <div className={classNames(styles.step)}>
          <span className={'medium'}>{index + 1}</span>
        </div>
        <span className={classNames('medium blue1', styles.stepTitle)}>
          {title}
        </span>
      </div>
    ))}
  </div>
)
