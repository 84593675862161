import { FC, ReactNode, useState } from 'react'
import styles from 'components/VCard/MediaCardItem/styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { InputText } from 'components/Input/InputText/InputText'
import { ReactComponent as Close } from 'icons/16px/CloseCustom.svg'
import { SocialMediaEnums } from 'src/enums/SocialMediaEnums'
import { SocialMediaIcon } from 'components/AppIcon/SocialMediaIcon'

export const getLastLinkPart = (link: string) => {
  const split = link.split('/')
  return '/' + split[split.length - 1]
}

export type MediaCardItemType = {
  type: SocialMediaEnums
  link: string
  disabled?: boolean
  setLink: (link: string) => void
}
export const MediaCardItem: FC<MediaCardItemType> = ({
  type,
  link,
  setLink,
  disabled,
}) => {
  const [edit, setEdit] = useState(false)
  const [value, setValue] = useState(link)
  const onSubmit = () => {
    setEdit(false)
    setLink(value)
  }
  const onCancel = () => {
    setEdit(false)
    setValue(link)
  }
  const onDelete = () => {
    setLink('')
    setValue('')
  }
  const LinkWrapper: FC<{ children?: ReactNode }> = ({ children }) =>
    value ? (
      <a href={value} target={'_blank'} rel="noreferrer">
        {children}
      </a>
    ) : (
      <>{children}</>
    )
  return (
    <div className={styles.mediaCardItem}>
      {edit ? (
        <>
          <div className={styles.wrapLeft}>
            <div className={styles.wrapIcon}>
              <SocialMediaIcon icon={type} />
            </div>
            <InputText
              withoutBorder
              placeholder={'Enter link'}
              value={value}
              onChange={(event) => {
                setValue(event.target.value)
              }}
              withoutClear
            />
          </div>

          <div className={styles.actions}>
            <Button onClick={onCancel} blackHoverText>
              <p className={'bold gray1'}>Cancel</p>
            </Button>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.small}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        <>
          <LinkWrapper>
            <div className={styles.wrapLeft}>
              <div className={styles.wrapIcon}>
                <SocialMediaIcon icon={type} />
              </div>
              <span>{type}</span>
            </div>
          </LinkWrapper>

          {value ? (
            <div className={styles.rightContent}>
              <span className={'gray1'}>{getLastLinkPart(value)}</span>
              <Button className={styles.close} onClick={onDelete}>
                <Close />
              </Button>
            </div>
          ) : (
            <Button onClick={() => setEdit(true)} disabled={disabled}>
              <span className={'bold blue3 link'}>Link</span>
            </Button>
          )}
        </>
      )}
    </div>
  )
}
