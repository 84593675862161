import styles from './styles.module.scss'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as AngleLeft } from 'icons/16px/Angle-Left.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { IObjective } from 'store/dashboard/objectives/types'
import objectivesStore from 'store/dashboard/objectives/objectivesStore'
import { RoutesEnum } from 'src/routes/routes'
import { observer } from 'mobx-react-lite'
import { StepInfo } from 'src/routes/main/dashboard/Objectives/ActiveObjective/StepInfo/StepInfo'

export const HeaderActiveObject = observer(() => {
  const { step } = useParams()
  const navigate = useNavigate()
  const activeObjective: IObjective | undefined =
    objectivesStore.objectivesMap.get(Number(step))
  if (!activeObjective) {
    navigate(RoutesEnum.dashboard)
    return <></>
  }
  const { title, lessons } = activeObjective
  const completeLessons = lessons.filter((lesson) => lesson.complete)
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <Button hoverGray onClick={() => navigate(-1)} size={BtnSize.small}>
          <AngleLeft />
        </Button>
        <p className={'bold'}>{title}</p>
      </div>
      <StepInfo
        count={lessons.length}
        current={completeLessons.length}
        durationMin={lessons.reduce((acc, cur) => acc + cur.durationMin, 0)}
      />
    </div>
  )
})
