import { InputText, InputTextProps } from 'components/Input/InputText/InputText'
import { ReactComponent as Phone } from 'icons/16px/Phone.svg'

type Props = InputTextProps<string> & {
  noIcon?: boolean
}

export const InputPhone = (props: Props) => {
  return (
    <InputText
      patternFormat
      format={'(###) ###-####'}
      maskFormat={'_'}
      placeholder={'(123) 123-1234'}
      leftIcon={props.noIcon ? undefined : <Phone />}
      {...props}
    />
  )
}
