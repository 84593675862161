import { Alert } from 'components/Alert/Alert'
import styles from './styles.module.scss'
import { SizedBox } from 'components/SizedBox'
import { InputText } from 'components/Input/InputText/InputText'
import { Separator } from 'components/Separator/Separator'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import React, { ChangeEvent } from 'react'
import { AddressBlock } from 'src/routes/settings/account/compponents/AdreesBlock/AddressBlock'
import user from 'store/user/user'
import { AddNumber } from 'components/CreateElement/AddNumber'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { useSetNumbers } from 'src/routes/settings/numbers/hooks/useSetNumbers'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { MediaCardItem } from 'components/VCard/MediaCardItem/MediaCardItem'
import { SocialMediaEnums } from 'src/enums/SocialMediaEnums'
import createVCardStore from 'store/settings/createVCardStore'
import vCardStore from 'store/settings/vCardStore'
import { EnumVCardFields } from 'src/enums/EnumVCardFields'
import {
  ContactCardFragment,
  MemberRole,
  useCreateContactCardMutation,
  useUpdateContactCardMutation,
} from 'src/generated/graphql'
import { ScrollWrapper } from 'components/Wrappers/ScrollWrapper/ScrollWrapper'
import numbersStore from 'store/settings/numbers/numbersStore'

export const VCard = observer(() => {
  const isDisabled =
    user.member?.role !== MemberRole.Owner &&
    !!createVCardStore.editId &&
    createVCardStore.memberId !== user.member?.id
  useSetNumbers()
  const [createContactCard, { loading: loadingCreate }] =
    useCreateContactCardMutation()
  const [updateContactCard, { loading: loadingUpdate }] =
    useUpdateContactCardMutation()

  const onChangeInputText = (field: EnumVCardFields) => {
    return function (e: ChangeEvent<HTMLInputElement>) {
      createVCardStore.setValue(e.target.value, field)
    }
  }
  const onChangeValue = (value: string, field: EnumVCardFields) => {
    createVCardStore.setValue(value, field)
  }
  const onClear = (field: EnumVCardFields) => {
    createVCardStore.setValue('', field)
  }
  const onSave = async () => {
    try {
      let card: ContactCardFragment | null = null
      if (createVCardStore.editId) {
        const { data } = await updateContactCard({
          variables: {
            cardInput: createVCardStore.cardInput,
            contactCardId: createVCardStore.editId,
          },
        })

        if (data?.updateContactCard) {
          card = data?.updateContactCard
        }
      } else {
        const { data } = await createContactCard({
          variables: {
            cardInput: createVCardStore.cardInput,
          },
        })
        if (data?.createContactCard) {
          card = data?.createContactCard
        }
      }
      if (card) {
        vCardStore.setContactCard(card)
      }
      if (createVCardStore.callbackOnSave && card) {
        createVCardStore.callbackOnSave(card)
      }
      onCancel()
    } catch (e) {
      console.error(e)
    }
  }
  const onCancel = () => {
    createVCardStore.closeModal()
  }

  const onSetPhone = (phone: string) => {
    createVCardStore.setValue(phone, EnumVCardFields.formattedPhoneNumber)
  }

  return (
    <div className={styles.wrap}>
      <ScrollWrapper>
        <div className={styles.wrapInfo}>
          <Alert
            title={'Setup how people see your company'}
            text={'You can share your vCard via SMS.'}
            type={AlertTypeEnum.notify2}
          />
        </div>
        <div className={styles.wrapBlock}>
          <p className={'bold'}>Personal Information</p>
          <SizedBox height={24} />
          <div className={styles.wrapFields}>
            <InputText
              label={'Name'}
              placeholder={'e.g. Adam Jonson'}
              required
              value={createVCardStore.name}
              onChange={onChangeInputText(EnumVCardFields.name)}
              onClickRightIcon={() => onClear(EnumVCardFields.name)}
              disabled={isDisabled}
              focusOnMount={!createVCardStore.editId}
            />
            <InputText
              label={'Tagline'}
              placeholder={'e.g. Clients Manager'}
              value={createVCardStore.tagline}
              onChange={onChangeInputText(EnumVCardFields.tagline)}
              onClickRightIcon={() => onClear(EnumVCardFields.tagline)}
              disabled={isDisabled}
            />
            <Dropdown
              disabled={isDisabled}
              required
              selectedValue={createVCardStore.formattedPhoneNumber}
              rightBtn={<></>}
              labelInput={'Phone'}
              labelDropdown={'Select or add new number'}
              options={numbersStore.voicePhoneNumbersOptions}
              BottomDropdownButton={(props) =>
                AddNumber({ ...props, setPhone: onSetPhone })
              }
              withPhoneInput
              inputValue={
                String(
                  numbersStore.numbersMapByFriendly.get(
                    createVCardStore.formattedPhoneNumber
                  )?.friendlyName || ''
                ) || createVCardStore.formattedPhoneNumber
              }
              setInputValue={(val) => onSetPhone(String(val))}
            />
            <InputText
              label={'Website'}
              placeholder={'company.com'}
              value={createVCardStore.website}
              onChange={onChangeInputText(EnumVCardFields.website)}
              onClickRightIcon={() => onClear(EnumVCardFields.website)}
              disabled={isDisabled}
            />
            <InputText
              label={'Email'}
              placeholder={'e.g. adam.jonson@gmail.com'}
              value={createVCardStore.email}
              onChange={onChangeInputText(EnumVCardFields.email)}
              onClickRightIcon={() => onClear(EnumVCardFields.email)}
              disabled={isDisabled}
            />
          </div>
        </div>
        <Separator />
        <div className={styles.wrapBlock}>
          <AddressBlock
            address={createVCardStore.address}
            setAddress={(address) => createVCardStore.setAddress(address)}
            countriesDropdownContentWidth={221}
            disabledEdit={isDisabled}
          />
        </div>
        <Separator />
        <div className={styles.wrapMediaBlock}>
          <p className={'bold'}>Social Media</p>
          <div className={styles.mediaCard}>
            <MediaCardItem
              type={SocialMediaEnums.Facebook}
              link={createVCardStore.facebookUrl}
              setLink={(link) =>
                onChangeValue(link, EnumVCardFields.facebookUrl)
              }
              disabled={isDisabled}
            />
            <MediaCardItem
              type={SocialMediaEnums.Twitter}
              link={createVCardStore.twitterUrl}
              setLink={(link) =>
                onChangeValue(link, EnumVCardFields.twitterUrl)
              }
              disabled={isDisabled}
            />
            <MediaCardItem
              type={SocialMediaEnums.Instagram}
              link={createVCardStore.instagramUrl}
              setLink={(link) =>
                onChangeValue(link, EnumVCardFields.instagramUrl)
              }
              disabled={isDisabled}
            />
            <MediaCardItem
              type={SocialMediaEnums.Linkedin}
              link={createVCardStore.linkedinUrl}
              setLink={(link) =>
                onChangeValue(link, EnumVCardFields.linkedinUrl)
              }
              disabled={isDisabled}
            />
            <MediaCardItem
              type={SocialMediaEnums.Youtube}
              link={createVCardStore.youtubeUrl}
              setLink={(link) =>
                onChangeValue(link, EnumVCardFields.youtubeUrl)
              }
              disabled={isDisabled}
            />
          </div>
        </div>
      </ScrollWrapper>

      <div className={styles.actions}>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onSave}
          disabled={createVCardStore.disabledSave || isDisabled}
          loading={loadingCreate || loadingUpdate}
        >
          Save
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
})
