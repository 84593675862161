import { useEffect } from 'react'
import uiStore from 'store/uiStore'

function onUnload(event: BeforeUnloadEvent) {
  event.preventDefault()
  event.returnValue = 'onbeforeunload'
}

export function useBeforeUnload() {
  useEffect(() => {
    if (uiStore.isBeforeUnload) {
      window.addEventListener('beforeunload', onUnload)
    } else {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [uiStore.isBeforeUnload])
}
