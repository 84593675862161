import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { ISegment } from 'src/types/ISegment'
import segmentStore from 'store/contacts/segment/segmentStore'
import { onRenameTableCell } from 'components/NewTable/functions'

export const SegmentRenameBtn = (props: ActionBtnProps<ISegment>) => {
  const { row, tr } = props
  const { updateSegment } = segmentStore
  const onRename = () => {
    if (row) {
      onRenameTableCell({
        tr,
        row,
        onSave: (id, text) => updateSegment({ ...row, name: text }),
      })
    }
  }
  return <TableBtn {...props} action={ActionsEnum.rename} onAction={onRename} />
}
