import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import { ReactComponent as Import } from 'icons/16px/Import.svg'
import importContactStore from 'store/contacts/importContactStore'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import { useRemoveContactImportStatesMutation } from 'src/generated/graphql'

export const Filters = observer(() => {
  const [removeContactImportStates] = useRemoveContactImportStatesMutation()
  const onDelete = async () => {
    if (!importContactStore.tableStore.checkedRowsIds.length) {
      return
    }
    try {
      const res = await removeContactImportStates({
        variables: {
          importStateIds: importContactStore.tableStore.checkedRowsIds,
        },
      })
      if (res.data?.removeContactImportStates) {
        importContactStore.removeContactImportStates()
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={'row12'}>
      <Button
        size={BtnSize.medium}
        typeBtn={BtnType.primary}
        onClick={() => importContactStore.setOpenImportModal(true)}
      >
        <Import />
        Import contacts
      </Button>
      {!!importContactStore.tableStore.checkedRowsIds.length && (
        <DeleteBtn medium onClick={onDelete} />
      )}
    </div>
  )
})
