import { observer } from 'mobx-react-lite'
import { InputCollapsableCheckbox } from 'components/Input/InputCollapsableCheckbox/InputCollapsableCheckbox'
import { EditableFieldText } from 'components/CreateElement/SMSContent/EditableFieldText/EditableFieldText'
import { Separator } from 'components/Separator/Separator'
import React from 'react'
import { TextareaStore } from 'store/textareaStore'
import { appLinks } from 'src/util/links'

type IComplianceContentProps = {
  textareaStore: TextareaStore
}

export const complianceMessageTT = (
  <>
    Append compliance language <br /> to your SMS text.{' '}
    <a
      className={'s1 medium'}
      href={appLinks.callLoopCompliance}
      target={'_blank'}
      onClick={(e) => e.stopPropagation()}
      rel="noreferrer"
    >
      Learn more.
    </a>{' '}
  </>
)

export const ComplianceContent = observer(
  ({ textareaStore }: IComplianceContentProps) => {
    return (
      <>
        <InputCollapsableCheckbox
          label={'Compliance message'}
          tooltipTitle={complianceMessageTT}
          checked={textareaStore.compliance}
          onChecked={() => {
            textareaStore.setCompliance(!textareaStore.compliance)
          }}
          collapsableContent={
            <EditableFieldText
              editableText={textareaStore.nonRequiredComplianceText}
              setEditableText={textareaStore.setNoneRequiredComplianceText}
              REQUIRED_TEXT={textareaStore.REQUIRED_COMPLIANCE_TEXT}
              NOT_REQUIRED_TEXT={textareaStore.NOT_REQUIRED_COMPLIANCE_TEXT}
            />
          }
          styleProps={{
            margin: '6px -12px',
          }}
        />
        <Separator />
      </>
    )
  }
)
