import { TablePage } from 'components/Page/TablePage'
import { observer } from 'mobx-react-lite'
import { KeywordsTable } from 'src/routes/main/keywords/KeywordsTable/KeywordsTable'

export const Keywords = observer(() => {
  return (
    <TablePage>
      <h1>Keywords</h1>
      <KeywordsTable />
    </TablePage>
  )
})
