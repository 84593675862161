import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { observer } from 'mobx-react-lite'
import importContactStore from 'store/contacts/importContactStore'
import { appLinks } from 'src/util/links'

export const AgreeTermsInput = observer(
  ({ fromStep2 }: { fromStep2?: boolean }) => (
    <InputCheckbox
      label={
        <>
          I agree to the{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href={appLinks.tos}
            target={'_blank'}
            className={'link'}
            onClick={(event) => event.stopPropagation()}
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
          , which requires that all of my contacts have opted in to receive
          messages from me and I periodically maintain my lists.
        </>
      }
      checked={importContactStore.agreeTerm}
      onChecked={() =>
        importContactStore.setAgreeTerms(!importContactStore.agreeTerm)
      }
      noBordered
      maxWidth={fromStep2 ? 674 : 578}
    />
  )
)
