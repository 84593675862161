import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import { FC } from 'react'
import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import numbersStore from 'store/settings/numbers/numbersStore'
import addNumberStore from 'store/settings/numbers/addNumberStore'

interface Props extends IBottomDropdownButtonProps {
  setPhone: (phone: string) => void
}

export const AddNumber: FC<Props> = ({ onClose, setPhone }) =>
  numbersStore.isFirstNumber ? (
    <></>
  ) : (
    <Button
      size={BtnSize.small}
      menuBtn
      hoverGray
      onClick={() => {
        onClose && onClose()
        addNumberStore.setOpenAddNumberModal(
          true,
          (number: string) => {
            setPhone(number)
          },
          true
        )
      }}
    >
      <Plus />
      <span className={'bold blue1'}>
        {numbersStore.isFirstNumber ? 'Buy' : 'Add'} number
      </span>
    </Button>
  )
