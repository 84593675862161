import { StripeCardElement } from 'components/StripeCardElement/StripeCardElement'
import { useState } from 'react'
import { useAddCardMutation } from 'src/generated/graphql'
import billingStore from 'store/settings/billing/billingStore'
import { nanoid } from 'nanoid'
import alertStore from 'store/alertStore'
import { Token } from '@stripe/stripe-js'

export const NewCreditCardComponent = ({
  onCancel,
}: {
  onCancel: () => void
}) => {
  const [error, setError] = useState('')
  const [attachPaymentMethod, { loading }] = useAddCardMutation()
  const onAction = (token: Token) => {
    const tokenId = token.id
    attachPaymentMethod({
      variables: {
        tokenId,
      },
    })
      .then((res) => {
        res.data?.addCard &&
          billingStore.setOrganizationBillingInfo(res.data?.addCard)
        onCancel()
      })
      .catch((e) => {
        console.error(e)
        setError(nanoid())
        if (e instanceof Error) {
          alertStore.disabledErrorAlert(e.message)
        }
      })
  }
  return (
    <StripeCardElement
      onAction={onAction}
      isAutoFocus
      error={error}
      loadingUI={loading}
    />
  )
}
