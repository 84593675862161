import { ActionBtnProps } from 'components/NewTable/types'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import * as React from 'react'
import { api } from 'src/apiRest/api'
import { onExport } from 'src/actions/onExport'
import { IContactList, IListResponse } from 'src/types/IContactList'

export const ListExportBtn = (
  props: ActionBtnProps<IListResponse | IContactList>
) => {
  const { row } = props

  const handleExport = async () => {
    if (row?.id) {
      await onExport({
        info: `${row.name} list`,
        request: api.getReport('list-contact', row.id),
      })
    }
  }
  return (
    <>
      <TableBtn
        {...props}
        action={ActionsEnum.export}
        textBtn={'Export'}
        onAction={handleExport}
      />
    </>
  )
}
