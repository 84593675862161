import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import triggersStore from 'store/triggers/triggersStore'
import { useMemo } from 'react'
import { OneSelectContent } from 'src/routes/main/triggers/TriggersTable/Filters/OneSelectContent'
import { ManySelectContent } from 'src/routes/main/triggers/TriggersTable/Filters/ManySelectContent'
import { NoSelectContent } from 'src/routes/main/triggers/TriggersTable/Filters/NoSelectContent'

export const LeftStickyContent = observer(() => {
  const tableStore = triggersStore.tableStore
  const content = useMemo(() => {
    if (tableStore.checkedRowsIds.length) {
      if (
        tableStore.checkedRows.length &&
        tableStore.checkedRows.length === 1
      ) {
        return <OneSelectContent />
      }
      return <ManySelectContent />
    }
    return <NoSelectContent />
  }, [tableStore.checkedRows])
  return <div className={styles.wrapActions}>{content}</div>
})
