// function downloadBlob(blob: Blob, name?: string) {
//   const blobUrl = window.URL.createObjectURL(blob)
//   const a = document.createElement('a')
//   a.download = decodeURI(name || '')
//   a.href = blobUrl
//   a.target = '_blank'
//   document.body.appendChild(a)
//   a.click()
//   a.remove()
// }
//
// function getBlob(url: string, onBlob: (blob: Blob) => void) {
//   let isTryAgain = false
//
//   const tryDownload = () => {
//     fetch(`${url}${isTryAgain ? `?hash=${nanoid()}` : ''}`)
//       .then((response) => response.blob())
//       .then(onBlob)
//       .catch((e) => {
//         if (!isTryAgain) {
//           isTryAgain = true
//           tryDownload()
//         }
//
//         console.error(e)
//       })
//   }
//   tryDownload()
// }

export function downloadFile(url: string, name?: string) {
  const a = document.createElement('a')
  a.download = decodeURI(name || url.split('/').pop() || '')
  a.href = url
  document.body.appendChild(a)
  a.click()
  a.remove()
  // getBlob(url, (blob) => downloadBlob(blob, name || url.split('/').pop()))
}
