import { makeAutoObservable } from 'mobx'

export type EmptyFilterProps = {
  orIndex: number
  andIndex: number
}

export class EmptyFilter {
  orIndex: number
  andIndex: number
  field = ''
  constructor({ orIndex, andIndex }: EmptyFilterProps) {
    makeAutoObservable(this)
    this.andIndex = andIndex
    this.orIndex = orIndex
  }
}
