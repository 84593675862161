import { DetailsModalContent } from 'components/DetailsModalContent/DetailsModalContent'
import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import broadcastsStore from 'store/broadcasts/broadcastsStore'

export const BroadcastDetailModal = observer(() => {
  const onClose = () => {
    broadcastsStore.setBroadcastDetailModal(null)
  }
  return (
    <Modal
      open={broadcastsStore.openBroadcastDetailModal}
      onClose={onClose}
      title={'Details'}
      topRight
    >
      {broadcastsStore.broadcastDetailModal && (
        <DetailsModalContent element={broadcastsStore.broadcastDetailModal} />
      )}
    </Modal>
  )
})
