import { observer } from 'mobx-react-lite'
import styles from 'components/SendToDropDownWithContacts/styles.module.scss'
import { BtnSize, Button } from 'components/Button/Button'
import keywordsStore from 'store/keywords/keywordsStore'
import webFormStore from 'store/webForms/webFormStore'
import importContactStore from 'store/contacts/importContactStore'
import { CheckLimitContactsWrap } from 'src/routes/main/contacts/components/CheckLimitContactsWrap'
import manualContactStore from 'store/contacts/manualContactStore'
import { ReactComponent as AddContact } from 'icons/16px/Contacts Add.svg'
import { IOption } from 'src/types/IOption'
import { ClickAwayListener } from '@mui/material'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { useState } from 'react'

type AddContactsByProps = {
  onAddListsFromModal: (options: IOption[]) => void
  noKeyword?: boolean
  noWebForm?: boolean
}

export const AddContactsBy = observer(
  ({ onAddListsFromModal, noKeyword, noWebForm }: AddContactsByProps) => {
    const [open, setOpen] = useState(false)

    const onClose = () => {
      setOpen(false)
    }

    return (
      <ClickAwayListener onClickAway={onClose}>
        <div>
          <MuiTooltip
            padding={0}
            open={open}
            title={
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className={styles.wrapTT}
                onClick={(event) => event.stopPropagation()}
              >
                <div className={styles.label}>
                  <span className={'s2 medium gray1'}>Add contacts by</span>
                </div>
                {!noKeyword && (
                  <Button
                    menuBtn
                    hoverGray
                    onClick={() => {
                      onClose()
                      keywordsStore.setOpenKeywordModal(
                        true,
                        onAddListsFromModal
                      )
                    }}
                  >
                    Keyword
                  </Button>
                )}
                {!noWebForm && (
                  <Button
                    menuBtn
                    hoverGray
                    onClick={() => {
                      onClose()
                      webFormStore.setOpenWebFormModal(
                        true,
                        onAddListsFromModal
                      )
                    }}
                  >
                    Web form
                  </Button>
                )}
                <Button
                  menuBtn
                  hoverGray
                  onClick={() => {
                    onClose()
                    importContactStore.setOpenImportModal(true)
                  }}
                >
                  Import
                </Button>
                <CheckLimitContactsWrap>
                  <Button
                    menuBtn
                    hoverGray
                    onClick={() => {
                      onClose()
                      manualContactStore.setOpenContactModal(true)
                    }}
                  >
                    Add manually
                  </Button>
                </CheckLimitContactsWrap>
              </div>
            }
            white
            width={200}
          >
            <div>
              <Button
                size={BtnSize.small}
                hoverGray
                onClick={(event) => {
                  event.stopPropagation()
                  setOpen(true)
                }}
              >
                <AddContact />
              </Button>
            </div>
          </MuiTooltip>
        </div>
      </ClickAwayListener>
    )
  }
)
