import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { useNavigate } from 'react-router-dom'
import { FC, ReactNode } from 'react'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'

export const CompanyPage: FC<{ loading?: boolean; children?: ReactNode }> = ({
  loading,
  children,
}) => {
  const navigate = useNavigate()
  const onClose = () => {
    navigate(-1)
  }
  if (loading) {
    return <AbsoluteLoader size={40} />
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.wrapCard}>{children}</div>

      <div className={styles.wrapCloseBtn}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          className={styles.closeBtn}
          onClick={onClose}
          icon
        >
          <Close />
        </Button>
      </div>
    </div>
  )
}
