import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Tabs } from 'components/Tabs/Tabs'
import { useState } from 'react'
import { AddContactsToListDropdown } from 'src/Modals/ImportContactModal/StepsContent/Step3/AddContactsToListDropdown'
import editContactListsStore from 'src/routes/main/contacts/modals/EditListsModal/editContactListsStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  useAddToListsMutation,
  useRemoveFromListsMutation,
} from 'src/generated/graphql'
import contactStore from 'store/contacts/contactStore'

export const EditListsModal = observer(() => {
  const [addToLists] = useAddToListsMutation()
  const [removeFromLists] = useRemoveFromListsMutation()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const onClose = () => {
    editContactListsStore.setEditListsModalContactIds(null)
    editContactListsStore.dropdownContactListsStore.onSelectOptions([])
    setActiveTabIndex(0)
  }
  const onSuccess = () => {
    onClose()
    contactStore.tableStoreContacts.onRefresh()
  }
  const onAdd = async () => {
    if (!editContactListsStore.bulk) {
      return
    }
    try {
      await addToLists({
        variables: {
          bulk: editContactListsStore.bulk,
          listIds:
            editContactListsStore.dropdownContactListsStore
              .selectedContactListsIds,
        },
      })
      onSuccess()
    } catch (e) {
      console.error(e)
    }
  }
  const onRemove = async () => {
    if (!editContactListsStore.bulk) {
      return
    }
    try {
      await removeFromLists({
        variables: {
          bulk: editContactListsStore.bulk,
          listIds:
            editContactListsStore.dropdownContactListsStore
              .selectedContactListsIds,
        },
      })
      onSuccess()
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Modal
      open={editContactListsStore.openEditListsModal}
      onClose={onClose}
      title={'Edit lists'}
    >
      <div className={'mediumModal'}>
        <Tabs
          tabs={['Add to', 'Remove from']}
          activeTabIndex={activeTabIndex}
          onTabClick={setActiveTabIndex}
        />
        <div className={styles.content}>
          <AddContactsToListDropdown
            store={editContactListsStore.dropdownContactListsStore}
            noLabel
          />
        </div>
        <div className={'row12'}>
          {activeTabIndex === 0 && (
            <Button
              onClick={onAdd}
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              disabled={
                !editContactListsStore.dropdownContactListsStore
                  .selectedContactListsOptions.length
              }
            >
              Add
            </Button>
          )}
          {activeTabIndex === 1 && (
            <Button
              onClick={onRemove}
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              disabled={
                !editContactListsStore.dropdownContactListsStore
                  .selectedContactListsOptions.length
              }
            >
              Remove
            </Button>
          )}

          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
