import { SizedBox } from 'components/SizedBox'
import styles from './styles.module.scss'
import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import { PerformanceCard } from 'src/routes/main/dashboard/Performance/PerformanceCard/PerformanceCard'
import { observer } from 'mobx-react-lite'
import performanceStore from 'store/dashboard/performanceStore'
import { IOption } from 'src/types/IOption'

export enum PerformanceChartTypes {
  broadcasts = 'broadcasts',
  campaigns = 'campaigns',
  triggers = 'triggers',
  keywords = 'keywords',
  webForms = 'webForms',
}

export interface IOptionPerformanceButtons extends IOption {
  value: PerformanceChartTypes
}

const optionsButtons1: IOptionPerformanceButtons[] = [
  {
    value: PerformanceChartTypes.broadcasts,
    title: 'Broadcasts',
  },
  {
    value: PerformanceChartTypes.campaigns,
    title: 'Campaigns',
  },
  {
    value: PerformanceChartTypes.triggers,
    title: 'Triggers',
  },
]

const optionsPeriods1: IOption[] = [
  {
    value: 'latest3',
    title: 'Latest 3',
  },
  {
    value: 'top3',
    title: 'Top 3',
  },
  {
    value: 'all',
    title: 'All',
  },
]

const optionsButtons2: IOptionPerformanceButtons[] = [
  {
    value: PerformanceChartTypes.keywords,
    title: 'Keywords',
  },
  {
    value: PerformanceChartTypes.webForms,
    title: 'Web Forms',
  },
]

const optionsPeriods2: IOption[] = [
  {
    value: 'latest3',
    title: 'Latest 3',
  },
  {
    value: 'top3',
    title: 'Top 3',
  },
  {
    value: 'all',
    title: 'All',
  },
]

export const Performance = observer(() => {
  return (
    <>
      <SizedBox height={32} />
      <div className={styles.wrapPicker}>
        <RangeDatePicker
          rangeDatePickerStore={performanceStore.rangeDatePickerStore}
        />
      </div>
      <SizedBox height={24} />
      <div className={styles.cardWrap}>
        <PerformanceCard
          optionsButtons={optionsButtons1}
          optionsPeriods={optionsPeriods1}
        />
        <PerformanceCard
          optionsButtons={optionsButtons2}
          optionsPeriods={optionsPeriods2}
        />
      </div>
    </>
  )
})
