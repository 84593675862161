import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'
import { ReactComponent as StatusIcon } from 'icons/16px/Status/Status.svg'
import { AllowImportStatus } from 'src/generated/graphql'
import { allowImportStatusToText } from 'store/superAdmin/util'
import { StatusContent } from 'components/StatusContent/StatusContent'
import superAdminImportsStore from 'store/superAdmin/superAdminImportsStore'

const options: IOption[] = Object.values(AllowImportStatus).map((status) => ({
  title: (
    <StatusContent status={status} text={allowImportStatusToText[status]} />
  ),
  value: status,
}))

export const Filters = observer(() => {
  return (
    <div>
      <Dropdown
        dropDownContentWidth={196}
        maxWidth={196}
        maxHeightDropdown={250}
        options={options}
        selectedValues={superAdminImportsStore.importsStatuses}
        onMultiSelectValues={(values) =>
          superAdminImportsStore.setImportStatuses(values)
        }
        leftIcon={<StatusIcon />}
        multi
        unit={'status'}
        units={'statuses'}
      />
    </div>
  )
})
