import dayjs from 'lib/dayjs'
import { IKeywordContact } from 'src/types/IKeywordContact'
import { ColumnType } from 'components/NewTable/types'

export const columnsDetailKeyword: ColumnType<IKeywordContact>[] = [
  {
    id: 'addedTime',
    label: 'Added time',
    minWidth: 249,
    format: (value) => dayjs(value as Date).format('MMM DD, YYYY - h:mm a'),
  },
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'phone', label: 'Phone', minWidth: 170 },
  // {
  //   id: 'shortUrlClickStatistics',
  //   label: 'Clicks',
  //   minWidth: 170,
  //   format: (value, { shortUrlClickStatistics }) =>
  //     shortUrlClickStatistics ? (
  //       <Clicks shortUrlClickStatistics={shortUrlClickStatistics} />
  //     ) : (
  //       ''
  //     ),
  // },
]
