export enum EnumVCardFields {
  name = 'name',
  tagline = 'tagline',
  formattedPhoneNumber = 'formattedPhoneNumber',
  website = 'website',
  email = 'email',
  facebookUrl = 'facebookUrl',
  twitterUrl = 'twitterUrl',
  instagramUrl = 'instagramUrl',
  linkedinUrl = 'linkedinUrl',
  youtubeUrl = 'youtubeUrl',
}
