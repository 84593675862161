import { useUpdateOrganizationMutation } from 'src/generated/graphql'
import companyStore from 'store/settings/company/companyStore'
import { useHubspotRegisterCompany } from 'src/hooks/useHubspotRegisterCompany'
import { RegisterOrganization } from 'src/widgets/RegisterOrganization/RegisterOrganization'
import { BtnSize } from 'components/Button/Button'
import { SizedBox } from 'components/SizedBox'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NoCompleteRoutes } from 'src/routes/routes'
import styles from 'src/widgets/RegisterOrganization/styles.module.scss'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { IOption } from 'src/types/IOption'
import registerStore from 'store/registerStore'
import { Tag } from 'components/Tag/Tag'
import contactStore from 'store/contacts/contactStore'
import { InputCheckboxGroup } from 'components/Input/InputCheckbox/InputCheckboxGroup'
import { observer } from 'mobx-react-lite'
import { useRegisterHubspotMember } from 'src/hooks/useRegisterHubspotMember'

const options: IOption[] = [
  {
    value: '1',
    title: 'Just me',
  },
  {
    value: '2-5',
    title: '2-5',
  },
  {
    value: '6-20',
    title: '6-20',
  },
  {
    value: '21-50',
    title: '21-50',
  },
  {
    value: '51-200',
    title: '51-200',
  },
  {
    value: '200+',
    title: '200+',
  },
]

export const RegisterCompanyInfoB = observer(() => {
  const { setCompanyData, setDelivered, deliveredMap } = registerStore
  const navigate = useNavigate()
  const onContinue = async (companyName: string, employees: string | null) => {
    try {
      setCompanyData({
        companyName,
        employees,
      })
      navigate(NoCompleteRoutes.register2)
    } catch (e) {
      console.error(e)
    }
  }

  useRegisterHubspotMember()

  return (
    <RegisterOrganization
      progress={50}
      btnPrimarySize={BtnSize.large}
      btnPrimaryText={'Continue'}
      onContinue={onContinue}
      initCompanyName={companyStore.companyName}
      additionalContent={
        <>
          <SizedBox height={32} />

          <div className={styles.deliverText}>
            How you’ll deliver your messages?
          </div>
          <SizedBox height={8} />
          <InputCheckboxGroup variant2>
            {Array.from(deliveredMap).map(([label, value]) => (
              <InputCheckbox
                key={label}
                label={label}
                checked={value}
                onChecked={() => setDelivered(label)}
              />
            ))}
          </InputCheckboxGroup>
          {/*<div className={styles.deliverWrap}>*/}
          {/*  {options.map((opt) => (*/}
          {/*    <InputCheckbox label={} checked={} onChecked={}/>*/}
          {/*  ))}*/}
          {/*</div>*/}
        </>
      }
      // bottomContent={
      //   <>
      //     <SizedBox height={16} />
      //     <FooterRegister
      //       text={
      //         'Cancel anytime. You will not be charged until your trial ends.'
      //       }
      //     />
      //   </>
      // }
    />
  )
})
