import { IStatusProps } from 'components/Status'
import { InvoiceStatus } from 'src/generated/graphql'

const statuses: { [key in InvoiceStatus]: IStatusProps } = {
  Draft: {
    title: 'Draft',
    icon: 'draft',
    color: 'PrimaryColorGray1',
  },
  Open: {
    title: 'Open',
    icon: 'clock',
    color: 'StatesColorNotify1',
  },
  Paid: {
    title: 'Paid',
    icon: 'checkCircle',
    color: 'StatesColorSuccess1',
  },
  Refunded: {
    title: 'Refunded',
    icon: 'backCircle',
    color: 'PrimaryColorGray1',
  },
  UnCollectible: {
    title: 'Uncollectible',
    icon: 'minusCircle',
    color: 'SecondaryColorOrange1',
  },
  Undefined: {
    title: 'Undefined',
    icon: 'minusCircle',
    color: 'PrimaryColorGray1',
  },
  Void: {
    title: 'Void',
    icon: 'closeCircle',
    color: 'StatesColorError1',
  },
}

export type IStatusesKeys = keyof typeof statuses

export default statuses
