// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AXheL2ciXYKSU2PtN4EQ{display:flex;column-gap:4px;align-items:center}.S0pSt4c6iNkBkcieqew0{width:24px;height:24px;background-color:var(--primary-color-gray-3);display:flex;align-items:center;justify-content:center;margin-right:4px;border-radius:8px}.S0pSt4c6iNkBkcieqew0.CAViuqmLF3rf3_jRjWng{background-color:var(--primary-color-blue-1)}`, "",{"version":3,"sources":["webpack://./src/routes/main/campaigns/detail/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,kBAAA,CAEF,sBACE,UAAA,CACA,WAAA,CACA,4CAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,iBAAA,CACA,2CACE,4CAAA","sourcesContent":[".tabItem{\n  display: flex;\n  column-gap: 4px;\n  align-items: center;\n}\n.counter{\n  width: 24px;\n  height: 24px;\n  background-color: var(--primary-color-gray-3);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 4px;\n  border-radius: 8px;\n  &.active{\n    background-color: var(--primary-color-blue-1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabItem": `AXheL2ciXYKSU2PtN4EQ`,
	"counter": `S0pSt4c6iNkBkcieqew0`,
	"active": `CAViuqmLF3rf3_jRjWng`
};
export default ___CSS_LOADER_EXPORT___;
