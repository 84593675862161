import styles from './styles.module.scss'
import { ReactComponent as Plus } from 'icons/12px/Plus.svg'
import { ReactComponent as Minus } from 'icons/12px/Minus.svg'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { FC } from 'react'

type Props = {
  count: string | number
  onMinus: () => void
  onPlus: () => void
  onChange?: (value: string | number) => void
}

export const Counter: FC<Props> = ({ count, onMinus, onPlus, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '')
    if (value.length > 3) return
    onChange && onChange(value)
  }
  const onBlur = () => {
    if (!count) {
      onChange && onChange(1)
    }
  }
  return (
    <div className={styles.wrap}>
      <Button
        size={BtnSize.mini}
        typeBtn={BtnType.secondaryGray}
        onClick={onMinus}
        disabled={+count <= 1}
      >
        <Minus />
      </Button>
      {onChange ? (
        <input
          type="text"
          value={count || ''}
          onChange={handleChange}
          onBlur={onBlur}
        />
      ) : (
        <span>{count}</span>
      )}

      <Button
        size={BtnSize.mini}
        typeBtn={BtnType.secondaryGray}
        onClick={onPlus}
      >
        <Plus />
      </Button>
    </div>
  )
}
