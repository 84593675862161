// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ou67O_ofslOunuuH4H52 h2,.Ou67O_ofslOunuuH4H52 p{text-align:center}.Ou67O_ofslOunuuH4H52 h2{margin-bottom:12px}.Ou67O_ofslOunuuH4H52 button{margin-top:32px}.XyixnbpIWbwWjAmaPYDA{padding:8px;display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/routes/canceled/cancel/styles.module.scss"],"names":[],"mappings":"AACE,iDACE,iBAAA,CAEF,yBACE,kBAAA,CAEF,6BACE,eAAA,CAGJ,sBACE,WAAA,CACA,YAAA,CACA,sBAAA","sourcesContent":[".wrap{\n  h2, p{\n    text-align: center;\n  }\n  h2{\n    margin-bottom: 12px;\n  }\n  button{\n    margin-top: 32px;\n  }\n}\n.wrapInfo{\n  padding: 8px;\n  display: flex;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Ou67O_ofslOunuuH4H52`,
	"wrapInfo": `XyixnbpIWbwWjAmaPYDA`
};
export default ___CSS_LOADER_EXPORT___;
