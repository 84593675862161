import { ActionsEnum } from 'components/Button/types'
import { SmallBtn } from 'components/Button/SmallBtn'
import * as React from 'react'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionBtnProps } from 'components/NewTable/types'
import { IPhoneNumber } from 'src/types/IPhoneNumber'
import numbersStore from 'store/settings/numbers/numbersStore'
import { useUpdatePhoneNumbersMutation } from 'src/generated/graphql'
import billingStore from 'store/settings/billing/billingStore'
import companyStore from 'store/settings/company/companyStore'

export const ForwardCallsAction = ({
  menu,
  medium,
  row,
  rows,
}: ActionBtnProps<IPhoneNumber>) => {
  const [updatePhoneNumbers, { loading }] = useUpdatePhoneNumbersMutation()
  const isDisabled = row
    ? row.callForwardEnabled
    : rows?.find((row) => row.callForwardEnabled)

  const text = isDisabled ? 'Disable call forwarding' : 'Forward calls'
  const type = isDisabled ? ActionsEnum.disabled : ActionsEnum.forward

  const onForward = async () => {
    const onTurnOn = async () => {
      if (isDisabled) {
        try {
          if (row) {
            const { data } = await updatePhoneNumbers({
              variables: {
                inputUpdatePhoneNumber: {
                  ids: [row.id],
                  callForwardEnabled: false,
                },
              },
            })
            data && numbersStore.onCallForward(data)
          }
          if (rows) {
            const { data } = await updatePhoneNumbers({
              variables: {
                inputUpdatePhoneNumber: {
                  ids: rows.map((row) => row.id),
                  callForwardEnabled: false,
                },
              },
            })
            data && numbersStore.onCallForward(data)
          }
        } catch (e) {
          console.error(e)
        }
        return
      }
      if (row) {
        numbersStore.setOpenCallForwardingModal([row])
      }
      if (rows) {
        numbersStore.setOpenCallForwardingModal(rows)
      }
    }

    if (!isDisabled && !billingStore.autoRecharge) {
      companyStore.setTurnOnAutoRechargeModal('callForwarding', onTurnOn)
      return
    }

    await onTurnOn()
  }

  if (menu || medium) {
    return (
      <MediumBtn
        type={type}
        onClick={onForward}
        text={text}
        menu={menu}
        disabled={loading}
      />
    )
  }
  return (
    <SmallBtn
      type={type}
      onClick={onForward}
      disabled={loading}
      // iconType={IIconType.stroke}
      tooltip={text}
      // iconColor={ColorsNames.success1}
    />
  )
}
