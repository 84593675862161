import { Outlet } from 'react-router-dom'
import { createContext, useContext, useState } from 'react'
import { CreateSegmentStore } from 'store/contacts/segment/createSegmentStore'
import styles from './styles.module.scss'

const SegmentDetailContext = createContext<CreateSegmentStore>(
  new CreateSegmentStore()
)

export function useSegmentDetailContext() {
  return useContext(SegmentDetailContext)
}

export const SegmentDetailLayout = () => {
  const [store] = useState(() => new CreateSegmentStore())
  return (
    <SegmentDetailContext.Provider value={store}>
      <div className={styles.wrap}>
        <Outlet />
      </div>
    </SegmentDetailContext.Provider>
  )
}
