import { FC, useEffect, useRef, useState } from 'react'
import DatePickerLib, { ReactDatePicker } from 'react-datepicker'
import { nanoid } from 'nanoid'
import user from 'store/user/user'
import { useTimeZoneDate } from 'lib/dayjs'

type Props = {
  startTime: Date | null | undefined
  onChange: (val: Date | null) => void
}

export const TimePicker: FC<Props> = ({ startTime, onChange }) => {
  const { startDateTz, handleChange } = useTimeZoneDate({
    startDate: startTime,
    onChange,
  })
  const [trigger, setTrigger] = useState('')
  const ref = useRef<ReactDatePicker | null>(null)
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const value = ref.current?.state?.inputValue
      const list = document.querySelector('.react-datepicker__time-list')
      if (list) {
        Array.from(list.children)
          .find((el) => el.innerHTML.includes(value))
          ?.scrollIntoView()
      }
    })
  }, [trigger])

  return (
    <div className={'timePicker'}>
      <div className={'zoneAbbreviationWrap'}>
        <div className={'zoneAbbreviation'}>
          {user.timezone?.zoneAbbreviation}
        </div>
      </div>

      <div>
        <DatePickerLib
          placeholderText={'Select time'}
          selected={startDateTz}
          onChange={handleChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          onKeyDown={() => {
            setTrigger(nanoid())
          }}
          ref={ref}
        />
      </div>
    </div>
  )
}
