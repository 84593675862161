import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import numbersStore from 'store/settings/numbers/numbersStore'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { SizedBox } from 'components/SizedBox'
import { InputText } from 'components/Input/InputText/InputText'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useUpdatePhoneNumbersMutation } from 'src/generated/graphql'

export const CallForwardingModal = observer(() => {
  const [updatePhoneNumbers, { loading }] = useUpdatePhoneNumbersMutation()
  const [destinationPhoneNumber, setDestinationPhoneNumber] = useState('')
  const [destinationExtension, setDestinationExtension] = useState('')

  useEffect(() => {
    setDestinationPhoneNumber('')
    setDestinationExtension('')
  }, [numbersStore.openCallForwardingModal])
  const onSave = async () => {
    try {
      const { data } = await updatePhoneNumbers({
        variables: {
          inputUpdatePhoneNumber: {
            ids: numbersStore.openCallForwardingPhoneNumbers.map(
              (row) => row.id
            ),
            callForwardEnabled: true,
            destinationPhoneNumber,
            destinationExtension:
              destinationExtension === '' ? undefined : +destinationExtension,
          },
        },
      })
      data && numbersStore.onCallForward(data)
    } catch (e) {
      console.error(e)
    }
  }
  const onClose = () => {
    numbersStore.setOpenCallForwardingModal([])
  }
  return (
    <Modal
      open={numbersStore.openCallForwardingModal}
      onClose={onClose}
      title={'Call forwarding'}
    >
      <div className={'mediumModal'}>
        <Alert
          type={AlertTypeEnum.notify2}
          text={`When enabled, all calls to ${
            numbersStore.openCallForwardingPhoneNumbers.length === 1
              ? numbersStore.openCallForwardingPhoneNumbers[0].friendlyName
              : `${numbersStore.openCallForwardingPhoneNumbers.length} numbers`
          } will be forwarded to the number provided`}
        />
        <SizedBox height={32} />
        <div className={styles.wrapNumber}>
          <InputPhone
            label={'Destination number'}
            noIcon
            value={destinationPhoneNumber}
            onChange={(e) => setDestinationPhoneNumber(e.target.value)}
          />
          <InputText
            label={'Extension'}
            withoutClear
            patternFormat
            placeholder={'123'}
            format={'###'}
            value={destinationExtension}
            onChange={(e) => setDestinationExtension(e.target.value)}
          />
        </div>
        <div className={'mt40 row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onSave}
            loading={loading}
            disabled={
              !destinationPhoneNumber || destinationPhoneNumber.includes('_')
            }
          >
            Save
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
