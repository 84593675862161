// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wPlCTV71SQ9tbNpl7ezI{position:sticky;overflow:auto;left:0}@media(max-width: 800px){.wPlCTV71SQ9tbNpl7ezI{padding-inline:24px}}@media(max-height: 600px){.wPlCTV71SQ9tbNpl7ezI{padding-inline:24px}}`, "",{"version":3,"sources":["webpack://./src/components/NewTable/components/AdditionalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,aAAA,CACA,MAAA,CACA,yBAJF,sBAKI,mBAAA,CAAA,CAEF,0BAPF,sBAQI,mBAAA,CAAA","sourcesContent":[".additionalContent{\n  position: sticky;\n  overflow: auto;\n  left: 0;\n  @media (max-width: 800px) {\n    padding-inline: 24px;\n  }\n  @media (max-height: 600px) {\n    padding-inline: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalContent": `wPlCTV71SQ9tbNpl7ezI`
};
export default ___CSS_LOADER_EXPORT___;
