import { observer } from 'mobx-react-lite'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { PropsBusinessStep } from 'src/routes/settings/compliance/BusinessProfile/types'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  BusinessProfileResponse,
  useAdminInitBusinessTermsMutation,
  useInitBusinessTermsMutation,
} from 'src/generated/graphql'

export const BusinessTerms = observer(
  ({ onCancel, loadingPrevious, onSuccess }: PropsBusinessStep) => {
    const { adminOrgId } = businessProfileStore
    const [initBusinessTerms, { loading }] = useInitBusinessTermsMutation()
    const [adminInitBusinessTerms, { loading: loadingAdmin }] =
      useAdminInitBusinessTermsMutation()
    const onContinue = async () => {
      try {
        let profile: BusinessProfileResponse | undefined | null
        const finish = businessProfileStore.continueText !== 'Continue'

        if (adminOrgId) {
          const { data } = await adminInitBusinessTerms({
            variables: {
              organizationId: adminOrgId,
            },
          })
          profile = data?.adminInitBusinessTerms
        } else {
          const { data } = await initBusinessTerms()
          profile = data?.initBusinessTerms
        }

        if (profile) {
          onSuccess(profile, finish)
        }
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <>
        <InputCheckbox
          marginLeft={-8}
          width={'calc(100% + 16px)'}
          noBordered
          label={
            'I declare that the information provided is accurate. I acknowledge that Twilio will be processing the infomation provided for the purposes of identity verification and that Twillio reserves the right to retain the Business profile information after account closure in the case of the traceback from a regulatory authority or equivalent'
          }
          checked={businessProfileStore.declareInformation}
          onChecked={() =>
            businessProfileStore.setDeclareInformation(
              !businessProfileStore.declareInformation
            )
          }
        />
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            disabled={businessProfileStore.disabledBusinessTerms}
            loading={loading || loadingAdmin}
          >
            {businessProfileStore.continueText}
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onCancel}
            loading={loadingPrevious}
          >
            {businessProfileStore.step === 1 ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </>
    )
  }
)
