// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValKY12zA9RBBro6PCd9{margin-top:24px;display:grid;grid-template-columns:1fr 1fr;column-gap:16px;height:376px}.ValKY12zA9RBBro6PCd9 .OkzKiYSmQL39MwAK6c8E{position:relative;border:1px solid var(--primary-color-gray-3);border-radius:12px}.ValKY12zA9RBBro6PCd9 .OkzKiYSmQL39MwAK6c8E img{margin-top:26px;margin-left:20px}.ValKY12zA9RBBro6PCd9 .OkzKiYSmQL39MwAK6c8E h3{position:absolute;top:176px;padding-top:47px;background-color:var(--primary-color-white);width:100%;text-align:center}.vJYk1i8h8BfELyzfI5QC{background-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/Modals/ImportContactModal/StepsContent/Step1/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA,CACA,YAAA,CACA,4CACE,iBAAA,CACA,4CAAA,CACA,kBAAA,CACA,gDACE,eAAA,CACA,gBAAA,CAEF,+CACE,iBAAA,CACA,SAAA,CACA,gBAAA,CACA,2CAAA,CACA,UAAA,CACA,iBAAA,CAKN,sBACE,8BAAA","sourcesContent":[".content{\n  margin-top: 24px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 16px;\n  height: 376px;\n  .templateContent{\n    position: relative;\n    border: 1px solid var(--primary-color-gray-3);\n    border-radius: 12px;\n    img{\n      margin-top: 26px;\n      margin-left: 20px;\n    }\n    h3{\n      position: absolute;\n      top: 176px;\n      padding-top: 47px;\n      background-color: var(--primary-color-white);\n      width: 100%;\n      text-align: center;\n    }\n  }\n\n}\n.upgrade{\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `ValKY12zA9RBBro6PCd9`,
	"templateContent": `OkzKiYSmQL39MwAK6c8E`,
	"upgrade": `vJYk1i8h8BfELyzfI5QC`
};
export default ___CSS_LOADER_EXPORT___;
