import { action, computed, makeObservable, observable } from 'mobx'
import { FilterInput } from 'src/generated/graphql'
import {
  CommonFilter,
  CommonFilterProps,
} from 'store/contacts/segment/filters/CommonFilter'
import { IOption } from 'src/types/IOption'
import segmentStore from 'store/contacts/segment/segmentStore'

type Props = CommonFilterProps

export class OptInFilter extends CommonFilter {
  constructor(props: Props) {
    super(props)
    makeObservable(this, {
      value: observable,
      keywordsIds: observable,
      webFormsIds: observable,

      setValue: action.bound,
      setKeywordsIds: action.bound,
      setWebFormIds: action.bound,
      onDeleteKeywordsId: action.bound,
      onDeleteWebFormId: action.bound,

      filterInput: computed,
      options: computed,
      valueExt: computed,
      isKeyword: computed,
      isWebForm: computed,
      isNotEmpty: computed,
    })

    if (props.value) {
      this.value = props.value
    }

    if (this.isKeyword) {
      if (props.valueExt) {
        this.setKeywordsIds(props.valueExt?.split(',').map(Number))
      }
    }
    if (this.isWebForm) {
      if (props.valueExt) {
        this.setWebFormIds(props.valueExt?.split(',').map(Number))
      }
    }
  }

  keywordsIds: number[] = []
  webFormsIds: number[] = []
  value = ''

  get isNotEmpty() {
    if (this.isKeyword) {
      return this.keywordsIds.length
    }
    if (this.isWebForm) {
      return this.webFormsIds.length
    }
    return this.value.trim()
  }

  get options(): IOption[] {
    const options: IOption[] = []

    segmentStore.fieldsMap.get(this.field)?.options?.forEach((opt) => {
      if (opt) {
        options.push({ title: opt?.label, value: opt?.key })
      }
    })
    return options
  }

  get isKeyword() {
    return false
    // return this.value === 'AddedByKeyword'
  }
  get isWebForm() {
    return false
    // return this.value === 'AddedByWebForm'
  }

  get valueExt() {
    if (this.isKeyword) {
      return this.keywordsIds.join(',')
    }
    if (this.isWebForm) {
      return this.webFormsIds.join(',')
    }
    return undefined
  }

  get filterInput(): FilterInput {
    return {
      orIndex: this.orIndex,
      andIndex: this.andIndex,
      dataType: this.dataType,
      condition: this.condition,
      field: this.field,
      value: this.value,
      valueExt: this.valueExt,
      // type: FilterFieldType.Contact,
    }
  }

  setValue = (value: typeof this.value) => {
    this.value = value
  }

  setKeywordsIds = (values: typeof this.keywordsIds) => {
    this.keywordsIds = values
  }
  setWebFormIds = (values: typeof this.webFormsIds) => {
    this.webFormsIds = values
  }
  onDeleteKeywordsId = (id: number) => {
    this.keywordsIds = this.keywordsIds.filter((idList) => idList !== id)
  }
  onDeleteWebFormId = (id: number) => {
    this.webFormsIds = this.keywordsIds.filter((idList) => idList !== id)
  }
}
