import styles from './styles.module.scss'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Phone } from 'icons/16pxNoMask/Phone.svg'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { ReactComponent as ContactsFill } from 'icons/16pxNoMask/ContactsFill.svg'
import { useKeywordFunctions } from 'src/routes/main/keywords/hooks/useKeywordFunctions'
import { DetailRow } from 'components/DetailsModalContent/DetailRow'
import { IKeyword } from 'src/types/IKeyword'
import { DetailTypeContent } from 'components/DetailsModalContent/DetailTypeContent'
import { ItemsRender } from 'components/NewTable/cell/ItemsRender/ItemsRender'

export const DetailKeywordContent = ({ keyword }: { keyword: IKeyword }) => {
  const { textTooltipTest } = useKeywordFunctions({ keyword })
  return (
    <>
      <div className={styles.row}>
        <h3>{keyword.name}</h3>
        <TestItBtn textTooltip={textTooltipTest} withoutAction />
      </div>
      <div className={styles.grid}>
        <DetailRow
          startLabel
          leftContent={
            <>
              <Phone />
              <span className={ColorsNames.gray1}>Phone number</span>
            </>
          }
          rightContent={
            <div className={'col4'}>
              {keyword.keywordPhoneNumbers?.map((phone) => (
                <span key={phone?.friendlyName}>{phone?.friendlyName}</span>
              ))}
            </div>
          }
        />
        <DetailRow
          leftContent={
            <>
              <ContactsFill />
              <span className={ColorsNames.gray1}>List</span>
            </>
          }
          rightContent={<ItemsRender items={keyword.items} />}
        />
      </div>
      <DetailTypeContent smsMessage={keyword.smsMessage} />
    </>
  )
}
