import { InputText } from 'components/Input/InputText/InputText'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import { observer } from 'mobx-react-lite'
import { BusinessAuthorizedUser } from 'src/generated/graphql'

export const AuthorizedUserBlock = observer(
  ({
    user,
    setUser,
    number,
    onCheckEmail,
    errorEmail,
    errorPhone,
    clearError,
  }: {
    user: BusinessAuthorizedUser
    setUser: (user: BusinessAuthorizedUser) => void
    number: 1 | 2
    onCheckEmail: () => void
    errorEmail?: string
    errorPhone?: string
    clearError: (key: string) => void
  }) => {
    return (
      <div className={'col24'}>
        <p>
          <b>Authorized Representative #{number}</b>
        </p>
        <div className={'row12'}>
          <InputText
            label={'First Name'}
            placeholder={'e.g. Adam'}
            value={user.firstName || ''}
            onChangeValue={(value) =>
              setUser({
                ...user,
                firstName: value,
              })
            }
          />
          <InputText
            label={'Last Name'}
            placeholder={'e.g. Johnson'}
            value={user.lastName || ''}
            onChangeValue={(value) =>
              setUser({
                ...user,
                lastName: value,
              })
            }
          />
        </div>
        <div className={'row12'}>
          <InputText
            label={'Business title'}
            placeholder={'e.g. Founder'}
            value={user.title || ''}
            onChangeValue={(value) =>
              setUser({
                ...user,
                title: value,
              })
            }
          />
          <Dropdown
            labelInput={'Job position'}
            fullWidth
            placeholder={'e.g. CEO'}
            selectedValue={user.jobPosition}
            onSelectValue={(value) =>
              setUser({
                ...user,
                jobPosition: value,
              })
            }
            options={businessProfileStore.businessJobPositionOptions}
          />
        </div>
        <InputText
          error={errorEmail}
          onFocus={() => clearError(`user${number}email`)}
          onBlur={onCheckEmail}
          label={'Business email'}
          placeholder={'e.g. adam.johnson@example.com'}
          value={user.email || ''}
          onChangeValue={(value) =>
            setUser({
              ...user,
              email: value,
            })
          }
        />
        <InputPhone
          error={errorPhone}
          onFocus={() => clearError(`user${number}phoneNumber`)}
          label={'Phone number'}
          noIcon
          value={user.phoneNumber || ''}
          onChangeValue={(value) =>
            setUser({
              ...user,
              phoneNumber: value,
            })
          }
        />
      </div>
    )
  }
)
