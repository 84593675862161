import { BtnSize, Button } from 'components/Button/Button'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import classNames from 'classnames'
import { FC } from 'react'
import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import numbersStore from 'store/settings/numbers/numbersStore'
import { checkLimitsNumbers } from 'store/settings/numbers/functions'

interface Props extends IBottomDropdownButtonProps {
  setPhone: (phone: string) => void
}

export const VerifyNumber: FC<Props> = ({ onClose }) => {
  const onAddCallerId = () => {
    onClose && onClose()
    const isAllow = checkLimitsNumbers()
    if (!isAllow) {
      return
    }
    numbersStore.setOpenVerifyCallerIdModal(true)
  }

  return (
    <Button size={BtnSize.small} menuBtn hoverGray onClick={onAddCallerId}>
      <AppIcon color={ColorsNames.blue1}>
        <Plus />
      </AppIcon>

      <span className={classNames('bold', 'blue1')}>Add caller ID</span>
    </Button>
  )
}
