// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tzy3fgAswyPRl8s_B1tf{display:grid}.yVyxeflmE_uGe6c5N50T{overflow:auto}@media(max-width: 800px){.yVyxeflmE_uGe6c5N50T{padding-left:24px}}@media(max-height: 600px){.yVyxeflmE_uGe6c5N50T{padding-left:24px}}`, "",{"version":3,"sources":["webpack://./src/routes/superAdmin/routes/organization/info/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAIF,sBACE,aAAA,CACA,yBAFF,sBAGI,iBAAA,CAAA,CAEF,0BALF,sBAMI,iBAAA,CAAA","sourcesContent":[".rows{\n  display: grid;\n}\n\n\n.wrapBlock{\n  overflow: auto;\n  @media (max-width: 800px) {\n    padding-left: 24px;\n  }\n  @media (max-height: 600px) {\n    padding-left: 24px;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rows": `tzy3fgAswyPRl8s_B1tf`,
	"wrapBlock": `yVyxeflmE_uGe6c5N50T`
};
export default ___CSS_LOADER_EXPORT___;
