import {
  TenDlcBusinessType,
  TenDlcPackageType,
  TollFreeVerificationOptInMethod,
} from 'src/generated/graphql'
import { appLinks } from 'src/util/links'

export const packageTypeMap = {
  [TenDlcPackageType.SoleProprietor]: 'Sole proprietor',
  [TenDlcPackageType.LowVolumeStandard]: 'Low volume standard',
  [TenDlcPackageType.Standard]: 'Standard',
}

export const businessTypeMap = {
  [TenDlcBusinessType.Private]: 'Private',
  [TenDlcBusinessType.Public]: 'Public',
}

export const optInLinks: { [key in TollFreeVerificationOptInMethod]: string } =
  {
    [TollFreeVerificationOptInMethod.IncomingText]:
      appLinks.smsOptInGuideViaTextKeywordsQrCodes,
    [TollFreeVerificationOptInMethod.OnlineForm]:
      appLinks.smsOptInGuideWebForms,
    [TollFreeVerificationOptInMethod.PaperForm]:
      appLinks.smsOptInGuidePaperForms,
    [TollFreeVerificationOptInMethod.QrCode]:
      appLinks.smsOptInGuideViaTextKeywordsQrCodes,
  }
