import styles from './styles.module.scss'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { SizedBox } from 'components/SizedBox'
import { RangeInput, RangeInputProps } from 'components/RangeInput/RangeInput'
import { ReactNode, useState } from 'react'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'

type RangePlanCardProps = {
  period: PlanPeriodsEnum
  rightBtn?: ReactNode
  rangeInputProps: RangeInputProps
}

export const RangePlanCard = ({
  period,
  rightBtn,
  rangeInputProps,
}: RangePlanCardProps) => {
  const [hideSteps, setHideSteps] = useState(true)
  const periodString = period === PlanPeriodsEnum.Monthly ? 'month' : 'year'
  return (
    <div
      className={styles.wrapRangeInput}
      onMouseEnter={() => setHideSteps(false)}
      onMouseLeave={() => setHideSteps(true)}
    >
      <div className={styles.row}>
        <div className={styles.row}>
          <span className={'medium'}>
            How many messages will you send per {periodString}?
          </span>
          <InfoTooltip
            title={
              <div className={'col4'}>
                <span className={'white'}>
                  Use credits for <br /> sending messages
                </span>
                <span className={'s2 gray2'}>
                  SMS - 1 Credit / segment <br />
                  MMS - 2 Credits
                </span>
              </div>
            }
            placement={'right'}
          />
        </div>

        {rightBtn}
      </div>

      <SizedBox height={12} />
      <RangeInput {...rangeInputProps} min={0} step={1} hideSteps={hideSteps} />
    </div>
  )
}
