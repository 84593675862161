import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Email } from 'icons/16px/Email Invite.svg'
import { SizedBox } from 'components/SizedBox'
import { useEffect } from 'react'
import user from 'store/user/user'
import {
  MemberResponseFragment,
  MemberRole,
  useGetAllMembersQuery,
} from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import teamStore from 'store/settings/teamStore'
import { acceptedCreate } from 'src/routes/settings/billing/functions/acceptedCreate'
import { MemberModal } from 'src/routes/settings/company/routes/CompanyTeam/modals/MemberModal/MemberModal'
import { InviteSentModal } from 'src/routes/settings/company/routes/CompanyTeam/modals/InviteSentModal/InviteSentModal'
import { MemberCard } from 'src/routes/settings/company/routes/CompanyTeam/MemberCard/MemberCard'

export const CompanyTeam = observer(() => {
  const { data, refetch } = useGetAllMembersQuery({
    variables: {
      page: {
        pageSize: 100,
        pageNumber: 0,
        sort: {
          orders: [
            {
              property: 'createdAt',
            },
          ],
        },
      },
    },
  })

  useEffect(() => {
    const members: MemberResponseFragment[] = []
    data?.getAllMembers?.content?.forEach((member) => {
      if (member) {
        members.push(member)
      }
    })
    teamStore.setMembers(members)
  }, [data])

  useEffect(() => {
    refetch().catch(console.error)
  }, [teamStore.refreshTrigger])

  const onEdit = (member: MemberResponseFragment) => {
    teamStore.setOpenMemberModal(true, member.id)
  }

  const onInvite = () => {
    acceptedCreate({
      limitFor: 'users',
      acceptedCB: () => teamStore.setOpenMemberModal(true),
    })
  }

  return (
    <>
      <MemberModal />
      <InviteSentModal />
      {user.role !== MemberRole.User && (
        <>
          <Button
            typeBtn={BtnType.primary}
            withIcon
            size={BtnSize.medium}
            onClick={onInvite}
          >
            <Email />
            Invite team member
          </Button>
          <SizedBox height={40} />
        </>
      )}

      <span className={'s2 medium gray1'}>Team members</span>
      <SizedBox height={8} />
      <div>
        {teamStore.members.map(
          (member) =>
            member && (
              <MemberCard
                key={member.id}
                {...member}
                onEdit={() => onEdit(member)}
              />
            )
        )}
      </div>
    </>
  )
})
