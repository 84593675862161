import modalStore from 'src/widgets/ModalContainer/store/modalStore'
import { ConfigureAutoRecharge } from 'src/widgets/ConfigureAutoRecharge/ConfigureAutoRecharge'

export const openConfigureAutoRechargeModal = () => {
  const id = 'Enable Auto-Recharge'
  const onClose = () => {
    modalStore.closeModal(id)
  }

  modalStore.addModal({
    id,
    minWidth: 600,
    title: 'Configure Auto-Recharge',
    children: <ConfigureAutoRecharge onClose={onClose} />,
  })
}
