import {
  AutoRechargeThreshold,
  Maybe,
  SimplePrice,
} from 'src/generated/graphql'
import { compactFormatter } from 'src/util/functions'
import { IOption } from 'src/types/IOption'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'

export const payGoLite = 'Pay & Go (Lite)'

export const upgradeModalSteps = ['Choose Plan', 'Payment']
export const downgradeModalSteps = ['Choose Plan', 'Payment', 'Confirm']

export const PlanPeriods: IOption<PlanPeriodsEnum>[] = [
  {
    title: 'Monthly',
    value: PlanPeriodsEnum.Monthly,
  },
  {
    title: 'Annually',
    value: PlanPeriodsEnum.Annual,
    discount: 'Get Two Months Free!',
  },
]

export const BalanceFallsOptions: IOption<AutoRechargeThreshold>[] = [
  {
    title: '100 credits',
    value: AutoRechargeThreshold.Credits100,
  },
  {
    title: '250 credits',
    value: AutoRechargeThreshold.Credits250,
  },
  {
    title: '500 credits',
    value: AutoRechargeThreshold.Credits500,
  },
  {
    title: '750 credits',
    value: AutoRechargeThreshold.Credits750,
  },
  {
    title: '1000 credits',
    value: AutoRechargeThreshold.Credits1000,
  },
]

export const BalanceFallsOptionsMap = new Map(
  BalanceFallsOptions.map((opt) => [opt.value, opt])
)

export enum SubscriptionsTitles {
  custom = 'Custom',
}

export const simplePriceToTitle = (price?: Maybe<SimplePrice>) => {
  if (!price) {
    return ''
  }
  if (price.planName === 'PayAsYouGo') {
    return price.planTitle
  }
  return price.planLine === 'Pro'
    ? `${price?.planLine} ${price?.period} ${compactFormatter.format(
        price?.creditsIncluded || 0
      )}`
    : price.planLine
}
