import { useEffect } from 'react'
import billingStore from 'store/settings/billing/billingStore'
import alertStore from 'store/alertStore'
import { useStopCancelSubscriptionMutation } from 'src/generated/graphql'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export function useIsCancelSubscription() {
  const [stopCancelSubscription] = useStopCancelSubscriptionMutation()
  const handleCancelDowngrade = async () => {
    try {
      const { data } = await stopCancelSubscription()
      if (data?.stopCancelSubscription) {
        billingStore.setClSubscription(data?.stopCancelSubscription)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    if (billingStore.clSubscription?.cancelAt) {
      alertStore.setCancelSubscriptionAlert({
        type: AlertTypeEnum.warning1,
        title: `Your subscription will be canceled on ${billingStore.cancelAtString}. To not lose access and your data, resubscribe back. `,
        rightContent: (
          <button onClick={handleCancelDowngrade} className={'pointer'}>
            <span className={'bold'}>Resubscribe</span>
          </button>
        ),
      })
    } else {
      alertStore.cancelSubscriptionAlert &&
        alertStore.setCancelSubscriptionAlert(null)
    }
  }, [billingStore.clSubscription?.cancelAt])
}
