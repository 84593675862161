import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Link } from 'icons/16px/Link.svg'
import { ReactComponent as Duplicate } from 'icons/16px/Duplicate.svg'
import { ReactComponent as Archive } from 'icons/16px/Archive.svg'
import { ReactComponent as Unarchive } from 'icons/16pxNoMask/Unarchive.svg'
import { ReactComponent as Export } from 'icons/16px/Export.svg'
import { ReactComponent as StatsView } from 'icons/16px/StatsView.svg'
import { ReactComponent as ContactDelete } from 'icons/16px/Contact Delete.svg'
import { ReactComponent as ContactAdd } from 'icons/16px/Contacts Add.svg'
import { ReactComponent as TestItSMS } from 'icons/16px/Test-it SMS.svg'
import { ReactComponent as TestItAudio } from 'icons/16px/Test-it Audio.svg'
import { ReactComponent as Edit } from 'icons/16pxNoMask/Edit.svg'
import { ReactComponent as WebFormIcon } from 'icons/16pxNoMask/Web Form.svg'
import { ReactComponent as Refresh } from 'icons/16pxNoMask/Refresh.svg'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import React, { ForwardedRef, forwardRef } from 'react'

export enum MenuEnum {
  copy = 'copy',
  duplicate = 'duplicate',
  analytics = 'analytics',
  export = 'export',
  archive = 'archive',
  unarchive = 'unarchive',
  unsubscribe = 'unsubscribe',
  resubscribe = 'resubscribe',
  delete = 'delete',
  testMessage = 'testMessage',
  testAudio = 'testAudio',
  edit = 'edit',
  testWebForm = 'testWebForm',
  refresh = 'refresh',
}

type Props = {
  type: MenuEnum
  title?: string
  onClick?: (e: React.MouseEvent) => void
  loading?: boolean
  disabled?: boolean
}

// eslint-disable-next-line react/display-name
export const MenuBtn = forwardRef(
  (
    { type, onClick = () => undefined, title, loading, disabled }: Props,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const obj: { [key in MenuEnum]: JSX.Element } = {
      edit: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <Edit />
          {title || 'Edit'}
        </Button>
      ),
      copy: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <Link />
          {title || 'Copy'}
        </Button>
      ),
      duplicate: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <Duplicate />
          {title || 'Duplicate'}
        </Button>
      ),
      analytics: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <StatsView />
          {title || 'Analytics'}
        </Button>
      ),
      export: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <Export />
          {title || 'Export report'}
        </Button>
      ),
      archive: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <Archive />
          {title || 'Archive'}
        </Button>
      ),
      unarchive: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <Unarchive />
          {title || 'Unarchive'}
        </Button>
      ),
      unsubscribe: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <ContactDelete />
          {title || 'Unsubscribe'}
        </Button>
      ),
      resubscribe: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <ContactAdd />
          {title || 'Resubscribe'}
        </Button>
      ),
      testMessage: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
          disabled={disabled}
        >
          <TestItSMS />
          {title || 'Test message'}
        </Button>
      ),
      testAudio: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <TestItAudio />
          {title || 'Test audio'}
        </Button>
      ),
      testWebForm: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <WebFormIcon />
          {title || 'Test web from'}
        </Button>
      ),
      refresh: (
        <Button
          size={BtnSize.small}
          hoverGray
          menuBtn
          onClick={onClick}
          ref={ref}
          loading={loading}
        >
          <Refresh />
          {title || 'Refresh'}
        </Button>
      ),
      delete: <DeleteBtn onClick={onClick} />,
    }
    return obj[type]
  }
)
