import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import billingStore from 'store/settings/billing/billingStore'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import styles from './styles.module.scss'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import manualContactStore from 'store/contacts/manualContactStore'
import { useMemo } from 'react'

export const LimitSubscriptionModal = observer(() => {
  const onClose = () => {
    billingStore.setLimitModalFor('')
    manualContactStore.openContactModal &&
      manualContactStore.setOpenContactModal(false)
  }
  const onUpgrade = () => {
    onClose()
    upgradePlanStore.setOpenUpgradePlanModal(true)
  }
  const namePlan = billingStore.clSubscriptionTitle
  const text = useMemo(() => {
    if (billingStore.limitModalFor === 'contacts') {
      return `To add more contacts`
    }
    if (billingStore.limitModalFor === 'webForms') {
      return 'To send more web forms'
    }
    if (billingStore.limitModalFor === 'users') {
      return 'To send invitations to users'
    }
    if (billingStore.limitModalFor === 'numbers') {
      return 'To get more phone numbers'
    }
    return `To send more ${billingStore.limitModalFor.toLowerCase() + 's'}`
  }, [billingStore.limitModalFor])
  return (
    <Modal open={billingStore.showLimitModal} onClose={onClose} withoutHeader>
      <div className={'pModalInfo'}>
        <ModalIcon type={'upgrade'} />
        <h3>
          You have exceeded the limit for <b>{namePlan}</b>
        </h3>
        <p className={'par2 medium gray1'}>
          {text} please upgrade to a higher tier. <br /> View{' '}
          <span className={styles.titlePlan}>{namePlan} and Pro limits.</span>
        </p>

        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onUpgrade}
          fullWidth
          mb={8}
        >
          Upgrade plan
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onClose}
          fullWidth
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
})
