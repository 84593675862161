// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iAJhlVeznSH4p2tP7lJ_{padding:0 32px 0 32px;position:relative;width:900px}.RWV36FjhMe5J9E9PA0p9{display:grid;grid-template-columns:max-content max-content max-content;column-gap:12px}.Q9bw5mwI4MTq51Vo36Qg{display:flex;align-items:center;justify-content:center;background-color:var(--primary-color-gray-4);border-radius:16px;height:288px;margin-bottom:32px}.ZvtUlYphxPxpbpw1lMS_{display:flex;justify-content:space-between;column-gap:84px}.IViBsKp9b_GDA1BbN3F_{max-width:836px;height:384px;position:relative}.IViBsKp9b_GDA1BbN3F_.N34EGRvjLcbFH1Ld5W1o{height:537px}.nsdAUTcaPfNUqM2tmfHl{position:absolute;bottom:0;width:100%}`, "",{"version":3,"sources":["webpack://./src/Modals/AudioModal/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,iBAAA,CACA,WAAA,CAEF,sBACE,YAAA,CACA,yDAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,4CAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,eAAA,CAEF,sBACE,eAAA,CAIA,YAAA,CACA,iBAAA,CAJA,2CACE,YAAA,CAMJ,sBACE,iBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".wrap{\n  padding: 0 32px 0 32px;\n  position: relative;\n  width: 900px;\n}\n.gridRow{\n  display: grid;\n  grid-template-columns: max-content max-content max-content;\n  column-gap: 12px;\n  //margin-bottom: 24px;\n}\n.empty{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--primary-color-gray-4);\n  border-radius: 16px;\n  height: 288px;\n  margin-bottom: 32px;\n}\n.audioType{\n  display: flex;\n  justify-content: space-between;\n  column-gap: 84px;\n}\n.tableWrap{\n  max-width: 836px;\n  &.withTable{\n    height: 537px;\n  }\n  height: 384px;\n  position: relative;\n  //padding-bottom: 65px;\n}\n.pagination{\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `iAJhlVeznSH4p2tP7lJ_`,
	"gridRow": `RWV36FjhMe5J9E9PA0p9`,
	"empty": `Q9bw5mwI4MTq51Vo36Qg`,
	"audioType": `ZvtUlYphxPxpbpw1lMS_`,
	"tableWrap": `IViBsKp9b_GDA1BbN3F_`,
	"withTable": `N34EGRvjLcbFH1Ld5W1o`,
	"pagination": `nsdAUTcaPfNUqM2tmfHl`
};
export default ___CSS_LOADER_EXPORT___;
