// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QjTjbcDyOzcP2hLgUNk_{display:flex;flex-wrap:nowrap;column-gap:4px;align-items:center}.dBc7hIfeFXViVHtsK_x1{width:fit-content;font-weight:500;display:flex;padding:2px 8px;background-color:var(--secondary-color-aqua-2);border-radius:8px;font-size:12px;font-family:Inter-Medium}.dBc7hIfeFXViVHtsK_x1.I43Xki4TlKjyYmiJ26xk{background-color:var(--primary-color-gray-3);color:var(--primary-color-black);border-radius:4px;font-size:14px;font-family:Inter;font-weight:400}.Aopm99makgU9K1eZFdNx{display:flex;gap:8px;width:100%;padding:4px}.Aopm99makgU9K1eZFdNx:hover{background-color:var(--primary-color-gray-4);border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/components/NewTable/cell/OptInRender/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,cAAA,CACA,kBAAA,CAEF,sBACE,iBAAA,CACA,eAAA,CACA,YAAA,CACA,eAAA,CACA,8CAAA,CACA,iBAAA,CACA,cAAA,CACA,wBAAA,CACA,2CACE,4CAAA,CACA,gCAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CAIJ,sBACE,YAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,4BACE,4CAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-wrap: nowrap;\n  column-gap: 4px;\n  align-items: center;\n}\n.link{\n  width: fit-content;\n  font-weight: 500;\n  display: flex;\n  padding: 2px 8px;\n  background-color: var(--secondary-color-aqua-2);\n  border-radius: 8px;\n  font-size: 12px;\n  font-family: Inter-Medium;\n  &.variant2{\n    background-color: var(--primary-color-gray-3);\n    color: var(--primary-color-black);\n    border-radius: 4px;\n    font-size: 14px;\n    font-family: Inter;\n    font-weight: 400;\n  }\n}\n\n.wrapLink{\n  display: flex;\n  gap: 8px;\n  width: 100%;\n  padding: 4px;\n  &:hover{\n    background-color: var(--primary-color-gray-4);\n    border-radius: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `QjTjbcDyOzcP2hLgUNk_`,
	"link": `dBc7hIfeFXViVHtsK_x1`,
	"variant2": `I43Xki4TlKjyYmiJ26xk`,
	"wrapLink": `Aopm99makgU9K1eZFdNx`
};
export default ___CSS_LOADER_EXPORT___;
