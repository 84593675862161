import styles from './styles.module.scss'
import { LoginCard } from 'src/routes/auth/login/LoginCard'
import { ReactComponent as Logo } from 'assets/images/Logo Horizontal.svg'
import CL1 from 'assets/images/CL1.png'
import cl1preview from 'assets/images/cl1preview.png'
import cl1previewFull from 'assets/images/cl1previewFull.png'
import cl2preview from 'assets/images/cl2preview.png'
import cl2previewFull from 'assets/images/cl2previewFull.png'
import { Link } from 'react-router-dom'
import { UnauthorizedRoutes } from 'src/routes/routes'

export const Login = () => {
  return (
    <div className={styles.wrap}>
      <LoginCard
        logo={<img src={CL1} alt={'CallLoop1'} />}
        title={'Log in to Call Loop 1.0'}
        info={
          <>
            Use the old Call Loop. <br /> This version will be discontinued
            soon.
          </>
        }
        previewSrc={cl1preview}
        previewSrcFull={cl1previewFull}
        loginBtn={
          <a
            className={'btn secondaryBlue large fullWidth mt40'}
            href={'https://members.callloop.com/login'}
          >
            Log in 1.0
          </a>
        }
      />
      <LoginCard
        logo={<Logo />}
        title={'Log in to Call Loop 2.0'}
        info={
          <>
            Scale your business with our brand new <br /> re-engineered Call
            Loop platform.
          </>
        }
        previewSrc={cl2preview}
        previewSrcFull={cl2previewFull}
        loginBtn={
          <Link
            className={'btn primary large fullWidth mt40'}
            to={UnauthorizedRoutes.login2}
          >
            Log in 2.0
          </Link>
        }
      />
    </div>
  )
}
