import styles from './styles.module.scss'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'components/Button/Button'
import classNames from 'classnames'
import { InputText } from 'components/Input/InputText/InputText'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import { DropdownProps } from 'components/Dropdown/types'
import { RightContent } from 'components/Dropdown/RightContent'
import { SizedBox } from 'components/SizedBox'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { Tag } from 'components/Tag/Tag'
import { nanoid } from 'nanoid'
import { TagsRender } from 'components/Dropdown/TagsRender/TagsRender'
import { DropdownSearchInput } from 'components/Dropdown/components/SearchInput'
import { useTagsWidth } from 'components/Input/CreateTagInput/useTagsWidth'
import { IOption } from 'src/types/IOption'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { DropdownToolTip } from 'components/Dropdown/DropdownToolTip'
import { onSelectOption } from 'components/Dropdown/components/OptionRender'
import { Icon } from 'components/Icon/Icon'
import uiStore from 'store/uiStore'

const optionsToMap = (options?: IOption[]) =>
  new Map(options?.map((opt) => [opt.value, opt]))

export const Dropdown = <T,>(props: DropdownProps<T>) => {
  const {
    parentElement,
    width,
    labelInput,
    labelInputRight,
    labelTooltip,
    labelTooltipWidth,
    placeholder = 'Select',
    options,
    rightBtn,
    leftIcon,
    selectedOption,
    selectedValue,
    selectedValues,
    columnGap,
    multi,
    unit,
    units,
    selectedOptions,
    fromNavigation,
    disabled,
    loading,
    error,
    transparent,
    className,
    tabIndex,
    withInput,
    withPhoneInput,
    inputValue = '',
    setInputValue,
    withIconRender,
    required,
    openTrigger,
    renderWithPlusOptions,
    openOnFocus,
    selectedTag,
    onCreate,
    tagsNoWrap,
    maxWidth,
    onDeleteTag,
    withSearch,
    withRemove,
    onRemove,
    fullWidth,
    search: propsSearch,
    setSearch: propsSetSearch,
    onBlur,
    setRefInputPhone,
    noBottomRadius,
    contentRender,
    searchPatternFormat,
    searchPrefix,
    plusItemsProps,
  } = props
  const [mapOptions, setMapOptions] = useState(optionsToMap(options))

  useEffect(() => {
    uiStore.setTriggerUpdate()
  }, [options?.length])

  const dropdownSelectedOption =
    selectedOption ||
    (selectedValue !== undefined && mapOptions.get(selectedValue)) ||
    undefined

  const dropdownSelectedOptions: IOption[] = useMemo(() => {
    if (selectedOptions) {
      return selectedOptions
    }
    const options: IOption[] = []
    if (selectedValues) {
      selectedValues?.forEach((value) => {
        const option = mapOptions.get(value)
        if (option) {
          options.push(option)
        }
      })
    }
    return options
  }, [selectedOptions, mapOptions, selectedValues])

  const ref = useRef<HTMLDivElement>(null)
  const refHover = useRef(false)
  const { trigger, showTT, setTrigger, onTriggerClick, setShowTT } =
    useFixedTooltip(refHover, undefined, parentElement)
  const [searchLocal, setSearchLocal] = useState('')

  const search = propsSearch || searchLocal
  const setSearch = propsSetSearch || setSearchLocal

  const [sortedOptions, setSortedOptions] = useState(options)
  const [isEditable, setIsEditable] = useState(false)
  const [showInput, setShowInput] = useState(!dropdownSelectedOptions?.length)

  const { widthTags } = useTagsWidth(
    ref.current,
    onDeleteTag,
    dropdownSelectedOptions
  )

  useEffect(() => {
    if (openTrigger) {
      onTriggerClick()
    }
  }, [openTrigger])

  useEffect(() => {
    setMapOptions(optionsToMap(options))
  }, [options])

  useEffect(() => {
    setSortedOptions(options)
  }, [options])

  const onChangeInput = (val: string) => {
    setSearch(val)
    setIsEditable(true)
    setShowTT(true)
  }

  const isSelectALl =
    !dropdownSelectedOptions?.length ||
    dropdownSelectedOptions?.length !== options?.length

  useEffect(() => {
    isEditable && setIsEditable(false)
    if (showTT && dropdownSelectedOption && options && multi) {
      setSortedOptions(
        [...options].sort((opt) =>
          opt.value === dropdownSelectedOption.value ? -1 : 1
        )
      )
    }
    !propsSetSearch && setSearch('')
    if (!showTT) {
      onBlur && onBlur()
    }
  }, [showTT])

  const filteredOptions = propsSearch
    ? options
    : sortedOptions?.filter((opt) =>
        typeof opt.title === 'string'
          ? !(
              selectedTag &&
              dropdownSelectedOptions
                ?.map((opt) => opt.value)
                .includes(opt.value)
            ) &&
            opt.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          : typeof opt.value === 'string'
          ? !(
              selectedTag &&
              dropdownSelectedOptions
                ?.map((opt) => opt.value)
                .includes(opt.value)
            ) &&
            opt.value.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          : false
      )

  const handleCreate = () => {
    const id = 'new' + nanoid()
    const newOpt: IOption = {
      title: search || 'My New List',
      value: id,
    }
    onCreate && onCreate(search || 'My New List', id)
    onSelectOption({ option: newOpt, setShowTT })
    setSearch('')
  }

  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(!showInput && !!dropdownSelectedOptions?.length)
  }, [showInput, dropdownSelectedOptions, showTT, tagsNoWrap])

  const getContent = () => {
    if (multi) {
      if (renderWithPlusOptions) {
        return (
          <div className={styles.row}>
            {dropdownSelectedOptions?.length ? (
              <>
                <div>{dropdownSelectedOptions[0].title}</div>
                {dropdownSelectedOptions.length > 1 && (
                  <div className={styles.counter}>
                    <span className={'s2 bold white'}>
                      +{dropdownSelectedOptions.length - 1}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className={'gray2'}>Select</div>
            )}
          </div>
        )
      }
      if (
        !dropdownSelectedOptions?.length ||
        dropdownSelectedOptions.length === options?.length
      ) {
        return `All ${units}`
      } else if (dropdownSelectedOptions.length === 1) {
        return `1 ${unit}`
      } else {
        return `${dropdownSelectedOptions.length} ${units}`
      }
    } else {
      if (dropdownSelectedOption) {
        const { LeftIcon, leftIcon } = dropdownSelectedOption
        return (
          <div className={styles.titleWrap}>
            {leftIcon && <Icon {...leftIcon} />}
            {withIconRender && LeftIcon && (
              <div
                className={classNames(
                  dropdownSelectedOption.iconClassSelected ||
                    dropdownSelectedOption.iconClass
                )}
              >
                <LeftIcon />
              </div>
            )}
            {dropdownSelectedOption.renderTitle || dropdownSelectedOption.title}
          </div>
        )
      } else {
        return <div className={styles.placeholder}>{placeholder}</div>
      }
    }
  }

  return (
    <>
      <DropdownToolTip
        {...props}
        showTT={showTT}
        trigger={trigger}
        search={search}
        dropdownSelectedOption={dropdownSelectedOption}
        propsSearch={props.search}
        setShowTT={setShowTT}
        dropdownSelectedOptions={dropdownSelectedOptions}
        filteredOptions={filteredOptions}
        handleCreate={handleCreate}
        isSelectALl={isSelectALl}
      />
      <div
        className={classNames(
          styles.wrap,
          fromNavigation && styles.fromNavigation,
          disabled && styles.disabled,
          loading && styles.loading,
          error && styles.error,
          className,
          transparent && styles.transparent,
          fullWidth && styles.fullWidth
        )}
        style={{ maxWidth, width }}
        onMouseEnter={() => {
          refHover.current = true
        }}
        onMouseLeave={() => {
          refHover.current = false
        }}
      >
        {labelInput && (
          <div className={styles.labelInputWrap}>
            <div className={'s2 gray1 mb8 medium flex'}>
              {labelInput}
              {required && <span className={'s2 medium error1'}>&nbsp;*</span>}
              {labelTooltip && (
                <InfoTooltip title={labelTooltip} width={labelTooltipWidth} />
              )}
            </div>
            {dropdownSelectedOption?.title && withRemove && (
              <Button onClick={onRemove}>
                <span className={'s2 bold blue3 link'}>Remove</span>
              </Button>
            )}
            {
              <div className={'s2 gray1 mb8 medium flex'}>
                {labelInputRight}
              </div>
            }
          </div>
        )}
        {contentRender && (
          <div ref={setTrigger} onClick={onTriggerClick} aria-hidden={true}>
            {contentRender}
          </div>
        )}
        {!contentRender && (withSearch || selectedTag) && (
          <div
            ref={setTrigger}
            onMouseEnter={() => {
              refHover.current = true
            }}
            onMouseLeave={() => {
              refHover.current = false
            }}
            className={classNames(
              styles.input,
              showTT && styles.open,
              noBottomRadius && styles.noBottomRadius
            )}
            onClick={onTriggerClick}
            onFocus={
              openOnFocus && !disabled ? () => onTriggerClick() : undefined
            }
            tabIndex={tabIndex}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onTriggerClick()
              }
            }}
            aria-hidden={true}
          >
            <div className={styles.left}>
              {leftIcon}
              <div
                className={classNames(
                  styles.fullWidth,
                  tagsNoWrap && styles.wrapWithNowrap
                )}
              >
                <div
                  className={classNames(
                    styles.searchWrap,
                    tagsNoWrap && styles.tagsNoWrap
                  )}
                  ref={ref}
                >
                  {selectedTag &&
                    !dropdownSelectedOptions?.length &&
                    !withSearch && (
                      <span className={'gray1'}>{placeholder}</span>
                    )}
                  {selectedTag && !!dropdownSelectedOptions?.length && (
                    <TagsRender
                      selectedOptions={dropdownSelectedOptions}
                      onDeleteTag={onDeleteTag}
                      placeholder={placeholder}
                      isEditable={isEditable}
                      selectedOption={dropdownSelectedOption}
                      search={search}
                      onChangeInput={onChangeInput}
                      selectedTag={selectedTag}
                      showInput={showInput && withSearch}
                      setShowInput={setShowInput}
                      tagsNoWrap={tagsNoWrap}
                      searchPrefix={searchPrefix}
                      plusItemsProps={plusItemsProps}
                    />
                  )}
                  {withSearch &&
                    (selectedTag && dropdownSelectedOption ? (
                      <Tag
                        text={dropdownSelectedOption.title as string}
                        close
                        leftIcon={{ icon: 'contactsFill' }}
                        onDeleteTag={() => {
                          if (dropdownSelectedOption?.value && onDeleteTag) {
                            onDeleteTag(dropdownSelectedOption.value as string)
                          }
                        }}
                      />
                    ) : (
                      !dropdownSelectedOptions?.length && (
                        <div className={'row8'}>
                          {searchPrefix && (
                            <span className={'gray1'}>{searchPrefix}</span>
                          )}
                          <DropdownSearchInput
                            searchPatternFormat={searchPatternFormat}
                            placeholder={
                              selectedTag && dropdownSelectedOption
                                ? ''
                                : placeholder
                            }
                            search={
                              search ||
                              (!isEditable &&
                                !selectedTag &&
                                (dropdownSelectedOption?.selectedRenderValue
                                  ? dropdownSelectedOption?.value
                                  : (dropdownSelectedOption?.title as string))) ||
                              ''
                            }
                            onChangeInput={(val) => onChangeInput(val)}
                            disabled={disabled}
                          />
                        </div>
                      )
                    ))}
                </div>
                {showTT && show && (
                  <div
                    className={classNames(
                      !tagsNoWrap && styles.wrapInput,
                      tagsNoWrap && styles.tagsNoWrapInput
                    )}
                    style={{
                      left:
                        widthTags > 0
                          ? widthTags + (leftIcon ? 41 : 17)
                          : leftIcon
                          ? 35
                          : 9,
                    }}
                  >
                    <DropdownSearchInput
                      placeholder={
                        selectedTag && dropdownSelectedOption ? '' : placeholder
                      }
                      search={
                        search ||
                        (!isEditable &&
                          !selectedTag &&
                          (dropdownSelectedOption?.selectedRenderValue
                            ? dropdownSelectedOption?.value
                            : (dropdownSelectedOption?.title as string))) ||
                        ''
                      }
                      onChangeInput={(val) => onChangeInput(val)}
                      searchPatternFormat={searchPatternFormat}
                    />
                  </div>
                )}
              </div>
            </div>
            <RightContent
              rightBtn={rightBtn}
              showTT={showTT}
              selectedOption={dropdownSelectedOption}
              setShowTT={setShowTT}
              disabled={disabled}
            />
          </div>
        )}
        {!contentRender && (withPhoneInput || withInput) && (
          <div
            ref={setTrigger}
            onClick={onTriggerClick}
            onFocus={openOnFocus ? () => onTriggerClick() : undefined}
            className={styles.inputBtn}
            aria-hidden={'true'}
            tabIndex={tabIndex}
          >
            {withPhoneInput ? (
              <InputPhone
                disabled={disabled}
                value={inputValue}
                onChange={(event) =>
                  setInputValue && setInputValue(event.target.value)
                }
                setRefInput={setRefInputPhone}
              />
            ) : (
              <InputText
                placeholder={placeholder}
                disabled={disabled}
                leftIcon={leftIcon}
                withoutClear
                value={inputValue}
                onChange={(event) =>
                  setInputValue && setInputValue(event.target.value)
                }
              />
            )}
            <div className={styles.wrapIcon}>
              <RightContent
                rightBtn={rightBtn}
                showTT={showTT}
                selectedOption={dropdownSelectedOption}
                setShowTT={setShowTT}
                disabled={disabled}
              />
            </div>
          </div>
        )}
        {!contentRender &&
          !withInput &&
          !withPhoneInput &&
          !withSearch &&
          !selectedTag && (
            <div ref={setTrigger}>
              <Button
                className={classNames(styles.input, showTT && styles.open)}
                onClick={onTriggerClick}
                tabIndex={tabIndex}
              >
                <div className={styles.left} style={{ columnGap }}>
                  {leftIcon}
                  {getContent()}
                </div>
                <RightContent
                  rightBtn={rightBtn}
                  showTT={showTT}
                  selectedOption={dropdownSelectedOption}
                />
              </Button>
            </div>
          )}
        {error && (
          <>
            <SizedBox height={8} />
            <ValidItem text={error} neutral={false} isValid={false} />
          </>
        )}
      </div>
    </>
  )
}
