// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jg3_iETrzA3fYrhjyxAw{padding-top:30px;padding-left:64px;display:flex;column-gap:8px}`, "",{"version":3,"sources":["webpack://./src/components/BreadCrumbs/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA","sourcesContent":[".wrap{\n  padding-top: 30px;\n  padding-left: 64px;\n  display: flex;\n  column-gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `jg3_iETrzA3fYrhjyxAw`
};
export default ___CSS_LOADER_EXPORT___;
