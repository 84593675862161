import { makeAutoObservable, reaction } from 'mobx'
import dayjs from 'lib/dayjs'
import { AppSymbols } from 'src/util/symbols'
import {
  monthlyToggleOptions,
  onTheDayOptions,
  onTheOrderOptions,
  periodsOptions,
  repeatsOptions2map,
} from 'components/Schedule/store/mockData'
import {
  BroadcastSendType,
  CampaignDelayPeriod,
  CampaignStepDelayRequestInput,
  CustomMonthRepeatType,
  DayOfWeek,
  OnMonthDayOrdinal,
  OnMonthDayType,
  RepeatType,
  SchedulingConfig,
  SchedulingConfigInput,
  SendLaterRepeatType,
} from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'

export class BroadcastScheduleStore {
  constructor() {
    makeAutoObservable(this)

    reaction(
      () => this.activeSendImmediately,
      (value) => {
        if (value) {
          this.sendLaterSendDate = null
        }
      }
    )
  }
  laterCount = 1
  laterPeriod = periodsOptions[0]
  laterAdvanced = false
  laterAdvancedTime: Date | null = new Date(
    dayjs().startOf('day').add(14, 'hours').valueOf()
  )

  repeatMonthlyToggle: IOption = monthlyToggleOptions[0]
  selectedOnTheOrderOptions: IOption = onTheOrderOptions[0]
  selectedOnTheDayOptions: IOption = onTheDayOptions[0]
  onlyImmediatelyBetween = false
  betweenStartTime: Date | null = new Date(dayjs().startOf('day').valueOf())
  betweenEndTime: Date | null = new Date(
    dayjs().startOf('day').add(18, 'hours').valueOf()
  )

  sendTypeBroadcast: BroadcastSendType = BroadcastSendType.Immediately
  sendLaterSendDate: Date | null = null
  sendLaterConfigRepeatType: SendLaterRepeatType = SendLaterRepeatType.Never
  repeatConfigEndDate: Date | null = null
  customRepeatConfigRepeatCount = 1
  customRepeatConfigRepeatType: RepeatType = RepeatType.Daily
  customMonthRepeatType: CustomMonthRepeatType = CustomMonthRepeatType.Each
  customEachMonthDayConfigDays: Array<number | null> = []
  customOnMonthDayConfigDayOrdinal: OnMonthDayOrdinal = OnMonthDayOrdinal.First
  customOnMonthDayConfigDayType: OnMonthDayType = OnMonthDayType.Sunday
  customRepeatWeekConfigDays: Array<DayOfWeek | null> = [
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
  ]

  refModalContent: HTMLDivElement | null = null

  get letterDelayInput(): CampaignStepDelayRequestInput | null {
    if (this.activeSendLater) {
      return {
        value: this.laterCount,
        period: this.laterPeriod.value as CampaignDelayPeriod,
      }
    }
    return null
  }

  get scheduleError() {
    if (this.activeSendLater) {
      if (this.sendLaterSendDate) {
        const sendLaterSendDateTimestamp = +this.sendLaterSendDate
        const nowTimestamp = +new Date()
        if (sendLaterSendDateTimestamp < nowTimestamp) {
          return 'Send date invalid'
        }
      }
    }
    return ''
  }

  get schedulingConfigInput(): SchedulingConfigInput {
    return {
      sendLaterConfig: this.activeSendImmediately
        ? undefined
        : {
            sendDate: this.sendLaterSendDate,
            repeatType: this.sendLaterConfigRepeatType,
            repeatConfig:
              this.sendLaterConfigRepeatType === SendLaterRepeatType.Never
                ? undefined
                : {
                    endDate: this.repeatConfigEndDate,
                    customRepeatConfig:
                      this.sendLaterConfigRepeatType ===
                      SendLaterRepeatType.Custom
                        ? {
                            repeatType: this.customRepeatConfigRepeatType,
                            monthConfig:
                              this.customRepeatConfigRepeatType ===
                              RepeatType.Monthly
                                ? {
                                    eachMonthDayConfig:
                                      this.customMonthRepeatType ===
                                      CustomMonthRepeatType.Each
                                        ? {
                                            days: this
                                              .customEachMonthDayConfigDays,
                                          }
                                        : undefined,
                                    onMonthDayConfig:
                                      this.customMonthRepeatType ===
                                      CustomMonthRepeatType.OnThe
                                        ? {
                                            dayOrdinal:
                                              this
                                                .customOnMonthDayConfigDayOrdinal,
                                            dayType:
                                              this
                                                .customOnMonthDayConfigDayType,
                                          }
                                        : undefined,
                                    monthRepeatType: this.customMonthRepeatType,
                                  }
                                : undefined,
                            repeatCount: this.customRepeatConfigRepeatCount,
                            weekConfig:
                              this.customRepeatConfigRepeatType ===
                              RepeatType.Weekly
                                ? {
                                    days: this.customRepeatWeekConfigDays,
                                  }
                                : undefined,
                          }
                        : undefined,
                  },
          },
      sendType: this.sendTypeBroadcast,
    }
  }

  get activeSendImmediately() {
    return this.sendTypeBroadcast === BroadcastSendType.Immediately
  }
  get activeSendLater() {
    return this.sendTypeBroadcast === BroadcastSendType.Later
  }

  get sendTimeString() {
    if (this.activeSendImmediately) {
      return 'Immediately'
    }
    if (this.activeSendLater) {
      return `${dayjs(this.sendLaterSendDate).format('ddd, MMM DD')} - ${dayjs(
        this.sendLaterSendDate
      ).format('h:mm a')}`
    }
    return ''
  }

  get suffixString() {
    if (this.activeSendImmediately) {
      if (this.onlyImmediatelyBetween) {
        return ` ${AppSymbols.point} ${dayjs(this.betweenStartTime).format(
          'h:mm A'
        )} - ${dayjs(this.betweenEndTime).format('h:mm A')}`
      }
      return ''
    }
    if (this.activeSendLater) {
      if (this.sendLaterConfigRepeatType === SendLaterRepeatType.Never) {
        return ''
      }
      if (this.sendLaterConfigRepeatType === SendLaterRepeatType.Custom) {
        return `${AppSymbols.point} Custom repeat`
      }
      if (
        this.sendLaterConfigRepeatType === SendLaterRepeatType.EveryDay ||
        this.sendLaterConfigRepeatType === SendLaterRepeatType.EveryWeek ||
        this.sendLaterConfigRepeatType === SendLaterRepeatType.EveryMonth
      ) {
        return `${AppSymbols.point} ${
          repeatsOptions2map.get(this.sendLaterConfigRepeatType)?.title
        }`
      }
    }
    return ''
  }
  setSchedulingConfig = (schedulingConfig: SchedulingConfig) => {
    this.sendTypeBroadcast =
      schedulingConfig.sendType || BroadcastSendType.Immediately
    this.sendLaterConfigRepeatType =
      schedulingConfig.sendLaterConfig?.repeatType || SendLaterRepeatType.Never
    this.sendLaterSendDate = schedulingConfig.sendLaterConfig?.sendDate
      ? dayjs(schedulingConfig.sendLaterConfig?.sendDate).toDate()
      : null
    //repeatConfig
    this.repeatConfigEndDate = schedulingConfig.sendLaterConfig?.repeatConfig
      ?.endDate
      ? dayjs(schedulingConfig.sendLaterConfig?.repeatConfig?.endDate).toDate()
      : null
    this.customRepeatConfigRepeatType =
      schedulingConfig.sendLaterConfig?.repeatConfig?.customRepeatConfig
        ?.repeatType || RepeatType.Daily
    this.customRepeatConfigRepeatCount =
      schedulingConfig.sendLaterConfig?.repeatConfig?.customRepeatConfig
        ?.repeatCount || 1
    //CustomRepeatMonthConfig
    this.customMonthRepeatType =
      schedulingConfig.sendLaterConfig?.repeatConfig?.customRepeatConfig
        ?.monthConfig?.monthRepeatType || CustomMonthRepeatType.Each
    this.customEachMonthDayConfigDays =
      schedulingConfig.sendLaterConfig?.repeatConfig?.customRepeatConfig
        ?.monthConfig?.eachMonthDayConfig?.days || []
    this.customOnMonthDayConfigDayOrdinal =
      schedulingConfig.sendLaterConfig?.repeatConfig?.customRepeatConfig
        ?.monthConfig?.onMonthDayConfig?.dayOrdinal || OnMonthDayOrdinal.First
    this.customOnMonthDayConfigDayType =
      schedulingConfig.sendLaterConfig?.repeatConfig?.customRepeatConfig
        ?.monthConfig?.onMonthDayConfig?.dayType || OnMonthDayType.Day
    //RepeatWeekConfig
    this.customRepeatWeekConfigDays =
      schedulingConfig.sendLaterConfig?.repeatConfig?.customRepeatConfig
        ?.weekConfig?.days || []
  }
  setSendTypeBroadcast(sendTyp: BroadcastSendType) {
    this.sendTypeBroadcast = sendTyp
  }

  setLaterDate(date: Date | null) {
    this.sendLaterSendDate = date
  }
  setLaterCount(count: number) {
    this.laterCount = count
  }
  setLaterPeriod(opt: IOption) {
    this.laterPeriod = opt
  }
  setLaterAdvanced(state: boolean) {
    this.laterAdvanced = state
  }
  setLaterAdvancedTime(time: Date | null) {
    this.laterAdvancedTime = time
  }
  selectLaterWeeklyDay(day: DayOfWeek | null) {
    if (this.customRepeatWeekConfigDays.includes(day)) {
      this.customRepeatWeekConfigDays = this.customRepeatWeekConfigDays.filter(
        (daySelected) => day !== daySelected
      )
    } else {
      this.customRepeatWeekConfigDays.push(day)
    }
  }
  onPlusRepeats() {
    this.customRepeatConfigRepeatCount += 1
  }
  onMinusRepeats() {
    if (this.customRepeatConfigRepeatCount === 1) {
      return
    }
    this.customRepeatConfigRepeatCount -= 1
  }
  setSendLaterConfigRepeatType(ending: SendLaterRepeatType) {
    this.sendLaterConfigRepeatType = ending
  }
  setCustomRepeatConfigRepeatType(type: RepeatType) {
    this.customRepeatConfigRepeatType = type
  }
  selectWeeklyDay(day: DayOfWeek | null) {
    if (this.customRepeatWeekConfigDays.includes(day)) {
      this.customRepeatWeekConfigDays = this.customRepeatWeekConfigDays.filter(
        (daySelected) => day !== daySelected
      )
    } else {
      this.customRepeatWeekConfigDays.push(day)
    }
  }
  selectMonthlyDay(day: number) {
    if (this.customEachMonthDayConfigDays.includes(day)) {
      this.customEachMonthDayConfigDays =
        this.customEachMonthDayConfigDays.filter(
          (daySelected) => day !== daySelected
        )
    } else {
      this.customEachMonthDayConfigDays.push(day)
    }
  }
  setCustomMonthRepeatType(value: CustomMonthRepeatType) {
    this.customMonthRepeatType = value
  }

  setCustomOnMonthDayConfigDayOrdinal(value: OnMonthDayOrdinal) {
    this.customOnMonthDayConfigDayOrdinal = value
  }
  setCustomOnMonthDayConfigDayType(value: OnMonthDayType) {
    this.customOnMonthDayConfigDayType = value
  }
  setRepeatConfigEndDate(date: Date | null) {
    this.repeatConfigEndDate = date
  }
  setOnlyImmediatelyBetween(state: boolean) {
    this.onlyImmediatelyBetween = state
  }
  setBetweenStartTime(time: Date | null) {
    this.betweenStartTime = time
  }
  setBetweenEndTime(time: Date | null) {
    this.betweenEndTime = time
  }

  setRefModalContent = (element: typeof this.refModalContent) => {
    this.refModalContent = element
  }
}
