import { observer } from 'mobx-react-lite'
import { DatePicker } from 'components/DatePicker/DatePicker'
import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { NewTextInput } from 'components/Input/NewTextInput/NewTextInput'
import { DateFilter } from 'store/contacts/segment/filters/DateFilter'
import { DateEndTimePicker } from 'components/DatePicker/DateEndTimePicker'

type Props = {
  filter: DateFilter
}

export const DateFilterContent = observer(({ filter }: Props) => {
  return (
    <>
      {filter.isDate && (
        <div className={'fullWidth grid'} style={{ zIndex: 200 }}>
          {filter.isTime ? (
            <DateEndTimePicker
              startDate={filter.date || new Date()}
              onChange={filter.setDate}
              position={TTPositionEnum.bottomLeft}
              withoutRemove
            />
          ) : (
            <DatePicker
              startDate={filter.date || null}
              onChange={filter.setDate}
            />
          )}
        </div>
      )}
      {filter.isRange && (
        <div className={'fullWidth grid'} style={{ zIndex: 200 }}>
          <RangeDatePicker
            rangeDatePickerStore={filter.rangeDatePickerStore}
            position={TTPositionEnum.bottomLeft}
            noLeftBar
            withTime={filter.isTime}
            fullWidth
          />
        </div>
      )}
      {filter.isNumber && (
        <NewTextInput
          value={filter.value}
          setValue={filter.setValue}
          number
          rightContent={<span className={'gray1'}>days ago</span>}
        />
      )}
    </>
  )
})
