import { ActionBtnProps } from 'components/NewTable/types'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import * as React from 'react'
import { api } from 'src/apiRest/api'
import { onExport } from 'src/actions/onExport'
import { ITrigger } from 'src/types/ITrigger'

export const TriggerExportBtn = (props: ActionBtnProps<ITrigger>) => {
  const { row } = props

  const handleExport = async () => {
    if (row?.id) {
      await onExport({
        info: 'trigger report',
        request: api.getReport('trigger', row.id),
      })
    }
  }
  return (
    <>
      <TableBtn
        {...props}
        action={ActionsEnum.export}
        textBtn={'Export report'}
        onAction={handleExport}
      />
    </>
  )
}
