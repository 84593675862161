// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W6HEfatwVWPzZmYpMwXS{margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/Invoices/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".wrap{\n  margin-top: 40px;\n}\n.wrapTable{\n  //height: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `W6HEfatwVWPzZmYpMwXS`
};
export default ___CSS_LOADER_EXPORT___;
