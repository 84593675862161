// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gPayhDJrILNrD24jjWK8{position:absolute;bottom:48px;cursor:pointer}.gPayhDJrILNrD24jjWK8:hover span{color:var(--states-color-error-1)}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/components/CancelSubscription/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CACA,cAAA,CAEE,iCACE,iCAAA","sourcesContent":[".button{\n  position: absolute;\n  bottom: 48px;\n  cursor: pointer;\n  &:hover{\n    span{\n      color: var(--states-color-error-1);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `gPayhDJrILNrD24jjWK8`
};
export default ___CSS_LOADER_EXPORT___;
