import classNames from 'classnames'
import styles from './styles.module.scss'

import { CSSProperties, useState } from 'react'
import { icons } from 'components/Icon/icons'
import { Colors, IColor } from 'styles/colors'

export type IIcon = keyof typeof icons

export type IIconProps = {
  icon: IIcon
  fontSize?: CSSProperties['fontSize']
  color?: IColor
  hoverColor?: IColor
  className?: string
}

export const Icon = ({
  icon,
  fontSize = 16,
  color,
  hoverColor,
  className,
}: IIconProps) => {
  const [hover, setHover] = useState(false)
  const styleColor =
    hoverColor && hover ? Colors[hoverColor] : color && Colors[color]
  return (
    <span
      className={classNames(styles.wrap, 'app-icon', className)}
      style={{ fontSize, height: fontSize, color: styleColor }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {icons[icon]}
    </span>
  )
}
