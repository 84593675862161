import classNames from 'classnames'
import styles from './styles.module.scss'
import { Switch } from 'components/Switch/Switch'
import { ReactNode } from 'react'

export const AutoRechargeCardItem = ({
  text,
  onToggle,
  title,
  subTitle,
  enabled,
}: {
  text: string | ReactNode
  title: string
  subTitle?: string
  enabled: boolean
  onToggle: (value: boolean) => void
}) => {
  return (
    <div className={classNames(styles.card)}>
      <div className={'col8'}>
        <div className={'col4'}>
          <span className={'bold'}>{title}</span>
          {subTitle && <span className={'s2 mb4'}>{subTitle}</span>}
        </div>
        <span className={'s2 gray1'}>{text}</span>
      </div>
      <Switch active={enabled} onChange={onToggle} />
    </div>
  )
}
