// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abxquvWHcXu7qpaSlSmp{cursor:pointer}.f50sqChIkGZYz8lh2qdt{width:24px;height:24px;display:flex;align-items:center;justify-content:center;border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/components/Button/PlusBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CAEF,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  cursor: pointer;\n}\n.wrapIcon{\n  width: 24px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `abxquvWHcXu7qpaSlSmp`,
	"wrapIcon": `f50sqChIkGZYz8lh2qdt`
};
export default ___CSS_LOADER_EXPORT___;
