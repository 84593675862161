import { observer } from 'mobx-react-lite'
import React from 'react'
import configStore from 'store/configStore'
import registerStore from 'store/registerStore'
import { Navigate } from 'react-router-dom'
import { NoCompleteRoutes } from 'src/routes/routes'
import { RegisterMemberInfoB } from 'src/routes/auth/signUp/RegisterMemberInfoB'
import { RegisterCompanyInfoA } from 'src/routes/auth/signUp/RegisterCompanyInfoA'

export const RegisterStep2 = observer(() => {
  if (configStore.signUpFlow === 'B') {
    return registerStore.companyName ? (
      <RegisterMemberInfoB />
    ) : (
      <Navigate to={NoCompleteRoutes.register1} />
    )
  }
  return <RegisterCompanyInfoA />
})
