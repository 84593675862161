import { observer } from 'mobx-react-lite'
import { Row } from 'src/routes/settings/compliance/modals/components/Row/Row'
import businessProfileStore from 'store/settings/company/businessProfileStore'

export const GeneralInfo = observer(() => {
  return (
    <div className={'col20'}>
      <p className={'par2'}>
        <b>General info</b>
      </p>
      <div className={'col12'}>
        <Row
          title={'Business name'}
          value={businessProfileStore.businessNameString}
        />
        <Row
          title={'EIN'}
          value={businessProfileStore.einToString(
            businessProfileStore.businessProfile?.ein
          )}
        />
        <Row
          title={'Business address'}
          value={businessProfileStore.addressString}
        />
      </div>
    </div>
  )
})
