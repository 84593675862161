import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import audioStore from 'store/audioStore/audioStore'
import styles from './styles.module.scss'
import { Alert } from 'components/Alert/Alert'
import { DragAndDropInput } from 'components/DragnDropInput/DragAndDropInput/DragAndDropInput'
import { useEffect, useMemo, useState } from 'react'
import { SIZE_LIMIT_5_MB } from 'src/static/constants'
import mediaStore from 'store/mediaStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import classNames from 'classnames'

export const UploadAudioModal = observer(() => {
  const [loading, setLoading] = useState(false)
  const [isError, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [audioStore.openUploadModal])

  const onAddFiles = async (files: File[]) => {
    try {
      setError(false)
      setLoading(true)
      await audioStore.onAddFiles(files, true)
    } catch (e) {
      setError(true)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const alertContent = useMemo(() => {
    if (isError) {
      return (
        <Alert
          title={'Upload failed'}
          text={
            <>
              Please upload the following file types: <b>*.mp3, *.wav</b>
            </>
          }
          type={AlertTypeEnum.error}
        />
      )
    }
    if (mediaStore.showError) {
      return (
        <Alert
          title={'Upload failed'}
          text={mediaStore.subTitleError}
          type={AlertTypeEnum.error}
        />
      )
    }
    return (
      <Alert
        title={
          'Voice messages are billed in 60-second increments and cost 1 credit per 60-second message.'
        }
        text={'We recommend recording an audio around 45-50 seconds.'}
      />
    )
  }, [isError, mediaStore.showError, mediaStore.subTitleError])

  return (
    <Modal
      open={audioStore.openUploadModal}
      onClose={() => audioStore.onCloseUploadModal()}
      title={'Upload audio'}
    >
      <div className={classNames('pModal', styles.wrap)}>
        {alertContent}
        <div className={styles.wrapInput}>
          <DragAndDropInput
            onAddFiles={onAddFiles}
            accept={'.mp3, .wav'}
            subtitle={'MP3, wav - up to 5MB'}
            inputLoading={loading}
            fileLimitSize={SIZE_LIMIT_5_MB}
          />
        </div>
      </div>
    </Modal>
  )
})
