// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CiC_nNm1AEytzV_6s3eA{display:grid;align-items:center}.fRK0UBTSOIwSiB_r8V96{margin-bottom:8px}.WdNePjZCIBVHTbqc24rJ{width:100%;justify-content:space-between;display:grid;grid-template-columns:auto 16px;gap:8px}`, "",{"version":3,"sources":["webpack://./src/routes/main/newOrganization/components/navbarItem/SwitchOrganization/TooltipContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAEF,sBACE,iBAAA,CAEF,sBACE,UAAA,CACA,6BAAA,CACA,YAAA,CACA,+BAAA,CACA,OAAA","sourcesContent":[".wrap{\n  display: grid;\n  align-items: center;\n}\n.wrapInput{\n  margin-bottom: 8px;\n}\n.btn{\n  width: 100%;\n  justify-content: space-between;\n  display: grid;\n  grid-template-columns: auto 16px;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `CiC_nNm1AEytzV_6s3eA`,
	"wrapInput": `fRK0UBTSOIwSiB_r8V96`,
	"btn": `WdNePjZCIBVHTbqc24rJ`
};
export default ___CSS_LOADER_EXPORT___;
