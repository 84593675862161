import { makeAutoObservable } from 'mobx'
import { Asset } from 'src/generated/graphql'

class PresentationStore {
  constructor() {
    makeAutoObservable(this)
  }
  presentationImages: Asset[] = []
  samplePresentationImageUrl = ''
  currentIndex = 0

  get openPresentationMode() {
    return !!this.presentationImages.length
  }
  get currentImg() {
    return this.presentationImages[this.currentIndex]
  }
  setPresentationImages(images: Asset[]) {
    this.presentationImages = images
  }
  setSampleImageUrl(url: string) {
    this.samplePresentationImageUrl = url
  }
  setCurrentIndex(index: number) {
    this.currentIndex = index
  }
  onPrev() {
    this.currentIndex -= 1
  }
  onNext() {
    this.currentIndex += 1
  }
}

export default new PresentationStore()
