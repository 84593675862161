import { RangeDatePicker } from 'components/RangeDatePicker/RangeDatePicker'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { useKeywordDetailContext } from 'src/routes/main/keywords/detail/keywordDetailPage'

export const LeftStickyContent = observer(() => {
  const keywordDetailStore = useKeywordDetailContext()
  return (
    <div className={styles.wrapRangePicker}>
      <RangeDatePicker
        rangeDatePickerStore={
          keywordDetailStore.tableStore.rangeDatePickerStore
        }
      />
    </div>
  )
})
