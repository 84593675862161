import styles from './styles.module.scss'
import { DragList } from 'components/DragList/DragList'
import { DataCollectionItem } from 'components/Textarea/DataCollection/DataCollectionItem'
import { FC, useMemo } from 'react'
import { TextareaStore } from 'store/textareaStore'
import { observer } from 'mobx-react-lite'

type Props = {
  textareaStore: TextareaStore
  fromTemplate?: boolean
}

export const DataCollectionContent: FC<Props> = observer(
  ({ textareaStore, fromTemplate }) => {
    const elements = useMemo(() => {
      return textareaStore.dataCollection.map((item) => ({
        id: item.key,
        component: (
          <DataCollectionItem
            item={item}
            textareaStore={textareaStore}
            fromTemplate={fromTemplate}
          />
        ),
      }))
    }, [textareaStore.dataCollection])
    return (
      <div className={styles.wrap}>
        <DragList
          elements={elements}
          order={textareaStore.dataCollectionOrder}
          setOrder={(order) => textareaStore.setDataCollectionOrder(order)}
        />
      </div>
    )
  }
)
