import styles from './styles.module.scss'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { Ref, useEffect, useState } from 'react'
import { StripeCardElementOptions, StripeError, Token } from '@stripe/stripe-js'
import { ColorsValues } from 'styles/variables'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { StripeProvider } from 'src/providers/StripeProvider'

type Props = {
  onAction?: (token: Token) => void
  setReadyForm?: (val: boolean) => void
  label?: string
  rightBtn?: JSX.Element
  noAction?: boolean
  error?: string
  isAutoFocus?: boolean
  fullWidth?: boolean
  refBtn?: Ref<HTMLButtonElement>
  loadingUI?: boolean
  onFailed?: (error: StripeError) => void
}

export const StripeCardElement = (props: Props) => (
  <StripeProvider>
    <StripeCardElementUI {...props} />
  </StripeProvider>
)

export const StripeCardElementUI = observer(
  ({
    onAction,
    label = 'New credit card',
    noAction,
    setReadyForm,
    refBtn,
    error,
    isAutoFocus,
    fullWidth,
    rightBtn,
    loadingUI,
    onFailed,
  }: Props) => {
    const [ready, setReady] = useState(false)
    const [isError, setError] = useState(error)
    const [loading, setLoading] = useState(false)
    const stripe = useStripe()

    useEffect(() => {
      setError(error)
    }, [error])

    const elements = useElements()
    const options: StripeCardElementOptions = {
      style: {
        base: {
          fontSize: `14px`,
          color: ColorsValues.black,
          fontWeight: 400,
          letterSpacing: 'normal',
          fontFamily: 'Inter, Source Code Pro, monospace',
          '::placeholder': {
            color: ColorsValues.gray2,
            fontWeight: 400,
            fontSize: `14px`,
          },
        },
        invalid: {
          color: ColorsValues.error1,
        },
      },
    }

    const handleSubmit = async (event?: any) => {
      setLoading(true)
      event.preventDefault && event.preventDefault()

      if (!stripe || !elements) {
        setLoading(false)
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      const card = elements.getElement(CardElement)
      if (!card) {
        setLoading(false)
        return
      }

      if (onAction) {
        const { token, error } = await stripe.createToken(card)
        if (token?.id) {
          onAction(token)
        }
        if (error) {
          setLoading(false)
          setError(error.message)
          onFailed && onFailed(error)
        }
      }

      setLoading(false)
    }
    useEffect(() => {
      if (setReadyForm) {
        setReadyForm(ready)
      }
    }, [ready])
    useEffect(() => {
      const cardElement = document.getElementById('stripe-card-input')
      if (cardElement && isAutoFocus) {
        setTimeout(() => {
          cardElement.click()
        }, 500)
      }
    }, [])
    return (
      <div
        className={classNames(
          styles.wrap,
          isError && styles.error,
          fullWidth && styles.fullWidth
        )}
      >
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapCard}>
            <div className={'flex-space-between'}>
              <span className={'s2 medium gray1'}>{label}</span>
              {rightBtn}
            </div>

            <CardElement
              id={'stripe-card-input'}
              options={options}
              onChange={(event) => {
                setReady(event.complete && !event.error)
              }}
              onFocus={() => {
                setError('')
              }}
            />
            {isError && (
              <>
                <ValidItem
                  text={'Please provide another credit card'}
                  neutral={false}
                  isValid={false}
                />
              </>
            )}
          </div>
          <Button
            style={{ display: noAction ? 'none' : undefined }}
            type="submit"
            disabled={!stripe || !ready || loading || loadingUI}
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            ref={refBtn}
          >
            Add card
          </Button>
        </form>
      </div>
    )
  }
)
