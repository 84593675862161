import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { AppIcon, ImportIcons } from 'components/AppIcon/AppIcon'
import { capitalize } from 'lodash'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ClickAwayListener } from '@mui/material'
import { useState } from 'react'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { DeleteBtn } from 'components/Button/DeleteBtn'
import billingStore from 'store/settings/billing/billingStore'
import {
  PaymentMethod,
  useDeletePaymentMethodMutation,
  useSetBackupPaymentMethodMutation,
  useSetPrimaryPaymentMethodMutation,
} from 'src/generated/graphql'
import { CardIcon } from 'components/Input/InputText/CardIcon'
import { observer } from 'mobx-react-lite'
import { CardType } from 'src/enums/cardType'

export const PaymentCard = observer((paymentMethod: PaymentMethod) => {
  const type =
    billingStore.primaryPaymentMethodId === paymentMethod.id
      ? 'Primary'
      : billingStore.backupPaymentMethodId === paymentMethod.id
      ? 'Backup Card'
      : ''
  const card = paymentMethod?.card
  const [open, setOpen] = useState(false)

  const [setPrimary, { loading: loadingPrimary }] =
    useSetPrimaryPaymentMethodMutation()
  const [setBackup, { loading: loadingBackup }] =
    useSetBackupPaymentMethodMutation()
  const [deletePaymentMethod] = useDeletePaymentMethodMutation()

  const onPrimary = () => {
    setPrimary({
      variables: {
        pmId: paymentMethod.id,
      },
    })
      .then((res) => {
        if (res.data?.setPrimaryPaymentMethod) {
          billingStore.setOrganizationBillingInfo(
            res.data?.setPrimaryPaymentMethod
          )
        }

        setOpen(false)
      })
      .catch(console.error)
  }
  const onBackUp = () => {
    setBackup({
      variables: {
        pmId: paymentMethod.id,
      },
    })
      .then((res) => {
        if (res.data?.setBackupPaymentMethod) {
          billingStore.setOrganizationBillingInfo(
            res.data?.setBackupPaymentMethod
          )
        }
        setOpen(false)
      })
      .catch(console.error)
  }
  const onDelete = () => {
    if (paymentMethod.id) {
      deletePaymentMethod({
        variables: {
          pmId: paymentMethod.id,
        },
      })
        .then((res) => {
          if (res.data?.deletePaymentMethod) {
            billingStore.setOrganizationBillingInfo(
              res.data?.deletePaymentMethod
            )
          }
        })
        .catch(console.error)
    }

    setOpen(false)
  }
  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <div>
          <CardIcon cardTypeProps={card?.brand as CardType} />
        </div>
        <div className={styles.info}>
          <span>
            <b>{`${capitalize(card?.brand || '')} ... ${card?.last4}`}</b>
          </span>
          <span className={'s2 gray1'}>
            Expires {card?.expMonth}/
            {(card?.expYear || '').toString().substring(2, 4)}
          </span>
        </div>
        {type ? (
          <div className={styles.cardType}>
            <span className={'s2 medium'}>{type}</span>
          </div>
        ) : (
          <div />
        )}
      </div>
      {type !== 'Primary' && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <MuiTooltip
              title={
                <div>
                  <Button
                    size={BtnSize.small}
                    hoverGray
                    menuBtn
                    onClick={onPrimary}
                    disabled={loadingPrimary}
                  >
                    <span>Make Primary</span>
                    <InfoTooltip title={'Info'} />
                  </Button>
                  {type !== 'Backup Card' && (
                    <Button
                      size={BtnSize.small}
                      hoverGray
                      menuBtn
                      onClick={onBackUp}
                      disabled={loadingBackup}
                    >
                      <span>Make Backup</span>
                      <InfoTooltip title={'Info'} />
                    </Button>
                  )}
                  <DeleteBtn onClick={onDelete} noIcon />
                </div>
              }
              open={open}
              white
              placement={'bottom-end'}
            >
              <Button
                size={BtnSize.small}
                typeBtn={BtnType.secondaryGray}
                onClick={() => setOpen(true)}
              >
                <AppIcon icon={ImportIcons.more} />
              </Button>
            </MuiTooltip>
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
})
