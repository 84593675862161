import styles from './styles.module.scss'
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react'
import { CheckBox } from 'components/CheckBox/CheckBox'
import classNames from 'classnames'
import { ClassesEnum } from 'styles/classes'
import { CardDropdown } from 'components/DetailCard/CardDropdown'
import { TableStatuses } from 'components/NewTable/store/TableStore'
import { observer } from 'mobx-react-lite'
import { RequiredFields } from 'components/NewTable/types'
import { numberString } from 'src/util/functions'
import { useInView } from 'react-intersection-observer'

export interface ICardDetail {
  title?: string
  titleComponent?: ReactNode
  value: string
  status?: TableStatuses
  count: number
  percent: number
  today?: number
  hidePercent?: boolean
}

type Props<T extends RequiredFields<T>> = {
  card: ICardDetail
  noSelect?: boolean
  setInvisibleCards: Dispatch<SetStateAction<ICardDetail[]>>
  isLastVisible: boolean
  invisibleCards: ICardDetail[]
  handleSelectInvisibleCard: (card: ICardDetail) => void
  handleSelectCard: () => void
  checked: boolean
}

export const DetailCard = observer(
  <T extends RequiredFields<T>>({
    card,
    noSelect,
    setInvisibleCards,
    isLastVisible,
    invisibleCards,
    handleSelectInvisibleCard,
    handleSelectCard,
    checked,
  }: Props<T>) => {
    const { title, percent, count, today, titleComponent, value, hidePercent } =
      card

    const { ref, inView } = useInView({
      initialInView: true,
      threshold: 1,
    })

    const refCard = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      setInvisibleCards &&
        setInvisibleCards((prev) => {
          if (inView) {
            return prev.filter((prevCard) => prevCard.value !== value)
          } else {
            return [...prev, card]
          }
        })
    }, [inView])

    return (
      <button
        className={classNames(
          styles.wrapCard,
          checked && styles.checked,
          noSelect && styles.noSelect
        )}
        onClick={handleSelectCard}
        ref={ref}
      >
        <div className={styles.content} ref={refCard}>
          <div className={classNames(styles.wrapTitleRow, styles.row)}>
            {title ? (
              <span className={ClassesEnum.nowrap}>{title}</span>
            ) : (
              titleComponent
            )}
            <div className={styles.row}>
              {value !== TableStatuses.totalCount && !hidePercent && (
                <span className={'gray1'}>
                  {numberString({ val: percent, maximumFractionDigits: 1 })}%
                </span>
              )}
              {Boolean(isLastVisible && invisibleCards.length) && (
                <CardDropdown
                  options={invisibleCards}
                  cardElement={refCard.current}
                  checked={checked}
                  setSelectedOption={handleSelectInvisibleCard}
                />
              )}
            </div>
          </div>
          <div className={styles.rowBottom}>
            <div>
              <div className={styles.count}>{count}</div>
              {today !== undefined && (
                <div className={styles.today}>
                  <span className={'s2 medium gray1'}>Today</span>
                  <span className={'s2 medium ml4'}>{today}</span>
                </div>
              )}
            </div>
            {!noSelect && (
              <div className={'mb4'}>
                <CheckBox checked={checked} radio />
              </div>
            )}
          </div>
        </div>
      </button>
    )
  }
)
