import { Tabs } from 'components/Tabs/Tabs'
import { observer } from 'mobx-react-lite'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { PaymentContent } from 'src/Modals/AddNumberModal/steps/PaymentContent/PaymentContent'
import { SizedBox } from 'components/SizedBox'
import { LocalNumberAlert } from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/LocalNumberAlert'
import { TollNumbersAlert } from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/TollNumbersAlert'
import { LocalNumbersFilters } from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/LocalNumbersFilters'
import { TollNumbersFilters } from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/TollNumbersFilters'
import { NumbersContent } from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/NumbersContent'
import { ActionsChoose } from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/ActionsChoose'

export const ChooseNumber = observer(() => {
  if (addNumberStore.stepChoose === 2) {
    return <PaymentContent />
  }
  return (
    <>
      {!addNumberStore.onlyTollFree && (
        <Tabs
          tabs={['Local number', 'Toll-Free number']}
          activeTabIndex={addNumberStore.activeTabIndexChoose}
          onTabClick={(index) => addNumberStore.setActiveTabIndexChoose(index)}
        />
      )}
      {!addNumberStore.onlyTollFree && <SizedBox height={24} />}
      {addNumberStore.isLocalTab ? <LocalNumberAlert /> : <TollNumbersAlert />}
      <SizedBox height={40} />
      {addNumberStore.isLocalTab ? (
        <LocalNumbersFilters />
      ) : (
        <TollNumbersFilters />
      )}
      <NumbersContent />
      <ActionsChoose />
    </>
  )
})
