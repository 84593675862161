import { useEffect } from 'react'
import billingStore from 'store/settings/billing/billingStore'
import alertStore from 'store/alertStore'
import { useCancelDowngradeMutation } from 'src/generated/graphql'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export function useIsDowngrade() {
  const [cancelDowngradeSubscription] = useCancelDowngradeMutation()
  const handleCancelDowngrade = async () => {
    try {
      const { data } = await cancelDowngradeSubscription()
      if (data?.cancelDowngrade) {
        billingStore.setClSubscription(data?.cancelDowngrade)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    if (billingStore.clSubscription?.nextPlan) {
      alertStore.setDowngradeAlert({
        type: AlertTypeEnum.blue3,
        title: `You’ll be ${
          billingStore.isPayGoLite ? 'upgraded' : 'downgraded'
        } to “${billingStore.nextClSubscriptionTitle}” on ${
          billingStore.downgradeDate
        }. ${
          billingStore.isPayGoLite
            ? ''
            : 'All your monthly credits from previous tier will disappear.'
        }`,
        rightContent: billingStore.isPayGoLite ? undefined : (
          <button onClick={handleCancelDowngrade} className={'pointer'}>
            <span className={'white bold'}>Cancel downgrade</span>
          </button>
        ),
      })
    } else {
      alertStore.downgradeAlert && alertStore.setDowngradeAlert(null)
    }
  }, [billingStore.clSubscription?.nextPlan])
}
