import { observer } from 'mobx-react-lite'
import mainStore from 'store/mainStore'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { UnauthorizedRoutes } from 'src/routes/routes'

export const InviteExistUnAuth = observer(() => {
  const { pathname } = useLocation()
  useEffect(() => {
    mainStore.setRedirectPathAfterLogin(pathname)
  }, [pathname])
  return <Navigate to={UnauthorizedRoutes.login2} />
})
