// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qGVZ_4DqT8WOZxWGh4Oh{padding-top:4px;position:sticky;top:-1px;left:0;background:#fff;padding-bottom:24px;z-index:5;display:grid;align-items:flex-end;grid-template-columns:1fr max-content;gap:12px;overflow:auto}@media(max-width: 800px){.qGVZ_4DqT8WOZxWGh4Oh{grid-template-columns:max-content max-content;width:100vw;padding-inline:24px}}@media(max-height: 600px){.qGVZ_4DqT8WOZxWGh4Oh{width:100vw;padding-inline:24px}}`, "",{"version":3,"sources":["webpack://./src/components/NewTable/components/StickyContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,eAAA,CACA,QAAA,CACA,MAAA,CAEA,eAAA,CACA,mBAAA,CACA,SAAA,CACA,YAAA,CACA,oBAAA,CACA,qCAAA,CACA,QAAA,CACA,aAAA,CACA,yBAdF,sBAeI,6CAAA,CACA,WAAA,CACA,mBAAA,CAAA,CAEF,0BAnBF,sBAoBI,WAAA,CACA,mBAAA,CAAA","sourcesContent":[".wrapStickyContent{\n  padding-top: 4px;\n  position: sticky;\n  top: -1px;\n  left: 0;\n  //padding-right: 64px;\n  background: white;\n  padding-bottom: 24px;\n  z-index: 5;\n  display: grid;\n  align-items: flex-end;\n  grid-template-columns: 1fr max-content;\n  gap: 12px;\n  overflow: auto;\n  @media (max-width: 800px) {\n    grid-template-columns: max-content max-content;\n    width: 100vw;\n    padding-inline: 24px;\n  }\n  @media (max-height: 600px) {\n    width: 100vw;\n    padding-inline: 24px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapStickyContent": `qGVZ_4DqT8WOZxWGh4Oh`
};
export default ___CSS_LOADER_EXPORT___;
