// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rzaPik_kjAsa9qXPJjVS{align-items:center;column-gap:8px;font-size:14px;line-height:16px;overflow:hidden;display:grid;grid-template-columns:max-content 1fr}.rzaPik_kjAsa9qXPJjVS.BPir7ZboH1DSaWi7Qgkz :first-child{order:2}.Z4535vvi2FK6twa0siyw svg circle{fill:var(--primary-color-gray-1)}.Z4535vvi2FK6twa0siyw svg path{fill:#fff !important}.SQTJNHE1JsZ_whGHKqCq svg circle{fill:var(--states-color-warning-2)}.SQTJNHE1JsZ_whGHKqCq svg path{fill:#fff !important}.BUSZ1ZFQsKqb_TEp0oN9 svg circle{fill:var(--states-color-error-1)}.BUSZ1ZFQsKqb_TEp0oN9 svg path{fill:#fff !important}.n_lUjquLFZvOcY31MyYD svg circle{fill:var(--secondary-color-orange-1)}.n_lUjquLFZvOcY31MyYD svg path{fill:#fff !important}`, "",{"version":3,"sources":["webpack://./src/components/StatusContent/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,kBAAA,CACA,cAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,YAAA,CACA,qCAAA,CAEE,wDACE,OAAA,CAOF,iCACE,gCAAA,CAEF,+BACE,oBAAA,CAOF,iCACE,kCAAA,CAEF,+BACE,oBAAA,CAOF,iCACE,gCAAA,CAEF,+BACE,oBAAA,CAOF,iCACE,oCAAA,CAEF,+BACE,oBAAA","sourcesContent":["@import \"src/styles/mixin\";\n.status{\n  align-items: center;\n  column-gap: 8px;\n  font-size: 14px;\n  line-height: 16px;\n  overflow: hidden;\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  &.right{\n    & :first-child{\n      order: 2;\n    }\n  }\n\n}\n.disabled{\n  svg {\n    circle{\n      fill: var(--primary-color-gray-1);\n    }\n    path{\n      fill: white!important;\n    }\n\n  }\n}\n.archived{\n  svg {\n    circle{\n      fill: var(--states-color-warning-2);\n    }\n    path{\n      fill: white!important;\n    }\n\n  }\n}\n.failed{\n  svg {\n    circle{\n      fill: var(--states-color-error-1);\n    }\n    path{\n      fill: white!important;\n    }\n\n  }\n}\n.limited{\n  svg {\n    circle{\n      fill: var(--secondary-color-orange-1);\n    }\n    path{\n      fill: white!important;\n    }\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `rzaPik_kjAsa9qXPJjVS`,
	"right": `BPir7ZboH1DSaWi7Qgkz`,
	"disabled": `Z4535vvi2FK6twa0siyw`,
	"archived": `SQTJNHE1JsZ_whGHKqCq`,
	"failed": `BUSZ1ZFQsKqb_TEp0oN9`,
	"limited": `n_lUjquLFZvOcY31MyYD`
};
export default ___CSS_LOADER_EXPORT___;
