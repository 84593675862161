import styles from './styles.module.scss'
import { ToggleBtn } from 'components/ToggleBtn/ToggleBrn'
import { observer } from 'mobx-react-lite'
import { PlanPeriods } from 'store/settings/billing/hellpers'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import billingStore from 'store/settings/billing/billingStore'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'
import { SubscriptionCard } from 'src/Modals/UpgradePlanModal/Step1Upgrade/cards/ui/SubscriptionCard'

export const DefaultSubscriptionCard = observer(() => {
  const onContinue = async () => {
    upgradePlanStore.setActiveModalStep(1)
  }

  return (
    <SubscriptionCard
      topContent={
        <>
          {upgradePlanStore.isPayAsYouGo ? (
            <span>Pay As You Go</span>
          ) : (
            <ToggleBtn
              options={PlanPeriods}
              activeValue={upgradePlanStore.modalActivePeriod}
              onOptionClick={(opt) =>
                upgradePlanStore.setActiveModalPeriod(opt)
              }
            />
          )}
        </>
      }
      centerContent={{
        isBilledAnnually:
          upgradePlanStore.modalActivePeriod === PlanPeriodsEnum.Annual ||
          upgradePlanStore.isPayAsYouGo,
        price: upgradePlanStore.priceValue,
        descPeriodString: upgradePlanStore.isPayAsYouGo
          ? ''
          : upgradePlanStore.modalActivePeriod === PlanPeriodsEnum.Annual
          ? '/ year'
          : upgradePlanStore.modalActivePeriodString,
        additionalCreditPrice: upgradePlanStore.additionalCreditPrice,
        creditsIncluded: upgradePlanStore.creditsIncluded,
      }}
      bottomContent={
        upgradePlanStore.isCurrent && !billingStore.isTrial ? (
          <div className={styles.current}>
            <span className={'s2 medium white'}>Current</span>
          </div>
        ) : (
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onContinue}
            fullWidth
            disabled={
              upgradePlanStore.isCurrent &&
              !billingStore.isTrial &&
              !billingStore.canceledSubscription
            }
          >
            Continue
          </Button>
        )
      }
    />
  )
})
