import { observer } from 'mobx-react-lite'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import {
  TollFreeVerificationProfile,
  useAdminInitTollFreeVerificationTermsMutation,
  useInitTollFreeVerificationTermsMutation,
} from 'src/generated/graphql'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { PropsTollFreeStep } from 'src/routes/settings/compliance/TollFreeVerification/types'
import { TollFreeBackBtn } from 'src/routes/settings/compliance/TollFreeVerification/steps/TollFreeBackBtn'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { SizedBox } from 'components/SizedBox'
import React from 'react'
import { appLinks } from 'src/util/links'

export const Terms = observer(({ onSuccess }: PropsTollFreeStep) => {
  const { setAgreeTerms, isAgreeTerms, continueText, adminOrgId } =
    tollFreeVerificationStore

  const [initTollFreeVerificationTerms, { loading }] =
    useInitTollFreeVerificationTermsMutation()
  const [adminInitTollFreeVerificationTerms, { loading: loadingAdmin }] =
    useAdminInitTollFreeVerificationTermsMutation()

  const onContinue = async () => {
    try {
      let profile: TollFreeVerificationProfile | null | undefined
      if (adminOrgId) {
        const { data } = await adminInitTollFreeVerificationTerms({
          variables: {
            organizationId: adminOrgId,
            shouldSend: true,
          },
        })
        profile = data?.adminInitTollFreeVerificationTerms
      } else {
        const { data } = await initTollFreeVerificationTerms()
        profile = data?.initTollFreeVerificationTerms
      }

      profile && onSuccess(profile, true)
    } catch (e) {
      console.error(e)
    }
  }

  const onLink: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation()
  }

  return (
    <>
      <InputCheckbox
        marginLeft={-8}
        width={'calc(100% + 16px)'}
        noBordered
        label={
          <div className={'col4'}>
            <p className={'par2 medium'}>I agree to the Terms of Service</p>
            <span className={'gray1'}>
              I certify that the associated Business Profile is the originator
              of the phone calls and certify that I will participate in
              traceback efforts, including those initiated by the{' '}
              <a
                href={appLinks.termsTollFree}
                target={'_blank'}
                className={'s1'}
                rel="noreferrer"
                onClick={onLink}
              >
                Secure Telephony Identity Policy
              </a>
            </span>
          </div>
        }
        checked={isAgreeTerms}
        onChecked={() => setAgreeTerms(!isAgreeTerms)}
      />
      <SizedBox height={40} />
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={!isAgreeTerms}
          loading={loading || loadingAdmin}
        >
          {continueText}
        </Button>
        <TollFreeBackBtn />
      </div>
    </>
  )
})
