// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sfQ9Wyhav2a1ACd3Jdni{display:grid;grid-template-columns:var(--nav-bar-width) 1fr;overflow:auto;height:100vh}.F8nQOrlgQ2nyHQeS9s8V{display:grid;grid-template-rows:76px var(--page-height)}`, "",{"version":3,"sources":["webpack://./src/layout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,8CAAA,CACA,aAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,0CAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: var(--nav-bar-width) 1fr;\n  overflow: auto;\n  height: 100vh;\n}\n\n.pageWrap{\n  display: grid;\n  grid-template-rows: 76px var(--page-height);\n}\n.page{\n  //@media (max-width: 800px) {\n  //  max-height: calc(100vh - 76px);\n  //  //overflow-y: scroll;\n  //}\n  //@media (max-height: 600px) {\n  //  max-height: calc(100vh - 76px);\n  //  //overflow-y: scroll;\n  //}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `sfQ9Wyhav2a1ACd3Jdni`,
	"pageWrap": `F8nQOrlgQ2nyHQeS9s8V`
};
export default ___CSS_LOADER_EXPORT___;
