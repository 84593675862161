// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfb6_mI5q4BNH_mZis_N{position:relative;overflow:hidden;height:calc(100vh - 280px)}.LAudEgVoYI10aAfaof1A{position:absolute;width:100%;height:calc(100vh - 280px)}.rZzHxRSKWPreRRoy81nt{opacity:.3;pointer-events:none}.t6zfAfaiM8VMWDVQfEQp{border-radius:20px;padding:40px;z-index:1000;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:400px;background:var(--primary-color-white);box-shadow:var(--shadow-1);display:flex;flex-direction:column;align-items:center;justify-content:center}.t6zfAfaiM8VMWDVQfEQp h3{margin-top:20px;margin-bottom:8px}.t6zfAfaiM8VMWDVQfEQp p{text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/CommingSoon/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,eAAA,CACA,0BAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,0BAAA,CAGF,sBACE,UAAA,CACA,mBAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,YAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,WAAA,CACA,qCAAA,CACA,0BAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,yBACE,eAAA,CACA,iBAAA,CAEF,wBACE,iBAAA","sourcesContent":[".wrap{\n  position: relative;\n  overflow: hidden;\n  height: calc(100vh - 280px);\n}\n\n.wrapCard{\n  position: absolute;\n  width: 100%;\n  height: calc(100vh - 280px);\n}\n\n.wrapChild{\n  opacity: 0.3;\n  pointer-events: none;\n}\n\n.card{\n  border-radius: 20px;\n  padding: 40px;\n  z-index: 1000;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 400px;\n  background: var(--primary-color-white);\n  box-shadow: var(--shadow-1);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  h3{\n    margin-top: 20px;\n    margin-bottom: 8px;\n  }\n  p{\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `mfb6_mI5q4BNH_mZis_N`,
	"wrapCard": `LAudEgVoYI10aAfaof1A`,
	"wrapChild": `rZzHxRSKWPreRRoy81nt`,
	"card": `t6zfAfaiM8VMWDVQfEQp`
};
export default ___CSS_LOADER_EXPORT___;
