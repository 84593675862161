import { observer } from 'mobx-react-lite'
import { WrapPageSuperAdmin } from 'src/routes/superAdmin/components/WrapPageSuperAdmin/WrapPageSuperAdmin'
import styles from './styles.module.scss'
import * as React from 'react'
import { OrganizationsUsers } from 'src/routes/superAdmin/routes/organization/OrganizationUsers/OrganizationsUsers'
import { useOrganizationPageContext } from 'src/routes/superAdmin/routes/organization/OrganizationPage'
import { OrganizationGeneralInfo } from 'src/routes/superAdmin/routes/organization/info/OrganizationGeneralInfo'
import { ResetPasswordModal } from 'src/routes/superAdmin/modals/ResetPasswordModal'
import { AddCreditsModal } from 'src/routes/superAdmin/modals/AddCreditsModal'
import { BanOrganizationModal } from 'src/routes/superAdmin/modals/BanOrganizationModal'
import { getOrganizationActions } from 'src/routes/superAdmin/hooks/getOrganizationActions'
import { StripeInfo } from 'src/routes/superAdmin/routes/organization/info/StripeInfo'
import { TollFreeInfo } from 'src/routes/superAdmin/routes/organization/info/TollFreeInfo'
import { AddCallerIdModal } from 'src/routes/superAdmin/modals/AddCallerIdModal'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { FC } from 'react'

export const OrganizationPageContent = observer(() => {
  const { organization } = useOrganizationPageContext()

  const actions = getOrganizationActions(organization)
  if (!organization) {
    return <></>
  }

  const rowActions = actions.slice(0, 2)
  const moreActions = actions.slice(2)

  return (
    <>
      <AddCreditsModal />
      <AddCallerIdModal />
      <BanOrganizationModal />
      <WrapPageSuperAdmin
        title={organization.companyName || ''}
        actions={
          <>
            {rowActions.map((Action, index) => (
              <Action key={index} medium />
            ))}
            {!!moreActions?.length && (
              <MoreBtn
                menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
                tooltip={'More'}
                medium
              />
            )}
          </>
        }
      >
        <ResetPasswordModal />
        <div className={styles.content}>
          <div className={styles.info}>
            <OrganizationGeneralInfo />
            <StripeInfo />
            <TollFreeInfo />
          </div>

          <OrganizationsUsers />
        </div>
      </WrapPageSuperAdmin>
    </>
  )
})
