import { ToggleBtn } from 'components/ToggleBtn/ToggleBrn'
import { observer } from 'mobx-react-lite'
import { PlanPeriods } from 'store/settings/billing/hellpers'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import user from 'store/user/user'
import { usePlanSelectionRegistrationStepMutation } from 'src/generated/graphql'
import migrationStore from 'store/migration/migrationStore'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'
import { SubscriptionCard } from 'src/Modals/UpgradePlanModal/Step1Upgrade/cards/ui/SubscriptionCard'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { numberString } from 'src/util/functions'

export const BonusExpiredCreditsMigrationSubscriptionCard = observer(() => {
  const [planSelectionRegistrationStep, { loading }] =
    usePlanSelectionRegistrationStepMutation()
  const onContinue = async () => {
    try {
      const { data } = await planSelectionRegistrationStep({
        variables: {
          price: migrationStore.annualSimplePrice?.planName,
          saveCredits: true,
        },
      })
      if (data?.planSelectionRegistrationStep) {
        user.setMember(data.planSelectionRegistrationStep)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <SubscriptionCard
      topContent={
        <>
          <span className={'flex'}>
            Save My Expired Credits{' '}
            <InfoTooltip
              title={
                <div className={'col4'}>
                  <span className={'white'}>
                    This offer is valid only for 1 year
                  </span>
                  <span className={'s2 gray1'}>
                    Next year you’ll be moved to the standard plan
                  </span>
                </div>
              }
            />
          </span>
          <ToggleBtn
            options={[PlanPeriods[1]]}
            activeValue={PlanPeriodsEnum.Annual}
          />
        </>
      }
      centerContent={{
        isBilledAnnually: true,
        noAdditionalCreditsString: true,
        price: migrationStore.bonusPriceValue,
        priceThrough: migrationStore.bonusPriceValueThrough,
        additionalCreditPrice: migrationStore.bonusAdditionalCreditPrice,
        creditsIncluded: migrationStore.allBonusCredits,
        descTooltip: (
          <InfoTooltip
            title={
              <div className={'col8'}>
                <div>
                  <span className={'white'}>
                    {numberString({
                      val: migrationStore.oldCreditsForPurchase,
                    })}{' '}
                    credits
                  </span>
                  <br />
                  <span className={'s2 gray1'}>Expired</span>
                </div>
                <div>
                  <span className={'white'}>
                    {numberString({
                      val: migrationStore.oldCreditsFree,
                    })}{' '}
                    credits
                  </span>
                  <br />
                  <span className={'s2 gray1'}>Unexpired</span>
                </div>
                <div>
                  <span className={'white'}>
                    {numberString({
                      val:
                        migrationStore.annualSimplePrice?.creditsIncluded || 0,
                    })}{' '}
                    credits
                  </span>
                  <br />
                  <span className={'s2 gray1'}>Plan</span>
                </div>
              </div>
            }
          />
        ),
      }}
      bottomContent={
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onContinue}
          fullWidth
          loading={loading}
        >
          Continue
        </Button>
      }
    />
  )
})
