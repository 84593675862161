// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZRRand1zn2IUtUPAZQR7{background-color:var(--primary-color-gray-4);border-radius:16px;display:flex;justify-content:center;align-items:center;height:384px;margin:0 32px}.lQph9g4xSARfccqvsXDv{margin-right:8px;padding-left:32px;height:328px;overflow:auto;margin-top:24px;padding-bottom:24px;background-color:var(--primary-color-white);display:flex;gap:8px;flex-wrap:wrap;align-content:flex-start}.UgdXZzUJSHvWaJPR02wd{width:100%;padding:16px 32px 24px 32px;display:grid;grid-template-columns:max-content max-content 1fr max-content;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/AddMediaContent/MyImagesContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,kBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,aAAA,CAGF,sBACE,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,aAAA,CACA,eAAA,CACA,mBAAA,CAGA,2CAAA,CACA,YAAA,CACA,OAAA,CACA,cAAA,CACA,wBAAA,CAEF,sBACE,UAAA,CACA,2BAAA,CACA,YAAA,CACA,6DAAA,CACA,eAAA","sourcesContent":[".wrapEmptyContent{\n  background-color: var(--primary-color-gray-4);\n  border-radius: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 384px;\n  margin: 0 32px;\n}\n\n.myImagesWrap{\n  margin-right: 8px;\n  padding-left: 32px;\n  height: 328px;\n  overflow: auto;\n  margin-top: 24px;\n  padding-bottom: 24px;\n  //width: 600px;\n  //height: 100%;\n  background-color: var(--primary-color-white);\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n  align-content: flex-start;\n}\n.actions{\n  width: 100%;\n  padding: 16px 32px 24px 32px;\n  display: grid;\n  grid-template-columns: max-content max-content 1fr max-content;\n  column-gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapEmptyContent": `ZRRand1zn2IUtUPAZQR7`,
	"myImagesWrap": `lQph9g4xSARfccqvsXDv`,
	"actions": `UgdXZzUJSHvWaJPR02wd`
};
export default ___CSS_LOADER_EXPORT___;
