import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { Tabs } from 'components/Tabs/Tabs'
import { useEffect, useState } from 'react'
import { MyTemplatesTab } from './MyTemplatesTab/MyTemplatesTab'
import { SuggestedTab } from './SuggestedTab/SuggestedTab'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import messageTemplateStore from 'src/widgets/MessageTemplate/store/MessageTemplateStore'
import { NewLabel } from 'components/NewLabel/NewLabel'

export const MessageTemplatesModal = observer(
  ({ store }: { store: CreateStoreTypes }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    useEffect(() => {
      setActiveTabIndex(0)
    }, [messageTemplateStore.open])
    return (
      <Modal
        open={messageTemplateStore.open}
        onClose={messageTemplateStore.onClose}
        minWidth={528}
        title={
          <div className={'row8'}>
            Message templates <NewLabel />
          </div>
        }
        centered2
        triggerHeight={`${messageTemplateStore.myTemplatesMap.size}_${activeTabIndex}`}
      >
        <div className={styles.wrap}>
          <div className={styles.wrapTabs}>
            <Tabs
              tabs={['My templates', 'Suggested']}
              activeTabIndex={activeTabIndex}
              onTabClick={setActiveTabIndex}
              mb={24}
            />
          </div>

          {activeTabIndex === 0 && <MyTemplatesTab store={store} />}
          {activeTabIndex === 1 && <SuggestedTab store={store} />}
        </div>
      </Modal>
    )
  }
)
