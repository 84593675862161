import { observer } from 'mobx-react-lite'
import { IOrganization } from 'src/types/IOrganization'
import superAdminStore from 'store/superAdmin/superAdminStore'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { useOrganizationsListQuery } from 'src/generated/graphql'
import { getOrganizationActions } from 'src/routes/superAdmin/hooks/getOrganizationActions'
import { Filters } from 'src/routes/superAdmin/routes/organizations/OrganizationTable/Filters'
import { organizationNoSortsColumns } from 'components/NewTable/columns/superAdmin/organizationColumns'
import { AppElements } from 'src/enums/appElements'

export const OrganizationsTable = observer(() => {
  const navigate = useNavigate()
  const tableStore = superAdminStore.organizationTableStore
  const options = useTablePaginationOptions(tableStore)
  const [firstLoading, setFirstLoading] = useState(true)
  const [refetchLoading, setRefetchLoading] = useState(true)

  options.variables = { ...options.variables }

  const { data, loading, error, refetch } = useOrganizationsListQuery({
    ...options,
    variables: {
      ...options.variables,
      search: {
        searchPattern: options.variables.searchPattern,
        organizationStatuses: superAdminStore.organizationStatuses,
        billingStatuses: superAdminStore.billingStatuses,
      },
    },
  })

  useEffect(() => {
    if (data) {
      setFirstLoading(false)
      setRefetchLoading(false)
      superAdminStore.setOrganizationListData(data)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setFirstLoading(false)
      setRefetchLoading(false)
    }
  }, [error])

  useEffect(() => {
    if (tableStore.refreshTrigger) {
      setRefetchLoading(true)
      refetch()
        .catch((e) => console.error(e))
        .finally(() => {
          setRefetchLoading(false)
        })
    }
  }, [tableStore.refreshTrigger])
  const onRowClick = (id: string) => {
    navigate(String(id))
  }
  return (
    <NewTable<IOrganization>
      tableStore={tableStore}
      rows={superAdminStore.organizations.map((organization) => ({
        ...organization,
        actions: getOrganizationActions(organization),
      }))}
      columns={tableStore.visibleColumns}
      leftStickyContent={<Filters />}
      showVisibleActionsCount={2}
      withCalcMaxHeight
      firstPaddingLeft16
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
      noSortsColumns={organizationNoSortsColumns}
      withSearch
      heightSticky91
      onRowClick={onRowClick}
      emptyContent={
        !superAdminStore.organizations.length && tableStore.search ? (
          <EmptyStateSearch element={AppElements.Organizations} />
        ) : undefined
      }
    />
  )
})
