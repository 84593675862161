// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WntkYc91zsrfIXJKvDkE{display:flex;justify-content:center;background:var(--primary-color-gray-4)}.N0Bg_mp3Dw1vW14Y_Rx8{margin-top:64px;margin-bottom:64px;width:600px;height:fit-content;padding:32px;background:var(--primary-color-white);box-shadow:var(--shadow-2);border-radius:20px}.i3Yzzxqd8g8WcX1nqjkf{height:40px;position:fixed;top:64px;right:64px}`, "",{"version":3,"sources":["webpack://./src/components/Page/CompanyPage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,sCAAA,CAEF,sBACE,eAAA,CACA,kBAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CAEA,qCAAA,CACA,0BAAA,CACA,kBAAA,CAEF,sBACE,WAAA,CACA,cAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".wrap{\n  display: flex;\n  justify-content: center;\n  background: var(--primary-color-gray-4);\n}\n.wrapCard{\n  margin-top: 64px;\n  margin-bottom: 64px;\n  width: 600px;\n  height: fit-content;\n  padding: 32px;\n\n  background: var(--primary-color-white);\n  box-shadow: var(--shadow-2);\n  border-radius: 20px;\n}\n.wrapCloseBtn{\n  height: 40px;\n  position: fixed;\n  top: 64px;\n  right: 64px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `WntkYc91zsrfIXJKvDkE`,
	"wrapCard": `N0Bg_mp3Dw1vW14Y_Rx8`,
	"wrapCloseBtn": `i3Yzzxqd8g8WcX1nqjkf`
};
export default ___CSS_LOADER_EXPORT___;
