import classNames from 'classnames'
import styles from 'components/NavBar/styles.module.scss'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import { useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/icons/logo.svg'
import { RoutesEnum } from 'src/routes/routes'
import { ReactComponent as Settings } from 'assets/icons/16px/Settings-outline.svg'

export const DashboardNav = () => {
  const location = useLocation()
  const isActive = location.pathname.includes(RoutesEnum.dashboard)
  return (
    <div className={classNames(styles.dashboard, isActive && styles.active)}>
      <LinkWrapper to={RoutesEnum.dashboard}>
        <div className={'flex'}>
          <div className={styles.leftIcon}>
            <Logo />
          </div>
          <span>Dashboard</span>
        </div>
      </LinkWrapper>

      <LinkWrapper to={`${RoutesEnum.settings}/${RoutesEnum.account}`}>
        <div className={styles.rightIcon}>
          <Settings />
        </div>
      </LinkWrapper>
    </div>
  )
}
