import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { ITrigger } from 'src/types/ITrigger'
import { useTriggerFunctions } from 'src/routes/main/triggers/hooks/useTriggerFunctions'

export const TriggerDeleteBtn = (props: ActionBtnProps<ITrigger>) => {
  const { row, rows } = props
  const { onDelete } = useTriggerFunctions(row, rows)
  return <TableBtn {...props} action={ActionsEnum.delete} onAction={onDelete} />
}
