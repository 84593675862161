import { CopyField } from 'components/CopyField/CopyField'
import { CopyBtn } from 'components/Button/CopyBtn'
import { Maybe } from 'graphql/jsutils/Maybe'

export const CopyWebhook = ({
  webhookLink,
}: {
  webhookLink?: Maybe<string>
}) => {
  return (
    <div style={{ width: 270 }} className={'p8'}>
      <span className={'bold'}>Copy webhook</span>
      <div className={'mt16 mb16'}>
        <CopyField link={webhookLink} />
      </div>
      <CopyBtn content={webhookLink || ''} linkIcon textBtn={'Copy link'} />
    </div>
  )
}
