// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DAbtAbeGQ4HAyBt18Gjh{border:1px solid var(--primary-color-gray-3);border-radius:8px;background-color:var(--primary-color-gray-4);height:40px;display:flex;align-items:center;padding:12px}`, "",{"version":3,"sources":["webpack://./src/components/CopyField/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,iBAAA,CACA,4CAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,YAAA","sourcesContent":[".wrap{\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 8px;\n  background-color: var(--primary-color-gray-4);\n  height: 40px;\n  display: flex;\n  align-items: center;\n  padding: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `DAbtAbeGQ4HAyBt18Gjh`
};
export default ___CSS_LOADER_EXPORT___;
