// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uDlBJt8n5LlbfoGrLjBE{margin:8px 0 0 0;display:grid;grid-template-columns:1fr 100px}.REPjmeFyLnnEhzhEG36J{display:flex;align-items:center;justify-content:right;column-gap:8px}`, "",{"version":3,"sources":["webpack://./src/routes/main/campaigns/create/createForm/HeadCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA,CACA,+BAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,cAAA","sourcesContent":[".inputWrap{\n  margin: 8px 0 0 0;\n  display: grid;\n  grid-template-columns: 1fr 100px;\n}\n.switch{\n  display: flex;\n  align-items: center;\n  justify-content: right;\n  column-gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrap": `uDlBJt8n5LlbfoGrLjBE`,
	"switch": `REPjmeFyLnnEhzhEG36J`
};
export default ___CSS_LOADER_EXPORT___;
