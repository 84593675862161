import styles from './styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { Status } from 'src/generated/graphql'
import { Switch } from 'components/Switch/Switch'
import { SizedBox } from 'components/SizedBox'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { SentToDropDown } from 'components/SendToDropDown/SentToDropDown'
import { Card } from 'components/Card/Card'
import React from 'react'
import { observer } from 'mobx-react-lite'
import createCampaignStore from 'store/campaigns/createCampaignStore'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { useElementName } from 'src/hooks/useElementName'

export const HeadCard = observer(() => {
  const {
    edit,
    name,
    setName,
    status,
    activeCampaign,
    setActiveCampaign,
    dropdownContactListsStore,
    reEnrol,
    toggleReEnrol,
  } = createCampaignStore
  const { error, onBlur, onFocus } = useElementName(createCampaignStore)
  return (
    <Card width={600}>
      <span className={'gray1'}>{`${edit ? 'Edit' : 'New'} campaign`}</span>
      <div className={styles.inputWrap}>
        <InputText
          id={'name'}
          placeholder={'Campaign name'}
          withoutBorder
          header
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
          autoComplete={'off'}
          withoutClear
          // focusOnMount={true}
          onFocus={onFocus}
          onBlur={onBlur}
          error={error}
        />
        <div className={styles.switch}>
          <span>{activeCampaign ? Status.Active : Status.Disabled}</span>
          <Switch
            active={activeCampaign}
            onChange={setActiveCampaign}
            tabIndex={-1}
          />
        </div>
      </div>
      {edit && status === Status.Draft && (
        <>
          <SizedBox height={12} />
          <StatusContent status={status} />
        </>
      )}

      <SizedBox height={24} />
      <SentToDropDown
        noKeyword
        noWebForm
        label={'Enroll'}
        labelTooltip={
          'Contacts that meet the criteria will be enrolled into this campaign.'
        }
        // tabIndex={5}
        selectedOptions={dropdownContactListsStore.selectedOptions}
        onMultiSelect={(options) =>
          dropdownContactListsStore.onSelectOptions(options)
        }
        onDeleteTag={(id) => dropdownContactListsStore.onDeleteTag(id)}
      />
      <SizedBox height={12} />
      <InputCheckbox
        label={'Allow contacts to re-enter this scenario after completion'}
        checked={reEnrol}
        onChecked={toggleReEnrol}
        noBordered3
      />
    </Card>
  )
})
