import { IStatisticMessage } from 'src/types/IDetailMessage'
import dayjs from 'lib/dayjs'
import { ContentType, FailReasonType, Status } from 'src/generated/graphql'
import { appLinks } from 'src/util/links'
import { ColumnType } from 'components/NewTable/types'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { TableStatuses } from 'components/NewTable/store/TableStore'
import { numberString, secondToMinutes } from 'src/util/functions'

const reasonColumn = (type: ContentType): ColumnType<IStatisticMessage> => ({
  id: 'state.reason',
  label: 'Failed Reason',
  minWidth: 400,
  format: (value, row) => <FailedReasonContent type={type} row={row} />,
})

const totalStatusColumn = (
  type: ContentType
): ColumnType<IStatisticMessage> => ({
  id: 'totalStatus',
  label: 'Status',
  minWidth: 400,
  format: (value, row) =>
    row.state?.simpleStatus === Status.Failed ? (
      <FailedReasonContent type={type} row={row} />
    ) : (
      <StatusContent
        status={
          row.state?.simpleStatus === Status.Success
            ? (row.state?.status as Status)
            : (row.state?.simpleStatus as Status)
        }
      />
    ),
})

const voiceTotalStatusColumn = (
  type: ContentType,
  label?: string
): ColumnType<IStatisticMessage> => ({
  id: 'extStatusLabel',
  label: label || 'Status',
  minWidth: 400,
  format: (value, row) =>
    row.state?.simpleStatus === Status.Failed ? (
      <FailedReasonContent type={type} row={row} />
    ) : (
      <StatusContent
        status={row.state?.simpleStatus as Status}
        text={value as string}
      />
    ),
})

const columns: { [key: string]: ColumnType<IStatisticMessage> } = {
  timeToSend: {
    id: 'state.timeToSend',
    label: 'Send time',
    // minWidth: 249,
    format: (value, { state }) =>
      state?.timeToSend
        ? dayjs(String(state?.timeToSend)).format('MMM DD, YYYY - h:mm A')
        : '',
  },
  contactName: {
    id: 'state.contactName',
    label: 'Name',
    format: (value, { state }) => state?.contactName,
    // minWidth: 170,
  },
  toNumber: {
    id: 'state.toNumber',
    label: 'Phone',
    format: (value, { state }) => state?.toNumber,
  },
  extStatusLabel: {
    id: 'extStatusLabel',
    label: 'Result',
    // minWidth: 170,
    format: (value, row) => row.extStatusLabel || '',
  },
  result: {
    id: 'state.simpleStatus',
    label: 'Result',
    // minWidth: 170,
    format: (value, { state }) => (
      <StatusContent status={state?.simpleStatus as Status} />
    ),
  },
  duration: {
    id: 'duration',
    label: 'Duration',
    // minWidth: 170,
    format: (value) => <>{value && secondToMinutes(value as number)}</>,
  },
  clicks: {
    id: 'clicks',
    label: 'Clicks',
    format: (value, { uniqueClicks, totalShortUrl }) =>
      totalShortUrl ? (
        <>{`${numberString({ val: uniqueClicks })} / ${numberString({
          val: totalShortUrl,
        })}`}</>
      ) : (
        ''
      ),
  },
}

export const messageStatisticsColumns = ({
  type = ContentType.Sms,
  status,
  withShortStatistic,
}: {
  type: ContentType
  status: TableStatuses
  withShortStatistic?: boolean
}): ColumnType<IStatisticMessage>[] => {
  if (type === ContentType.Sms || type == ContentType.Ringless) {
    if (status === TableStatuses.totalCount) {
      return [
        columns['timeToSend'],
        columns['contactName'],
        columns['toNumber'],
        withShortStatistic && type === ContentType.Sms && columns['clicks'],
        columns['result'],
        totalStatusColumn(type),
      ].filter((column) => Boolean(column)) as ColumnType<IStatisticMessage>[]
    }
    if (status === TableStatuses.failedCount) {
      return [
        columns['timeToSend'],
        columns['contactName'],
        columns['toNumber'],
        reasonColumn(type),
      ]
    }
    return [
      columns['timeToSend'],
      columns['contactName'],
      columns['toNumber'],
      withShortStatistic && type === ContentType.Sms && columns['clicks'],
      columns['result'],
    ].filter((column) => Boolean(column)) as ColumnType<IStatisticMessage>[]
  }
  if (type === ContentType.Voice) {
    if (status === TableStatuses.totalCount) {
      return [
        columns['timeToSend'],
        columns['contactName'],
        columns['toNumber'],
        columns['result'],
        columns['duration'],
        voiceTotalStatusColumn(type),
      ]
    }
    if (status === TableStatuses.successCount) {
      return [
        columns['timeToSend'],
        columns['contactName'],
        columns['toNumber'],
        columns['duration'],
        voiceTotalStatusColumn(type),
      ]
    }
    if (status === TableStatuses.unsubscribedCount) {
      return [
        columns['timeToSend'],
        columns['contactName'],
        columns['toNumber'],
        columns['duration'],
        columns['simpleStatus'],
      ]
    }
    if (status === TableStatuses.failedCount) {
      return [
        columns['timeToSend'],
        columns['contactName'],
        columns['toNumber'],
        columns['duration'],
        reasonColumn(type),
      ]
    }
    return [
      columns['timeToSend'],
      columns['contactName'],
      columns['toNumber'],
      columns['simpleStatus'],
    ]
  }
  return [
    columns['timeToSend'],
    columns['contactName'],
    columns['toNumber'],
    reasonColumn(type),
  ]
}

const FailedReasonContent = ({
  row,
  type,
}: {
  row: IStatisticMessage
  type: ContentType
}) =>
  row.state?.reason ? (
    <>
      {row.state?.failReasonType === FailReasonType.InsufficientFunds ||
      String(row.state?.reason).includes('Skipped - Not enough credits') ? (
        <>
          Skipped - Not enough credits (
          <button
            className={'s1 link'}
            onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
            id={'message_statistic_upgrade_plan'}
          >
            Upgrade
          </button>
          )
        </>
      ) : (
        <>
          {row.state?.reason} (
          <a
            href={
              type === ContentType.Sms
                ? appLinks.smsErrorCodes
                : type === ContentType.Voice
                ? appLinks.voiceErrorCodes
                : appLinks.ringlessErrorCodes
            }
            target={'_blank'}
            rel="noreferrer"
            className={'s1 regular'}
          >
            learn more
          </a>
          )
        </>
      )}
    </>
  ) : (
    <></>
  )

export const messageStatisticsNoSortsColumns = ['clicks', 'extStatusLabel']
