import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { ITrigger } from 'src/types/ITrigger'
import { TableStore } from 'components/NewTable/store/TableStore'
import { triggersTableColumns } from 'components/NewTable/columns/triggersTableColumns'
import {
  AccountIntegrationType,
  CustomField,
  GetIntegrationCustomFieldsQuery,
  GetTriggersQuery,
  SpecificField,
  StandardField,
} from 'src/generated/graphql'
import { transformTrigger } from 'store/triggers/functions'
import { transformCustomFieldsFromBack } from 'store/contacts/customFields/functions'
import { ICustomField } from 'src/types/ICustomField'

class TriggersStore {
  tableStore: TableStore<ITrigger>
  constructor() {
    this.tableStore = new TableStore({
      orderBy: 'createdAt',
      columns: triggersTableColumns,
      defaultHiddenColumn: [
        'messagesStat.pending',
        'messagesStat.unsubscribed',
      ],
      tableName: 'TriggersTableColumns',
    })
    makeAutoObservable(this)
  }
  triggersMap: Map<string, ITrigger> = new Map()

  detailModal: ITrigger | null = null

  createTriggerId = ''
  integrationCustomFieldsMap: Map<AccountIntegrationType, Array<ICustomField>> =
    new Map()
  integrationPhoneFieldsMap: Map<AccountIntegrationType, Array<ICustomField>> =
    new Map()

  get hubspotCustomFieldsMap() {
    const fields: ICustomField[] =
      this.integrationCustomFieldsMap.get(AccountIntegrationType.HubSpot) || []
    return new Map(fields.map((field) => [field.key, field]))
  }
  get keapFieldsMap() {
    const fields: ICustomField[] =
      this.integrationCustomFieldsMap.get(AccountIntegrationType.Keap) || []
    return new Map(fields.map((field) => [field.key, field]))
  }
  get activeCampaignFieldsMap() {
    const fields: ICustomField[] =
      this.integrationCustomFieldsMap.get(
        AccountIntegrationType.ActiveCampaign
      ) || []
    return new Map(fields.map((field) => [field.key, field]))
  }

  get triggers() {
    return Array.from(this.triggersMap.values())
  }

  get openDetailModal() {
    return !!this.detailModal
  }

  updateTrigger(trigger: ITrigger) {
    this.triggersMap.set(trigger.id, trigger)
    this.tableStore.setCheckedRows(
      this.tableStore.checkedRows.map((row) =>
        row.id === trigger.id ? trigger : row
      )
    )
  }
  deleteTrigger(id: string) {
    this.triggersMap.delete(id)
  }
  deleteTriggers(ids: string[]) {
    ids.forEach((id) => {
      this.triggersMap.delete(id)
    })
    this.tableStore.setCheckedRows(
      this.tableStore.checkedRows.filter((trigger) => !ids.includes(trigger.id))
    )
    this.tableStore.onRefresh()
  }
  duplicate(trigger: ITrigger) {
    const newId = nanoid()
    this.triggersMap.set(newId, { ...trigger, id: newId })
  }
  setTrigger(trigger: ITrigger) {
    runInAction(() => {
      this.triggersMap.set(trigger.id, trigger)
    })
  }
  setCreateTriggerId(id: string) {
    this.createTriggerId = id
  }
  setData(data?: GetTriggersQuery) {
    if (data?.getAllTriggers) {
      if (data?.getAllTriggers) {
        this.tableStore.setTotal(data.getAllTriggers.total || 0)
        this.triggersMap.clear()
        data?.getAllTriggers?.content?.map((trigger) => {
          if (trigger) {
            const newTrigger = transformTrigger(trigger)
            this.triggersMap.set(newTrigger.id, newTrigger)
          }
        })
      }
    }
  }

  setIntegrationCustomFieldsData(data: GetIntegrationCustomFieldsQuery) {
    data.getIntegrationCustomFields?.forEach((integrationCustomField) => {
      const fields: Partial<CustomField | StandardField | SpecificField>[] = []
      const backPhoneFields: Partial<
        CustomField | StandardField | SpecificField
      >[] = []

      integrationCustomField?.standardFields?.forEach((field) => {
        if (field) {
          fields.push(field)
        }
      })
      integrationCustomField?.specificFields?.forEach((field) => {
        if (field) {
          fields.push(field)
        }
      })
      integrationCustomField?.phoneFields?.forEach((field) => {
        if (field) {
          backPhoneFields.push(field)
        }
      })
      const customFields: ICustomField[] = transformCustomFieldsFromBack(fields)
      const phoneFields: ICustomField[] =
        transformCustomFieldsFromBack(backPhoneFields)
      if (integrationCustomField?.integrationType && customFields.length) {
        this.integrationCustomFieldsMap.set(
          integrationCustomField.integrationType,
          customFields
        )
      }
      if (integrationCustomField?.integrationType && phoneFields.length) {
        this.integrationPhoneFieldsMap.set(
          integrationCustomField.integrationType,
          phoneFields
        )
      }
    })
  }
  getIntegrationPersonalizeNameByKey(
    accountIntegrationType: AccountIntegrationType,
    key: string
  ) {
    return this.integrationCustomFieldsMap
      .get(accountIntegrationType)
      ?.find((field) => field.key === key)?.name
  }
  setDetailModal(row: ITrigger | null) {
    this.detailModal = row
  }
}

export default new TriggersStore()
