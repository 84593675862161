import { makeAutoObservable } from 'mobx'
import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import { webFormDetailColumns } from 'components/NewTable/columns/webForms/webFormDetailColumns'
import {
  ContactStatisticsByUuidResponse,
  GetWebFormContactsQuery,
} from 'src/generated/graphql'
import { transformContactLightResponse } from 'store/contacts/functions'
import { IContact } from 'src/types/IContact'
import { IWebForm } from 'src/types/IWebForm'

export class WebFormDetailStore {
  tableStore: TableStore<IContact>
  constructor() {
    this.tableStore = new TableStore({
      orderBy: 'createdAt',
      columns: webFormDetailColumns,
      tableName: 'WebFormsDetailTable',
      status: TableStatuses.subscribedCount,
      withDataRange: true,
    })
    makeAutoObservable(this)
  }
  webForm: IWebForm | null = null
  contacts: IContact[] = []
  contactStatistics: ContactStatisticsByUuidResponse | null = null

  get totalCharged() {
    return this.contactStatistics?.chargeStatistics?.totalCharged || 0
  }

  setWebForm(webForm: IWebForm) {
    this.webForm = webForm
  }

  setWebFormContactsData(data: GetWebFormContactsQuery) {
    this.tableStore.setTotal(data.getWebFormContacts?.total || 0)
    const contacts: IContact[] = []
    data.getWebFormContacts?.content?.forEach((contact) => {
      if (contact) {
        contacts.push(transformContactLightResponse(contact))
      }
    })
    this.contacts = contacts
  }

  setContactStatistics(
    contactStatistics: ContactStatisticsByUuidResponse | null
  ) {
    this.contactStatistics = contactStatistics
  }
}
