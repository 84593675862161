import styles from './styles.module.scss'
import importContactStore from 'store/contacts/importContactStore'
import { ReactComponent as Import } from 'icons/16px/Import.svg'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as HistoryIcon } from 'icons/16px/History.svg'
import { ClickAwayListener } from '@mui/material'
import classNames from 'classnames'
import { ReactComponent as AngleDown } from 'icons/16px/Angle-Down.svg'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'

export const ImportBtn = ({ noDrop }: { noDrop?: boolean }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [openDropdown, setOpenImportDropdown] = useState(false)
  if (noDrop) {
    return (
      <Button
        size={BtnSize.medium}
        typeBtn={BtnType.primary}
        withIcon
        onClick={() => importContactStore.setOpenImportModal(true, id)}
      >
        <Import />
        Import contacts
      </Button>
    )
  }
  return (
    <div className={styles.wrapBtn}>
      <button
        className={styles.importBtn}
        onClick={() => importContactStore.setOpenImportModal(true, id)}
      >
        <div className={'mr8'}>
          <Import />
        </div>
        <span className={'bold white nowrap'}>Import contacts</span>

        <div className={styles.divider} />
      </button>
      <MuiTooltip
        title={
          <Button
            hoverGray
            menuBtn
            onClick={() => navigate(RoutesEnum.contactsHistory)}
          >
            <HistoryIcon />
            <span>Import history</span>
          </Button>
        }
        open={openDropdown}
        white
        width={193}
        placement={'bottom-end'}
      >
        <div>
          <ClickAwayListener onClickAway={() => setOpenImportDropdown(false)}>
            <button
              className={classNames(
                styles.dropdownBtn,
                openDropdown && styles.active
              )}
              onClick={() => setOpenImportDropdown(!openDropdown)}
            >
              <AngleDown />
            </button>
          </ClickAwayListener>
        </div>
      </MuiTooltip>
    </div>
  )
}
