import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { ICampaign } from 'src/types/ICampaign'
import { useCampaignFunctions } from 'src/routes/main/campaigns/hooks/useCampaignFunctions'

export const CampaignDuplicateBtn = (props: ActionBtnProps<ICampaign>) => {
  const { row, rows } = props
  const { onDuplicate } = useCampaignFunctions(row, rows)
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.duplicate}
      onAction={onDuplicate}
    />
  )
}
