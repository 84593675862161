import axios, { AxiosResponse } from 'axios'
import {
  IRequestInviteSignUp,
  IRequestInviteSignUpWithGoogle,
  IRequestSignIn,
  IRequestSignInWithGoogle,
  IRequestSignUp,
  IResponseAuth,
} from 'src/apiRest/authApi/types'

export const authApi = {
  signup: (data: IRequestSignUp): Promise<AxiosResponse<IResponseAuth>> =>
    axios.post('/auth/signup', data),
  signIn: (data: IRequestSignIn): Promise<AxiosResponse<IResponseAuth>> =>
    axios.post('/auth/signin', data),
  signInWithGoogle: (
    data: IRequestSignInWithGoogle
  ): Promise<AxiosResponse<IResponseAuth>> => axios.post('/auth/signin', data),
  inviteSignInWithGoogle: (
    data: IRequestInviteSignUpWithGoogle
  ): Promise<AxiosResponse<IResponseAuth>> =>
    axios.post('/auth/invitation-signup', data),
  inviteSignUp: (
    data: IRequestInviteSignUp
  ): Promise<AxiosResponse<IResponseAuth>> =>
    axios.post('/auth/invitation-signup', data),
  logout: () => axios.post('/auth/signout'),
}
