// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xPM4qIoCi72hjw6k6AN2{align-items:center;column-gap:8px;font-size:14px;line-height:16px;overflow:hidden;display:grid;grid-template-columns:max-content 1fr}.Q2Xo2wCkuISemTJHwTaT{color:unset}`, "",{"version":3,"sources":["webpack://./src/components/Status/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,cAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,YAAA,CACA,qCAAA,CAGF,sBACE,WAAA","sourcesContent":[".wrap{\n  align-items: center;\n  column-gap: 8px;\n  font-size: 14px;\n  line-height: 16px;\n  overflow: hidden;\n  display: grid;\n  grid-template-columns: max-content 1fr;\n}\n\n.title {\n  color: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `xPM4qIoCi72hjw6k6AN2`,
	"title": `Q2Xo2wCkuISemTJHwTaT`
};
export default ___CSS_LOADER_EXPORT___;
