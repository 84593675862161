import { SizedBox } from 'components/SizedBox'
import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { AmericanAddressBlock } from 'src/routes/settings/account/compponents/AmericanAddressBlock/AmericanAddressBlock'
import { appLinks } from 'src/util/links'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { PropsBusinessStep } from 'src/routes/settings/compliance/BusinessProfile/types'
import {
  BusinessProfileResponse,
  useAdminInitBusinessGeneralInfoMutation,
  useInitBusinessGeneralInfoMutation,
} from 'src/generated/graphql'

export const GeneralInfoWithoutEIN = observer(
  ({ onCancel, loadingPrevious }: PropsBusinessStep) => {
    const { adminOrgId } = businessProfileStore
    const [initBusinessGeneralInfo, { loading }] =
      useInitBusinessGeneralInfoMutation()
    const [adminInitBusinessGeneralInfo, { loading: loadingAdmin }] =
      useAdminInitBusinessGeneralInfoMutation()
    const onContinue = async () => {
      try {
        let profile: BusinessProfileResponse | undefined | null
        if (adminOrgId) {
          const { data } = await adminInitBusinessGeneralInfo({
            variables: {
              input: businessProfileStore.businessGeneralInfoRequestInput,
              organizationId: adminOrgId,
            },
          })
          profile = data?.adminInitBusinessGeneralInfo
        } else {
          const { data } = await initBusinessGeneralInfo({
            variables: {
              input: businessProfileStore.businessGeneralInfoRequestInput,
            },
          })
          profile = data?.initBusinessGeneralInfo
        }

        if (profile) {
          businessProfileStore.setFullBusinessProfile(profile)
        }
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <>
        <Alert
          compact
          text={
            <p className={'par2'}>
              Add your business information and get access to{' '}
              <a
                href={appLinks.callLoopCompliance}
                target={'_blank'}
                rel="noreferrer"
              >
                toll-free verification
              </a>{' '}
              to avoid carrier filtering and ensure better deliverability.{' '}
            </p>
          }
        />
        <SizedBox height={40} />
        <AmericanAddressBlock
          address={businessProfileStore.businessGeneralInfo?.address}
          setAddress={(address) =>
            businessProfileStore.setBusinessGeneralInfo({
              ...businessProfileStore.businessGeneralInfo,
              address,
            })
          }
          name={businessProfileStore.businessGeneralInfo?.name || ''}
          setName={(name) =>
            businessProfileStore.setBusinessGeneralInfo({
              ...businessProfileStore.businessGeneralInfo,
              name,
            })
          }
          noEIN
        />
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            disabled={businessProfileStore.disabledGeneralInfoWithoutEIN}
            loading={loading || loadingAdmin}
          >
            {businessProfileStore.continueText}
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onCancel}
            loading={loadingPrevious}
          >
            {businessProfileStore.step === 1 ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </>
    )
  }
)
