// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DpYNuo4FzszEmlAMF1R_{margin-top:32px;margin-bottom:28px}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,kBAAA","sourcesContent":[".wrapTabs{\n  margin-top: 32px;\n  margin-bottom: 28px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTabs": `DpYNuo4FzszEmlAMF1R_`
};
export default ___CSS_LOADER_EXPORT___;
