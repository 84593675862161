export enum AppElements {
  Automation = 'automation',
  Broadcast = 'broadcast',
  Campaign = 'campaign',
  Keyword = 'keyword',
  Trigger = 'trigger',
  WebForm = 'web form',
  Contact = 'contact',
  List = 'list',
  Segment = 'segment',
  Audio = 'audio',
  Organizations = 'organization',
  Imports = 'import',
  Numbers = 'number',
}
