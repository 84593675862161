import styles from 'src/routes/auth/login/styles.module.scss'
import { ClickAwayListener } from '@mui/material'
import { ActionsEnum } from 'components/Button/types'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'

export const PreviewImg = ({
  previewSrcFull,
  showPreviewFull,
  setShowPreviewFull,
}: {
  previewSrcFull: string
  showPreviewFull: boolean
  setShowPreviewFull: (value: boolean) => void
}) => {
  const onClose = () => {
    setShowPreviewFull(false)
  }

  return (
    <>
      {showPreviewFull && (
        <div className={styles.previewImg}>
          <div className={styles.wrapCloseBtn}>
            <button onClick={onClose}>
              <AppIcon
                icon={ActionsEnum.close}
                type={IIconType.stroke}
                color={ColorsNames.white}
              />
            </button>
          </div>

          <ClickAwayListener onClickAway={onClose}>
            <img src={previewSrcFull} alt={'preview'} className={styles.img} />
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}
