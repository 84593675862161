import styles from './styels.module.scss'
import classNames from 'classnames'
import { CSSProperties } from 'react'

type Props = {
  progress: number
  style2?: boolean
  background?: CSSProperties['background']
  withBarContainer?: boolean
}

export const RangeBar = ({
  progress,
  style2,
  background,
  withBarContainer,
}: Props) => {
  const bar = (
    <div
      className={styles.bar}
      style={{
        width: progress > 100 ? '100%' : `${progress}%`,
        background,
      }}
    />
  )
  return (
    <div className={classNames(styles.range, style2 && styles.style2)}>
      {withBarContainer ? (
        <div className={styles.barContainer}>{bar}</div>
      ) : (
        bar
      )}
    </div>
  )
}
