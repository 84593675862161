import superAdminStore from 'store/superAdmin/superAdminStore'
import { MediumBtn } from 'components/Button/MediumBtn'
import { OrganizationStatus } from 'src/generated/graphql'
import { ActionsEnum } from 'components/Button/types'
import { SmallBtn } from 'components/Button/SmallBtn'
import * as React from 'react'
import { IOrganization } from 'src/types/IOrganization'
import { IOrganizationDetails } from 'src/types/IOrganizationDetails'
import { ActionBtnProps } from 'components/NewTable/types'

export const BanOrganizationBtn = ({
  onCloseTT,
  row,
  medium,
  menu,
}: ActionBtnProps<IOrganization | IOrganizationDetails>) => {
  if (!row) {
    return <></>
  }
  const onBan = () => {
    onCloseTT && onCloseTT()
    if (row) {
      superAdminStore.setOpenBanOrganizationId(row.id)
    }
  }

  return medium || menu ? (
    <MediumBtn
      type={
        row.organizationStatus === OrganizationStatus.Banned
          ? ActionsEnum.unblock
          : ActionsEnum.ban
      }
      onClick={onBan}
      menu={menu}
      text={
        row.organizationStatus === OrganizationStatus.Banned
          ? 'Unban organization'
          : 'Ban Organization'
      }
    />
  ) : (
    <SmallBtn
      type={
        row.organizationStatus === OrganizationStatus.Banned
          ? ActionsEnum.unblock
          : ActionsEnum.ban
      }
      onClick={onBan}
      tooltip={
        row.organizationStatus === OrganizationStatus.Banned
          ? 'Unban organization'
          : 'Ban Organization'
      }
    />
  )
}
