import { useSelectOrganizationMutation } from 'src/generated/graphql'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { RoutesEnum } from 'src/routes/routes'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import companyStore from 'store/settings/company/companyStore'

export const InviteExistAuth = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [selectOrganizationMutation] = useSelectOrganizationMutation()

  const onSelectOrganization = async () => {
    if (companyStore.id && Number(id) === companyStore.id) {
      navigate(RoutesEnum.broadcasts)
    } else {
      try {
        const { data } = await selectOrganizationMutation({
          variables: {
            organizationId: id,
          },
        })
        if (data?.selectOrganization) {
          window.location.reload()
          navigate(RoutesEnum.broadcasts)
        }
      } catch (e) {
        navigate(RoutesEnum.broadcasts)
        console.error(e)
      }
    }
  }
  useEffect(() => {
    if (id) {
      onSelectOrganization()
    }
  }, [])
  return <AbsoluteLoader />
}
