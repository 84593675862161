import { makeAutoObservable, reaction } from 'mobx'
import contactStore from 'store/contacts/contactStore'
import { ContactRequestInput, Status } from 'src/generated/graphql'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import billingStore from 'store/settings/billing/billingStore'
import importContactStore from 'store/contacts/importContactStore'
import { IContact } from 'src/types/IContact'
import { IContactList } from 'src/types/IContactList'
import { ICustomFieldValue } from 'src/types/ICustomFieldValue'
import {
  contactListToOption,
  customFieldsMapToCustomField,
} from 'store/contacts/functions'
import { IOption } from 'src/types/IOption'

class ManualContactStore {
  contactStore: typeof contactStore
  customFieldsStore: typeof customFieldsStore
  constructor() {
    this.contactStore = contactStore
    this.customFieldsStore = customFieldsStore

    this.customFieldsMap = this.initMap()
    makeAutoObservable(this)
    reaction(
      () => this.editContact,
      () => {
        const listOptions: IOption[] = []
        if (this.editContact) {
          this.editContact.lists?.forEach((list) => {
            listOptions.push(contactListToOption(list))
          })
        }
        this.selectedOptions = listOptions
      }
    )
    reaction(
      () => this.customFieldsStore.customFields,
      () => {
        this.customFieldsMap = this.initMap()
      }
    )
  }

  openContactModal = false
  selectedOptions: IOption[] = []
  editContactId = ''
  firstName = ''
  lastName = ''
  phoneNumber = ''
  loadingCheckContactsLimit = false
  email = ''
  error = ''
  errorContactId = 0
  customFieldsMap: Map<string, ICustomFieldValue> = new Map<
    string,
    ICustomFieldValue
  >()

  editContact: IContact | null = null
  onSuccessEditCb: (() => void) | null = null

  get acceptNewAddContact() {
    if (importContactStore.noLimitContact) {
      return true
    } else {
      return (
        (billingStore.clSubscription?.limits?.contacts || 0) >
        contactStore.totalContactCount
      )
    }
  }

  get createContactInput(): ContactRequestInput {
    return {
      email: this.email?.trim(),
      firstName: this.firstName?.trim(),
      lastName: this.lastName?.trim(),
      phoneNumber: this.phoneNumber,

      status: Status.Active,
      customFields: customFieldsMapToCustomField(this.customFieldsMap),
      listIds: this.selectedOptions.map((opt) => Number(opt.value)),
    }
  }

  get updateContactInput(): ContactRequestInput {
    return {
      ...this.createContactInput,
      id: this.editContactId,
      status: this.editContact?.status || Status.Active,
    }
  }

  get selectedContactLists() {
    const findContactLists: IContactList[] = []
    this.selectedOptions.forEach((opt) => {
      const list = this.contactStore.contactListsMap.get(String(opt.value))
      if (list) {
        findContactLists.push(list)
      }
    })
    return findContactLists
  }
  get disabled() {
    return !this.phoneNumber.trim() || this.phoneNumber.includes('_')
  }
  initMap() {
    return new Map<string, ICustomFieldValue>(
      this.customFieldsStore.customFields.length
        ? this.customFieldsStore.customFields.map((field) => [
            field.id,
            {
              ...field,
              date: null,
              time: null,
              value: '',
            },
          ])
        : new Map()
    )
  }
  setCustomFieldsMap(map: Map<string, ICustomFieldValue>) {
    this.customFieldsMap = map
  }
  setFirstName(val: string) {
    this.firstName = val
  }
  setLastName(val: string) {
    this.lastName = val
  }
  setPhone(val: string) {
    this.phoneNumber = val
  }
  setEmail(val: string) {
    this.email = val
  }
  onDeleteTag = (id: string | number) => {
    this.selectedOptions = this.selectedOptions.filter(
      (opt) => opt.value !== id
    )
    const list = this.contactStore.contactListsMap.get(id)
    if (list?.isNew) {
      this.contactStore.contactListsMap.delete(id)
    }
  }

  onSelectOptions = (options: IOption[]) => {
    this.selectedOptions = options
  }

  setEditContact(contact?: IContact, onSuccessEditCb?: () => void) {
    this.onSuccessEditCb = onSuccessEditCb || null
    this.editContact = contact || null
    this.editContactId = contact?.id || ''
    if (contact) {
      this.firstName = contact.firstName || ''
      this.lastName = contact.lastName || ''
      this.email = contact.email || ''
      this.phoneNumber = contact.phoneNumber || ''

      contact?.customFieldsMap.forEach((field) => {
        if (field.id) {
          this.customFieldsMap.set(field.id, field as ICustomFieldValue)
        }
      })
    }
  }

  setOpenContactModal(state: boolean, id?: string) {
    if (id) {
      const list = this.contactStore.contactListsMap.get(id)
      if (list) {
        const listOption = contactListToOption(list)
        this.selectedOptions = [listOption]
      }
    }
    this.openContactModal = state
  }
  timeError = 0
  setError(error: string) {
    this.timeError = Date.now()
    this.error = error
  }
  closeAddContactModal() {
    this.openContactModal = false
    this.selectedOptions = []
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.phoneNumber = ''
    this.editContactId = ''
    this.error = ''
    this.customFieldsMap = this.initMap()
    this.errorContactId = 0
  }
  setLoadingCheckContactsLimit(val: boolean) {
    this.loadingCheckContactsLimit = val
  }
  setErrorContactId(id: number) {
    this.errorContactId = id
  }
}

export default new ManualContactStore()
