// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ht_SkyCreP7hv15OArE4{display:grid;padding:8px 8px 20px 8px}.M8jmDP1BppihkNrUGrhy{display:grid;grid-template-columns:104px 1fr;column-gap:8px;padding:8px 8px 20px 8px}.SawZ7RVQU5Fgpaj5Behj{padding:8px;border-top:1px solid var(--primary-color-gray-3);background-color:var(--primary-color-gray-4);border-bottom-right-radius:8px;border-bottom-left-radius:8px}.u57B8ON6ihq4E5bMv9fA{margin:0 8px 8px;display:grid;row-gap:8px}`, "",{"version":3,"sources":["webpack://./src/components/Schedule/LaterCard/LaterContentBroadcast/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,wBAAA,CAEF,sBACE,YAAA,CACA,+BAAA,CACA,cAAA,CACA,wBAAA,CAEF,sBACE,WAAA,CACA,gDAAA,CACA,4CAAA,CACA,8BAAA,CACA,6BAAA,CAEF,sBACE,gBAAA,CACA,YAAA,CACA,WAAA","sourcesContent":[".wrap{\n  display: grid;\n  padding: 8px 8px 20px 8px;\n}\n.wrap2{\n  display: grid;\n  grid-template-columns: 104px 1fr;\n  column-gap: 8px;\n  padding: 8px 8px 20px 8px;\n}\n.advanced{\n  padding: 8px;\n  border-top: 1px solid var(--primary-color-gray-3);\n  background-color: var(--primary-color-gray-4);\n  border-bottom-right-radius: 8px;\n  border-bottom-left-radius: 8px;\n}\n.advancedContent{\n  margin: 0 8px 8px;\n  display: grid;\n  row-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ht_SkyCreP7hv15OArE4`,
	"wrap2": `M8jmDP1BppihkNrUGrhy`,
	"advanced": `SawZ7RVQU5Fgpaj5Behj`,
	"advancedContent": `u57B8ON6ihq4E5bMv9fA`
};
export default ___CSS_LOADER_EXPORT___;
