import { Dropdown } from 'components/Dropdown/Dropdown'
import billingStore from 'store/settings/billing/billingStore'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { AddNewCard } from 'src/routes/settings/billing/components/AddNewCard'
import { StripeCardElement } from 'components/StripeCardElement/StripeCardElement'
import { BtnType, Button } from 'components/Button/Button'
import { useSetPaymentsMethods } from 'src/routes/settings/billing/hooks/useSetPaymentsMethods'
import { useAddCardMutation } from 'src/generated/graphql'
import { nanoid } from 'nanoid'
import alertStore from 'store/alertStore'
import { Token } from '@stripe/stripe-js'

export type CardDropdownProps = {
  selectedValue: string | undefined
  onSelectValue: (value: string) => void
  onFinish?: () => void
  withNewCard?: boolean
  fromUpdate?: boolean
  noAddCard?: boolean
  newCardError?: string
  skip?: boolean
}

export const PrimaryCardDropdown = observer(
  ({
    onSelectValue,
    selectedValue,
    onFinish,
    withNewCard,
    fromUpdate,
    noAddCard,
    skip,
    newCardError,
  }: CardDropdownProps) => {
    useSetPaymentsMethods(skip)
    const refBtn = useRef<HTMLButtonElement | null>(null)
    const [error, setError] = useState('')
    const [addCard] = useAddCardMutation()
    const onAction = (token: Token) => {
      const tokenId = token.id
      const cardId = token.card?.id
      if (noAddCard) {
        billingStore.addActionNewCardCb &&
          billingStore.addActionNewCardCb('', tokenId)
      } else {
        addCard({
          variables: {
            tokenId,
          },
        })
          .then((res) => {
            if (res.data?.addCard) {
              billingStore.setOrganizationBillingInfo(res.data?.addCard)
              const newCard = res.data.addCard.paymentMethods?.find(
                (paymentMethod) => paymentMethod?.id === cardId
              )
              if (newCard) {
                onSelectValue(newCard.id || '')
              }
            }
            billingStore.addActionNewCardCb &&
              billingStore.addActionNewCardCb(cardId || '', tokenId)
          })
          .catch((e) => {
            console.error(e)
            setError(nanoid())
            if (e instanceof Error) {
              alertStore.disabledErrorAlert(e.message)
            }
          })
          .finally(onFinish)
      }
    }
    useEffect(() => {
      billingStore.setNewCardElement(refBtn.current)
    }, [billingStore.showNewCard, billingStore.showNewCardUpdate])

    const isNewCard = () => {
      if (
        billingStore.loadingPaymentMethods &&
        !billingStore.cardsOptions.length
      ) {
        return false
      }
      return (
        !billingStore.cardsOptions.length ||
        (fromUpdate ? billingStore.showNewCardUpdate : billingStore.showNewCard)
      )
    }

    return isNewCard() ? (
      <StripeCardElement
        label={'New credit card'}
        onAction={onAction}
        onFailed={onFinish}
        noAction
        fullWidth
        refBtn={refBtn}
        error={error || newCardError}
        setReadyForm={(val) => billingStore.setReadyNewCard(val)}
        rightBtn={
          !billingStore.cardsOptions.length ? undefined : (
            <Button
              typeBtn={BtnType.blueAction}
              onClick={() =>
                fromUpdate
                  ? billingStore.setShowNewCardUpdate(false)
                  : billingStore.setShowNewCard(false)
              }
            >
              Cancel
            </Button>
          )
        }
      />
    ) : (
      <>
        <Dropdown
          withIconRender
          labelInput={'Credit card'}
          options={billingStore.cardsOptions}
          selectedValue={selectedValue}
          onSelectValue={onSelectValue}
          BottomDropdownButton={(props) =>
            AddNewCard({
              ...props,
              withAddBtn: withNewCard,
              onAction: () =>
                fromUpdate
                  ? billingStore.setShowNewCardUpdate(true)
                  : billingStore.setShowNewCard(true),
            })
          }
          error={
            (billingStore.upgradeCardId &&
              billingStore.upgradeCardId === selectedValue &&
              'Please provide another credit card') ||
            ''
          }
        />
      </>
    )
  }
)
