// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n6kUHisGj9jm03M0WHDG{display:grid;grid-template-columns:1fr 32px;min-height:32px;gap:12px;align-items:flex-start;margin-top:8px;margin-bottom:6px}.T0WYzjOL0NAS1uh3RtK7{margin-top:6px;margin-bottom:4px;font-size:14px;font-style:normal;font-weight:400;line-height:20px}.T0WYzjOL0NAS1uh3RtK7 .H1AgL0LA0scMpLUYbqRR{outline:none}.T0WYzjOL0NAS1uh3RtK7 span{display:unset}.T0WYzjOL0NAS1uh3RtK7 .JOarhFhrY2mC9sX9ikUa{pointer-events:none;color:var(--primary-color-gray-1)}`, "",{"version":3,"sources":["webpack://./src/components/CreateElement/SMSContent/EditableFieldText/style.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,8BAAA,CACA,eAAA,CACA,QAAA,CACA,sBAAA,CACA,cAAA,CACA,iBAAA,CAEF,sBAEE,cAAA,CACA,iBAAA,CAQA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAVA,4CACE,YAAA,CAEF,2BACE,aAAA,CAOF,4CACE,mBAAA,CACA,iCAAA","sourcesContent":["\n.wrap{\n  display: grid;\n  grid-template-columns: 1fr 32px;\n  min-height: 32px;\n  gap: 12px;\n  align-items: flex-start;\n  margin-top: 8px;\n  margin-bottom: 6px;\n}\n.wrapEditContent{\n  //min-height: 32px;\n  margin-top: 6px;\n  margin-bottom: 4px;\n  .editContent{\n    outline: none;\n  }\n  span{\n    display: unset;\n\n  }\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  .required{\n    pointer-events: none;\n    color: var(--primary-color-gray-1);\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `n6kUHisGj9jm03M0WHDG`,
	"wrapEditContent": `T0WYzjOL0NAS1uh3RtK7`,
	"editContent": `H1AgL0LA0scMpLUYbqRR`,
	"required": `JOarhFhrY2mC9sX9ikUa`
};
export default ___CSS_LOADER_EXPORT___;
