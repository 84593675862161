import styles from 'components/Dropdown/styles.module.scss'
import { ReactComponent as AngleUp } from 'icons/16px/Angle-Up.svg'
import { ReactComponent as AngleDown } from 'icons/16px/Angle-Down.svg'
import React from 'react'
import classNames from 'classnames'
import { IOption } from 'src/types/IOption'

export const RightContent = ({
  rightBtn,
  selectedOption,
  showTT,
  setShowTT,
  disabled,
}: {
  selectedOption?: IOption
  rightBtn?: JSX.Element
  showTT: boolean
  disabled?: boolean
  setShowTT?: (val: boolean) => void
}) =>
  rightBtn ? (
    rightBtn
  ) : (
    <div className={styles.checkWrap}>
      {selectedOption?.rightContent}
      <button
        onClick={() => setShowTT && setShowTT(!showTT)}
        className={classNames(styles.arrowBtn, disabled && styles.disabled)}
      >
        {showTT ? <AngleUp /> : <AngleDown />}
      </button>
    </div>
  )
