import apiSore from 'store/apiSore'
import {
  AdminChangeOwnerDocument,
  AdminChangeOwnerMutation,
  AdminChangeOwnerMutationVariables,
} from 'src/generated/graphql'

export const AdminApi = {
  changeOwner: (variables: AdminChangeOwnerMutationVariables) =>
    apiSore.client.mutate<
      AdminChangeOwnerMutation,
      AdminChangeOwnerMutationVariables
    >({
      mutation: AdminChangeOwnerDocument,
      variables,
    }),
}
