import React, { FC, ReactNode } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Pointer } from 'assets/icons/Bottom pointer.svg'
import classNames from 'classnames'

type Props = {
  text?: ReactNode
  nowrapText?: string
  subText?: string
  disableVisible?: boolean
  children?: ReactNode
}

export const Tooltip: FC<Props> = ({
  text,
  subText,
  nowrapText,
  disableVisible,
  children,
}) => {
  return (
    <div className={styles.wrap}>
      <div
        className={classNames(
          disableVisible && styles.disableVisible,
          styles.content
        )}
      >
        <div className={styles.arrow}>
          <Pointer />
        </div>
        <div className={styles.nowrap}>{nowrapText}</div>
        {text}
        <div className={styles.subText}>{subText}</div>
      </div>
      {children}
    </div>
  )
}
