import { TabWrap } from 'src/routes/settings/account/TabWrap'
import { SizedBox } from 'components/SizedBox'
import { InputText } from 'components/Input/InputText/InputText'
import { ReactComponent as Web } from 'icons/16pxNoMask/Web.svg'
import { AppIcon } from 'components/AppIcon/AppIcon'
import React, { useEffect, useMemo, useState } from 'react'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import user from 'store/user/user'
import {
  Address,
  useUpdateOrganizationInfoMutation,
} from 'src/generated/graphql'
import { AddressBlock } from 'src/routes/settings/account/compponents/AdreesBlock/AddressBlock'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import companyStore from 'store/settings/company/companyStore'

export const CompanyProfile = observer(() => {
  const [updateOrganizationInfo, { loading }] =
    useUpdateOrganizationInfoMutation()
  const [companyName, setCompanyName] = useState(companyStore.companyName)
  const [website, setWebsite] = useState(companyStore.website)
  const [termsUrl, setTermsUrl] = useState(companyStore.termsUrl)
  const [complianceUrl, setComplianceUrl] = useState(companyStore.complianceUrl)
  const [phone, setPhone] = useState(companyStore.formattedPhoneNumber)
  const [address, setAddress] = useState<Address>(companyStore.address || {})

  const disabledEdit = !user.isOwner

  const oldCompanyInfo = JSON.stringify([
    companyStore.companyName,
    companyStore.website,
    companyStore.formattedPhoneNumber,
    companyStore.termsUrl,
    companyStore.complianceUrl,
    companyStore.address?.street,
    companyStore.address?.city,
    companyStore.address?.state,
    companyStore.address?.zipCode,
    companyStore.address?.country?.countryCode,
  ])

  const newCompanyInfo = JSON.stringify([
    companyName,
    website,
    phone,
    termsUrl,
    complianceUrl,
    address?.street,
    address?.city,
    address?.state,
    address?.zipCode,
    address?.country?.countryCode,
  ])

  const disabledSave = useMemo(() => {
    if (!companyName) {
      return true
    }
    if (!phone || phone.includes('_')) {
      return true
    }
    if (oldCompanyInfo === newCompanyInfo) {
      return true
    }
    return false
  }, [companyName, phone, oldCompanyInfo, newCompanyInfo])

  const initData = () => {
    setCompanyName(companyStore.companyName || '')
    setWebsite(companyStore.website || '')
    setPhone(companyStore.formattedPhoneNumber || '')
    setTermsUrl(companyStore.termsUrl || '')
    setComplianceUrl(companyStore.complianceUrl || '')
    setAddress(companyStore.address || {})
  }

  useEffect(() => {
    initData()
  }, [companyStore.companyName])

  const onSave = async () => {
    try {
      const { data } = await updateOrganizationInfo({
        variables: {
          input: {
            name: companyName,
            website: website,
            phoneNumber: phone,
            termsUrl: termsUrl.trim(),
            complianceUrl: complianceUrl.trim(),
            street: address.street,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
            countryCode: address.country?.countryCode,
          },
        },
      })
      if (data?.updateOrganizationInfo) {
        companyStore.setOrganization(data.updateOrganizationInfo)
        alertStore.setAlert({
          type: AlertTypeEnum.success,
          title: 'Your company profile is successfully updated!',
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const onCancel = () => {
    initData()
  }
  return (
    <TabWrap>
      <p>
        <b>General information</b>
      </p>
      <SizedBox height={32} />
      <InputText
        label={'Company name'}
        tooltip={
          <>
            Your company name will be <br /> used in compliance messages{' '}
          </>
        }
        required
        placeholder={'e.g. Automize'}
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        onClickRightIcon={() => setCompanyName('')}
        disabled={disabledEdit}
      />
      <SizedBox height={16} />
      <InputText
        disabled={disabledEdit}
        label={'Company website'}
        placeholder={'company.com'}
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
        onClickRightIcon={() => setWebsite('')}
        leftIcon={
          <AppIcon>
            <Web />
          </AppIcon>
        }
      />
      <SizedBox height={16} />
      <InputPhone
        disabled={disabledEdit}
        label={'Support phone number'}
        required
        tooltip={
          <>
            This number will be <br /> displayed when your <br /> contacts
            replies “HELP”
          </>
        }
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <SizedBox height={16} />
      <InputText
        disabled={disabledEdit}
        label={'Terms and Conditions'}
        placeholder={'https://'}
        value={termsUrl}
        onChange={(e) => setTermsUrl(e.target.value)}
        onClickRightIcon={() => setTermsUrl('')}
        // leftIcon={
        //   <AppIcon>
        //     <Web />
        //   </AppIcon>
        // }
      />
      <SizedBox height={16} />
      <InputText
        disabled={disabledEdit}
        label={'Privacy Policy'}
        placeholder={'https://'}
        value={complianceUrl}
        onChange={(e) => setComplianceUrl(e.target.value)}
        onClickRightIcon={() => setComplianceUrl('')}
        // leftIcon={
        //   <AppIcon>
        //     <Web />
        //   </AppIcon>
        // }
      />
      <SizedBox height={48} />
      <AddressBlock
        address={address}
        setAddress={setAddress}
        disabledEdit={disabledEdit}
        withRemoveCountry
      />
      {!disabledEdit && (
        <div className={styles.actions}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            disabled={disabledSave}
            onClick={onSave}
            loading={loading}
          >
            Save
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </TabWrap>
  )
})
