import { useGetBalancesQuery } from 'src/generated/graphql'
import billingStore from 'store/settings/billing/billingStore'
import { useEffect } from 'react'

export function useSetBalance() {
  const { data } = useGetBalancesQuery()
  useEffect(() => {
    if (data?.getBalances) {
      billingStore.setBalances(data.getBalances)
    }
  }, [data])
}
