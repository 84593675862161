// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eAHoGIT6UHIonLFEXn8C{width:526px;overflow-x:hidden}.xI6vRbYPN62YwZcJsoHW{bottom:0;position:fixed;overflow-y:auto}.SOgNKVXaWDXZP0Y_1scb{padding:0 32px}.cSsZghelVNBiG_SYzv5H{padding:40px 36px 32px 32px}.d2Rq2FDHomm3KR_0lVnH{padding:40px 32px 17px 32px}.Cza0mnvtb9lFvdTOpmaK{display:grid;row-gap:16px}.Qcs1sGwTyD2FpK4an53m{padding:32px;display:flex;column-gap:12px}.p9Ig60jon3O7fFltDfIQ{display:grid;row-gap:16px;column-gap:12px;grid-template-columns:1fr 1fr}.p9Ig60jon3O7fFltDfIQ>div:first-child{grid-column:1/-1}.iagkpNQwmJxro_Ym8eZA{margin-top:24px;border:1px solid var(--primary-color-gray-3);border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/components/VCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,iBAAA,CAEF,sBACE,QAAA,CACA,cAAA,CACA,eAAA,CAEF,sBACE,cAAA,CAEF,sBACE,2BAAA,CAEF,sBACE,2BAAA,CAEF,sBACE,YAAA,CACA,YAAA,CAEF,sBACE,YAAA,CACA,YAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,YAAA,CACA,eAAA,CACA,6BAAA,CACA,sCACE,gBAAA,CAKJ,sBACE,eAAA,CACA,4CAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  width: 526px;\n  overflow-x: hidden;\n}\n.wrapScroll{\n  bottom: 0;\n  position: fixed;\n  overflow-y: auto;\n}\n.wrapInfo{\n  padding: 0 32px;\n}\n.wrapBlock{\n  padding: 40px 36px 32px 32px;\n}\n.wrapMediaBlock{\n  padding: 40px 32px 17px 32px;\n}\n.wrapFields{\n  display: grid;\n  row-gap: 16px;\n}\n.actions{\n  padding: 32px;\n  display: flex;\n  column-gap: 12px;\n}\n.wrapFieldsAddress{\n  display: grid;\n  row-gap: 16px;\n  column-gap: 12px;\n  grid-template-columns: 1fr 1fr;\n  & > div:first-child{\n    grid-column: 1/-1;\n  }\n  //grid-template-rows: 1fr ;\n}\n\n.mediaCard{\n  margin-top: 24px;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `eAHoGIT6UHIonLFEXn8C`,
	"wrapScroll": `xI6vRbYPN62YwZcJsoHW`,
	"wrapInfo": `SOgNKVXaWDXZP0Y_1scb`,
	"wrapBlock": `cSsZghelVNBiG_SYzv5H`,
	"wrapMediaBlock": `d2Rq2FDHomm3KR_0lVnH`,
	"wrapFields": `Cza0mnvtb9lFvdTOpmaK`,
	"actions": `Qcs1sGwTyD2FpK4an53m`,
	"wrapFieldsAddress": `p9Ig60jon3O7fFltDfIQ`,
	"mediaCard": `iagkpNQwmJxro_Ym8eZA`
};
export default ___CSS_LOADER_EXPORT___;
