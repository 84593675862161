import React, { Dispatch, SetStateAction } from 'react'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import styles from 'components/TestItBtn/styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { InputPhone } from 'components/Input/InputText/InputPhone'

export const TestPhoneInputComponent = ({
  fromCreate,
  onClose,
  onTest,
  // position,
  width,
  phone,
  setPhone,
  loading,
  disabled,
  success,
}: {
  fromCreate?: boolean
  success?: boolean

  width?: number
  onClose?: () => void
  onTest?: () => void
  position?: TTPositionEnum
  loading?: boolean
  disabled?: boolean
  phone?: string
  setPhone?: Dispatch<SetStateAction<string>>
}) => {
  // const { trigger, setTrigger, showTT, setShowTT, onTriggerClick } =
  //   useFixedTooltip()
  // const onSelectPhone = (val: string) => {
  //   setShowTT(false)
  //   setPhone(val)
  // }
  return (
    <>
      {/*<FixedTooltip*/}
      {/*  trigger={trigger}*/}
      {/*  visible={showTT}*/}
      {/*  white*/}
      {/*  noArrow*/}
      {/*  position={*/}
      {/*    position*/}
      {/*      ? position*/}
      {/*      : fromCreate*/}
      {/*      ? TTPositionEnum.bottomLeft*/}
      {/*      : TTPositionEnum.bottomCenter*/}
      {/*  }*/}
      {/*  width={204}*/}
      {/*  globalClasses={[ClassesEnum.p8]}*/}
      {/*>*/}
      {/*  <span className={classNames('s2 medium', styles.title)}>*/}
      {/*    Select or add new number*/}
      {/*  </span>*/}
      {/*  {phones.map((phoneOpt) => (*/}
      {/*    <Button*/}
      {/*      key={phoneOpt}*/}
      {/*      size={BtnSize.small}*/}
      {/*      menuBtn*/}
      {/*      hoverGray*/}
      {/*      onClick={() => onSelectPhone(phoneOpt)}*/}
      {/*    >*/}
      {/*      <div className={styles.row}>*/}
      {/*        <span>{phoneOpt}</span>*/}
      {/*        {phone === phoneOpt && <Check />}*/}
      {/*      </div>*/}
      {/*    </Button>*/}
      {/*  ))}*/}
      {/*</FixedTooltip>*/}
      <div
        // ref={setTrigger}
        // onClick={onTriggerClick}
        className={styles.btn}
        style={{ width }}
      >
        <InputPhone
          value={phone}
          onChange={(event) => setPhone && setPhone(event.target.value)}
          focusOnMount
        />
        {fromCreate && (
          <div className={styles.additionalContent}>
            <button className={styles.cancel} onClick={onClose}>
              <span className={'gray1 bold'}>Cancel</span>
            </button>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.small}
              p12
              onClick={onTest}
              loading={loading}
              disabled={disabled}
              success={success}
            >
              Send test
            </Button>
          </div>
        )}
      </div>
    </>
  )
}
