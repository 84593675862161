import { useEffect, useState } from 'react'
import { secondToMinutes } from 'src/util/functions'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MicRecorder from 'mic-recorder-to-mp3'
import { nanoid } from 'nanoid'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import audioStore from 'store/audioStore/audioStore'

type IRecorder = () => {
  audioURL: string
  isRecording: boolean
  startRecording: () => void
  stopRecording: () => void
  removeAudio: () => void
  audioDurationString: string
  audioId: string
  audioDuration: number
  blob: Blob | null
  audioName: string
}

const useRecorder: IRecorder = () => {
  const initMp3Recorder = () =>
    new MicRecorder({
      bitRate: 128,
      deviceId: audioStore.selectedMicroDeviceId,
    })

  const [Mp3Recorder, seMp3Recorder] = useState(initMp3Recorder())
  const [audioURL, setAudioURL] = useState('')
  const [audioId, setAudioId] = useState('')
  const [audioName, setAudioName] = useState('')
  const [blob, setBlob] = useState<Blob | null>(null)
  const [audioDuration, setAudioDuration] = useState(0)
  const [isRecording, setIsRecording] = useState(false)
  const [idInterval, setIdInterval] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isRecording) {
      if (!idInterval) {
        setIdInterval(
          setInterval(() => setAudioDuration((prev) => prev + 1), 1000)
        )
      }
    } else {
      idInterval && clearInterval(idInterval)
    }
  }, [isRecording, audioDuration])

  useEffect(() => {
    seMp3Recorder(initMp3Recorder())
  }, [audioStore.selectedMicroDeviceId])

  const getPermission = (onGranted?: () => void) => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then(() => {
        audioStore.setGranted(true)
        onGranted && onGranted()
      })
      .catch((e) => {
        console.error(e)
        audioStore.setGranted(false)
      })
  }

  useEffect(() => {
    getPermission()
  }, [])

  const startRecording = () => {
    const onGranted = () => {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true)
        })
        .catch(console.error)
    }

    if (!audioStore.isGranted) {
      getPermission(onGranted)
    } else {
      onGranted()
    }
  }

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .then(([, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        const fileName = `Recorded at ${dayjs().format(DayjsFormats.full)}.mp3`
        setAudioName(fileName)
        setAudioId(nanoid())
        setBlob(blob)
        setAudioURL(blobURL)
        setIsRecording(false)
      })
      .catch(console.error)
  }
  const removeAudio = () => {
    setAudioURL('')
    setAudioName('')
    setAudioDuration(0)
    setIdInterval(null)
    setAudioId('')
  }

  return {
    audioURL,
    isRecording,
    audioName,
    startRecording,
    stopRecording,
    removeAudio,
    audioDurationString: secondToMinutes(audioDuration),
    audioDuration,
    blob,
    audioId,
  }
}

export default useRecorder
