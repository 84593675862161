import { CircularProgressbar } from 'react-circular-progressbar'
import './styles.scss'
import { ReactComponent as Check } from 'icons/16px/Check.svg'

export const CircularProgress = ({
  progress,
  small,
}: {
  progress: number
  small?: boolean
}) =>
  progress === 100 ? (
    <div className={'wrapCheck'}>
      <Check />
    </div>
  ) : (
    <CircularProgressbar
      value={Math.round(progress)}
      text={small ? undefined : `${Math.round(progress)}%`}
      className={small ? 'CircularProgressbarSmall' : undefined}
    />
  )
