import { observer } from 'mobx-react-lite'
import React from 'react'
import tollFreeVerificationStore, {
  maxLengthSample,
  maxLengthUseCaseDesc,
} from 'store/settings/company/tollFreeVerificationStore'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { Alert } from 'components/Alert/Alert'
import { SimpleTextarea } from 'components/SimpleTextarea/SimpleTextarea'
import {
  TollFreeVerificationProfile,
  TollFreeVerificationUseCaseType,
  useAdminInitTollFreeVerificationUseCaseMutation,
  useInitTollFreeVerificationUseCaseMutation,
} from 'src/generated/graphql'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { PropsTollFreeStep } from 'src/routes/settings/compliance/TollFreeVerification/types'
import { TollFreeBackBtn } from 'src/routes/settings/compliance/TollFreeVerification/steps/TollFreeBackBtn'
import { appLinks } from 'src/util/links'

export const UseCase = observer(({ onSuccess }: PropsTollFreeStep) => {
  const {
    tollFreeVerificationUseCaseRequestInput,
    useCaseType,
    setUseCaseType,
    useCaseTypesOptions,
    setMessageAmount,
    messageAmount,
    messageAmountOptions,
    useCaseDescription,
    setUseCaseDescription,
    setSampleMessage,
    disabledUseCase,
    continueText,
    sampleMessage,
    adminOrgId,
  } = tollFreeVerificationStore
  const [initTollFreeVerificationUseCase, { loading }] =
    useInitTollFreeVerificationUseCaseMutation()
  const [adminInitTollFreeVerificationUseCase, { loading: loadingAdmin }] =
    useAdminInitTollFreeVerificationUseCaseMutation()
  const onContinue = async () => {
    try {
      let profile: TollFreeVerificationProfile | null | undefined = null
      if (adminOrgId) {
        const { data } = await adminInitTollFreeVerificationUseCase({
          variables: {
            input: tollFreeVerificationUseCaseRequestInput,
            organizationId: adminOrgId,
          },
        })
        profile = data?.adminInitTollFreeVerificationUseCase
      } else {
        const { data } = await initTollFreeVerificationUseCase({
          variables: {
            input: tollFreeVerificationUseCaseRequestInput,
          },
        })
        profile = data?.initTollFreeVerificationUseCase
      }
      if (profile) {
        onSuccess(profile)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={'col40'}>
      <Alert
        compact
        text={
          <>
            Your toll-free use case describes the type of messaging you plan to
            send. Please select the use case that best describes how you will
            use messaging.{' '}
            <a
              href={appLinks.tollFreeVerificationRegistration}
              target={'_blank'}
              rel="noreferrer"
            >
              Learn more and see examples.
            </a>
          </>
        }
      />
      <div className={'col24'}>
        <div className={'row12 fullWidth'}>
          <Dropdown
            fullWidth
            selectedValue={useCaseType}
            onSelectValue={(value) =>
              setUseCaseType(value as TollFreeVerificationUseCaseType)
            }
            placeholder={'e.g. General Marketing'}
            labelInput={'Use case'}
            labelTooltip={
              <span className={'s1 white'}>
                Select the use case that best fits the description of how you
                will use this specific toll-free number Call Loop.{' '}
                <a
                  href={appLinks.tollFreeVerificationRegistration}
                  target={'_blank'}
                  className={'s1'}
                  rel="noreferrer"
                >
                  See examples.
                </a>
              </span>
            }
            options={useCaseTypesOptions}
          />
          <Dropdown
            fullWidth
            selectedValue={messageAmount}
            onSelectValue={(value) => setMessageAmount(value)}
            placeholder={'10,000'}
            labelInput={'Expected monthly messages'}
            labelTooltipWidth={246}
            labelTooltip={
              'Estimate your monthly message volume across all the numbers you intend to include in this use case.'
            }
            options={messageAmountOptions}
          />
        </div>

        <SimpleTextarea
          value={useCaseDescription}
          onChangeValue={setUseCaseDescription}
          label={'Use case description'}
          labelTooltip={
            <span className={'white'}>
              Please describe the use case and how you plan on using SMS.{' '}
              <a
                href={appLinks.tollFreeVerificationRegistration}
                target={'_blank'}
                className={'s1'}
                rel="noreferrer"
              >
                See examples.
              </a>
            </span>
          }
          placeholder={'Enter use case description'}
          height={104}
          maxLength={maxLengthUseCaseDesc}
        />
        <SimpleTextarea
          label={'Sample message'}
          placeholder={'Enter sample message'}
          labelTooltip={
            'Provide a sample message that you have or plan on sending from this phone number.'
          }
          value={sampleMessage}
          onChangeValue={setSampleMessage}
          height={104}
          maxLength={maxLengthSample}
        />
      </div>
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={disabledUseCase}
          loading={loading || loadingAdmin}
        >
          {continueText}
        </Button>
        <TollFreeBackBtn />
      </div>
    </div>
  )
})
