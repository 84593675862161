import { IBroadcast } from 'src/types/IBroadcast'
import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import {
  ContentType,
  GetBroadcastMessagesQuery,
  MessageStatisticsResponse,
} from 'src/generated/graphql'
import { IStatisticMessage } from 'src/types/IDetailMessage'
import { messageStatisticsColumns } from 'components/NewTable/columns/messageStatisticsColumns'
import { makeAutoObservable, reaction } from 'mobx'

export class BroadcastDetailStore {
  tableStore!: TableStore<IStatisticMessage>
  constructor() {
    makeAutoObservable(this)
  }

  broadcast: IBroadcast | null = null

  messageStatistics: MessageStatisticsResponse | null = null
  statisticMessages: IStatisticMessage[] = []

  get totalCharged() {
    return this.messageStatistics?.chargeStatistics?.totalCharged || 0
  }

  setBroadcast(broadcast: IBroadcast) {
    this.broadcast = broadcast
    this.tableStore = new TableStore({
      columns: messageStatisticsColumns({
        type: broadcast.type as ContentType,
        status: TableStatuses.totalCount,
        withShortStatistic:
          !!this.messageStatistics?.shortUrlClickStatistics?.shortUrlVisits
            ?.length,
      }),
      tableName: 'BroadcastsDetailTable',
      orderBy: 'state.timeToSend',
      fromBroadcastStatistic: true,
    })
    reaction(
      () => this.tableStore.status,
      (status) => {
        this.tableStore.setColumns(
          messageStatisticsColumns({
            type: this.broadcast?.type as ContentType,
            status: status,
            withShortStatistic:
              !!this.messageStatistics?.shortUrlClickStatistics?.shortUrlVisits
                ?.length,
          })
        )
      }
    )
  }

  setMessageStatistics(messageStatistics: MessageStatisticsResponse | null) {
    this.messageStatistics = messageStatistics
    this.tableStore.setColumns(
      messageStatisticsColumns({
        type: this.broadcast?.type as ContentType,
        status: this.tableStore.status,
        withShortStatistic:
          !!this.messageStatistics?.shortUrlClickStatistics?.shortUrlVisits
            ?.length,
      })
    )
  }

  setMessages(data: GetBroadcastMessagesQuery) {
    this.tableStore.total = data.getBroadcastMessages?.total || 0

    const iMessages: IStatisticMessage[] = []

    data.getBroadcastMessages?.content?.forEach((message) => {
      if (message) {
        iMessages.push({
          id: message?.id,
          ...message,
        })
      }
    })
    this.statisticMessages = iMessages
  }
}
