import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import { usePreviousStepTenDlcMutation } from 'src/generated/graphql'
import { useNavigate } from 'react-router-dom'

export const BackBtn = observer(() => {
  const [previousStepTenDlc, { loading }] = usePreviousStepTenDlcMutation()
  const navigate = useNavigate()
  const onCancel = async () => {
    try {
      if (a2p10DLCStore.step === 1) {
        navigate(-1)
      } else {
        const { data } = await previousStepTenDlc()
        if (data?.previousStepTenDlc) {
          a2p10DLCStore.setTenDlcProfile(data?.previousStepTenDlc.profile)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Button
      size={BtnSize.medium}
      typeBtn={BtnType.secondaryGray}
      onClick={onCancel}
      loading={loading}
    >
      {a2p10DLCStore.step === 1 ? 'Cancel' : 'Back'}
    </Button>
  )
})
