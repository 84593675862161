import { CreateElementBtn } from 'components/Button/CreateElementBtn'

import { AppElements } from 'src/enums/appElements'
// import { Dropdown } from 'components/Dropdown/Dropdown'
// import { ReactComponent as StatusIcon } from 'icons/16px/Status/Status.svg'
// import { ReactComponent as Deliver } from 'icons/16px/Deliver.svg'
// import { useState } from 'react'
// import { IOption } from 'components/Dropdown/types'
// import { StatusContent } from 'components/StatusContent/StatusContent'
// import { ContentType, Status } from 'src/generated/graphql'
// import { TypeContent } from 'components/TypeContent/TypeContent'

// const options: IOption[] = [
//   {
//     title: <StatusContent status={Status.Scheduled} />,
//     value: Status.Scheduled,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <StatusContent status={Status.Sending} />,
//     value: Status.Sending,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <StatusContent status={Status.Paused} />,
//     value: Status.Paused,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <StatusContent status={Status.Finished} />,
//     value: Status.Sent,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <StatusContent status={Status.Draft} />,
//     value: Status.Draft,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <StatusContent status={Status.Archived} />,
//     value: Status.Archived,
//     padding: '0 8px 0 0',
//   },
// ]
// const optionsTypes: IOption[] = [
//   {
//     title: <TypeContent type={ContentType.Sms} />,
//     value: ContentType.Sms,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <TypeContent type={ContentType.Voice} />,
//     value: ContentType.Voice,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <TypeContent type={ContentType.Ringless} />,
//     value: ContentType.Ringless,
//     padding: '0 8px 0 0',
//   },
// ]

export const NoSelectContent = () => {
  // const [currentStatus, setCurrentStatus] = useState<IOption[]>([])
  // const [currentTypes, setCurrentTypes] = useState<IOption[]>([])
  return (
    <>
      <CreateElementBtn appElement={AppElements.Broadcast} />
      {/*<Dropdown*/}
      {/*  dropDownContentWidth={196}*/}
      {/*  maxHeightDropdown={250}*/}
      {/*  options={options}*/}
      {/*  selectedOptions={currentStatus}*/}
      {/*  onMultiSelect={setCurrentStatus}*/}
      {/*  leftIcon={<StatusIcon />}*/}
      {/*  multi*/}
      {/*  unit={'status'}*/}
      {/*  units={'Status'}*/}
      {/*/>*/}
      {/*<Dropdown*/}
      {/*  dropDownContentWidth={156}*/}
      {/*  options={optionsTypes}*/}
      {/*  selectedOptions={currentTypes}*/}
      {/*  onMultiSelect={setCurrentTypes}*/}
      {/*  leftIcon={<Deliver />}*/}
      {/*  multi*/}
      {/*  unit={'type'}*/}
      {/*  units={'types'}*/}
      {/*/>*/}
    </>
  )
}
