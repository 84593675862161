import styles from './styles.module.scss'
import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import presentationStore from 'store/presentationStore'
import classNames from 'classnames'
import { AppIcon, ImportIcons } from 'components/AppIcon/AppIcon'

export const SamplePresentationImage = observer(() => {
  const refWrapHover = useRef(false)
  const onWrapClick = () => {
    if (!refWrapHover.current) {
      presentationStore.setSampleImageUrl('')
    }
  }
  const onMouseEnter = () => {
    refWrapHover.current = true
  }
  const onMouseLeave = () => {
    refWrapHover.current = false
  }
  if (!presentationStore.samplePresentationImageUrl) {
    return <></>
  }
  return (
    <div
      className={classNames(styles.wrap, styles.sample)}
      onClick={onWrapClick}
      aria-hidden={true}
    >
      <AppIcon
        icon={ImportIcons.closeNew}
        // color={ColorsNames.white}
        // type={IIconType.stroke}
        className={styles.closeSample}
      />
      <img
        src={presentationStore.samplePresentationImageUrl}
        alt=""
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </div>
  )
})
