import { makeAutoObservable } from 'mobx'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

class ApiSore {
  private _client: ApolloClient<NormalizedCacheObject> | null = null
  constructor() {
    makeAutoObservable(this)
  }

  // Example use ApolloClient
  // GetMyContactDocument
  //     apiSore.client.query<GetMyContactQuery, GetMyContactQueryVariables>({
  //         query: GetMyContactDocument,
  //         variables: {
  //           contactId: 5,
  //         },
  //       })

  get client() {
    return this._client as ApolloClient<NormalizedCacheObject>
  }

  setClient = (client: ApolloClient<NormalizedCacheObject>) => {
    this._client = client
  }
}

export default new ApiSore()
