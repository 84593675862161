import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import * as React from 'react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import { useSetNumbers } from 'src/routes/settings/numbers/hooks/useSetNumbers'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { useSetFullTollFreeProfile } from 'src/routes/settings/compliance/TollFreeVerification/hooks/useSetFullTollFreeProfile'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { CircularProgress } from 'components/CircularProgress/CircularProgress'
import numbersStore from 'store/settings/numbers/numbersStore'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { AddTollFreeFirstModal } from 'src/Modals/AddTollFreeFirstModal/AddTollFreeFirstModal'
import { TollFreeVerificationStatus } from 'src/generated/graphql'
import { SmallBtn } from 'components/Button/SmallBtn'
import { ActionsEnum } from 'components/Button/types'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import typesStore from 'store/typesStore'

export const TollFreeVerificationCard = observer(
  ({ fromModal, noAction }: { fromModal?: boolean; noAction?: boolean }) => {
    const {
      completed,
      useCaseName,
      completedStep,
      selectedNumbersOptions,
      completedPercent,
      stepCount,
      status,
      setOpenModal,
    } = tollFreeVerificationStore

    const { loading: loadingProfile } = useSetFullTollFreeProfile()

    const { loading } = useSetNumbers()
    const navigate = useNavigate()

    const disabledContinue = useMemo(() => {
      return (
        loading ||
        loadingProfile ||
        (numbersStore.isFirstNumber && !numbersStore.toolFreeNumbers.length)
      )
    }, [businessProfileStore.completed, loading, loadingProfile])

    const onVerify: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation()
      if (fromModal && !completed) {
        addNumberStore.setOpenAddNumberModal(false)
        navigate(RoutesEnum.settingsCompliance)
      } else {
        if (!numbersStore.toolFreeNumbers.length) {
          addNumberStore.setOpenFirstTollFreeNumberModal(true)
        } else {
          navigate(
            `${RoutesEnum.settingsCompliance}/${RoutesEnum.tollFreeVerification}`
          )
        }
      }
    }
    const onOpenModal = () => {
      if (completed) {
        setOpenModal(true)
      }
    }
    if (fromModal && completed) {
      return <></>
    }
    return (
      <div>
        <AddTollFreeFirstModal />
        {completed && (
          <span className={'s2 gray1 medium mb8 mt24'}>
            Toll-Free verification
          </span>
        )}
        {/*eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/}
        <div
          className={classNames(styles.wrap, completed && styles.completed)}
          onClick={onOpenModal}
        >
          <div className={'col16'}>
            <div className={'col8'}>
              <p>
                <b>{completed ? useCaseName : 'Toll-Free verification'}</b>
              </p>
              <span
                className={classNames(
                  fromModal && styles.fromModal,
                  's2 gray1'
                )}
              >
                {completed
                  ? `${selectedNumbersOptions.length} numbers`
                  : 'Verify your toll-free numbers to ensure better deliverability. Toll-free verification is required if you want to send messages to United States or Canada.'}
              </span>
            </div>
            {!!completedStep && !completed && (
              <div className={'row8'}>
                <CircularProgress progress={completedPercent} small />
                <span className={'gray1'}>
                  {completedStep}/{stepCount} steps
                </span>
              </div>
            )}
          </div>
          {!noAction &&
            (completed && status ? (
              <div className={'row24'}>
                <StatusContent
                  status={status}
                  text={typesStore.tollFreeMapping.get(status)}
                />
                {status === TollFreeVerificationStatus.Rejected && (
                  <SmallBtn type={ActionsEnum.edit} gray4 onClick={onVerify} />
                )}
              </div>
            ) : (
              <MuiTooltip
                title={
                  fromModal || !disabledContinue
                    ? ''
                    : 'You will need to register your business before you are able to verify your toll-free numbers'
                }
                width={224}
                arrow
                placement={'left'}
              >
                <div>
                  <Button
                    size={BtnSize.medium}
                    typeBtn={
                      fromModal ? BtnType.secondaryBlue : BtnType.primary
                    }
                    onClick={onVerify}
                    disabled={disabledContinue && !fromModal}
                  >
                    {!!completedStep && !completed ? 'Resume' : 'Verify'}
                  </Button>
                </div>
              </MuiTooltip>
            ))}
        </div>
      </div>
    )
  }
)
