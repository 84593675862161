// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oSVBUEsPjX0wgqTVlDsg{height:368px;display:grid;grid-template-columns:1fr 200px;gap:24px}.VFYUPBu51KqgJvVj5_o6{display:flex;align-items:center}.u59UYiTxHm141ZRdsTZ0{white-space:nowrap;background-color:var(--states-color-success-3);padding:2px 8px;border-radius:8px;margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/routes/auth/migration/stpes/PaymentStep/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,+BAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAEF,sBACE,kBAAA,CACA,8CAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":[".wrap{\n  height: 368px;\n  display: grid;\n  grid-template-columns: 1fr 200px;\n  gap: 24px;\n}\n\n.rightInputContent{\n  display: flex;\n  align-items: center;\n}\n.save{\n  white-space: nowrap;\n  background-color: var(--states-color-success-3);\n  padding: 2px 8px;\n  border-radius: 8px;\n  margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `oSVBUEsPjX0wgqTVlDsg`,
	"rightInputContent": `VFYUPBu51KqgJvVj5_o6`,
	"save": `u59UYiTxHm141ZRdsTZ0`
};
export default ___CSS_LOADER_EXPORT___;
