import { useGetIntegrationCustomFieldsQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import triggersStore from 'store/triggers/triggersStore'

export function useGetIntegrationsFields({
  onFinish,
  byPassCache,
}: {
  onFinish?: () => void
  byPassCache?: boolean
}) {
  const {
    data,
    loading: loadingFields,
    refetch: refetchFields,
  } = useGetIntegrationCustomFieldsQuery({
    variables: {
      byPassCache,
    },
  })

  useEffect(() => {
    if (data) {
      triggersStore.setIntegrationCustomFieldsData(data)
      onFinish && onFinish()
    }
  }, [data])
  return { loadingFields, refetchFields }
}
