import classNames from 'classnames'
import styles from 'components/Input/InputText/styles.module.scss'
import { ReactComponent as Point } from 'assets/icons/16px/Bullet Point.svg'
import { ReactComponent as Check } from 'assets/icons/16px/Check.svg'
import { FC, ReactNode } from 'react'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ActionsEnum } from 'components/Button/types'
import { ColorsNames } from 'styles/variables'

type Props = {
  text: string
  neutral?: boolean
  isValid?: boolean
  mt?: number
  rightContent?: ReactNode
}

export const ValidItem: FC<Props> = ({
  text,
  neutral,
  isValid,
  mt,
  rightContent,
}) => (
  <div
    className={classNames(
      styles.li,
      !neutral && isValid && styles.positive,
      !neutral && !isValid && styles.negative
    )}
    style={{ marginTop: mt }}
  >
    {neutral ? (
      <Point />
    ) : isValid ? (
      <Check />
    ) : (
      <AppIcon
        icon={ActionsEnum.close}
        color={ColorsNames.error1}
        type={IIconType.stroke}
      />
    )}
    <span
      className={classNames(
        's2 medium gray1',
        neutral ? 'gray1' : isValid ? 'success1' : 'error1'
      )}
    >
      {text}
    </span>
    {rightContent}
  </div>
)
