import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { ReactComponent as Star } from 'icons/16pxNoMask/Star.svg'
import { observer } from 'mobx-react-lite'
import { StatusContent } from 'components/StatusContent/StatusContent'
import * as React from 'react'
import { FC } from 'react'
import campaignDetailStore from 'store/campaigns/campaignDetailStore'
import { getCampaignsActions } from 'src/routes/main/campaigns/functions/getCampaignsActions'
import { MoreBtn, MoreBtnItemProps } from 'components/Button/MoreBtn'
import { CampaignDetailModal } from 'src/routes/main/campaigns/modals/CampaignDetailModal'
import { numberString } from 'src/util/functions'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'

export const HeaderContent = observer(() => {
  const navigate = useNavigate()
  const { campaign, overviewMessageStatistics } = campaignDetailStore

  if (!campaign) {
    return <></>
  }
  const actions = getCampaignsActions(campaign)

  const rowActions = actions.slice(0, 3)
  const moreActions = actions.slice(3)
  return (
    <div className={styles.header}>
      <CampaignDetailModal />
      <BreadCrumbs />
      <div className={styles.nav}>
        <div className={styles.left}>
          <div>
            <Button hoverGray size={BtnSize.small} onClick={() => navigate(-1)}>
              <Angle />
            </Button>
          </div>
          <h1>{campaign?.name}</h1>
        </div>
        <div className={styles.right}>
          {rowActions.map((Action, index) => (
            <Action key={index} medium />
          ))}
          {!!moreActions?.length && (
            <MoreBtn
              menuItems={moreActions as Array<FC<MoreBtnItemProps>>}
              tooltip={'More'}
              medium
            />
          )}
        </div>
      </div>
      <div className={styles.rowInfo}>
        {campaign?.status && (
          <>
            <StatusContent status={campaign?.status} />
            <div className={'point'} />
          </>
        )}
        <div className={styles.creditCount}>
          <Star />
          <span>
            {numberString({
              val:
                overviewMessageStatistics?.chargeStatistics?.totalCharged || 0,
            })}{' '}
            credits
          </span>
        </div>
      </div>
    </div>
  )
})
