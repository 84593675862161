import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import * as React from 'react'
import { RequiredFields, TableProps } from 'components/NewTable/types'

export const AdditionalContent = observer(
  <T extends RequiredFields<T>>({ additionalContent }: TableProps<T>) => {
    if (!additionalContent) {
      return <></>
    }
    return <div className={styles.additionalContent}>{additionalContent}</div>
  }
)
