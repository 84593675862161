import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { Counter } from 'components/Counter/Counter'
import { periodsOptions } from 'components/Schedule/store/mockData'
// import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
// import { WeeklyDaysSelector } from 'components/Schedule/WeeklyDaysSelector/WeeklyDaysSelector'
// import { RepeatContent } from 'components/Schedule/LaterContent/RepeatContent/RepeatContent'
import { CampaignScheduleStore } from 'components/Schedule/store/campaignScheduleStore'
import { CampaignDelayPeriod } from 'src/generated/graphql'
import dayjs from 'lib/dayjs'
import { TimePicker } from 'components/TimePicker/TimePicker'

export const LaterContentCampaign: FC<{
  scheduleStore: CampaignScheduleStore
}> = observer(({ scheduleStore }) => {
  useEffect(() => {
    if (
      scheduleStore.laterPeriod?.value !== CampaignDelayPeriod.Hour &&
      scheduleStore.laterPeriod?.value !== CampaignDelayPeriod.Minute &&
      !scheduleStore.laterDate
    ) {
      scheduleStore.setLaterDate(
        dayjs().startOf('day').add(12, 'hours').toDate()
      )
    }
  }, [scheduleStore.activeSendLater, scheduleStore.laterPeriod])
  if (!scheduleStore.activeSendLater) {
    return <></>
  }
  return (
    <>
      <div className={styles.wrap}>
        <Counter
          count={scheduleStore.laterCount}
          onMinus={() =>
            scheduleStore.setLaterCount(+scheduleStore.laterCount - 1)
          }
          onPlus={() =>
            scheduleStore.setLaterCount(+scheduleStore.laterCount + 1)
          }
          onChange={scheduleStore.setLaterCount}
        />
        <Dropdown
          options={periodsOptions}
          selectedOption={scheduleStore.laterPeriod}
          onSelect={(opt) => scheduleStore.setLaterPeriod(opt)}
          parentElement={scheduleStore.refModalContent || undefined}
        />
        {scheduleStore.laterPeriod?.value !== CampaignDelayPeriod.Hour &&
          scheduleStore.laterPeriod?.value !== CampaignDelayPeriod.Minute && (
            <div className={styles.time}>
              <TimePicker
                startTime={scheduleStore.laterDate}
                onChange={(time) => scheduleStore.setLaterDate(time)}
              />
            </div>
          )}
      </div>

      {/*<div className={styles.advanced}>*/}
      {/*  <InputCheckbox*/}
      {/*    label={'Advanced Scheduling'}*/}
      {/*    checked={scheduleStore.laterAdvanced}*/}
      {/*    onChecked={() =>*/}
      {/*      scheduleStore.setLaterAdvanced(!scheduleStore.laterAdvanced)*/}
      {/*    }*/}
      {/*    noBordered2*/}
      {/*    small*/}
      {/*  />*/}
      {/*  {scheduleStore.laterAdvanced && (*/}
      {/*    <div className={styles.advancedContent}>*/}
      {/*      <WeeklyDaysSelector*/}
      {/*        selectWeeklyDay={(day) =>*/}
      {/*          scheduleStore.selectLaterWeeklyDay(day)*/}
      {/*        }*/}
      {/*        selectedWeeklyDays={scheduleStore.customRepeatWeekConfigDays}*/}
      {/*      />*/}
      {/*      <TimePicker*/}
      {/*        startTime={scheduleStore.laterAdvancedTime}*/}
      {/*        onChange={(time) => scheduleStore.setLaterAdvancedTime(time)}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}
    </>
  )
})
