// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EuQ9CPzWjJeFqQRpu9Su{z-index:2;position:relative}.h05xCwVv6SPSFMFEuGwH{padding:16px}.hmf2i0D0XsD0ghWbdgXI{display:flex;margin-bottom:16px}.hmf2i0D0XsD0ghWbdgXI div{display:flex}.hmf2i0D0XsD0ghWbdgXI svg{margin-left:4px}.hmf2i0D0XsD0ghWbdgXI svg g path{fill:var(--primary-color-gray-2)}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/ShortenLink/styles.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,iBAAA,CAEF,sBACE,YAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,0BACE,YAAA,CAEF,0BACE,eAAA,CCbE,iCACE,gCDca","sourcesContent":["@import \"src/styles/mixin\";\n\n.wrap{\n  z-index: 2;\n  position: relative;\n}\n.content{\n  padding: 16px;\n}\n.header{\n  display: flex;\n  margin-bottom: 16px;\n  div{\n    display: flex;\n  }\n  svg{\n    margin-left: 4px;\n  }\n  @include svg-color(var(--primary-color-gray-2))\n}\n","@mixin svg-color($color){\n  svg {\n    g {\n      path {\n        fill: $color;\n      }\n    }\n\n  }\n}\n@mixin svg-color-rect($color){\n  svg {\n    g {\n      rect {\n        fill: $color;\n      }\n    }\n\n  }\n}\n\n@mixin svg-color-stroke($color){\n  svg{\n    path{\n      stroke: $color;\n    }\n  }\n}\n@mixin svg-color-circle($color){\n  svg{\n    circle{\n      fill: $color;\n    }\n  }\n}\n@mixin svg-color-fill($color){\n  svg{\n    path{\n      fill: $color;\n    }\n  }\n}\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n@mixin scroll-4{\n  &::-webkit-scrollbar, &::-webkit-scrollbar-track {\n    width: 4px;\n    height: 4px;\n    border-radius: 3px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `EuQ9CPzWjJeFqQRpu9Su`,
	"content": `h05xCwVv6SPSFMFEuGwH`,
	"header": `hmf2i0D0XsD0ghWbdgXI`
};
export default ___CSS_LOADER_EXPORT___;
