import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import { IntegrationsOAuth } from 'src/routes/settings/integrations/integrationsOAuth'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import auth from 'store/auth'
import { AuthorizedApp } from 'components/App/AuthorizedApp/AuthorizedApp'
import { UnauthorizedApp } from 'components/App/UnauthorizedApp/UnauthorizedApp'
import { usePostHog } from 'posthog-js/react'

export const PublicApp = observer(() => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const posthog = usePostHog()

  useEffect(() => {
    if (analytics.page) {
      analytics.page()
    }
    if (posthog) {
      posthog.capture('$pageview')
    }
  }, [pathname])
  useEffect(() => {
    if (searchParams) {
      const assumeToken = searchParams.get('assumeToken')
      const stripeCoupon = searchParams.get('c')
      if (assumeToken) {
        auth.setAssumeToken(assumeToken)
        navigate(RoutesEnum.broadcasts)
      }
      if (stripeCoupon) {
        auth.setStripeCoupon(stripeCoupon)
      }
    }
  }, [searchParams])
  return (
    <Routes>
      <Route
        path={RoutesEnum.settings + '/' + RoutesEnum.integrations + '/:type'}
        element={<IntegrationsOAuth />}
      />
      <Route
        path={'/*'}
        element={auth.isAuth ? <AuthorizedApp /> : <UnauthorizedApp />}
      />
    </Routes>
  )
})
