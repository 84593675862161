import { SizedBox } from 'components/SizedBox'
import { InputText } from 'components/Input/InputText/InputText'
import user from 'store/user/user'
import { observer } from 'mobx-react-lite'
import accountStore from 'store/settings/accountStore'
import { useEffect, useState } from 'react'
import {
  useChangeEmailMutation,
  useConfirmChangeEmailMutation,
} from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import alertStore from 'store/alertStore'
import auth from 'store/auth'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { ErrorsEnum } from 'src/static/errors'

export const EmailAuthContent = observer(() => {
  const [changeEmail, { loading }] = useChangeEmailMutation()
  const [confirmChangeEmail] = useConfirmChangeEmailMutation()
  const [email, setEmail] = useState(user.email)
  const [emailError, setEmailError] = useState('')
  const onVerify = async (code: number | string) => {
    const { data } = await confirmChangeEmail({
      variables: {
        input: {
          verificationCode: +code,
        },
      },
    })
    if (data?.confirmChangeEmail?.email) {
      alertStore.setAlert({
        type: AlertTypeEnum.success,
        title: 'Email changed',
      })
      user.setMemberEmail(data.confirmChangeEmail?.email)
    }
    if (data?.confirmChangeEmail?.token) {
      auth.setToken(data?.confirmChangeEmail?.token)
    }
    return
  }
  const onCancel = async () => {
    setEmail(user.email)
  }
  const onResend = () => {
    return changeEmail({
      variables: {
        input: {
          email,
        },
      },
    })
  }
  const onBlur = () => {
    if (email.includes('@') && email.includes('.')) {
      if (email.trim() !== user.email.trim()) {
        changeEmail({
          variables: {
            input: {
              email,
            },
          },
        })
          .then(({ data }) => {
            accountStore.setOpenVerifyNumberModal(true, {
              title: 'Verify your identity',
              info1: `We’ve sent it to ${user.email}`,
              info2: 'Didn’t receive the code?',
              size: 6,
              onVerify,
              onCancel,
              onResend,
              resendRemainingSeconds: data?.changeEmail?.resendRemainingSeconds,
            })
          })
          .catch((e) => {
            if (e instanceof Error) {
              if (e.message.includes('already')) {
                alertStore.disabledErrorAlert(e.message)
                setEmailError(ErrorsEnum.alreadyUseEmail)
              }
            }
          })
      }
    } else {
      setEmailError(ErrorsEnum.verifyEmail)
    }
  }

  const onFocus = () => {
    setEmailError('')
  }

  useEffect(() => {
    setEmail(user.email)
  }, [user.email])
  return (
    <>
      {loading && <AbsoluteLoader size={40} />}
      <p className={'mb8'}>
        <b>Email authentication</b>
      </p>
      <span className={'s2 gray1'}>You can log in with your password</span>
      <SizedBox height={32} />
      <InputText
        id={'email'}
        label={'Email address'}
        placeholder={'email@address.com'}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onClickRightIcon={() => setEmail('')}
        onBlur={onBlur}
        blurOnEnter
        error={emailError}
        onFocus={onFocus}
      />
    </>
  )
})
