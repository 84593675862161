// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jhQau1XsSxFhneIooiI0{margin-top:24px;margin-right:8px}.ED7ZRdxFEwMgW5E2P2uv{height:136px;position:relative}.yI4GGDz9o_s8uLN1W5KE{height:100%;margin-right:24px;display:flex;align-items:center;justify-content:center;background-color:var(--primary-color-gray-4);border-radius:16px}.BSjXQ8CZG7K1e9rQ8cQ_{display:grid;grid-template-columns:max-content 1fr;padding-right:32px;gap:12px}`, "",{"version":3,"sources":["webpack://./src/widgets/MessageTemplate/MessageTemplatesModal/MyTemplatesTab/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,eAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,iBAAA,CAGF,sBACE,WAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,4CAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,qCAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":["\n.wrapContent {\n  margin-top: 24px;\n  margin-right: 8px;\n}\n\n.wrapEmpty{\n  height: 136px;\n  position: relative;\n}\n\n.empty {\n  height: 100%;\n  margin-right: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--primary-color-gray-4);\n  border-radius: 16px;\n}\n\n.actions{\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  padding-right: 32px;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapContent": `jhQau1XsSxFhneIooiI0`,
	"wrapEmpty": `ED7ZRdxFEwMgW5E2P2uv`,
	"empty": `yI4GGDz9o_s8uLN1W5KE`,
	"actions": `BSjXQ8CZG7K1e9rQ8cQ_`
};
export default ___CSS_LOADER_EXPORT___;
