import { FC } from 'react'
import { Route } from 'react-router-dom'

export interface AppRoute {
  path: string
  Element: FC
}

export const renderRoute = ({ path, Element }: AppRoute) => (
  <Route key={path} path={path} element={<Element />} />
)
