import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { downloadFile } from 'src/util/downloadFile'
import * as React from 'react'
import { AxiosResponse } from 'axios'
import { IResponseExport } from 'src/apiRest/type'

export const onExport = async ({
  info,
  request,
}: {
  info: string
  request: Promise<AxiosResponse<IResponseExport>>
}) => {
  try {
    alertStore.setAlert({
      type: AlertTypeEnum.blue3,
      title: `Exporting the ${info}. We'll notify you when the export finishes.`,
    })

    const { data } = await request
    const reportUrl = data.reportUrl
    if (reportUrl) {
      alertStore.setAlert(
        {
          type: AlertTypeEnum.success,
          title: `The ${info} is ready and emailed to you`,
          delay: 5000,
          rightContent: (
            <button
              onClick={() => downloadFile(reportUrl)}
              className={'s1 bold white'}
            >
              Download
            </button>
          ),
        },
        true
      )
    } else {
      alertStore.setAlert({
        type: AlertTypeEnum.success,
        title: `The ${info} will be ready soon. We'll email you when the export finishes.`,
      })
    }
  } catch (e) {
    if (e instanceof Error) {
      alertStore.disabledErrorAlert(e.message)
      alertStore.setAlert({
        type: AlertTypeEnum.error,
        title: `The ${info} failed to export`,
      })
    }
    console.error(e)
  }
}
