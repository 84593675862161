// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pkiZ36eQmSyAzL7bfNO8{width:68px;height:48px;position:relative;border-radius:8px;cursor:pointer}.pkiZ36eQmSyAzL7bfNO8.QlpNxfNNXyoHi_BKNi6P{width:62px;height:44px}.pkiZ36eQmSyAzL7bfNO8.ufb2VwWDySMkn8ewtvvL{width:128px;height:92px}.ySeROt4ALi1MUXqtw7EE{z-index:1;position:absolute;width:100%;height:100%;background-position:50% 50%;background-size:contain;background-repeat:no-repeat;border-radius:8px;background-color:#f3f5f8}.bqstrxE9KuHD3VxdYAXm{z-index:2;position:absolute;width:100%;height:100%;border-radius:8px;background:var(--hover-small-img-gradient);padding:4px;display:flex;justify-content:right;align-items:flex-start}`, "",{"version":3,"sources":["webpack://./src/components/ImageCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBASE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,cAAA,CAZA,2CACE,UAAA,CACA,WAAA,CAEF,2CACE,WAAA,CACA,WAAA,CAQJ,sBACE,SAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,2BAAA,CACA,uBAAA,CACA,2BAAA,CACA,iBAAA,CACA,wBAAA,CAEF,sBACE,SAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,0CAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA","sourcesContent":[".wrap{\n  &.small{\n    width: 62px;\n    height: 44px;\n  }\n  &.medium{\n    width: 128px;\n    height: 92px;\n  }\n  width: 68px;\n  height: 48px;\n  position: relative;\n  border-radius: 8px;\n  cursor: pointer;\n}\n.img{\n  z-index: 1;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-position: 50% 50%;\n  background-size: contain;\n  background-repeat: no-repeat;\n  border-radius: 8px;\n  background-color: #F3F5F8;\n}\n.addInfo{\n  z-index: 2;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  border-radius: 8px;\n  background: var(--hover-small-img-gradient);\n  padding: 4px;\n  display: flex;\n  justify-content: right;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `pkiZ36eQmSyAzL7bfNO8`,
	"small": `QlpNxfNNXyoHi_BKNi6P`,
	"medium": `ufb2VwWDySMkn8ewtvvL`,
	"img": `ySeROt4ALi1MUXqtw7EE`,
	"addInfo": `bqstrxE9KuHD3VxdYAXm`
};
export default ___CSS_LOADER_EXPORT___;
