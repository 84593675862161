import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Alert } from 'components/Alert/Alert'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Table, TableVariant } from 'components/Table/Table'
import React, { useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { SizedBox } from 'components/SizedBox'
import {
  Status,
  useCreateBroadcastMutation,
  useGetBroadcastEstimatedPriceQuery,
  useUpdateBroadcastMutation,
} from 'src/generated/graphql'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import { numberString } from 'src/util/functions'
import billingStore from 'store/settings/billing/billingStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { CreateTestItBroadcastBtn } from 'src/routes/main/broadcasts/components/CreateTestItBroadcastBtn'
import { BroadcastLimitAlert } from 'src/routes/main/broadcasts/createBroadcast/newBroadcast/BroadcastLimitAlert'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import classNames from 'classnames'
import { useCreateBroadcastContext } from 'src/routes/main/broadcasts/createBroadcast/context/CreateBroadcastContext'
import { openBuyCreditsModal } from 'store/settings/billing/actions'
import { useApolloError } from 'src/hooks/useApolloError'

interface IReviewTable {
  sendFrom: string
  sendTime: string
  totalContacts: number
  totalCredits: number
}

export const CreateBroadcastsModal = observer(() => {
  const [openTest, setOpenTest] = useState(false)
  const navigate = useNavigate()
  const [createBroadcast, { loading: loadingCreate, error: errorCreate }] =
    useCreateBroadcastMutation()
  const [updateBroadcast, { loading: loadingUpdate, error: errorUpdate }] =
    useUpdateBroadcastMutation()
  const newBroadCastStore = useCreateBroadcastContext()

  const { errorMessage, ErrorAlert, setErrorMessage } = useApolloError(
    errorCreate || errorUpdate
  )

  const { data, refetch, loading } = useGetBroadcastEstimatedPriceQuery({
    variables: {
      broadcastInput: newBroadCastStore.broadcastInput,
    },
    skip: !newBroadCastStore.openCreateModal,
  })

  useEffect(() => {
    if (newBroadCastStore.openCreateModal) {
      refetch()
    } else {
      setErrorMessage('')
    }
  }, [newBroadCastStore.openCreateModal])

  useEffect(() => {
    if (data) {
      newBroadCastStore.setEstimate(data)
    }
  }, [data])

  const dataTable: IReviewTable[] = useMemo(() => {
    return [
      {
        sendFrom:
          newBroadCastStore.broadcastInput.sendFromPhoneNumber?.friendlyName ||
          '',
        sendTime: newBroadCastStore.scheduleStore.sendTimeString,
        totalContacts: newBroadCastStore.totalContacts,
        totalCredits: newBroadCastStore.totalCredits,
      },
    ]
  }, [
    newBroadCastStore.broadcastInput.sendFromPhoneNumber?.friendlyName,
    newBroadCastStore.dropdownContactListsStore.selectedContactsCount,
    newBroadCastStore.scheduleStore.sendTimeString,
    newBroadCastStore.totalContacts,
    newBroadCastStore.totalCredits,
  ])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const columns: Column<IReviewTable>[] = useMemo(() => {
    return [
      {
        Header: 'Send from',
        accessor: 'sendFrom',
      },
      {
        Header: 'Send time',
        accessor: 'sendTime',
      },
      {
        Header: 'Total Contacts',
        accessor: 'totalContacts',
        Cell: ({ value }) => numberString({ val: value }),
      },
      {
        Header: 'Total Credits',
        accessor: 'totalCredits',
        Cell: ({ value }) => numberString({ val: value }),
      },
    ]
  }, [newBroadCastStore.broadcastInput.sendFromPhoneNumber])
  const onClose = () => {
    newBroadCastStore.setOpenCreateModal(false)
  }
  const onSchedule = async () => {
    try {
      if (newBroadCastStore.edit) {
        await updateBroadcast({
          variables: {
            broadcastInput: {
              ...newBroadCastStore.broadcastInput,
              status:
                newBroadCastStore.broadcastInput.status === Status.Draft
                  ? Status.Scheduled
                  : newBroadCastStore.broadcastInput.status,
            },
          },
        })
      } else {
        await createBroadcast({
          variables: {
            broadcastInput: newBroadCastStore.broadcastInput,
          },
        })
      }

      broadcastsStore.tableStore.onRefresh()
      navigate(RoutesEnum.broadcasts)
    } catch (e) {
      console.error(e)
    }
  }

  const info = `To send your broadcast you need at least ${numberString({
    val: newBroadCastStore.neededCredits,
  })} credits more`

  return (
    <Modal
      open={newBroadCastStore.openCreateModal}
      onClose={onClose}
      title={'Review and schedule'}
    >
      <div className={styles.wrap}>
        {<ErrorAlert />}
        {!newBroadCastStore.broadcastAllowed && (
          <BroadcastLimitAlert type={AlertTypeEnum.error} />
        )}
        {newBroadCastStore.neededCredits > 0 && (
          <Alert
            title={info}
            type={AlertTypeEnum.notify2}
            rightContent={
              billingStore.isTrial ? (
                <button
                  className={classNames(styles.upgrade, 's1 link bold')}
                  onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
                >
                  Upgrade
                </button>
              ) : (
                <button
                  className={classNames(styles.upgrade, 's1 link bold')}
                  onClick={() => openBuyCreditsModal(true, info)}
                >
                  Buy credits
                </button>
              )
            }
          />
        )}
        <SizedBox height={12} />
        <Table<IReviewTable>
          columns={columns}
          data={dataTable}
          variant={TableVariant.variant1}
        />
        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onSchedule}
            loading={loadingCreate || loadingUpdate}
            disabled={
              !!errorMessage ||
              newBroadCastStore.isDisableByLimit ||
              loading ||
              newBroadCastStore.neededCredits > 0 ||
              !newBroadCastStore.broadcastAllowed
            }
          >
            Schedule
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={() => newBroadCastStore.setOpenCreateModal(false)}
          >
            Cancel
          </Button>
          <div />
          <div className={styles.wrapTest}>
            <CreateTestItBroadcastBtn
              setOpenTest={() => setOpenTest(!openTest)}
              openTest={openTest}
              newBroadCastStore={newBroadCastStore}
              widthInput={390}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
})
