import { ICreditPrice } from 'store/settings/billing/type'
import { IOption } from 'src/types/IOption'
import { Currencies, numberString } from 'src/util/functions'
import { openUpgradePlanModal } from 'src/features/openUpgradePlanModal'

export const iCreditPriceToOption = (
  price: ICreditPrice,
  fromMigration?: boolean
): IOption => ({
  value: price?.creditsAmount,
  title: numberString({ val: price?.creditsAmount || 0 }),
  rightContent: price.allowed ? (
    <span className={'gray1'}>
      {numberString({ val: price?.cost || 0, currency: Currencies.usd })}
    </span>
  ) : (
    <button className={'s1 gray1 nowrap'}>Upgrade plan</button>
  ),
  onClick: price.allowed || fromMigration ? undefined : openUpgradePlanModal,

  data: price,
})
