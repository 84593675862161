import styles from 'components/Preview/styles.module.scss'
import { MessagePreview } from 'components/Preview/Message'
import { FC } from 'react'
import { ISMSMessages } from 'components/Preview/types'
import { observer } from 'mobx-react-lite'

export const SMSMessageContent: FC<ISMSMessages> = observer(
  ({ smsMessages, phone, company }) => {
    return (
      <div className={styles.messageWrap}>
        {smsMessages.map((message) => (
          <MessagePreview
            company={company}
            key={message.messageId}
            message={message}
            phone={phone || 'Your number'}
            placeholder={message.placeholder}
          />
        ))}
      </div>
    )
  }
)
