import { Alert } from 'components/Alert/Alert'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import configStore from 'store/configStore'
import companyStore from 'store/settings/company/companyStore'

export const DealInToRecord = observer(() => {
  return (
    <div>
      <Alert
        title={
          'Call us at the number below and enter your PIN when prompted to begin recording'
        }
      />
      <div className={styles.wrapDeal}>
        <div className={styles.row}>
          <span className={'gray1'}>Dial:</span>
          <span className={'bold'}>
            {configStore.twilioCallRecordingNumber}
          </span>
        </div>
        <div className={styles.row}>
          <span className={'gray1'}>Enter PIN:</span>
          <span className={'bold'}>
            {companyStore.organization?.recordingPin}
          </span>
        </div>
      </div>
    </div>
  )
})
