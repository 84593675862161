import { useEffect } from 'react'
import user from 'store/user/user'

export function useRegisterHubspotMember() {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    script.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      hbspt &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        hbspt.forms.create({
          region: process.env.FE_HUBSPOT_REGION,
          portalId: process.env.FE_HUBSPOT_PORTAL_ID,
          formId: process.env.FE_HUBSPOT_LEAD_FORM_ID,
          onFormReady: (form: HTMLFormElement) => {
            const emailInput = form.querySelector(
              "input[name='email']"
            ) as HTMLInputElement
            if (emailInput) {
              emailInput.value = user.email
            }

            form.submit()
          },
        })
    }
    document.body.appendChild(script)
  }, [])
}
