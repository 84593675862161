import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useContactFunctions } from 'src/routes/main/contacts/hooks/useContactFunctions'
import { IContactActions } from 'src/routes/main/contacts/functions/getContactActions'

export const ContactsValidateBtn = (props: IContactActions) => {
  const { onValidatePhone } = useContactFunctions(props)
  const handleClick = async () => {
    await onValidatePhone()
    if (props.onCloseTT) {
      props.onCloseTT()
    }
  }
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.validatePhone}
      onAction={handleClick}
    />
  )
}
