import { getManyAudioActions } from 'src/routes/settings/audio/functions/getManyAudioActions'
import { IAudioItem } from 'src/types/IAudioItem'

export const ManySelectContent = ({
  audioItems,
}: {
  audioItems: IAudioItem[]
}) => {
  const actions = getManyAudioActions(audioItems)

  return (
    <>
      {actions.map((Action, index) => (
        <Action key={index} medium />
      ))}
    </>
  )
}
