import classNames from 'classnames'
import styles from 'components/NavBar/styles.module.scss'
import { ReactComponent as LogOut } from 'assets/icons/20px/Log Out.svg'
import { googleLogout } from '@react-oauth/google'
import auth from 'store/auth'
import { useEffect, useState } from 'react'
import { usePostHog } from 'posthog-js/react'

export function useLogout() {
  const [confirmLogout, setConfirmLogout] = useState(false)
  const posthog = usePostHog()
  useEffect(() => {
    let id: NodeJS.Timeout
    if (confirmLogout) {
      id = setTimeout(() => {
        setConfirmLogout(false)
      }, 2500)
    }
    return () => {
      clearTimeout(id)
    }
  }, [confirmLogout])
  const onLogoutSuccess = () => {
    auth.onLogout()
    if (posthog) {
      posthog.reset(true)
    }
  }
  const onLogout = (withoutConfirm = false) => {
    if (confirmLogout || withoutConfirm) {
      if (auth.withGoogle) {
        googleLogout()
      }
      onLogoutSuccess()
    } else {
      setConfirmLogout(true)
    }
  }
  return { onLogout, confirmLogout }
}

export const Logout = () => {
  const { onLogout, confirmLogout } = useLogout()
  return (
    <button
      className={classNames(
        styles.logoutBtn,
        confirmLogout && styles.confirmLogout
      )}
      onClick={() => onLogout()}
    >
      <div className={classNames(styles.navLink)}>
        <div className={styles.leftIcon}>
          <LogOut />
        </div>
        <span className={'nowrap'}>
          {confirmLogout ? 'Confirm log out' : 'Log Out'}
        </span>
      </div>
    </button>
  )
}
