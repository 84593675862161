import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import * as React from 'react'
import { observer } from 'mobx-react-lite'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import {
  TollFreeVerificationProfile,
  useAdminInitTollFreeVerificationTermsMutation,
} from 'src/generated/graphql'

export const SuperAdminTFProfileActions = observer(
  ({
    profile,
    organizationId,
  }: {
    profile: TollFreeVerificationProfile
    organizationId: number
  }) => {
    const { setOpenEditModal, setOpenModal, adminOrgId, completed } =
      tollFreeVerificationStore
    const onEdit = () => {
      setOpenEditModal(true, profile, organizationId)
    }
    const [adminInitTollFreeVerificationTerms, { loading }] =
      useAdminInitTollFreeVerificationTermsMutation()
    const onSubmit = async () => {
      try {
        const { data } = await adminInitTollFreeVerificationTerms({
          variables: {
            organizationId: adminOrgId,
            shouldSend: true,
          },
        })
        if (data?.adminInitTollFreeVerificationTerms && adminOrgId)
          setOpenModal(
            true,
            data.adminInitTollFreeVerificationTerms,
            adminOrgId
          )
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <div className={'mb32 row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onSubmit}
          disabled={!completed}
          loading={loading}
        >
          Submit for review
        </Button>
        <MediumBtn type={ActionsEnum.edit} onClick={onEdit} />
      </div>
    )
  }
)
