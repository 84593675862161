import { PresentationImages } from 'components/PresentatinImages/PresentationImages'
import { VCardModal } from 'components/VCard/VCardModal'
import { ImportContactModal } from 'src/Modals/ImportContactModal/ImportContactModal'
import { UpgradePlanModal } from 'src/Modals/UpgradePlanModal/UpgradePlanModal'
import { AudioModal } from 'src/Modals/AudioModal/AudioModal'
import { UploadAudioModal } from 'src/Modals/UploadAudioModal/UploadAudioModal'
import { CreateTextToSpeechModal } from 'src/Modals/CreateTextToSpeechModal/CreateTextToSpeechModal'
import { VerifyModal } from 'src/Modals/VerifyModal/VerifyModal'
import { AddNumberModal } from 'src/Modals/AddNumberModal/AddNumberModal'
import React from 'react'
import { CongratsModal } from 'src/Modals/CongratsModal/CongratsModal'
import { AddContactModal } from 'src/routes/main/contacts/modals/AddContactModal/AddContactModal'
import { CustomFieldsModal } from 'src/routes/main/contacts/customFields/modals/CustomFieldsModal/CustomFieldsModal'
import { EditContactModal } from 'src/routes/main/contacts/modals/AddContactModal/EditContactModal'
import { UnsubscribeContactModal } from 'src/routes/main/contacts/UnsubscribeContactModal/UnsubscribeContactModal'
import { TestWebFormModal } from 'src/routes/main/webForms/modals/TestWebFormModal/TestWebFormModal'
import { GetWebFromCodeModal } from 'src/routes/main/webForms/modals/GetWebFromCodeModal/GetWebFromCodeModal'
import { BuyCreditsModal } from 'src/routes/settings/billing/modals/BuyCreditsModal/BuyCreditsModal'
import { SuccessByCreditsModal } from 'src/routes/settings/billing/modals/SuccessByCreditsModal/SuccessByCreditsModal'
import { CancelSubscriptionModal } from 'src/routes/settings/billing/modals/CancelSubscriptionModal/CancelSubscriptionModal'
import { PauseSubscriptionModal } from 'src/routes/settings/billing/modals/PauseSubscriptionModal/PauseSubscriptionModal'
import { SuccessPausedSubscriptionModal } from 'src/routes/settings/billing/modals/SuccessPausedSubscriptionModal/SuccessPausedSubscriptionModal'
import { ContinueCancelSubscriptionModal } from 'src/routes/settings/billing/modals/ContinueCancelSubscriptionModal/ContinueCancelSubscriptionModal'
import { BuySubscriptionModal } from 'src/routes/settings/billing/modals/BuySubscriptionModal/BuySubscriptionModal'
import { SuccessCanceledSubscriptionModal } from 'src/routes/settings/billing/modals/SuccessCanceledSubscriptionModal/SuccessCanceledSubscriptionModal'
import { NewKeywordModal } from 'src/routes/main/keywords/modals/NewKeywordModal/NewKeywordModal'
import { NewWebFormModal } from 'src/routes/main/webForms/modals/NewWebFormModal/NewWebFormModal'
import { ClicksDetailsModal } from 'components/Clicks/ClicksDetailsModal'
import { EditListsModal } from 'src/routes/main/contacts/modals/EditListsModal/EditListsModal'
import { LimitSubscriptionModal } from 'src/routes/settings/billing/modals/LimitSubscriptionModal/LimitSubscriptionModal'
import { PhoneCheckrModal } from 'src/routes/settings/billing/components/PhoneCheckr/modal/PhoneCheckrModal'
import { TurnOnAutoRechargeModal } from 'src/routes/settings/billing/modals/TurnOnAutoRechargeModal/TurnOnAutoRechargeModal'
import { TurnOffPhoneCheckrModal } from 'src/routes/settings/billing/components/PhoneCheckr/modal/TurnOffPhoneCheckrModal'
import { ActivatePhoneCheckrModal } from 'src/routes/settings/billing/components/PhoneCheckr/modal/ActivatePhoneCheckrModal'
import { SuccessValidateModal } from 'src/routes/settings/billing/components/PhoneCheckr/modal/SuccessValidateModal'
import { UnverifiedNumberModal } from 'src/routes/settings/compliance/modals/UnverifiedNumberModal/UnverifiedNumberModal'
import { UpdateCardModal } from 'src/routes/settings/billing/modals/UpdateCardModal/UpdateCardModal'
import { SamplePresentationImage } from 'components/PresentatinImages/SamplePresentationImage'
import { EditBPModal } from 'src/routes/superAdmin/modals/EditBPModal/EditBPModal'
import { EditTFModal } from 'src/routes/superAdmin/modals/EditTFModal/EditTFModal'
import { BusinessProfileModal } from 'src/routes/settings/compliance/modals/BusinessProfileModal/BusinessProfileModal'
import { UseCaseProfileModal } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/UseCaseProfileModal'
import { AddCardModal } from 'src/routes/settings/billing/modals/AddCardModal/AddCardModal'

export const AppModals = () => (
  <>
    <PresentationImages />
    <SamplePresentationImage />
    <VCardModal />
    <ImportContactModal />
    <UpgradePlanModal />
    <BuyCreditsModal />
    <UpdateCardModal />
    <AddCardModal />
    <SuccessByCreditsModal />
    <CancelSubscriptionModal />
    <PauseSubscriptionModal />
    <SuccessPausedSubscriptionModal />
    <ContinueCancelSubscriptionModal />
    <SuccessCanceledSubscriptionModal />
    <BuySubscriptionModal />
    <AudioModal />
    <UploadAudioModal />
    <CreateTextToSpeechModal />
    <VerifyModal />
    <AddNumberModal />
    <CongratsModal />
    <AddContactModal />
    <EditListsModal />
    <EditContactModal />
    <CustomFieldsModal />
    <UnsubscribeContactModal />
    <TestWebFormModal />
    <GetWebFromCodeModal />
    <NewKeywordModal />
    <NewWebFormModal />
    <ClicksDetailsModal />
    <LimitSubscriptionModal />
    <PhoneCheckrModal />
    <TurnOnAutoRechargeModal />
    <TurnOffPhoneCheckrModal />
    <ActivatePhoneCheckrModal />
    <SuccessValidateModal />
    <UnverifiedNumberModal />
    <EditBPModal />
    <EditTFModal />
    <BusinessProfileModal />
    <UseCaseProfileModal />
  </>
)
