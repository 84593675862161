import * as React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { OrganizationPageStore } from 'store/superAdmin/organizationPageStore'
import { OrganizationPageContent } from 'src/routes/superAdmin/routes/organization/OrganizationPageContent'
import { useParams } from 'react-router-dom'
import { useGetOrganizationDetailsQuery } from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { observer } from 'mobx-react-lite'
import superAdminStore from 'store/superAdmin/superAdminStore'
import styles from './styles.module.scss'

const OrganizationPageContext = createContext<OrganizationPageStore>(
  new OrganizationPageStore()
)

export function useOrganizationPageContext() {
  return useContext(OrganizationPageContext)
}

export const OrganizationPage = observer(() => {
  const [store] = useState(new OrganizationPageStore())
  const { id } = useParams()
  const { data, loading, refetch } = useGetOrganizationDetailsQuery({
    skip: !id,
    variables: {
      id: Number(id),
    },
  })

  useEffect(() => {
    if (data?.getOrganizationDetails) {
      store.setOrganization(data.getOrganizationDetails)
    }
  }, [data])

  useEffect(() => {
    if (superAdminStore.orgPageTrigger) {
      refetch()
    }
  }, [superAdminStore.orgPageTrigger])

  return (
    <OrganizationPageContext.Provider value={store}>
      {loading && (
        <div className={styles.emptyContent}>
          <AbsoluteLoader />
        </div>
      )}
      <OrganizationPageContent />
    </OrganizationPageContext.Provider>
  )
})
