import { useEffect, useState } from 'react'
import { IOption } from 'src/types/IOption'

export function useTagsWidth(
  ref: HTMLDivElement | null,
  onDeleteTag?: (id: string) => void,
  options?: IOption[]
) {
  const [widthTags, setWidthTags] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      const elements = ref?.querySelectorAll('.appTag')
      const visibleElements: Element[] = []
      elements &&
        elements.forEach((el) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          if (el.style?.visibility === 'visible') {
            visibleElements.push(el)
          }
        })
      const width =
        visibleElements.reduce((acc, el) => acc + el.clientWidth, 0) +
        4 * (visibleElements.length - 1)

      setWidthTags(width)
    }, 50)
  }, [options, onDeleteTag, ref])

  return { widthTags }
}
