import { Page } from 'components/Page/Page'
import styles from './styles.module.scss'
import { FC, ReactNode } from 'react'

type Props = {
  header: ReactNode
  children?: ReactNode
}

export const DetailPage: FC<Props> = ({ children, header }) => {
  return (
    <Page white>
      <div className={styles.detailWrap}>
        {header}
        <div className={styles.detailContent}>{children}</div>
      </div>
    </Page>
  )
}
