import { Modal } from 'components/Modal/Modal'
import { VCard } from 'components/VCard/VCard'
import { observer } from 'mobx-react-lite'
import createVCardStore from 'store/settings/createVCardStore'

export const VCardModal = observer(() => {
  return (
    <Modal
      open={createVCardStore.isOpenModal}
      onClose={() => createVCardStore.closeModal()}
      title={'Virtual Contact Card'}
      minWidth={528}
      centered
    >
      <VCard />
    </Modal>
  )
})
