// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NZdVC8YRksWPkOW7aFRe{min-width:800px}.hSH_ncPebx6neJGDXpEo{margin-top:24px;height:344px}`, "",{"version":3,"sources":["webpack://./src/Modals/UploadAudioModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,eAAA,CACA,YAAA","sourcesContent":[".wrap{\n  min-width: 800px;\n}\n.wrapInput{\n  margin-top: 24px;\n  height: 344px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `NZdVC8YRksWPkOW7aFRe`,
	"wrapInput": `hSH_ncPebx6neJGDXpEo`
};
export default ___CSS_LOADER_EXPORT___;
