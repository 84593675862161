export type IResponseVoice = {
  voiceId: string
  voiceName: string
  gender: 'Male' | 'Female'
  langCode: 'en-US'
  types: IResponseVoiceType[]
  type: IResponseVoiceType
}

export type IResponseVoiceType = 'PREMIUM' | 'STANDARD'

export function responseVoiceToOption(voice: IResponseVoice) {
  return {
    value: voice.voiceId,
    title: voice.voiceName,
    // optionClass: 'flex',
    data: voice,
  }
}
