import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import { VerifyContent } from 'src/Modals/VerifyModal/VerifyContent'
import accountStore from 'store/settings/accountStore'

export const VerifyModal = observer(() => {
  const onCancel = () => {
    if (accountStore.verifySetting?.onCancel) {
      accountStore.verifySetting?.onCancel()
    }
    accountStore.setOpenVerifyNumberModal(false)
  }
  return (
    <Modal
      open={accountStore.openVerifyNumberModal}
      onClose={onCancel}
      title={accountStore.verifySetting?.title}
    >
      {accountStore.openVerifyNumberModal && <VerifyContent />}
    </Modal>
  )
})
