import { action, computed, makeObservable, observable } from 'mobx'
import { FilterCondition, FilterDataType } from 'src/generated/graphql'
import { EmptyFilterProps } from 'store/contacts/segment/filters/EmptyFilter'

export type CommonFilterProps = {
  condition?: FilterCondition | null
  field?: string | null
  dataType?: FilterDataType | null
  value?: string | null
  valueExt?: string | null
} & EmptyFilterProps

export class CommonFilter {
  orIndex: number
  andIndex: number
  condition?: FilterCondition | null = null
  field: string
  dataType: FilterDataType
  constructor({
    orIndex,
    andIndex,
    condition,
    field,
    dataType,
  }: CommonFilterProps) {
    makeObservable(this, {
      condition: observable,
      setCondition: action.bound,
      commonIsNotEmpty: computed,
    })
    this.andIndex = andIndex
    this.orIndex = orIndex
    this.condition = condition || null
    this.field = field || ''
    this.dataType = dataType || FilterDataType.Text
  }

  get commonIsNotEmpty() {
    return (
      this.condition === FilterCondition.Exists ||
      this.condition === FilterCondition.NotExists
    )
  }

  setCondition = (value: typeof this.condition) => {
    this.condition = value
  }
}
