import { Alert } from 'components/Alert/Alert'
import { AttachmentFile } from 'components/AttachmentFIle/AttachmentFile'
import { observer } from 'mobx-react-lite'
import { SizedBox } from 'components/SizedBox'
import styles from './styles.module.scss'
import { AddContactsToListDropdown } from 'src/Modals/ImportContactModal/StepsContent/Step3/AddContactsToListDropdown'
import { AgreeTermsInput } from 'src/Modals/ImportContactModal/StepsContent/AgreeTermsInput'
import importContactStore from 'store/contacts/importContactStore'

export const Step3 = observer(() => {
  return (
    <>
      <Alert
        title={'Add contacts to a list to send them a message'}
        rightContent={
          <div className={styles.wrapFile}>
            {!!importContactStore.uploadFiles.length && (
              <AttachmentFile name={importContactStore.uploadFiles[0].name} />
            )}
          </div>
        }
      />
      <SizedBox height={32} />
      <AddContactsToListDropdown store={importContactStore} required />
      <div className={styles.wrapCheckBox}>
        <AgreeTermsInput />
      </div>
    </>
  )
})
