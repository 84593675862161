// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N4xKhUW1PpSLcUpeJveF{position:relative}.SiKbbabhWSG1yPF0XHGh{position:absolute;right:4px;top:28px;height:32px}.SiKbbabhWSG1yPF0XHGh button{min-height:unset;height:32px;border:none;background-color:var(--primary-color-white);outline:none !important}.cLbSm9KaFrTOWzk0LgQh{display:flex;column-gap:12px}.YXowL0EYQBiC_Gd_zYqf{display:grid;grid-template-columns:283px 115px 40px;column-gap:12px}.xGKOBpNvTEGOebIo2Wv7{padding:12px 16px 12px 12px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/company/routes/CompanyTeam/modals/MemberModal/InviteContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEF,sBACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,WAAA,CACA,6BACE,gBAAA,CACA,WAAA,CACA,WAAA,CAEA,2CAAA,CACA,uBAAA,CAIJ,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,sCAAA,CACA,eAAA,CAEF,sBACE,2BAAA","sourcesContent":[".email{\n  position: relative;\n}\n.drop{\n  position: absolute;\n  right: 4px;\n  top: 28px;\n  height: 32px;\n  button{\n    min-height: unset;\n    height: 32px;\n    border: none;\n\n    background-color: var(--primary-color-white);\n    outline: none!important;\n  }\n\n}\n.actions{\n  display: flex;\n  column-gap: 12px;\n}\n.inviteLinkContent{\n  display: grid;\n  grid-template-columns: 283px 115px 40px;\n  column-gap: 12px;\n}\n.copyBtn{\n  padding: 12px 16px 12px 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email": `N4xKhUW1PpSLcUpeJveF`,
	"drop": `SiKbbabhWSG1yPF0XHGh`,
	"actions": `cLbSm9KaFrTOWzk0LgQh`,
	"inviteLinkContent": `YXowL0EYQBiC_Gd_zYqf`,
	"copyBtn": `xGKOBpNvTEGOebIo2Wv7`
};
export default ___CSS_LOADER_EXPORT___;
