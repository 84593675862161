import billingStore from 'store/settings/billing/billingStore'
import numbersStore from 'store/settings/numbers/numbersStore'

export const checkLimitsNumbers = (): boolean => {
  const numberLimit = billingStore.clSubscription?.limits?.numbers || 0
  const nonShortCodeNumbersLength = numbersStore.nonShortCodeNumbers.length
  if (nonShortCodeNumbersLength >= numberLimit) {
    billingStore.setLimitModalFor('numbers')
    return false
  }
  return true
}
