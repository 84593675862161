import { makeAutoObservable, runInAction } from 'mobx'
import apiSore from 'store/apiSore'
import {
  DeleteSegmentsDocument,
  DeleteSegmentsMutation,
  DeleteSegmentsMutationVariables,
  FilterDataType,
  FilterFieldType,
  FilterOptionFragment,
  GetAvailableFilterFieldsDocument,
  GetAvailableFilterFieldsQuery,
  UpdateSegmentDocument,
  UpdateSegmentMutation,
  UpdateSegmentMutationVariables,
  UpdateSegmentRequestInput,
} from 'src/generated/graphql'
import { FilterConditionItem } from 'store/contacts/segment/types'
import { IOption } from 'src/types/IOption'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { TableStore } from 'components/NewTable/store/TableStore'
import { segmentsColumns } from 'components/NewTable/columns/contacts/contactColums'

class SegmentStore {
  tableStore = new TableStore({
    orderBy: 'createdAt',
    tableName: 'SegmentsTable',
    columns: segmentsColumns,
  })
  constructor() {
    makeAutoObservable(this)
  }
  dataTypeConditions: Map<FilterDataType, Array<FilterConditionItem>> =
    new Map()
  fieldsMap: Map<string, FilterOptionFragment> = new Map()

  get fields() {
    return Array.from(this.fieldsMap.values())
  }

  get fieldsOptions(): IOption<{ dataType: FilterDataType }>[] {
    let options: IOption[] = this.fields.map((field) => ({
      title: field.label,
      value: field.key,
      data: {
        dataType: field.dataType,
      },
    }))
    if (customFieldsStore.customFieldsOptions.length) {
      options = options.concat([
        { title: 'Custom fields', value: '-', isLabel: true },
        ...customFieldsStore.customFields.map((field) => ({
          title: field.name,
          value: field.id,
          data: {
            dataType: field.type,
          },
        })),
      ])
    }
    return options
  }
  loadAvailableData = async () => {
    try {
      if (this.fields.length) return
      const { data } =
        await apiSore.client.query<GetAvailableFilterFieldsQuery>({
          query: GetAvailableFilterFieldsDocument,
        })
      runInAction(() => {
        if (data.getAvailableFilterFields) {
          this.dataTypeConditions = new Map(
            Object.entries(data.getAvailableFilterFields.dataTypeConditions)
          ) as typeof this.dataTypeConditions
          if (data.getAvailableFilterFields.fields) {
            data.getAvailableFilterFields.fields.forEach((filed) => {
              if (filed?.key) {
                this.fieldsMap.set(filed.key, filed)
              }
            })
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }
  getTypeByField = (filed: string): FilterFieldType => {
    if (this.fields.find((i) => i.key === filed)) {
      return FilterFieldType.Contact
    }
    return FilterFieldType.Custom
  }

  deleteSegments = async (segmentIds: number[], onSusses?: () => void) => {
    await apiSore.client.mutate<
      DeleteSegmentsMutation,
      DeleteSegmentsMutationVariables
    >({
      mutation: DeleteSegmentsDocument,
      variables: {
        segmentIds,
      },
    })
    onSusses && onSusses()
    this.tableStore.setCheckedRows(
      this.tableStore.checkedRows.filter(
        (item) => !segmentIds.includes(+item.id)
      )
    )
    this.tableStore.onRefresh()
  }

  updateSegment = async (segment: UpdateSegmentRequestInput) => {
    try {
      await apiSore.client.mutate<
        UpdateSegmentMutation,
        UpdateSegmentMutationVariables
      >({
        mutation: UpdateSegmentDocument,
        variables: {
          segment: {
            id: segment.id,
            name: segment.name,
            filterValue: segment.filterValue,
          },
        },
      })
      this.tableStore.onRefresh()
    } catch (e) {
      console.error(e)
    }
  }
}

export default new SegmentStore()
