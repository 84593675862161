// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adJeD7a2zc1gbWN_c8yG{overflow-y:hidden;padding-right:64px}@media(max-width: 800px){.adJeD7a2zc1gbWN_c8yG{padding-right:0}}@media(max-height: 600px){.adJeD7a2zc1gbWN_c8yG{padding-right:0}}`, "",{"version":3,"sources":["webpack://./src/routes/main/contacts/ContactsTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,kBAAA,CACA,yBAHF,sBAII,eAAA,CAAA,CAEF,0BANF,sBAOI,eAAA,CAAA","sourcesContent":[".wrap{\n  overflow-y: hidden;\n  padding-right: 64px;\n  @media (max-width: 800px) {\n    padding-right: 0;\n  }\n  @media (max-height: 600px) {\n    padding-right: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `adJeD7a2zc1gbWN_c8yG`
};
export default ___CSS_LOADER_EXPORT___;
