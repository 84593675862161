import { observer } from 'mobx-react-lite'
import { OptIn } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/details/OptIn'
import { BusinessInfo } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/details/BusinessInfo'
import { ContactInfo } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/details/ContactInfo'
import { UseCase } from 'src/routes/settings/compliance/modals/UseCaseProfileModal/details/UseCase'

export const Details = observer(() => {
  return (
    <>
      <BusinessInfo />
      <ContactInfo />
      <UseCase />
      <OptIn />
    </>
  )
})
