import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { ClassesEnum } from 'styles/classes'
import { BtnSize, Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import { ReactComponent as Angle } from 'icons/16pxNoMask/Angle-Down.svg'
import classNames from 'classnames'
import { ColorsNames } from 'styles/variables'
import { ICardDetail } from 'components/DetailCard/DetailCard'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'

export const CardDropdown = ({
  options,
  cardElement,
  checked,
  setSelectedOption,
}: {
  options: ICardDetail[]
  cardElement: HTMLDivElement | null
  checked: boolean
  setSelectedOption: (car: ICardDetail) => void
}) => {
  const { showTT, onTriggerClick, setShowTT } = useFixedTooltip()
  const onSelectOpt = (opt: ICardDetail) => {
    setSelectedOption(opt)
    setShowTT(false)
  }
  return (
    <>
      <FixedTooltip
        trigger={cardElement}
        visible={showTT}
        white
        noArrow
        width={212}
        position={TTPositionEnum.bottomRight}
        globalClasses={[ClassesEnum.p8]}
      >
        {options.map((opt) => (
          <Button
            key={opt.value}
            size={BtnSize.small}
            menuBtn
            hoverGray
            onClick={(e) => {
              e.stopPropagation()
              onSelectOpt(opt)
            }}
          >
            <div className={styles.optionRow}>
              {opt.title ? <span>{opt.title}</span> : opt.titleComponent}
              <span className={ClassesEnum.medium}>{opt.count}</span>
              <div className={'point'} />
              <span className={ColorsNames.gray1}>{opt.percent}%</span>
            </div>
          </Button>
        ))}
      </FixedTooltip>
      <button
        onClick={(e) => {
          e.stopPropagation()
          onTriggerClick()
        }}
        className={classNames(
          styles.dropBtn,
          checked && styles.checkedBtn,
          showTT && styles.active
        )}
      >
        <Angle />
      </button>
    </>
  )
}
