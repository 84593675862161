import { makeAutoObservable } from 'mobx'
import { ShortUrlClickStatistics } from 'src/generated/graphql'

class ClicksDetailStore {
  constructor() {
    makeAutoObservable(this)
  }
  shortUrlClickStatistics: ShortUrlClickStatistics | null = null

  get openModal() {
    return !!this.shortUrlClickStatistics
  }
  setShortUrlClickStatistics(
    shortUrlClickStatistics: typeof this.shortUrlClickStatistics
  ) {
    this.shortUrlClickStatistics = shortUrlClickStatistics
  }
}

export default new ClicksDetailStore()
