import { createContext, useContext } from 'react'
import { CreateTriggerStore } from 'store/triggers/createTriggerStore'

export const CreateTriggerContext = createContext<CreateTriggerStore>(
  new CreateTriggerStore()
)

export function useCreateTriggerContext() {
  return useContext(CreateTriggerContext)
}
