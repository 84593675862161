// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._QHoFGlM3bFRz5k4IpCC{max-width:100%;width:fit-content;height:24px;border-radius:8px;background-color:var(--primary-color-gray-4);display:flex;align-items:center}._QHoFGlM3bFRz5k4IpCC.XOIKxFPDTK4xhiCRM7Cw .hdppm79dIA91bYLPzxjo{width:47px;margin-right:0}._QHoFGlM3bFRz5k4IpCC button{display:flex;cursor:pointer}._QHoFGlM3bFRz5k4IpCC.s4bu9wEYki3aNZ7oTjwz{height:32px}._QHoFGlM3bFRz5k4IpCC.MdRg2pva0aYnZVGwUhUc{width:100%}.hdppm79dIA91bYLPzxjo{width:48px;margin-right:20px}.ROArn0qAC4kFjJJRwTpx{display:grid;grid-template-columns:calc(100% - 16px) max-content;height:11px}.Aq6KiGIqas5s_JVQZbyv{display:flex}.Aq6KiGIqas5s_JVQZbyv svg rect{fill:var(--primary-color-blue-1)}`, "",{"version":3,"sources":["webpack://./src/components/AudioPlayer/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,cAAA,CACA,iBAAA,CACA,WAAA,CACA,iBAAA,CACA,4CAAA,CACA,YAAA,CACA,kBAAA,CAEE,iEACE,UAAA,CACA,cAAA,CAIJ,6BACE,YAAA,CACA,cAAA,CAEF,2CACE,WAAA,CAEF,2CACE,UAAA,CAGJ,sBACE,UAAA,CACA,iBAAA,CAEF,sBACE,YAAA,CACA,mDAAA,CAGA,WAAA,CAEF,sBACE,YAAA,CAEE,+BACE,gCAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrapAudio{\n  max-width: 100%;\n  width: fit-content;\n  height: 24px;\n  border-radius: 8px;\n  background-color: var(--primary-color-gray-4);\n  display: flex;\n  align-items: center;\n  &.fromPreview{\n    .duration{\n      width: 47px;\n      margin-right: 0;\n    }\n\n  }\n  button{\n    display: flex;\n    cursor: pointer;\n  }\n  &.small{\n    height: 32px;\n  }\n  &.fullWidth{\n    width: 100%;\n  }\n}\n.duration{\n  width: 48px;\n  margin-right: 20px;\n}\n.waveform{\n  display: grid;\n  grid-template-columns: calc(100% - 16px) max-content;\n  //margin-right: 12px;\n  //margin-left: 19px;\n  height: 11px;\n}\n.pause{\n  display: flex;\n  svg{\n    rect{\n      fill: var(--primary-color-blue-1);\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapAudio": `_QHoFGlM3bFRz5k4IpCC`,
	"fromPreview": `XOIKxFPDTK4xhiCRM7Cw`,
	"duration": `hdppm79dIA91bYLPzxjo`,
	"small": `s4bu9wEYki3aNZ7oTjwz`,
	"fullWidth": `MdRg2pva0aYnZVGwUhUc`,
	"waveform": `ROArn0qAC4kFjJJRwTpx`,
	"pause": `Aq6KiGIqas5s_JVQZbyv`
};
export default ___CSS_LOADER_EXPORT___;
