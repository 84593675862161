// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GiYGHXRiAjfdLeiuziQ3{font-family:Inter-Medium;font-weight:500;color:var(--primary-color-gray-1);font-size:12px;line-height:16px}.pO8yFw44TiDJFMWcRYNX{display:grid;grid-template-columns:1fr 1fr 1fr;gap:12px}@media(max-width: 800px){.pO8yFw44TiDJFMWcRYNX{grid-template-columns:1fr 1fr}}@media(max-height: 600px){.pO8yFw44TiDJFMWcRYNX{grid-template-columns:1fr 1fr}}`, "",{"version":3,"sources":["webpack://./src/widgets/RegisterOrganization/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,eAAA,CACA,iCAAA,CACA,cAAA,CACA,gBAAA,CAEF,sBACE,YAAA,CACA,iCAAA,CACA,QAAA,CACA,yBAJF,sBAKI,6BAAA,CAAA,CAEF,0BAPF,sBAQI,6BAAA,CAAA","sourcesContent":[".deliverText{\n  font-family: Inter-Medium;\n  font-weight: 500;\n  color: var(--primary-color-gray-1);\n  font-size: 12px;\n  line-height: 16px;\n}\n.deliverWrap{\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 12px;\n  @media (max-width: 800px) {\n    grid-template-columns: 1fr 1fr;\n  }\n  @media (max-height: 600px) {\n    grid-template-columns: 1fr 1fr;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deliverText": `GiYGHXRiAjfdLeiuziQ3`,
	"deliverWrap": `pO8yFw44TiDJFMWcRYNX`
};
export default ___CSS_LOADER_EXPORT___;
