import NewTable from 'components/NewTable/NewTable'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Filters } from './Filters/Filters'
import webFormStore from 'store/webForms/webFormStore'
import { HoverRowComponent } from './HoverRowComponent/HoverRowComponent'
import { Status, useGetWebFormsQuery } from 'src/generated/graphql'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import * as React from 'react'
import { EmptyState } from 'components/EmptyState/EmptyState'
import { noSortsColumns } from 'components/NewTable/columns/webForms/webFormsColumns'
import { useSetData } from 'src/hooks/useSetData'
import { IWebForm } from 'src/types/IWebForm'
import { AppElements } from 'src/enums/appElements'

export const WebFormsTable = observer(() => {
  const { tableStore, webForms } = webFormStore

  const options = useTablePaginationOptions(tableStore)

  const queryResult = useGetWebFormsQuery(options)
  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => webFormStore.setData(data),
    refreshDeps: [webFormStore.tableStore.refreshTrigger],
  })
  const navigate = useNavigate()
  const onRowClick = (id: string, row: IWebForm) => {
    if (row.status === Status.Draft) {
      navigate(id + '/edit')
    } else {
      navigate(id)
    }
  }

  if (!firstLoading && !webForms.length) {
    return (
      <EmptyState
        element={AppElements.WebForm}
        description={'Use web forms to capture leads'}
      />
    )
  }

  return (
    <div className={'p64withMobile'}>
      <NewTable<IWebForm>
        noSortsColumns={noSortsColumns}
        withCheckbox
        columns={webFormStore.tableStore.visibleColumns}
        rows={webForms}
        leftStickyContent={<Filters />}
        // withSearch
        onRowClick={onRowClick}
        HoverRowComponent={HoverRowComponent}
        // maxHeight={CustomHeight.tableHeight}
        withCalcMaxHeight
        tableStore={webFormStore.tableStore}
        pageLoading={loading || refetchLoading}
        firstLoading={firstLoading}
      />
    </div>
  )
})
