import styles from 'components/Schedule/styles.module.scss'
import { ScheduleBtn } from 'components/Schedule/ScheduleBtn'
import { BroadcastSendType, CampaignDelayType } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { BroadcastScheduleStore } from 'components/Schedule/store/broadcastScheduleStore'
import { ScheduleProps } from 'components/Schedule/Schedule'

export const ImmediatelyCard = observer(({ scheduleStore }: ScheduleProps) => {
  const onClick = () => {
    if (scheduleStore instanceof BroadcastScheduleStore) {
      scheduleStore.setSendTypeBroadcast(BroadcastSendType.Immediately)
    } else {
      scheduleStore.setSendTypeCampaign(CampaignDelayType.Immediate)
    }
  }
  return (
    <>
      <div className={styles.row}>
        <ScheduleBtn
          active={scheduleStore.activeSendImmediately}
          title={'Send Immediately'}
          onClick={onClick}
        />
      </div>
      {/*{scheduleStore.variant === ScheduleVariants.two && (*/}
      {/*  <BetweenContent scheduleStore={scheduleStore} />*/}
      {/*)}*/}
    </>
  )
})
