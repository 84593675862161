import { observer } from 'mobx-react-lite'
import superAdminStore from 'store/superAdmin/superAdminStore'
import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { AppSymbols } from 'src/util/symbols'
import {
  OrganizationStatus,
  useBanOrganizationMutation,
  useUnbanOrganizationMutation,
} from 'src/generated/graphql'
import { useParams } from 'react-router-dom'
import { useOrganizationPageContext } from 'src/routes/superAdmin/routes/organization/OrganizationPage'
import { IOrganization } from 'src/types/IOrganization'
import { IOrganizationDetails } from 'src/types/IOrganizationDetails'

export const BanOrganizationModal = observer(() => {
  const { id } = useParams()
  const fromOrgPage = !!id
  const [banOrganization, { loading: loadingBan }] =
    useBanOrganizationMutation()
  const [unbanOrganization, { loading: loadingUnBan }] =
    useUnbanOrganizationMutation()

  const { organization: organizationOrgPage } = useOrganizationPageContext()
  const organization: IOrganization | IOrganizationDetails | null = fromOrgPage
    ? organizationOrgPage
    : superAdminStore.banOrganization || null

  const onClose = () => {
    superAdminStore.setOpenBanOrganizationId('')
  }
  const onBan = async () => {
    try {
      if (organization?.organizationStatus === OrganizationStatus.Banned) {
        const { data } = await unbanOrganization({
          variables: {
            input: {
              organizationId: organization?.id,
            },
          },
        })
        if (data?.unbanOrganization) {
          if (fromOrgPage) {
            superAdminStore.updateOrgPageTrigger()
          } else {
            superAdminStore.updateOrganizations(data.unbanOrganization)
          }
        }
      } else {
        const { data } = await banOrganization({
          variables: {
            input: {
              organizationId: organization?.id,
            },
          },
        })
        if (data?.banOrganization) {
          if (fromOrgPage) {
            superAdminStore.updateOrgPageTrigger()
          } else {
            superAdminStore.updateOrganizations(data.banOrganization)
          }
        }
      }

      onClose()
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Modal
      open={!!superAdminStore.openBanOrganizationId}
      withoutHeader
      minWidth={464}
      onClose={onClose}
    >
      <div className={classNames('p32', styles.contentBan)}>
        <h3>
          {organization?.organizationStatus === OrganizationStatus.Banned
            ? 'Unban'
            : 'Ban'}{' '}
          organization
        </h3>
        <SizedBox height={12} />
        <p className={'par2 gray1'}>
          {`Are you sure you want to ${
            organization?.organizationStatus === OrganizationStatus.Banned
              ? 'unban'
              : 'ban'
          } `}
          <b>{`${organization?.companyName} ${AppSymbols.dash} ${organization?.id}?`}</b>
        </p>
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onBan}
            loading={loadingBan || loadingUnBan}
          >
            Yes,{' '}
            {organization?.organizationStatus === OrganizationStatus.Banned
              ? 'Unban'
              : 'Ban'}{' '}
            account
          </Button>
        </div>
      </div>
    </Modal>
  )
})
