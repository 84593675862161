import styles from 'src/routes/main/triggers/TriggersTable/styles.module.scss'
import { FC, ReactNode } from 'react'

type Props = {
  LeftIcon?: FC
  iconClass?: string
  title: string | ReactNode
}

export const AppRender = ({ LeftIcon, iconClass, title }: Props) => (
  <div className={styles.appWrap}>
    {LeftIcon && (
      <div className={iconClass}>
        <LeftIcon />
      </div>
    )}
    {title}
  </div>
)
