import { ImportBtn } from 'src/routes/main/contacts/components/ImportBtn/ImportBtn'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import manualContactStore from 'store/contacts/manualContactStore'
import { ReactComponent as Contacts } from 'icons/16px/Contacts Add.svg'
import { EmptyState } from 'components/EmptyState/EmptyState'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import contactStore from 'store/contacts/contactStore'
import { AppElements } from 'src/enums/appElements'

export const EmptyStateContacts = observer(
  ({ onlyImport }: { onlyImport?: boolean }) => {
    const { id } = useParams()
    return (
      <EmptyState
        add={!!contactStore.totalContactCount}
        element={AppElements.Contact}
        description={`Import ${
          onlyImport ? '' : 'or add manually'
        } the contacts`}
        heightV2
        actions={
          <div className={'row12'}>
            <ImportBtn />
            {!onlyImport && (
              <Button
                size={BtnSize.medium}
                typeBtn={BtnType.secondaryGray}
                withIcon
                onClick={() => manualContactStore.setOpenContactModal(true, id)}
              >
                <Contacts />
                Add manually
              </Button>
            )}
          </div>
        }
      />
    )
  }
)
