import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import { useInitMessageSampleTenDlcMutation } from 'src/generated/graphql'
import React from 'react'
import { SimpleTextarea } from 'components/SimpleTextarea/SimpleTextarea'
import { BackBtn } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/BackBtn'

export const Step6 = observer(() => {
  const [initMessageSampleTenDlc, { loading }] =
    useInitMessageSampleTenDlcMutation()
  const onContinue = async () => {
    try {
      const { data } = await initMessageSampleTenDlc({
        variables: {
          input: a2p10DLCStore.tenDlcMessageSampleRequestInput,
        },
      })
      if (data?.initMessageSampleTenDlc?.profile) {
        a2p10DLCStore.setTenDlcProfile(data.initMessageSampleTenDlc.profile)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={'col40'}>
      <Alert
        text={
          'Provide an example of a message that you will be sending with this campaign use case. This sample will be used by carriers to help identify your traffic.'
        }
      />
      <div className={'col24'}>
        <SimpleTextarea
          label={'Sample message 1'}
          placeholder={'Enter sample message'}
          value={a2p10DLCStore.firstMessage}
          onChangeValue={(value) => a2p10DLCStore.setFirstMessage(value)}
          height={104}
        />
        <SimpleTextarea
          label={'Sample message 2'}
          placeholder={'Enter sample message'}
          value={a2p10DLCStore.secondMessage}
          onChangeValue={(value) => a2p10DLCStore.setSecondMessage(value)}
          height={104}
        />
      </div>
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          loading={loading}
          disabled={a2p10DLCStore.disabledSamples}
        >
          {a2p10DLCStore.continueText}
        </Button>
        <BackBtn />
      </div>
    </div>
  )
})
