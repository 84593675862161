import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { CreateKeywordStore } from 'store/keywords/createKeywordStore'

const CreateKeywordContext = createContext<CreateKeywordStore>(
  new CreateKeywordStore()
)

export function useCreateKeywordContext() {
  return useContext(CreateKeywordContext)
}

export const CreateKeywordState: FC<{ children?: ReactNode }> = (props) => {
  const [store] = useState(() => new CreateKeywordStore())
  return <CreateKeywordContext.Provider value={store} {...props} />
}
