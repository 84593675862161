import { ScheduleProps } from 'components/Schedule/Schedule'
import { BroadcastScheduleStore } from 'components/Schedule/store/broadcastScheduleStore'
import { LaterContentBroadcast } from 'components/Schedule/LaterCard/LaterContentBroadcast/LaterContentBroadcast'
import { LaterContentCampaign } from 'components/Schedule/LaterCard/LaterContentCampaign/LaterContentCampaign'

export const LaterContent = ({ scheduleStore }: ScheduleProps) =>
  scheduleStore instanceof BroadcastScheduleStore ? (
    <LaterContentBroadcast scheduleStore={scheduleStore} />
  ) : (
    <LaterContentCampaign scheduleStore={scheduleStore} />
  )
