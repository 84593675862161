import { useEffect, useState } from 'react'
import { useUpdateLeadMemberMutation } from 'src/generated/graphql'

export function useUpdateMemberLead(
  phone: string,
  firstName: string,
  lastName: string
) {
  const [updateLeadMember] = useUpdateLeadMemberMutation()

  const [activityUserTimeoutId, setActivityUserTimeoutId] =
    useState<NodeJS.Timeout | null>(null)
  const onUpdateMemberLead = () => {
    updateLeadMember({
      variables: {
        member: {
          phone,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        },
      },
    }).catch(console.error)
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        onUpdateMemberLead()
      }
    }

    window.addEventListener('beforeunload', onUpdateMemberLead)
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('beforeunload', onUpdateMemberLead)
    }
  }, [firstName, lastName, phone])

  useEffect(() => {
    const setActivity = () => {
      if (activityUserTimeoutId) {
        clearInterval(activityUserTimeoutId)
      }
      setActivityUserTimeoutId(
        setTimeout(() => {
          onUpdateMemberLead()
        }, 3 * 60 * 1000)
      )
    }
    window.addEventListener('click', setActivity)
    window.addEventListener('keydown', setActivity)
    return () => {
      if (activityUserTimeoutId) {
        clearInterval(activityUserTimeoutId)
      }
      window.removeEventListener('click', setActivity)
      window.removeEventListener('keydown', setActivity)
    }
  }, [activityUserTimeoutId])
}
