import { ActionsEnum } from 'components/Button/types'
import { AudioRenameBtn } from 'src/routes/settings/audio/components/AudioRenameBtn'
import { AudioDeleteBtn } from 'src/routes/settings/audio/components/AudioDeleteBtn'
import { AudioDownloadBtn } from 'src/routes/settings/audio/components/AudioDownloadBtn'
import { AudioCopyUrlBtn } from 'src/routes/settings/audio/components/AudioCopyUrlBtn'

export const audioBtnByAction = {
  [ActionsEnum.rename]: AudioRenameBtn,
  [ActionsEnum.copy]: AudioCopyUrlBtn,
  [ActionsEnum.download]: AudioDownloadBtn,
  [ActionsEnum.delete]: AudioDeleteBtn,
}
