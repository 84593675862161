import { AllowImportStatus } from 'src/generated/graphql'
import { FC } from 'react'
import { MoreBtnItemProps } from 'components/Button/MoreBtn'
import { IContactImportState } from 'src/routes/main/contacts/importHistory/ImportHistoryTable/types'
import { ApproveImportBtn } from 'src/routes/superAdmin/components/ApproveImportBtn'
import { RejectImportBtn } from 'src/routes/superAdmin/components/RejectImportBtn'

export function getImportsActions(
  row?: IContactImportState
): Array<FC<MoreBtnItemProps>> {
  const actions: Array<FC<MoreBtnItemProps>> = []
  if (!row) return actions

  if (
    row.allowImportStatus === AllowImportStatus.NeedsReview ||
    row.allowImportStatus === AllowImportStatus.Rejected
  ) {
    actions.push((props) => ApproveImportBtn({ ...props, row }))
  }

  if (
    row.allowImportStatus === AllowImportStatus.NeedsReview ||
    row.allowImportStatus === AllowImportStatus.Approved
  ) {
    actions.push((props) => RejectImportBtn({ ...props, row }))
  }

  return actions
}
