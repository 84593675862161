import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useMemberRegistrationPreviousStepMutation } from 'src/generated/graphql'
import user from 'store/user/user'
import { useLogout } from 'components/NavBar/components/Logout'
import { useEffect } from 'react'
import auth from 'store/auth'
import migrationStore from 'store/migration/migrationStore'

export const BackMigrationBtn = () => {
  const [memberRegistrationPreviousStep, { loading }] =
    useMemberRegistrationPreviousStepMutation()
  const { onLogout } = useLogout()
  const onBack = async () => {
    try {
      if (migrationStore.migrationStep === 1) {
        onLogout(true)
      } else {
        const { data } = await memberRegistrationPreviousStep()
        if (data?.memberRegistrationPreviousStep) {
          user.setMember(data.memberRegistrationPreviousStep)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    auth.setOnBackFn(onBack)
  }, [])
  return (
    <Button
      size={BtnSize.medium}
      typeBtn={BtnType.secondaryGray}
      onClick={onBack}
      loading={loading}
    >
      Back
    </Button>
  )
}
