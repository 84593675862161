// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DmIUt7vMBrWurgYZpZ2i{margin-top:40px;display:grid;gap:32px}.DmIUt7vMBrWurgYZpZ2i.jAJo0eJ1LshrXAo69M7g{margin-top:86px}.TOeVW3ctQmzAQbFPg034{display:flex;justify-content:space-between}.TOeVW3ctQmzAQbFPg034 .MU7GJODlXhcm0gmxbuE1{display:flex;column-gap:12px}.IWNqkS8v0gyi9kEpG1zA{padding:8px}`, "",{"version":3,"sources":["webpack://./src/routes/main/keywords/create/createForm/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAIA,YAAA,CACA,QAAA,CAJA,2CACE,eAAA,CAMJ,sBAEE,YAAA,CACA,6BAAA,CAEA,4CACE,YAAA,CACA,eAAA,CAGJ,sBACE,WAAA","sourcesContent":[".wrap{\n  margin-top: 40px;\n  &.fromModal{\n    margin-top: 86px;\n  }\n  display: grid;\n  gap: 32px;\n}\n\n.actionsWrap{\n\n  display: flex;\n  justify-content: space-between;\n\n  .left{\n    display: flex;\n    column-gap: 12px;\n  }\n}\n.menuWrap{\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `DmIUt7vMBrWurgYZpZ2i`,
	"fromModal": `jAJo0eJ1LshrXAo69M7g`,
	"actionsWrap": `TOeVW3ctQmzAQbFPg034`,
	"left": `MU7GJODlXhcm0gmxbuE1`,
	"menuWrap": `IWNqkS8v0gyi9kEpG1zA`
};
export default ___CSS_LOADER_EXPORT___;
