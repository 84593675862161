import { observer } from 'mobx-react-lite'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { AuthorizedUserBlock } from 'src/routes/settings/compliance/BusinessProfile/steps/PeopleToContact/AuthorizedUserBlock'
import { SizedBox } from 'components/SizedBox'
import { Separator } from 'components/Separator/Separator'
import { ErrorsEnum } from 'src/static/errors'
import { PropsBusinessStep } from 'src/routes/settings/compliance/BusinessProfile/types'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  BusinessProfileResponse,
  useAdminInitBusinessAuthorizedUsersMutation,
  useInitBusinessAuthorizedUsersMutation,
} from 'src/generated/graphql'
import alertStore from 'store/alertStore'

export const PeopleToContact = observer(
  ({ onCancel, loadingPrevious, onSuccess }: PropsBusinessStep) => {
    const { adminOrgId } = businessProfileStore
    const onCheckEmail = (number: 1 | 2, key: string) => {
      let email = ''
      if (number === 1) {
        email = businessProfileStore.user1.email || ''
      }
      if (number === 2) {
        email = businessProfileStore.user2.email || ''
      }
      const isWrong = !(email.includes('@') && email.includes('.'))
      businessProfileStore.setError(key, isWrong)
    }
    const clearError = (key: string) => {
      businessProfileStore.setError(key, false)
    }

    const [initBusinessAuthorizedUsers, { loading }] =
      useInitBusinessAuthorizedUsersMutation()

    const [adminInitBusinessAuthorizedUsers, { loading: loadingAdmin }] =
      useAdminInitBusinessAuthorizedUsersMutation()

    const onContinue = async () => {
      const finish = businessProfileStore.continueText !== 'Continue'
      try {
        let profile: BusinessProfileResponse | undefined | null
        if (adminOrgId) {
          const { data } = await adminInitBusinessAuthorizedUsers({
            variables: {
              input: businessProfileStore.businessAuthorizedUserRequestInput,
              organizationId: adminOrgId,
            },
          })
          profile = data?.adminInitBusinessAuthorizedUsers
        } else {
          const { data } = await initBusinessAuthorizedUsers({
            variables: {
              input: businessProfileStore.businessAuthorizedUserRequestInput,
            },
          })
          profile = data?.initBusinessAuthorizedUsers
        }
        profile && onSuccess(profile, finish)
      } catch (e) {
        if (e instanceof Error && e.message.includes('Number is invalid')) {
          businessProfileStore.mapErrors.set('user1phoneNumber', true)
          alertStore.disabledErrorAlert(e.message)
        }
        console.error(e)
      }
    }
    return (
      <>
        <AuthorizedUserBlock
          errorEmail={
            (businessProfileStore.mapErrors.get('user1email') &&
              ErrorsEnum.verifyEmail) ||
            ''
          }
          errorPhone={
            (businessProfileStore.mapErrors.get('user1phoneNumber') &&
              ErrorsEnum.validPhoneNumber) ||
            ''
          }
          user={businessProfileStore.user1}
          setUser={(user) => businessProfileStore.setUser(user, 1)}
          number={1}
          onCheckEmail={() => {
            onCheckEmail(1, 'user1email')
          }}
          clearError={clearError}
        />
        <SizedBox height={24} />
        <InputCheckbox
          marginLeft={-8}
          width={'calc(100% + 16px)'}
          label={'I have a second authorized representative'}
          noBordered
          fitHeight
          checked={businessProfileStore.haveSecondAuthUser}
          onChecked={() =>
            businessProfileStore.setHaveSecondAuthUser(
              !businessProfileStore.haveSecondAuthUser
            )
          }
        />
        {businessProfileStore.haveSecondAuthUser ? (
          <>
            <Separator
              width={'calc(100% + 64px)'}
              marginLeft={-32}
              marginBlock={40}
              color={'PrimaryColorGray2'}
            />
            <AuthorizedUserBlock
              errorEmail={
                (businessProfileStore.mapErrors.get('user2email') &&
                  ErrorsEnum.verifyEmail) ||
                ''
              }
              errorPhone={
                (businessProfileStore.mapErrors.get('user2phoneNumber') &&
                  ErrorsEnum.validPhoneNumber) ||
                ''
              }
              user={businessProfileStore.user2}
              setUser={(user) => businessProfileStore.setUser(user, 2)}
              number={2}
              clearError={() => clearError('user2email')}
              onCheckEmail={() => {
                onCheckEmail(2, 'user2email')
              }}
            />
            <SizedBox height={24} />
          </>
        ) : (
          <SizedBox height={8} />
        )}

        <InputCheckbox
          marginLeft={-8}
          width={'calc(100% + 16px)'}
          label={
            'I confirm that my nominated authorized representative agree to be contacted by CallLoop'
          }
          noBordered
          fitHeight
          checked={businessProfileStore.confirmContacted}
          onChecked={() =>
            businessProfileStore.setConfirmContacted(
              !businessProfileStore.confirmContacted
            )
          }
        />
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onContinue}
            disabled={businessProfileStore.disabledPeopleToContact}
            loading={loading || loadingAdmin}
          >
            {businessProfileStore.continueText}
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onCancel}
            loading={loadingPrevious}
          >
            {businessProfileStore.step === 1 ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </>
    )
  }
)
