import styles from './styles.module.scss'
import { ReactComponent as Plus } from 'icons/16pxNoMask/Plus.svg'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { PaymentCard } from 'src/routes/settings/billing/components/PaymentMethods/PaymentCard'
import { observer } from 'mobx-react-lite'
import { ExpandContent } from 'src/components/ExpandedCard/ExpandContent'
import billingStore from 'store/settings/billing/billingStore'
import { NewCreditCardComponent } from 'src/routes/settings/billing/components/PaymentMethods/NewCreditCardComponent/NewCreditCardComponent'

export const PaymentMethods = observer(() => {
  const onCancel = () => {
    billingStore.setExpandPaymentCard(false)
  }

  return (
    <div className={styles.wrap}>
      <span className={'s2 medium gray1 mb8'}>Payment Methods</span>
      <div className={styles.content}>
        {billingStore.paymentsMethods.map((card) => (
          <PaymentCard key={card.id} {...card} />
        ))}
        {billingStore.expandPaymentCard ? (
          <ExpandContent
            onCancel={onCancel}
            leftContent={<NewCreditCardComponent onCancel={onCancel} />}
            primaryActionText={'Add card'}
            noPrimaryBtn
          />
        ) : (
          billingStore.paymentsMethods.length < 3 && (
            <button
              className={styles.addBtn}
              onClick={() => billingStore.setExpandPaymentCard(true)}
            >
              <AppIcon>
                <Plus />
              </AppIcon>
              <b> Add credit card</b>
            </button>
          )
        )}
      </div>
    </div>
  )
})
