import styles from './styles.module.scss'
import { useLocation } from 'react-router-dom'
import React, { useMemo } from 'react'
import { pages } from 'components/NavBar/types'
import { ReactComponent as SettingMenu } from 'icons/16pxNoMask/Setting Menu.svg'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { BtnSize, Button } from 'components/Button/Button'
import uiStore from 'store/uiStore'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { RoutesEnum } from 'src/routes/routes'
import { SmallBtn } from 'components/Button/SmallBtn'
import { ActionsEnum } from 'components/Button/types'

export const MobileBar = observer(() => {
  const { pathname } = useLocation()
  const page = useMemo(() => {
    if (uiStore.mobileTitle) {
      return uiStore.mobileTitle
    }
    if (pathname.includes('admin')) {
      return 'Admin Panel'
    }
    if (pathname.includes(RoutesEnum.account)) {
      return 'Account'
    }
    if (pathname.includes(RoutesEnum.company)) {
      return 'Company'
    }
    if (pathname.includes(RoutesEnum.billing)) {
      return 'Billing & Usage'
    }
    if (pathname.includes(RoutesEnum.numbers)) {
      return 'Numbers'
    }
    if (pathname.includes(RoutesEnum.integrations)) {
      return 'Integrations'
    }
    if (pathname.includes(RoutesEnum.audio)) {
      return 'Audio'
    }
    return pages[pathname] || pathname
  }, [pathname, uiStore.mobileTitle])
  return (
    <div className={styles.relative}>
      {uiStore.visibleSidebar && (
        <div>
          <div className={styles.blur} />
          <Button
            size={BtnSize.small}
            className={classNames(styles.btn, styles.visibleSidebar)}
            onClick={() => uiStore.toggleMenu()}
          >
            {uiStore.visibleSidebar ? <Close /> : <SettingMenu />}
          </Button>
        </div>
      )}
      <div className={styles.wrap}>
        <div className={styles.content}>
          <Button
            size={BtnSize.small}
            className={styles.btn}
            onClick={() => uiStore.toggleMenu()}
          >
            {uiStore.visibleSidebar ? <Close /> : <SettingMenu />}
          </Button>
          <span className={'bold'}>{page}</span>
          {uiStore.closeAction ? (
            <SmallBtn
              type={ActionsEnum.close}
              secondaryGray
              onClick={() => uiStore.closeAction && uiStore.closeAction()}
            />
          ) : (
            uiStore.mobileRightBtn
          )}
        </div>
      </div>
    </div>
  )
})
