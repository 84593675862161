import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import {
  Status,
  useCreateKeywordMutation,
  useUpdateKeywordMutation,
} from 'src/generated/graphql'
import { useCreateKeywordContext } from 'src/routes/main/keywords/create/CreateKeywordState'
import keywordsStore from 'store/keywords/keywordsStore'
import { useKeywordFunctions } from 'src/routes/main/keywords/hooks/useKeywordFunctions'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import classNames from 'classnames'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { CloseCreateBtn } from 'components/Page/components/CloseCreateBtn'
import { useApolloError } from 'src/hooks/useApolloError'

export const ActionsContent = observer(
  ({ fromModal }: { fromModal?: boolean }) => {
    const refUpdateDraft = useRef(false)
    const [
      createKeywordNutation,
      { loading: loadingCreate, error: errorCreate },
    ] = useCreateKeywordMutation()
    const [
      updateKeywordNutation,
      { loading: loadingUpdate, error: errorUpdate },
    ] = useUpdateKeywordMutation()
    const createKeywordStore = useCreateKeywordContext()
    const { setTrigger, trigger, onTriggerClick, showTT, setShowTT } =
      useFixedTooltip()
    const { onTest, onDelete, textTooltipTest } = useKeywordFunctions({
      keywordInput: createKeywordStore.keywordInput,
      phonesProps: createKeywordStore.phones,
      isNeedSave: !createKeywordStore.edit || createKeywordStore.isHaveChanges,
    })
    const navigate = useNavigate()

    const { ErrorAlert } = useApolloError(errorCreate || errorUpdate)

    const navigateToKeywords = () => {
      uiStore.setNextRoute('')
      navigate(RoutesEnum.keywords)
    }

    const onCreate = async () => {
      try {
        await (createKeywordStore.edit
          ? updateKeywordNutation
          : createKeywordNutation)({
          variables: {
            keywordInput: {
              ...createKeywordStore.keywordInput,
              status: createKeywordStore.activeKeyword
                ? Status.Active
                : Status.Disabled,
            },
          },
        })
        keywordsStore.tableStore.onRefresh()

        if (fromModal) {
          keywordsStore.keywordModalCB &&
            keywordsStore.keywordModalCB(
              createKeywordStore.dropdownContactListsStore.selectedOptions
            )
          keywordsStore.setOpenKeywordModal(false)
        } else {
          navigateToKeywords()
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onUpdateDraft = async () => {
      refUpdateDraft.current = true
      await updateKeywordNutation({
        variables: {
          keywordInput: createKeywordStore.keywordInput,
        },
      })
      keywordsStore.tableStore.onRefresh()

      refUpdateDraft.current = false

      if (fromModal) {
        keywordsStore.keywordModalCB &&
          keywordsStore.keywordModalCB(
            createKeywordStore.dropdownContactListsStore.selectedOptions
          )
        keywordsStore.setOpenKeywordModal(false)
      } else {
        navigateToKeywords()
      }
    }
    const onCancel = () => {
      if (fromModal) {
        keywordsStore.setOpenKeywordModal(false)
      } else {
        if (uiStore.isBeforeUnload) {
          uiStore.setOpenCancelCreateModal(true)
        } else {
          navigate(-1)
        }
      }
    }
    const handleTest = () => {
      setShowTT(false)
      onTest()
    }
    return (
      <div className={classNames(styles.wrap, fromModal && styles.fromModal)}>
        <ErrorAlert />
        <div className={classNames(styles.actionsWrap)}>
          {!createKeywordStore.openTest && (
            <div className={styles.left}>
              <Button
                typeBtn={BtnType.primary}
                size={BtnSize.medium}
                onClick={onCreate}
                disabled={createKeywordStore.disabledCreate}
                loading={
                  !refUpdateDraft.current && (loadingCreate || loadingUpdate)
                }
              >
                {createKeywordStore.edit
                  ? createKeywordStore.keywordInput.status === Status.Draft
                    ? 'Create keyword'
                    : 'Save'
                  : createKeywordStore.createBtnText}
              </Button>
              {createKeywordStore.edit &&
              createKeywordStore.keywordInput.status === Status.Draft ? (
                <Button
                  typeBtn={BtnType.secondaryGray}
                  size={BtnSize.medium}
                  onClick={onUpdateDraft}
                  loading={refUpdateDraft.current && loadingUpdate}
                >
                  Update draft
                </Button>
              ) : (
                <CloseCreateBtn onCancel={onCancel} fromActions />
              )}
            </div>
          )}
          {createKeywordStore.edit && !createKeywordStore.openTest ? (
            <>
              <FixedTooltip
                visible={showTT}
                trigger={trigger}
                white
                noArrow
                position={TTPositionEnum.topRight}
                addLeftPosition={8}
              >
                <div className={styles.menuWrap}>
                  <MuiTooltip title={textTooltipTest} arrow placement={'top'}>
                    <div>
                      <MenuBtn
                        type={MenuEnum.testMessage}
                        onClick={handleTest}
                        disabled={!createKeywordStore.name}
                      />
                    </div>
                  </MuiTooltip>

                  <MenuBtn
                    type={MenuEnum.delete}
                    onClick={() => onDelete([createKeywordStore.editId])}
                  />
                </div>
              </FixedTooltip>
              <Button
                ref={setTrigger}
                typeBtn={BtnType.secondaryGray}
                size={BtnSize.medium}
                onClick={onTriggerClick}
                icon
              >
                <More />
              </Button>
            </>
          ) : (
            <TestItBtn
              fromCreate
              textTooltip={textTooltipTest}
              withoutAction
              // tabIndex={22}
            />
          )}
        </div>
      </div>
    )
  }
)
