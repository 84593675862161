import { Button } from 'components/Button/Button'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import DatePickerLib from 'react-datepicker'
import React, { FC, useState } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as AngleUp } from 'icons/16px/Angle-Up.svg'
import { ReactComponent as AngleDown } from 'icons/16px/Angle-Down.svg'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useTimeZoneDate } from 'lib/dayjs'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { TimePickerWithoutTZ } from 'components/TimePicker/TimePickerWithoutTZ'
import user from 'store/user/user'

type Props = {
  startDate: Date | null | undefined
  onChange: (val: Date | null) => void
  label?: string
  position?: TTPositionEnum
  required?: boolean
  withoutRemove?: boolean
}

export const DateEndTimePicker: FC<Props> = observer(
  ({ startDate, onChange, position, label, required, withoutRemove }) => {
    const { trigger, showTT, setTrigger, onTriggerClick } = useFixedTooltip()

    const { startDateTz, handleChange } = useTimeZoneDate({
      startDate,
      onChange,
    })

    const [focus, setFocus] = useState(false)

    const onRemove = () => {
      onChange(null)
    }

    return (
      <div>
        <div className={styles.rowBetween}>
          {label ? (
            <div>
              <span className={'s2 medium gray1 mb8'}>{label}</span>
              &nbsp;
              {required && <span className={'s2 medium error1'}>*</span>}
            </div>
          ) : (
            <div />
          )}
          {startDateTz && !withoutRemove && (
            <Button onClick={onRemove}>
              <span className={'s2 bold blue3 link'}>Remove</span>
            </Button>
          )}
        </div>

        <div ref={setTrigger}>
          <Button
            onClick={onTriggerClick}
            className={classNames(
              styles.dropdown,
              (showTT || focus) && styles.active
            )}
          >
            <DatePickerLib
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              id={'input-time-date-picker'}
              placeholderText={'Select date and time'}
              onChange={handleChange}
              dateFormat={`MMM d, yyyy 'at' h:mm a`}
              selected={startDateTz}
              showTimeSelectOnly
              wrapperClassName={'input-time-date-picker-overlay'}
            />
            {showTT ? <AngleUp /> : <AngleDown />}
          </Button>
        </div>

        <FixedTooltip
          trigger={trigger}
          visible={showTT || focus}
          noArrow
          white
          position={position}
        >
          <div className={classNames(styles.wrapContent)}>
            <DatePickerLib
              onChange={handleChange}
              inline
              selected={startDateTz}
            />
            <div className={styles.wrapTime}>
              <TimePickerWithoutTZ
                startTime={startDateTz}
                onChange={handleChange}
                zoneAbbreviation={user.timezone?.zoneAbbreviation || ''}
              />
            </div>
          </div>
        </FixedTooltip>
      </div>
    )
  }
)
