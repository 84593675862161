import { StatusContent } from 'components/StatusContent/StatusContent'
import { Status, UnvalidatedContact } from 'src/generated/graphql'
import { ColumnType } from 'components/NewTable/types'

export const columns: ColumnType<UnvalidatedContact>[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'phoneNumber',
    label: 'Phone',
  },
  {
    id: 'invalidReason',
    label: 'Reason',
    format: (value) => (
      <StatusContent status={Status.Failed} text={value as string} />
    ),
  },
]
