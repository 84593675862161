import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { SizedBox } from 'components/SizedBox'
import { CityDropdown } from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/CityDropdown'
import { FilterByDigitsInput } from 'src/Modals/AddNumberModal/steps/ChooseNumber/components/FilterByDigitsInput'
import { IOption } from 'src/types/IOption'
import { CountryCode } from 'src/generated/graphql'
import { ReactComponent as USA } from 'icons/16pxNoMask/USA.svg'
import { ReactComponent as Canada } from 'icons/16pxNoMask/Canada.svg'

const options: IOption[] = [
  {
    title: 'United States',
    value: CountryCode.Us,
    LeftIcon: USA,
    iconClass: 'pl8',
    iconClassSelected: 'pl4',
  },
  {
    title: 'Canada',
    value: CountryCode.Ca,
    LeftIcon: Canada,
    iconClass: 'pl8',
    iconClassSelected: 'pl4',
  },
]

export const LocalNumbersFilters = observer(() => (
  <>
    <Dropdown
      labelInput={'Country'}
      options={options}
      selectedValue={addNumberStore.selectedCountryCode}
      onSelectValue={(value) => addNumberStore.setSelectedCountryCode(value)}
      withIconRender
    />
    <SizedBox height={24} />
    <CityDropdown />
    <FilterByDigitsInput />
  </>
))
