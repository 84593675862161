import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { VerificationStatus } from 'src/generated/graphql'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import { RoutesEnum } from 'src/routes/routes'
import { Alert } from 'components/Alert/Alert'
import React from 'react'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import classNames from 'classnames'
import { useCreateBroadcastContext } from 'src/routes/main/broadcasts/createBroadcast/context/CreateBroadcastContext'

export const BroadcastLimitAlert = observer(
  ({ type }: { type?: AlertTypeEnum }) => {
    const newBroadCastStore = useCreateBroadcastContext()
    return (
      <Alert
        title={newBroadCastStore.limitMessage}
        text={newBroadCastStore.limitMessageText}
        rightContent={
          billingStore.isTrial ? (
            <div className={'flex mt4'} style={{ height: 20 }}>
              <button
                onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
                className={classNames(
                  'link bold s1',
                  type === AlertTypeEnum.error && 'linkError'
                )}
              >
                Upgrade
              </button>
            </div>
          ) : (
            (newBroadCastStore.smsPhoneFull?.verificationStatus ===
              VerificationStatus.Unverified && (
              <div className={'flex mt4'} style={{ height: 20 }}>
                <LinkWrapper
                  to={RoutesEnum.settingsCompliance}
                  className={classNames(
                    's1 flex',
                    type === AlertTypeEnum.error && 'linkError'
                  )}
                >
                  Increase
                </LinkWrapper>
              </div>
            )) || <></>
          )
        }
        type={type}
      />
    )
  }
)
