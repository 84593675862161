import { Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import { useState } from 'react'
import { DeleteAccountModal } from 'src/routes/settings/account/compponents/DeleteAccount/DeleteAccountModal'
import { observer } from 'mobx-react-lite'
import user from 'store/user/user'
import { Alert } from 'components/Alert/Alert'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const DeleteAccount = observer(() => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <DeleteAccountModal open={open} onClose={() => setOpen(false)} />
      {!user.isDeleteAccount ? (
        <Button className={styles.wrap} onClick={() => setOpen(true)}>
          Delete my account
        </Button>
      ) : (
        <div className={styles.alert}>
          <Alert
            small
            title={
              'Your account will be deleted on Jan 1, 2021. To not loose access and your data, cancel the account delete.'
            }
            type={AlertTypeEnum.warning1}
            rightContent={
              <div className={styles.cancelWrap}>
                <Button onClick={() => user.setIsDeleteAccount(false)}>
                  <span>
                    <b>Cancel delete</b>
                  </span>
                  <Close />
                </Button>
              </div>
            }
          />
        </div>
      )}
    </>
  )
})
