import styles from './styles.module.scss'
import { appLinks } from 'src/util/links'

export const SignupPolicy = () => (
  <p className={styles.footerText}>
    By clicking “Sign Up” button, you agree to the Call Loop
    <br />
    <a href={appLinks.privacy} target={'_blank'} rel="noreferrer">
      Privacy Policy
    </a>
    ,{' '}
    <a href={appLinks.termsService} target={'_blank'} rel="noreferrer">
      Terms of Service
    </a>
    ,{' '}
    <a href={appLinks.tos} target={'_blank'} rel="noreferrer">
      Terms of Use
    </a>
    ,{' '}
    <a href={appLinks.antiSpam} target={'_blank'} rel="noreferrer">
      Anti-Spam Policy
    </a>
    .
  </p>
)
