import { Dispatch, SetStateAction, useEffect } from 'react'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'

export function useRelativePosition({
  relativePosition,
  position,
  triggerRects,
  contentRects,
  setRelativePosition,
  visible,
  refContent,
}: {
  relativePosition: TTPositionEnum | undefined
  position: TTPositionEnum | undefined
  setRelativePosition: Dispatch<SetStateAction<TTPositionEnum | undefined>>
  triggerRects: DOMRect | undefined
  contentRects: DOMRect | undefined
  visible: boolean | undefined
  refContent: HTMLDivElement | null
}) {
  useEffect(() => {
    const heightBody = document.body.getBoundingClientRect().height
    const isBottom =
      (relativePosition === TTPositionEnum.bottomRight &&
        position !== TTPositionEnum.topRight) ||
      (relativePosition === TTPositionEnum.bottomCenter &&
        position !== TTPositionEnum.topCenter) ||
      (relativePosition === TTPositionEnum.bottomLeft &&
        position !== TTPositionEnum.topLeft)
    const isTop =
      (relativePosition === TTPositionEnum.topRight &&
        position !== TTPositionEnum.bottomRight) ||
      (relativePosition === TTPositionEnum.topCenter &&
        position !== TTPositionEnum.bottomCenter) ||
      (relativePosition === TTPositionEnum.topLeft &&
        position !== TTPositionEnum.bottomLeft)
    if (isBottom) {
      if (triggerRects && contentRects) {
        const isVisible =
          heightBody -
            (triggerRects.top + triggerRects.height + 8 + contentRects.height) >
          0

        if (!isVisible) {
          if (relativePosition === TTPositionEnum.bottomRight) {
            setRelativePosition(TTPositionEnum.topRight)
          }
          if (relativePosition === TTPositionEnum.bottomCenter) {
            setRelativePosition(TTPositionEnum.topCenter)
          }
          if (relativePosition === TTPositionEnum.bottomLeft) {
            setRelativePosition(TTPositionEnum.topLeft)
          }
        }
      }
    }
    if (isTop) {
      if (triggerRects && contentRects) {
        const isVisible = triggerRects.top + 8 > contentRects.height

        if (!isVisible) {
          if (relativePosition === TTPositionEnum.topRight) {
            setRelativePosition(TTPositionEnum.bottomRight)
          }
          if (relativePosition === TTPositionEnum.topCenter) {
            setRelativePosition(TTPositionEnum.bottomCenter)
          }
          if (relativePosition === TTPositionEnum.topLeft) {
            setRelativePosition(TTPositionEnum.bottomLeft)
          }
        }
      }
    }
  }, [visible, refContent])
}
