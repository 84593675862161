import { Alert } from 'components/Alert/Alert'
import { Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import { DragAndDropInput } from 'components/DragnDropInput/DragAndDropInput/DragAndDropInput'
import TablePng from 'assets/images/Table.png'
import { observer } from 'mobx-react-lite'
import contactStore from 'store/contacts/contactStore'
import TemplateCSV from 'assets/template.csv'
import classNames from 'classnames'
import importContactStore from 'store/contacts/importContactStore'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { useState } from 'react'

export const Step1 = observer(() => {
  const [filesRejections, setFilesRejections] = useState<File[]>([])

  const onAddFiles = (uploadFiles: File[]) => {
    setFilesRejections([])
    importContactStore.setUploadFiles(uploadFiles)
  }

  const onFilesRejections = (files: File[]) => {
    setFilesRejections(files)
  }

  return (
    <>
      {importContactStore.importLoadingError && (
        <Alert
          type={AlertTypeEnum.error}
          text={importContactStore.importLoadingError}
        />
      )}
      {!!filesRejections.length && (
        <Alert
          type={AlertTypeEnum.error}
          title={`Upload failed`}
          text={
            <>
              Please upload the following file types: <b>*.csv</b>
            </>
          }
        />
      )}
      {!importContactStore.noLimitContact && (
        <Alert
          title={
            importContactStore.newContactCount > 0
              ? `You can import ${importContactStore.newContactCount} new contacts`
              : importContactStore.overContactsCount > 0
              ? `Only first ${importContactStore.overContactsCount} contacts will be imported`
              : `You have reached the limit of ${contactStore.maxContactCount} contacts`
          }
          type={
            importContactStore.newContactCount > 0
              ? AlertTypeEnum.info
              : importContactStore.overContactsCount > 0
              ? AlertTypeEnum.warning
              : AlertTypeEnum.error
          }
          rightContent={
            <Button
              className={styles.upgrade}
              onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
            >
              <span
                className={classNames(
                  'bold',
                  importContactStore.newContactCount > 0
                    ? 'blue3 link'
                    : importContactStore.overContactsCount > 0
                    ? 'linkOrange'
                    : 'linkError'
                )}
              >
                Upgrade plan
              </span>
            </Button>
          }
          //TODO: add link for guidelines
          text={
            <>
              Need to import more? Upgrade to a higher tier. Learn more about
              our import guidelines{' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href={'#'}
                target={'_blank'}
                className={'link'}
                rel="noreferrer"
              >
                here.
              </a>
            </>
          }
        />
      )}
      <div className={styles.content}>
        <DragAndDropInput
          files={importContactStore.uploadFiles}
          onAddFiles={onAddFiles}
          accept={'.csv'}
          title={'or drag and drop a file here'}
          subtitle={'Acceptable file types: CSV'}
          disabled={importContactStore.overContactsCount <= 0}
          inputLoading={importContactStore.importLoadingFile}
          onRemoveFile={() => importContactStore.removeFile()}
          onFilesRejections={onFilesRejections}
          withCheckAccept
        />
        <div className={styles.templateContent}>
          <img src={TablePng} alt="" />
          <h3>
            Download our{' '}
            <a href={TemplateCSV} className={'link'} download={'template'}>
              template
            </a>{' '}
            <br /> for best results
          </h3>
        </div>
      </div>
    </>
  )
})
