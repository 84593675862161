import user from 'store/user/user'
import { useGetMemberQuery } from 'src/generated/graphql'
import { useEffect, useState } from 'react'

export function useSetMember(fromLayout?: boolean, noSkip?: boolean) {
  const [skip, setSkip] = useState(false)
  const { data } = useGetMemberQuery({
    skip: noSkip ? false : fromLayout && skip,
  })
  useEffect(() => {
    if (data?.getMember) {
      setSkip(true)
      user.setMember(data?.getMember)
    }
  }, [data])
}
