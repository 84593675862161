import { SizedBox } from 'components/SizedBox'
import styles from './styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { CountriesDropdown } from 'components/Dropdown/components/CountriesDropdown'
import React from 'react'
import { Address } from 'src/generated/graphql'

export const AddressBlock = ({
  disabledEdit,
  withRemoveCountry,
  address,
  setAddress,
  countriesDropdownContentWidth = 248,
}: {
  disabledEdit?: boolean
  withRemoveCountry?: boolean
  countriesDropdownContentWidth?: number
  address: Address
  setAddress: (address: Address) => void
}) => {
  return (
    <>
      <p>
        <b>Address</b>
      </p>
      <SizedBox height={32} />
      <div className={styles.wrap}>
        <InputText
          disabled={disabledEdit}
          label={<>Street</>}
          placeholder={'e.g. 9400 S Normandie Ave #14'}
          value={address.street || ''}
          onChange={(e) => setAddress({ ...address, street: e.target.value })}
          onClickRightIcon={() => setAddress({ ...address, street: '' })}
        />
        <InputText
          disabled={disabledEdit}
          label={'City'}
          placeholder={'e.g. Los Angeles'}
          value={address.city || ''}
          onChange={(e) => setAddress({ ...address, city: e.target.value })}
          onClickRightIcon={() => setAddress({ ...address, city: '' })}
        />
        <InputText
          disabled={disabledEdit}
          label={'State'}
          placeholder={'e.g. California (CA)'}
          value={address.state || ''}
          onChange={(e) => setAddress({ ...address, state: e.target.value })}
          onClickRightIcon={() => setAddress({ ...address, state: '' })}
        />
        <InputText
          disabled={disabledEdit}
          maxLength={25}
          label={'ZIP Code'}
          placeholder={'e.g. 90011'}
          value={address.zipCode || ''}
          onChange={(e) => setAddress({ ...address, zipCode: e.target.value })}
          onClickRightIcon={() => setAddress({ ...address, zipCode: null })}
        />
        <CountriesDropdown
          country={address.country || undefined}
          setCountry={(country) => setAddress({ ...address, country })}
          disabled={disabledEdit}
          dropDownContentWidth={countriesDropdownContentWidth}
          withRemove={withRemoveCountry}
        />
      </div>
    </>
  )
}
