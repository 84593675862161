// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iPQZQfCB3zekczLxPIcz{max-width:800px}`, "",{"version":3,"sources":["webpack://./src/routes/superAdmin/modals/EditBPModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".wrap{\n  max-width: 800px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `iPQZQfCB3zekczLxPIcz`
};
export default ___CSS_LOADER_EXPORT___;
