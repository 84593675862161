import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { useAdminGetNotificationQuery } from 'src/generated/graphql'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { Link } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import React, { useEffect } from 'react'
import superAdminStore from 'store/superAdmin/superAdminStore'
import classNames from 'classnames'

export const AdminNotification = observer(
  ({
    isImports,
    isCompliance,
  }: {
    isImports?: boolean
    isCompliance?: boolean
  }) => {
    const { data } = useAdminGetNotificationQuery()
    useEffect(() => {
      if (data) {
        superAdminStore.setNotificationCount(
          data.adminGetImportHistory?.total || 0,
          data.adminOrganizationComplianceList?.total || 0
        )
      }
    }, [data])

    const needsReviewImportsCountContent =
      !!superAdminStore.needsReviewImportsCount && (
        <MuiTooltip title={'Needs review imports'} arrow placement={'top'}>
          <div className={styles.wrap}>
            <Link to={RoutesEnum.adminImports}>
              {superAdminStore.needsReviewImportsCount}
            </Link>
          </div>
        </MuiTooltip>
      )
    const needsReviewComplianceCountContent =
      !!superAdminStore.needsReviewComplianceCount && (
        <MuiTooltip title={'Needs review compliance'} arrow placement={'top'}>
          <div className={classNames(styles.wrap, styles.compliance)}>
            <Link to={RoutesEnum.compliance}>
              {superAdminStore.needsReviewComplianceCount}
            </Link>
          </div>
        </MuiTooltip>
      )

    if (isImports) {
      return <>{needsReviewImportsCountContent}</>
    }
    if (isCompliance) {
      return <> {needsReviewComplianceCountContent}</>
    }
    return (
      <div className={classNames(styles.wrapIcons, 'row8')}>
        {needsReviewImportsCountContent}
        {needsReviewComplianceCountContent}
      </div>
    )
  }
)
