import styles from './styles.module.scss'
import { CreateElementBtn } from 'components/Button/CreateElementBtn'
import { SizedBox } from 'components/SizedBox'
import { ReactComponent as KeywordsIcon } from 'icons/32px/Keywords.svg'
import { ReactComponent as BroadcastsIcon } from 'icons/32px/Broadcasts.svg'
import { ReactComponent as CampaignsIcon } from 'icons/32px/Campaigns.svg'
import { ReactComponent as TriggersIcon } from 'icons/32px/Triggers.svg'
import { ReactComponent as WebformsIcon } from 'icons/32px/Webforms.svg'
import { ReactComponent as ContactsIcon } from 'icons/32px/Contacts.svg'
import { ReactComponent as SegmentsIcon } from 'icons/32px/Segments.svg'
import { ReactComponent as AudioIcon } from 'icons/32px/Audio.svg'
import classNames from 'classnames'
import { AppElements } from 'src/enums/appElements'

export const EmptyState = ({
  element,
  description,
  heightV2,
  actions,
  add,
  height,
  createText,
  onCreate,
}: {
  element: AppElements
  description: string
  heightV2?: boolean
  height?: string | number
  add?: boolean
  createText?: string
  actions?: JSX.Element
  onCreate?: () => void
}) => {
  return (
    <div
      className={classNames(styles.wrap, heightV2 && styles.heightV2)}
      style={{ height }}
    >
      {element === AppElements.Keyword && <KeywordsIcon />}
      {element === AppElements.Broadcast && <BroadcastsIcon />}
      {element === AppElements.Campaign && <CampaignsIcon />}
      {element === AppElements.Trigger && <TriggersIcon />}
      {element === AppElements.WebForm && <WebformsIcon />}
      {element === AppElements.Contact && <ContactsIcon />}
      {element === AppElements.List && <ContactsIcon />}
      {element === AppElements.Segment && <SegmentsIcon />}
      {element === AppElements.Audio && <AudioIcon />}
      <SizedBox height={20} />
      <h3 className={'bold mb8'}>{`${
        add ? 'Add' : `${createText ? createText : 'Create'} your first`
      } ${element}`}</h3>
      <p className={'par2 gray1'}>{description}</p>
      <SizedBox height={24} />
      {actions ?? (
        <CreateElementBtn
          appElement={element}
          createText={createText}
          onCreate={onCreate}
        />
      )}
    </div>
  )
}
