import { observer } from 'mobx-react-lite'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { Alert } from 'components/Alert/Alert'
import billingStore from 'store/settings/billing/billingStore'
import auth from 'store/auth'
import user from 'store/user/user'

export const AddCreditCardAlert = observer(() => {
  const onPayNow = () => {
    billingStore.setOpenAddCardModal(true)
  }
  if (
    !auth.loadingMainInfo &&
    user.role === 'OWNER' &&
    user.member?.registrationFinished &&
    billingStore.clSubscription &&
    !billingStore.primaryPaymentMethodId
  ) {
    return (
      <Alert
        type={AlertTypeEnum.error}
        text={
          <>
            <b>Please add credit card.</b>
          </>
        }
        global
        rightContent={
          <button className={'s1 bold white'} onClick={onPayNow}>
            Add card
          </button>
        }
      />
    )
  }
  return null
})
