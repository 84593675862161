import { observer } from 'mobx-react-lite'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { Link } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import { Alert } from 'components/Alert/Alert'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { TollFreeVerificationStatus } from 'src/generated/graphql'
import dayjs, { timestampToAbsoluteDay } from 'lib/dayjs'

export const RejectTollFreeAlert = observer(() => {
  const { profile } = tollFreeVerificationStore
  if (
    !profile?.isNotResubmitable &&
    profile?.status === TollFreeVerificationStatus.Rejected
  ) {
    const date = dayjs(profile.verificationRejectedDate).valueOf()
    const currentDay = Math.ceil(timestampToAbsoluteDay(+new Date()))
    const rejectedDay = Math.ceil(timestampToAbsoluteDay(date))
    const days = rejectedDay + 7 - currentDay

    if (days > 0) {
      return (
        <Alert
          title={`URGENT: Your Toll-Free Verification application was Rejected. You have ${days} days to edit and resubmit.`}
          type={AlertTypeEnum.error}
          global
          rightContent={
            <Link to={RoutesEnum.settingsCompliance}>
              <span className={'bold white'}>Update now</span>
            </Link>
          }
        />
      )
    }
    if (days < 1) {
      return (
        <Alert
          title={`Your toll-free resubmission deadline has passed and your texts are blocked by the carriers. Please re-submit your application for carrier approval.`}
          type={AlertTypeEnum.error}
          global
          rightContent={
            <Link to={RoutesEnum.settingsCompliance}>
              <span className={'bold white'}>Submit now</span>
            </Link>
          }
        />
      )
    }
  }
  if (
    profile?.isNotResubmitable &&
    profile?.status === TollFreeVerificationStatus.Rejected
  ) {
    return (
      <Alert
        title={`Your Toll-Free Verification was rejected. Please resubmit to resume sending text messages.`}
        type={AlertTypeEnum.error}
        global
        rightContent={
          <Link to={RoutesEnum.settingsCompliance}>
            <span className={'bold white'}>Update</span>
          </Link>
        }
      />
    )
  }

  return <></>
})
