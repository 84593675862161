import { nanoid } from 'nanoid'
import { ICustomField } from 'src/types/ICustomField'
import { IOption } from 'src/types/IOption'
import { CustomFieldType } from 'src/generated/graphql'

export const mockPages: IOption[] = [
  {
    value: '',
    title: 'None',
  },
  {
    value: 'custom',
    title: 'Custom page',
  },
]

export const phoneCollectField: ICustomField = {
  id: 'phoneNumber',
  idLong: 0,
  key: 'phoneNumber',
  name: 'Phone',
  required: true,
  type: CustomFieldType.Number,
  placeholder: '(123) 123-1234',
}

export const generateField = (): ICustomField => ({
  id: nanoid(),
  idLong: 0,
  name: '',
  required: true,
  type: undefined,
  placeholder: '(123) 123-1234',
})
