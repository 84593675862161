import { observer } from 'mobx-react-lite'
import { RangePlanCard } from 'src/Modals/UpgradePlanModal/Step1Upgrade/components/RangePlanCard/RangePlanCard'
import migrationStore from 'store/migration/migrationStore'
import styles from './styles.module.scss'
import { useMemo } from 'react'
import { MigrationPlanCardWithNoSubscription } from 'src/routes/auth/migration/stpes/ChoosePlanStep/UpgradeSubscriptionContent/MigrationPlanCardWithNoSubscription'
import { OldMigrationPlanCard } from 'src/routes/auth/migration/stpes/ChoosePlanStep/UpgradeSubscriptionContent/OldMigrationPlanCard'
import { MigrationSubscriptionCard } from 'src/routes/auth/migration/stpes/ChoosePlanStep/UpgradeSubscriptionContent/MigrationSubscriptionCard'
import { Button } from 'components/Button/Button'
import { BonusExpiredCreditsMigrationSubscriptionCard } from 'src/routes/auth/migration/stpes/ChoosePlanStep/UpgradeSubscriptionContent/BonusExpiredCreditsMigrationSubscriptionCard'

export const UpgradeSubscriptionContent = observer(() => {
  const {
    withExpiredCredits,
    activeCurrentPlan,
    oldMigrationSubscription,
    activePeriod,
    setActiveCurrentPlan,
    proPricesForPeriod,
    rangeValue,
    setSelectedRangeValue,
    isOldPayAsYouGo,
  } = migrationStore

  const leftCard = useMemo(() => {
    if (withExpiredCredits) {
      return <BonusExpiredCreditsMigrationSubscriptionCard />
    }
    return <MigrationSubscriptionCard withBonusCredits />
  }, [withExpiredCredits, oldMigrationSubscription, activeCurrentPlan])

  const rightCard = useMemo(() => {
    if (!oldMigrationSubscription) {
      return <MigrationPlanCardWithNoSubscription />
    }
    if (withExpiredCredits && !activeCurrentPlan) {
      return <MigrationSubscriptionCard />
    }
    return <OldMigrationPlanCard />
  }, [withExpiredCredits, oldMigrationSubscription, activeCurrentPlan])

  return (
    <>
      <RangePlanCard
        period={activePeriod}
        rightBtn={
          oldMigrationSubscription &&
          withExpiredCredits &&
          !isOldPayAsYouGo && (
            <Button
              onClick={() => {
                setActiveCurrentPlan(!activeCurrentPlan)
              }}
            >
              <span className={'s2 bold linkGray2'}>
                {activeCurrentPlan ? 'Select plan' : 'Current plan'}
              </span>
            </Button>
          )
        }
        rangeInputProps={{
          rangeValues: proPricesForPeriod.map(
            (price) => price.creditsIncluded || 0
          ),
          value: rangeValue,
          max: proPricesForPeriod.length - 1,
          onChangeValue: setSelectedRangeValue,
        }}
      />
      <div className={styles.cards}>
        {leftCard}
        {rightCard}
      </div>
    </>
  )
})
