// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wA9R7yQW9juhU_11vqM_{display:flex;align-items:center;column-gap:8px}.Z2_2OOwmlv76Owl2Jh6r{display:flex;align-items:center;align-content:center}.gDQDugCrL7jcGLU7DFSo{height:82px;display:grid;grid-template-columns:1fr max-content;align-items:center;padding:0 24px}.gDQDugCrL7jcGLU7DFSo .y1Np6PEyF8giK11_r1Lk{display:grid;row-gap:4px}.gDQDugCrL7jcGLU7DFSo .e8elQLNSwrRSgRpDKgtd{display:flex;column-gap:24px;align-items:center}.kn99YShnsu1ZfXX8TW29{background-color:var(--primary-color-gray-4);border-top:1px solid var(--primary-color-gray-3);border-bottom-left-radius:12px;border-bottom-right-radius:12px;padding:24px;display:grid;grid-template-columns:1fr max-content;align-items:flex-end;gap:12px}`, "",{"version":3,"sources":["webpack://./src/components/ExpandedCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,oBAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,qCAAA,CACA,kBAAA,CACA,cAAA,CACA,4CACE,YAAA,CACA,WAAA,CAEF,4CACE,YAAA,CACA,eAAA,CACA,kBAAA,CAIJ,sBACE,4CAAA,CACA,gDAAA,CACA,8BAAA,CACA,+BAAA,CACA,YAAA,CACA,YAAA,CACA,qCAAA,CACA,oBAAA,CACA,QAAA","sourcesContent":[".row{\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n.row2{\n  display: flex;\n  align-items: center;\n  align-content: center;\n}\n.content{\n  height: 82px;\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  align-items: center;\n  padding: 0 24px;\n  .left{\n    display: grid;\n    row-gap: 4px;\n  }\n  .right{\n    display: flex;\n    column-gap: 24px;\n    align-items: center;\n  }\n}\n\n.expand{\n  background-color: var(--primary-color-gray-4);\n  border-top: 1px solid var(--primary-color-gray-3);\n  border-bottom-left-radius: 12px;\n  border-bottom-right-radius: 12px;\n  padding: 24px;\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  align-items: flex-end;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `wA9R7yQW9juhU_11vqM_`,
	"row2": `Z2_2OOwmlv76Owl2Jh6r`,
	"content": `gDQDugCrL7jcGLU7DFSo`,
	"left": `y1Np6PEyF8giK11_r1Lk`,
	"right": `e8elQLNSwrRSgRpDKgtd`,
	"expand": `kn99YShnsu1ZfXX8TW29`
};
export default ___CSS_LOADER_EXPORT___;
