import styles from './styles.module.scss'
import { NavItem } from 'components/NavBar/NavItem'
import {
  HelpNavItem,
  navItems,
  navItemsAdmin,
  navItemsSettings,
  ProductUpdatesNavItem,
} from 'components/NavBar/navItems'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Plan } from 'components/Plan/Plan'
import user from 'store/user/user'
import { RoutesEnum } from 'src/routes/routes'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { MigrationCard } from 'components/NavBar/components/MigrationCard/MigrationCard'
import configStore from 'store/configStore'
import { useMemo } from 'react'
import { NavType } from 'components/NavBar/types'

export const NavBar = observer(() => {
  const { pathname } = useLocation()

  const navItemsSettingsFiltered = useMemo(() => {
    let items = navItemsSettings
    if (!configStore.isMultiOrg) {
      items = navItemsSettings.filter((item) => item.type !== NavType.switchOrg)
    }
    if (!user.isOwner) {
      items = navItemsSettings.filter(
        (item) => item.route !== RoutesEnum.billing
      )
    }
    return items
  }, [user.isOwner, configStore.isMultiOrg, navItemsSettings])
  return (
    <div
      className={styles.wrap}
      style={{ visibility: uiStore.visibleSidebar ? 'visible' : 'hidden' }}
    >
      <div className={styles.content}>
        <div>
          <Routes>
            <Route
              path={`${RoutesEnum.settings}/*`}
              element={
                <>
                  {navItemsSettingsFiltered.map((navItem) => (
                    <NavItem key={navItem.text} navItem={navItem} />
                  ))}
                </>
              }
            />
            {user.isAdmin && (
              <Route
                path={`${RoutesEnum.admin}/*`}
                element={
                  <>
                    {navItemsAdmin.map((navItem) => (
                      <NavItem key={navItem.text} navItem={navItem} />
                    ))}
                  </>
                }
              />
            )}
            <Route
              path={'/*'}
              element={
                <>
                  {(user.isAdmin
                    ? navItems
                    : navItems.filter((item) => !item.route?.includes('admin'))
                  ).map((navItem) => (
                    <NavItem
                      key={navItem.text}
                      navItem={{
                        ...navItem,
                        isNew:
                          navItem.route === RoutesEnum.contacts
                            ? configStore.isSegments
                            : navItem.isNew,
                      }}
                    />
                  ))}
                </>
              }
            />
          </Routes>
        </div>

        <MigrationCard />
        {!pathname.includes(RoutesEnum.admin) && (
          <div>
            <NavItem
              key={ProductUpdatesNavItem.text}
              navItem={ProductUpdatesNavItem}
            />
            <NavItem key={HelpNavItem.text} navItem={HelpNavItem} />
          </div>
        )}
        <Plan />
      </div>
    </div>
  )
})
