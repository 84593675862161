export enum ActionsEnum {
  addCredits = 'Add credits',
  addCallerId = 'Add caller ID',
  edit = 'Edit',
  rename = 'Rename',
  start = 'Start',
  editLists = 'Edit lists',
  resubscribe = 'Resubscribe',
  unsubscribe = 'Unsubscribe',
  archive = 'Archive',
  unarchive = 'Unarchive',
  restore = 'Restore',
  delete = 'Delete',
  delete2 = 'delete2',
  stop = 'Stop',
  download = 'Download',
  markValid = 'Mark valid',
  copy = 'Copy',
  duplicate = 'Duplicate',
  analytics = 'Analytics',
  export = 'Export',
  testWebWorm = 'Test web form',
  testMessage = 'Test message',
  testAudio = 'Test audio',
  getCode = 'Get code',
  setting = 'Setting',
  more = 'More',
  details = 'Details',
  pause = 'Pause',
  resume = 'Resume',
  refresh = 'refresh',
  reload = 'reload',

  ban = 'Ban',
  unblock = 'Unblock',
  close = 'Close',
  check = 'Check',
  validatePhone = 'Validate phone numbers',
  search = 'search',
  sent = 'sent',
  forward = 'forward',
  disabled = 'disabled',
  switch = 'switch',
  turnOn = 'Turn on',
  turnOff = 'Turn off',
}
