import { OptionRender } from 'components/Dropdown/components/OptionRender'
import React, { useMemo } from 'react'
import { OptionsRenderProps } from 'components/Dropdown/types'
import { IOption } from 'src/types/IOption'
import { Separator } from 'components/Separator/Separator'
import { PhoneType } from 'src/generated/graphql'

const groupToString = (group: IOption['group']): string => {
  if (!group) {
    return ''
  }
  const obj: { [key: string]: string } = {
    [PhoneType.Local]: 'Local numbers',
    [PhoneType.TollFree]: 'Toll-free numbers',
    [PhoneType.ShortCode]: 'Short-codes',
    [PhoneType.CallerId]: 'Verified caller ID',
  }
  return obj[group] || group
}

export const GroupingOptionsRender = <T,>({
  options,
  groupActions,
  ...props
}: OptionsRenderProps<T>) => {
  return useMemo(() => {
    const mapGroup = new Map<IOption['group'], IOption[]>()
    options?.forEach((option) => {
      const values = mapGroup.get(option.group) || []
      mapGroup.set(option.group, [...values, option])
    })

    return (
      <div>
        {Array.from(mapGroup.entries()).map(([key, values], index, array) => {
          const count = groupActions && key && groupActions[key]?.count
          const countString = count ? `(${count})` : ''
          const label =
            groupActions && key
              ? `${groupActions[key].label} ${countString}`
              : groupToString(key)
          return (
            <div key={key}>
              <div className={'flex-space-between'}>
                {key && (
                  <span className={'s2 medium gray1 ml8 mb8 mt8'}>{label}</span>
                )}
                {groupActions && key && (
                  <div className={'mr8'}>{groupActions[key]?.action}</div>
                )}
              </div>

              {values?.map((option) => (
                <OptionRender key={option.value} option={option} {...props} />
              ))}
              {index !== array.length - 1 && <Separator marginBlock={8} />}
            </div>
          )
        })}
      </div>
    )
  }, [options, props])
}
