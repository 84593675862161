import { InputText } from 'components/Input/InputText/InputText'
import { DateField } from 'components/Fields/DateField/DateField'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { DateEndTimePicker } from 'components/DatePicker/DateEndTimePicker'
import { observer } from 'mobx-react-lite'
import { CustomFieldType } from 'src/generated/graphql'
import dayjs from 'lib/dayjs'
import { ICustomField } from 'src/types/ICustomField'
import { ICustomFieldValue } from 'src/types/ICustomFieldValue'

export const CustomFieldsContent = observer(
  ({
    fields,
    mapValues,
    setValues,
    error,
    clearError,
  }: {
    fields: Array<ICustomField>
    mapValues: Map<string, ICustomFieldValue>
    setValues: (map: Map<string, ICustomFieldValue>) => void
    error: string
    clearError: () => void
  }) => {
    const onChangeField = ({
      field,
      value,
      date = null,
    }: {
      field: ICustomField
      value?: string
      date?: Date | null
    }) => {
      const newMap = new Map(mapValues)
      const prevValue = newMap.get(field.id)
      if (prevValue) {
        newMap.set(field.id, {
          ...prevValue,
          value: value,
          date: date,
        })
      }
      setValues(newMap)
    }
    return (
      <div className={'col16'}>
        {fields.map((field) => {
          if (field.type === CustomFieldType.Text) {
            return (
              <InputText
                key={field.id}
                label={field.name}
                placeholder={`Enter ${field.name}`}
                value={mapValues.get(field.id)?.value}
                onChange={(event) =>
                  onChangeField({ field, value: event.target.value })
                }
                onClickRightIcon={() => onChangeField({ field, value: '' })}
              />
            )
          }
          if (field.type === CustomFieldType.Url) {
            return (
              <InputText
                key={field.id}
                error={
                  error.includes('Provided url is not valid')
                    ? 'Invalid URL. Example: http(s)://******.***'
                    : undefined
                }
                label={field.name}
                placeholder={`Enter ${field.name}`}
                value={mapValues.get(field.id)?.value}
                onChange={(event) => {
                  error.includes('Provided url is not valid') && clearError()
                  onChangeField({ field, value: event.target.value })
                }}
                onClickRightIcon={() => onChangeField({ field, value: '' })}
              />
            )
          }
          if (field.type === CustomFieldType.Number) {
            return (
              <InputText
                key={field.id}
                numericFormat
                label={field.name}
                placeholder={`Enter ${field.name}`}
                value={mapValues.get(field.id)?.value}
                onChangeValue={(value) => onChangeField({ field, value })}
              />
            )
          }
          if (field.type === CustomFieldType.Date) {
            return (
              <DateField
                key={field.id}
                title={String(field.name)}
                date={
                  mapValues.get(field.id)?.date
                    ? dayjs(mapValues.get(field.id)?.date).toDate()
                    : undefined
                }
                onSetDate={(date: Date | null) =>
                  onChangeField({ field, date })
                }
              />
            )
          }
          if (field.type === CustomFieldType.DateTime) {
            return (
              <DateEndTimePicker
                key={field.id}
                label={String(field.name)}
                startDate={
                  mapValues.get(field.id)?.date
                    ? dayjs(mapValues.get(field.id)?.date).toDate()
                    : undefined
                }
                onChange={(date: Date | null) => onChangeField({ field, date })}
                position={TTPositionEnum.topLeft}
              />
            )
          }
        })}
      </div>
    )
  }
)
