import { computed, makeObservable, observable } from 'mobx'
import { FilterFieldType, FilterInput } from 'src/generated/graphql'
import {
  CommonFilter,
  CommonFilterProps,
} from 'store/contacts/segment/filters/CommonFilter'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'

type ListFilterStoreProps = CommonFilterProps

export class ListFilter extends CommonFilter {
  dropdownContactListsStore = new DropdownContactListsStore()
  constructor(props: ListFilterStoreProps) {
    super(props)
    makeObservable(this, {
      dropdownContactListsStore: observable,
      filterInput: computed,
      isNotEmpty: computed,
    })
    if (props.value) {
      this.dropdownContactListsStore.setInitListsIds(
        props.value?.split(',').map((value) => Number(value)) || []
      )
    }
  }

  get isNotEmpty() {
    return this.dropdownContactListsStore.selectedContactListsIds.length
  }

  get filterInput(): FilterInput {
    return {
      orIndex: this.orIndex,
      andIndex: this.andIndex,
      dataType: this.dataType,
      condition: this.condition,
      field: this.field,
      value: this.dropdownContactListsStore.selectedContactListsIds.join(','),
      type: FilterFieldType.Contact,
    }
  }
}
