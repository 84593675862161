import { action, computed, makeObservable, observable } from 'mobx'
import { FilterDataType, FilterInput } from 'src/generated/graphql'
import {
  CommonFilter,
  CommonFilterProps,
} from 'store/contacts/segment/filters/CommonFilter'
import { IOption } from 'src/types/IOption'

type TextFilterProps = CommonFilterProps

export class TextFilter extends CommonFilter {
  constructor(props: TextFilterProps) {
    super(props)
    makeObservable(this, {
      values: observable,
      setValues: action.bound,
      onDeleteId: action.bound,
      addValue: action.bound,
      filterInput: computed,
      options: computed,
      isNumber: computed,
      isNotEmpty: computed,
    })
    if (props.value) {
      this.values = props.value.split(',')
    }
  }

  values: string[] = []

  get options(): IOption[] {
    return this.values.map((value) => ({ title: value, value }))
  }

  get filterInput(): FilterInput {
    return {
      orIndex: this.orIndex,
      andIndex: this.andIndex,
      dataType: this.dataType,
      condition: this.condition,
      field: this.field,
      value: this.values.join(','),
      // type: FilterFieldType.Contact,
    }
  }

  get isNotEmpty() {
    return this.values.length
  }

  get isNumber() {
    return (
      this.dataType === FilterDataType.Phone ||
      this.dataType === FilterDataType.Number
    )
  }

  setValues = (values: typeof this.values) => {
    this.values = values
  }
  addValue = (value: string) => {
    if (this.values.includes(value)) return
    this.values = [...this.values, value]
  }
  onDeleteId = (id: string) => {
    this.values = this.values.filter((idList) => idList !== id)
  }
}
