// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlJQSXAQY6gNqWI04PIZ{border-radius:16px;background-color:var(--primary-color-gray-4);padding:16px 16px 14px 16px}.Rvb8_zLJTJ05HHIHsUUS{display:flex;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/Modals/UpgradePlanModal/Step1Upgrade/components/RangePlanCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CAAA,CACA,2BAAA,CAEF,sBACE,YAAA,CACA,6BAAA","sourcesContent":[".wrapRangeInput{\n  border-radius: 16px;\n  background-color: var(--primary-color-gray-4);\n  padding: 16px 16px 14px 16px;\n}\n.row{\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapRangeInput": `AlJQSXAQY6gNqWI04PIZ`,
	"row": `Rvb8_zLJTJ05HHIHsUUS`
};
export default ___CSS_LOADER_EXPORT___;
