import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CollapseContent } from 'src/routes/settings/account/routes/PrivacyAccount/password/CollapseContent'
import { ExpandContent } from 'src/routes/settings/account/routes/PrivacyAccount/password/ExpandContent'

export const ChangePasswordContent = observer(() => {
  const [expand, setExpand] = useState(false)

  return expand ? (
    <ExpandContent onCollapse={() => setExpand(false)} />
  ) : (
    <CollapseContent onExpand={() => setExpand(true)} />
  )
})
