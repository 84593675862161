import { ActionsEnum } from 'components/Button/types'
import { Status } from 'src/generated/graphql'

export function getTriggerActionsByStatus(status: Status): ActionsEnum[] {
  if (status === Status.Active) {
    return [
      ActionsEnum.switch,
      ActionsEnum.details,
      ActionsEnum.edit,
      ActionsEnum.copy,
      ActionsEnum.export,
      ActionsEnum.duplicate,
      ActionsEnum.archive,
      ActionsEnum.delete,
    ]
  }
  if (status === Status.Disabled) {
    return [
      ActionsEnum.switch,
      ActionsEnum.details,
      ActionsEnum.edit,
      ActionsEnum.copy,
      ActionsEnum.export,
      ActionsEnum.duplicate,
      ActionsEnum.archive,
      ActionsEnum.delete,
    ]
  }
  if (status === Status.Draft) {
    return [
      ActionsEnum.details,
      ActionsEnum.edit,
      ActionsEnum.duplicate,
      ActionsEnum.delete,
    ]
  }
  if (status === Status.Archived) {
    return [
      ActionsEnum.details,
      ActionsEnum.export,
      ActionsEnum.duplicate,
      ActionsEnum.restore,
      ActionsEnum.delete,
    ]
  }
  return []
}
