import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { useContactFunctions } from 'src/routes/main/contacts/hooks/useContactFunctions'
import { IContactActions } from 'src/routes/main/contacts/functions/getContactActions'

export const ContactsDeleteBtn = (props: IContactActions) => {
  const { onDelete } = useContactFunctions(props)
  return <TableBtn {...props} action={ActionsEnum.delete} onAction={onDelete} />
}
