import styles from './styles.module.scss'
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { ReactComponent as Close } from 'icons/24px/Close.svg'
import { BtnSize, Button } from 'components/Button/Button'
import classNames from 'classnames'
import { Portal } from '../Portal'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { Steps } from 'components/Modal/Steps/Steps'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { IInfoModal, InfoModal } from 'components/Modal/ui/InfoModal/InfoModal'

export type IModalProps = {
  open?: boolean
  onClose?: () => void
  title?: ReactNode
  subTitle?: string | JSX.Element
  titleTooltip?: string
  minWidth?: number
  minHeight?: number
  withoutHeader?: boolean
  headerContent?: JSX.Element
  centered?: boolean
  centered2?: boolean
  topRight?: boolean
  steps?: string[]
  activeStep?: number
  staticContent?: boolean
  loading?: boolean
  triggerHeight?: string
  infoModalProps?: Omit<IInfoModal, 'onClose'>
  children?: ReactNode
}

export const Modal: FC<IModalProps> = observer(
  ({
    open,
    onClose,
    title,
    subTitle,
    minHeight,
    minWidth,
    withoutHeader,
    centered,
    headerContent,
    steps,
    activeStep = 0,
    topRight,
    titleTooltip,
    staticContent,
    centered2,
    loading,
    triggerHeight,
    children,
    infoModalProps,
  }) => {
    const refContent = useRef<HTMLDivElement>(null)
    const onOverlayClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      onClose && onClose()
    }
    const [isCentered, setIsCentered] = useState(false)
    useEffect(() => {
      if (open && refContent.current && centered2) {
        setIsCentered(
          window.document.body.offsetHeight -
            (refContent.current?.offsetHeight || 0) -
            240 <
            0
        )
      }
    }, [open, refContent, triggerHeight])

    const content = (
      <div
        className={classNames(
          styles.wrap,
          uiStore.isMobile && styles.mobile,
          centered && styles.centered,
          centered2 && isCentered && styles.centered,
          topRight && styles.topRight,
          staticContent && styles.staticContent
        )}
        onClick={onOverlayClick}
        aria-hidden="true"
        style={{ minHeight: uiStore.minRootHeight }}
      >
        <div
          className={styles.content}
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={{ minHeight }}
          aria-hidden="true"
          ref={refContent}
          id={'modal_content'}
        >
          {!infoModalProps && !withoutHeader && (
            <div className={styles.header}>
              {title && (
                <div className={'fullWidth'}>
                  {subTitle && <span className={'gray1 mb28'}>{subTitle}</span>}
                  <h3 className={'bold'}>
                    {title}
                    {titleTooltip && <InfoTooltip title={titleTooltip} large />}
                  </h3>
                </div>
              )}
              <div className={styles.headerSteps}>
                {steps && <Steps steps={steps} activeStep={activeStep} />}
                {headerContent}
              </div>

              {!staticContent && (
                <Button
                  size={BtnSize.mini}
                  onClick={onClose}
                  className={styles.close}
                >
                  <Close />
                </Button>
              )}
            </div>
          )}
          {loading ? (
            <div
              style={{
                height: 528,
                width: minWidth || 800,
                position: 'relative',
              }}
            >
              {/*<SizedBox height={528} />*/}
              <AbsoluteLoader />
            </div>
          ) : (
            <div className={styles.wrapContent}>
              {infoModalProps && (
                <InfoModal {...infoModalProps} onClose={onClose} />
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    )
    return (
      <>
        {open && !staticContent && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <Portal>{content}</Portal>
        )}
        {open && staticContent && content}
      </>
    )
  }
)
