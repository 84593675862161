import { Dropdown } from 'components/Dropdown/Dropdown'
import React, { useEffect, useState } from 'react'
import { Country, useGetAllCountriesQuery } from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'

export const CountriesDropdown = ({
  disabled,
  country,
  setCountry,
  dropDownContentWidth,
  withRemove,
}: {
  disabled?: boolean
  withRemove?: boolean
  country?: Country | null
  setCountry: (country: Country | null | undefined) => void
  dropDownContentWidth?: number
}) => {
  const { data } = useGetAllCountriesQuery()
  const [countriesOption, setCountriesOption] = useState<IOption[]>([])

  const countryToOption = (country: Country) => ({
    title: country.countryName || '',
    value: country.countryCode,
    data: country,
    optionClass: 'p4_0',
  })

  useEffect(() => {
    if (data?.getAllCountries) {
      const options: IOption[] = []
      data?.getAllCountries.forEach((country) => {
        if (country) {
          options.push(countryToOption(country))
        }
      })
      setCountriesOption(options)
    }
  }, [data])
  return (
    <Dropdown
      disabled={disabled}
      withSearch
      openOnFocus
      withNoResults
      dropDownContentWidth={dropDownContentWidth}
      labelInput={'Country'}
      options={countriesOption}
      selectedOption={country ? countryToOption(country) : undefined}
      placeholder={'e.g. United States'}
      onSelect={(option) => setCountry(option.data)}
      onRemove={() => setCountry(null)}
      withRemove={withRemove}
    />
  )
}
