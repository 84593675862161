import { CompanyPage } from 'components/Page/CompanyPage/CompanyPage'
import { TollFreeVerificationContent } from 'src/routes/settings/compliance/TollFreeVerification/TollFreeVerificationContent'
import { useSetFullTollFreeProfile } from 'src/routes/settings/compliance/TollFreeVerification/hooks/useSetFullTollFreeProfile'
import { useSetFullBusinessProfile } from 'src/routes/settings/compliance/BusinessProfile/hooks/useSetFullBusinessProfile'
import businessProfileStore from 'store/settings/company/businessProfileStore'
import { observer } from 'mobx-react-lite'

export const TollFreeVerification = observer(() => {
  const { loading: loadingProfile } = useSetFullTollFreeProfile()
  const { loading: loadingBusiness } = useSetFullBusinessProfile(
    !!businessProfileStore.businessProfile
  )
  return (
    <CompanyPage loading={loadingProfile || loadingBusiness}>
      <TollFreeVerificationContent />
    </CompanyPage>
  )
})
