import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16px/CloseBlack.svg'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Asset } from 'src/generated/graphql'
import { useInView } from 'react-intersection-observer'

type Props = {
  img: Asset
  removeAttachImages?: (ids: string[]) => void
  onImageClick: (e: React.MouseEvent) => void
  small?: boolean
  medium?: boolean
  setInvisibleAssets?: Dispatch<SetStateAction<Asset[]>>
}
export const ImageCard: FC<Props> = observer(
  ({
    img,
    removeAttachImages,
    onImageClick,
    small,
    medium,
    setInvisibleAssets,
  }) => {
    const { ref, inView } = useInView({
      initialInView: true,
      threshold: 1,
    })
    const [hover, setHover] = useState(false)
    const onRemoveImage = (e: React.MouseEvent) => {
      e.stopPropagation()
      removeAttachImages && removeAttachImages([img.id])
    }
    useEffect(() => {
      setInvisibleAssets &&
        setInvisibleAssets((prev) => {
          if (inView) {
            return prev.filter((asset) => asset.id !== img.id)
          } else {
            return [...prev, img]
          }
        })
    }, [inView])
    return (
      <div
        ref={ref}
        className={classNames(
          styles.wrap,
          small && styles.small,
          medium && styles.medium
        )}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onImageClick}
        aria-hidden={true}
      >
        <div
          className={styles.img}
          style={{ backgroundImage: `url(${img.compressedUrl})` }}
        />
        {hover && (
          <div className={styles.addInfo}>
            {removeAttachImages && (
              <Button onClick={onRemoveImage}>
                <Close />
              </Button>
            )}
          </div>
        )}
      </div>
    )
  }
)
