import { makeAutoObservable } from 'mobx'
import { IBroadcast } from 'src/types/IBroadcast'
import { nanoid } from 'nanoid'
import { TableStore } from 'components/NewTable/store/TableStore'
import { getBroadcastsTableColumns } from 'components/NewTable/columns/getBroadcastsTableColumns'
import {
  BroadcastFullResponseFragment,
  BroadcastResponseFragment,
  Direction,
  GetAllBroadcastsQuery,
} from 'src/generated/graphql'
import {
  transformBroadcast,
  transformFullBroadcast,
} from 'src/routes/main/broadcasts/functions/transformBroadcast'
import { Maybe } from 'graphql/jsutils/Maybe'

class BroadcastsStore {
  tableStore: TableStore<IBroadcast>
  constructor() {
    this.tableStore = new TableStore({
      tableName: 'BroadcastsTable',
      columns: getBroadcastsTableColumns(this.setBroadcastId),
      defaultHiddenColumn: ['listBroadcasts', 'messagesStat.unsubscribedCount'],
      orderBy: 'sendTime',
      order: Direction.Desc,
    })
    makeAutoObservable(this)
  }
  broadcastsMap: Map<string, IBroadcast> = new Map()

  broadcastDetailModal: IBroadcast | null = null

  broadcastId: number | null = null

  get openBroadcastDetailModal() {
    return !!this.broadcastDetailModal
  }

  get broadcasts() {
    return Array.from(this.broadcastsMap.values())
  }

  setBroadcastDetailModal(broadcast: IBroadcast | null) {
    this.broadcastDetailModal = broadcast
  }
  updateBroadcast = (broadcast: BroadcastResponseFragment) => {
    const newBroadcast = transformBroadcast(broadcast)
    this.broadcastsMap.set(newBroadcast.id, newBroadcast)
  }
  updateFullBroadcast = (
    broadcast: BroadcastFullResponseFragment
  ): IBroadcast => {
    const newBroadcast = transformFullBroadcast(broadcast)
    this.broadcastsMap.set(newBroadcast.id, newBroadcast)
    return newBroadcast
  }
  deleteBroadcasts(ids: string[]) {
    ids.forEach((id) => {
      this.broadcastsMap.delete(id)
    })
    this.tableStore.setCheckedRows(
      this.tableStore.checkedRows.filter(
        (broadcast) => !ids.includes(broadcast.id)
      )
    )
    this.tableStore.onRefresh()
  }
  duplicate(broadcast: IBroadcast) {
    const newId = nanoid()
    this.broadcastsMap.set(newId, { ...broadcast, id: newId })
  }

  setBroadcasts(broadcasts: Maybe<Array<Maybe<BroadcastResponseFragment>>>) {
    broadcasts?.map((broadcast) => {
      if (broadcast) {
        this.updateBroadcast(broadcast)
      }
    })
  }

  setData(data: GetAllBroadcastsQuery) {
    this.tableStore.setTotal(data.getAllBroadcasts?.total || 0)
    this.broadcastsMap.clear()

    data.getAllBroadcasts?.content?.forEach((broadcast) => {
      if (broadcast) {
        const newBroadcast = transformBroadcast(broadcast)
        this.broadcastsMap.set(newBroadcast.id, newBroadcast)
      }
    })
  }

  setBroadcastId = (id: typeof this.broadcastId) => {
    this.broadcastId = id
  }
}

export default new BroadcastsStore()
