import styles from './styles.module.scss'
import { VCardGallery } from 'components/VCard/VCardGallery/VCardGallery'
import { ImageCard } from 'components/ImageCard/ImageCard'
import presentationStore from 'store/presentationStore'
import React, { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Asset, ContactCardFragment } from 'src/generated/graphql'

type Props = {
  onDeleteVCard?: (id: number) => void
  removeAttachImages?: (ids: string[]) => void
  small?: boolean
  medium?: boolean
  fromTemplate?: boolean
  fromDataCollectionItem?: boolean
  attachImages?: Asset[]
  vCards?: ContactCardFragment[]
  stopPropagation?: boolean
}
export const AttachmentGallery: FC<Props> = observer(
  ({
    onDeleteVCard,
    attachImages,
    removeAttachImages,
    small,
    fromTemplate,
    fromDataCollectionItem,
    vCards,
    medium,
    stopPropagation,
  }) => {
    const [invisibleAssets, setInvisibleAssets] = useState<Asset[]>([])
    const onOverlayClick = () => {
      if (attachImages) {
        presentationStore.setCurrentIndex(
          attachImages.length - invisibleAssets.length
        )
        presentationStore.setPresentationImages(attachImages)
      }
    }
    return (
      <div
        className={classNames(
          styles.imagesWrap,
          small && styles.small,
          medium && styles.medium
        )}
      >
        {!!vCards?.length &&
          vCards.map((vCard) => (
            <VCardGallery
              key={vCard.id}
              onDelete={onDeleteVCard}
              small={small}
              vCard={vCard}
              stopPropagation={stopPropagation}
            />
          ))}
        {attachImages?.map((img, index) => (
          <ImageCard
            small={small}
            medium={medium}
            img={img}
            key={img.id}
            removeAttachImages={removeAttachImages}
            onImageClick={(e) => {
              if (stopPropagation) {
                e.stopPropagation()
              }
              presentationStore.setCurrentIndex(index)
              presentationStore.setPresentationImages(attachImages)
            }}
            setInvisibleAssets={setInvisibleAssets}
          />
        ))}
        {!!invisibleAssets.length && (
          <div
            className={classNames(
              styles.overlay,
              small && styles.small,
              fromTemplate && styles.fromTemplate,
              fromDataCollectionItem && styles.fromDataCollectionItem
            )}
            onClick={onOverlayClick}
            aria-hidden={true}
          >
            <span className={'medium white'}>+{invisibleAssets.length}</span>
          </div>
        )}
      </div>
    )
  }
)
