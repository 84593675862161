import { Modal } from 'components/Modal/Modal'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { ReactComponent as Reload } from 'icons/16px/Reload.svg'
import { useResendInvitationMutation } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import teamStore from 'store/settings/teamStore'

export const InviteSentModal = observer(() => {
  const [resendInvitation, { loading }] = useResendInvitationMutation()
  const onResend = async () => {
    try {
      await resendInvitation({
        variables: {
          invitationId: teamStore.sentInvite?.id,
        },
      })
      teamStore.setSentInvite(
        teamStore.sentInvite
          ? {
              ...teamStore.sentInvite,
              isResent: true,
            }
          : null
      )
    } catch (e) {
      console.error(e)
    }
  }
  const onClose = () => {
    teamStore.setSentInvite(null)
  }
  return (
    <Modal
      open={!!teamStore.sentInvite}
      onClose={onClose}
      title={'Recover password'}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon />
        <h3>Invitation {teamStore.sentInvite?.isResent ? 'resent' : 'sent'}</h3>
        <p className={'par2 gray1'}>
          Email sent to <br />
          {teamStore.sentInvite?.email}
        </p>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          fullWidth
          onClick={onResend}
          loading={loading}
          mb={8}
        >
          <Reload />
          Resend invitation
        </Button>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onClose}
          fullWidth
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
