import dayjs from 'lib/dayjs'
import { IContact } from 'src/types/IContact'
import { ColumnType } from 'components/NewTable/types'

export const webFormDetailColumns: ColumnType<IContact>[] = [
  {
    id: 'createdAt',
    label: 'Added time',
    minWidth: 249,
    format: (value) => dayjs(value as Date).format('MMM DD, YYYY - h:mm a'),
  },
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'formattedPhoneNumber', label: 'Phone', minWidth: 170 },
  // {
  //   id: 'shortUrlClickStatistics',
  //   label: 'Clicks',
  //   minWidth: 170,
  //   format: (value, { shortUrlClickStatistics }) =>
  //     shortUrlClickStatistics ? (
  //       <Clicks shortUrlClickStatistics={shortUrlClickStatistics} />
  //     ) : (
  //       ''
  //     ),
  // },
]
