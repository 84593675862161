import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import user from 'store/user/user'

export const CollapseContent = observer(
  ({ onExpand }: { onExpand: () => void }) => {
    return (
      <>
        <SizedBox height={24} />
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onExpand}
        >
          {user.isEmailAuth ? 'Change' : 'Set'} password
        </Button>
      </>
    )
  }
)
