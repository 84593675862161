import styles from './styles.module.scss'
import NewTable from 'components/NewTable/NewTable'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Filters } from 'src/routes/main/contacts/segments/SegmentsTable/Filters/Filters'
import { EmptyState } from 'components/EmptyState/EmptyState'
import * as React from 'react'
import { useState } from 'react'
import { ISegment } from 'src/types/ISegment'
import segmentStore from 'store/contacts/segment/segmentStore'
import { useGetSegmentsQuery } from 'src/generated/graphql'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { getSegmentActions } from 'src/routes/main/contacts/segments/functions/getSegmentActions'
import { useSetData } from 'src/hooks/useSetData'
import configStore from 'store/configStore'
import { ComingSoon } from 'components/CommingSoon/ComingSoon'
import { ReactComponent as SegmentsIcon } from 'icons/32px/Segments.svg'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { AppElements } from 'src/enums/appElements'

export const SegmentsTable = observer(() => {
  const { tableStore } = segmentStore
  const options = useTablePaginationOptions(tableStore)
  const queryResult = useGetSegmentsQuery(options)
  const [rows, setRows] = useState<ISegment[]>([])
  const navigate = useNavigate()

  const onRowClick = (id: string) => {
    navigate(String(id))
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => {
      if (data) {
        tableStore.setTotal(data?.getSegments?.total)
        const items: ISegment[] = []
        data.getSegments?.content?.forEach((item) => {
          if (item) {
            items.push({ ...item, id: item.id })
          }
        })
        setRows(items)
      }
    },
    refreshDeps: [tableStore.refreshTrigger],
  })

  if (!configStore.isSegments) {
    return (
      <ComingSoon
        description={'Improve reach rate with contact filtering'}
        height={'calc(100vh - 396px)'}
        icon={<SegmentsIcon />}
        width={'calc(100% - 64px)'}
      >
        <div />
      </ComingSoon>
    )
  }

  if (!loading && !rows.length && !options.variables.searchPattern.trim()) {
    return (
      <EmptyState
        element={AppElements.Segment}
        description={'Improve reach rate with contact filtering'}
        heightV2
      />
    )
  }

  return (
    <div className={styles.wrap}>
      <NewTable<ISegment>
        withCheckbox
        columns={tableStore.visibleColumns}
        rows={rows.map((item) => {
          return {
            ...item,
            actions: getSegmentActions(item),
          }
        })}
        emptyContent={
          rows.length ? undefined : (
            <EmptyStateStatistic description={'No segments matching'} />
          )
        }
        leftStickyContent={<Filters />}
        withSearch
        onRowClick={onRowClick}
        withCalcMaxHeight
        tableStore={tableStore}
        pageLoading={loading || refetchLoading}
        firstLoading={firstLoading}
      />
    </div>
  )
})
