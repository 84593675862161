// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dxMtSmmi4KIjuncrIsWX{display:grid;gap:8px;padding:8px 8px 20px 8px}`, "",{"version":3,"sources":["webpack://./src/components/Schedule/ScheduleContentWrapper/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,wBAAA","sourcesContent":[".wrap{\n  display: grid;\n  gap: 8px;\n  padding: 8px 8px 20px 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `dxMtSmmi4KIjuncrIsWX`
};
export default ___CSS_LOADER_EXPORT___;
