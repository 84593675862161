// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhvhTQdfGzs0eWWjlGa9{display:grid;grid-template-columns:512px 200px;column-gap:24px;grid-template-rows:440px max-content}.i1k3K0UlPejUvT8oHjfV{display:grid;grid-template-columns:max-content max-content 1fr max-content;column-gap:12px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/Modals/UpgradePlanModal/Step3Downgrade/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iCAAA,CACA,eAAA,CACA,oCAAA,CAEF,sBACE,YAAA,CACA,6DAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".wrap{\n  display: grid;\n  grid-template-columns: 512px 200px;\n  column-gap: 24px;\n  grid-template-rows: 440px max-content;\n}\n.actions{\n  display: grid;\n  grid-template-columns: max-content max-content 1fr max-content;\n  column-gap: 12px;\n  margin-top: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `WhvhTQdfGzs0eWWjlGa9`,
	"actions": `i1k3K0UlPejUvT8oHjfV`
};
export default ___CSS_LOADER_EXPORT___;
