import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { SizedBox } from 'components/SizedBox'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import addNumberStore from 'store/settings/numbers/addNumberStore'
import { PhoneType } from 'src/generated/graphql'

export const CongratsModal = observer(() => {
  return (
    <Modal
      open={addNumberStore.congratsNumberModal}
      onClose={() => addNumberStore.setCongratsNumberModal(false)}
      withoutHeader
    >
      <div className={styles.wrap}>
        <ModalIcon />
        <h3>
          Congrats! Your{' '}
          {addNumberStore.congratsNumber?.type === PhoneType.TollFree
            ? 'toll - free'
            : 'local'}{' '}
          number is
        </h3>
        <h3 className={'bold'}>
          {addNumberStore.congratsNumber &&
            addNumberStore.congratsNumber.friendlyName}
        </h3>
        <SizedBox height={24} />
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={() => addNumberStore.setCongratsNumberModal(false)}
          fullWidth
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
})
