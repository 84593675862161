import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import styles from 'components/Page/styles.module.scss'
import { ReactComponent as Close } from 'icons/16pxNoMask/Close.svg'
import { useNavigate } from 'react-router-dom'
import React from 'react'

export const CloseCreateBtn = observer(
  ({
    fromActions,
    onCancel,
  }: {
    fromActions?: boolean
    onCancel?: () => void
  }) => {
    const navigate = useNavigate()
    const onClose = () => {
      if (onCancel) {
        onCancel()
      } else {
        if (uiStore.isBeforeUnload) {
          uiStore.setOpenCancelCreateModal(true)
        } else {
          navigate(-1)
        }
      }
    }
    if (fromActions) {
      if (uiStore.isMobile) {
        if (uiStore.isPreview) {
          return (
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={() => uiStore.setIsPreview(false)}
            >
              Hide preview
            </Button>
          )
        }
        return (
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={() => uiStore.setIsPreview(true)}
          >
            Preview
          </Button>
        )
      } else {
        return (
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        )
      }
    }

    return (
      <Button
        size={uiStore.isMobile ? BtnSize.small : BtnSize.medium}
        typeBtn={BtnType.secondaryGray}
        className={styles.closeBtn}
        onClick={onClose}
        icon
        // tabIndex={20}
      >
        <Close />
      </Button>
    )
  }
)
