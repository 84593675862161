import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'

type Props = {
  leftContent: JSX.Element
  primaryActionText?: string
  onSave?: () => void
  onCancel: () => void
  disabledSave?: boolean
  noPrimaryBtn?: boolean
}

export const ExpandContent = ({
  onSave,
  onCancel,
  leftContent,
  primaryActionText = 'Save',
  disabledSave,
  noPrimaryBtn,
}: Props) => {
  return (
    <div className={styles.expand}>
      {leftContent}
      <div className={'row12'}>
        {!noPrimaryBtn && (
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={onSave}
            disabled={disabledSave}
          >
            {primaryActionText}
          </Button>
        )}
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}
