// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q2XfPIc0TRVcGgtcYtt5{display:unset;font-weight:500;font-size:14px;line-height:20px;color:var(--primary-color-black)}`, "",{"version":3,"sources":["webpack://./src/routes/settings/billing/modals/LimitSubscriptionModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,gCAAA","sourcesContent":[".titlePlan{\n  display: unset;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--primary-color-black);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titlePlan": `q2XfPIc0TRVcGgtcYtt5`
};
export default ___CSS_LOADER_EXPORT___;
