import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import companyStore from 'store/settings/company/companyStore'
import phoneCheckerSrc from 'assets/images/PhoneCheckr.png'
import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  useSwitchPhoneCheckerMutation,
  useVerifyContactListsMutation,
  useVerifyContactsMutation,
} from 'src/generated/graphql'
import contactStore from 'store/contacts/contactStore'

export const PhoneCheckrModal = observer(() => {
  const [switchPhoneChecker] = useSwitchPhoneCheckerMutation()
  const [verifyContacts, { loading: loadingVerifyContacts }] =
    useVerifyContactsMutation()
  const [verifyContactLists, { loading: loadingVerifyContactLists }] =
    useVerifyContactListsMutation()
  const onClose = () => {
    companyStore.setPhoneCheckerModal({ state: false })
  }

  const onValidateContacts = async () => {
    try {
      if (companyStore.listsValidateIds.length) {
        const { data } = await verifyContactLists({
          variables: {
            listIds: companyStore.listsValidateIds,
          },
        })
        if (data?.verifyContactLists) {
          contactStore.updateContactLists(data.verifyContactLists)
          contactStore.tableStoreLists.setCheckedRows([])
          companyStore.setSuccessValidateModal(true)
        }
      } else {
        if (companyStore.contactsValidateBulk) {
          const { data } = await verifyContacts({
            variables: {
              bulk: companyStore.contactsValidateBulk,
            },
          })
          if (data?.verifyContacts) {
            contactStore.onUpdateContacts()
            contactStore.tableStoreContacts.setCheckedRows([])
            companyStore.setSuccessValidateModal(true)
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onTurnOn = async () => {
    try {
      const { data } = await switchPhoneChecker({
        variables: {
          input: {
            enabled: true,
            verifyExisting: companyStore.verifyExisting,
          },
        },
      })
      if (data?.switchPhoneChecker) {
        companyStore.setOrganization(data.switchPhoneChecker)
        companyStore.setActivatePhoneCheckrModal(true)
        companyStore.setTurnOnAutoRechargeModal(null)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onContinue = () => {
    companyStore.setPhoneCheckerModal({ state: false })
    companyStore.setTurnOnAutoRechargeModal('phoneChecker', onTurnOn)
  }

  return (
    <Modal
      open={companyStore.phoneCheckerModal}
      onClose={onClose}
      title={'PhoneCheckr'}
      minWidth={600}
    >
      <div className={'pModal'}>
        <img src={phoneCheckerSrc} alt="" className={'mb32'} />
        <div className={styles.wrap}>
          <p className={'par0 bold mb20'}>About</p>
          <p className={'par2'}>
            PhoneCheckr is a lookup tool that validates contacts’ phone numbers.
            It prevents sending messages to invalid numbers, thus losing credits
            and getting blocked by carriers. <br />
            <br /> One phone number lookup costs 0.25 credit.
          </p>
        </div>
        <div className={'mt40 row12'}>
          {companyStore.contactsValidateBulk ? (
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={onValidateContacts}
              loading={loadingVerifyContacts || loadingVerifyContactLists}
            >
              Validate phone numbers
            </Button>
          ) : (
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={onContinue}
            >
              Continue
            </Button>
          )}

          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
