import { useEffect, useState } from 'react'
import billingStore from 'store/settings/billing/billingStore'

export function useCardDropdownOptions(): [
  string,
  (value: string | undefined) => void
] {
  const [selectedValue, onSelectValue] = useState<string | undefined>(
    billingStore.primaryCard?.id || ''
  )

  useEffect(() => {
    if (billingStore.upgradedCardId) {
      onSelectValue(billingStore.upgradedCardId)
    }
  }, [billingStore.upgradedCardId])

  useEffect(() => {
    if (
      !selectedValue ||
      (selectedValue && !billingStore.paymentsMethodsMap.get(selectedValue))
    ) {
      onSelectValue(
        billingStore.primaryCard?.id ||
          (billingStore.paymentsMethods.length &&
            billingStore.paymentsMethods[0].id) ||
          ''
      )
    }
  }, [billingStore.cardsOptions])
  return [selectedValue || '', onSelectValue]
}
