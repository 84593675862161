import * as React from 'react'
import { ColumnType } from 'components/NewTable/types'
import { IPhoneNumber } from 'src/types/IPhoneNumber'
import { AppSymbols } from 'src/util/symbols'
import { PhoneType, VerificationStatus } from 'src/generated/graphql'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { observer } from 'mobx-react-lite'
import numbersStore from 'store/settings/numbers/numbersStore'

export const numbersTableColumns: ColumnType<IPhoneNumber>[] = [
  { id: 'friendlyName', label: 'Phone' },
  {
    id: 'region',
    label: 'Region',
    format: (value, { city, state }) => (
      <>
        {[city || '', state || ''].filter((val) => val).join(', ') ||
          AppSymbols.dash}
      </>
    ),
  },
  {
    id: 'type',
    label: 'Type',
    format: (value, { verificationStatus }) => (
      <>
        {value === PhoneType.TollFree ? (
          <StatusContent
            status={verificationStatus || VerificationStatus.Unverified}
            text={'Toll-Free'}
            tooltipIconText={
              (verificationStatus === VerificationStatus.InReview &&
                'In review') ||
              (verificationStatus === VerificationStatus.Blocked &&
                'Blocked') ||
              (verificationStatus === VerificationStatus.Pending &&
                'Pending') ||
              (verificationStatus === VerificationStatus.Unverified &&
                'Unverified') ||
              (verificationStatus === VerificationStatus.Restricted &&
                'Restricted') ||
              (verificationStatus === VerificationStatus.Verified &&
                'Verified') ||
              verificationStatus ||
              ''
            }
          />
        ) : value === PhoneType.Local ? (
          'Local'
        ) : (
          value
        )}
      </>
    ),
  },
  {
    id: 'callForwardEnabled',
    label: 'Call forwarding',
    format: (value, { destinationPhoneNumber, destinationExtension }) =>
      value ? (
        <div className={'row8'}>
          {destinationPhoneNumber} <div className={'point'} />
          <span className={'gray1'}>Ext {destinationExtension}</span>
        </div>
      ) : (
        <span className={'gray2'}>Disabled</span>
      ),
  },
  {
    id: 'useCase',
    label: 'Use case',
    format: (value, { id }) => <UseCase id={id} />,
  },
]

const UseCase = observer(({ id }: { id: string }) => {
  return <>{numbersStore.numbersUseCaseTitleMap.get(id) || AppSymbols.dash}</>
})

export const noSortsColumnsNumbers = ['useCase']
