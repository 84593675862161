// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c6P1Ctx4CSraYMjIVl6X{margin:8px 0 0 0;display:grid;grid-template-columns:1fr 100px}.A18GEoVpq81qMPKQ_t6A{display:flex;align-items:center;justify-content:right;column-gap:8px}.wvsbNEbeCkkKu4THXL3x{display:grid;grid-template-columns:200px 1fr;column-gap:12px;align-items:end;margin-bottom:24px}.tafjXKAQDu7ssYeZoN3C{display:grid;row-gap:8px;margin-bottom:40px}.yehL1PBxYxYhbzx0q82d{display:flex;column-gap:12px}.KJVPM_3COzXgcDb9g9yW{width:100%}.fw3IeatA9u_EYTK4saNf{min-width:294px}`, "",{"version":3,"sources":["webpack://./src/routes/main/triggers/create/components/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,YAAA,CACA,+BAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,cAAA,CAEF,sBACE,YAAA,CACA,+BAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CAKF,sBACE,YAAA,CACA,WAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,eAAA,CAEF,sBACE,UAAA,CAEF,sBACE,eAAA","sourcesContent":[".inputWrap{\n  margin: 8px 0 0 0;\n  display: grid;\n  grid-template-columns: 1fr 100px;\n}\n.switch{\n  display: flex;\n  align-items: center;\n  justify-content: right;\n  column-gap: 8px;\n}\n.sendWrap{\n  display: grid;\n  grid-template-columns: 200px 1fr;\n  column-gap: 12px;\n  align-items: end;\n  margin-bottom: 24px;\n}\n\n\n\n.appDropdownContent{\n  display: grid;\n  row-gap: 8px;\n  margin-bottom: 40px;\n}\n.appDropdownWrap{\n  display: flex;\n  column-gap: 12px;\n}\n.appDrop{\n  width: 100%;\n}\n.phoneDrop{\n  min-width: 294px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrap": `c6P1Ctx4CSraYMjIVl6X`,
	"switch": `A18GEoVpq81qMPKQ_t6A`,
	"sendWrap": `wvsbNEbeCkkKu4THXL3x`,
	"appDropdownContent": `tafjXKAQDu7ssYeZoN3C`,
	"appDropdownWrap": `yehL1PBxYxYhbzx0q82d`,
	"appDrop": `KJVPM_3COzXgcDb9g9yW`,
	"phoneDrop": `fw3IeatA9u_EYTK4saNf`
};
export default ___CSS_LOADER_EXPORT___;
