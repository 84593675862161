import numbersStore from 'store/settings/numbers/numbersStore'
import { Modal } from 'components/Modal/Modal'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import {
  useAddExternalPhoneNumberMutation,
  useConfirmExternalPhoneNumberMutation,
} from 'src/generated/graphql'
import { useEffect, useState } from 'react'
import accountStore from 'store/settings/accountStore'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import { SizedBox } from 'components/SizedBox'
import { InputText } from 'components/Input/InputText/InputText'
import styles from './styles.module.scss'

export const VerifyCallerIdModal = observer(() => {
  const [phone, setPhone] = useState('')
  const [extension, setExtension] = useState('')
  const onClose = () => {
    numbersStore.setOpenVerifyCallerIdModal(false)
  }

  useEffect(() => {
    setPhone('')
    setExtension('')
  }, [numbersStore.openVerifyCallerIdModal])

  const isVerifyDisabled = !phone || phone.includes('_')
  const [addExternalPhoneNumber, { loading }] =
    useAddExternalPhoneNumberMutation()
  const [confirmExternalPhoneNumber] = useConfirmExternalPhoneNumberMutation()

  const handleSendVerificationCode = async () => {
    try {
      const { data } = await addExternalPhoneNumber({
        variables: {
          inputPhoneNumber: phone,
          extension: extension.trim(),
        },
      })

      const uuidCodeRes = data?.addExternalPhoneNumber
      if (uuidCodeRes) {
        numbersStore.setUuidCode(uuidCodeRes)

        accountStore.setOpenVerifyNumberModal(true, {
          title: 'Verify caller ID',
          info1: `We’ve called ${phone}`,
          info2: 'Didn’t receive the call?',
          phone: phone,
          size: 6,
          onVerify: async (code) => {
            await handleVerifyCode(code)
          },
          onResend: async () => {
            await handleResendCode()
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  //
  const handleResendCode = async () => {
    try {
      const { data } = await addExternalPhoneNumber({
        variables: {
          inputPhoneNumber: phone,
        },
      })
      const uuidCodeRes = data?.addExternalPhoneNumber
      uuidCodeRes && numbersStore.setUuidCode(uuidCodeRes)
    } catch (e) {
      console.error(e)
    }
  }

  const handleVerifyCode = async (code: string) => {
    try {
      const { data } = await confirmExternalPhoneNumber({
        variables: {
          code,
          uuid: numbersStore.uuidCode,
        },
      })
      if (data?.confirmExternalPhoneNumber) {
        numbersStore.setVerifiedNumber(data.confirmExternalPhoneNumber)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleOpenVerifyModal = async () => {
    try {
      await handleSendVerificationCode()
      onClose && onClose()
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Modal
      open={numbersStore.openVerifyCallerIdModal}
      onClose={onClose}
      title={'Add caller ID'}
    >
      <div className={'smallModal'}>
        <Alert
          type={AlertTypeEnum.notify2}
          text={
            'Enter the phone number you want to verify. It can be used for Voice and Ringless messages.'
          }
        />
        <SizedBox height={32} />
        <div className={styles.wrapNumber}>
          <InputPhone
            value={phone}
            onChangeValue={setPhone}
            label={'Phone number'}
            noIcon
          />
          <InputText
            label={'Extension'}
            withoutClear
            patternFormat
            placeholder={'123'}
            format={'###'}
            value={extension}
            onChange={(e) => setExtension(e.target.value)}
          />
        </div>

        <div className={'mt40 row12'}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            onClick={handleOpenVerifyModal}
            disabled={isVerifyDisabled}
            loading={loading}
          >
            Add number
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
