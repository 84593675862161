import { observer } from 'mobx-react-lite'
import { useApplyCouponMutation } from 'src/generated/graphql'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import cookie from 'js-cookie'
import { CookieEnum } from 'src/enums/CookieEnum'

export const StripeCoupon = observer(() => {
  const [applyCoupon] = useApplyCouponMutation()
  const navigate = useNavigate()
  useEffect(() => {
    const stripeCoupon = cookie.get(CookieEnum.StripeCoupon)
    cookie.remove(CookieEnum.StripeCoupon)
    if (stripeCoupon) {
      applyCoupon({
        variables: {
          couponId: stripeCoupon,
        },
      })
        .then(({ data }) => {
          if (data?.applyCoupon) {
            if (data?.applyCoupon.stripeCouponId === stripeCoupon) {
              alertStore.setAlert({
                title: 'Coupon applied!',
                type: AlertTypeEnum.success,
              })
            }
            navigate(window.location.pathname)
          }
        })
        .catch(console.error)
    }
  }, [])
  return null
})
