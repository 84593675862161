import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  TollFreeVerificationProfile,
  useAdminTollFreeVerificationProfilePreviousStepMutation,
  useTollFreeVerificationProfilePreviousStepMutation,
} from 'src/generated/graphql'

export const TollFreeBackBtn = observer(() => {
  const navigate = useNavigate()

  const { step, setProfile, adminOrgId, setOpenEditModal } =
    tollFreeVerificationStore

  const [tollFreeVerificationProfilePrevious, { loading }] =
    useTollFreeVerificationProfilePreviousStepMutation()

  const [adminTollFreeVerificationProfilePrevious, { loading: loadingAdmin }] =
    useAdminTollFreeVerificationProfilePreviousStepMutation()

  const onCancel = async () => {
    try {
      if (step === 1) {
        if (adminOrgId) {
          setOpenEditModal(false)
        } else {
          navigate(-1)
        }
      } else {
        let profile: TollFreeVerificationProfile | null | undefined
        if (adminOrgId) {
          const { data } = await adminTollFreeVerificationProfilePrevious({
            variables: { organizationId: adminOrgId },
          })
          profile = data?.adminTollFreeVerificationProfilePreviousStep
        } else {
          const { data } = await tollFreeVerificationProfilePrevious()
          profile = data?.tollFreeVerificationProfilePreviousStep
        }
        if (profile) {
          setProfile(profile)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Button
      size={BtnSize.medium}
      typeBtn={BtnType.secondaryGray}
      onClick={onCancel}
      loading={loading || loadingAdmin}
    >
      {step === 1 ? 'Cancel' : 'Back'}
    </Button>
  )
})
