import { useGetDataForSmsQuery } from 'src/generated/graphql'
import { useSetCustomFields } from 'src/routes/main/contacts/customFields/hooks/useSetCustomFields'
import { useEffect } from 'react'
import vCardStore from 'store/settings/vCardStore'

export function useSetSMSData(onLoad: () => void) {
  const { data } = useGetDataForSmsQuery()
  useSetCustomFields(data)
  useEffect(() => {
    if (data) {
      vCardStore.setMyContactsCardData(data)
      onLoad()
    }
  }, [data])
}
