import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import companyStore from 'store/settings/company/companyStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { useSwitchPhoneCheckerMutation } from 'src/generated/graphql'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'

export const TurnOffPhoneCheckrModal = observer(() => {
  const [switchPhoneChecker, { loading }] = useSwitchPhoneCheckerMutation()
  const onTurnOn = async () => {
    try {
      const { data } = await switchPhoneChecker({
        variables: {
          input: {
            enabled: false,
          },
        },
      })
      if (data?.switchPhoneChecker) {
        companyStore.setOrganization(data.switchPhoneChecker)
        alertStore.setAlert({
          type: AlertTypeEnum.success,
          title: 'PhoneCheckr is disabled',
        })
        companyStore.setTurnOffPhoneCheckerModal(false)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const onClose = () => {
    companyStore.setTurnOffPhoneCheckerModal(false)
  }

  return (
    <Modal
      open={companyStore.turnOffPhoneCheckerModal}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon type={'warning'} />
        <h3>Disable PhoneCheckr?</h3>
        <p className={'par2 gray1'}>
          Disabling PhoneCheckr increases the risk of sending messages to
          invalid numbers and getting blocked by carriers.
        </p>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onTurnOn}
          loading={loading}
          fullWidth
          mb={8}
        >
          Disable
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onClose}
          fullWidth
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
})
