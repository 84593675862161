import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { IAudioItem } from 'src/types/IAudioItem'
import { useAudioFunctions } from 'src/routes/settings/audio/hooks/useAudioFunctions'

export const AudioDeleteBtn = (props: ActionBtnProps<IAudioItem>) => {
  const { row, rows } = props
  const { onDelete } = useAudioFunctions({
    audioItem: row,
    audioItems: rows,
  })
  return (
    <TableBtn
      {...props}
      action={ActionsEnum.delete}
      onAction={() => onDelete()}
    />
  )
}
