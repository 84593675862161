// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P26ge0LCRDHwJypd6Y4Z{margin-top:24px;display:grid;gap:12px;grid-template-columns:1fr max-content}`, "",{"version":3,"sources":["webpack://./src/routes/settings/integrations/modal/CustomIntegrationModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,QAAA,CACA,qCAAA","sourcesContent":[".wrapCustom{\n  margin-top: 24px;\n  display: grid;\n  gap: 12px;\n  grid-template-columns: 1fr max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapCustom": `P26ge0LCRDHwJypd6Y4Z`
};
export default ___CSS_LOADER_EXPORT___;
