import shortenLinkStore from 'store/shortenLinkStore'

export const replaceLink = (link: string, extraSpace?: boolean) => {
  if (
    link.startsWith('http://') ||
    link.startsWith('https://') ||
    link.startsWith('www.') ||
    shortenLinkStore.isShorten(link)
  ) {
    return `<a target='_blank' href='${link}' data-shorten='${shortenLinkStore.isShorten(
      link
    )}' contenteditable='false'>${link}</a>${extraSpace ? ' ' : ''}`
  }
  return link
}
