import { Alert } from 'components/Alert/Alert'
import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import a2p10DLCStore from 'store/settings/company/a2p10DLCStore'
import { useInitOptInImageTenDlcMutation } from 'src/generated/graphql'
import React from 'react'
import { OptInItem } from 'src/routes/settings/compliance/registrations/components/OptInItem/OptInItem'
import { BackBtn } from 'src/routes/settings/compliance/registrations/A2P10DLCRegistration/steps/BackBtn'
import typesStore from 'store/typesStore'

export const Step5 = observer(() => {
  const [initOptInImageTenDlc, { loading }] = useInitOptInImageTenDlcMutation()
  const onContinue = async () => {
    try {
      const { data } = await initOptInImageTenDlc({
        variables: {
          input: a2p10DLCStore.tenDlcOptInImagesRequestInput,
        },
      })
      if (data?.initOptInImageTenDlc?.profile) {
        a2p10DLCStore.setTenDlcProfile(data.initOptInImageTenDlc.profile)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div className={'col40'}>
      <div>
        <Alert
          text={`Please provide images of the opt-in process and what a subscriber is agreeing to. This should be where the customer’s phone number was entered by the customer agreeing to receive the SMS.`}
        />
        <span className={'mt16 s2 gray1'}>
          Browse or drag and drop an image. Supported files: png, jpg - up to
          5MB
        </span>
      </div>

      <div className={'col24'}>
        {a2p10DLCStore.optInMethods.map((method) => (
          <OptInItem
            key={method}
            successCb={(assets) => a2p10DLCStore.setOptInImages(method, assets)}
            onRemove={(ids) => a2p10DLCStore.removeOptInImages(method, ids)}
            label={typesStore.a2pOptInMethodsMapping.get(method) || method}
            attachImages={a2p10DLCStore.optInImagesMap.get(method)}
          />
        ))}
      </div>
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          loading={loading}
          disabled={a2p10DLCStore.disabledOptInMethodsImages}
        >
          {a2p10DLCStore.continueText}
        </Button>
        <BackBtn />
      </div>
    </div>
  )
})
