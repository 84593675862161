import { useGetTokenForOrganizationLazyQuery } from 'src/generated/graphql'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import { SmallBtn } from 'components/Button/SmallBtn'
import * as React from 'react'
import { IOrganization } from 'src/types/IOrganization'
import { IOrganizationDetails } from 'src/types/IOrganizationDetails'
import { ActionBtnProps } from 'components/NewTable/types'

export const AssumeOrganizationBtn = ({
  onCloseTT,
  row,
  menu,
  medium,
}: ActionBtnProps<IOrganization | IOrganizationDetails>) => {
  const [getTokenForOrganization, { loading }] =
    useGetTokenForOrganizationLazyQuery()
  if (!row) {
    return <></>
  }
  const onAssume = async () => {
    if (!row) return
    onCloseTT && onCloseTT()
    try {
      const { data } = await getTokenForOrganization({
        variables: {
          request: {
            organizationId: row.id,
          },
        },
      })
      if (data) {
        const a = document.createElement('a')
        a.target = '_blank'
        a.href = `${window.location.origin}?assumeToken=${data.getTokenForOrganization?.token}`
        a.click()
      }
    } catch (e) {
      console.error(e)
    }
  }
  return medium || menu ? (
    <MediumBtn
      type={ActionsEnum.details}
      onClick={onAssume}
      text={'Assume an Account'}
      disabled={loading}
      menu={menu}
      // tooltip={'Rename'}
    />
  ) : (
    <SmallBtn
      type={ActionsEnum.details}
      onClick={onAssume}
      tooltip={'Assume an Account'}
      disabled={loading}
      // tooltip={'Rename'}
    />
  )
}
