import { action, computed, makeObservable, observable } from 'mobx'
import { FilterDataType, FilterInput, Status } from 'src/generated/graphql'
import {
  CommonFilter,
  CommonFilterProps,
} from 'store/contacts/segment/filters/CommonFilter'
import { IOption } from 'src/types/IOption'
import segmentStore from 'store/contacts/segment/segmentStore'
import { statusToOptions } from 'components/StatusContent/functions/statusToOptions'

type Props = CommonFilterProps

export class SelectFilter extends CommonFilter {
  constructor(props: Props) {
    super(props)
    makeObservable(this, {
      values: observable,
      value: observable,
      setValues: action.bound,
      setValue: action.bound,
      onDeleteId: action.bound,
      filterInput: computed,
      options: computed,
      isNumber: computed,
      isNotEmpty: computed,
    })

    if (this.isMulti) {
      if (props.value) {
        this.setValues(props.value?.split(','))
      }
    } else {
      if (props.value) {
        this.setValue(props.value)
      }
    }
  }

  dataType = FilterDataType.Select

  values: string[] = []
  value = ''

  get isNotEmpty() {
    return this.isMulti ? this.values.length : this.value.trim()
  }

  get options(): IOption[] {
    const options: IOption[] = []

    segmentStore.fieldsMap.get(this.field)?.options?.forEach((opt) => {
      if (opt) {
        if (this.field === 'STATUS') {
          if (opt?.key) {
            options.push(statusToOptions(opt?.key as Status, opt.label || ''))
          }
        } else {
          options.push({ title: opt?.label, value: opt?.key })
        }
      }
    })
    return options
  }

  get filterInput(): FilterInput {
    return {
      orIndex: this.orIndex,
      andIndex: this.andIndex,
      dataType: this.dataType,
      condition: this.condition,
      field: this.field,
      value: this.isMulti ? this.values.join(',') : this.value,
      // type: FilterFieldType.Contact,
    }
  }

  get isNumber() {
    return (
      this.dataType === FilterDataType.Phone ||
      this.dataType === FilterDataType.Number
    )
  }

  get isMulti() {
    return this.field === 'STATUS'
  }

  setValues = (values: typeof this.values) => {
    this.values = values
  }
  setValue = (value: typeof this.value) => {
    this.value = value
  }
  onDeleteId = (id: string) => {
    this.values = this.values.filter((idList) => idList !== id)
  }
}
