import styles from './styles.module.scss'
import classNames from 'classnames'
import * as React from 'react'

export const Row = ({
  title,
  value,
}: {
  title: string
  value: JSX.Element | string | undefined | null
}) => (
  <div className={styles.row}>
    <span className={classNames('gray1', styles.left)}>{title}</span>
    <span className={classNames(styles.right)}>{value}</span>
  </div>
)
