// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QRA5YWWeFHsq1HrKBWY2{margin-top:2px}.ivZTFAVADtwa7Pv15UQQ{margin:24px -8px 0 -8px}`, "",{"version":3,"sources":["webpack://./src/Modals/ImportContactModal/StepsContent/Step3/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,cAAA,CAEF,sBACE,uBAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrapFile{\n  margin-top: 2px;\n}\n.wrapCheckBox{\n  margin: 24px -8px 0 -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapFile": `QRA5YWWeFHsq1HrKBWY2`,
	"wrapCheckBox": `ivZTFAVADtwa7Pv15UQQ`
};
export default ___CSS_LOADER_EXPORT___;
