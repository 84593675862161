import { makeAutoObservable } from 'mobx'
import { GetConfigurationQuery } from 'src/generated/graphql'
import shortenLinkStore from 'store/shortenLinkStore'
import cookie from 'js-cookie'
import { CookieEnum } from 'src/enums/CookieEnum'

class ConfigStore {
  constructor() {
    makeAutoObservable(this)
  }

  isInit = false
  twilioCallRecordingNumber = ''

  isShowSegmentsByApi = false

  isSegmentsByPostHog = false
  isSegmentsByApi = false
  isContactAllByPostHog = false
  isMultiOrg = false

  signUpFlow: 'B' | null | string = cookie.get(CookieEnum.SignUpFlow) || null

  get isSegments() {
    return this.isShowSegmentsByApi
      ? this.isSegmentsByApi
      : this.isSegmentsByPostHog
  }

  setData = (data: GetConfigurationQuery) => {
    this.isInit = true
    this.twilioCallRecordingNumber =
      data.getConfiguration?.twilioCallRecordingNumber || ''
    shortenLinkStore.setShortUrlDomains(data.getConfiguration?.shortUrlDomains)
    this.isSegmentsByApi = data.getConfiguration?.segmentFeatureEnabled || false
  }

  setIsSegmentsByPostHog = (value: boolean) => {
    this.isSegmentsByPostHog = value
  }
  setShowSegmentsByApi = () => {
    this.isShowSegmentsByApi = true
  }

  setIsContactAllByPostHog = (value: boolean) => {
    this.isContactAllByPostHog = value
  }

  setIsMultiOrg = (value: boolean) => {
    this.isMultiOrg = value
  }

  setSignUpFlow = (value?: string | boolean) => {
    this.signUpFlow = value === 'B' ? 'B' : 'control'
    cookie.set(CookieEnum.SignUpFlow, this.signUpFlow, { expires: 7 })
  }
}

export default new ConfigStore()
