import dayjs from 'lib/dayjs'

export type RangeOption = {
  value: [number, number]
  title: string
}
export const rangeOptions: RangeOption[] = [
  {
    value: [
      dayjs().startOf('day').add(-7, 'day').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    title: 'Last 7 days',
  },
  {
    value: [
      dayjs().startOf('day').add(-14, 'day').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    title: 'Last 14 days ',
  },
  {
    value: [
      dayjs().startOf('day').add(-30, 'day').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    title: 'Last 30 days',
  },
  {
    value: [
      dayjs().startOf('day').add(-3, 'month').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    title: 'Last 3 months',
  },
  {
    value: [
      dayjs().startOf('day').add(-6, 'month').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    title: 'Last 6 months',
  },
  {
    value: [
      dayjs().startOf('day').startOf('year').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    title: 'This years',
  },
  {
    value: [
      dayjs().startOf('day').add(-12, 'month').valueOf(),
      dayjs().endOf('day').valueOf(),
    ],
    title: 'Last 12 months',
  },
]
