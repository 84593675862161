import { observer } from 'mobx-react-lite'
import { ScheduleProps } from 'components/Schedule/Schedule'
import { BroadcastScheduleStore } from 'components/Schedule/store/broadcastScheduleStore'
import { ImmediatelyCard } from 'components/Schedule/ImmediatelyCard/ImmediatelyCard'
import { LaterCard } from 'components/Schedule/LaterCard/LaterCard'
import { CalendarDateCard } from 'components/Schedule/CalendarDateCard/CalendarDateCard'
import { ContactDateFieldCard } from 'components/Schedule/ContactDateFieldCard/ContactDateFieldCard'

export const ScheduleContent = observer(({ scheduleStore }: ScheduleProps) => {
  if (scheduleStore instanceof BroadcastScheduleStore) {
    return (
      <>
        <ImmediatelyCard scheduleStore={scheduleStore} />
        <LaterCard scheduleStore={scheduleStore} />
      </>
    )
  }
  return (
    <>
      <ImmediatelyCard scheduleStore={scheduleStore} />
      <LaterCard scheduleStore={scheduleStore} />
      <CalendarDateCard scheduleStore={scheduleStore} />
      <ContactDateFieldCard scheduleStore={scheduleStore} />
    </>
  )
})
