import { AppIcon } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { AudioPlayer } from 'components/AudioPlayer/AudioPlayer'
import styles from './styles.module.scss'
import { ReactComponent as Phone } from 'icons/16pxNoMask/Phone Ring.svg'
import { ReactComponent as Voicemail } from 'icons/16pxNoMask/Voicemail.svg'
import { IAudioItem } from 'src/types/IAudioItem'

export const MessageCardContent = ({
  title,
  audio,
  from,
  phone,
}: {
  title: string
  audio: IAudioItem | null
  from: string
  phone: string
}) => (
  <div className={styles.grid}>
    <div className={styles.audioMessageContent}>
      <div>
        <span className={'s2 medium mb4'}>{title}</span>
        <div className={'s2 gray1'}>{phone || 'Your phone'}</div>
      </div>
      <div className={styles.icon}>
        {from === 'ringlessPreview' ? (
          <AppIcon color={audio ? ColorsNames.orange1 : undefined}>
            <Voicemail />
          </AppIcon>
        ) : (
          <AppIcon color={audio ? ColorsNames.purple1 : undefined}>
            <Phone />
          </AppIcon>
        )}
      </div>
    </div>

    {audio ? (
      <div className={styles.wrapPlayer}>
        <AudioPlayer
          src={audio.url || ''}
          id={audio.id + from + 'Preview'}
          maxCanvasWidth={84}
          fromPreview
        />
      </div>
    ) : (
      <span className={'s2 medium gray2'}>Add an audio</span>
    )}
  </div>
)
