import styles from './styles.module.scss'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import classNames from 'classnames'

export const ThankYouPageContent = () => {
  return (
    <div
      className={classNames(styles.wrapThankPage, 'callLoopForm-WrapThankPage')}
    >
      <ModalIcon type={'success'} />
      <h2>
        You have successfully <br /> subscribed!{' '}
      </h2>
    </div>
  )
}
