import styles from './styles.module.scss'
import { ReactComponent as SearchIcon } from 'icons/32px/Search.svg'

import { AppElements } from 'src/enums/appElements'

export const EmptyStateSearch = ({
  element = 'element',
}: {
  element?: AppElements | string
}) => {
  return (
    <div className={styles.wrapStatistics}>
      <SearchIcon />
      {`No ${element}s found matching your search`}
    </div>
  )
}
