import React from 'react'
import { OptionRender } from 'components/Dropdown/components/OptionRender'
import { GroupingOptionsRender } from 'components/Dropdown/components/GroupingOptionsRender'
import { OptionsRenderProps } from 'components/Dropdown/types'

export const OptionsRender = <T,>({
  options,
  grouping,
  ...props
}: OptionsRenderProps<T>) => {
  if (grouping) {
    return <GroupingOptionsRender options={options} {...props} />
  }
  return (
    <>
      {options?.map((option) => (
        <OptionRender key={option.value} option={option} {...props} />
      ))}
    </>
  )
}
