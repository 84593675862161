import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useEffect } from 'react'
import { UpgradePlanModal } from 'src/Modals/UpgradePlanModal/UpgradePlanModal'

export const ReopenPage = () => {
  useEffect(() => {
    upgradePlanStore.setOpenUpgradePlanModal(true)
    return () => {
      upgradePlanStore.setOpenUpgradePlanModal(false)
    }
  }, [])

  return <UpgradePlanModal fromCancel />
}
