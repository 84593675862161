import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from 'components/Dropdown/TagsRender/styles.module.scss'
import { Tag } from 'components/Tag/Tag'
import { IOption } from 'src/types/IOption'
import { PlusItems, PlusItemsProps } from 'components/PlusItems/PlusItems'

export const WrapTagRender = ({
  option,
  onDeleteTag,
  invisibleOptions,
  length,
  index,
  setInvisibleOptions,
  setShowInput,
  plusItemsProps,
}: {
  option: IOption
  invisibleOptions: IOption[]
  setInvisibleOptions: Dispatch<SetStateAction<IOption[]>>
  onDeleteTag?: (id: string | number, group?: string) => void
  length: number
  index: number
  setShowInput?: (val: boolean) => void
  plusItemsProps?: PlusItemsProps
}) => {
  const { ref, inView, entry } = useInView({
    initialInView: true,
    threshold: 1,
  })
  useEffect(() => {
    if (!inView) {
      setInvisibleOptions((prev) => [...prev, option])
    } else {
      setInvisibleOptions((prev) =>
        prev.filter((optionLocal) =>
          optionLocal.id
            ? optionLocal.id !== option.id
            : optionLocal.value !== option.value
        )
      )
    }
  }, [inView])

  useEffect(() => {
    if (!setShowInput || !entry) {
      return
    }
    if (invisibleOptions.length) {
      setShowInput(false)
    } else {
      if (index === length - invisibleOptions.length - 1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const right = entry?.target?.offsetLeft + entry?.target.clientWidth + 8
        const parentWidth =
          entry?.target.parentElement?.parentElement?.parentElement
            ?.clientWidth || 0
        if (right && parentWidth && parentWidth - right < 200) {
          setShowInput(false)
        } else {
          setShowInput(true)
        }
      } else {
        setShowInput(true)
      }
    }
  }, [invisibleOptions, entry, length])

  return (
    <div ref={ref} className={styles.tagWrap}>
      <div
        style={{ visibility: inView ? 'visible' : 'hidden' }}
        data-name={'tag'}
        className={'appTag'}
      >
        <Tag
          text={option.title as string}
          count={option.count}
          close
          leftIcon={option.leftIcon}
          onDeleteTag={
            onDeleteTag
              ? () => {
                  onDeleteTag(
                    option.id || option.value,
                    (option.group = 'list')
                  )
                }
              : undefined
          }
          backgroundColor={option.backgroundColor}
        />
      </div>

      {inView &&
        !!invisibleOptions.length &&
        index === length - invisibleOptions.length - 1 && (
          <PlusItems
            options={invisibleOptions}
            onDeleteTag={onDeleteTag}
            {...plusItemsProps}
          />
        )}
    </div>
  )
}
