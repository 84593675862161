import { observer } from 'mobx-react-lite'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { StatusContent } from 'components/StatusContent/StatusContent'

export const Numbers = observer(() => {
  const { selectedNumbersOptions, getTitleByType } = tollFreeVerificationStore
  return (
    <div className={'col12'}>
      {selectedNumbersOptions.map((option) => (
        <div className={'row-between'} key={option.value}>
          <p className={'par2'}>{option.title}</p>
          <StatusContent
            status={option.data.verificationStatus}
            text={getTitleByType(option.data.verificationStatus)}
          />
        </div>
      ))}
    </div>
  )
})
