import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { appLinks } from 'src/util/links'
import { BtnSize, BtnType, Button } from 'components/Button/Button'

export const UnVerificationTollFreeContent = ({
  onAction,
  onClose,
}: {
  onClose: () => void
  onAction: () => void
}) => {
  return (
    <div className={'pModalInfo'}>
      <ModalIcon type={'info'} />
      <h3>You are required by the carriers to verify your toll-free numbers</h3>
      <p className={'par2 gray1'}>
        To avoid carrier filtering and ensure better deliverability, please
        complete the toll-free verification. <br />
        <a
          href={appLinks.tollFreeVerification}
          target={'_blank'}
          rel="noreferrer"
        >
          Learn more
        </a>
      </p>
      <Button
        size={BtnSize.medium}
        typeBtn={BtnType.primary}
        fullWidth
        onClick={onAction}
        className={'mb8'}
      >
        Verify
      </Button>
      <Button
        size={BtnSize.medium}
        typeBtn={BtnType.secondaryGray}
        fullWidth
        onClick={onClose}
      >
        Cancel
      </Button>
    </div>
  )
}
