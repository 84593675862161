import { observer } from 'mobx-react-lite'
import superAdminStore from 'store/superAdmin/superAdminStore'
import { Modal } from 'components/Modal/Modal'
import styles from './styles.module.scss'
import { numberString } from 'src/util/functions'
import { InputText } from 'components/Input/InputText/InputText'
import classNames from 'classnames'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { IOrganization } from 'src/types/IOrganization'
import { useEffect, useMemo, useState } from 'react'
import {
  AddOrganizationCreditsMutation,
  BalanceType,
  useAddOrganizationCreditsMutation,
} from 'src/generated/graphql'
import { FetchResult } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useOrganizationPageContext } from 'src/routes/superAdmin/routes/organization/OrganizationPage'
import { IOrganizationDetails } from 'src/types/IOrganizationDetails'

export const AddCreditsModal = observer(() => {
  const { id } = useParams()
  const fromOrgPage = !!id
  const [addOrganizationCredits, { loading }] =
    useAddOrganizationCreditsMutation()

  const onClose = () => {
    superAdminStore.setOpenAddCreditsModalOrganizationId('')
  }
  const { organization: organizationOrgPage } = useOrganizationPageContext()
  const organization: IOrganization | IOrganizationDetails | null = fromOrgPage
    ? organizationOrgPage
    : superAdminStore.addCreditOrganization || null
  const [planCredits, setPlanCredits] = useState('')
  const [rollOverCredits, setRollOverCredits] = useState('')
  const [purchasedCredits, setPurchasedCredits] = useState('')

  useEffect(() => {
    if (superAdminStore.openAddCreditsModalOrganizationId) {
      setPlanCredits('')
      setRollOverCredits('')
      setPurchasedCredits('')
    }
  }, [superAdminStore.openAddCreditsModalOrganizationId])

  const addCredits = async () => {
    try {
      const promises: Promise<
        FetchResult<
          AddOrganizationCreditsMutation,
          Record<string, any>,
          Record<string, any>
        >
      >[] = []
      if (planCredits) {
        promises.push(
          addOrganizationCredits({
            variables: {
              input: {
                amount: Number(planCredits),
                balanceType: BalanceType.Plan,
                organizationId: fromOrgPage ? id : organization?.id,
              },
            },
          })
        )
      }
      if (rollOverCredits) {
        promises.push(
          addOrganizationCredits({
            variables: {
              input: {
                amount: Number(rollOverCredits),
                balanceType: BalanceType.Rollover,
                organizationId: organization?.id,
              },
            },
          })
        )
      }
      if (purchasedCredits) {
        promises.push(
          addOrganizationCredits({
            variables: {
              input: {
                amount: Number(purchasedCredits),
                balanceType: BalanceType.Purchased,
                organizationId: organization?.id,
              },
            },
          })
        )
      }
      await Promise.all(promises)
      if (fromOrgPage) {
        superAdminStore.updateOrgPageTrigger()
      } else {
        superAdminStore.organizationTableStore.onRefresh()
      }

      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const totalCredits = useMemo(() => {
    if (!organization) {
      return 0
    }
    if ('credits' in organization) {
      return organization?.credits || 0
    }
    if ('balances' in organization) {
      const planCredits = organization.balances?.plan || 0
      const purchasedCredits = organization.balances?.purchased || 0
      const rolloverCredits = organization.balances?.rollover || 0
      return planCredits + purchasedCredits + rolloverCredits
    }
    return 0
  }, [organization])

  return (
    <Modal
      open={!!superAdminStore.openAddCreditsModalOrganizationId}
      title={'Add credits'}
      onClose={onClose}
    >
      <div className={classNames('pModal', styles.content)}>
        <div className={styles.row}>
          <span>
            <b>Total:</b>
          </span>
          <span>
            <b>{numberString({ val: totalCredits })} credits</b>
          </span>
        </div>
        <div className={styles.row}>
          {/*<span>Plan Credits ({organization?.planCredits})</span>*/}
          <span>Plan Credits</span>
          <InputText
            numericFormat
            value={planCredits}
            onChangeValue={setPlanCredits}
          />
        </div>
        <div className={styles.row}>
          {/*<span>Roll-over ({organization?.rollOverCredits})</span>*/}
          <span>Roll-over</span>
          <InputText
            numericFormat
            value={rollOverCredits}
            onChangeValue={setRollOverCredits}
          />
        </div>
        <div className={styles.row}>
          {/*<span>Purchased ({organization?.purchasedCredits})</span>*/}
          <span>Purchased</span>
          <InputText
            numericFormat
            value={purchasedCredits}
            onChangeValue={setPurchasedCredits}
          />
        </div>
        <SizedBox height={40} />
        <div className={'row12'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={addCredits}
            disabled={!planCredits && !rollOverCredits && !purchasedCredits}
            loading={loading}
          >
            Add credits
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
