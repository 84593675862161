import { DetailPage } from 'components/Page/DetailPage'
import { HeaderContent } from 'src/routes/main/triggers/detail/header/HeaderContent'
import { TriggersDetailTable } from 'src/routes/main/triggers/detail/TriggersDetailTable/TriggersDetailTable'
import { createContext, useContext, useEffect, useState } from 'react'
import { TriggersDetailStore } from 'store/triggers/triggersDetailStore'
import { useGetTriggerQuery } from 'src/generated/graphql'
import { useParams } from 'react-router-dom'
import { transformTrigger } from 'store/triggers/functions'
import { observer } from 'mobx-react-lite'
import triggersStore from 'store/triggers/triggersStore'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'

const TriggersDetailContext = createContext<TriggersDetailStore>(
  new TriggersDetailStore()
)

export function useTriggersDetailContext() {
  return useContext(TriggersDetailContext)
}

export const TriggerDetail = observer(() => {
  const [store] = useState(new TriggersDetailStore())
  const { id } = useParams()
  const { data, loading } = useGetTriggerQuery({
    variables: {
      id,
    },
  })
  if (data?.getTriggerById) {
    const newTrigger = transformTrigger(data.getTriggerById)
    triggersStore.setTrigger(newTrigger)
  }
  useEffect(() => {
    if (data?.getTriggerById) {
      const newTrigger = transformTrigger(data.getTriggerById)
      store.setTrigger(newTrigger)
    }
  }, [data])

  return (
    <TriggersDetailContext.Provider value={store}>
      {loading && <AbsoluteLoader size={40} />}
      {!loading && store.trigger && (
        <DetailPage header={<HeaderContent />}>
          <TriggersDetailTable />
        </DetailPage>
      )}
    </TriggersDetailContext.Provider>
  )
})
