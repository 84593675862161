export enum CardType {
  visa = 'visa',
  mastercard = 'mastercard',
  unknown = 'card',
  amex = 'amex',
  discover = 'discover',
  diners = 'diners',
  jcb = 'jcb',
  unionpay = 'unionpay',
}
