import { InputText } from 'components/Input/InputText/InputText'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Dispatch, SetStateAction, useState } from 'react'
import user from 'store/user/user'
import {
  useChangePasswordMutation,
  useConfirmRestorePasswordMutation,
} from 'src/generated/graphql'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export const NewPasswordContent = ({
  onCancel,
  fromModal,
  currentPassword,
  setCurrentPasswordError,
  verificationCode,
  fromAdmin,
  onResetFromAdmin,
  loadingFromAdmin,
}: {
  onCancel: () => void
  fromModal?: boolean
  fromAdmin?: boolean
  verificationCode?: number
  currentPassword?: string
  onResetFromAdmin?: (password: string) => Promise<void>
  loadingFromAdmin?: boolean
  setCurrentPasswordError?: Dispatch<SetStateAction<string>>
}) => {
  const [changePassword, { loading }] = useChangePasswordMutation()
  const [confirmRestorePassword, { loading: loadingRestore }] =
    useConfirmRestorePasswordMutation()
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [typeNewPassword, setTypeNewPassword] = useState('password')
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')
  const [isValidPassword, setValidPassword] = useState(false)
  const [notMatchError, setNotMatchError] = useState('')

  const isDisabled = !(
    newPassword &&
    isValidPassword &&
    newPassword === confirmPassword
  )

  const handleCancel = () => {
    onCancel()
  }

  const onSave = async () => {
    if (fromAdmin) {
      try {
        if (onResetFromAdmin) {
          await onResetFromAdmin(newPassword)
          alertStore.setAlert({
            title: `Password changed`,
            type: AlertTypeEnum.success,
          })
          onCancel()
        }
      } catch (e) {
        if (
          e instanceof Error &&
          e?.message?.includes('Current password') &&
          setCurrentPasswordError
        ) {
          setCurrentPasswordError(
            "Password provided doesn't match existing password"
          )
          alertStore.disabledErrorAlert(e.message)
        }
      }
    } else {
      if (!fromModal) {
        try {
          await changePassword({
            variables: {
              input: {
                currentPassword,
                newPassword,
              },
            },
          })
          alertStore.setAlert({
            title: `Password changed`,
            type: AlertTypeEnum.success,
          })
          onCancel()
        } catch (e) {
          if (
            e instanceof Error &&
            e?.message?.includes('Current password') &&
            setCurrentPasswordError
          ) {
            setCurrentPasswordError(
              "Password provided doesn't match existing password"
            )
            alertStore.disabledErrorAlert(e.message)
          }
        }
      } else {
        try {
          await confirmRestorePassword({
            variables: {
              input: {
                newPassword,
                verificationCode,
              },
            },
          })
          onCancel()
          alertStore.setAlert({
            title: `Password changed`,
            type: AlertTypeEnum.success,
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
  const onBlur = () => {
    if (isValidPassword && newPassword && newPassword !== confirmPassword) {
      setNotMatchError('Passwords entered do not match')
    }
  }
  const onFocus = () => {
    setNotMatchError('')
  }
  return (
    <>
      <InputText
        setValidPassword={setValidPassword}
        valid
        password
        readOnly
        removeReadOnly
        aria-autocomplete={'none'}
        id={'password'}
        label={'New password'}
        placeholder={'Enter your password'}
        withError={!!notMatchError}
        onBlur={onBlur}
        onFocus={onFocus}
        value={newPassword}
        type={typeNewPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        onClickRightIcon={() =>
          setTypeNewPassword(
            typeNewPassword === 'password' ? 'text' : 'password'
          )
        }
      />
      {((newPassword && isValidPassword) ||
        fromModal ||
        fromAdmin ||
        !user.isEmailAuth) && (
        <>
          <SizedBox height={24} />
          <InputText
            password
            id={'password'}
            autoComplete={'new-password'}
            label={'Confirm password'}
            placeholder={'Enter your password'}
            value={confirmPassword}
            type={typeConfirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onFocus={onFocus}
            error={notMatchError}
            onBlur={onBlur}
            onClickRightIcon={() =>
              setTypeConfirmPassword(
                typeConfirmPassword === 'password' ? 'text' : 'password'
              )
            }
          />
        </>
      )}
      <SizedBox height={40} />
      <div className={'row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          disabled={isDisabled}
          onClick={onSave}
          loading={loading || loadingRestore || loadingFromAdmin}
        >
          {fromModal ? 'Update' : 'Save'} password
        </Button>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.secondaryGray}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </>
  )
}
