// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eNZHa8SdN1sg8kkeIZlw{display:flex;align-items:center;padding:4px 8px}.QkLKczzxCVPMnP1lgKwk{display:flex;align-items:center;color:var(--primary-color-gray-1);padding-right:12px;border-right:1px solid var(--primary-color-gray-3);height:24px;margin-right:12px}.QkLKczzxCVPMnP1lgKwk svg{margin-right:8px}.w332Rzsu5zw4DvXpc7WE{width:167px}.ayUNRoTF2Ikw7pDtfGCr:disabled{pointer-events:all}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/Personolize/styles.module.scss"],"names":[],"mappings":"AACA,sBAEE,YAAA,CACA,kBAAA,CACA,eAAA,CAIF,sBACE,YAAA,CACA,kBAAA,CACA,iCAAA,CACA,kBAAA,CACA,kDAAA,CACA,WAAA,CACA,iBAAA,CACA,0BACE,gBAAA,CAIJ,sBACE,WAAA,CAIA,+BACE,kBAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrapTT{\n  //height: 40px;\n  display: flex;\n  align-items: center;\n  padding: 4px 8px;\n\n}\n\n.fallback{\n  display: flex;\n  align-items: center;\n  color: var(--primary-color-gray-1);\n  padding-right: 12px;\n  border-right: 1px solid var(--primary-color-gray-3);\n  height: 24px;\n  margin-right: 12px;\n  svg{\n    margin-right: 8px;\n  }\n}\n\n.subText{\n  width: 167px;\n}\n\n.btn{\n  &:disabled{\n    pointer-events: all;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTT": `eNZHa8SdN1sg8kkeIZlw`,
	"fallback": `QkLKczzxCVPMnP1lgKwk`,
	"subText": `w332Rzsu5zw4DvXpc7WE`,
	"btn": `ayUNRoTF2Ikw7pDtfGCr`
};
export default ___CSS_LOADER_EXPORT___;
