import React from 'react'
import { VoiceContent } from 'src/components/CreateElement/VoiceContent/VoiceContent'
import { observer } from 'mobx-react-lite'
import { SendFromComponentVR } from 'components/CreateElement/SendFromComponentVR'
import { CreateStoreTypes } from 'components/CreateElement/store/types'

export const MainVoiceContent = observer(
  ({ store }: { store: CreateStoreTypes }) => {
    return (
      <>
        <VoiceContent voiceStore={store.voiceStore} />
        <SendFromComponentVR store={store} />
      </>
    )
  }
)
