import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { DatePicker } from 'components/DatePicker/DatePicker'
import { TimePicker } from 'components/TimePicker/TimePicker'
import { SizedBox } from 'components/SizedBox'
// import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
// import { WeeklyDaysSelector } from 'components/Schedule/WeeklyDaysSelector/WeeklyDaysSelector'
// import { RepeatContent } from 'components/Schedule/LaterContent/RepeatContent/RepeatContent'
import dayjs from 'lib/dayjs'
import { BroadcastScheduleStore } from 'components/Schedule/store/broadcastScheduleStore'

export const LaterContentBroadcast: FC<{
  scheduleStore: BroadcastScheduleStore
}> = observer(({ scheduleStore }) => {
  useEffect(() => {
    if (!scheduleStore.sendLaterSendDate) {
      scheduleStore.setLaterDate(dayjs().add(5, 'minute').toDate())
    }
  }, [scheduleStore.activeSendLater])
  if (!scheduleStore.activeSendLater) {
    return <></>
  }
  return (
    <>
      <div className={styles.wrap}>
        <DatePicker
          startDate={scheduleStore.sendLaterSendDate}
          onChange={(date) => scheduleStore.setLaterDate(date)}
        />
        <SizedBox height={8} />
        <TimePicker
          startTime={scheduleStore.sendLaterSendDate}
          onChange={(time) => scheduleStore.setLaterDate(time)}
        />
      </div>
      {/*<RepeatContent scheduleStore={scheduleStore} />*/}
    </>
  )
})
