import { observer } from 'mobx-react-lite'
import { Row } from 'src/routes/settings/compliance/modals/components/Row/Row'
import tollFreeVerificationStore from 'store/settings/company/tollFreeVerificationStore'
import { ImageCard } from 'components/ImageCard/ImageCard'
import presentationStore from 'store/presentationStore'
import typesStore from 'store/typesStore'

export const OptIn = observer(() => {
  const { optInMethod, optInAsset } = tollFreeVerificationStore

  if (!optInMethod) {
    return null
  }
  return (
    <div className={'col16'}>
      <p className={'par2'}>
        <b>Opt-in</b>
      </p>
      <div className={'col24'}>
        <Row
          center
          title={
            typesStore.tfOptInMethodMapping.get(optInMethod)?.title ||
            optInMethod
          }
          value={
            <div className={'col12'}>
              <div className={'row8'}>
                {optInAsset && (
                  <ImageCard
                    img={optInAsset}
                    onImageClick={() => {
                      optInAsset &&
                        presentationStore.setPresentationImages([optInAsset])
                    }}
                  />
                )}
              </div>
            </div>
          }
        />
      </div>
    </div>
  )
})
