// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nlUyfxtTcbCdjFUJHb1i th{position:sticky;top:107px;height:48px;background-color:#fff;border-bottom:none;z-index:2}.d5LUu0fsehSOlDaDGmHv{padding-left:40px;display:flex;gap:7px}`, "",{"version":3,"sources":["webpack://./src/components/NewTable/components/AllSelected/syles.module.scss"],"names":[],"mappings":"AACE,yBACE,eAAA,CACA,SAAA,CACA,WAAA,CACA,qBAAA,CACA,kBAAA,CACA,SAAA,CAGJ,sBACE,iBAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".row {\n  th {\n    position: sticky;\n    top: 107px;\n    height: 48px;\n    background-color: white;\n    border-bottom:none;\n    z-index: 2;\n  }\n}\n.content{\n  padding-left: 40px;\n  display: flex;\n  gap: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `nlUyfxtTcbCdjFUJHb1i`,
	"content": `d5LUu0fsehSOlDaDGmHv`
};
export default ___CSS_LOADER_EXPORT___;
