export enum FiledTypeEnum {
  text = 'text',
  number = 'number',
  date = 'date',
  dateTime = 'dateTime',
  url = 'url',
  phone = 'PHONE',
  lists = 'lists',
  status = 'status',
  optIn = 'optIn',
}
