import { CurrentPlan } from 'src/routes/settings/billing/components/CurrentPlan/CurrentPlan'
import { CreditsContent } from 'src/routes/settings/billing/components/CreditsContent/CreditsContent'
import { PaymentMethods } from 'src/routes/settings/billing/components/PaymentMethods/PaymentMethods'
import { CancelSubscription } from 'src/routes/settings/billing/components/CancelSubscription/CancelSubscription'
import styles from './styles.module.scss'
import { Invoices } from 'src/routes/settings/billing/Invoices/Invoices'
import { useEffect } from 'react'
import alertStore from 'store/alertStore'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { useSetBillingInfo } from 'src/routes/settings/billing/hooks/useSetBillingInfo'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import classNames from 'classnames'
import { PhoneCheckrCard } from 'src/routes/settings/billing/components/PhoneCheckr/PhoneCheсkrCard'

export const Billing = observer(() => {
  useSetBillingInfo()
  useEffect(() => {
    if (billingStore.isPausedSubscription) {
      alertStore.setAlert(
        {
          type: AlertTypeEnum.blue3,
          title:
            'Your subscription will be paused at the end of the billing cycle: Jan 1, 2022. Pause period is up to 3 months.',
          rightContent: <span className={'white bold'}>Cancel pause</span>,
          onClose: () => billingStore.setIsPausedSubscription(false),
        },
        true
      )
    }
  }, [billingStore.isPausedSubscription])
  return (
    <div className={classNames('small-container', styles.wrap)}>
      <h1>Billing & Usage</h1>
      <CurrentPlan />
      {!billingStore.isTrial && <CreditsContent />}
      <PhoneCheckrCard />
      <PaymentMethods />
      <Invoices />
      <CancelSubscription />
    </div>
  )
})
