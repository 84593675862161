import { BtnSize, Button } from 'components/Button/Button'
import styles from 'components/Schedule/styles.module.scss'
import { Switch } from 'components/Switch/Switch'
import { FC } from 'react'

type Props = {
  active: boolean
  title: string
  onClick: () => void
}

export const ScheduleBtn: FC<Props> = ({ active, title, onClick }) => {
  return (
    <Button
      size={BtnSize.small}
      fullWidth
      hoverGray
      className={styles.btn}
      onClick={onClick}
    >
      <span>{title}</span>
      <Switch active={active} />
    </Button>
  )
}
