import { numberString } from 'src/util/functions'
import { ColumnType } from 'components/NewTable/types'
import { ISimplePrice } from 'src/types/ISimplePrice'

export const superAdminCustomPriceColumns: ColumnType<ISimplePrice>[] = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'planName',
    label: 'Name',
  },
  // {
  //   id: 'planTitle',
  //   label: 'Title',
  // },
  {
    id: 'planDescription',
    label: 'Description',
  },
  {
    id: 'period',
    label: 'Period',
  },
  {
    id: 'creditsIncluded',
    label: 'Credits',
    format: (value) => numberString({ val: +value }),
  },
  {
    id: 'priceValue',
    label: 'Price, $',
    format: (value) => numberString({ val: +value, minimumFractionDigits: 2 }),
  },
  {
    id: 'additionalCreditPrice',
    label: 'Extra credit price, $',
    format: (value) => numberString({ val: +value }),
  },
]
