// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.at8v5Q25DQoBB5xWQyNY{margin:0 32px 32px 32px;height:384px}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/AddMediaContent/UploadContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,uBAAA,CACA,YAAA","sourcesContent":[".wrap{\n  margin: 0 32px 32px 32px;\n  height: 384px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `at8v5Q25DQoBB5xWQyNY`
};
export default ___CSS_LOADER_EXPORT___;
