import { useEffect } from 'react'
import triggersStore from 'store/triggers/triggersStore'
import { useGetTriggersQuery } from 'src/generated/graphql'
import billingStore from 'store/settings/billing/billingStore'

export function useGetTriggers() {
  const { data } = useGetTriggersQuery({
    variables: {
      page: {
        pageNumber: 0,
        pageSize: 100,
      },
    },
    skip: !!triggersStore.triggers.length || !billingStore.isTrial,
  })

  useEffect(() => {
    triggersStore.setData(data)
  }, [data])
}
