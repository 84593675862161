import { useGetPaymentsMethodsQuery } from 'src/generated/graphql'
import { useEffect } from 'react'
import billingStore from 'store/settings/billing/billingStore'
import { useRefetch } from 'src/hooks/useRefetch'

export function useSetPaymentsMethods(skip?: boolean) {
  const { data, refetch, loading } = useGetPaymentsMethodsQuery({ skip })

  billingStore.setPaymentsMethodLoading(loading)
  useEffect(() => {
    if (data?.getOrganizationBillingInfo?.paymentMethods) {
      billingStore.setOrganizationBillingInfo(data?.getOrganizationBillingInfo)
      billingStore.setShowNewCard(
        !data?.getOrganizationBillingInfo?.paymentMethods.length
      )
    }
  }, [data])

  useRefetch(refetch, skip)
}
