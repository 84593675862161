import { Filters } from './Filters/Filters'
import NewTable from 'components/NewTable/NewTable'
import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { IContactImportState } from 'src/routes/main/contacts/importHistory/ImportHistoryTable/types'
import { useTablePaginationOptions } from 'components/NewTable/hooks/useTablePaginationOptions'
import { useGetImportHistoryQuery } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import importContactStore from 'store/contacts/importContactStore'
import { EmptyStateContacts } from 'src/routes/main/contacts/ContactsTable/EmptyStateContacts'

export const ImportHistoryTable = observer(() => {
  const [fistLoading, setFirstLoading] = useState(true)
  const [refetchLoading, setRefetchLoading] = useState(false)
  const options = useTablePaginationOptions(importContactStore.tableStore)
  const { data, refetch, loading } = useGetImportHistoryQuery(options)

  useEffect(() => {
    if (data?.getImportHistory) {
      setFirstLoading(false)
      importContactStore.setIContactImportStateResponse(data)
    }
  }, [data])

  useEffect(() => {
    setRefetchLoading(true)
    refetch().finally(() => setRefetchLoading(false))
  }, [importContactStore.tableStore.refreshTrigger])

  const rows = importContactStore.iContactImportState

  return (
    <div className={styles.wrap}>
      {!fistLoading && !rows.length ? (
        <EmptyStateContacts onlyImport />
      ) : (
        <NewTable<IContactImportState>
          withCheckbox
          columns={importContactStore.tableStore.visibleColumns}
          rows={rows}
          leftStickyContent={<Filters />}
          // withSearch
          withCalcMaxHeight
          tableStore={importContactStore.tableStore}
          firstLoading={fistLoading}
          pageLoading={loading || refetchLoading}
        />
      )}
    </div>
  )
})
