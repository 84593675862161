import styles from './styles.module.scss'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Media } from 'icons/16px/Media.svg'
import { ReactComponent as Download } from 'icons/16px/Download.svg'
import { ReactComponent as Microphone } from 'icons/16px/Microphone.svg'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip } from 'components/Tooltip/Tooltip'
import classNames from 'classnames'
import {
  createElementFromHTML,
  getCaretIndex,
  placeCaretAtEnd,
  placeCaretAtStart,
  textToHtml,
} from 'src/util/functions'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { EmojiPicker } from 'components/Textarea/EmojiPicker/EmojiPicker'
import { TextareaStore } from 'store/textareaStore'
import { observer } from 'mobx-react-lite'
import { TextareaMediaModal } from 'components/Textarea/TextareaMediaModal'
import { Personalize } from 'components/Textarea/Personolize/Personalize'
import { PersonalizeTT } from 'components/Textarea/Personolize/PersonalizeTT'
import {
  EnumFutureContent,
  MoreFeatures,
} from 'components/Textarea/MoreFeatures/MoreFeatures'
import { AttachmentGallery } from 'components/AttachmentGallery/AttachmentGallery'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { useLocation } from 'react-router-dom'
import { RoutesEnum } from 'src/routes/routes'
import mainStore from 'store/mainStore'
import { ContactCardFragment } from 'src/generated/graphql'
import { useSetSMSData } from 'components/CreateElement/hooks/useSetSMSData'
import { ShortenLink } from 'components/Textarea/ShortenLink/ShortenLink'
import shortenLinkStore from 'store/shortenLinkStore'
import { ShortenLinkTooltip } from 'components/Textarea/ShortenLinkTooltip/ShortenLinkTooltip'
import { MessageTemplateAction } from 'src/widgets/MessageTemplate'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'

type Props = {
  textareaStore: TextareaStore
  fromWebForm?: boolean
  compose?: boolean
  smallGallery?: boolean
  fromTemplate?: boolean
  fromKeyword?: boolean
  fromDataCollectionItem?: boolean
  fromSpeech?: boolean
  filterFutureOption?: EnumFutureContent
  className?: string
  tabIndex?: number
  focusOnMount?: boolean
}

export const Textarea: FC<Props> = observer(
  ({
    textareaStore,
    compose,
    filterFutureOption,
    smallGallery,
    fromTemplate,
    fromKeyword,
    fromDataCollectionItem,
    className,
    fromSpeech,
    tabIndex,
    fromWebForm,
    focusOnMount,
  }) => {
    const onLoadSMSData = () => {
      textareaStore.setTriggerMessageUpdate()
    }
    useSetSMSData(onLoadSMSData)
    const [currentHoverLink, setCurrentHoverLink] = useState<
      HTMLAnchorElement | undefined
    >(undefined)
    const [linkTimeoutId, setLinkTimeoutId] = useState<NodeJS.Timeout | null>(
      null
    )
    const [triggerFocus, setTriggerFocus] = useState(false)
    const refHoverTooltipLink = useRef(false)
    const refHoverLink = useRef(false)
    const { pathname } = useLocation()
    const disablePersonalize = useMemo(
      () => pathname === RoutesEnum.newTrigger && !mainStore.isSelectTriggerApp,
      [mainStore.isSelectTriggerApp, pathname]
    )
    const [currentPersonalize, setCurrentPersonalize] = useState<
      HTMLSpanElement | undefined
    >(undefined)

    const offsetRef = useRef(0)
    const focusNodeRef = useRef<Node | null>(null)

    const ref = useRef<HTMLDivElement>(null)

    const addContent = (content: string) => {
      const node = createElementFromHTML(content, true)
      if (!node) {
        return
      }
      let focusNodeLocal = focusNodeRef.current
      let offsetLocal = offsetRef.current

      if (ref.current) {
        if (!focusNodeLocal) {
          ref.current.focus()
          focusNodeLocal = getCaretIndex(ref.current).focusNode || null
          offsetLocal = getCaretIndex(ref.current).offset || 0
        }
        if (focusNodeLocal) {
          if (focusNodeLocal.textContent) {
            if (focusNodeLocal.nodeName === '#text') {
              const firstPart = focusNodeLocal.textContent.slice(0, offsetLocal)
              const firstNode = document.createElement('span')
              const firstNodeContent = createElementFromHTML(firstPart, true)
              if (firstNodeContent) {
                firstNode.appendChild(firstNodeContent)
              }

              const secondPart = focusNodeLocal.textContent.slice(offsetLocal)

              const secondNode = document.createElement('span')
              const secondNodeContent = createElementFromHTML(secondPart, true)
              if (secondNodeContent) {
                secondNode.appendChild(secondNodeContent)
              }

              const newNode = document.createElement('span')
              if (firstPart) {
                newNode.appendChild(firstNode)
              }
              newNode.appendChild(node)
              if (secondPart) {
                newNode.appendChild(secondNode)
              }
              if (newNode) {
                const parentNode = focusNodeLocal.parentNode
                if (parentNode) {
                  parentNode.replaceChild(newNode, focusNodeLocal)
                }
              }
              if (ref.current) {
                textareaStore.setMessageText(
                  ref.current.innerHTML,
                  ref.current.innerText
                )
                try {
                  placeCaretAtStart(secondNode)
                } catch (e) {
                  console.error(e)
                }
              }
            } else {
              if (focusNodeLocal.firstChild?.nodeName === 'BR') {
                focusNodeLocal.removeChild(focusNodeLocal.firstChild)
              }
              focusNodeLocal.appendChild(node)
              textareaStore.setMessageText(
                ref.current.innerHTML,
                ref.current.innerText
              )
              placeCaretAtEnd(node)
            }
          } else {
            if (focusNodeLocal.firstChild?.nodeName === 'BR') {
              focusNodeLocal.removeChild(focusNodeLocal.firstChild)
            }
            focusNodeLocal.appendChild(node)
            textareaStore.setMessageText(
              ref.current.innerHTML,
              ref.current.innerText
            )
            if (focusNodeLocal) {
              placeCaretAtEnd(focusNodeLocal)
            } else {
              placeCaretAtEnd(ref.current)
            }
          }

          const testFocusNodeLocal =
            getCaretIndex(ref.current).focusNode || null
          if (!testFocusNodeLocal) {
            placeCaretAtEnd(ref.current)
          }
        }
      }
      textareaStore.setTriggerMessageUpdate()
    }

    const onAddEmoji = (emoji: string) => {
      addContent(emoji)
    }
    const onInput = () => {
      if (ref.current) {
        textareaStore.setMessageText(
          ref.current.innerHTML,
          ref.current.innerText
        )
      }
    }

    const onHoverLink = (link: HTMLAnchorElement) => {
      if (link.getAttributeNode('data-shorten')?.value !== 'true') {
        if (linkTimeoutId) {
          clearTimeout(linkTimeoutId)
        }
        refHoverLink.current = true
        setCurrentHoverLink(link)
      }
    }
    const onLeaveLink = () => {
      refHoverLink.current = false
      const id = setTimeout(() => {
        if (refHoverLink.current || refHoverTooltipLink.current) {
          return
        }
        setCurrentHoverLink(undefined)
      }, 1000)
      setLinkTimeoutId(id)
    }
    const onHoverPersonalize = (span: HTMLSpanElement) => {
      setCurrentPersonalize(span)
    }

    function hoverLinkEvent(link: HTMLAnchorElement) {
      onHoverLink(link)
    }
    const hoverPersonalizeEvent = (span: HTMLSpanElement) => {
      onHoverPersonalize(span)
    }

    const setTooltips = () => {
      if (ref.current) {
        const links = ref.current.querySelectorAll('a')
        links.forEach((link) => {
          if (!link.getAttribute('data-events')) {
            link.addEventListener('mouseenter', hoverLinkEvent.bind(this, link))
            link.addEventListener('mouseleave', onLeaveLink)
            link.setAttribute('data-events', 'true')
          }
        })
        const personalizes = ref.current.querySelectorAll('.personalize')
        personalizes.forEach((span) => {
          if (!span.getAttribute('data-events')) {
            span.addEventListener(
              'mouseenter',
              hoverPersonalizeEvent.bind(null, span as HTMLSpanElement)
            )
            span.setAttribute('data-events', 'true')
          }
        })
      }
    }

    useEffect(() => {
      if (ref.current) {
        setTooltips()
      }
    }, [textareaStore.triggerMessageUpdate])

    const onClickShortenLink = async () => {
      if (currentHoverLink && ref.current) {
        const originalUrl = currentHoverLink.getAttribute('href')
        if (originalUrl) {
          const shortUrl = await shortenLinkStore.getShortenLink(originalUrl)
          if (shortUrl) {
            currentHoverLink?.setAttribute('data-shorten', 'true')
            currentHoverLink?.setAttribute('href', shortUrl)
            currentHoverLink?.setAttribute('target', '_blank')
            currentHoverLink.innerHTML = shortUrl
            textareaStore.setMessageText(
              ref.current.innerHTML,
              ref.current.innerText
            )
          }
        }
      }

      setCurrentHoverLink(undefined)
    }

    const onAddShortenLink = async (longUrl: string, withError?: boolean) => {
      if (ref.current) {
        const shortUrl = await shortenLinkStore.getShortenLink(
          longUrl,
          withError
        )
        if (shortUrl) {
          const newLink = `<a href=${shortUrl} data-shorten="true" target="_blank">${shortUrl}</a>`
          addContent(newLink)
        }
      }
    }

    const onPaste: React.ClipboardEventHandler<HTMLDivElement> = (e) => {
      onFocus()

      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      addContent(textToHtml(text, false, true))
      if (ref.current) {
        setTooltips()
      }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.ctrlKey && e.code === 'KeyC') {
        window.navigator.clipboard.writeText(textareaStore.text)
      }
    }

    const onAddPersonalize = (name: string, key: string) => {
      if (ref.current) {
        const newElement = `<span class=\'personalize\' contenteditable='false' data-key='${key}' data-merge='${key.startsWith(
          'merge.'
        )}'>${name}</span>`
        addContent(newElement)
        const personalizes = ref.current.querySelectorAll('.personalize')
        personalizes.forEach((span) => {
          span.addEventListener(
            'mouseenter',
            hoverPersonalizeEvent.bind(null, span as HTMLSpanElement)
          )
        })

        const lastElement = personalizes[personalizes.length - 1]
        if (lastElement) {
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setCurrentPersonalize(lastElement)
            setTriggerFocus(true)
          }, 50)
        }
      }
    }

    const onEditPersonalize = (
      span: HTMLSpanElement | undefined,
      value: string
    ) => {
      if (span && ref.current) {
        if (!value) {
          span?.removeAttribute('data-fallback')
        } else {
          span?.setAttribute('data-fallback', value)
        }
        textareaStore.setMessageText(
          ref.current.innerHTML,
          ref.current.innerText
        )
      }
    }

    const onAddVCard = (vCard: ContactCardFragment) => {
      textareaStore.addVCard(vCard)
    }
    const onDeleteVCard = (id: number) => {
      textareaStore.removeVCard(id)
    }

    useEffect(() => {
      if (!currentPersonalize) {
        setTriggerFocus(false)
      }
      currentPersonalize?.classList.toggle('active')
      return () => {
        currentPersonalize?.classList.toggle('active')
      }
    }, [currentPersonalize])

    useEffect(() => {
      if (focusOnMount && ref) {
        ref.current?.focus()
      }
    }, [ref])

    useEffect(() => {
      if (textareaStore.triggerMessageUpdateText && ref.current) {
        ref.current.innerHTML = textareaStore.messageHtml
        setTooltips()
      }
    }, [textareaStore.triggerMessageUpdateText])

    const onFocus = () => {
      if (ref.current) {
        const { offset, focusNode } = getCaretIndex(ref.current)
        offsetRef.current = offset
        if (focusNode) {
          focusNodeRef.current = focusNode
        }
      }
    }

    return (
      <div
        className={classNames(
          styles.wrap,
          compose && styles.compose,
          className
        )}
      >
        <TextareaMediaModal textareaStore={textareaStore} />
        <ShortenLinkTooltip
          onClickShortenLink={onClickShortenLink}
          currentHoverLink={currentHoverLink}
          refHoverLink={refHoverTooltipLink}
          setCurrentHoverLink={setCurrentHoverLink}
        />
        {!!currentPersonalize && (
          <PersonalizeTT
            visible={!!currentPersonalize}
            trigger={currentPersonalize}
            onClose={() => {
              setCurrentPersonalize(undefined)
            }}
            onEditPersonalize={onEditPersonalize}
            triggerFocus={triggerFocus}
          />
        )}
        <div
          tabIndex={tabIndex}
          ref={ref}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          onInput={onInput}
          contentEditable={true}
          className={classNames(
            styles.textarea,
            textareaStore.messageHtml && styles.withContent,
            fromDataCollectionItem && styles.fromDataCollectionItem,
            fromWebForm && styles.fromWebForm,
            fromKeyword && styles.fromKeyword
          )}
          aria-hidden={true}
          onBlur={onFocus}
          onClick={onFocus}
        />
        {(textareaStore.vCards.length ||
          !!textareaStore.attachImages?.length) && (
          <div className={styles.wrapGallery}>
            <AttachmentGallery
              attachImages={textareaStore.attachImages}
              removeAttachImages={(ids) =>
                textareaStore.removeAttachImages(ids)
              }
              onDeleteVCard={onDeleteVCard}
              small={smallGallery}
              fromTemplate={fromTemplate}
              fromDataCollectionItem={fromDataCollectionItem}
              vCards={textareaStore.vCards}
            />
          </div>
        )}

        <div className={styles.elements}>
          <div className={styles.iconsWrap}>
            {fromSpeech ? (
              <>
                <Button size={BtnSize.small} hoverGray>
                  <Microphone />
                </Button>
                <Personalize
                  onAddPersonalize={onAddPersonalize}
                  disabled={disablePersonalize}
                />
                <TestItBtn
                  fromTable
                  fromAudio
                  testTooltipPosition={
                    fromSpeech ? TTPositionEnum.bottomLeft : undefined
                  }
                />
                <Button size={BtnSize.small} hoverGray>
                  <Download />
                </Button>
              </>
            ) : (
              <>
                <MuiTooltip
                  title={[
                    'Media',
                    <br />,
                    <span className={'gray2'}>png, jpg, gif - up to 5MB</span>,
                  ]}
                  placement={'top'}
                  arrow
                >
                  <Button
                    size={BtnSize.small}
                    hoverGray
                    onClick={() => textareaStore.setActiveMedia(true)}
                  >
                    <Media />
                  </Button>
                </MuiTooltip>
                <ShortenLink onAddShortenLink={onAddShortenLink} />
                <EmojiPicker onAddEmoji={onAddEmoji} />
                {!fromTemplate && !fromDataCollectionItem && (
                  <MessageTemplateAction />
                )}
                {(fromTemplate || fromDataCollectionItem) && (
                  <Personalize
                    onAddPersonalize={onAddPersonalize}
                    disabled={disablePersonalize}
                  />
                )}
                {/*{fromDataCollectionItem && (*/}
                {/*  <VCCardIcon onAddVCard={onAddVCard} />*/}
                {/*)}*/}
                {!fromDataCollectionItem && (
                  <MoreFeatures
                    onAddPersonalize={onAddPersonalize}
                    filterFutureOption={filterFutureOption}
                    onAddVCard={onAddVCard}
                    textareaStore={textareaStore}
                    fromTemplate={fromTemplate}
                    disablePersonalize={disablePersonalize}
                  />
                )}
              </>
            )}
          </div>
          {!fromSpeech && (
            <div
              className={classNames(
                styles.counter,
                (textareaStore.errorMMSLength ||
                  textareaStore.errorSMSLength) &&
                  styles.error
              )}
            >
              <span className={styles.active}>
                {textareaStore.messageCount}
              </span>
              <span>/</span>
              <span>{textareaStore.messageRemains}</span>
            </div>
          )}
        </div>
      </div>
    )
  }
)
