import { DetailPage } from 'components/Page/DetailPage'
import { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { WebFormDetailStore } from 'store/webForms/webFormDetailStore'
import { HeaderContent } from 'src/routes/main/webForms/detail/header/HeaderContent'
import { WebFromDetailTable } from 'src/routes/main/webForms/detail/WebFromDetailTable/WebFromDetailTable'
import { useGetWebFormQuery } from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { transformWebForm } from 'store/webForms/functions'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const WebFormDetailContext = createContext<WebFormDetailStore>(null)

export function useWebFormDetailContext() {
  return useContext(WebFormDetailContext)
}

export const WebFormDetailPage = observer(() => {
  const [store] = useState(new WebFormDetailStore())
  const { id } = useParams()
  const { data, loading } = useGetWebFormQuery({
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (data?.getWebForm) {
      store.setWebForm(transformWebForm(data?.getWebForm))
    }
  }, [data])

  return (
    <WebFormDetailContext.Provider value={store}>
      {loading && <AbsoluteLoader size={40} />}
      {!loading && store.webForm && (
        <DetailPage header={<HeaderContent />}>
          <WebFromDetailTable />
        </DetailPage>
      )}
    </WebFormDetailContext.Provider>
  )
})
