import { AudioTable } from 'src/routes/settings/audio/AudioTable/AudioTable'
import { SizedBox } from 'components/SizedBox'

export const Audio = () => {
  return (
    <>
      <h1>Audio</h1>
      <SizedBox height={28} />
      <AudioTable />
    </>
  )
}
