import { StatusContent } from 'components/StatusContent/StatusContent'
import { Status } from 'src/generated/graphql'
import { numberString } from 'src/util/functions'
import { IKeyword } from 'src/types/IKeyword'
import { ColumnType } from 'components/NewTable/types'
import dayjs, { DayjsFormats } from 'lib/dayjs'

export const columnsKeywords: ColumnType<IKeyword>[] = [
  { id: 'name', label: 'Name', minWidth: 143 },
  {
    id: 'keywordPhoneNumbers',
    label: 'Phone',
    minWidth: 143,
    format: (value, { keywordPhoneNumbers }) =>
      !!keywordPhoneNumbers?.length ? (
        <>{keywordPhoneNumbers[0]?.friendlyName}</>
      ) : (
        <></>
      ),
  },
  {
    id: 'status',
    minWidth: 143,
    label: 'Status',
    format: (value) => <StatusContent status={value as Status} />,
  },
  {
    id: 'createdAt',
    label: 'Created',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAt),
  },
  {
    minWidth: 143,
    id: 'contactStatusStatistics.subscribersCount',
    label: 'Subscribers',
    format: (value, { contactStatusStatistics }) =>
      numberString({ val: +contactStatusStatistics?.subscribersCount }),
  },
  {
    minWidth: 143,
    id: 'contactStatusStatistics.activeCount',
    label: 'Active',
    format: (value, { contactStatusStatistics }) =>
      numberString({ val: +contactStatusStatistics?.activeCount }),
  },
  {
    id: 'shortUrlClickStatistics',
    label: 'Clicks',
    minWidth: 143,
    format: (value, { shortUrlClickStatistics }) =>
      numberString({ val: shortUrlClickStatistics?.totalVisits || 0 }),
  },
  {
    minWidth: 143,
    id: 'contactStatusStatistics.unsubscribedCount',
    label: 'Unsubscribed',
    format: (value, { contactStatusStatistics }) =>
      numberString({ val: +contactStatusStatistics?.unsubscribedCount }),
  },
]

export const noSortsColumns: string[] = [
  'keywordPhoneNumbers',
  'shortUrlClickStatistics',
  'contactStatusStatistics.subscribersCount',
  'contactStatusStatistics.activeCount',
  'contactStatusStatistics.unsubscribedCount',
]
