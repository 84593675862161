import { makeAutoObservable, reaction, runInAction } from 'mobx'
import apiSore from 'store/apiSore'
import {
  CreateSegmentDocument,
  CreateSegmentMutation,
  CreateSegmentMutationVariables,
  FilterDataType,
  FilterInput,
  SegmentResponseFragment,
  UpdateSegmentDocument,
  UpdateSegmentMutation,
  UpdateSegmentMutationVariables,
} from 'src/generated/graphql'
import { Filter } from 'store/contacts/segment/types'
import { EmptyFilter } from 'store/contacts/segment/filters/EmptyFilter'
import { groupBy } from 'lodash'
import segmentStore from 'store/contacts/segment/segmentStore'
import { ListFilter } from 'store/contacts/segment/filters/ListFilter'
import { TextFilter } from 'store/contacts/segment/filters/TextFilter'
import { OptInFilter } from 'store/contacts/segment/filters/OptInFilter'
import { SelectFilter } from 'store/contacts/segment/filters/SelectFilter'
import { DateFilter } from 'store/contacts/segment/filters/DateFilter'
import { ISegment } from 'src/types/ISegment'
import contactStore from 'store/contacts/contactStore'

const getNewFilter = (orIndex: number) => {
  return new EmptyFilter({
    orIndex,
    andIndex: 0,
  })
}

export class CreateSegmentStore {
  constructor() {
    makeAutoObservable(this)
    this.addFiltersGroup(0)
    reaction(
      () => this.filters,
      (filters) => {
        if (!filters.length) {
          this.addFiltersGroup(0)
        }
      }
    )
  }

  name = ''
  segment: ISegment | null = null
  filtersMap: Map<string, Filter | EmptyFilter> = new Map()
  loading = false

  get triggerHeight() {
    return `${this.filters.length}.${contactStore.tableStoreContacts.total}`
  }

  get isHasEmpty() {
    let isHasEmpty = false
    if (this.filters.length === 1 && this.filters[0] instanceof EmptyFilter) {
      isHasEmpty = true
    } else {
      this.filters.forEach((filter) => {
        if (filter instanceof EmptyFilter) {
        } else {
          if (!filter.commonIsNotEmpty && !filter.isNotEmpty) {
            isHasEmpty = true
          }
        }
      })
    }
    return isHasEmpty
  }

  get isNotEmpty() {
    let isNotEmpty = false
    this.filters.forEach((filter) => {
      if (filter instanceof EmptyFilter) {
      } else {
        if (filter.commonIsNotEmpty) {
          isNotEmpty = true
        } else if (filter.isNotEmpty) {
          isNotEmpty = true
        }
      }
    })
    return isNotEmpty
  }

  get disabledCreate() {
    return this.isHasEmpty || !this.name.trim()
  }

  get isEdit() {
    return !!this.segment
  }

  get filters(): Array<Filter | EmptyFilter> {
    return Array.from(this.filtersMap.values())
  }
  get filtersGroup() {
    return groupBy(this.filters, 'orIndex')
  }

  get filtersInputs(): FilterInput[] {
    const items: FilterInput[] = []
    this.filters.forEach((filter) => {
      if (filter instanceof EmptyFilter) {
      } else {
        items.push({
          ...filter.filterInput,
          type: segmentStore.getTypeByField(filter.field),
        })
      }
    })
    return items
  }
  setName = (title: string) => {
    this.name = title
  }
  setFilter = (filter: Filter | EmptyFilter) => {
    this.filtersMap.set(`${filter.orIndex}_${filter.andIndex}`, filter)
  }
  addFiltersGroup = (orIndex: number) => {
    this.setFilter(getNewFilter(orIndex))
  }
  onDeleteFilter = (filter: Filter | EmptyFilter) => {
    this.filtersMap.delete(`${filter.orIndex}_${filter.andIndex}`)
  }

  onSave = async (onSuccess: () => void) => {
    try {
      this.loading = true
      await (this.isEdit ? this.updateSegment : this.createSegment)()
      segmentStore.tableStore.onRefresh()
      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  private createSegment = async () =>
    apiSore.client.mutate<
      CreateSegmentMutation,
      CreateSegmentMutationVariables
    >({
      mutation: CreateSegmentDocument,
      variables: {
        segment: {
          name: this.name,
          filterValue: {
            filters: this.filtersInputs,
          },
        },
      },
    })

  private updateSegment = async () =>
    apiSore.client.mutate<
      UpdateSegmentMutation,
      UpdateSegmentMutationVariables
    >({
      mutation: UpdateSegmentDocument,
      variables: {
        segment: {
          id: this.segment?.id,
          name: this.name,
          filterValue: {
            filters: this.filtersInputs,
          },
        },
      },
    })

  setSegment = (segment: SegmentResponseFragment, isDuplicate?: boolean) => {
    if (isDuplicate) {
      this.name = `${segment.name} copy`
    } else {
      this.segment = { ...segment, id: segment.id }
      this.name = segment.name || ''
    }

    segment.filterValue?.filters?.forEach((filter) => {
      if (filter) {
        if (filter.dataType === FilterDataType.List) {
          const listFilter = new ListFilter(filter)
          this.setFilter(listFilter)
          return
        }
        if (
          filter?.dataType === FilterDataType.Text ||
          filter?.dataType === FilterDataType.Phone ||
          filter?.dataType === FilterDataType.Number
        ) {
          this.setFilter(new TextFilter(filter))
          return
        }
        if (filter.field === 'OPT_IN') {
          this.setFilter(new OptInFilter(filter))
          return
        }
        if (filter?.dataType === FilterDataType.Select) {
          this.setFilter(new SelectFilter(filter))
          return
        }
        if (
          filter?.dataType === FilterDataType.Date ||
          filter?.dataType === FilterDataType.DateTime
        ) {
          this.setFilter(new DateFilter(filter))
          return
        }
      }
    })
  }
}
