import { Modal } from 'components/Modal/Modal'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'

export const SuccessCanceledSubscriptionModal = observer(() => {
  const onClose = () => {
    billingStore.setOpenSuccessCanceledSubscriptionModal(false)
  }
  return (
    <Modal
      open={billingStore.openSuccessCanceledSubscriptionModal}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon />
        <h3>As long as you`re happy, that`s all that matters!</h3>
        <p className={'par2 gray1'}>
          Your <b>{billingStore.clSubscriptionTitle}</b>{' '}
          {billingStore.isTrial ? (
            'is canceled'
          ) : (
            <>
              will be canceled at the end of the billing cycle:{' '}
              <b>{billingStore.cancelAtString}</b>.
            </>
          )}
        </p>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          fullWidth
          onClick={onClose}
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
