import { IBroadcast, IBroadcastFull } from 'src/types/IBroadcast'
import { transformToInputs } from 'src/util/transform'
import {
  BroadcastFullResponseFragment,
  BroadcastItemType,
  BroadcastResponseFragment,
} from 'src/generated/graphql'
import { IItem } from 'components/NewTable/cell/ItemsRender/ItemsRender'

export const transformBroadcast = (
  broadcast: BroadcastResponseFragment
): IBroadcast => {
  const items: IItem[] = []
  if (broadcast.firstItemResponse?.name) {
    const getIcon = (): IItem['icon'] => {
      if (broadcast.firstItemResponse?.type === BroadcastItemType.Contact) {
        return 'user'
      }
      if (broadcast.firstItemResponse?.type === BroadcastItemType.List) {
        return 'contactsFill'
      }
      if (broadcast.firstItemResponse?.type === BroadcastItemType.Segment) {
        return 'segments'
      }
      return 'contactsFill'
    }
    const getGroup = (): IItem['group'] => {
      if (broadcast.firstItemResponse?.type === BroadcastItemType.Contact) {
        return 'contact'
      }
      if (broadcast.firstItemResponse?.type === BroadcastItemType.List) {
        return 'list'
      }
      if (broadcast.firstItemResponse?.type === BroadcastItemType.Segment) {
        return 'segment'
      }
      return 'list'
    }
    items.push({
      name: broadcast.firstItemResponse.name,
      id: broadcast.firstItemResponse?.id,
      icon: getIcon(),
      group: getGroup(),
    })
  }
  return {
    ...broadcast,
    id: String(broadcast.id),
    items,
    sendFromPhoneNumber: {
      id: broadcast.sendFromPhoneNumber?.id,
      phoneNumber: broadcast.sendFromPhoneNumber?.phoneNumber,
      friendlyName: broadcast.sendFromPhoneNumber?.friendlyName,
      verificationStatus: broadcast.sendFromPhoneNumber?.verificationStatus,
    },
    ...transformToInputs(broadcast),
  }
}

export const transformFullBroadcast = (
  broadcast: BroadcastFullResponseFragment
): IBroadcastFull => {
  const items: IItem[] = []
  broadcast.lists?.forEach((list) => {
    if (list?.name) {
      items.push({
        name: list.name,
        id: list.id,
        icon: 'contactsFill',
        group: 'list',
      })
    }
  })
  broadcast.segments?.forEach((segment) => {
    if (segment) {
      items.push({
        name: segment.name?.trim() || '',
        id: segment.id,
        icon: 'segments',
        group: 'segment',
      })
    }
  })
  broadcast.contacts?.forEach((contact) => {
    if (contact?.name) {
      items.push({
        name: contact.name?.trim() || contact.phone || '',
        id: contact.id,
        icon: 'user',
        group: 'contact',
      })
    }
  })

  return {
    ...broadcast,
    id: String(broadcast.id),
    items,
    sendFromPhoneNumber: {
      ...broadcast.sendFromPhoneNumber,
      id: broadcast.sendFromPhoneNumber?.id,
      phoneNumber: broadcast.sendFromPhoneNumber?.phoneNumber,
      friendlyName: broadcast.sendFromPhoneNumber?.friendlyName,
      verificationStatus: broadcast.sendFromPhoneNumber?.verificationStatus,
    },
    ...transformToInputs(broadcast),
  }
}
