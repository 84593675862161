// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lnkRIKgpwHOfjdwPPOyy{height:32px;display:flex;justify-content:space-between;align-items:center;padding:0 8px;border-radius:8px;column-gap:8px}.lnkRIKgpwHOfjdwPPOyy:hover{background-color:var(--primary-color-gray-4)}.QLXrdGjmTooqW7IOWmKN{width:146px}`, "",{"version":3,"sources":["webpack://./src/components/NewTable/components/Settings/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,aAAA,CACA,iBAAA,CACA,cAAA,CACA,4BACE,4CAAA,CAGJ,sBACE,WAAA","sourcesContent":[".settingItem{\n  height: 32px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 8px;\n  border-radius: 8px;\n  column-gap: 8px;\n  &:hover{\n    background-color: var(--primary-color-gray-4);\n  }\n}\n.title{\n  width: 146px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingItem": `lnkRIKgpwHOfjdwPPOyy`,
	"title": `QLXrdGjmTooqW7IOWmKN`
};
export default ___CSS_LOADER_EXPORT___;
