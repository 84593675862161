import axios, { AxiosResponse } from 'axios'
import { FilterInput } from 'src/generated/graphql'
import { IResponseExport } from 'src/apiRest/type'

export const api = {
  getReport: (
    item: string,
    id: number
  ): Promise<AxiosResponse<IResponseExport>> =>
    axios.get(`report/${item}?id=${id}`),

  postContactReport: (
    filters: Array<FilterInput>
  ): Promise<AxiosResponse<IResponseExport>> =>
    axios.post(`report/filter-contact`, { filters }),

  getShortenLink: (
    originalUrl: string
  ): Promise<AxiosResponse<{ shortUrl: string }>> =>
    axios.post('short-url', {
      originalUrl,
    }),
}
