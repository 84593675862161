import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ObservableQuery } from '@apollo/client'

export function useRefetch(
  refetch: ObservableQuery['refetch'],
  skip?: boolean
) {
  const location = useLocation()
  useEffect(() => {
    if (!skip) {
      refetch()
    }
  }, [location, skip])
}
