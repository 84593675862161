import React, { useMemo } from 'react'
import { ValidItem } from 'components/Input/InputText/ValidItem'

export const FilesRejection = ({
  file,
  accepted,
}: {
  file: File
  accepted: string[]
}) => {
  const reason = useMemo(() => {
    const extFile = file.name.split('.')?.pop() || ''
    if (!accepted.includes(extFile)) {
      return `Please upload the following file types: ${accepted
        .map((ext) => `*.${ext}`)
        .join(', ')}`
    } else {
      return `The file "${file.name}" is too large, please upload files less than 5 MB`
    }
  }, [file, accepted])
  return <ValidItem text={reason} isValid={false} />
}
