// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zQ0fhb784ekRQ145n0Rw{border:1px solid var(--primary-color-gray-3);border-radius:12px;display:flex;justify-content:space-between;align-items:center;padding:23px}.zQ0fhb784ekRQ145n0Rw.m2dzZazh_0NhbhLol1WR:hover{cursor:pointer;background-color:var(--primary-color-gray-4)}.RH9A21kSgSmQ0aM3WxZg{width:372px}`, "",{"version":3,"sources":["webpack://./src/routes/settings/compliance/BusinessProfileCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,YAAA,CAEE,iDACE,cAAA,CACA,4CAAA,CAKN,sBACE,WAAA","sourcesContent":[".wrap{\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 12px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 23px;\n  &.completed{\n    &:hover{\n      cursor: pointer;\n      background-color: var(--primary-color-gray-4);\n    }\n\n  }\n}\n.fromModal{\n  width: 372px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `zQ0fhb784ekRQ145n0Rw`,
	"completed": `m2dzZazh_0NhbhLol1WR`,
	"fromModal": `RH9A21kSgSmQ0aM3WxZg`
};
export default ___CSS_LOADER_EXPORT___;
