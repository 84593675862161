import { observer } from 'mobx-react-lite'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { IOption } from 'src/types/IOption'
import { AddContactsBy } from 'components/AddContactsBy/AddContactsBy'
import { useEffect, useMemo, useState } from 'react'
import { useGetShortMyOrganizationListsQuery } from 'src/generated/graphql'
import { contactListToOption } from 'store/contacts/functions'

const emptyText =
  'You don’t have any contacts. Click on the right icon to import or add manually contacts.'

export const SentToDropDown = observer(
  ({
    label = 'Send to',
    labelTooltip,
    tabIndex,
    onMultiSelect,
    onDeleteTag,
    selectedOptions,
    maxWidth,
    required,
    noKeyword,
    noWebForm,
    noRightBth,
  }: {
    tabIndex?: number
    withSegments?: boolean
    label?: string
    labelTooltip?: string
    onMultiSelect: (opt: IOption[]) => void
    onDeleteTag: (id: string) => void
    selectedOptions: IOption[]
    required?: boolean
    noKeyword?: boolean
    noWebForm?: boolean
    maxWidth?: number
    noRightBth?: boolean
  }) => {
    const [search, setSearch] = useState('')
    const { data, loading, refetch } = useGetShortMyOrganizationListsQuery({
      variables: {
        page: {
          pageNumber: 0,
          pageSize: 20,
        },
        listingSearch: {
          searchPattern: search,
        },
      },
    })
    const options: IOption[] = useMemo(() => {
      const options: IOption[] = []
      data?.getMyOrganizationLists?.content?.forEach((list) => {
        if (list) {
          options.push(contactListToOption(list))
        }
      })

      return options
    }, [data])

    useEffect(() => {
      refetch()
    }, [])
    const handleDeleteTag = (id: string) => {
      onDeleteTag(id)
    }
    const onAddListsFromModal = (options: IOption[]) => {
      onMultiSelect && onMultiSelect([...selectedOptions, ...options])
    }
    return (
      <>
        <Dropdown
          search={search}
          setSearch={setSearch}
          loadingSearchData={loading}
          options={options}
          withSearch
          multi
          withSelectALl
          tabIndex={tabIndex}
          labelInput={label}
          labelTooltip={labelTooltip}
          labelDropdown={'Lists'}
          emptyText={search.trim() ? '' : emptyText}
          placeholder={'Select'}
          onMultiSelect={onMultiSelect}
          selectedOptions={selectedOptions}
          selectedTag
          withNoResults
          required={required}
          maxWidth={maxWidth}
          onDeleteTag={(id) => handleDeleteTag(id as string)}
          rightBtn={
            noRightBth ? undefined : (
              <AddContactsBy
                onAddListsFromModal={onAddListsFromModal}
                noWebForm={noWebForm}
                noKeyword={noKeyword}
              />
            )
          }
        />
      </>
    )
  }
)
