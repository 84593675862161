import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material'
import { Colors, IColor } from 'styles/colors'

type Props = {
  determinateColor?: IColor
  indeterminateColor?: IColor
}

export function Loader({
  determinateColor,
  indeterminateColor,
  ...props
}: CircularProgressProps & Props) {
  return (
    <Box sx={{ position: 'relative', display: 'flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color:
            (determinateColor && Colors[determinateColor]) ||
            Colors['PrimaryColorGray2Light'],
        }}
        size={props.size || 32}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color:
            (indeterminateColor && Colors[indeterminateColor]) ||
            Colors['PrimaryColorBlue1'],
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={props.size || 32}
        thickness={4}
        {...props}
      />
    </Box>
  )
}
