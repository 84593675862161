import { makeAutoObservable } from 'mobx'

class RegisterStore {
  constructor() {
    makeAutoObservable(this)
    const companyDataString = localStorage.getItem('companyData')
    if (companyDataString) {
      const companyData = JSON.parse(companyDataString)
      this.companyName = companyData.companyName
      this.employees = companyData.employees
      this.deliveredMap = new Map(companyData.deliveredMap)
    }
  }

  companyName = ''
  employees: string | null = ''
  deliveredMap: Map<string, boolean> = new Map([
    ['SMS', false],
    ['Ringless', false],
    ['Automated Calls', false],
  ])
  setCompanyData = ({
    companyName,
    employees,
  }: {
    companyName: string
    employees: string | null
  }) => {
    this.companyName = companyName
    this.employees = employees
    localStorage.setItem(
      'companyData',
      JSON.stringify({
        companyName: this.companyName,
        employees: this.employees,
        deliveredMap: this.deliveredMap,
      })
    )
  }

  setDelivered = (label: string) => {
    const oldValue = this.deliveredMap.get(label)
    this.deliveredMap.set(label, !oldValue)
  }
}

export default new RegisterStore()
