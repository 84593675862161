import 'react-datepicker/dist/react-datepicker.css'
import './styles/all.scss'
import { PublicApp } from 'components/App/PublicApp'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { clientId } from 'src/credentials'
import { AppPostHogProvider } from 'src/providers/AppPostHogProvider'

export const App = () => {
  return (
    <>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={clientId}>
          <AppPostHogProvider>
            <PublicApp />
          </AppPostHogProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </>
  )
}
