import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { ISegment } from 'src/types/ISegment'
import { useNavigate } from 'react-router-dom'

export const SegmentDuplicateBtn = (props: ActionBtnProps<ISegment>) => {
  const { row } = props
  const navigate = useNavigate()

  const onAction = () => {
    if (row) {
      navigate(`${row.id}/duplicate`)
    }
  }
  return (
    <TableBtn {...props} action={ActionsEnum.duplicate} onAction={onAction} />
  )
}
