// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kCLStjbuFzhfsLTFI3_0{display:grid}.ThgcdepRmO2sfqxE3rsg{display:flex;align-items:center;margin-bottom:8px}._vFIQNPi8HxOM2vxzcuT{height:73px;resize:none;width:100%;border:1px solid var(--primary-color-gray-3);border-radius:8px;padding:11px;outline:none;font-size:14px;line-height:20px;color:var(--primary-color-black)}._vFIQNPi8HxOM2vxzcuT:hover{border-color:rgba(19,100,249,.4);outline:3px solid rgba(208,224,254,.4)}._vFIQNPi8HxOM2vxzcuT:active,._vFIQNPi8HxOM2vxzcuT:focus{outline:3px solid #d0e0fe;border-color:var(--primary-color-blue-1)}._vFIQNPi8HxOM2vxzcuT::placeholder{font-weight:400;color:var(--primary-color-gray-2)}`, "",{"version":3,"sources":["webpack://./src/components/SimpleTextarea/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CAEF,sBACE,WAAA,CACA,WAAA,CACA,UAAA,CACA,4CAAA,CACA,iBAAA,CACA,YAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CACA,gCAAA,CACA,4BACE,gCAAA,CACA,sCAAA,CAEF,yDACE,yBAAA,CACA,wCAAA,CAGF,mCACE,eAAA,CACA,iCAAA","sourcesContent":[".wrap{\n  display: grid;\n}\n.wrapLabel{\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n}\n.textarea{\n  height: 73px;\n  resize: none;\n  width: 100%;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 8px;\n  padding: 11px;\n  outline: none;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--primary-color-black);\n  &:hover{\n    border-color: rgba(19, 100, 249, 0.4);\n    outline: 3px solid rgba(208, 224, 254, 0.4);\n  }\n  &:active, &:focus{\n    outline: 3px solid #D0E0FE;\n    border-color: var(--primary-color-blue-1);\n  }\n\n  &::placeholder {\n    font-weight: 400;\n    color: var(--primary-color-gray-2);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `kCLStjbuFzhfsLTFI3_0`,
	"wrapLabel": `ThgcdepRmO2sfqxE3rsg`,
	"textarea": `_vFIQNPi8HxOM2vxzcuT`
};
export default ___CSS_LOADER_EXPORT___;
