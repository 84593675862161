// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qEvKttKHh1w_xziO4zTL{width:800px}.G7fhjB5vlmkqGC8eQt87 .vfiLKY1H6yulLLryLpaR{background-color:rgba(0,0,0,0)}.OEqHjbFPe8U9i_ajtBXR{margin:0 32px 24px 32px}.zcDDM0CYYssFWRx8N6nL{margin:0 32px}.qdLQ8hAgN1BH9j8J1P2w{margin-top:24px;min-height:384px}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/AddMediaContent/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,WAAA,CAGA,4CACE,8BAAA,CAGJ,sBACE,uBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,eAAA,CACA,gBAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrap{\n  width: 800px;\n}\n.disabled{\n  .infoIconWrap{\n    background-color: transparent;\n  }\n}\n.infoWrap{\n  margin: 0 32px 24px 32px;\n}\n\n.tabsWrap{\n  margin: 0 32px;\n}\n\n.tabWrap{\n  margin-top: 24px;\n  min-height: 384px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `qEvKttKHh1w_xziO4zTL`,
	"disabled": `G7fhjB5vlmkqGC8eQt87`,
	"infoIconWrap": `vfiLKY1H6yulLLryLpaR`,
	"infoWrap": `OEqHjbFPe8U9i_ajtBXR`,
	"tabsWrap": `zcDDM0CYYssFWRx8N6nL`,
	"tabWrap": `qdLQ8hAgN1BH9j8J1P2w`
};
export default ___CSS_LOADER_EXPORT___;
