// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iNl8tVEoCCe82j8_clni{background:var(--primary-color-white);box-shadow:0px 4px 8px -2px rgba(22,44,99,.1);border-radius:10px;height:16px;padding:4px}.iNl8tVEoCCe82j8_clni.kXQ8Vk0ntBmn2UOHs2yc{box-shadow:none;padding:0;height:6px;background:var(--primary-color-gray-3)}.iNl8tVEoCCe82j8_clni.kXQ8Vk0ntBmn2UOHs2yc .F3fnzB2Hr96Ae6xBUqxT{height:6px}.RvM0T0nPldg5Dh1cnGCP{background:var(--primary-color-gray-3);border-radius:100px;height:8px;box-shadow:1px 2px 4px 0px rgba(183,191,200,.4) inset}.F3fnzB2Hr96Ae6xBUqxT{background:linear-gradient(90deg, #1885EA 0%, #2E9AE4 100%);border-radius:100px;height:8px}`, "",{"version":3,"sources":["webpack://./src/components/RangeBar/styels.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CACA,6CAAA,CACA,kBAAA,CACA,WAAA,CACA,WAAA,CACA,2CACE,eAAA,CACA,SAAA,CACA,UAAA,CACA,sCAAA,CACA,iEACE,UAAA,CAKN,sBACE,sCAAA,CACA,mBAAA,CACA,UAAA,CACA,qDAAA,CAGF,sBACE,2DAAA,CACA,mBAAA,CACA,UAAA","sourcesContent":[".range{\n  background: var(--primary-color-white);\n  box-shadow: 0px 4px 8px -2px rgba(22, 44, 99, 0.1);\n  border-radius: 10px;\n  height: 16px;\n  padding: 4px;\n  &.style2{\n    box-shadow: none;\n    padding: 0;\n    height: 6px;\n    background: var(--primary-color-gray-3);\n    .bar{\n      height: 6px;\n    }\n  }\n\n}\n.barContainer{\n  background: var(--primary-color-gray-3);\n  border-radius: 100px;\n  height: 8px;\n  box-shadow: 1px 2px 4px 0px rgba(183, 191, 200, 0.4) inset;\n\n}\n.bar{\n  background: linear-gradient(90deg, #1885EA 0%, #2E9AE4 100%);\n  border-radius: 100px;\n  height: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"range": `iNl8tVEoCCe82j8_clni`,
	"style2": `kXQ8Vk0ntBmn2UOHs2yc`,
	"bar": `F3fnzB2Hr96Ae6xBUqxT`,
	"barContainer": `RvM0T0nPldg5Dh1cnGCP`
};
export default ___CSS_LOADER_EXPORT___;
