import * as React from 'react'
import { MoreBtn } from 'components/Button/MoreBtn'
import { SettingBtn } from 'components/NewTable/components/Settings/SettingBtn'
import { TableStore } from 'components/NewTable/store/TableStore'
import { ColumnType, RequiredFields } from 'components/NewTable/types'

export const mobileActionColumn: ColumnType<RequiredFields<any>> = {
  id: 'mobileActionColumn',
  label: '',
  labelContent: (tableStore: TableStore<any>) => (
    <SettingBtn<any> tableStore={tableStore} />
  ),
  stickyRight: true,
  format: (_, { actions }) => (
    <MoreBtn menuItems={actions || []} fromMobile width={'fit-content'} />
  ),
}
