import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import contactStore from 'store/contacts/contactStore'
import styles from './styles.module.scss'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ReactComponent as Calendar } from 'icons/16pxNoMask/Calendar.svg'
import { ReactComponent as Contacts } from 'icons/16pxNoMask/ContactsFill.svg'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import manualContactStore from 'store/contacts/manualContactStore'
import { CommonContent } from 'src/routes/main/contacts/modals/AddContactModal/CommonContent'
import { Listing, useUpdateContactMutation } from 'src/generated/graphql'
import { transformContact } from 'store/contacts/functions'
import React, { useEffect, useState } from 'react'
import { useContactFunctions } from 'src/routes/main/contacts/hooks/useContactFunctions'
import { ScrollWrapper } from 'components/Wrappers/ScrollWrapper/ScrollWrapper'
import { IContact } from 'src/types/IContact'
import { Actions } from 'components/Actions/Actions'
import { getContactActions } from 'src/routes/main/contacts/functions/getContactActions'

export const EditContactModal = observer(() => {
  const [updateContact, { error }] = useUpdateContactMutation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (error?.graphQLErrors?.length && error?.graphQLErrors[0].extensions.id) {
      manualContactStore.setErrorContactId(
        error?.graphQLErrors[0].extensions.id as number
      )
    }
  }, [error?.graphQLErrors])

  const { onEdit } = useContactFunctions({
    row: manualContactStore.editContact as IContact,
  })

  const onClose = () => manualContactStore.closeAddContactModal()
  const onSave = () => {
    setLoading(true)
    updateContact({
      variables: {
        updateContactInput: manualContactStore.updateContactInput,
      },
    })
      .then((res) => {
        if (res.data?.updateContact?.updatedListings) {
          const listings: Listing[] = []
          res.data.updateContact.updatedListings.forEach((list) => {
            if (list) {
              listings.push(list)
            }
          })
          contactStore.addListing(listings)
          if (res.data?.updateContact?.contact) {
            contactStore.updateContact(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              transformContact(res.data?.updateContact?.contact)
            )
          }
        }
        manualContactStore.closeAddContactModal()
        contactStore.setStatisticTrigger()
      })
      .catch((e) => {
        manualContactStore.setError(e?.message)
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      open={!!manualContactStore.editContactId}
      onClose={onClose}
      title={'Edit contact'}
      minWidth={528}
      centered
    >
      <div className={styles.wrap}>
        <ScrollWrapper>
          {manualContactStore.editContact?.status && (
            <div className={styles.card}>
              <StatusContent status={manualContactStore.editContact.status} />
              <SizedBox height={24} />
              <div className={styles.rowInfo}>
                <Calendar />
                <span className={'gray1'}>Added on</span>
                <span>
                  {dayjs(manualContactStore.editContact.createdAt).format(
                    DayjsFormats.fullWithAt
                  )}
                </span>
                <div className={'point'} />
                <span className={'gray1'}>EST</span>
              </div>
              <SizedBox height={10} />
              <div className={styles.rowInfo}>
                <Contacts />
                <span className={'gray1'}>Opted-in</span>
                <span>{manualContactStore.editContact.optIn}</span>
              </div>
            </div>
          )}
          <CommonContent />
        </ScrollWrapper>
        <div className={styles.actions}>
          <div>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              disabled={manualContactStore.disabled || loading}
              onClick={onSave}
            >
              Save
            </Button>
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
          <Actions
            actions={getContactActions({
              row: manualContactStore.editContact as IContact,
              isNoEdit: true,
              onSuccessAction: onEdit,
            })}
            visibleActionsCount={0}
            position={TTPositionEnum.topRight}
            tooltip={''}
          />
        </div>
      </div>
    </Modal>
  )
})
