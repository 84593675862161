import styles from './styles.module.scss'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import messageTemplateStore from 'src/widgets/MessageTemplate/store/MessageTemplateStore'
import { observer } from 'mobx-react-lite'

export const NoTemplates = observer(() => {
  const { loading, search } = messageTemplateStore
  return (
    <div className={styles.wrapEmpty}>
      {loading ? (
        <AbsoluteLoader size={32} />
      ) : (
        <div className={styles.empty}>
          <p className={'par2'}>
            {search
              ? `No results for "${search}"`
              : 'You don’t have any templates created yet'}
          </p>
        </div>
      )}
    </div>
  )
})
