import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { MediumBtn } from 'components/Button/MediumBtn'
import { ActionsEnum } from 'components/Button/types'
import * as React from 'react'
import { observer } from 'mobx-react-lite'
import {
  BusinessProfileResponse,
  useAdminInitBusinessTermsMutation,
} from 'src/generated/graphql'
import businessProfileStore from 'store/settings/company/businessProfileStore'

export const SuperAdminBPActions = observer(
  ({
    profile,
    organizationId,
  }: {
    profile: BusinessProfileResponse
    organizationId: number
  }) => {
    const { setOpenEditModal, setOpenModal, adminOrgId } = businessProfileStore
    const onEdit = () => {
      setOpenEditModal(true, profile, organizationId)
    }
    const [adminInitBusinessTerms, { loading }] =
      useAdminInitBusinessTermsMutation()
    const onSubmit = async () => {
      try {
        const { data } = await adminInitBusinessTerms({
          variables: {
            organizationId: adminOrgId,
          },
        })
        if (data?.adminInitBusinessTerms && adminOrgId)
          setOpenModal(true, data.adminInitBusinessTerms, adminOrgId)
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <div className={'mb32 row12'}>
        <Button
          size={BtnSize.medium}
          typeBtn={BtnType.primary}
          onClick={onSubmit}
          disabled={!businessProfileStore.completed}
          loading={loading}
        >
          Submit for review
        </Button>
        <MediumBtn type={ActionsEnum.edit} onClick={onEdit} />
      </div>
    )
  }
)
