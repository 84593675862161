import { useEffect, useMemo, useState } from 'react'
import { Chart } from 'chart.js'
import dayjs from 'lib/dayjs'
import { SizedBox } from 'components/SizedBox'
import { Table, TableVariant } from 'components/Table/Table'
import { Column } from 'react-table'
import styles from './styles.module.scss'
import { numberString } from 'src/util/functions'
import classNames from 'classnames'
import { ColorsValues } from 'styles/variables'

const getDay = (prevDay: number) => dayjs().add(prevDay, 'day').format('MMM DD')

export enum MessagesType {
  sms = 'SMS',
  voice = 'Voice',
  ringless = 'Ringless',
}

interface IBroadcastsTable {
  name: string
  type: MessagesType
  total: number
  success: number
  unsubscribed: number
  failed: number
  color: string
}

const mockReminder = {
  [`${getDay(-6)} - ${getDay(0)}`]: 232,
}
const mockWebinar = {
  [`${getDay(-6)} - ${getDay(0)}`]: 887,
}
const mockDiscount = {
  [`${getDay(-6)} - ${getDay(0)}`]: 1026,
}

export const BroadcastsChart = () => {
  const [ref, setRef] = useState<HTMLCanvasElement | null>(null)
  useEffect(() => {
    // const ctx = document.getElementById('myChart')
    if (ref) {
      const labels = Object.keys(mockDiscount)
      new Chart(ref, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Reminder',
              data: Object.values(mockReminder),
              backgroundColor: '#7E31F4',
              borderRadius: 12,
              maxBarThickness: 40,
            },
            {
              label: 'Webinar',
              data: Object.values(mockWebinar),
              backgroundColor: '#FFC043',
              borderRadius: 12,
              maxBarThickness: 40,
            },
            {
              label: 'Discount',
              data: Object.values(mockDiscount),
              backgroundColor: '#FC7327',
              borderRadius: 12,
              maxBarThickness: 40,
            },
          ],
        },
        options: {
          scales: {
            y: {
              grid: {
                borderDash: [6],
                tickLength: 12,
                tickWidth: 0,
                lineWidth: 0,
                borderWidth: 0,
              },
              ticks: {
                color: ColorsValues.gray1,
                font: { size: 10 },
              },
            },
            x: {
              grid: {
                borderDash: [6],
                tickLength: 12,
                tickWidth: 0,
                borderWidth: 0,
              },
              ticks: {
                color: ColorsValues.gray1,
                font: { size: 10 },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      })
    }
  }, [ref])

  const data: IBroadcastsTable[] = useMemo(() => {
    return [
      {
        name: 'Reminder',
        type: MessagesType.sms,
        total: 232,
        success: 230,
        unsubscribed: 7,
        failed: 2,
        color: '#7E31F4',
      },
      {
        name: 'Webinar',
        type: MessagesType.voice,
        total: 887,
        success: 887,
        unsubscribed: 0,
        failed: 0,
        color: '#FFC043',
      },
      {
        name: 'Discount',
        type: MessagesType.ringless,
        total: 1026,
        success: 1020,
        unsubscribed: 10,
        failed: 6,
        color: '#FC7327',
      },
    ]
  }, [])

  const columns: Column<IBroadcastsTable>[] = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          value,
          row: {
            original: { color },
          },
        }) => (
          <div className={styles.nameCell}>
            <div className={styles.marker} style={{ background: color }} />
            {value}
          </div>
        ),
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => (
          <div className={classNames(styles.typeCell, styles[value])}>
            <span className={'s2 medium'}>{value}</span>
          </div>
        ),
      },
      {
        Header: 'Total',
        accessor: 'total',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Success',
        accessor: 'success',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Unsubscribed',
        accessor: 'unsubscribed',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
      {
        Header: 'Filed',
        accessor: 'failed',
        Cell: ({ value }) => <>{numberString({ val: value })}</>,
      },
    ]
  }, [])
  return (
    <>
      <canvas id="myChart" width="460" height="160" ref={setRef} />
      <SizedBox height={24} />
      <Table<IBroadcastsTable>
        columns={columns}
        data={data}
        variant={TableVariant.variant1}
        tdHeight={48}
      />
    </>
  )
}
