import { useGetConfigurationQuery } from 'src/generated/graphql'
import configStore from 'store/configStore'
import { useEffect } from 'react'

export function useSetConfig() {
  const { data } = useGetConfigurationQuery({
    skip: configStore.isInit,
  })
  useEffect(() => {
    if (data) {
      configStore.setData(data)
    }
  }, [data])
}
