// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aFRIb4t50pEQFLHGKTIL{display:flex;align-items:center;justify-content:center;width:32px;height:32px;border:1px solid var(--primary-color-gray-3);border-radius:8px}.aFRIb4t50pEQFLHGKTIL svg{width:24px;height:24px}.BrNSwPuv1NwRg3TZCiWS{margin-top:32px;display:grid;row-gap:24px;margin-bottom:40px}.hb7Q5dg69X_pAlB9xfxv{margin-top:24px;display:grid;gap:12px;grid-template-columns:1fr max-content}`, "",{"version":3,"sources":["webpack://./src/routes/settings/integrations/modal/connectIntegrationsModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,4CAAA,CACA,iBAAA,CACA,0BACE,UAAA,CACA,WAAA,CAGJ,sBACE,eAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,QAAA,CACA,qCAAA","sourcesContent":[".wrapTitleIcon{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 32px;\n  height: 32px;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 8px;\n  svg{\n    width: 24px;\n    height: 24px;\n  }\n}\n.wrapFields{\n  margin-top: 32px;\n  display: grid;\n  row-gap: 24px;\n  margin-bottom: 40px;\n}\n.wrapCustom{\n  margin-top: 24px;\n  display: grid;\n  gap: 12px;\n  grid-template-columns: 1fr max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTitleIcon": `aFRIb4t50pEQFLHGKTIL`,
	"wrapFields": `BrNSwPuv1NwRg3TZCiWS`,
	"wrapCustom": `hb7Q5dg69X_pAlB9xfxv`
};
export default ___CSS_LOADER_EXPORT___;
