import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import addNumberStore from 'store/settings/numbers/addNumberStore'

export const AddTollFreeFirstModal = observer(() => {
  const onClose = () => {
    addNumberStore.setOpenFirstTollFreeNumberModal(false)
  }
  const onAdd = () => {
    onClose()
    addNumberStore.setOpenAddNumberModal(true, null, true)
  }
  return (
    <Modal
      open={addNumberStore.openFirstTollFreeNumberModal}
      onClose={onClose}
      withoutHeader
    >
      <div className={'pModalInfo'}>
        <ModalIcon type={'info'} />
        <h3>Add toll-free number first</h3>
        <p className={'par2 gray1'}>
          To get access to Toll-Free verification you need to first add a
          toll-free number.
        </p>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onAdd}
          fullWidth
          mb={8}
        >
          Add number
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onClose}
          fullWidth
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
